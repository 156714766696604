import React, { Component } from "react";
import parse from "html-react-parser";
import $c from "../../../../../modules/functions";
import $f from "../../../../../src";
import { Link, withRouter } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import Privacy from "./privacy";
import Terms from "./terms";

class ConferenceInterestForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();
    this.fetchCountries = this.fetchCountries.bind(this);
    this.setInputState = this.setInputState.bind(this);
    this.validate = this.validate.bind(this);
    this.formIsFilled = this.formIsFilled.bind(this);
    this.requestSeat = this.requestSeat.bind(this);

    this.openPrivacy = this.openPrivacy.bind(this);
    this.openTerms = this.openTerms.bind(this);

    this.state = {
      data: [],
      loaded: false,
      isValid: false,
      formIsFilled: false,
    };
  }

  componentDidMount() {
    this.setState({
      loaded: false,
    });

    this.fetchCountries();
  }

  setInputState(name, e) {
    this.setState({ [name]: e.target.value });
  }

  validate() {
    const isValid = this.validator.allValid();

    this.setState({ isValid });
    this.validator.showMessages();
    this.forceUpdate();

    return isValid;
  }

  openPrivacy() {
    const ref = React.createRef();

    const Body = <Privacy key={$f.key()} ref={ref}></Privacy>;

    window["secondaryModal"].setState({
      show: true,
      size: "lg",
      title: (
        <h2 className="display-3-no-line-height header-ellipsis">
          Privacy Policy
        </h2>
      ),
      footer: [
        <button
          type="button"
          onClick={() => {
            window["secondaryModal"].close();
          }}
          className="btn btn-sm btn-white"
        >
          Close
        </button>,
      ],
      body: Body,
    });
  }

  openTerms() {
    const ref = React.createRef();

    const Body = <Terms key={$f.key()} ref={ref}></Terms>;

    window["secondaryModal"].setState({
      show: true,
      size: "lg",
      title: (
        <h2 className="display-3-no-line-height header-ellipsis">
          Terms and conditions
        </h2>
      ),
      footer: [
        <button
          type="button"
          onClick={() => {
            window["secondaryModal"].close();
          }}
          className="btn btn-sm btn-white"
        >
          Close
        </button>,
      ],
      body: Body,
    });
  }

  fetchCountries() {
    $f.fetch("/countries", "GET", null, {}, {}, (resp) => {
      let output = [
        {
          label: "Please select",
          value: "",
        },
      ];

      let data = resp.data;

      data.map((itm, idx) => {
        output.push({
          label: itm,
          value: itm,
        });
      });

      this.setState({
        loaded: true,
        options: output,
      });
    });
  }

  formIsFilled() {
    if (
      this.state.firstname?.trim() &&
      this.state.lastname?.trim() &&
      this.state.jobtitle?.trim() &&
      this.state.company?.trim() &&
      $c.isEmail(this.state.emailaddress)
    ) {
      return true;
    }
    return false;
  }

  requestSeat() {
    if (this.formIsFilled()) {
      let _this = this;
      window.grecaptcha.ready(function () {
        window.grecaptcha
          .execute("6LfqMDQeAAAAAKxo_kbK9dVlnwXtRoGWTEOUWn-X", {
            action: "sendEmail",
          })
          .then(function (token) {
            let postData = {
              token: token,
              streamId: $f.getQueryPath(2),
              firstname: _this.state.firstname,
              lastname: _this.state.lastname,
              jobtitle: _this.state.jobtitle,
              company: _this.state.company,
              emailaddress: _this.state.emailaddress,
              phonenumber: _this.state.phonenumber
                ? _this.state.phonenumber
                : "",
              country: _this.state.country ? _this.state.country : "",
              comments: _this.state.comments ? _this.state.comments : "",
            };

            $f.fetch("/auth/recaptcha", "POST", postData, {}, {}, (resp) => {
              $f.createNotificationFromResponse(resp.data, false, true, false);
              window["primaryModal"].setState({ show: false });
            });
          });
      });
    }
  }

  render() {
    if (this.state.loaded) {
      return (
        <div>
          <span className="d-block text-grey pb-4">
            Please fill the form below to reserver a seat and we will contact
            you asap. (*) required fields.
          </span>
          <div>
            <div className="row">
              <div className="form-group col-md-6 mb-3">
                <label htmlFor="firstname">First name *</label>
                <input
                  type="text"
                  className="form-control"
                  name="firstname"
                  id="firstname"
                  placeholder="Type your first name"
                  onChange={(e) => this.setInputState("firstname", e)}
                />

                {this.validator.message(
                  "First name",
                  this.state.firstname,
                  "required",
                  { className: "text-danger pt-2" }
                )}
              </div>
              <div className="form-group col-md-6 mb-3">
                <label htmlFor="lastname">Last name *</label>
                <input
                  type="text"
                  className="form-control"
                  name="lastname"
                  id="lastname"
                  placeholder="Type your last name"
                  onChange={(e) => this.setInputState("lastname", e)}
                />
                {this.validator.message(
                  "Last name",
                  this.state.lastname,
                  "required",
                  { className: "text-danger pt-2" }
                )}
              </div>
              <div className="form-group col-md-6  mb-3">
                <label htmlFor="jobtitle">Job title *</label>
                <input
                  type="text"
                  className="form-control"
                  name="jobtitle"
                  id="jobtitle"
                  placeholder="Type your job title"
                  onChange={(e) => this.setInputState("jobtitle", e)}
                />
                {this.validator.message(
                  "Job title",
                  this.state.jobtitle,
                  "required",
                  { className: "text-danger pt-2" }
                )}
              </div>
              <div className="form-group col-md-6  mb-3">
                <label htmlFor="company">Company *</label>
                <input
                  type="text"
                  className="form-control"
                  name="company"
                  id="company"
                  placeholder="Type your company's name"
                  onChange={(e) => this.setInputState("company", e)}
                />
                {this.validator.message(
                  "company's name",
                  this.state.company,
                  "required",
                  { className: "text-danger pt-2" }
                )}
              </div>
              <div className="form-group col-md-6  mb-3">
                <label htmlFor="emailaddress">Email address *</label>
                <input
                  type="text"
                  className="form-control"
                  name="emailaddress"
                  id="emailaddress"
                  placeholder="Type your email address"
                  onChange={(e) => this.setInputState("emailaddress", e)}
                />
                {this.validator.message(
                  "email address",
                  this.state.emailaddress,
                  "required|email",
                  { className: "text-danger pt-2" }
                )}
              </div>
              <div className="form-group col-md-6  mb-3">
                <label htmlFor="phonenumber">Phone number</label>
                <input
                  type="text"
                  className="form-control"
                  name="phonenumber"
                  id="phonenumber"
                  placeholder="Type your phone number"
                  onChange={(e) => this.setInputState("phonenumber", e)}
                />
              </div>
            </div>
            <div className="form-group  mb-3">
              <label htmlFor="country">Country</label>

              <select
                className="form-control form-select"
                value={this.state.country}
                onChange={(e) => {
                  this.setState({ country: e.target.value });
                }}
              >
                {this.state.options?.map((el) => {
                  return <option value={el.value}>{el.label}</option>;
                })}
              </select>
            </div>

            <div className="form-group  mb-3">
              <label htmlFor="comments">Anything else?</label>
              <textarea
                className="form-control"
                id="comments"
                rows="3"
                name="comments"
                placeholder="Tell us more about your needs"
                onChange={(e) => this.setInputState("comments", e)}
              ></textarea>
            </div>
            <div className="form-group mb-3 text-end">
              <button
                type="button"
                onClick={this.requestSeat}
                className="btn btn-sm btn-purple text-white"
                disabled={this.formIsFilled() ? false : true}
              >
                Send request
              </button>
            </div>
            <div className="form-group">
              <span className="small d-block pt-2 color-grey">
                We respect your privacy and wherever your information is
                processed we will handle it with care. We want you to understand
                how we collect, use, and share data about you. Our data
                collection practices are described in our{" "}
                <span
                  role="button"
                  onClick={this.openPrivacy}
                  className="text-primary"
                >
                  Privacy Policy
                </span>
                . This site is protected by reCAPTCHA and the Google{" "}
                <a
                  href="https://policies.google.com/privacy?hl=en"
                  target="_blank"
                  className="text-primary"
                >
                  Privacy Policy
                </a>{" "}
                and{" "}
                <a
                  href="https://policies.google.com/terms?hl=en"
                  target="_blank"
                  className="text-primary"
                >
                  Term
                </a>{" "}
                apply.{" "}
              </span>
            </div>
          </div>
        </div>
      );
    } else {
      return "loading...";
    }
  }
}

export default withRouter(ConferenceInterestForm);
