import { Component } from "react";
import $f from "../../../../src";
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";
import $c from "../../../../modules/functions";
import MeetingFunctions from "./functions";

class AssignParticipants extends Component {
  constructor(props) {
    super(props);
    this.fetchParticipants = this.fetchParticipants.bind(this);

    this.state = {
      loaded: false,
      value: [],
    };
  }

  componentDidMount() {
    this.fetchParticipants = this.fetchParticipants.bind(this);

    this.setState({
      loaded: false,
      value: [],
      selected: this.props.selected ? this.props.selected : [],
      searchFor: "",
    });

    this.fetchParticipants();
  }

  fetchParticipants() {
    let _this = this;

    let url = "/meeting/{id}/participants";

    if (MeetingFunctions.isTraining()) {
      url = "/event/training/videoConference/{id}/participants";
    }

    $f.fetch(
      url,
      "GET",
      null,
      {},
      {
        id: () => {
          return $f.getQueryPath(2).replace("m-", "").replace("v-", "");
        },
      },
      (resp) => {
        resp.data.data[0].guestUsers?.forEach((el) => {
          resp.data.data[0].participants.push({
            email: el,
            firstName: "Guest",
            lastName: "User",
          });
        });

        _this.setState({
          loaded: true,
          value: [...resp.data.data[0].participants],
        });
      }
    );
  }

  render() {
    if (this.state.loaded) {
      let _this = this;
      return (
        <div>
          <div className="mb-3 mt-3">
            <div className="form-group ">
              <input
                type="text"
                value={this.state.searchFor}
                className="form-control"
                placeholder="Search to add"
                onChange={(e) => {
                  this.setState({ searchFor: e.target.value });
                }}
              ></input>
            </div>
          </div>
          {this.state.value
            ?.filter((el) => {
              return (
                el.firstName
                  .toString()
                  .toLowerCase()
                  .includes(this.state.searchFor.toLowerCase()) ||
                el.lastName
                  .toString()
                  .toLowerCase()
                  .includes(this.state.searchFor.toLowerCase())
              );
            })
            .map((el) => {
              return (
                <label
                  className="p-3 rounded border mb-3 a-hover-grey w-100"
                  role="button"
                >
                  <div className="d-flex">
                    <span className="d-block w-100">
                      {$c.createAvatar(
                        el.profileImage,
                        el.firstName,
                        el.lastName
                      )}
                    </span>
                    <Checkbox
                      onChange={(checked) => {
                        let selected = [..._this.state.selected];

                        if (selected.indexOf(el._id) >= 0) {
                          if (!checked) {
                            selected.splice(selected.indexOf(el._id), 1);
                          } else {
                            selected.push(el._id);
                          }
                        } else {
                          selected.push(el._id);
                        }

                        _this.setState({ selected: selected });
                      }}
                      checked={
                        this.state.selected.indexOf(el._id) >= 0 ? true : false
                      }
                      className="checkbox"
                      icon={
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            backgroundColor: true ? "#4e2c95" : "#fff",
                            alignSelf: "stretch",
                          }}
                        >
                          <Icon.FiCheck color="white" size={20} />
                        </div>
                      }
                      borderColor={"#4e2c95"}
                      borderRadius={20}
                      style={{
                        overflow: "hidden",
                        backgroundColor: "#fff",
                      }}
                      size={23}
                    />
                  </div>
                </label>
              );
            })}
        </div>
      );
    }

    return "";
  }
}

export default AssignParticipants;
