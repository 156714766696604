import { Component } from "react";
import $c from "../../modules/functions";
import $f from "../../src";
import GeneralSearch from "../../types/generalsearch/generalsearch";
import Profile from "../../types/profile/profile";

import "./css/style.css";
import FileUploader from "./fileUploader";

import ModalWidget from "./modalwidget";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      middle: null,
      topmenu: null,
      leftmenu: null,
      fullpage: null,
      meeting: null,
    };
  }

  componentDidMount() {
    this.getCompanyInfo = this.getCompanyInfo.bind(this);

    if (!this.state.meeting && !this.state.fullpage) {
      document.body.style.paddingTop = "6.7rem";
      document.body.style.backgroundColor = "unset";
    }

    this.getCompanyInfo();
  }

  getCompanyInfo() {
    let _this = this;
    $f.fetch("/basicCompanyInfo ", "GET", null, {}, {}, (resp) => {
      try {
        const companyInfo = resp.data.data[0];

        window["COMPANY_INFO"] = () => {
          return { ...companyInfo };
        };

        window["COMPANY_LOGO"] = () => {
          return (
            <div className="d-flex">
              <div>
                <img
                  src={companyInfo.logo}
                  alt={companyInfo.name}
                  className="me-2 "
                  style={{ height: "2.2rem" }}
                  role="button"
                  onClick={() => {
                    window.location.replace("/");
                  }}
                ></img>
                <span className="display-lt-md-none">{companyInfo.name}</span>
              </div>
            </div>
          );
        };

        window["COMPANY_NAME_ONLY"] = () => {
          return companyInfo.name;
        };

        window["COMPANY_LOGO_ONLY"] = () => {
          return (
            <img
              src={companyInfo.logo}
              alt={companyInfo.name}
              className="me-2 "
              style={{ height: "2.2rem" }}
              role="button"
              onClick={() => {
                window.location.replace("/");
              }}
            ></img>
          );
        };

        window["COMPANY_NAME"] = () => {
          return (
            <div className="d-flex">
              <div>
                <img
                  src={companyInfo.logo}
                  alt={companyInfo.name}
                  className="me-2 "
                  style={{ height: "2.2rem" }}
                  role="button"
                  onClick={() => {
                    window.location.replace("/");
                  }}
                ></img>
                <span className="">{companyInfo.name}</span>
              </div>
              {/* <div
                className="ms-auto mt-auto mb-auto"
                role="button"
                onClick={() => {
                  $c.logout();
                }}
              >
                Logout
              </div> */}
            </div>
          );
        };
        _this.setState({ loaded: true });
      } catch (e) {}
    });
  }

  render() {
    if (this.state.loaded) {
      if (this.state.meeting) {
        return this.state.meeting;
      }

      return this.state.fullpage ? (
        <>
          {this.state.fullpage}
          <ModalWidget
            ref={(refComponent) => {
              window["primaryModal"] = refComponent;
            }}
          ></ModalWidget>
          <ModalWidget
            ref={(refComponent) => {
              window["secondaryModal"] = refComponent;
            }}
          ></ModalWidget>
        </>
      ) : (
        <>
          <header className="fixed-top">
            <div className="row viewport-padding bg-light-grey">
              <div className="col-4">
                <nav className="navbar navbar-expand-md  top-navbar">
                  <div className="container-fluid   color-strong-grey">
                    <div className="display-5 mb-0">
                      {window["COMPANY_LOGO"]()}
                    </div>
                  </div>
                </nav>
              </div>
              <div className="col-4 m-auto">
                <GeneralSearch></GeneralSearch>
              </div>
              <div className="col-4 m-auto text-end">
                <Profile></Profile>
              </div>
            </div>

            {this.state.topmenu}
          </header>
          <main
            className="container-fluid viewport-padding pt-2125rem"
            style={{ minΗeight: "100%" }}
          >
            <div className="wrapper d-flex">
              {this.state.leftmenu}
              <div
                className={
                  "page-content-wrapper " + (this.state.leftmenu ? "" : "ms-0")
                }
              >
                {this.state.middle}
              </div>
            </div>
          </main>
          <ModalWidget
            ref={(refComponent) => {
              window["primaryModal"] = refComponent;
            }}
          ></ModalWidget>
          <ModalWidget
            ref={(refComponent) => {
              window["secondaryModal"] = refComponent;
            }}
          ></ModalWidget>
          <FileUploader
            ref={(refComponent) => {
              window["fileUploader"] = refComponent;
            }}
          ></FileUploader>
        </>
      );
    }

    return "";
  }
}

export default Index;
