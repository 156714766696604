import $f from "../../src";

const Actions = {
  forms: {
    create: function (ref) {
      const refValue = $f.getRefValues(ref);
      if ($f.validateRefValues(ref)) {
        const questions = [];

        refValue.questions.value.forEach((el) => {
          delete el._id;
          questions.push(el);
        });

        const postData = {
          title: refValue.title.value,
          instructions: refValue.instructions.value,
          questions: questions,
          isAnonymous: refValue.formoptions.value.isAnonymous,
          type: refValue.formoptions.value.type,
        };

        $f.fetch("/forms", "POST", postData, {}, {}, (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, true);
        });
      } else {
        $f.createNotification({
          type: "danger",
          message: "You have at least one error in the form.",
        });
      }
    },
    update: function (ref) {
      const refValue = $f.getRefValues(ref);
      if ($f.validateRefValues(ref)) {
        const postData = {
          _id: refValue._id.value,
          title: refValue.title.value,
          instructions: refValue.instructions.value,
          questions: refValue.questions.value,
          isAnonymous: refValue.formoptions.value.isAnonymous,
          type: refValue.formoptions.value.type,
        };

        $f.fetch("/forms", "PUT", postData, {}, {}, (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, true);
        });
      } else {
        $f.createNotification({
          type: "danger",
          message: "You have at least one error in the form.",
        });
      }
    },
    delete: function (id, callback) {
      $f.fetch(
        "/forms/{id}",
        "DELETE",
        [],
        {},
        {
          id: () => {
            return id;
          },
        },
        (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, false);
          if (callback) {
            callback();
          }
        }
      );
    },
  },
  meetings: {
    create: function (ref) {
      const refValue = $f.getRefValues(ref);

      if ($f.validateRefValues(ref)) {
        const organizers = [];

        refValue.organizers?.value.forEach((el) => {
          organizers.push(el._id ? el._id : el.id);
        });

        const files = [];

        refValue.files?.value.forEach((el) => {
          files.push(el._id ? el._id : el.id);
        });

        const participants = [];

        refValue.participants?.value.forEach((el) => {
          participants.push({
            _id: el._id ? el._id : el.id,
            type: el.type ? el.type : "participant",
          });
        });

        const postData = {
          title: refValue.title.value,
          description: refValue.description.value,
          from: refValue.fromtoDatetime.value.from,
          to: refValue.fromtoDatetime.value.to,
          files: files,
          participants: participants,
          organizers: organizers,
          options: refValue.recordingOptions.value,
          reminders: refValue.reminder.value,
          duration: 29040,
        };

        $f.fetch("/meeting", "POST", postData, {}, {}, (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, true);
        });
      } else {
        $f.createNotification({
          type: "danger",
          message: "You have at least one error in the meeting form.",
        });
      }
    },
    update: function (ref) {
      const refValue = $f.getRefValues(ref);

      if ($f.validateRefValues(ref)) {
        const organizers = [];

        refValue.organizers?.value.forEach((el) => {
          organizers.push(el._id ? el._id : el.id);
        });
        const postData = {
          _id: refValue._id.value,
          title: refValue.title.value,
          description: refValue.description.value,
          from: refValue.fromtoDatetime.value.from,
          to: refValue.fromtoDatetime.value.to,
          files: refValue.files.value ? refValue.files.value : [],
          organizers: organizers,
          options: refValue.recordingOptions.value,
          reminders: refValue.reminder.value,
          duration: 29040,
        };

        $f.fetch("/meeting", "PATCH", postData, {}, {}, (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, true);
        });
      } else {
        $f.createNotification({
          type: "danger",
          message: "You have at least one error in the meeting form.",
        });
      }
    },
    delete: function (id, callback) {
      $f.fetch(
        "/meeting/{id}",
        "DELETE",
        [],
        {},
        {
          id: () => {
            return id;
          },
        },
        (resp) => {
          if (callback) {
            $f.createNotificationFromResponse(resp.data, false, true, false);
            callback();
          } else {
            $f.createNotificationFromResponse(resp.data, false, true, true);
          }
        }
      );
    },
  },
  trainings: {
    create: function (ref) {
      const refValue = $f.getRefValues(ref);
      if ($f.validateRefValues(ref)) {
        refValue.modules.value?.forEach((module) => {
          module?.sessions?.forEach((session) => {
            let instructors = [];

            session.instructors?.forEach((el) => {
              if (el) {
                instructors.push(el._id ? el._id : el.id);
              }
            });

            session?.exam?.forEach((exam) => {
              let evaluators = [];

              delete exam.updatedAt;
              delete exam.createdAt;
              delete exam.fromTemplate;
              delete exam.owner;

              exam?.evaluators?.forEach((evaluator) => {
                if (evaluator) {
                  if (!exam.training) {
                    evaluator._id = evaluator._id
                      ? evaluator._id
                      : evaluator.id;
                    delete evaluator.id;
                    evaluators.push(evaluator);
                  } else {
                    evaluators.push(
                      evaluator.id ? evaluator.id : evaluator._id
                    );
                  }
                }
              });

              exam.evaluators = evaluators;
            });

            session.instructors = instructors;
          });
        });

        const participants = [];

        refValue.participants?.value.forEach((el) => {
          participants.push({ _id: el.id, type: "participant" });
        });

        const postData = {
          title: refValue.title.value,
          description: refValue.description.value,
          participants: participants,
          from: refValue.fromtodate.value.from,
          to: refValue.fromtodate.value.to,
          modules: refValue.modules.value,
          options: refValue.recordingOptions.value,
        };

        $f.fetch("/event/training", "POST", postData, {}, {}, (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, true);
        });
      } else {
        $f.createNotification({
          type: "danger",
          message: "You have at least one error in the training form.",
        });
      }
    },
    update: function (ref) {
      const refValue = $f.getRefValues(ref);

      if ($f.validateRefValues(ref)) {
        refValue.modules.value?.forEach((module) => {
          module?.sessions?.forEach((session) => {
            let instructors = [];

            session.instructors?.forEach((el) => {
              if (el) {
                instructors.push(el._id ? el._id : el.id);
              }
            });

            session?.exam?.forEach((exam) => {
              let evaluators = [];

              delete exam.updatedAt;
              delete exam.createdAt;
              delete exam.fromTemplate;
              delete exam.owner;

              exam.evaluators?.forEach((evaluator) => {
                if (evaluator) {
                  if (!exam.training) {
                    evaluator._id = evaluator._id
                      ? evaluator._id
                      : evaluator.id;
                    delete evaluator.id;
                    evaluators.push(evaluator);
                  } else {
                    evaluators.push(
                      evaluator.id ? evaluator.id : evaluator._id
                    );
                  }
                }
              });

              exam.evaluators = evaluators;
            });

            session.instructors = instructors;
          });
        });

        const postData = {
          _id: refValue._id.value,
          title: refValue.title.value,
          description: refValue.description.value,
          from: refValue.fromtodate.value.from,
          to: refValue.fromtodate.value.to,
          modules: refValue.modules.value,
          options: refValue.recordingOptions.value,
        };

        $f.fetch("/event/training", "PATCH", postData, {}, {}, (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, true);
        });
      } else {
        $f.createNotification({
          type: "danger",
          message: "You have at least one error in the training form.",
        });
      }
    },
    delete: function (id) {
      $f.fetch(
        "/event/training/{id}",
        "DELETE",
        [],
        {},
        {
          id: () => {
            return id;
          },
        },
        (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, true);
        }
      );
    },
  },
  tests: {
    create: function (ref) {
      const refValue = $f.getRefValues(ref);

      if ($f.validateRefValues(ref)) {
        const participants = [];

        refValue.participants?.value.forEach((el) => {
          participants.push({ _id: el.id, type: "participant" });
        });

        const evaluators = [];

        refValue.evaluators?.value.forEach((el) => {
          evaluators.push({ _id: el.id, type: "instructor" });
        });

        const questions = [];

        refValue.questions.value.forEach((el) => {
          delete el._id;
          questions.push(el);
        });

        const postData = {
          title: refValue.title.value,
          instructions: refValue.instructions.value,
          from: refValue.fromtoDatetime.value.from,
          to: refValue.fromtoDatetime.value.to,
          reminders: refValue.reminder.value,
          examDuration: refValue.examDuration.value,
          evaluators: evaluators,
          participants: participants,
          questions: questions,
          passingGrade: refValue.passingGradeEdit.value,
        };

        $f.fetch("/event/exam", "POST", postData, {}, {}, (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, true);
        });
      } else {
        $f.createNotification({
          type: "danger",
          message: "You have at least one error in the test form.",
        });
      }
    },
    update: function (ref) {
      const refValue = $f.getRefValues(ref);

      if ($f.validateRefValues(ref)) {
        const participants = [];

        refValue.participants?.value.forEach((el) => {
          participants.push({
            _id: el._id ? el._id : el.id,
            type: "participant",
          });
        });

        const evaluators = [];

        refValue.evaluators?.value.forEach((el) => {
          evaluators.push({ _id: el._id ? el._id : el.id, type: "instructor" });
        });

        const postData = {
          _id: refValue._id.value,
          title: refValue.title.value,
          instructions: refValue.instructions.value,
          from: refValue.fromtoDatetime.value.from,
          to: refValue.fromtoDatetime.value.to,
          reminders: refValue.reminder.value,
          examDuration: refValue.examDuration.value,
          evaluators: evaluators,
          questions: refValue.questions.value,
          passingGrade: refValue.passingGradeEdit.value,
        };

        $f.fetch("/event/exam", "PATCH", postData, {}, {}, (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, true);
        });
      } else {
        $f.createNotification({
          type: "danger",
          message: "You have at least one error in the test form.",
        });
      }
    },
    delete: function (id, callback) {
      $f.fetch(
        "/event/exam/{id}",
        "DELETE",
        [],
        {},
        {
          id: () => {
            return id;
          },
        },
        (resp) => {
          if (callback) {
            $f.createNotificationFromResponse(resp.data, false, true, false);
            window.location.replace("/events/tests");
          } else {
            $f.createNotificationFromResponse(resp.data, false, true, true);
          }
        }
      );
    },
    duplicate: function (data) {
      let postData = { ...data };
      delete postData._id;
      postData.evaluators = [];
      postData.participants = [];
      postData.title = "Copy of ".concat(postData.title);

      postData.questions.forEach((el) => {
        delete el._id;
        el.answers.forEach((ans) => {
          delete ans._id;
        });
      });

      postData.reminders.forEach((el) => {
        delete el._id;
      });

      $f.fetch("/event/exam", "POST", postData, {}, {}, (resp) => {
        $f.createNotificationFromResponse(resp.data, false, true, true);
      });
    },
  },
  testTemplates: {
    create: function (ref) {
      const refValue = $f.getRefValues(ref);

      if ($f.validateRefValues(ref)) {
        const postData = {
          title: refValue.title.value,
          instructions: refValue.instructions.value,
          questions: refValue.questions.value,
          passingGrade: refValue.passingGradeEdit.value.toString(),
        };

        $f.fetch("/template/exam", "POST", postData, {}, {}, (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, true);
        });
      } else {
        $f.createNotification({
          type: "danger",
          message: "You have at least one error in the test form.",
        });
      }
    },
    duplicate: function (data) {
      let postData = { ...data };

      delete postData._id;
      postData.title = "Copy of ".concat(postData.title);

      postData.questions.forEach((el) => {
        delete el._id;
        el.answers.forEach((ans) => {
          delete ans._id;
        });
      });

      $f.fetch("/template/exam", "POST", postData, {}, {}, (resp) => {
        $f.createNotificationFromResponse(resp.data, false, true, true);
      });
    },
    update: function (ref) {
      const refValue = $f.getRefValues(ref);

      if ($f.validateRefValues(ref)) {
        const postData = {
          _id: refValue._id.value,
          title: refValue.title.value,
          instructions: refValue.instructions.value,
          questions: refValue.questions.value,
          passingGrade: refValue.passingGradeEdit.value.toString(),
        };

        $f.fetch("/template/exam", "PATCH", postData, {}, {}, (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, true);
        });
      } else {
        $f.createNotification({
          type: "danger",
          message: "You have at least one error in the test form.",
        });
      }
    },
    delete: function (id) {
      $f.fetch(
        "/template/exam/{id}",
        "DELETE",
        [],
        {},
        {
          id: () => {
            return id;
          },
        },
        (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, true);
        }
      );
    },
  },
  trainingTemplates: {
    create: function (ref) {
      const refValue = $f.getRefValues(ref);

      if ($f.validateRefValues(ref)) {
        const postData = {
          title: refValue.title.value,
          description: refValue.description.value,
          modules: refValue.modules.value,
        };
        $f.fetch("/template/training", "POST", postData, {}, {}, (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, true);
        });
      } else {
        $f.createNotification({
          type: "danger",
          message: "You have at least one error in the training form.",
        });
      }
    },
    fetchExamTemplateObject: function (ref) {
      const refValue = $f.getRefValues(ref);

      if ($f.validateRefValues(ref)) {
        let examObject = {};

        if (refValue.fromTemplate?.value) {
          examObject = {
            title: refValue.titleReadonly.value,
            from: refValue.fromtoDatetime.value.from,
            to: refValue.fromtoDatetime.value.to,
            reminders: refValue.reminder.value,
            examDuration: refValue.examDuration.value,
            evaluators: refValue.evaluators.value,
            participants: [],
          };
        } else {
          examObject = {
            title: refValue.title.value,
            instructions: refValue.instructions.value,
            from: refValue.fromtoDatetime.value.from,
            to: refValue.fromtoDatetime.value.to,
            reminders: refValue.reminder.value,
            examDuration: refValue.examDuration.value,
            evaluators: refValue.evaluators.value,
            participants: [],
            questions: refValue.questions.value,
            passingGrade: refValue.passingGradeEdit.value,
          };
        }

        if (refValue._id) {
          examObject["_id"] = refValue._id.value;
        }
        return examObject;
      } else {
        $f.createNotification({
          type: "danger",
          message: "You have at least one error in the test form.",
        });
      }
      return null;
    },
    duplicate: function (data) {
      let postData = { ...data };

      delete postData._id;
      postData.title = "Copy of ".concat(postData.title);

      postData.modules.forEach((el) => {
        delete el._id;
      });

      $f.fetch("/template/training", "POST", postData, {}, {}, (resp) => {
        $f.createNotificationFromResponse(resp.data, false, true, true);
      });
    },
    update: function (ref) {
      const refValue = $f.getRefValues(ref);

      if ($f.validateRefValues(ref)) {
        const postData = {
          _id: refValue._id.value,
          title: refValue.title.value,
          description: refValue.description.value,
          modules: refValue.modules.value,
        };
        $f.fetch("/template/training", "PATCH", postData, {}, {}, (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, true);
        });
      } else {
        $f.createNotification({
          type: "danger",
          message: "You have at least one error in the training form.",
        });
      }
    },
    delete: function (id) {
      $f.fetch(
        "/template/training/{id}",
        "DELETE",
        [],
        {},
        {
          id: () => {
            return id;
          },
        },
        (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, true);
        }
      );
    },
  },
  conferencePartners: {
    create: function (ref) {
      const refValue = $f.getRefValues(ref);

      if ($f.validateRefValues(ref)) {
        let logo = "";
        try {
          logo = refValue.logo.value[0]._id;
        } catch (e) {}

        const postData = {
          title: refValue.title.value,
          category: refValue.category.value,
          description: refValue.description.value,
          logo: logo,
          link: refValue.link.value,
        };

        $f.fetch("/sponsor", "POST", postData, {}, {}, (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, true);
        });
      } else {
        $f.createNotification({
          type: "danger",
          message: "You have at least one error in the sponsors form.",
        });
      }
    },
    update: function (ref) {
      const refValue = $f.getRefValues(ref);

      if ($f.validateRefValues(ref)) {
        let logo = "";
        try {
          logo = refValue.logo.value[0]._id;
        } catch (e) {}

        const postData = {
          _id: refValue._id.value,
          title: refValue.title.value,
          category: refValue.category.value,
          description: refValue.description.value,
          logo: logo,
          link: refValue.link.value,
        };

        $f.fetch("/sponsor", "PUT", postData, {}, {}, (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, true);
        });
      } else {
        $f.createNotification({
          type: "danger",
          message: "You have at least one error in the sponsors form.",
        });
      }
    },
    delete: function (id) {
      $f.fetch(
        "/sponsors/{id}",
        "DELETE",
        { _id: id },
        {},
        {
          id: () => {
            return id;
          },
        },
        (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, true);
        }
      );
    },
  },
  conferences: {
    create: function (ref) {
      const refValue = $f.getRefValues(ref);

      if ($f.validateRefValues(ref)) {
        const hosts = [];

        refValue.hosts?.value.forEach((el) => {
          hosts.push(el._id ? el._id : el.id);
        });

        refValue.days.value?.forEach((module) => {
          module?.sessions?.forEach((session) => {
            let speakers = [];

            session.speakers?.forEach((el) => {
              speakers.push(el._id ? el._id : el.id);
            });

            session.speakers = speakers;
          });
        });

        const sponsors = [];
        refValue.sponsors.value?.forEach((el) => {
          sponsors.push(el._id ? el._id : el.id);
        });

        const participants = [];

        refValue.participants?.value.forEach((el) => {
          participants.push({ _id: el.id, type: "participant" });
        });

        let postData = {
          title: refValue.title.value,
          sponsors: sponsors,
          shortDescription: refValue.shortDescription.value,
          description: refValue.description.value,
          pollEvents: [],
          participants: participants,
          hosts: hosts,
          eventType: refValue.eventType.value,
          days: refValue.days.value,
        };

        if (refValue.banner.value[0]) {
          postData["banner"] = refValue.banner.value[0]._id;
        }

        $f.fetch("/stream", "POST", postData, {}, {}, (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, true);
        });
      } else {
        $f.createNotification({
          type: "danger",
          message: "You have at least one error in the conference form.",
        });
      }
    },
    update: function (ref) {
      const refValue = $f.getRefValues(ref);

      if ($f.validateRefValues(ref)) {
        const hosts = [];

        refValue.hosts?.value.forEach((el) => {
          hosts.push(el._id ? el._id : el.id);
        });

        refValue.days.value?.forEach((module) => {
          module?.sessions?.forEach((session) => {
            let speakers = [];

            session.speakers?.forEach((el) => {
              speakers.push(el._id ? el._id : el.id);
            });

            session.speakers = speakers;
          });
        });

        const sponsors = [];
        refValue.sponsors.value?.forEach((el) => {
          sponsors.push(el._id ? el._id : el.id);
        });

        let postData = {
          _id: refValue._id.value,
          title: refValue.title.value,
          sponsors: sponsors,
          description: refValue.description.value,
          shortDescription: refValue.shortDescription.value,
          pollEvents: [],
          hosts: hosts,
          banner: refValue.banner.value[0]
            ? refValue.banner.value[0]._id
            : null,
          eventType: refValue.eventType.value,
          days: refValue.days.value,
        };

        if (refValue.banner.value[0]) {
          postData["banner"] = refValue.banner.value[0]._id;
        }

        $f.fetch("/stream", "PUT", postData, {}, {}, (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, true);
        });
      } else {
        $f.createNotification({
          type: "danger",
          message: "You have at least one error in the conference form.",
        });
      }
    },
    delete: function (id) {
      $f.fetch(
        "/stream/{id}",
        "DELETE",
        [],
        {},
        {
          id: () => {
            return id;
          },
        },
        (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, true);
        }
      );
    },
    askQuestion: function (ref, args) {
      const refValue = $f.getRefValues(ref);

      if ($f.validateRefValues(ref)) {
        let postData = {
          questionBody: refValue.questionBody.value,
        };

        $f.fetch(
          "/stream/{conferenceId}/day/{dayId}/session/{sessionId}/questionAnswer",
          "POST",
          postData,
          {},
          args.replace,
          (resp) => {
            if (args.callback) {
              args.callback();
            }
            $f.createNotificationFromResponse(resp.data, false, true, false);
          }
        );
      }
    },
  },
  polls: {
    create: function (ref, args) {
      const refValue = $f.getRefValues(ref);

      if ($f.validateRefValues(ref)) {
        const postData = {
          title: refValue.title.value,
          question: refValue.question.value,
        };

        $f.fetch(
          "/streams/{conferenceId}/day/{dayId}/session/{sessionId}/pollAnswer",
          "POST",
          postData,
          {},
          args.replace,
          (resp) => {
            if (args.callback) {
              args.callback();
            }
            $f.createNotificationFromResponse(resp.data, false, true, false);
          }
        );
      } else {
        $f.createNotification({
          type: "danger",
          message: "You have at least one error in the poll form.",
        });
      }
    },
    update: function (ref, args) {
      const refValue = $f.getRefValues(ref);

      if ($f.validateRefValues(ref)) {
        const postData = {
          _id: refValue._id.value,
          title: refValue.title.value,
          question: refValue.question.value,
        };

        $f.fetch(
          "/streams/{conferenceId}/day/{dayId}/session/{sessionId}/pollAnswer",
          "PUT",
          postData,
          {},
          args.replace,
          (resp) => {
            if (args.callback) {
              args.callback();
            }
            $f.createNotificationFromResponse(resp.data, false, true, false);
          }
        );
      } else {
        $f.createNotification({
          type: "danger",
          message: "You have at least one error in the poll form.",
        });
      }
    },
    delete: function (args) {
      $f.fetch(
        "/streams/{conferenceId}/day/{dayId}/session/{sessionId}/pollAnswer/{pollId}",
        "DELETE",
        [],
        {},
        args.replace,
        (resp) => {
          if (args.callback) {
            args.callback();
          }
          $f.createNotificationFromResponse(resp.data, false, true, false);
        }
      );
    },
    start: function (args) {
      $f.fetch(
        "/streams/{conferenceId}/day/{dayId}/session/{sessionId}/pollAnswer/{pollId}/startPoll",
        "PATCH",
        [],
        {},
        args.replace,
        (resp) => {
          if (args.callback) {
            args.callback();
          }
          $f.createNotificationFromResponse(resp.data, false, true, false);
        }
      );
    },
    end: function (args) {
      $f.fetch(
        "/streams/{conferenceId}/day/{dayId}/session/{sessionId}/pollAnswer/{pollId}/endPoll",
        "PATCH",
        [],
        {},
        args.replace,
        (resp) => {
          if (args.callback) {
            args.callback();
          }
          $f.createNotificationFromResponse(resp.data, false, true, false);
        }
      );
    },
    vote: function (args) {
      if (args.answerId) {
        $f.fetch(
          "/streams/{conferenceId}/day/{dayId}/session/{sessionId}/pollAnswer/{pollId}/vote",
          "PATCH",
          {
            answerId: args.answerId,
          },
          {},
          args.replace,
          (resp) => {
            if (args.callback) {
              args.callback();
            }
            $f.createNotificationFromResponse(resp.data, false, false, false);
          }
        );
      }
    },
  },
  files: {
    uploadFile: function () {},
    createFolder: function (ref) {
      const refValue = $f.getRefValues(ref);

      if ($f.validateRefValues(ref)) {
        const postData = {
          name: refValue.name.value,
          description: refValue.description.value,
          parent: refValue.parent.value,
        };

        $f.fetch("/folder", "POST", postData, {}, {}, (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, true);
        });
      } else {
        $f.createNotification({
          type: "danger",
          message: "You have at least one error in the form.",
        });
      }
    },
    addToFavorites: function (id, type) {
      let url = "/{type}/{id}/favorite";

      $f.fetch(
        url,
        "PATCH",
        null,
        {},
        {
          id: () => {
            return id;
          },
          type: () => {
            return type;
          },
        },
        (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, true);
        }
      );
    },
    removeFromFavorites: function (id, type) {
      let url = "/{type}/{id}/unfavorite";

      $f.fetch(
        url,
        "PATCH",
        null,
        {},
        {
          id: () => {
            return id;
          },
          type: () => {
            return type;
          },
        },
        (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, true);
        }
      );
    },
    update: function (ref) {
      const refValue = $f.getRefValues(ref);
      const keywords = [];
      refValue.keywords?.value.forEach((el) => {
        keywords.push(el.name ? el.name : el);
      });

      const authors = [];
      refValue.authors?.value.forEach((el) => {
        authors.push(el.name ? el.name : el);
      });

      if ($f.validateRefValues(ref)) {
        const postData = {
          _id: refValue._id.value,
          title: refValue.title.value,
          description: refValue.description.value,
          keywords: keywords,
          folder: refValue.folder.value,
          authors: authors,
        };

        $f.fetch("/file", "PATCH", postData, {}, {}, (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, true);
        });
      } else {
        $f.createNotification({
          type: "danger",
          message: "You have at least one error in the form.",
        });
      }
    },
    updateAgendaFile: function (ref) {
      const refValue = $f.getRefValues(ref);
      const keywords = [];
      refValue.keywords?.value.forEach((el) => {
        keywords.push(el.name ? el.name : el);
      });

      const authors = [];
      refValue.authors?.value.forEach((el) => {
        authors.push(el.name ? el.name : el);
      });

      if ($f.validateRefValues(ref)) {
        const postData = {
          _id: refValue._id.value,
          title: refValue.title.value,
          description: refValue.description.value,
        };

        $f.fetch("/agenda-file", "PATCH", postData, {}, {}, (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, true);
        });
      } else {
        $f.createNotification({
          type: "danger",
          message: "You have at least one error in the form.",
        });
      }
    },
    deleteFile: function (id) {
      $f.fetch(
        "/file/{id}",
        "DELETE",
        [],
        {},
        {
          id: () => {
            return id;
          },
        },
        (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, true);
        }
      );
    },
    deleteAgendaFile: function (id, type, sectionId, eventId) {
      $f.fetch(
        "/agenda-file/{id}/{type}",
        "DELETE",
        { [sectionId]: eventId },
        {},
        {
          id: () => {
            return id;
          },
          type: () => {
            return type;
          },
        },
        (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, true);
        }
      );
    },
    deleteFolder: function (id) {
      $f.fetch(
        "/folder/{id}",
        "DELETE",
        [],
        {},
        {
          id: () => {
            return id;
          },
        },
        (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, true);
        }
      );
    },
  },
  participants: {
    create: function () {},
    update: function () {},
    delete: function () {},
    removeUser: function (
      type,
      sectionId,
      participantId,
      participantsProperty
    ) {
      $f.fetch(
        "/{type}/{sectionId}/participants",
        "DELETE",
        { [participantsProperty]: [participantId] },
        {},
        {
          sectionId: () => {
            return sectionId;
          },
          type: () => {
            return type;
          },
        },
        (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, true);
        }
      );
    },
    addParticipant: function (type, id, section, ref) {
      let participants = [];

      const refValue = $f.getRefValues(ref);

      if ($f.validateRefValues(ref)) {
        refValue.participants?.value.forEach((el) => {
          participants.push({
            _id: el._id ? el._id : el.id,
            type: el.type ? el.type : "participant",
          });
        });

        refValue.participantsAll?.value.forEach((el) => {
          participants.push({
            _id: el._id ? el._id : el.id,
            type: el.type ? el.type : "participant",
          });
        });

        refValue.participantsAllAndExternals?.value.forEach((el) => {
          participants.push({
            _id: el._id ? el._id : el.id,
            type: el.type ? el.type : "participant",
          });
        });

        let url = "/{type}/{id}/participants";

        switch (type) {
          case "formsInvitation":
            url = "/forms/{id}/send-invites";
            section = "users";
            break;
        }

        $f.fetch(
          url,
          "POST",
          { [section]: participants },
          {},
          {
            id: () => {
              return id;
            },
            type: () => {
              return type;
            },
          },
          (resp) => {
            $f.createNotificationFromResponse(resp.data, false, true, true);
          }
        );
      }
    },
  },
  contacts: {
    inviteManually: function (ref) {
      const refValue = $f.getRefValues(ref);

      if ($f.validateRefValues(ref)) {
        const postData = [];

        refValue.inviteContactsManually.value.forEach((el) => {
          let user = {
            firstName: el.firstName,
            lastName: el.lastName,
            email: el.email,
          };

          switch (el.tag) {
            case "instructor":
              user["instructor"] = true;
              user["host"] = false;
              user["manager"] = false;
              break;
            case "viewer":
              user["host"] = false;
              user["instructor"] = false;
              user["manager"] = true;
              break;

            case "host":
              user["instructor"] = false;
              user["host"] = true;
              user["manager"] = false;
              break;

            default:
              user["instructor"] = false;
              user["host"] = false;
              user["manager"] = false;
              break;
          }

          postData.push(user);
        });

        $f.fetch(
          "/auth/sendInvitation/manually",
          "POST",
          { users: postData },
          {},
          {},
          (resp) => {
            $f.createNotificationFromResponse(resp.data, false, true, true);
          }
        );
      } else {
        $f.createNotification({
          type: "danger",
          message: "You have at least one error in the form.",
        });
      }
    },
    inviteMultiple: function (ref) {
      const refValue = $f.getRefValues(ref);

      if ($f.validateRefValues(ref)) {
        document
          .getElementsByClassName("scheduleEventNextBtn")[0]
          .classList.add("disabled");

        const postData = {
          fileId: refValue.files.value[0]._id,
          fileKey: refValue.files.value[0].fileKey,
        };

        $f.fetch(
          "/auth/importInvitation/excel",
          "POST",
          postData,
          {},
          {},
          (resp) => {
            $f.createNotificationFromResponse(resp.data, false, true, true);
          }
        );
      } else {
        $f.createNotification({
          type: "danger",
          message: "You have at least one error in the form.",
        });
      }
    },
    inviteDirectly: function (ref) {
      const refValue = $f.getRefValues(ref);

      if ($f.validateRefValues(ref)) {
        document
          .getElementsByClassName("scheduleEventNextBtn")[0]
          .classList.add("disabled");

        const postData = {
          fileId: refValue.files.value[0]._id,
          fileKey: refValue.files.value[0].fileKey,
        };

        $f.fetch(
          "/auth/importUsers/excel",
          "POST",
          postData,
          {},
          {},
          (resp) => {
            $f.createNotificationFromResponse(resp.data, false, true, true);
          }
        );
      } else {
        $f.createNotification({
          type: "danger",
          message: "You have at least one error in the form.",
        });
      }
    },
    delete: function (id) {
      $f.fetch(
        "/user/{id}",
        "DELETE",
        [],
        {},
        {
          id: () => {
            return id;
          },
        },
        (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, true);
        }
      );
    },
    resendInvitation: function (email) {
      $f.fetch(
        "/auth/resendInvitation",
        "POST",
        { email: email },
        {},
        {},
        (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, true);
        }
      );
    },
    revokeInvitation: function (email) {
      $f.fetch(
        "/auth/revokeInvitation",
        "POST",
        { email: email },
        {},
        {},
        (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, true);
        }
      );
    },
    activateUser: function (id) {
      $f.fetch(
        "/user/{id}/activate",
        "PATCH",
        [],
        {},
        {
          id: () => {
            return id;
          },
        },
        (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, true);
        }
      );
    },
    deactivateUser: function (id) {
      $f.fetch(
        "/user/{id}/deactivate",
        "PATCH",
        [],
        {},
        {
          id: () => {
            return id;
          },
        },
        (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, true);
        }
      );
    },
  },
  spaces: {
    create: function (ref) {
      const refValue = $f.getRefValues(ref);

      if ($f.validateRefValues(ref)) {
        let image = null;
        try {
          image = refValue.image.value[0]._id;
        } catch (e) {}

        const postData = {
          name: refValue.name.value,
          description: refValue.description.value,
          image: image,
          parent: refValue.parent.value,
          rootSpace: refValue.rootSpace.value,
        };

        if (refValue.advanceFields) {
          const { websiteUrl, industry, market, moneyRaised } =
            refValue.advanceFields.value;

          postData["websiteUrl"] = websiteUrl;
          postData["industry"] = industry;
          postData["market"] = market;
          postData["moneyRaised"] = moneyRaised;
        }

        $f.fetch("/space", "POST", postData, {}, {}, (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, true);
        });
      } else {
        $f.createNotification({
          type: "danger",
          message: "You have at least one error in the form.",
        });
      }
    },
    update: function (ref) {
      const refValue = $f.getRefValues(ref);

      if ($f.validateRefValues(ref)) {
        let image = null;
        try {
          image = refValue.image.value[0]._id;
        } catch (e) {}

        const postData = {
          _id: refValue._id.value,
          name: refValue.name.value,
          description: refValue.description.value,
          image: image,
        };

        if (refValue.advanceFields) {
          const { websiteUrl, industry, market, moneyRaised } =
            refValue.advanceFields.value;

          postData["websiteUrl"] = websiteUrl;
          postData["industry"] = industry;
          postData["market"] = market;
          postData["moneyRaised"] = moneyRaised;
        }

        $f.fetch("/space", "PATCH", postData, {}, {}, (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, true);
        });
      } else {
        $f.createNotification({
          type: "danger",
          message: "You have at least one error in the  form.",
        });
      }
    },
    delete: function (id, callback) {
      $f.fetch(
        "/space/{id}",
        "DELETE",
        [],
        {},
        {
          id: () => {
            return id;
          },
        },
        (resp) => {
          $f.createNotificationFromResponse(resp.data, false, true, false);
          if (callback) {
            callback(resp);
          }
        }
      );
    },
  },
};

export default Actions;
