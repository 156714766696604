import { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import $f from "../../../src";
import GeneralSearch from "../../../types/generalsearch/generalsearch";

class TopMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    const { data, module, view } = this.props;

    this.setState({ loaded: true, data, module, view });
  }

  render() {
    if (this.state.loaded) {
      return (
        <nav
          className="navbar navbar-expand top-subnavbar border-bottom-1 bg-white scroll-x"
          draggable="false"
        >
          <div className="container-fluid viewport-padding " draggable="false">
            <ul className="navbar-nav" draggable="false">
              {this.state.data.map((itm) => {
                const active = itm.id.data.isActive() ? "active" : "";

                if (itm.id.data.hidden) {
                  if ($f.getValue(itm.id.data.hidden)) {
                    return "";
                  }
                }

                return (
                  <li className="nav-item" key={$f.key()}>
                    <Link
                      to={itm.url.value}
                      className={"nav-link ".concat(active)}
                    >
                      {itm.title.value}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </nav>
      );
    }

    return "";
  }
}

export default withRouter(TopMenu);
