import { Component } from "react";
import $f from "../../src";
import SimpleReactValidator from "simple-react-validator";

import {
  arrayMove,
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";

const SortableItem = sortableElement(({ value }) => (
  <li style={{ listStyle: "none" }}>{value}</li>
));

const DragHandle = sortableHandle(() => (
  <span className="sortableHandler me-2">
    <i className="fas fa-ellipsis-v"></i>
    <i className="fas fa-ellipsis-v"></i>
  </span>
));

const SortableContainer = sortableContainer(({ children }) => {
  return (
    <ul className="ps-0" style={{ listStyle: "none" }}>
      {children}
    </ul>
  );
});

class ConferencePolls extends Component {
  constructor(props) {
    super(props);

    this.updateData = this.updateData.bind(this);
    this.updateAnswerData = this.updateAnswerData.bind(this);
    this.deleteAnswer = this.deleteAnswer.bind(this);
    this.addAnswer = this.addAnswer.bind(this);
    this.editRender = this.editRender.bind(this);
    this.validate = this.validate.bind(this);

    this.validator = new SimpleReactValidator();

    this.state = {
      loaded: false,
    };
  }

  validate() {
    let isValid = this.validator.allValid();

    this.setState({ isValid });
    this.validator.showMessages();
    this.forceUpdate();

    return isValid;
  }

  componentDidMount() {
    let events = [];

    Object.keys(this.props.property.events)?.forEach((element, idx) => {
      if (!element.toLowerCase().startsWith("onchange")) {
        this[element] = this.props.property.events[element];
        this[element] = this[element].bind(this);
        events[element] = this[element];
      } else {
        this["customOnChange"] = { ...this.props.property.events[element] };
        this["customOnChange"] = this["customOnChange"].bind(this);
      }
    });

    this.setState({
      loaded: true,
      events: events,
      attributes: { ...this.props.property.attributes },
      value: this.props.value ? this.props.value : [],
      // value: [
      //   { text1: "text11", text2: "text21" },
      //   { text1: "text12", text2: "text22" },
      // ],
      editable: this.props.property.editable === false ? false : true,
      configuration: { ...this.props.property.configuration },
    });
  }

  updateData(property, data) {
    let value = { ...this.state.value };

    value[property] = data;

    this.setState({ value });
  }

  updateAnswerData(idx, property, data) {
    let value = { ...this.state.value };

    value.answers[idx][property] = data;

    this.setState({ value });
  }

  deleteAnswer(idx) {
    let value = { ...this.state.value };
    value.answers.splice(idx, 1);
    this.validator.purgeFields();
    this.setState({ value: value });
  }

  addAnswer() {
    const value = { ...this.state.value };

    value.answers.push({});

    this.setState({ value: value });
  }

  onReorderAnswers(args, qidx) {
    let value = { ...this.state.value };

    const answers = [...value.answers];

    let q = arrayMove(answers, args.oldIndex, args.newIndex);

    value.answers = q;

    this.setState({
      value: value,
    });
  }

  editRender() {
    const data = this.state.value;

    return (
      <div>
        <div className="mb-3">
          <div className="form-group mb-3">
            <label className="label-top">Question</label>

            <input
              type="text"
              value={data.text}
              className="form-control "
              placeholder="Type poll question"
              onChange={(e) => {
                this.updateData("text", e.target.value);
              }}
            ></input>
            <span className="text-danger mt-2 d-block">
              {this.validator.message(
                "question ",
                this.state.value.text,
                "required"
              )}
            </span>
          </div>
          <div className="form-group mb-3">
            <SortableContainer
              onSortEnd={(args) => {
                this.onReorderAnswers(args);
              }}
              pressDelay={50}
              helperClass="sortableHelper"
              useDragHandle
            >
              {data.answers?.map((ans, idx) => {
                const value = (
                  <div className="d-flex mb-2">
                    <div className=" mt-1">
                      <DragHandle />
                    </div>
                    <div className="me-2 mt-1">{idx + 1}</div>
                    <div className="me-2 question-answer">
                      <input
                        type="text"
                        value={ans.answer}
                        className="form-control "
                        placeholder="Type an answer"
                        onChange={(e) => {
                          this.updateAnswerData(idx, "answer", e.target.value);
                        }}
                      ></input>
                      <span className="text-danger mt-2 d-block">
                        {this.validator.message(
                          "answer ".concat(idx + 1),
                          this.state.value.answers[idx].answer,
                          "required"
                        )}
                      </span>
                    </div>
                    <div className="me-2"></div>
                    <div className="mt-1">
                      {data.answers.length > 2 ? (
                        <i
                          className="fas fa-trash-alt color-black"
                          onClick={() => {
                            this.deleteAnswer(idx);
                          }}
                          role="button"
                        ></i>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );

                return (
                  <SortableItem key={`item-${idx}`} index={idx} value={value} />
                );
              })}
            </SortableContainer>
            <div
              className="color-black"
              onClick={() => {
                this.addAnswer();
              }}
              role="button"
            >
              + Add answer
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.loaded) {
      return this.editRender();
    }

    return "";
  }
}

export default ConferencePolls;
