import $c from "../../../../modules/functions";
import $f from "../../../../src";
import ab2str from "arraybuffer-to-string";
import { getAttendeeInfo } from "./apis";
import { Link } from "react-router-dom";

const MeetingFunctions = {
  sendMessage: (meetingManager, topic, msg) => {
    meetingManager?.audioVideo?.realtimeSendDataMessage(topic, msg);
  },
  isRecordingUser: (attendeeName) => {
    if (attendeeName?.indexOf("MediaPipeline-") >= 0) {
      return true;
    }

    return false;
  },
  recording: (roster) => {
    let isRecording = false;

    Object.keys(roster).forEach((el) => {
      if (roster[el]?.externalUserId?.indexOf("MediaPipeline-") >= 0) {
        isRecording = true;
      }
    });

    return isRecording;
  },
  createRemoteVideoAvatar: (attendeeName, profileImageId, avatarClass) => {
    let profileImage =
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdj+P///38ACfsD/QVDRcoAAAAASUVORK5CYII=";

    if (attendeeName) {
      const fullName = attendeeName.replace("  ", " ").split(" ");

      const firstName = fullName[0];

      const lastName = fullName[2]
        ? fullName[1] + " " + fullName[2]
        : fullName[1];

      let initials = firstName.charAt(0).concat(lastName?.charAt(0));

      if (profileImageId) {
        profileImage = window["baseurl"](
          "/agenda-file/data/" + profileImageId
        ).concat("?type=preview&jwt=" + localStorage.getItem("jwt"));
        initials = "";
      }

      let customClasses = "";

      if (!avatarClass) {
        customClasses = "avatar-xl";
      } else {
        customClasses = avatarClass;
      }

      return (
        <div
          className={"d-inline-block avatar-wrapper  ".concat(customClasses)}
          style={{ lineHeight: "normal" }}
        >
          <div className="d-flex">
            <span>
              <img src={profileImage} alt={fullName} />
              <span>{initials}</span>
            </span>
          </div>
        </div>
      );
    }
    return "";
  },
  getAttendee: (roster, attendeeId) => {
    let splitPipe = roster[attendeeId]?.externalUserId?.toString().split("|");

    return roster[attendeeId]
      ? {
          chimeAttendeeId: roster[attendeeId].chimeAttendeeId,
          externalUserId: splitPipe[1] ? splitPipe[1] : splitPipe,
          name: splitPipe[0] ? splitPipe[0] : splitPipe,
        }
      : "";
  },

  getCurrentAttendee: (meetingManager) => {
    try {
      return meetingManager?.meetingSessionConfiguration?.credentials;
    } catch (e) {}
    return null;
  },
  returnToEventURL(meeting) {
    try {
      let _meeting = JSON.parse(meeting);

      if (_meeting.trainingId) {
        return "/event/training/".concat(_meeting.trainingId);
      } else {
        return "/event/meeting/".concat($f.getQueryPath(2).replace("m-", ""));
      }
    } catch (e) {}
    return "/";
  },
  receiveActionFromMessage(topic, meetingManager, msg, setIsVideoEnabled) {
    const rmsg = JSON.parse(ab2str(msg.data));

    if (rmsg.attendeeId !== $c.getUserId()) {
      if (topic === "ENDCALL") {
        let meeting =
          meetingManager.meetingSessionConfiguration.externalMeetingId;
        meetingManager.leave();
        window.location.replace(MeetingFunctions.returnToEventURL(meeting));
      }
    }
    if (
      rmsg.attendeeId ==
      MeetingFunctions.getCurrentAttendee(meetingManager)?.["attendeeId"]
    ) {
      try {
        switch (topic) {
          case "MUTE":
            meetingManager.audioVideo.realtimeMuteLocalAudio();
            break;

          case "UNMUTE":
            meetingManager.audioVideo.realtimeUnmuteLocalAudio();
            break;

          case "VIDEO_DEACTIVATE":
            meetingManager.audioVideo.stopLocalVideoTile();
            meetingManager.audioVideo.removeLocalVideoTile();
            setIsVideoEnabled(false);
            break;

          case "BANUNMUTE":
            meetingManager.audioVideo.realtimeMuteLocalAudio();
            meetingManager.audioVideo.realtimeSetCanUnmuteLocalAudio(false);

            break;

          case "KICK":
            let meeting =
              meetingManager.meetingSessionConfiguration.externalMeetingId;
            meetingManager.leave();
            window.location.replace(MeetingFunctions.returnToEventURL(meeting));

            break;
        }
      } catch (e) {}
    }
  },
  sendChatMessage: (meetingManager, msg) => {
    MeetingFunctions.sendMessage(meetingManager, "CHAT", msg);
  },
  muteAttendee: (meetingManager, attendeeId) => {
    const mess = {
      uuid: $f.key(),
      action: "MUTE",
      cmd: "TEXT",
      data: "MUTE",
      createdDate: new Date().getTime(),
      attendeeId: attendeeId,
    };

    MeetingFunctions.sendMessage(meetingManager, "MUTE", mess);
  },
  banUnmuteAttendee: (meetingManager, attendeeId) => {
    const mess = {
      uuid: $f.key(),
      action: "BANUNMUTE",
      cmd: "TEXT",
      data: "BANUNMUTE",
      createdDate: new Date().getTime(),
      attendeeId: attendeeId,
    };

    MeetingFunctions.sendMessage(meetingManager, "BANUNMUTE", mess);
  },
  kickAttendee: (meetingManager, attendeeId) => {
    const mess = {
      uuid: $f.key(),
      action: "KICK",
      cmd: "TEXT",
      data: "KICK",
      createdDate: new Date().getTime(),
      attendeeId: attendeeId,
    };
    MeetingFunctions.sendMessage(meetingManager, "KICK", mess);
  },
  endCall: (meetingManager) => {
    const mess = {
      uuid: $f.key(),
      action: "ENDCALL",
      cmd: "TEXT",
      data: "ENDCALL",
      createdDate: new Date().getTime(),
      attendeeId: $c.getUserId(),
    };
    MeetingFunctions.sendMessage(meetingManager, "ENDCALL", mess);
  },
  unMuteAttendee: (meetingManager, attendeeId) => {
    const mess = {
      uuid: $f.key(),
      action: "UNMUTE",
      cmd: "TEXT",
      data: "UNMUTE",
      createdDate: new Date().getTime(),
      attendeeId: attendeeId,
    };
    MeetingFunctions.sendMessage(meetingManager, "UNMUTE", mess);
  },
  deactivateAttendeeVideo: (meetingManager, attendeeId) => {
    const mess = {
      uuid: $f.key(),
      action: "VIDEO_DEACTIVATE",
      cmd: "TEXT",
      data: "VIDEO_DEACTIVATE",
      createdDate: new Date().getTime(),
      attendeeId: attendeeId,
    };
    MeetingFunctions.sendMessage(meetingManager, "VIDEO_DEACTIVATE", mess);
  },
  activateAttendeeVideo: (meetingManager, attendeeId) => {
    const mess = {
      uuid: $f.key(),
      action: "VIDEO_ACTIVATE",
      cmd: "TEXT",
      data: "VIDEO_ACTIVATE",
      createdDate: new Date().getTime(),
      attendeeId: attendeeId,
    };
    MeetingFunctions.sendMessage(meetingManager, "VIDEO_ACTIVATE", mess);
  },
  mute: (meetingManager) => {
    meetingManager.audioVideo.realtimeMuteLocalAudio();
  },
  unmute: (meetingManager) => {
    meetingManager.audioVideo.realtimeUnmuteLocalAudio();
  },
  isCurrentAttendee: (audioVideo, attendeeId) => {
    const localAttendeeId =
      audioVideo?.audioVideoController?.realtimeController?.state
        ?.localAttendeeId;

    return localAttendeeId == attendeeId;
  },
  isCurrentModerator: (meetingManager, audioVideo) => {
    let output = false;
    try {
      JSON.parse(
        meetingManager.meetingSessionConfiguration.externalMeetingId
      ).participants.forEach((el) => {
        // const localAttendeeId =
        //   audioVideo?.audioVideoController?.realtimeController?.state
        //     ?.localAttendeeId;

        if (el._id === $c.getUserId() && el.organizer) {
          output = true;
        }
      });
    } catch (e) {}

    return output;
  },
  isModerator: (moderators) => {
    const userInfo = $c.getUserId();

    if (moderators) {
      return moderators.indexOf(userInfo) >= 0;
    }

    return false;
  },
  participantIsModerator: (meetingManager, attendeeId) => {
    let output = false;

    try {
      let moderators = JSON.parse(
        meetingManager.meetingSessionConfiguration.externalMeetingId
      ).participants;

      moderators.forEach((el) => {
        if (attendeeId === el._id && el.organizer) {
          output = true;
        }
      });
    } catch (e) {}

    return output;
  },
  createControlsPanel: (
    meetingManager,
    isVideoEnabled,
    toggleVideo,
    muted,
    toggleMute,
    isLocalUserSharing,
    toggleContentShare,
    audioVideo,
    meetingObject
  ) => {
    let leaveButton = [];

    let isModerator = MeetingFunctions.participantIsModerator(
      meetingManager,
      $c.getUserId()
    );

    if (!MeetingFunctions.isCurrentModerator(meetingManager, audioVideo)) {
      if (MeetingFunctions.isBreakout()) {
        leaveButton.push(
          <div className=" dropdown d-inline-block">
            <button
              type="button"
              className="btn btn-danger btn-sm  border-0 dropdown-toggle btn-sm"
              data-bs-toggle="dropdown"
              aria-expanded="true"
            >
              <i className="fas fa-sign-out-alt  me-2"></i>Exit
            </button>
            <ul className="dropdown-menu">
              <li
                className="dropdown-item small"
                role="button"
                onClick={() => {
                  MeetingFunctions.leaveBreakout(meetingManager);
                }}
              >
                Return to main session
              </li>
              <li
                className="dropdown-item small"
                role="button"
                onClick={() => {
                  MeetingFunctions.leaveMeeting(meetingManager);
                }}
              >
                Leave call
              </li>
            </ul>
          </div>
        );
      } else {
        leaveButton.push(
          <button
            type="button"
            className="btn btn-danger btn-sm  border-0"
            onClick={() => {
              MeetingFunctions.leaveMeeting(meetingManager);
            }}
          >
            <i className="fas fa-sign-out-alt  me-2"></i>Exit
          </button>
        );
      }
    } else {
      leaveButton.push(
        <div className=" dropdown d-inline-block">
          <button
            type="button"
            className="btn btn-danger btn-sm  border-0 dropdown-toggle btn-sm"
            data-bs-toggle="dropdown"
            aria-expanded="true"
          >
            <i className="fas fa-sign-out-alt  me-2"></i>Exit
          </button>
          <ul className="dropdown-menu">
            {MeetingFunctions.isBreakout() ? (
              <li
                className="dropdown-item small"
                role="button"
                onClick={() => {
                  MeetingFunctions.leaveBreakout(meetingManager);
                }}
              >
                Return to main session
              </li>
            ) : null}
            <li
              className="dropdown-item small"
              role="button"
              onClick={() => {
                MeetingFunctions.leaveMeeting(meetingManager);
              }}
            >
              Leave call
            </li>
            <li
              className="dropdown-item small"
              role="button"
              onClick={() => {
                MeetingFunctions.endMeetingForAll(meetingManager);
              }}
            >
              End call for all
            </li>
          </ul>
        </div>
      );
    }

    console.log("pasok1", meetingObject);

    return (
      <>
        {MeetingFunctions.isTraining() ? (
          isModerator ? (
            <button
              type="button"
              className={"btn btn-sm border-0 me-2 display-lt-md-none ".concat(
                isLocalUserSharing ? " btn-purple" : "btn-white bg-white"
              )}
              aria-expanded="true"
              onClick={() => {
                toggleContentShare();
              }}
            >
              <i role="button" className="fas fa-tv me-2"></i>
              {isLocalUserSharing ? "Share screen" : "Share screen"}
            </button>
          ) : (
            ""
          )
        ) : (
          <button
            type="button"
            className={"btn btn-sm border-0 me-2 display-lt-md-none ".concat(
              isLocalUserSharing ? " btn-purple" : "btn-white bg-white"
            )}
            aria-expanded="true"
            onClick={() => {
              toggleContentShare();
            }}
          >
            <i role="button" className="fas fa-tv me-2"></i>
            {isLocalUserSharing ? "Share screen" : "Share screen"}
          </button>
        )}

        {!isModerator && !meetingObject?.options?.allowCameraForParticipants ? (
          ""
        ) : (
          <>
            <button
              type="button"
              className="btn  btn-sm btn-white bg-white  border-0 ps-0"
              aria-expanded="true"
              style={{ width: "6.5rem" }}
              onClick={() => {
                toggleVideo();
              }}
            >
              <i
                role="button"
                className={
                  isVideoEnabled
                    ? "fas fa-video me-2"
                    : "fas fa-video-slash me-2"
                }
              ></i>
              {!isVideoEnabled ? "Camera off" : "Camera on"}
            </button>
            <div className=" dropdown d-inline-block me-2">
              <button
                type="button"
                className="btn  btn-sm btn-white bg-white  border-0 dropdown-toggle ps-0"
                data-bs-toggle="dropdown"
                aria-expanded="true"
              ></button>
              <ul
                className="dropdown-menu"
                style={{ right: "40%", left: "unset" }}
              >
                {meetingManager?.videoInputDevices?.map((el) => {
                  return (
                    <li
                      className="dropdown-item small"
                      role="button"
                      onClick={() => {
                        meetingManager.audioVideo.startVideoInput(el.deviceId);
                      }}
                    >
                      {el.label}
                    </li>
                  );
                })}
              </ul>
            </div>
          </>
        )}

        <button
          type="button"
          className="btn  btn-sm btn-white bg-white  border-0 ps-0"
          aria-expanded="true"
          style={{ width: "6rem" }}
          onClick={() => {
            toggleMute();
          }}
        >
          <i
            role="button"
            className={
              muted
                ? "fas fa-microphone-slash  me-2"
                : "fas fa-microphone  me-2"
            }
          ></i>
          {muted ? "Audio off" : "Audio on"}
        </button>

        <div className=" dropdown d-inline-block me-2">
          <button
            type="button"
            className="btn btn-white btn-sm bg-white  border-0 dropdown-toggle ps-0"
            data-bs-toggle="dropdown"
            aria-expanded="true"
          >
            {/* {muted ? "Audio off" : "Audio on"} */}
          </button>
          <ul className="dropdown-menu" style={{ right: "40%", left: "unset" }}>
            {meetingManager?.audioInputDevices?.map((el) => {
              return (
                <li
                  className="dropdown-item small"
                  role="button"
                  onClick={() => {
                    meetingManager.audioVideo.startAudioInput(el.deviceId);
                  }}
                >
                  {el.label}
                </li>
              );
            })}
          </ul>
        </div>
        {leaveButton}
      </>
    );
  },
  createCameraDeviceButton: (meetingManager, isVideoEnabled, toggleVideo) => {
    return (
      <span className="video-control position-relative">
        <div className="position-absolute bg-white list-of-devices collapse list-of-video rounded">
          <ul className="list-group list-group-flush rounded">
            {meetingManager?.videoInputDevices?.map((el) => {
              return (
                <li className="list-group-item" role="button">
                  {el.label}
                </li>
              );
            })}
          </ul>
        </div>
        <i
          role="button"
          className={
            isVideoEnabled
              ? "fas fa-video fa-fw fa-lg"
              : "fas fa-video-slash fa-fw fa-lg"
          }
          onClick={() => {
            toggleVideo();
          }}
        ></i>

        <i
          role="button"
          className="fas fa-angle-up ms-2 fa-lg"
          data-bs-toggle="collapse"
          data-bs-target=".list-of-video"
        ></i>
      </span>
    );
  },
  createAudioInputButton: (meetingManager, muted, toggleMute) => {
    return (
      <span className="video-control position-relative">
        <div className="position-absolute bg-white list-of-devices collapse list-of-audio-input rounded">
          <ul className="list-group list-group-flush rounded">
            {meetingManager?.audioInputDevices?.map((el) => {
              return (
                <li
                  className="list-group-item"
                  role="button"
                  onClick={() => {
                    console.log(el);
                    // meetingManager;

                    // console.log(el);
                    meetingManager.audioVideo.startAudioInput(el.deviceId);
                  }}
                >
                  {el.label}
                </li>
              );
            })}
          </ul>
        </div>
        <i
          className={
            muted
              ? "fas fa-microphone-slash fa-fw fa-lg"
              : "fas fa-microphone fa-fw fa-lg"
          }
          role="button"
          onClick={toggleMute}
        ></i>
        <i
          role="button"
          className="fas fa-angle-up ms-2 fa-lg"
          data-bs-toggle="collapse"
          data-bs-target=".list-of-audio-input"
        ></i>
      </span>
    );
  },
  createContentShareButton: (toggleContentShare) => {
    return (
      <span className="video-control">
        <i
          className="fas fa-desktop fa-fw fa-lg"
          role="button"
          onClick={toggleContentShare}
        ></i>
      </span>
    );
  },
  createExpandButton: (onToggleExpand, id) => {
    return (
      <span className="video-control">
        <i
          role="button"
          className="fas fa-expand-alt fa-fw fa-lg"
          onClick={() => {
            onToggleExpand(id);
          }}
        ></i>
      </span>
    );
  },
  isBreakout: () => {
    return $f.getParameterByName("breakout") ? true : false;
  },
  isTraining: () => {
    return $f.getQueryPath(2).startsWith("v-");
  },
  isMeeting: () => {
    return $f.getQueryPath(2).startsWith("m-");
  },
  leaveBreakout: (meetingManager) => {
    // history.push("/meeting/".concat(meetingId));

    window["meetingModal"].setState({
      show: true,
      size: "default",
      title: (
        <>
          <div className="display-3-no-line-height ">
            Return to main session?
          </div>
        </>
      ),
      footer: [
        <button
          type="button"
          onClick={() => {
            window["meetingModal"].close();
          }}
          className="btn btn-sm btn-white"
        >
          Cancel
        </button>,
        <button
          type="button"
          onClick={() => {
            meetingManager.leave();

            window.location.replace(
              "/".concat($f.getQueryPath(1), "/", $f.getQueryPath(2))
            );
            window["meetingModal"].close();
          }}
          className="btn btn-sm btn-danger"
        >
          Return to main session
        </button>,
      ],
      body: (
        <p>
          Are you sure that you want to leave this room and return to main
          session?
        </p>
      ),
    });
  },

  endBreakout: (meetingManager) => {
    $f.fetch(
      "/end/{id}",
      "POST",
      {
        conference: $f.getParameterByName("breakout"),
      },
      {},
      {
        id: () => {
          return $f.getParameterByName("breakout");
        },
      },
      (resp) => {}
    );
    MeetingFunctions.endCall(meetingManager, "all");
  },
  leaveMeeting: (meetingManager) => {
    // history.push("/event/meeting/".concat(meetingId));

    let meeting = meetingManager.meetingSessionConfiguration.externalMeetingId;
    let trainingId = meeting.trainingId;

    window["meetingModal"].setState({
      show: true,
      size: "default",
      title: (
        <>
          <div className="display-3-no-line-height ">Leave call?</div>
        </>
      ),
      footer: [
        <button
          type="button"
          onClick={() => {
            window["meetingModal"].close();
          }}
          className="btn btn-sm btn-white"
        >
          Cancel
        </button>,
        <button
          type="button"
          onClick={() => {
            meetingManager.leave();

            window.location.replace(MeetingFunctions.returnToEventURL(meeting));
          }}
          className="btn btn-sm btn-danger"
        >
          Leave call
        </button>,
      ],
      body: <p>Are you sure you want to leave call?</p>,
    });
  },
  endMeetingForAll: (meetingManager) => {
    // history.push("/event/meeting/".concat(meetingId));

    window["meetingModal"].setState({
      show: true,
      size: "default",
      title: (
        <>
          <div className="display-3-no-line-height ">End call for all?</div>
        </>
      ),
      footer: [
        <button
          type="button"
          onClick={() => {
            window["meetingModal"].close();
          }}
          className="btn btn-sm btn-white"
        >
          Cancel
        </button>,
        <button
          type="button"
          onClick={() => {
            MeetingFunctions.endMeeting(meetingManager);
          }}
          className="btn btn-sm btn-danger"
        >
          End call for all
        </button>,
      ],
      body: <p>This action will end the call for you and the participants</p>,
    });
  },
  endMeeting: (meetingManager) => {
    let meeting = meetingManager.meetingSessionConfiguration.externalMeetingId;

    MeetingFunctions.endCall(meetingManager, "all");
    $f.fetch(
      "/end/{id}",
      "POST",
      {
        conference: $f.getQueryPath(2),
      },
      {},
      {
        id: () => {
          return $f.getQueryPath(2);
        },
      },
      (resp) => {
        window.location.replace(MeetingFunctions.returnToEventURL(meeting));
      }
    );
  },
  participantAvatar: (userId, meetingManager) => {
    try {
      console.log("pasok", meetingManager);
      return JSON.parse(
        meetingManager.meetingSessionConfiguration.externalMeetingId
      )
        .participants.filter((el) => {
          return el._id === userId;
        })
        .map((row) => {
          return row.firstName ? (
            $c.userIsAdmin() || $c.getUserId() === row._id ? (
              <Link to={"/user/".concat(row._id)} target="_blank">
                {$c.createAvatar(
                  row.profileImage,
                  row.firstName,
                  row.lastName,
                  "breakout-avatar avatar-xs"
                )}
              </Link>
            ) : (
              <span
                role={row.hasPrivateInfo && $c.userIsMember() ? "" : "button"}
                onClick={() => {
                  if (row.hasPrivateInfo && $c.userIsMember()) {
                    return;
                  }
                  window.location.replace("/user/".concat(row._id));
                }}
              >
                {$c.createAvatar(
                  row.profileImage,
                  row.firstName,
                  row.lastName,
                  "breakout-avatar avatar-xs"
                )}
              </span>
            )
          ) : (
            "Guest"
          );
        });
    } catch (e) {
      console.log("pasokeer", e);
    }

    return "";
  },
  createLeaveButton: () => {
    return (
      <span className="video-control">
        <i
          className="fas fa-sign-out-alt fa-fw fa-lg"
          role="button"
          onClick={() => {
            if (MeetingFunctions.isBreakout()) {
              MeetingFunctions.leaveBreakout();
            }
          }}
        ></i>
      </span>
    );
  },
};

export default MeetingFunctions;
