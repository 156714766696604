import { Component } from "react";
import { withRouter } from "react-router";
import $c from "../../../../modules/functions";
import $f from "../../../../src";
import Header from "../header";
import SimpleReactValidator from "simple-react-validator";
import Switch from "../../../../types/switch/switch";
import Modals from "../../../../modules/modals";

class Security extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();
    this.validate = this.validate.bind(this);
    this.onChange = this.onChange.bind(this);

    this.update = this.update.bind(this);
    this.sendPasswordEmail = this.sendPasswordEmail.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.onChangeNewPassord = this.onChangeNewPassord.bind(this);
    this.checkNewPassword = this.checkNewPassword.bind(this);

    this.state = {
      loaded: false,
      edit: false,
      hasChanges: false,
    };
  }

  validate() {
    const isValid = this.validator.allValid();

    this.setState({ isValid });
    this.validator.showMessages();
    this.forceUpdate();

    return isValid;
  }

  componentDidMount() {
    const { data, module, view, specialProperties, response } = this.props;

    let templateConfiguration = view.template.configuration;

    const innerTemplates = ["header", "top", "bottom"];

    innerTemplates.map((itm, idx) => {
      let tmp = templateConfiguration[itm + "Template"]
        ? templateConfiguration[itm + "Template"]
        : () => {};
      this[itm + "Template"] = tmp.bind(this);
    });

    let {
      firstName,
      lastName,
      isPublicProfileImage,
      hasPrivateInfo,
      _id,
      status,
      email,
      instructor,
      profileImage,
    } = response
      ? response.data[0]
      : {
          _id: "",
          firstName: "",
          lastName: "",
          isPublicProfileImage: false,
          hasPrivateInfo: false,
          status: "",
          email: "",
          instructor: false,
          profileImage: null,
        };

    let tag = "";

    if (response.host) {
      tag = "host";
    }

    if (response.instructor) {
      tag = "instructor";
    }

    this.setState({
      specialProperties,
      data,
      loaded: true,
      view,
      module,
      response,
      firstName,
      lastName,
      email,
      profileImage,
      _id,
      status,
      instructor,
      isPublicProfileImage: isPublicProfileImage ? isPublicProfileImage : false,
      hasPrivateInfo: hasPrivateInfo ? hasPrivateInfo : false,
      initial: {
        firstName,
        lastName,
        isPublicProfileImage,
        hasPrivateInfo,
      },
    });
  }

  onChange(value, property) {
    this.update(value, property);
  }

  onChangeNewPassord(value, property) {
    this.setState({ [property]: value });
    setTimeout(this.checkNewPassword, 0);
  }

  update(value, property) {
    let _this = this;

    $f.fetch(
      "/user",
      "PATCH",
      {
        _id: this.state._id,
        [property]: value,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        profileImage: this.state.profileImage,
      },
      {},
      {},
      (resp) => {
        $f.createNotificationFromResponse(resp.data);
        _this.props.module.doWork();
      }
    );
  }

  sendPasswordEmail() {
    $f.fetch(
      "/auth/changePassword",
      "POST",
      {
        email: this.state.email,
      },
      {},
      {},
      (resp) => {
        if (resp.status === 200) {
          $f.createNotification({
            message: "A link has sent to the user",
            type: "success",
          });
        }
      }
    );
  }

  checkNewPassword() {
    document
      .getElementById("btn-change-password")
      .setAttribute("disabled", "true");
    if (this.state.newpassword && this.state.confirmpassword) {
      if (this.state.newpassword === this.state.confirmpassword) {
        document
          .getElementById("btn-change-password")
          .removeAttribute("disabled");
      }
    }
  }

  changePassword() {
    let _this = this;

    this.setState({ newpassword: "", confirmpassword: "" });
    let Body = (
      <p key={$f.key()}>
        <div className="form-group mb-4">
          <label className="label-top ">New password</label>
          <input
            type="password"
            defaultValue=""
            className="form-control form-sm-control"
            onChange={(e) => {
              _this.onChangeNewPassord(e.target.value, "newpassword");
            }}
            key={$f.key()}
            placeholder="Type new password"
          ></input>
        </div>
        <div className="form-group mb-4">
          <label className="label-top ">Confirm password</label>
          <input
            type="password"
            defaultValue=""
            className="form-control form-sm-control"
            onChange={(e) => {
              _this.onChangeNewPassord(e.target.value, "confirmpassword");
            }}
            key={$f.key()}
            placeholder="Retype password"
          ></input>
        </div>
      </p>
    );

    window["primaryModal"].setState({
      show: true,
      size: "default",
      title: (
        <div className=" ">
          <h2 className="display-3-no-line-height header-ellipsis">
            Change your password
          </h2>
          <h5 className="display-5-no-bold color-grey">
            The password must contain at least 8 characters, 1 letter and 1
            number.
          </h5>
        </div>
      ),
      subTitle:
        "The password must contain at least 8 characters, 1 letter and 1 number.",
      footer: [
        <button
          type="button"
          onClick={() => {
            window["primaryModal"].close();
          }}
          className="btn btn-sm btn-white"
        >
          Cancel
        </button>,
        <button
          type="button"
          id="btn-change-password"
          onClick={() => {
            $f.fetch(
              "/auth/saveNewPassword",
              "POST",
              {
                confirmPassword: _this.state.confirmpassword,
                password: _this.state.newpassword,
              },
              {},
              {},
              (resp) => {
                if (resp.status === 200) {
                  $f.createNotification({
                    message: "Your password has changed!",
                    type: "success",
                  });
                }
              }
            );
            window["primaryModal"].close();
          }}
          className="btn btn-sm btn-purple"
        >
          <span className="text-white">Change password</span>
        </button>,
      ],
      body: Body,
    });
  }

  render() {
    if (this.state.loaded) {
      if ($c.userIsMember() && $c.getUserId() !== $f.getQueryPath(2)) {
        return "Page not found";
      }

      let tagLabel = "Not specified";

      switch (this.state.tag) {
        case "host":
          tagLabel = "Conference host";
          break;

        case "instructor":
          tagLabel = "Instructor";
          break;
      }

      let _this = this;

      return (
        <>
          <Header
            data={{
              title: this.state.firstName.concat(" ", this.state.lastName),
              subTitle: "Manage security settings",
              buttons: (args) => {
                if ($c.userIsAdmin() || $c.getUserId() === this.state._id) {
                  return [
                    this.state.status == "deactivated" ? (
                      <span className="p-2 me-2 text-danger">
                        User deactivated
                      </span>
                    ) : null,
                  ];
                }
              },
            }}
          ></Header>

          {this.state.status !== "registration" ? (
            <div className=" w-md-50 mt-3">
              <div className="color-grey row">
                <div className="col-9">
                  <span className="d-block">
                    <strong>Change password</strong>
                  </span>
                  <span className="d-block">
                    {$c.getUserId() !== this.state._id
                      ? "Send reset password email to the user"
                      : "Change your account password"}
                  </span>
                </div>
                <div className="col-3">
                  <button
                    className="btn btn-purple btn-sm"
                    type="button"
                    onClick={() => {
                      if ($c.getUserId() !== this.state._id) {
                        this.sendPasswordEmail();
                      } else {
                        this.changePassword();
                      }
                    }}
                    disabled={this.state.status == "deactivated" ? true : false}
                  >
                    <span className="text-white">
                      {$c.getUserId() !== this.state._id
                        ? "Send email"
                        : "Change password"}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className=" w-md-50 mt-3">
              <div className="color-grey row">
                <div className="col-9">
                  <span className="d-block">
                    <strong>Resend invitation</strong>
                  </span>
                  <span className="d-block">
                    A registration link will be sent to the user's email address
                  </span>
                </div>
                <div className="col-3">
                  <button
                    className="btn btn-purple btn-sm"
                    type="button"
                    onClick={() => {
                      Modals.contacts.resendInvitation({
                        userName: this.state.firstName.concat(
                          " ",
                          this.state.lastName
                        ),
                        email: this.state.email,
                      });
                    }}
                  >
                    <span className="text-white">Resend invitation</span>
                  </button>
                </div>
              </div>
            </div>
          )}
          {this.state.instructor && this.state.status !== "registration" ? (
            <div className="pt-5 w-md-50">
              <div className="color-grey row">
                <div className="col-9">
                  <span className="d-block">
                    <strong>Public events (for speakers)</strong>
                  </span>
                  <span className="d-block">
                    Show profile picture in public conferences
                  </span>
                </div>
                <div className="col-3">
                  <Switch
                    value={
                      this.state.isPublicProfileImage.toString() === "true"
                        ? true
                        : false
                    }
                    property={{
                      parentChange: (e) => {
                        _this.update(
                          e ? "true" : "false",
                          "isPublicProfileImage"
                        );
                      },
                      events: {},
                      attributes: {
                        className: "form-control",
                        disabled:
                          this.state.status == "deactivated" ? true : false,
                      },
                    }}
                  ></Switch>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {this.state.status !== "registration" ? (
            <div className="pt-5 w-md-50">
              <div className="color-grey row">
                <div className="col-9">
                  <span className="d-block">
                    <strong>Private info</strong>
                  </span>
                  <span className="d-block">
                    Hide your email and job details across the platform.
                  </span>
                </div>
                <div className="col-3">
                  <Switch
                    value={
                      this.state.hasPrivateInfo.toString() === "true"
                        ? true
                        : false
                    }
                    property={{
                      events: {},
                      parentChange: (e) => {
                        _this.update(e ? true : false, "hasPrivateInfo");
                      },
                      attributes: {
                        className: "form-control",
                        disabled:
                          this.state.status == "deactivated" ? true : false,
                      },
                    }}
                  ></Switch>
                </div>
              </div>
            </div>
          ) : null}

          {$c.userIsAdmin() &&
          $c.getUserId() !== this.state._id &&
          this.state.status !== "registration" ? (
            this.state.status === "deactivated" ? (
              <div className="pt-5 mt-5 border-top w-md-50">
                <div className="color-grey row">
                  <div className="col-9">
                    <span className="d-block">
                      <strong>Activate user</strong>
                    </span>
                    <span className="d-block">
                      Allow user access to the platform and its data.
                    </span>
                  </div>
                  <div className="col-3">
                    <button
                      className="btn btn-purple btn-sm"
                      onClick={() => {
                        Modals.contacts.activateDeactivateContact({
                          userName: this.state.firstName.concat(
                            " ",
                            this.state.lastName
                          ),
                          _id: this.state._id,
                          action:
                            this.state.status == "deactivated"
                              ? "activate"
                              : "deactivate",
                        });
                      }}
                    >
                      Activate account
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="pt-5 mt-5 border-top w-md-50">
                <div className="color-grey row">
                  <div className="col-9">
                    <span className="d-block">
                      <strong>Deactivate user</strong>
                    </span>
                    <span className="d-block">
                      Prevent user's access to the platform and its data.
                      <br></br>
                      You can reactivate at any time.
                    </span>
                  </div>
                  <div className="col-3">
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() => {
                        Modals.contacts.activateDeactivateContact({
                          userName: this.state.firstName.concat(
                            " ",
                            this.state.lastName
                          ),
                          _id: this.state._id,
                          action:
                            this.state.status == "deactivated"
                              ? "activate"
                              : "deactivate",
                        });
                      }}
                    >
                      Deactivate account
                    </button>
                  </div>
                </div>
              </div>
            )
          ) : (
            ""
          )}
        </>
      );
    }

    return "";
  }
}

export default withRouter(Security);
