import $f from "../src";
import $c from "./functions";

const Meeting = {
  properties: () => {
    return {
      _id: {
        label: "",
        type: "text",
        events: {},
        attributes: {},
      },
      joinEventButton: {
        events: {
          onClick: function () {
            window.location.replace("/meeting/m-".concat(this.props.data._id));
          },
        },
        attributes: {
          className: "btn btn-purple btn-sm",
        },
        caption: "Join meeting",
        configuration: {
          eventNotStartedText: (
            <span className="color-purple">
              <strong>Meeting starts soon</strong>
            </span>
          ),
          eventHasEndedText: (
            <span className="text-danger">
              <strong>Meeting has ended</strong>
            </span>
          ),
          fromDateProperty: "from",
          endDateProperty: "to",
          extraStartTime: 0,
          extraEndTime: 0,
        },
      },
    };
  },
  views: {
    list: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: "{path}",
          replace: {
            path: () => {
              if (!$c.isLoggedIn()) {
                $c.logout(window.location.href);
              }

              let path = window.location.pathname;

              switch (true) {
                case path.startsWith("/meeting"):
                  return "/meetingInfo/".concat(
                    $f.getQueryPath(2).replace("m-", "")
                  );

                  break;

                case path.startsWith("/liveclass"):
                  return "/trainingConferenceInfo/".concat(
                    $f.getQueryPath(2).replace("v-", "")
                  );
                  break;
              }
            },
          },
          headers: {},
          mapper: () => {},
          formatResponse: (data) => {
            data.data = Array.isArray(data.data) ? data.data : [data.data];
            return data;
          },
        },
      },
      template: {
        name: () => {
          return "IndexMeeting";
        },
        configuration: {
          includeProperties: ["_id"],
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
  },
};

export default Meeting;
