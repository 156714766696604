import parse from "html-react-parser";
import { Component } from "react";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import $f from "../../src";

class Breadcrumb extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.validator = new SimpleReactValidator();

    this.state = {
      loaded: false,
    };
  }

  onChange(e) {
    this.setState({
      value: e.target.value,
    });

    setTimeout(this["customOnChange"], 0);
  }

  validate() {
    const isValid = this.validator.allValid();

    this.setState({ isValid });
    this.validator.showMessages();
    this.forceUpdate();

    return isValid;
  }

  componentDidMount() {
    let events = [];

    Object.keys(this.props.property.events)?.forEach((element, idx) => {
      if (!element.toLowerCase().startsWith("onchange")) {
        this[element] = this.props.property.events[element];
        this[element] = this[element].bind(this);
        events[element] = this[element];
      } else {
        this["customOnChange"] = this.props.property.events[element];
        this["customOnChange"] = this["customOnChange"].bind(this);
      }
    });

    this.setState({
      loaded: true,
      events: events,
      attributes: { ...this.props.property.attributes },
      value: this.props.value,
      editable: this.props.property.editable === false ? false : true,
      useCustomUI: this.props.property.useCustomUI,
      configuration: { ...this.props.property.configuration },
    });
  }

  editable() {
    let data = [];
    let count = "";
    let url = "";

    try {
      switch ($f.getValue(this.state.configuration.type, this)) {
        case "files":
          this.props.data.data[0].breadCrumb.forEach((el) => {
            el.url = "/files/".concat("?_id=" + el._id);

            data.push(el);
          });

          data[0].name = "All files";
          count = this.props.data.data[0].fileCount;

          data[data.length - 1]["count"] = count;

          break;

        case "spacefiles":
          this.props.data.data[0].breadCrumb.forEach((el) => {
            if ($f.getQueryPath(1) == "space") {
              if (el.type !== "space") {
                if (el.type == "folder") {
                  el.url = "/space/".concat(
                    $f.getQueryPath(2),
                    "/group/",
                    $f.getQueryPath(4),
                    "/files?_id=" + el._id
                  );
                  data.push(el);
                }
              }
            } else {
              if (el.type !== "space") {
                if (el.type == "folder") {
                  el.url = "/files/space/"
                    .concat($f.getQueryPath(3))
                    .concat("?_id=" + el._id);
                  data.push(el);
                }
              }
            }
          });

          data[0].name = "All files";
          if ($f.getQueryPath(1) == "space") {
            data[0].url = "/space/".concat(
              $f.getQueryPath(2),
              "/group/",
              $f.getQueryPath(4),
              "/files"
            );
          }

          count = this.props.data.data[0].fileCount;

          data[data.length - 1]["count"] = count;

          break;

        case "trainingModules":
          data = [{ name: "Modules", count: this.props.data.data.length }];
          break;

        case "trainingSessions":
          count = this.props.data.data[0].files;
          this.props.data.data[0].breadCrumb.forEach((el) => {
            switch (el.type) {
              case "Training":
                el.url = "/event/training/" + el._id + "/files";
                break;
              case "Module":
                el.url =
                  "/event/training/" +
                  $f.getQueryPath(3) +
                  "/files/module/" +
                  el._id;
                break;
              case "Session":
                el.url =
                  "/event/training/" +
                  $f.getQueryPath(3) +
                  "/files/module/" +
                  $f.getQueryPath(6) +
                  "/session/" +
                  el._id;
                count = this.props.data.data[0].files.length;
                break;
            }

            data.push(el);
          });

          data[data.length - 1]["count"] = count;
          break;
      }
    } catch (e) {}

    let items = [];
    let moreItems = [];

    data.forEach((el, idx) => {
      if (idx == data.length - 1) {
        items.push(
          <li className="breadcrumb-item d-flex" title={el.name}>
            <span className=" nav-ellipsis text-ellipsis-mobile  text-ellipsis strong d-inline-block">
              {el.name}
            </span>
            <span className=" d-inline-block strong ms-1">
              {el.count >= 0 ? "(".concat(el.count).concat(")") : ""}
            </span>
          </li>
        );
      } else if (idx == 0 || data.length == 3) {
        items.push(
          <li className="breadcrumb-item">
            <Link
              className="color-strong-grey nav-ellipsis text-ellipsis-mobile text-ellipsis  d-inline-block"
              to={el.url}
            >
              {el.name}
            </Link>
            <span className=" d-inline-block ">
              {el.count >= 0 ? " (".concat(el.count).concat(")") : ""}
            </span>
          </li>
        );
      } else {
        moreItems.push(
          <li className="list-group-item p-1 ps-2 border-bottom-0 a-hover-grey">
            <Link
              className="color-strong-grey nav-ellipsis d-block "
              to={el.url}
            >
              {el.name}
              {el.count >= 0 ? " (".concat(el.count).concat(")") : ""}
            </Link>
          </li>
        );
      }
    });

    if (data.length > 3) {
      items.splice(
        1,
        0,
        <li className="breadcrumb-item ">
          <span className="dropdown">
            <i
              className="fas fa-ellipsis-h a-hover-grey"
              data-bs-toggle="dropdown"
              role="button"
            ></i>
            <ul className="dropdown-menu ">{moreItems}</ul>
          </span>
        </li>
      );
    }

    return (
      <nav aria-label="breadcrumb">
        <ol
          className={"breadcrumb pb-2 mb-0 border-bottom ".concat(
            this.state.configuration.customClass
          )}
        >
          {items}
        </ol>
      </nav>
    );
  }

  nonEditable() {
    return typeof this.state.value === "string"
      ? parse(this.state.value)
      : this.state.value;
  }

  customUI() {}

  render() {
    if (this.state.loaded) {
      if (this.state.useCustomUI) {
        return this.customUI();
      } else if (this.state.editable) {
        return this.editable();
      } else {
        return this.nonEditable();
      }
    }

    return "";
  }
}

export default Breadcrumb;
