import { Component } from "react";
import $f from "../../../../src";
import parse from "html-react-parser";
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";
import $c from "../../../../modules/functions";

class EvaluatorTest extends Component {
  constructor(props) {
    super(props);

    this.submitEvaluation = this.submitEvaluation.bind(this);
    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    const { data, module, view } = this.props;

    this.setState({
      loaded: true,
      data,
      module,
      view,
      exam: this.props.response.data[0],
    });

    document.body.style.paddingTop = "0rem";
    document.body.style.backgroundColor = "#f6f7f9";
  }

  changeMark(idx, data) {
    let exam = this.state.exam;

    exam.questions[idx]["mark"] = data;

    this.setState({ exam });
  }

  submitEvaluation() {
    const postData = {
      _id: this.state.exam._id,
      questions: [],
    };

    this.state.exam?.questions.forEach((el) => {
      postData.questions.push({
        _id: el._id,
        question: el.question,
        mark: el.mark,
        type: el.type,
      });
    });

    const Body = (
      <p>
        You are about to submit your evaluation. Before submitting be sure that
        you have added the correct grades.<br></br> This action cannot be
        undone!<br></br> An email will be sent to the participant to inform him
        about the final grade.
      </p>
    );

    window["primaryModal"].setState({
      show: true,
      size: "default",
      title: (
        <h2 className="display-3-no-line-height header-ellipsis">
          Submit evaluation?
        </h2>
      ),
      footer: [
        <button
          type="button"
          onClick={() => {
            window["primaryModal"].close();
          }}
          className="btn btn-sm btn-white"
        >
          Cancel
        </button>,
        <button
          type="button"
          onClick={() => {
            $f.fetch(
              "/exam/user-exam/evaluate/{id}",
              "PATCH",
              postData,
              {},
              {
                id: () => {
                  return this.state.exam._id;
                },
              },
              (resp) => {
                window.location.replace("/tests/assessments/");
              }
            );
          }}
          className="btn btn-sm btn-purple"
        >
          Submit
        </button>,
      ],
      body: Body,
    });
  }

  render() {
    if (this.state.loaded) {
      const data = this.state.data[0];
      const { exam } = this.state;

      return (
        <>
          <header className="fixed-top">
            <nav className="navbar navbar-expand-md bg-white  top-navbar">
              <div className="container-fluid viewport-padding  color-strong-grey">
                <span className="display-5 mb-0"></span>
                <div className="text-md-end mt-auto mb-auto">
                  Submitted on:{" "}
                  <strong>{$c.formatDateTime(exam.finalizedOn)}</strong>
                </div>
              </div>
            </nav>
          </header>
          <main className="container-fluid viewport-padding bg-light-grey  h-100 ">
            <div className="wrapper " style={{ paddingTop: "3.563rem" }}>
              <div className="notification"></div>
              <div className="container p-3 rounded border mb-3 mt-5 bg-white">
                <h1 className="display-4">{parse(exam.title)}</h1>
                <label>
                  <strong>Instructions</strong>
                </label>
                <div>{parse(exam.instructions)}</div>
              </div>
              <div className="container p-3 rounded border mb-3 mt-3 bg-white">
                <div>
                  {exam.questions?.map((el, idx) => {
                    let borderBottom = "";

                    if (idx < exam.questions.length - 1) {
                      borderBottom = "border-bottom-1";
                    }

                    return (
                      <div className={"pt-3 pb-3 ".concat(borderBottom)}>
                        <div className="d-flex">
                          <label className="w-100 ">
                            <strong>Question {idx + 1}</strong>
                          </label>
                        </div>
                        <div>{parse(el.question)}</div>
                        {el.image ? (
                          <img
                            src={window["baseurl"](
                              "/agenda-file/data/" +
                                el.image +
                                "?type=preview&jwt=" +
                                localStorage.getItem("jwt")
                            )}
                            className="img-fluid mb-3"
                          ></img>
                        ) : (
                          ""
                        )}
                        <div className="color-grey mb-3">Answer</div>

                        <div className="mb-2">
                          <div className="d-flex">
                            <div className="w-100 text-break">
                              {el.answers[0]
                                ? el.answers[0].userAnswer
                                : "[ No answer ]"}
                            </div>
                          </div>
                        </div>

                        <div className="input-group mb-3 mt-3 w-25">
                          <span className="input-group-text">
                            Grade question (0-10)
                          </span>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="0 - 10"
                            min="0"
                            value={el.mark}
                            max="100"
                            step="1"
                            style={{ width: "1rem", textAlign: "right" }}
                            onChange={(e) => {
                              if (e.target.value <= 10 && e.target.value >= 0) {
                                this.changeMark(
                                  idx,
                                  e.target.value != 0
                                    ? e.target.value.replace(/^0+/, "")
                                    : e.target.value
                                );
                              }
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                  <div className="text-end">
                    <button
                      type="button"
                      className="btn btn-purple btn-sm ps-4 pe-4"
                      onClick={this.submitEvaluation}
                    >
                      Submit evaluation
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </>
      );
    }

    return "";
  }
}

export default EvaluatorTest;
