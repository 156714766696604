import moment from "moment/moment";
import { InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import $f from "../../src";
import OptionsForHeader from "../../types/optionsForHeader/optionsForHeader";
import EmptyStates from "../emptyStates";
import $c from "../functions";
import Modals from "../modals";

const OptionsForHeaderButton = function (data) {
  let property = {
    configuration: {
      options: {
        edit: function (args) {
          if ($c.userIsAdmin()) {
            let newargs = {
              props: { data: { ...args.props.data } },
              action: "update",
            };

            return {
              label: "Edit",
              onClick: function () {
                $f.getValue(Modals.events.editTraining, newargs);
              },
            };
          }
        },
        copyLink: function (args) {
          return {
            label: "Copy link",
            onClick: function () {
              $c.copyToClipboard(
                window["host"]("/event/training/".concat(args.props.data._id))
              );
              $f.createNotification({
                type: "success",
                message: "Link copied!",
              });
            },
          };
        },
        delete: function (args) {
          if ($c.userIsAdmin()) {
            return {
              label: <span className="text-danger">Delete</span>,
              onClick: function () {
                $f.getValue(Modals.events.deleteTraining, args);
              },
            };
          }
        },
      },
    },
  };

  return <OptionsForHeader data={data} property={property} />;
};

const Trainings = {
  properties: (args) => {
    const { editable } = args;

    return {
      _id: {
        label: "",
        path: "_id",
        labelPosition: "none",
        type: "hidden",
        events: {},
        attributes: {},
      },
      title: {
        label: "Title",
        type: "text",
        editable: editable,
        validation: "required",
        events: {},
        attributes: {
          className: "form-control",
          placeholder: "Type training title",
        },
      },
      fromtodate: {
        label: "Duration",
        type: "fromtodate",
        events: {},
        formatValue: (value, row) => {
          return { from: row.from, to: row.to };
        },
        attributes: {
          className: "form-control",
        },
      },
      optionsfortable: {
        label: "",
        type: "optionsfortable",
        configuration: {
          options: {
            open: function (args) {
              return {
                label: "Open",
                onClick: function () {
                  args.props.history.push(
                    "/event/training/".concat(args.props.data._id)
                  );
                },
              };
            },
            edit: function (args) {
              if ($c.userIsAdmin()) {
                let newargs = {
                  props: { data: { ...args.props.data } },
                  action: "update",
                };

                return {
                  label: "Edit",
                  onClick: function () {
                    $f.getValue(Modals.events.editTraining, newargs);
                  },
                };
              }
            },
            copyLink: function (args) {
              return {
                label: "Copy link",
                onClick: function () {
                  $c.copyToClipboard(
                    window["host"](
                      "/event/training/".concat(args.props.data._id)
                    )
                  );
                  $f.createNotification({
                    type: "success",
                    message: "Link copied!",
                  });
                },
              };
            },

            delete: function (args) {
              if ($c.userIsAdmin()) {
                return {
                  label: <span className="text-danger">Delete</span>,
                  onClick: function () {
                    $f.getValue(Modals.events.deleteTraining, args);
                  },
                };
              }
            },
          },
        },
      },

      description: {
        label: "Description",
        type: "editor",
        editable: editable,
        validation: "required",
        events: {},
        attributes: {
          className: "form-control",
          placeholder: "Type training description",
        },
      },
      participants: {
        label: "Participants",
        type: "tags",
        editable: editable,
        configuration: {
          type: "participants",
        },
        events: {},
        attributes: {
          className: "form-control",
        },
      },
      options: {
        label: "Options",
        type: "label",
        labelPosition: "none",
        attributes: {
          className: "display-4-no-margin color-grey",
        },
      },
      recordingOptions: {
        label: "",
        type: "recordingOptions",
        labelPosition: "none",
        path: "options",
        attributes: {},
        events: {},
      },
      modules: {
        label: <div className="display-4-no-margin color-grey">Modules</div>,
        type: "trainingmodules",
        editable: editable,
        configuration: {
          canAdd: true,
          canRemove: true,
        },
        events: {},
        attributes: {},
      },
      files: {
        label: "Attached files",
        type: "upload",
        editable: editable,
        configuration: {
          url: function (args) {
            return "/agenda-files/meeting/".concat(args.props.data._id);
          },
          type: "meeting",
          fileDataProperty: "files",
          allowedExtensions: ["pdf", "png", "jpeg", "jpg", "mp4"],
          multipleFiles: true,
        },
        caption: function (_this) {
          return (
            <div className="w-100 text-center p-4 ">
              <img src="/uni/svg/files/upload.svg" className="svg-sm mb-2" />
              <span className="d-block mb-1">
                <strong>
                  <span
                    className="color-purple"
                    role="button"
                    onClick={_this.onTargetClick}
                  >
                    Click to upload
                  </span>{" "}
                  or drag and drop
                </strong>
              </span>
              <span className="color-grey d-block">
                PDF, PNG, JPEG, MP4, (max 1GB)
              </span>
            </div>
          );
        },
        events: {},
        attributes: {
          className: "form-control bg-light-grey",
        },
      },
      createdAt: {
        label: "Date Created",
        type: "text",
        editable: editable,
        events: {},
        attributes: {},
        formatValue: (value, row) => {
          return $c.formatDateTime(row.createdAt);
        },
      },

      date: {
        label: "Date",
        type: "text",
        editable: editable,
        events: {},
        attributes: {},
        formatValue: (value, row) => {
          return row.from && row.to
            ? $c.formatDateFromToNoTime(row.from, row.to)
            : "---";
        },
      },
    };
  },
  specialProperties: () => {
    return {
      header: {
        type: "template",
        configuration: {
          title: "Trainings",
          subTitle: "View all available trainings",
          template: "Header",
          buttons: (args) => {
            if ($c.userIsMember()) {
              return [];
            }
            return [
              <button
                type="button"
                className="btn btn-purple  btn-sm  "
                onClick={() => {
                  Modals.events.scheduleTraining();
                }}
              >
                Schedule
              </button>,
            ];
          },
        },
        events: {},
      },
      headerOverview: {
        type: "template",
        configuration: {
          title: (args) => {
            return args.data.data[0].title;
          },
          template: "Header",
          buttons: (args) => {
            if ($f.getQueryPath(4) == "participants") {
              return [
                <button
                  type="button"
                  className="btn btn-purple  btn-sm  "
                  onClick={() => {
                    $f.getValue(Modals.participants.addParticipants, {
                      title: "Add people",
                      api: "new",
                      actionType: "event/training",
                      actionId: args.data.data[0]._id,
                      actionSection: "trainingParticipants",
                    });
                  }}
                >
                  Add people
                </button>,
              ];
            }

            return [$f.getValue(OptionsForHeaderButton, args.data.data[0])];
          },
        },
        events: {},
      },
      eventsNav: {
        type: "template",
        configuration: {
          data: (resp) => {
            let output = [];
            let data = [
              { name: "All trainings", filter: "" },
              // { name: "Upcoming", filter: "upcoming" },
              // { name: "Ongoing", filter: "ongoing" },
              // { name: "Ended", filter: "completed" },
            ];
            try {
              data.forEach((el, idx) => {
                let isActive = false;
                if ($f.getParameterByName("filter") == el.filter) {
                  isActive = true;
                } else {
                  if (!$f.getParameterByName("filter")) {
                    if (idx == 0) {
                      isActive = true;
                    }
                  }
                }

                output.push({
                  url:
                    idx == 0
                      ? "/events/trainings"
                      : "/events/trainings?filter=" + el.filter,
                  title: el.name,
                  isActive: () => {
                    return isActive;
                  },
                });
              });
            } catch (e) {}
            return output;
          },
          template: "HorizontalNavScroll",
        },
      },
      sessions: {
        type: "template",
        configuration: {
          data: (resp) => {
            let data = [];
            try {
              let hasActive = false;

              let trainingModule = null;

              let training = resp.data.data[0];

              training?.modules.forEach((el) => {
                if (el._id == $f.getQueryPath(5)) {
                  trainingModule = el;
                }
              });

              trainingModule.sessions.forEach((el, idx) => {
                if ($f.getQueryPath(7) == el._id) {
                  hasActive = true;
                  data.push({
                    url:
                      "/event/training/" +
                      training._id +
                      "/module/" +
                      trainingModule._id +
                      "/session/" +
                      el._id,
                    title: "Session ".concat(idx + 1),
                    isActive: () => {
                      return true;
                    },
                  });
                } else {
                  data.push({
                    url:
                      "/event/training/" +
                      training._id +
                      "/module/" +
                      trainingModule._id +
                      "/session/" +
                      el._id,
                    title: "Session ".concat(idx + 1),
                    isActive: () => {
                      return false;
                    },
                  });
                }
              });

              // data.unshift({
              //   url:
              //     "/event/training/" +
              //     training._id +
              //     "/module/" +
              //     trainingModule._id,
              //   title: "About",
              //   isActive: () => {
              //     return false;
              //   },
              // });

              if (!hasActive) {
                data[0].isActive = () => {
                  return true;
                };
              }
            } catch (e) {}
            return data;
          },
          template: "HorizontalNav",
        },
      },
      participants: {
        label: "",
        type: "module",
        configuration: {
          module: "ParticipantTable",
          view: "list",
        },
      },
      testResults: {
        label: "",
        type: "module",
        configuration: {
          module: "TrainingTestResults",
          view: "list",
        },
      },
      participantResults: {
        label: "",
        type: "module",
        configuration: function (args) {
          return {
            module: "TrainingTestResults",
            view: "userResults",
          };
        },
      },
      pagination: {
        type: "pagination",
        configuration: {
          start: function (props) {
            return 0;
          },
          offset: "25",
          total: function (props) {
            return props.props.data.pagination.total;
          },
          current: function (props) {
            return props.props.data.pagination.currentPage;
          },
          replaceAttribute: "start",
        },
        events: {},
      },
      files: {
        label: "",
        type: "module",
        configuration: {
          module: "FilesTable",
          view: () => {
            if ($f.getQueryPath(8)) {
              return "trainingFiles";
            }
            if ($f.getQueryPath(6)) {
              return "trainingSessions";
            }
            return "trainingModules";
          },
        },
      },
    };
  },
  views: {
    list: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/event/training/?start={start}&offset={offset}{filters}";
          },
          dataPath: () => {},
          replace: {
            start: 0,
            offset: 25,
            filters: function () {
              switch ($f.getParameterByName("filter")) {
                case "completed":
                  return (
                    "&filters=" +
                    JSON.stringify([
                      { key: "to", operator: "lt", value: new Date() },
                    ])
                  );
                  break;

                case "ongoing":
                  return (
                    "&filters=" +
                    JSON.stringify([
                      { operator: "is", key: "event", value: "today" },
                    ])
                  );
                  break;

                case "upcoming":
                  return (
                    "&filters=" +
                    JSON.stringify([
                      { key: "to", operator: "gt", value: new Date() },
                    ])
                  );
                  break;

                default:
                  return "";
                  break;
              }
            },
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Table";
        },
        configuration: {
          emptyTemplate: function (arg) {
            return EmptyStates.events.trainings.default();
          },
          headerTemplate: function () {
            return (
              <>
                {this.state.specialProperties.header.component}
                {this.state.specialProperties.eventsNav.component}
              </>
            );
          },
          bottomTemplate: function () {
            return this.state.specialProperties.pagination.component;
          },
          tableConfiguration: {
            headers: {
              title: {
                attributes: {
                  className: "w-50",
                },
              },
            },
            cellTemplates: {
              title: (comp, itm) => {
                return (
                  <Link
                    to={"/event/training/".concat(itm._id.value)}
                    className="color-grey"
                  >
                    {comp}
                  </Link>
                );
              },
            },
          },
          includeProperties: () => {
            return ["title", "optionsfortable", "createdAt", "date"];
          },
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
    detail: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/event/training/{id}";
          },
          dataPath: () => {},
          replace: {
            id: () => {
              return $f.getQueryPath(3);
            },
          },
          headers: {},
          mapper: () => {},
        },
        new: {
          url: () => {
            return {
              data: [
                {
                  modules: [
                    {
                      sessions: [
                        {
                          videoConference: [],
                          exam: [],
                          reading: [],
                          videoPresentation: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            };
          },
          dataPath: () => {},
          replace: {
            id: () => {
              return $f.getQueryPath(3);
            },
          },
          headers: {},
          mapper: () => {},
        },
        fromTemplate: {
          url: () => {
            return "/template/training/{id}";
          },
          dataPath: () => {},
          replace: {},
          headers: {},
          mapper: () => {},
          formatResponse: function (resp) {
            let data = { ...resp.data[0] };

            data?.modules?.forEach((module) => {
              module?.sessions?.forEach((session) => {
                session?.videoConference?.forEach((conference) => {
                  conference.from = moment()
                    .add(30 - (moment().minute() % 30), "minutes")
                    .format("YYYY-MM-DDTHH:mm:ss.000Z");
                  conference.to = moment()
                    .add(30 - (moment().minute() % 30), "minutes")
                    .add(1, "hour")
                    .format("YYYY-MM-DDTHH:mm:ss.000Z");

                  conference.reminders = [{ type: "minutes", value: 5 }];
                });

                session?.exam?.forEach((exam) => {
                  exam.from = moment()
                    .add(30 - (moment().minute() % 30), "minutes")
                    .format("YYYY-MM-DDTHH:mm:ss.000Z");
                  exam.to = moment()
                    .add(30 - (moment().minute() % 30), "minutes")
                    .add(1, "hour")
                    .format("YYYY-MM-DDTHH:mm:ss.000Z");
                  exam.examDuration = {
                    hours: 1,
                    minutes: 0,
                  };
                  exam.reminders = [{ type: "minutes", value: 5 }];
                });
              });
            });

            resp.data[0] = data;

            return resp;
          },
        },
      },
      template: {
        name: () => {
          return "Form";
        },
        configuration: {
          includeProperties: (args) => {
            if (
              args?.moduleReferer?.props?.property?.configuration?.api ==
              "fromTemplate"
            ) {
              return [
                "_id",
                "title",
                "fromtodate",
                "description",
                "participants",
                "options",
                "recordingOptions",
                "modules",
              ];
            }
            if (args.data[0]._id) {
              return [
                "_id",
                "title",
                "fromtodate",
                "description",
                "options",
                "recordingOptions",
                "modules",
              ];
            } else {
              return [
                "_id",
                "title",
                "fromtodate",
                "description",
                "participants",
                "options",
                "recordingOptions",
                "modules",
              ];
            }
          },
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: true };
        },
      },
    },
    overview: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/event/training/{id}";
          },
          dataPath: () => {},
          replace: {
            id: () => {
              return $f.getQueryPath(3);
            },
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          if (
            ["participants", "files", "results", "test"].indexOf(
              $f.getQueryPath(4)
            ) >= 0
          ) {
            return "Custom";
          }

          if ($f.getQueryPath(7)) {
            return "TrainingSessionOverview";
          }

          if ($f.getQueryPath(5)) {
            return "TrainingModuleOverview";
          }

          return "TrainingOverview";
        },
        configuration: {
          headerTemplate: function () {
            return this.state.specialProperties.headerOverview.component;
          },
          topTemplate: function () {
            switch ($f.getQueryPath(4)) {
              case "participants":
                return this.state.specialProperties.participants.component;
              case "files":
                return this.state.specialProperties.files.component;
              case "results":
                return this.state.specialProperties.testResults.component;
              case "test":
                return this.state.specialProperties.participantResults
                  .component;
                return;
            }

            return "";
          },
          includeProperties: () => {
            return ["_id", "title"];
          },
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
  },
};

export default Trainings;
