import { Link } from "react-router-dom";
import $f from "../../src";
import EmptyStates from "../emptyStates";
import $c from "../functions";

const TestResults = {
  properties: (args) => {
    const { editable } = args;
    return {
      _id: {
        label: "",
        type: "hidden",
        events: {},
        editable: editable,
        attributes: {
          className: "form-control",
        },
      },
      title: {
        label: "",
        type: "text",
        events: {},
        editable: editable,
        attributes: {
          className: "form-control",
        },
      },
      name: {
        label: "Name",
        type: "text",
        events: {},
        editable: editable,
        attributes: {
          className: "form-control",
        },
        formatValue: (value, row) => {
          if (row.participant === null) {
            return "-";
          }

          return row.participant
            ? $c.createAvatar(
                row.participant.profileImage,
                row.participant.firstName,
                row.participant.lastName
              )
            : $c.createAvatar(row.profileImage, row.firstName, row.lastName);
        },
      },
      passingGrade: {
        label: "Category",
        type: "text",
        events: {},
        editable: editable,
        attributes: {
          className: "form-control",
        },
        formatValue: (value, row) => {
          if (value) {
            switch (row.status) {
              case "Pass":
                return (
                  <span className="badge bg-success p-2 color-white rounded">
                    Pass
                  </span>
                );

              case "Fail":
                return (
                  <span className="badge bg-danger p-2 color-white rounded">
                    Fail
                  </span>
                );

              default:
                return (
                  <span className="badge bg-info p-2 rounded">
                    {row.status}
                  </span>
                );
            }
          }

          return (
            <span className="badge bg-light-purple p-2 color-black rounded">
              Practice test
            </span>
          );
        },
      },
      result: {
        label: "Result",
        type: "text",
        events: {},
        editable: editable,
        attributes: {
          className: "form-control",
        },
        formatValue: (value, row, moduleReferer) => {
          try {
            let passingGrade = moduleReferer?.props.data.data[0].passingGrade;

            if (passingGrade > 0) {
              switch (row.status) {
                case "Pass":
                  return <div className="badge bg-success">Pass</div>;
                  break;

                case "Fail":
                  return <div className="badge bg-danger">Fail</div>;
                  break;
              }
            }
          } catch (e) {}

          return "---";
        },
      },
      finalGrade: {
        label: "Grade",
        type: "text",
        events: {},
        editable: editable,
        attributes: {
          className: "form-control",
        },
        formatValue: (value, row) => {
          if (row.status == "Not Evaluated") {
            return "---";
          }

          return value != "NaN" ? value.concat("%") : "---";
        },
      },
      status: {
        label: "Status",
        type: "text",
        events: {},
        editable: editable,
        attributes: {
          className: "form-control",
        },
        formatValue: (value, row, moduleReferer) => {
          if (row.status == "Not Evaluated") {
            return "Pending evaluation";
          }

          return row.status ? row.status : "Not submitted";
        },
      },
    };
  },
  views: {
    list: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/exam/{id}/user-exam";
          },
          replace: {
            id: () => {
              return $f.getQueryPath(3);
            },
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Table";
        },
        configuration: {
          tableConfiguration: {
            headers: {
              name: {
                attributes: {
                  className: "w-50",
                },
              },
            },
            cellTemplates: {
              name: (comp, itm) => {
                if (!itm._id.data.status) {
                  return <span className="color-grey">{comp}</span>;
                }

                return (
                  <Link
                    to={"/event/test/".concat(itm._id.value).concat("/result")}
                    className="color-grey"
                  >
                    {comp}
                  </Link>
                );
              },
            },
          },
          emptyTemplate: () => {
            return EmptyStates.events.tests.results();
          },
          headerTemplate: () => {
            return (
              <div className="display-3-no-bold border-bottom-1">Results</div>
            );
          },
          includeProperties: ["name", "result", "finalGrade", "status"],
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
    result: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/exam/user-exam/{id}";
          },
          replace: {
            id: () => {
              return $f.getQueryPath(3);
            },
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "TestResult";
        },
        configuration: {
          headerTemplate: () => {
            return (
              <div className="display-3-no-bold border-bottom-1">Results</div>
            );
          },
          includeProperties: ["name", "passingGrade", "finalGrade", "status"],
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
  },
};

export default TestResults;
