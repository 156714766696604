import $f from "../src";
import base64 from "base-64";

const Login = {
  properties: () => {
    return {
      email: {
        label: "",
        type: "text",
        events: {},
        attributes: {
          className: "form-control",
          placeholder: "Type your email",
        },
      },
      password: {
        label: "",
        type: "password",
        events: {},
        attributes: {
          className: "form-control",
          placeholder: $f.getParameterByName("i")
            ? "Type new password"
            : "Type your password",
        },
      },
      confirmPassword: {
        label: "",
        type: "password",
        events: {},
        attributes: {
          className: "form-control",
          placeholder: "Retype password",
        },
      },
      otp: {
        label: "",
        type: "text",
        events: {},
        attributes: {
          className: "form-control",
          placeholder: "Type or paste the 6-digit code here...",
        },
      },
      submitOtp: {
        caption: "Submit",
        type: "button",
        attributes: {
          className: "btn btn-purple  w-100",
        },
        events: {},
      },
      submitPassword: {
        caption: "Create password",
        type: "button",
        attributes: {
          className: "btn btn-purple  w-100",
        },
        events: {
          onClick: function () {
            const postData = $f.getValuesFromModule(this.props.module);

            const view = this.props.module.state.view;
            let api = view.apis.changePassword;

            if ($f.getQueryPath(1) === "confirm-password") {
              api = view.apis.register;
            }
            let authorization = "Bearer " + $f.getParameterByName("i");

            $f.fetch(
              api.url,
              "POST",
              postData,
              { Authorization: authorization },
              {},
              (response) => {
                $f.createNotificationFromResponse(response.data);
                if (response.status == 200) {
                  localStorage.clear();
                  window.location.replace("/login");
                }
              }
            );
          },
        },
      },
      submitResetPassword: {
        caption: "Send me instructions",
        type: "button",
        attributes: {
          className: "btn btn-purple  w-100",
        },
        events: {
          onClick: function () {
            const postData = $f.getValuesFromModule(this.props.module);

            const view = this.props.module.state.view;
            const api = view.apis.submit;

            $f.fetch(api.url, "POST", postData, {}, {}, (response) => {
              $f.createNotificationFromResponse(response.data);
            });
          },
        },
      },
      changePassword: {
        caption: "Change password",
        type: "button",
        attributes: {
          className: "btn btn-purple  w-100",
        },
        events: {
          onClick: function () {
            const postData = $f.getValuesFromModule(this.props.module);

            const view = this.props.module.state.view;
            const api = view.apis.changePassword;
            let authorization = "Bearer " + $f.getParameterByName("i");

            $f.fetch(
              api.url,
              "POST",
              postData,
              { Authorization: authorization },
              {},
              (response) => {
                $f.createNotificationFromResponse(response.data);
                if (response.status == 200) {
                  localStorage.clear();
                  window.location.replace("/login");
                }
              }
            );
          },
        },
      },
      loginBtn: {
        caption: "Login",
        type: "button",
        attributes: {
          className: "btn btn-purple  w-100",
        },
        events: {
          onClick: function () {
            const postData = $f.getValuesFromModule(this.props.module);
            const view = this.props.module.state.view;
            const api = view.apis.login;

            $f.fetch(
              api.url,
              "POST",
              postData,
              {},
              {},
              (response) => {
                let responseData = { ...response.data };
                if (response.status == 200) {
                  localStorage.setItem("jwt", responseData.jwt);
                  localStorage.setItem(
                    "extra",
                    base64.encode(JSON.stringify(responseData.extra))
                  );
                  if ($f.getParameterByName("redirect")) {
                    window.location.replace($f.getParameterByName("redirect"));
                  } else {
                    window.location.replace("/");
                  }
                }
              },
              null,
              null,
              null,
              null,
              false,
              (resp) => {
                let msg = resp.message;

                if (resp.response) {
                  if (resp.response.data) {
                    msg = resp.response.data.message;
                  }
                }

                $f.createNotification({
                  message: msg,
                  type: "danger",
                });
              }
            );
          },
        },
      },
    };
  },
  views: {
    list: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: { data: [{}] },
          dataPath: () => {},
          replace: {},
          headers: {},
          mapper: () => {},
        },
        login: {
          url: "/auth/login",
          replace: {},
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Login";
        },
        configuration: {
          includeProperties: () => {},
          excludeProperties: () => {},
          primaryKey: "",
        },
        properties: () => {},
      },
    },
    otp: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: { data: [{}] },
          dataPath: () => {},
          replace: {},
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "VerifyAccount";
        },
        configuration: {
          includeProperties: () => {},
          excludeProperties: () => {},
          primaryKey: "",
        },
        properties: () => {},
      },
    },
    confirmPassword: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: { data: [{}] },
          dataPath: () => {},
          replace: {},
          headers: {},
          mapper: () => {},
        },
        changePassword: {
          url: "/auth/saveNewPassword",
          dataPath: () => {},
          replace: {},
          headers: {},
          mapper: () => {},
        },
        register: {
          url: "/auth/register",
          dataPath: () => {},
          replace: {},
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          if ($f.getQueryPath(1) === "confirm-password") {
            return "CreatePassword";
          }

          if ($f.getParameterByName("i")) {
            return "ChangePassword";
          }
          return "CreatePassword";
        },
        configuration: {
          includeProperties: () => {},
          excludeProperties: () => {},
          primaryKey: "",
        },
        properties: () => {},
      },
    },
    resetPassword: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: { data: [{}] },
          dataPath: () => {},
          replace: {},
          headers: {},
          mapper: () => {},
        },
        submit: {
          url: "/auth/changePassword",
          dataPath: () => {},
          replace: {},
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "ResetPassword";
        },
        configuration: {
          includeProperties: () => {},
          excludeProperties: () => {},
          primaryKey: "",
        },
        properties: () => {},
      },
    },
    changePassword: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: { data: [{}] },
          dataPath: () => {},
          replace: {},
          headers: {},
          mapper: () => {},
        },
        changePassword: {
          url: "/auth/saveNewPassword",
          dataPath: () => {},
          replace: {},
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "ChangePassword";
        },
        configuration: {
          includeProperties: () => {},
          excludeProperties: () => {},
          primaryKey: "",
        },
        properties: () => {},
      },
    },
  },
};

export default Login;
