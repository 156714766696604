import { useState } from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import {
  useAudioVideo,
  useMeetingManager,
} from "amazon-chime-sdk-component-library-react";
import { MeetingSessionConfiguration } from "amazon-chime-sdk-js";
import $f from "../../../../src";
import { Link } from "react-router-dom";
import AssignParticipants from "./assignParticipants";
import React from "react";
import $c from "../../../../modules/functions";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import MeetingFunctions from "./functions";
import {
  breakoutAssignParticipants,
  createBreakout,
  fetchBreakoutRooms,
  fetchParticipants,
  renameBreakout,
} from "./apis";

const BreakoutRooms = (props) => {
  const [breakoutRooms, setBreakoutRooms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentBreakoutRoom, setCurrentBreakoutRoom] = useState(null);

  const meetingManager = useMeetingManager();
  const audioVideo = useAudioVideo();

  meetingManager?.audioVideo?.realtimeSubscribeToReceiveDataMessage(
    "UPDATEBREAKROOMS",
    (msg) => {
      getBreakoutRooms();
    }
  );

  const AssignBreakoutModal = (el) => {
    let templateRef = React.createRef();

    let Body = (
      <AssignParticipants
        ref={templateRef}
        selected={el.allowedAttendees}
        meeting={props.meeting}
      ></AssignParticipants>
    );

    window["meetingModal"].setState({
      show: true,
      size: "default",
      title: (
        <>
          <div className="display-3-no-line-height ">
            Assign participants
            <div className="display-5-no-margin color-grey">{el.Title}</div>
          </div>
        </>
      ),
      footer: [
        <button
          type="button"
          onClick={() => {
            window["meetingModal"].close();
          }}
          className="btn btn-sm btn-white"
        >
          Cancel
        </button>,
        <button
          type="button"
          onClick={() => {
            let participants = [...templateRef.current.state.selected];

            breakoutAssignParticipants(participants, el.id, (resp) => {
              window["meetingModal"].setState({ show: false });
              const mess = {
                uuid: $f.key(),
                action: "UPDATEBREAKROOMS",
                cmd: "TEXT",
                data: "UPDATEBREAKROOMS",
                createdDate: new Date().getTime(),
                attendeeId: "",
              };
              MeetingFunctions.sendMessage(
                meetingManager,
                "UPDATEBREAKROOMS",
                mess
              );
              getBreakoutRooms();
            });
          }}
          className="btn btn-sm btn-purple"
        >
          Assign
        </button>,
      ],
      body: Body,
    });
  };

  const RenameBreakoutModal = (el) => {
    let title = el.Title;
    window["meetingModal"].setState({
      show: true,
      size: "default",
      title: (
        <h2 className="display-3-no-line-height header-ellipsis">
          Rename room
        </h2>
      ),
      footer: [
        <button
          type="button"
          onClick={() => {
            window["meetingModal"].close();
          }}
          className="btn btn-sm btn-white"
        >
          Cancel
        </button>,
        <button
          type="button"
          onClick={() => {
            renameBreakout(el.id, title, (resp) => {
              window["meetingModal"].setState({ show: false });
              const mess = {
                uuid: $f.key(),
                action: "UPDATEBREAKROOMS",
                cmd: "TEXT",
                data: "UPDATEBREAKROOMS",
                createdDate: new Date().getTime(),
                attendeeId: "",
              };
              MeetingFunctions.sendMessage(
                meetingManager,
                "UPDATEBREAKROOMS",
                mess
              );
              getBreakoutRooms();
            });
          }}
          className="btn btn-sm btn-purple"
        >
          Rename
        </button>,
      ],
      body: (
        <div className="mb-3 mt-3">
          <div className="form-group ">
            <label className="label-top">Name</label>
            <input
              type="text"
              className="form-control"
              defaultValue={title}
              required="required"
              onChange={(e) => {
                title = e.target.value;
              }}
            ></input>
          </div>
        </div>
      ),
    });
  };

  const getBreakoutRooms = async () => {
    const data = await fetchBreakoutRooms($f.getQueryPath(2));

    setIsLoading(true);

    const breakrooms = [];

    data?.forEach((el) => {
      let toBePushed = false;

      if (
        MeetingFunctions.participantIsModerator(meetingManager, $c.getUserId())
      ) {
        toBePushed = true;
      } else {
        if (el.allowedAttendees?.indexOf($c.getUserId()) >= 0) {
          toBePushed = true;
        }
      }

      if (toBePushed) {
        if ($f.getParameterByName("breakout") === el.id) {
          setCurrentBreakoutRoom(el);
        }

        breakrooms.push(
          <div className="border p-3 rounded mt-3 bg-light-grey">
            <div className="d-flex">
              <div className="d-block display-5-no-margin w-100">
                {el.Title}
              </div>
              <div className="text-end">
                {$f.getParameterByName("breakout") === el.id ? (
                  <span className="color-purple">
                    <strong>Joined</strong>
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="color-grey pb-3 border-0 border-bottom mb-3 small mt-1">
              {el.allowedAttendees ? el.allowedAttendees.length : 0}{" "}
              participants
            </div>
            <div>
              {el.allowedAttendees?.map((pel) => {
                return (
                  <div className="d-block mb-4">
                    {MeetingFunctions.participantAvatar(pel, meetingManager)}
                  </div>
                );
              })}
            </div>
            <div>
              {MeetingFunctions.isCurrentModerator(
                meetingManager,
                audioVideo
              ) ? (
                <>
                  <button
                    className="btn btn-sm btn-white me-2"
                    onClick={() => {
                      AssignBreakoutModal(el);
                    }}
                  >
                    Assign
                  </button>
                  <button
                    className="btn btn-sm btn-white me-2"
                    onClick={() => {
                      RenameBreakoutModal(el);
                    }}
                  >
                    Rename
                  </button>
                </>
              ) : null}
              {$f.getParameterByName("breakout") === el.id ? (
                <span
                  className="btn btn-danger btn-sm"
                  onClick={() => {
                    MeetingFunctions.leaveBreakout(meetingManager);
                  }}
                >
                  Leave
                </span>
              ) : (
                <span
                  className="btn btn-purple btn-sm"
                  onClick={() => {
                    //meetingManager.leave();
                    window.location.replace(
                      "/" +
                        $f
                          .getQueryPath(1)
                          .concat("/", $f.getQueryPath(2), "?breakout=", el.id)
                    );

                    // meetingManager.leave();
                  }}
                >
                  Join
                </span>
              )}
            </div>
          </div>
        );
      }
    });

    setBreakoutRooms(breakrooms);
  };

  if (!isLoading) {
    getBreakoutRooms();
  }

  return (
    <div className="h-100 position-relative ">
      <div className="me-4">
        {MeetingFunctions.isCurrentModerator(meetingManager, audioVideo) ? (
          <>
            <span className="d-block display-4-no-margin mb-1">
              Breakout rooms
            </span>
            <span className="color-grey small d-block mb-3">
              Create your breakout rooms
            </span>
            <div className="input-group mb-3 d-block ">
              <button
                className="btn btn-sm btn-purple"
                type="button"
                onClick={async () => {
                  await createBreakout(
                    $f.getQueryPath(2),
                    "Room ".concat(breakoutRooms.length + 1)
                  );
                  setTimeout(getBreakoutRooms, 500);
                }}
              >
                Create new room
              </button>
            </div>
          </>
        ) : (
          <>
            <span className="d-block display-4-no-margin mb-1">
              Breakout rooms
            </span>
            <span className="color-grey small d-block mb-3">
              Your rooms will appear here
            </span>
          </>
        )}

        <div className="mt-4">
          <span className="d-block mb-1 color-grey">
            {currentBreakoutRoom ? (
              <>
                You are now in room <strong>{currentBreakoutRoom.Title}</strong>
              </>
            ) : (
              ""
            )}
          </span>
          <PerfectScrollbar>
            <div style={{ height: "calc(100vh - 20rem)" }} className="me-3">
              {breakoutRooms}
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    </div>
  );
};

export default BreakoutRooms;
