import $f from "../src";
import Data from "./data/data";
import $c from "./functions";

const LeftMenu = {
  properties: (args) => {
    const { editable } = args;
    return {
      id: {
        label: "",
        type: "text",
        events: {},
        editable,
        attributes: {},
      },
      title: {
        label: "",
        type: "text",
        events: {},
        editable,
        attributes: {},
      },
      url: {
        label: "",
        type: "text",
        events: {},
        editable,
        attributes: {},
      },
      children: {
        label: "",
        type: "text",
        events: {},
        editable,
        attributes: {},
      },
      isActive: {
        label: "",
        type: "text",
        events: {},
        editable,
        attributes: {},
      },
    };
  },
  views: {
    general: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            let path = window.location.pathname;

            switch (true) {
              case path.startsWith("/contacts"):
                return "/root-spaces";
                //return Data.LeftMenuData.contact;
                break;

              case path.startsWith("/user"):
                return Data.LeftMenuData.user;
                break;

              case path.startsWith("/files"):
                return "/root-spaces"; //Data.LeftMenuData.files;
                break;

              case path.startsWith("/event/training"):
                return "/training/"
                  .concat($f.getQueryPath(3))
                  .concat("/modules");
                break;

              case path.startsWith("/events"):
                return Data.LeftMenuData.events;
                break;
              case path.startsWith("/templates"):
                return Data.LeftMenuData.events;
                break;

              case path.startsWith("/event/meeting"):
                return Data.LeftMenuData.meetingOverview;
                break;

              case path.startsWith("/event/test"):
                if ($c.userIsMember) {
                  return "/event/exam/".concat($f.getQueryPath(3));
                } else {
                  return Data.LeftMenuData.testOverview;
                }

                break;

              case path.startsWith("/event/conference"):
                return Data.LeftMenuData.conference.private;
                break;

              case path.startsWith("/forms"):
                // return Data.LeftMenuData.forms;
                return "/forms?start=0&offset=1000";
                break;

              case path.startsWith("/form"):
                //return Data.LeftMenuData.form;
                return "/forms?start=0&offset=1000";
                break;

              case path.startsWith("/tests/assessments"):
                return Data.LeftMenuData.testEvaluation;
                break;
              case path.startsWith("/space"):
                return "/root-spaces";
                break;
            }

            return {};
          },
          formatResponse: (responseData) => {
            let path = window.location.pathname;

            if (path.startsWith("/files")) {
              let rootSpaces = [];

              responseData.data?.forEach((el) => {
                rootSpaces.push({
                  id: "/files/space/".concat(el._id),
                  title: el.name,
                  url: "/files/space/".concat(el._id),
                  children: [],
                  isActive: function () {
                    if (window.location.pathname.startsWith(this.url)) {
                      return true;
                    }
                    return false;
                  },
                });
              });

              Data.LeftMenuData.files.data[0].children[2].children = rootSpaces;

              return Data.LeftMenuData.files;
            }

            if (path.startsWith("/forms")) {
              let forms = [];

              responseData.data?.forEach((el) => {
                forms.push({
                  id: "/forms/".concat(el._id),
                  title: el.title,
                  url: "/forms/".concat(el._id),
                  children: [],
                  isActive: function () {
                    if (window.location.pathname.startsWith(this.url)) {
                      return true;
                    }
                    return false;
                  },
                });
              });

              Data.LeftMenuData.forms.data[0].children[0].children = forms;

              return Data.LeftMenuData.forms;
            }

            if (path.startsWith("/contacts")) {
              let rootSpaces = [];

              responseData.data?.forEach((el) => {
                rootSpaces.push({
                  id: "/contacts/space/".concat(el._id),
                  title: el.name,
                  url: "/contacts/space/".concat(el._id),
                  children: [],
                  isCollapsed: function () {
                    if (
                      window.location.pathname.startsWith(
                        "/contacts/space".concat(el._id)
                      )
                    ) {
                      return true;
                    }
                    return false;
                  },
                  isActive: function () {
                    if (window.location.pathname.startsWith(this.url)) {
                      return true;
                    }
                    return false;
                  },
                });
              });

              Data.LeftMenuData.contact.data[0].children[1].children =
                rootSpaces;

              return Data.LeftMenuData.contact;
            }

            if (path.startsWith("/space")) {
              let rootSpaces = [];

              responseData.data?.forEach((el) => {
                rootSpaces.push({
                  id: "/space/".concat(el._id),
                  title: el.name,
                  url: "/space/".concat(el._id),
                  children: [],
                  isCollapsed: function () {
                    if (
                      window.location.pathname.startsWith(
                        "/space/".concat(el._id)
                      )
                    ) {
                      return true;
                    }
                    return false;
                  },
                  isActive: function () {
                    if (window.location.pathname.startsWith(this.url)) {
                      return true;
                    }
                    return false;
                  },
                });
              });

              Data.LeftMenuData.spaces.data[0].children[1].children =
                rootSpaces;

              return Data.LeftMenuData.spaces;
            }

            if (path.startsWith("/event/test/")) {
              let results = {};

              if (responseData.data) {
                if (responseData.data[0]) {
                  if (responseData.data[0].userExamInfo) {
                    results = {
                      id: "/event/test/:id/result",
                      title: "Results",
                      url: () => {
                        try {
                          return "/event/test/"
                            .concat(responseData.data[0].userExamInfo._id)
                            .concat("/result");
                        } catch (e) {}
                      },
                      children: [],
                      isActive: function () {
                        try {
                          if (
                            window.location.pathname ==
                            "/event/test/"
                              .concat(responseData.data[0].userExamInfo._id)
                              .concat("/result")
                          ) {
                            return true;
                          }
                        } catch (e) {}
                        return false;
                      },
                    };
                    Data.LeftMenuData.testOverview.data[0].children[1] =
                      results;
                  } else {
                    results = {
                      id: "/event/test/:id/result",
                      title: "Results",
                      url: () => {
                        try {
                          return "/event/test/"
                            .concat(responseData.data[0]._id)
                            .concat("/results");
                        } catch (e) {}
                      },
                      children: [],
                      isActive: function () {
                        try {
                          if (
                            window.location.pathname ==
                            "/event/test/"
                              .concat(responseData.data[0]._id)
                              .concat("/results")
                          ) {
                            return true;
                          }
                        } catch (e) {}
                        return false;
                      },
                    };
                    Data.LeftMenuData.testOverview.data[0].children[1] =
                      results;
                  }
                }
              }

              return Data.LeftMenuData.testOverview;
            }

            if (path.startsWith("/event/training")) {
              let modules = [];

              responseData.data?.forEach((el, idx) => {
                console.log(el);
                modules.push({
                  id: "/event/training/"
                    .concat($f.getQueryPath(3))
                    .concat("/module/")
                    .concat(el.moduleId),
                  title: "Module ".concat(idx + 1),
                  url: "/event/training/".concat(
                    $f.getQueryPath(3),
                    "/module/",
                    el.moduleId,
                    "/session/",
                    el.sessionIds[0]
                  ),
                  children: [],
                  isActive: function () {
                    if (
                      window.location.pathname.startsWith(
                        "/event/training/".concat(
                          $f.getQueryPath(3),
                          "/module/",
                          el.moduleId,
                          "/session/",
                          el.sessionIds[0]
                        )
                      )
                    ) {
                      return true;
                    }
                    return false;
                  },
                });
              });

              Data.LeftMenuData.training.data[0].children[0].children = modules;

              return Data.LeftMenuData.training;
            }

            return Data.LeftMenuData.files;
          },
          replace: {},
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: "LeftMenu",
        configuration: {
          primaryKey: "id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
  },
};

export default LeftMenu;
