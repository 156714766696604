import React, { Component } from "react";
import parse from "html-react-parser";
import $c from "../../../../modules/functions";
import $f from "../../../../src";
import { Link } from "react-router-dom";

class TrainingModuleOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loaded: false,
      training: {},
    };
  }

  componentDidMount() {
    const { data, module, view, specialProperties } = this.props;

    let templateConfiguration = view.template.configuration;

    const innerTemplates = ["header"];

    innerTemplates.map((itm, idx) => {
      let tmp = templateConfiguration[itm + "Template"]
        ? templateConfiguration[itm + "Template"]
        : () => {};
      this[itm + "Template"] = tmp.bind(this);
    });

    let trainingModule = null;

    let training = this.props.response.data[0];

    training?.modules.forEach((el) => {
      if (el._id == $f.getQueryPath(5)) {
        trainingModule = el;
      }
    });

    this.setState({
      specialProperties,
      data,
      loaded: true,
      view,
      module,
      training: training,
      trainingModule,
    });
  }

  render() {
    if (this.state.loaded) {
      const data = this.state.data[0];
      return (
        <React.Fragment>
          {this.headerTemplate()}
          <div className="display-3-no-bold ">
            {this.state.trainingModule.title}
          </div>
          {this.state.specialProperties.sessions.component}
          {this.state.trainingModule.description ? (
            <div className=" mt-3 color-grey">
              {parse(this.state.trainingModule.description)}
            </div>
          ) : (
            <div className="mt-3 color-grey">[No description]</div>
          )}
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default TrainingModuleOverview;
