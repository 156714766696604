import $c from "../functions";
import $f from "../../src";
import Modals from "../modals";
import Actions from "../actions/actions";
import EmptyStates from "../emptyStates";

const CompanyFiles = {
  properties: (args) => {
    const { editable } = args;

    return {
      _id: {
        label: "",
        path: "_id",
        type: "text",
        events: {},
        attributes: {},
      },
      title: {
        label: "Name",
        type: "text",
        editable: editable,
        events: {},
        attributes: {},
      },
      optionsfortable: {
        label: "",
        type: "optionsfortable",
        configuration: {
          options: {
            open: function (args) {
              if (args.props.data.isFolder) {
                return {
                  label: "Open",
                  onClick: function () {
                    args.props.history.push(
                      "/files/library?_id=".concat(args.props.data._id)
                    );
                  },
                };
              }

              return {
                label: "Preview",
                onClick: function () {
                  args.props.history.push(
                    "/files/preview/".concat(args.props.data._id)
                  );
                },
              };
            },
            edit: function (args) {
              let isOwner = false;

              try {
                if (args.props.data.owner._id == $c.getUserId()) {
                  isOwner = true;
                }
              } catch (e) {}

              if ($c.userIsAdmin()) {
                isOwner = true;
              }

              if (args.props.data.isFolder) {
                let newargs = {
                  props: { data: { ...args.props.data } },
                  action: "update",
                  title: isOwner ? "Edit folder" : "Preview folder",
                };

                return {
                  label: isOwner ? "Edit folder" : "Preview folder",
                  onClick: function () {
                    $f.getValue(Modals.files.editFolder, newargs);
                  },
                };
              }

              let newargs = {
                props: { data: { ...args.props.data } },
                action: "update",
                title: isOwner ? "Edit file" : "Preview file",
              };

              return {
                label: isOwner ? "Edit file" : "Preview file",
                onClick: function () {
                  $f.getValue(Modals.files.editFile, newargs);
                },
              };
            },
            copyLink: function (args) {
              if (args.props.data.isFolder) {
                return {
                  label: "Copy link",
                  onClick: function () {
                    $c.copyToClipboard(
                      window["host"](
                        "/files/library?_id=".concat(args.props.data._id)
                      )
                    );
                    $f.createNotification({
                      type: "success",
                      message: "Link copied!",
                    });
                  },
                };
              }

              return {
                label: "Copy link",
                onClick: function () {
                  $c.copyToClipboard(
                    window["host"](
                      "/files/preview/".concat(args.props.data._id)
                    )
                  );
                  $f.createNotification({
                    type: "success",
                    message: "Link copied!",
                  });
                },
              };
            },
            download: function (args) {
              if (args.props.data.isFolder) {
                return {
                  label: "Download folder",
                  onClick: function () {
                    $c.downloadFile(
                      "/folder/downloadContents/".concat(
                        args.props.data._id,
                        "?type=download"
                      ),
                      args.props.data.title
                    );
                  },
                };
              }

              return {
                label: "Download file",
                onClick: function () {
                  $c.downloadFile(
                    "/file/data/".concat(args.props.data._id, "?type=download"),
                    args.props.data.title
                  );
                },
              };
            },
            favorites: function (args) {
              let label = args.props.data.isFavorite
                ? "Remove from favorites"
                : "Add to favorites";

              if (args.props.data.isFolder) {
                return {
                  label: label,
                  onClick: function () {
                    if (args.props.data.isFavorite) {
                      Actions.files.removeFromFavorites(
                        args.props.data._id,
                        "folder"
                      );
                    } else {
                      Actions.files.addToFavorites(
                        args.props.data._id,
                        "folder"
                      );
                    }
                  },
                };
              }

              return {
                label: label,
                onClick: function () {
                  if (args.props.data.isFavorite) {
                    Actions.files.removeFromFavorites(
                      args.props.data._id,
                      "file"
                    );
                  } else {
                    Actions.files.addToFavorites(args.props.data._id, "file");
                  }
                },
              };
            },
            delete: function (args) {
              let isOwner = false;

              try {
                if (args.props.data.owner._id == $c.getUserId()) {
                  isOwner = true;
                }
              } catch (e) {}

              if ($c.userIsAdmin() || isOwner) {
                if (args.props.data.isFolder) {
                  return {
                    label: <span className="text-danger">Delete</span>,
                    onClick: function () {
                      $f.getValue(Modals.files.deleteFolder, args);
                    },
                  };
                }

                return {
                  label: <span className="text-danger">Delete</span>,
                  onClick: function () {
                    $f.getValue(Modals.files.deleteFile, args);
                  },
                };
              }
            },
          },
        },
      },
      authors: {
        label: "Authors",
        type: "text",
        editable: editable,
        events: {},
        attributes: {},
      },
      updatedAt: {
        label: "Last Modified",
        type: "text",
        editable: editable,
        events: {},
        attributes: {},
      },
      size: {
        label: "Size",
        type: "text",
        editable: editable,
        events: {},
        attributes: {},
      },
    };
  },
  specialProperties: () => {
    return {
      header: {
        type: "template",
        configuration: {
          title: "Company Files",
          subTitle: "This is the company files",
          template: "Header",
          buttons: (args) => {
            if ($c.userIsAdmin()) {
              return (
                <>
                  <button
                    type="button"
                    className="btn btn-white btn-sm me-2"
                    onClick={() => {
                      let newArgs = { ...args };

                      newArgs.inputData = [
                        {
                          parent: $f.getParameterByName("_id")
                            ? $f.getParameterByName("_id")
                            : $c.getLibraryDirectory(),
                        },
                      ];

                      $f.getValue(Modals.files.newFolder, newArgs);
                    }}
                  >
                    New Folder
                  </button>
                  <button
                    type="button"
                    className="btn btn-purple  btn-sm  "
                    onClick={() => {
                      const fileTemplateRef = args.module.refTemplate.current;

                      fileTemplateRef.onTargetClick();
                    }}
                  >
                    Upload Files
                  </button>
                </>
              );
            }
          },
        },
        events: {},
      },
      breadcrumb: {
        type: "breadcrumb",
        events: {},
        attributes: {},
        configuration: {
          type: "files",
        },
      },
    };
  },
  views: {
    companyFiles: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            if ($f.getParameterByName("_id")) {
              return "/folder/".concat($f.getParameterByName("_id"));
            }
            return "/library";
          },
          dataPath: () => {
            return "data.0.allFiles";
          },
          formatResponse: function (resp) {
            return $c.formatFileResponse(resp);
          },
          replace: {},
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          if ($c.userIsAdmin()) {
            return "FileTable";
          }
          return "Table";
        },
        configuration: {
          headerTemplate: function () {
            return (
              <>
                {this.state.specialProperties.header.component}
                {this.state.specialProperties.breadcrumb.component}
              </>
            );
          },
          filetableConfiguration: {
            url: function (args) {
              return "/file";
            },
            fileDataProperty: "file",
            folder: function (args) {
              return $f.getParameterByName("_id")
                ? $f.getParameterByName("_id")
                : $c.getLibraryDirectory();
            },
          },
          emptyTemplate: (args) => {
            let newArgs = { ...args };

            newArgs.viewOnly = !$c.userIsAdmin();
            newArgs.subtitle = "Company files will appear here";
            return $f.getValue(EmptyStates.defaultFiles, newArgs);
          },
          tableConfiguration: {
            headers: {
              title: {
                attributes: {
                  className: "w-50",
                },
              },
            },
            cellTemplates: {
              title: (data) => {
                return $c.createFileTitleForTable(data);
              },
              updatedAt: (value) => {
                return $c.formatDateTime(value.props.value);
              },
            },
          },
          includeProperties: () => {
            return ["title", "optionsfortable", "updatedAt", "size"];
          },
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
  },
};

export default CompanyFiles;
