import React, { Component } from "react";
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";

class SelectBoxes extends Component {
  constructor(props) {
    super(props);

    this.check = this.check.bind(this);

    this.state = {
      data: [],
      loaded: false,
    };
  }

  componentDidMount() {
    const { data, module, view } = this.props;

    this.setState({
      loaded: true,
      checkedOption: null,
      data,
      module,
      view,
    });

    try {
      document
        .getElementsByClassName("scheduleEventNextBtn")[0]
        .classList.add("disabled");
    } catch (e) {}
  }

  check(type, checked, checkedValue) {
    this.setState({
      checkedOption: checked ? type : null,
      checkedValue: checkedValue,
    });
    const _this = this;
    setTimeout(function () {
      if (_this.state.checkedOption) {
        document
          .getElementsByClassName("scheduleEventNextBtn")[0]
          .classList.remove("disabled");
      } else {
        document
          .getElementsByClassName("scheduleEventNextBtn")[0]
          .classList.add("disabled");
      }
    }, 0);
  }

  render() {
    if (this.state.loaded) {
      const _this = this;

      return (
        <div>
          {this.state.data.map((el) => {
            return (
              <label
                className="p-3 rounded border mb-3 a-hover-grey w-100"
                role="button"
              >
                <div className="d-flex">
                  <h3 className="display-4-no-margin w-100">
                    {el.title.component}
                  </h3>
                  <Checkbox
                    onChange={(checked) => {
                      _this.check(el._id.value, checked, el._id.data);
                    }}
                    checked={
                      _this.state.checkedOption == el._id.value ? true : false
                    }
                    className="checkbox"
                    icon={
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          backgroundColor: true ? "#4e2c95" : "#fff",
                          alignSelf: "stretch",
                        }}
                      >
                        <Icon.FiCheck color="white" size={20} />
                      </div>
                    }
                    borderColor={"#4e2c95"}
                    borderRadius={20}
                    style={{
                      overflow: "hidden",
                      backgroundColor: "#fff",
                    }}
                    size={23}
                  />
                </div>
                <h5 className="display-5-no-bold color-grey">
                  {el.subTitle ? el.subTitle.component : ""}
                </h5>
              </label>
            );
          })}
        </div>
      );
    } else {
      return "loading...";
    }
  }
}

export default SelectBoxes;
