import axios from "axios";
import React from "react";
import { Component } from "react";
import { InputGroup } from "react-bootstrap";
import $c from "../../modules/functions";
import $f from "../../src";

class FileUploader extends Component {
  state = {
    data: [],
    loaded: false,
    title: [],
    body: [],
    footer: [],
    show: false,
    size: "",
    showClose: true,
    overflowY: "auto",
    showProgress: false,
    value: [],
  };

  constructor(props) {
    super(props);

    this.onDrop = this.onDrop.bind(this);
    this.statusIcon = this.statusIcon.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
    this.openModal = this.openModal.bind(this);
    this.clearAll = this.clearAll.bind(this);
  }

  componentDidMount() {
    let events = [];

    this.setState({
      loaded: true,
      files: [],
      uploadFiles: {},
      value: [],
    });
  }

  onDrop(files, configuration, module) {
    let filesArray = Array.from(files);

    filesArray.map((file) => {
      const postData = new FormData();
      if (configuration?.type) {
        postData.set("type", $f.getValue(configuration.type, this));
      }

      postData.set(
        configuration.fileDataProperty
          ? $f.getValue(configuration.fileDataProperty, this)
          : "file",
        file
      );

      if (configuration?.folder) {
        postData.set("folder", $f.getValue(configuration.folder, this));
      }

      const fileId = $f.key();

      let uploadFiles = this.state.uploadFiles;

      const cancelToken = axios.CancelToken.source();

      const _this = this;

      uploadFiles[fileId] = {
        title: file.name,
        progress: 0,
        fileSize: file.size,
        mimetype: file.type,
        status: "init",
        cancelToken: cancelToken,
      };

      this.setState({ uploadFiles: uploadFiles });

      $f.fetch(
        $f.getValue(configuration.url, this),
        "POST",
        postData,
        {},
        {},
        (response) => {
          const data = response.data;

          let uploadFiles = this.state.uploadFiles;

          delete uploadFiles[fileId];

          _this.setState({
            value: [
              ..._this.state.value,
              data.data[0] ? data.data[0] : data.data,
            ],
            uploadFiles: uploadFiles,
          });

          try {
            module.doWork();
          } catch (e) {}
        },
        null,
        null,
        (resp) => {
          let uploadFiles = _this.state.uploadFiles;

          let progress = Math.floor((resp.loaded / file.size) * 100);
          progress = progress > 100 ? 100 : progress;

          uploadFiles[fileId].uploaded = resp.loaded;
          uploadFiles[fileId].progress = progress;
          uploadFiles[fileId].status =
            progress == 100 ? "completed" : "pending";

          _this.setState({ uploadFiles: uploadFiles });
        },
        cancelToken.token
      );
    });
  }

  deleteFile(idx) {
    let value = [...this.state.value];
    value.splice(idx, 1);
    this.setState({ value: value });
  }

  statusIcon(fileObject, idx) {
    const status = fileObject ? fileObject.status : "done";
    switch (status) {
      case "pending":
        return (
          <i
            className="fas fa-trash-alt"
            role="button"
            onClick={() => {
              fileObject.cancelToken.cancel();
            }}
          ></i>
        );
      default:
        return (
          <>
            <i
              className="fas fa-trash-alt"
              role="button"
              onClick={() => {
                this.deleteFile(idx);
              }}
            ></i>
          </>
        );

        break;
    }
  }

  clearAll() {
    this.setState({ value: [], uploadFiles: {} });
  }

  openModal() {
    this.setState({ showProgress: this.state.showProgress ? false : true });
  }

  editable() {
    let uploaded = this.state.value.length;
    let total =
      Object.keys(this.state.uploadFiles).length + this.state.value.length;

    return (
      <div className="fileupload-section border shadow p-3 ">
        <div className="d-flex">
          <div className="w-75">
            <img
              src="/uni/svg/files_uploading.svg"
              className="me-2"
              style={{ width: "20px" }}
            ></img>
            <span className="pt-1" style={{ lineHeight: "33px" }}>
              {/* {this.state.content.length} files uploading */}
              {uploaded}/{total} uploaded
            </span>
          </div>
          <div className="w-25 text-end">
            {uploaded == total ? (
              <span
                className="btn btn-link btn-sm"
                onClick={this.clearAll}
                role="button"
              >
                Done
              </span>
            ) : (
              <span
                className="btn btn-link btn-sm"
                onClick={this.openModal}
                role="button"
              >
                {this.state.showProgress ? "Hide" : "View Status"}
              </span>
            )}
          </div>
        </div>
        <div className={this.state.showProgress ? "mt-3" : "d-none"}>
          <div style={{ maxHeight: "200px", overflowY: "auto" }}>
            {Object.keys(this.state.uploadFiles)?.map((el) => {
              return (
                <div className="card mt-2 p-3 ">
                  <div className="d-flex">
                    {$c.getFontFileIcon(this.state.uploadFiles[el].mimetype)}
                    <div className="w-100 me-2 mb-2">
                      <div className="w-100 d-flex">
                        <div className="w-100">
                          <div className="mb-2">
                            <strong>{this.state.uploadFiles[el].title}</strong>
                          </div>
                          <div className="display-5 color-grey mb-1">
                            {$c.formatBytes(
                              this.state.uploadFiles[el].uploaded
                            )}{" "}
                            from{" "}
                            {$c.formatBytes(
                              this.state.uploadFiles[el].fileSize
                            )}{" "}
                            uploaded
                          </div>
                        </div>
                        <div>{this.statusIcon(this.state.uploadFiles[el])}</div>
                      </div>
                      <div className="d-flex">
                        <div
                          className="progress w-100  "
                          style={{ height: "8px" }}
                        >
                          <div
                            className="progress-bar"
                            role="progressbar"
                            aria-valuenow={this.state.uploadFiles[el].progress}
                            aria-valuemin="0"
                            aria-valuemax={this.state.uploadFiles[el].fileSize}
                            style={{
                              width: this.state.uploadFiles[el].progress + "%",
                              backgroundColor: "#4E2C95",
                            }}
                          ></div>
                        </div>
                        <div style={{ marginTop: "-8px" }} className="ms-3">
                          {this.state.uploadFiles[el].progress + "%"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            {this.state.value?.map((el, idx) => {
              return (
                <div className="card mt-2 p-3">
                  <div className="d-flex">
                    {$c.getFontFileIcon(el.mimetype)}
                    <div className="w-100 me-2 mb-2">
                      <div className="w-100 d-flex">
                        <div className="w-100">
                          <div className="mb-2">
                            <strong>{el.title}</strong>
                          </div>
                          <div className="display-5 color-grey mb-1">
                            {el.subTitle}
                          </div>
                        </div>
                        <div className="d-flex">{this.statusIcon(el, idx)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.loaded) {
      if (
        this.state.value.length > 0 ||
        Object.keys(this.state.uploadFiles).length > 0
      ) {
        return this.editable();
      }
    }

    return "";
  }
}

export default FileUploader;
