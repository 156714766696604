import $f from "../../src";
import $c from "../functions";
import Modals from "../modals";

const TestTemplates = {
  properties: (args) => {
    const { editable } = args;

    return {
      _id: {
        label: "",
        path: "_id",
        type: "hidden",
        events: {},
        attributes: {},
      },
      title: {
        label: "Title",
        type: "text",
        editable: editable,
        validation: "required",
        events: {},
        attributes: {
          className: "form-control",
        },
      },
      optionsfortable: {
        label: "",
        type: "optionsfortable",
        configuration: {
          options: {
            edit: function (args) {
              let newargs = {
                props: { data: { ...args.props.data } },
                action: "update",
              };

              return {
                label: "Edit",
                onClick: () => {
                  $f.getValue(Modals.events.editTestTemplate, newargs);
                },
              };
            },
            duplicate: function (args) {
              return {
                label: "Duplicate",
                onClick: () => {
                  $f.getValue(Modals.events.duplicateTestTemplate, args);
                },
              };
            },
            delete: function (args) {
              return {
                label: <span className="text-danger">Delete</span>,
                onClick: () => {
                  $f.getValue(Modals.events.deleteTestTemplate, args);
                },
              };
            },
          },
        },
      },
      instructions: {
        label: "Instructions",
        type: "editor",
        editable: editable,
        validation: "required",
        events: {},
        attributes: {
          className: "form-control",
        },
      },
      passingGradeEdit: {
        label: "Passing grade (%)",
        type: "passinggrade",
        path: "passingGrade",
        events: {},
        editable: editable,
        validation: "required|min:0,num|max:100,num",
        attributes: {
          className: "form-control",
        },
      },
      questions: {
        label: "Questions",
        type: "examquestions",
        editable: editable,
        events: {},
        attributes: {},
      },
      createdBy: {
        label: "Created By",
        type: "text",
        editable: editable,
        events: {},
        attributes: {},
        formatValue: (value, row) => {
          return row.owner ? row.owner.fullName : "";
        },
      },
      createdAt: {
        label: "Created on",
        type: "text",
        editable: editable,
        events: {},
        attributes: {},
        formatValue: (value, row) => {
          return $c.formatDateTime(row.createdAt);
        },
      },
      updatedAt: {
        label: "Last modified",
        type: "text",
        editable: editable,
        events: {},
        attributes: {},
        formatValue: (value, row) => {
          return $c.formatDateTime(row.updatedAt);
        },
      },
    };
  },
  specialProperties: () => {
    return {
      header: {
        type: "template",
        configuration: {
          title: "Test Templates",
          subTitle: "View all available test templates",
          template: "Header",
          buttons: (args) => {
            return [
              <button
                type="button"
                className="btn btn-purple  btn-sm  "
                onClick={() => {
                  $f.getValue(Modals.events.editTestTemplate, {
                    title: "Create template",
                    api: "new",
                    action: "create",
                  });
                }}
              >
                Create template
              </button>,
            ];
          },
        },
        events: {},
      },
      pagination: {
        type: "pagination",
        configuration: {
          start: function (props) {
            return 0;
          },
          offset: "25",
          total: function (props) {
            return props.props.data.pagination.total;
          },
          current: function (props) {
            return props.props.data.pagination.currentPage;
          },
          replaceAttribute: "start",
        },
        events: {},
      },
    };
  },
  views: {
    list: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/template/exam?start={start}&offset={offset}";
          },
          dataPath: () => {},
          replace: {
            start: 0,
            offset: 25,
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Table";
        },
        configuration: {
          headerTemplate: function () {
            return this.state.specialProperties.header.component;
          },
          bottomTemplate: function () {
            return this.state.specialProperties.pagination.component;
          },
          tableConfiguration: {
            headers: {
              title: {
                attributes: {
                  className: "w-50",
                },
              },
            },
            cellTemplates: {
              title: (comp, itm) => {
                return (
                  <span
                    className="color-grey d-block"
                    role="button"
                    onClick={(e) => {
                      const args = {
                        action: "update",
                        props: {
                          data: {
                            _id: itm._id.value,
                          },
                        },
                      };

                      $f.getValue(Modals.events.editTestTemplate, args);
                    }}
                  >
                    {comp}
                  </span>
                );
              },
            },
          },
          includeProperties: () => {
            return [
              "title",
              "optionsfortable",
              "createdBy",
              "createdAt",
              "updatedAt",
            ];
          },
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
    selectBoxes: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/template/exam?start={start}&offset={offset}";
          },
          dataPath: () => {},
          replace: {
            start: 0,
            offset: 1000,
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "SelectBoxes";
        },
        configuration: {
          includeProperties: () => {
            return ["title", "_id"];
          },
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
    detail: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/template/exam/{id}";
          },
          dataPath: () => {},
          replace: {
            start: 0,
            offset: 20,
          },
          headers: {},
          mapper: () => {},
        },
        new: {
          url: () => {
            return { data: [{}] };
          },
          dataPath: () => {},
          replace: {},
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Form";
        },
        configuration: {
          includeProperties: () => {
            return [
              "_id",
              "title",
              "instructions",
              "passingGradeEdit",
              "questions",
            ];
          },
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: true };
        },
      },
    },
  },
};

export default TestTemplates;
