import { Component } from "react";
import { withRouter } from "react-router";
import $f from "../../src";

class OptionsForTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      configuration: {},
    };
  }

  componentDidMount() {
    let events = [];

    this.setState({
      loaded: true,
      events: events,
      attributes: { ...this.props.property.attributes },
      configuration: { ...this.props.property.configuration },
      value: this.props.value,
    });
  }

  render() {
    if (this.state.loaded) {
      const collapseTarget = $f.key();

      let showList = false;

      Object.keys(this.state.configuration.options).map((el, idx) => {
        if ($f.getValue(this.state.configuration.options[el], this)) {
          showList = true;
        }
      });

      return this.state.configuration && showList ? (
        <span
          className="grid-controls position-relative"
          style={{ zIndex: 100 - this.props.rowIndex }}
        >
          <button
            type="button"
            className="btn btn-white btn-sm border-0 pt-0 pb-0"
            data-toggle="collapse"
            data-bs-toggle="collapse"
            data-bs-target={"#".concat(collapseTarget)}
            // style={{ height: "2rem" }}
          >
            <i className="fas fa-ellipsis-h" role="button"></i>
          </button>
          <div
            className="bg-white ml-4 mt-2 collapse position-absolute"
            id={collapseTarget}
            style={{
              bottom:
                this.props.rowIndex > this.props.dataLength - 7 &&
                this.props.dataLength > 7
                  ? "8rem"
                  : "auto",
            }}
          >
            <ul
              className="list-group dropdown-menu p-0 pt-2 pb-2 "
              style={{ width: "200px" }}
            >
              {Object.keys(this.state.configuration.options).map((el, idx) => {
                return $f.getValue(
                  this.state.configuration.options[el],
                  this
                ) ? (
                  <li
                    className={
                      Object.keys(this.state.configuration.options).length ==
                      idx + 1
                        ? "dropdown-item small pb-0 pt-0"
                        : "dropdown-item small pb-0 pt-0"
                    }
                    role="button"
                    key={$f.key()}
                    onClick={
                      $f.getValue(this.state.configuration.options[el], this)[
                        "onClick"
                      ]
                    }
                  >
                    {
                      $f.getValue(this.state.configuration.options[el], this)[
                        "label"
                      ]
                    }
                  </li>
                ) : (
                  ""
                );
              })}
            </ul>
          </div>
        </span>
      ) : (
        ""
      );
    }

    return "";
  }
}

export default withRouter(OptionsForTable);
