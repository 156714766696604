import parse from "html-react-parser";
import { Component } from "react";
import ReactTags from "react-tag-autocomplete";
import $c from "../../modules/functions";
import $f from "../../src";
import "./tags.css";

class Tags extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.formatSuggestions = this.formatSuggestions.bind(this);
    this.parentChange = function () {};

    this.state = {
      loaded: false,
    };
  }

  onChange(e) {
    this.setState({
      value: e.target.value,
    });

    setTimeout(this["customOnChange"], 0);
  }

  componentDidMount() {
    let events = [];

    Object.keys(this.props.property.events)?.forEach((element, idx) => {
      if (!element.toLowerCase().startsWith("onchange")) {
        this[element] = this.props.property.events[element];
        this[element] = this[element].bind(this);
        events[element] = this[element];
      } else {
        this["customOnChange"] = this.props.property.events[element];
        this["customOnChange"] = this["customOnChange"].bind(this);
      }
    });

    if (this.props.property.parentChange) {
      this.parentChange = this.props.property.parentChange.bind(this);
    }

    let value = [];

    if (Array.isArray(this.props.value)) {
      this.props?.value?.forEach((el) => {
        if (el) {
          value.push(el);
        }
      });
    }

    this.setState({
      loaded: true,
      events: events,
      attributes: { ...this.props.property.attributes },
      value: value,
      configuration: { ...this.props.property.configuration },
      editable: this.props.property.editable === false ? false : true,
      useCustomUI: this.props.property.useCustomUI,
      suggestions: [],
      placeholder: this.props.property.attributes.placeholder
        ? this.props.property.attributes.placeholder
        : "Search by email or name",
    });
  }

  onDelete(i) {
    let _this = this;
    const value = this.state.value.slice(0);
    value.splice(i, 1);
    this.setState({ value });
    setTimeout(function () {
      _this.parentChange(value);
    }, 0);
  }

  onAddition(tag) {
    try {
      let _this = this;
      let value = [];

      if (this.state.configuration.type == "sponsors") {
        tag.title = tag.name;
        tag._id = tag.id;

        value = value.concat(this.state.value, tag);
      } else if (
        ["sponsors", "instructors", "hosts"].indexOf(
          this.state.configuration.type
        ) >= 0
      ) {
        if (!tag.id) {
          return;
        }

        value = value.concat(this.state.value, tag);
      } else if (
        this.state.configuration.type == "all" ||
        this.state.configuration.type == "allandexternals"
      ) {
        if (!tag.id) {
          if ($c.isEmail(tag.name)) {
            tag["_id"] = tag.name.trim();
            tag["name"] = tag.name.trim();

            let splitParticipants = [];
            let notValidEmails = [];

            tag.name = tag.name.replaceAll(",", " ");
            tag.name = tag.name.replaceAll(";", " ");

            tag.name.split(" ").forEach((itm, idx) => {
              if (itm) {
                if ($c.isEmail(itm.trim())) {
                  splitParticipants.push({
                    id: itm.trim(),
                    name: itm.trim(),
                  });
                } else {
                  notValidEmails.push(
                    <span className="d-block">{itm.trim()}</span>
                  );
                }
              }
            });

            if (notValidEmails.length > 0) {
              $f.createNotification({
                type: "danger",
                message: (
                  <span>
                    The following inputs are not valid emails:<br></br>
                    {notValidEmails}
                  </span>
                ),
              });
            }
            value = value.concat(this.state.value, splitParticipants);
          } else {
            let splitParticipants = [];
            let notValidEmails = [];

            tag.name = tag.name.replaceAll(",", " ");
            tag.name = tag.name.replaceAll(";", " ");

            tag.name.split(" ").forEach((itm, idx) => {
              if (itm) {
                if ($c.isEmail(itm.trim())) {
                  splitParticipants.push({
                    id: itm.trim(),
                    name: itm.trim(),
                  });
                } else {
                  notValidEmails.push(
                    <span className="d-block">{itm.trim()}</span>
                  );
                }
              }
            });

            if (notValidEmails.length > 0) {
              $f.createNotification({
                type: "danger",
                message: (
                  <span>
                    The following inputs are not valid emails:<br></br>
                    {notValidEmails}
                  </span>
                ),
              });
            }
            value = value.concat(this.state.value, splitParticipants);
          }
        } else {
          value = value.concat(this.state.value, tag);
        }
      } else {
        if (!tag.id) {
          tag.id = tag.name;
        }

        value = value.concat(this.state.value, tag);
      }

      this.setState({ value });
      setTimeout(function () {
        _this.parentChange(value);
      }, 0);
    } catch (e) {
      console.log(e);
    }
  }

  formatSuggestions(suggestions) {
    let output = [];
    try {
      switch (this.state.configuration.type) {
        case "sponsors":
          suggestions?.forEach((itm) => {
            if (itm.title && itm.id) {
              output.push({ id: itm.id, name: itm.title });
            } else {
              output.push({ id: itm["_id"], name: itm.title });
            }
          });
          break;

        case "instructors":
          suggestions?.forEach((itm) => {
            let fullName =
              itm["firstName"] +
              " " +
              itm["lastName"] +
              (itm["email"] ? " - " + itm["email"] : "");
            if (itm.type == "group") {
              fullName = itm["firstName"] + " - " + itm["lastName"];
            }
            if (itm.name && itm.id) {
              output.push({ id: itm.id, name: itm.name });
            } else {
              output.push({ id: itm["_id"], name: fullName });
            }
          });

          break;

        case "hosts":
          suggestions?.forEach((itm) => {
            let fullName =
              itm["firstName"] +
              " " +
              itm["lastName"] +
              (itm["email"] ? " - " + itm["email"] : "");
            if (itm.type == "group") {
              fullName = itm["firstName"] + " - " + itm["lastName"];
            }
            if (itm.name && itm.id) {
              output.push({ id: itm.id, name: itm.name });
            } else {
              output.push({ id: itm["_id"], name: fullName });
            }
          });

          break;

        case "participants":
          suggestions?.forEach((itm) => {
            if (itm.name && itm.id) {
              output.push({ id: itm.id, name: itm.name, type: itm.type });
            } else {
              if (itm["_id"]) {
                let fullName =
                  itm["firstName"] +
                  " " +
                  itm["lastName"] +
                  " - " +
                  itm["email"];

                if (itm.type == "group") {
                  fullName = itm["firstName"] + " - " + itm["lastName"];
                }
                output.push({
                  id: itm["_id"],
                  name: fullName,
                  type: itm.type,
                });
              } else {
                if ($f.getQueryPath(1) !== "conference") {
                  output.push({ id: "", name: itm });
                }
              }
            }
          });

          break;

        case "all":
          suggestions?.forEach((itm) => {
            if (itm.name && itm.id) {
              output.push({ id: itm.id, name: itm.name, type: itm.type });
            } else {
              if (itm["_id"]) {
                let fullName =
                  itm["firstName"] +
                  " " +
                  itm["lastName"] +
                  " - " +
                  itm["email"];

                if (itm.type == "group") {
                  fullName = itm["firstName"] + " - " + itm["lastName"];
                }
                output.push({
                  id: itm["_id"],
                  name: fullName,
                  group: itm.type,
                  type: itm.type,
                });
              } else {
                if ($f.getQueryPath(1) !== "conference") {
                  output.push({ id: "", name: itm });
                }
              }
            }
          });

          break;

        case "allandexternals":
          suggestions?.forEach((itm) => {
            if (itm.name && itm.id) {
              output.push({ id: itm.id, name: itm.name, type: itm.type });
            } else {
              if (itm["_id"]) {
                let fullName =
                  itm["firstName"] +
                  " " +
                  itm["lastName"] +
                  " - " +
                  itm["email"];

                if (itm.type == "group") {
                  fullName = itm["firstName"] + " - " + itm["lastName"];
                }
                output.push({
                  id: itm["_id"],
                  name: fullName,
                  group: itm.type,
                  type: itm.type,
                });
              } else {
                output.push({ id: "", name: itm });
              }
            }
          });

          break;

        case "nogroups":
          suggestions?.forEach((itm) => {
            if (itm.type === "participant") {
              if (itm.name && itm.id) {
                output.push({ id: itm.id, name: itm.name, type: itm.type });
              } else {
                if (itm["_id"]) {
                  let fullName =
                    itm["firstName"] +
                    " " +
                    itm["lastName"] +
                    " - " +
                    itm["email"];

                  if (itm.type == "group") {
                    fullName = itm["firstName"] + " - " + itm["lastName"];
                  }
                  output.push({
                    id: itm["_id"],
                    name: fullName,
                    group: itm.type,
                    type: itm.type,
                  });
                } else {
                  if ($f.getQueryPath(1) !== "conference") {
                    output.push({ id: "", name: itm });
                  }
                }
              }
            }
          });

          break;

        default:
          suggestions?.forEach((itm) => {
            if (itm.name) {
              output.push({ id: itm.name, name: itm.name });
            } else {
              output.push({ id: itm, name: itm });
            }
          });

          break;
      }
    } catch (e) {
      console.log(e);
    }

    // let newFormat = output?.map((el) => {
    //   return { value: el.id, label: el.name };
    // });

    // console.log(output);
    return output;
  }

  onInput(e) {
    const _this = this;
    if (this.state.configuration) {
      if (this.state.configuration.type) {
        let url = this.state.configuration.url;
        switch (this.state.configuration.type) {
          case "sponsors":
            url = "/sponsors/autocomplete/{query}";
            break;
          case "instructors":
            url = "/user/autocomplete/{query}?type=instructors";
            break;
          case "hosts":
            url = "/user/autocomplete/{query}?type=hosts";
            break;
          case "participants":
            url = "/user/autocomplete/{query}?type=participants";
            break;

          case "allandexternals":
            url = "/user/autocomplete/{query}?type=all";
            break;
          case "all":
            url = "/user/autocomplete/{query}?type=all";
            break;
          case "nogroups":
            url = "/user/autocomplete/{query}?type=all";
            break;
        }

        $f.fetch(
          url,
          "GET",
          null,
          {},
          { query: e },
          (resp) => {
            let suggestions = [];
            if (resp) {
              if (resp.data) {
                if (resp.data.data) {
                  suggestions = resp.data.data;
                }
              }
            }
            _this.setState({ suggestions: suggestions });
          },
          null,
          null,
          null,
          null,
          false
        );
      }
    }
  }

  editable() {
    return (
      <ReactTags
        ref={this.ref}
        tags={this.formatSuggestions(this.state.value)}
        suggestions={this.formatSuggestions(this.state.suggestions)}
        onDelete={this.onDelete.bind(this)}
        onAddition={this.onAddition.bind(this)}
        addOnBlur={true}
        onInput={this.onInput.bind(this)}
        allowNew={true}
        maxSuggestionsLength={1000}
        placeholderText={this.state.placeholder}
        suggestionsFilter={function () {
          return true;
        }}
        classNames={{
          root: "react-tags form-control h-100 rounded",
          rootFocused: "is-focused",
          selected: "react-tags__selected",
          selectedTag: "react-tags__selected-tag rounded me-1 mt-1",
          selectedTagName: "react-tags__selected-tag-name",
          search: "react-tags__search",
          searchInput: "react-tags__search-input",
          suggestions: "react-tags__suggestions",
          suggestionActive: "is-active",
          suggestionDisabled: "is-disabled",
        }}
      />
    );
  }

  nonEditable() {
    try {
      return this.state.value.join(", ");
    } catch (e) {}

    return typeof this.state.value === "string"
      ? parse(this.state.value)
      : this.state.value;
  }

  customUI() {}

  render() {
    if (this.state.loaded) {
      if (this.state.useCustomUI) {
        return this.customUI();
      } else if (this.state.editable) {
        return this.editable();
      } else {
        return this.nonEditable();
      }
    }

    return "";
  }
}

export default Tags;
