import { Link } from "react-router-dom";
import $f from "../../src";
import $c from "../functions";

const TestEvaluations = {
  properties: (args) => {
    const { editable } = args;
    return {
      _id: {
        label: "",
        type: "hidden",
        events: {},
        editable: editable,
        attributes: {
          className: "form-control",
        },
      },
      title: {
        label: "Title",
        type: "text",
        events: {},
        editable: editable,
        attributes: {
          className: "form-control",
        },
      },
      passingGrade: {
        label: "",
        type: "text",
        events: {},
        editable: editable,
        attributes: {
          className: "form-control",
        },
        formatValue: (value, row) => {
          if (value) {
            return (
              <span
                className="badge bg-light-blue p-2 color-black rounded"
                style={{ width: "5rem" }}
              >
                Graded
              </span>
            );
          }
          return (
            <span
              className="badge bg-light-purple p-2 color-black rounded"
              style={{ width: "5rem" }}
            >
              Practice
            </span>
          );
        },
      },
      submitted: {
        label: "Submitted on",
        type: "text",
        events: {},
        editable: editable,
        formatValue: (value, row) => {
          return $c.formatDateTime(row.finalizedOn);
        },
        attributes: {
          className: "form-control",
        },
      },
    };
  },
  specialProperties: () => {
    return {
      header: {
        type: "template",
        configuration: {
          title: "Test assessments",
          subTitle: "In this page you will find your pending tests to grade.",
          template: "Header",
        },
        events: {},
      },
      pagination: {
        type: "pagination",
        configuration: {
          start: function (props) {
            return 0;
          },
          offset: "25",
          total: function (props) {
            return props.props.data.pagination.total;
          },
          current: function (props) {
            return props.props.data.pagination.currentPage;
          },
          replaceAttribute: "start",
        },
        events: {},
      },
    };
  },
  views: {
    list: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/results/other?start={start}&offset={offset}";
          },
          replace: {
            start: 0,
            offset: 25,
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Table";
        },
        configuration: {
          tableConfiguration: {
            headers: {
              title: {
                attributes: {
                  className: "w-50",
                },
              },
            },
            cellTemplates: {
              title: (comp, itm) => {
                return (
                  <Link
                    to={"/tests/assessments/".concat(itm._id.value)}
                    className="color-grey"
                  >
                    {comp}
                  </Link>
                );
              },
            },
          },
          headerTemplate: function () {
            return this.state.specialProperties.header.component;
          },

          includeProperties: ["title", "passingGrade", "submitted"],
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
    result: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/exam/user-exam/{id}";
          },
          replace: {
            id: () => {
              return $f.getQueryPath(3);
            },
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "EvaluatorTest";
        },
        configuration: {
          headerTemplate: () => {
            return (
              <div className="display-3-no-bold border-bottom-1">Results</div>
            );
          },
          includeProperties: ["name", "passingGrade", "finalGrade", "status"],
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
  },
};

export default TestEvaluations;
