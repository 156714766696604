import { Component } from "react";
import { withRouter } from "react-router";
import $c from "../../../../modules/functions";
import $f from "../../../../src";
import Header from "../header";
import SimpleReactValidator from "simple-react-validator";
import ReactQuill from "react-quill";
import "quill/dist/quill.snow.css";
import parse from "html-react-parser";

class JobDetails extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();
    this.validate = this.validate.bind(this);
    this.onChange = this.onChange.bind(this);
    this.update = this.update.bind(this);
    this.onChangeExtraFields = this.onChangeExtraFields.bind(this);
    this.checkIfChanged = this.checkIfChanged.bind(this);
    this.state = {
      loaded: false,
      edit: false,
      hasChanges: false,
    };
  }

  validate() {
    const isValid = this.validator.allValid();

    this.setState({ isValid });
    this.validator.showMessages();
    this.forceUpdate();

    return isValid;
  }

  componentDidMount() {
    const { data, module, view, specialProperties, response } = this.props;

    let templateConfiguration = view.template.configuration;

    const innerTemplates = ["header", "top", "bottom"];

    innerTemplates.map((itm, idx) => {
      let tmp = templateConfiguration[itm + "Template"]
        ? templateConfiguration[itm + "Template"]
        : () => {};
      this[itm + "Template"] = tmp.bind(this);
    });

    let {
      jobTitle,
      biography,
      firstName,
      lastName,
      extraFields,
      _id,
      status,
      profileImage,
    } = response
      ? response.data[0]
      : {
          jobTitle: "",
          biography: "",
          extraFields: [],
          _id: "",
          status: "",
          profileImage: null,
        };

    let extraFieldsValue = {};

    if (response.data) {
      if (response.data[0]) {
        extraFields.forEach((el) => {
          extraFieldsValue[el.label] = response.data[0][el.label];
        });
      }
    }

    this.setState({
      specialProperties,
      data,
      loaded: true,
      view,
      module,
      response,
      jobTitle,
      biography,
      firstName,
      lastName,
      profileImage,
      _id,
      status,
      extraFieldsValue,
      initial: {
        jobTitle,
        biography,
        extraFieldsValue,
      },
    });
  }

  checkIfChanged() {
    let initialValues = this.state.initial;

    let hasChanges = false;
    if (
      initialValues.jobTitle !== this.state.jobTitle ||
      initialValues.biography !== this.state.biography
    ) {
      hasChanges = true;
    }

    Object.keys(initialValues.extraFieldsValue)?.forEach((el) => {
      if (
        initialValues.extraFieldsValue[el] !== this.state.extraFieldsValue[el]
      ) {
        hasChanges = true;
      }
    });

    return hasChanges;
  }

  onChange(value, property) {
    this.setState({ [property]: value });
  }

  onChangeExtraFields(value, property) {
    let extraFieldsValue = { ...this.state.extraFieldsValue };

    extraFieldsValue[property] = value;

    this.setState({ extraFieldsValue });
  }

  update() {
    let _this = this;

    let postData = {
      _id: this.state._id,
      jobTitle: this.state.jobTitle,
      biography: this.state.biography,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      profileImage: this.state.profileImage,
    };

    Object.keys(this.state.extraFieldsValue)?.forEach((el) => {
      postData[el] = this.state.extraFieldsValue[el];
    });

    $f.fetch("/user", "PATCH", postData, {}, {}, (resp) => {
      $f.createNotificationFromResponse(resp.data);
      _this.props.module.doWork();
    });
  }

  render() {
    if (this.state.loaded) {
      const toolbarOptions = [
        ["bold", "italic", "underline", "strike", "link"],
        [{ list: "ordered" }, { list: "bullet" }],
      ];

      return (
        <>
          <Header
            data={{
              title: this.state.firstName.concat(" ", this.state.lastName),
              subTitle:
                $c.getUserId() !== this.state._id && !$c.userIsAdmin()
                  ? "Job details"
                  : "Manage job details",
              buttons: (args) => {
                if ($c.userIsAdmin() || $c.getUserId() === this.state._id) {
                  if (this.state.edit) {
                    return [
                      <button
                        className="btn btn-sm btn-white me-2"
                        onClick={() => {
                          this.props.module.doWork();
                        }}
                      >
                        Cancel
                      </button>,
                      <button
                        className="btn btn-sm btn-purple"
                        onClick={this.update}
                        disabled={!this.checkIfChanged()}
                      >
                        <span className="text-white">Save</span>
                      </button>,
                    ];
                  } else {
                    return [
                      this.state.status == "deactivated" ? (
                        <span className="p-2 me-2 text-danger">
                          User deactivated
                        </span>
                      ) : null,
                      <button
                        className="btn btn-sm btn-white"
                        disabled={
                          this.state.status == "deactivated" ? true : false
                        }
                        onClick={() => {
                          this.setState({ edit: true });
                        }}
                      >
                        Edit
                      </button>,
                    ];
                  }
                }
              },
            }}
          ></Header>

          <div className="form-group mb-4 mt-3">
            <label className="label-top ">Job title</label>
            <div className="w-md-50">
              {this.state.edit ? (
                <>
                  <input
                    type="text"
                    value={this.state.jobTitle}
                    className="form-control form-sm-control"
                    onChange={(e) => {
                      this.onChange(e.target.value, "jobTitle");
                    }}
                    placeholder="Type job title"
                  ></input>
                </>
              ) : (
                <span>{this.state.jobTitle ? this.state.jobTitle : "-"}</span>
              )}
            </div>
          </div>

          <div className="form-group mb-4 ">
            <label className="label-top ">Bio</label>
            <div className="w-md-50">
              {this.state.edit ? (
                <>
                  <div className="form-control p-0 pb-5">
                    <ReactQuill
                      theme="snow"
                      value={this.state.biography}
                      onChange={(value) => {
                        this.onChange(
                          value == "<p><br></p>" ? "" : value,
                          "biography"
                        );
                      }}
                      style={{ height: "60px" }}
                      modules={{
                        toolbar: toolbarOptions,
                      }}
                      placeholder="Type biography"
                    ></ReactQuill>
                  </div>
                </>
              ) : (
                <span>
                  {this.state.biography ? parse(this.state.biography) : "-"}
                </span>
              )}
            </div>
          </div>
          {Object.keys(this.state.extraFieldsValue)?.map((el) => {
            return (
              <div className="form-group mb-4 ">
                <label className="label-top ">{el}</label>
                <div className="w-md-50">
                  {this.state.edit ? (
                    <>
                      <input
                        type="text"
                        value={this.state.extraFieldsValue[el]}
                        className="form-control form-sm-control"
                        onChange={(e) => {
                          this.onChangeExtraFields(e.target.value, el);
                        }}
                        placeholder={"Type ".concat(el)}
                      ></input>
                    </>
                  ) : (
                    <span>
                      {this.state.extraFieldsValue[el]
                        ? this.state.extraFieldsValue[el]
                        : "-"}
                    </span>
                  )}
                </div>
              </div>
            );
          })}
        </>
      );
    }

    return "";
  }
}

export default withRouter(JobDetails);
