import axios from "axios";
import parse from "html-react-parser";
import React from "react";
import { Component } from "react";
import { FileDrop } from "react-file-drop";
import $c from "../../modules/functions";
import $f from "../../src";

class Upload extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);

    this.onFrameDragEnter = function () {};
    this.onFrameDragLeave = function () {};
    this.onFrameDrop = function () {};
    this.onDragOver = function () {};
    this.onDragLeave = function () {};
    this.onDrop = this.onDrop.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.onFileInputChange = this.onFileInputChange.bind(this);
    this.onTargetClick = this.onTargetClick.bind(this);
    this.statusIcon = this.statusIcon.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
    this.parentChange = function () {};

    this.fileInputRef = React.createRef();

    this.state = {
      loaded: false,
    };
  }

  uploadFiles() {}

  onFileInputChange = (event) => {
    const { files } = event.target;

    this.onDrop(files);
  };

  onTargetClick() {
    this.fileInputRef.current.click();
  }

  onChange(e) {
    this.setState({
      value: e.target.value,
    });

    setTimeout(this["customOnChange"], 0);
  }

  onDrop(files) {
    let filesArray = Array.from(files);

    if (this.state.configuration.allowedExtensions) {
      filesArray = filesArray.filter(
        (file) =>
          this.state.configuration.allowedExtensions.indexOf(
            file.name.split(".").pop().toLowerCase()
          ) >= 0
      );

      let showWarningNotification = false;

      Array.from(files).forEach((file) => {
        if (
          this.state.configuration.allowedExtensions.indexOf(
            file.name.split(".").pop().toLowerCase()
          ) < 0
        ) {
          showWarningNotification = true;
        }
      });

      if (showWarningNotification) {
        $f.createNotification({
          type: "danger",
          message: "Allowed file extensions are ".concat(
            this.state.configuration.allowedExtensions.join(", ")
          ),
        });
      }
    }

    filesArray.map((file) => {
      const postData = new FormData();
      if ($f.getValue(this.state.configuration.type)) {
        postData.set("type", $f.getValue(this.state.configuration.type));
      }

      postData.set(
        this.state.configuration.fileDataProperty
          ? $f.getValue(this.state.configuration.fileDataProperty, this)
          : "file",
        file
      );

      const fileId = $f.key();

      let uploadFiles = this.state.uploadFiles;

      const cancelToken = axios.CancelToken.source();

      const _this = this;

      uploadFiles[fileId] = {
        title: file.name,
        progress: 0,
        fileSize: file.size,
        mimetype: file.type,
        status: "init",
        cancelToken: cancelToken,
        fileId: fileId,
      };

      this.setState({ uploadFiles: uploadFiles });

      $f.fetch(
        $f.getValue(this.state.configuration.url, this),
        "POST",
        postData,
        {},
        {},
        (response) => {
          const data = response.data;

          let uploadFiles = this.state.uploadFiles;

          delete uploadFiles[fileId];

          if (_this.state.configuration?.multipleFiles.toString() == "false") {
            _this.setState({
              value: [data.data[0] ? data.data[0] : data.data],
              uploadFiles: uploadFiles,
            });
          } else {
            _this.setState({
              value: [
                ..._this.state.value,
                data.data[0] ? data.data[0] : data.data,
              ],
              uploadFiles: uploadFiles,
            });
          }

          setTimeout(function () {
            _this.parentChange(_this.state.value, data.data[0]);
          }, 0);
        },
        null,
        null,
        (resp) => {
          let uploadFiles = _this.state.uploadFiles;

          let progress = Math.floor((resp.loaded / file.size) * 100);
          progress = progress > 100 ? 100 : progress;

          uploadFiles[fileId].uploaded = resp.loaded;
          uploadFiles[fileId].progress = progress;
          uploadFiles[fileId].status =
            progress == 100 ? "completed" : "pending";

          _this.setState({ uploadFiles: uploadFiles });
        },
        cancelToken.token
      );
    });
  }

  componentDidMount() {
    let events = [];

    Object.keys(this.props.property.events)?.forEach((element, idx) => {
      if (!element.toLowerCase().startsWith("onchange")) {
        this[element] = this.props.property.events[element];
        this[element] = this[element].bind(this);
        events[element] = this[element];
      } else if (element == "overrideOnChange") {
        this.onChange = this.props.property.event[element];
        this.onChange = this[element].bind(this);
        events["onChange"] = this[element];
      } else {
        this["customOnChange"] = this.props.property.events[element];
        this["customOnChange"] = this["customOnChange"].bind(this);
      }
    });

    if (this.props.property.parentChange) {
      this.parentChange = this.props.property.parentChange.bind(this);
    }

    this.setState({
      loaded: true,
      events: events,
      attributes: { ...this.props.property.attributes },
      value: this.props.value ? this.props.value : [],
      configuration: { ...this.props.property.configuration },
      editable: this.props.property.editable === false ? false : true,
      useCustomUI: this.props.property.useCustomUI,
      uploadFiles: {},
    });
  }

  deleteFile(idx) {
    let _this = this;
    let value = [...this.state.value];
    value.splice(idx, 1);
    this.setState({ value: value });

    setTimeout(function () {
      _this.parentChange(value);
    }, 0);
  }

  statusIcon(fileObject, idx) {
    const status = fileObject ? fileObject.status : "done";

    switch (status) {
      case "pending":
        return (
          <i
            className="fas fa-trash-alt"
            role="button"
            onClick={() => {
              fileObject.cancelToken.cancel();

              let uploadFiles = { ...this.state.uploadFiles };

              delete uploadFiles[fileObject.fileId];

              this.setState({ uploadFiles });
            }}
          ></i>
        );
      default:
        if (this.state.configuration.editFunction) {
          return (
            <>
              <i
                className="fas fa-pen me-2"
                role="button"
                onClick={() => {
                  this.state.configuration.editFunction(fileObject);
                }}
              ></i>
              <i
                className="fas fa-trash-alt"
                role="button"
                onClick={() => {
                  if (this.state.configuration.deleteFunction) {
                    this.state.configuration.deleteFunction(fileObject);
                  } else {
                    this.deleteFile(idx);
                  }
                }}
              ></i>
            </>
          );
        } else {
          return (
            <>
              <i
                className="fas fa-trash-alt"
                role="button"
                onClick={() => {
                  this.deleteFile(idx);
                }}
              ></i>
            </>
          );
        }

        break;
    }
  }

  editable() {
    return (
      <div className={this.state.configuration.wrapperClassName}>
        <FileDrop
          onFrameDragEnter={(event) => this.onFrameDragEnter(event)}
          onFrameDragLeave={(event) => this.onFrameDragLeave(event)}
          onFrameDrop={(event) => this.onFrameDrop(event)}
          onDragOver={(event) => this.onDragOver(event)}
          onDragLeave={(event) => this.onDragLeave(event)}
          onDrop={(files, event) => this.onDrop(files, event)}
          className={
            this.state.attributes ? this.state.attributes["className"] : ""
          }
        >
          {$f.getValue(this.props.property.caption, this)}
        </FileDrop>
        <input
          onChange={this.onFileInputChange}
          ref={this.fileInputRef}
          onClick={(event) => {
            event.target.value = null;
          }}
          type="file"
          style={{ display: "none" }}
          multiple
        />
        {Object.keys(this.state.uploadFiles)?.map((el, idx) => {
          return (
            <div className="card mt-2 p-3 ">
              <div className="d-flex">
                {$c.getFileIcon(
                  this.state.uploadFiles[el].mimetype,
                  this.state.uploadFiles[el].title
                )}
                <div className="w-100 me-2 mb-2">
                  <div className="w-100 d-flex">
                    <div className="w-100">
                      <div className="mb-2">
                        <strong>{this.state.uploadFiles[el].title}</strong>
                      </div>
                      <div className="display-5 color-grey mb-1">
                        {$c.formatBytes(this.state.uploadFiles[el].uploaded)}{" "}
                        from{" "}
                        {$c.formatBytes(this.state.uploadFiles[el].fileSize)}{" "}
                        uploaded
                      </div>
                    </div>
                    <div>
                      {this.statusIcon(this.state.uploadFiles[el], idx)}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="progress w-100  " style={{ height: "8px" }}>
                      <div
                        className="progress-bar"
                        role="progressbar"
                        aria-valuenow={this.state.uploadFiles[el].progress}
                        aria-valuemin="0"
                        aria-valuemax={this.state.uploadFiles[el].fileSize}
                        style={{
                          width: this.state.uploadFiles[el].progress + "%",
                          backgroundColor: "#4E2C95",
                        }}
                      ></div>
                    </div>
                    <div style={{ marginTop: "-8px" }} className="ms-3">
                      {this.state.uploadFiles[el].progress + "%"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        {this.state.value?.map((el, idx) => {
          return (
            <div className="card mt-2 p-3">
              <div className="d-flex">
                {$c.getFileIcon(el.mimetype, el.title, el)}
                <div className="w-100 me-2 mb-2">
                  <div className="w-100 d-flex">
                    <div className="w-100">
                      <div className="mb-2">
                        <strong>{el.title}</strong>
                      </div>
                      <div className="display-5 color-grey mb-1">
                        {el.subTitle}
                      </div>
                      {/* <div className="display-5 color-grey mb-1">
                        {$c.formatBytes(this.state.uploadFiles[el].uploaded)}{" "}
                        from{" "}
                        {$c.formatBytes(this.state.uploadFiles[el].fileSize)}{" "}
                        uploaded
                      </div> */}
                    </div>
                    <div className="d-flex">{this.statusIcon(el, idx)}</div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  nonEditable() {
    return typeof this.state.value === "string"
      ? parse(this.state.value)
      : this.state.value;
  }

  customUI() {}

  render() {
    if (this.state.loaded) {
      if (this.state.useCustomUI) {
        return this.customUI();
      } else if (this.state.editable) {
        return this.editable();
      } else {
        return this.nonEditable();
      }
    }

    return "";
  }
}

export default Upload;
