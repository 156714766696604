import React, { Component } from "react";
import parse from "html-react-parser";
import $c from "../../../../../modules/functions";
import $f from "../../../../../src";
import { Link } from "react-router-dom";

import PerfectScrollbar from "react-perfect-scrollbar";
import Modals from "../../../../../modules/modals";

class ConferencePartners extends Component {
  constructor(props) {
    super(props);

    this.setActiveTab = this.setActiveTab.bind(this);

    this.state = {
      data: [],
      loaded: false,
      training: {},
    };
  }

  componentDidMount() {
    const { data, module, view, specialProperties, response } = this.props;

    let templateConfiguration = view.template.configuration;

    const innerTemplates = ["header"];

    innerTemplates.map((itm, idx) => {
      let tmp = templateConfiguration[itm + "Template"]
        ? templateConfiguration[itm + "Template"]
        : () => {};
      this[itm + "Template"] = tmp.bind(this);
    });

    this.setState({
      activeTab: 0,
      specialProperties,
      data,
      loaded: true,
      view,
      module,
      response,
    });

    // document.body.style.paddingTop = "3.7rem";
  }

  setActiveTab(index) {
    this.setState({ activeTab: index });
  }

  render() {
    if (this.state.loaded) {
      const data = this.state.response.data[0];

      const sponsors = $c.conferencePartners(data.sponsors);

      return (
        <React.Fragment>
          {this.headerTemplate()}
          <div className="pb-5 me-0 ms-0">
            <div className="display-3-no-bold border-bottom-1">Partners</div>
            {Object.keys(sponsors).map((el) => {
              return (
                <div className="mt-3">
                  <span className="display-4">{el}</span>
                  <div className="row mt-3">
                    {sponsors[el].map((el) => {
                      return (
                        <div
                          className="col-md-4 p-3"
                          role="button"
                          onClick={() => {
                            Modals.events.openPartner(el);
                          }}
                        >
                          <img
                            src={$c.agendaFileImageURL(el.logo._id)}
                            style={{ maxHeight: "15rem", maxWidth: "15rem" }}
                            title={el.title}
                          ></img>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default ConferencePartners;
