import { Link } from "react-router-dom";
import $f from "../../src";
import JoinEventButton from "../../types/joinEventButton/joinEventButton";
import $c from "../functions";
import Modals from "../modals";

const UserTest = {
  properties: (args) => {
    const { editable } = args;

    return {
      _id: {
        label: "",
        path: "_id",
        labelPosition: "none",
        type: "hidden",
        events: {},
        attributes: {},
      },
      title: {
        label: "Title",
        type: "text",
        editable: editable,
        events: {},
        attributes: {
          className: "form-control",
        },
      },

      passingGrade: {
        label: "Category",
        type: "text",
        events: {},
        editable: editable,
        attributes: {
          className: "form-control",
        },
        formatValue: (value, row) => {
          if (value) {
            return (
              <span
                className="badge bg-light-blue p-2 color-black rounded"
                style={{ width: "5rem" }}
              >
                Graded
              </span>
            );
          }
          return (
            <span
              className="badge bg-light-purple p-2 color-black rounded"
              style={{ width: "5rem" }}
            >
              Practice
            </span>
          );
        },
      },

      fromtoDatetime: {
        label: "Availability",
        type: "fromtodatetime",
        events: {},
        formatValue: (value, row) => {
          return { from: row.from, to: row.to };
        },
        attributes: {
          className: "form-control",
        },
      },
      examDuration: {
        label: "Duration",
        type: "duration",
        events: {},
        editable: editable,
        formatValue: (value, row) => {
          return { hours: row.hours, minutes: row.minutes };
        },
        attributes: {
          className: "form-control",
        },
      },

      instructions: {
        label: "Instructions",
        type: "editor",
        editable: editable,
        events: {},
        attributes: {
          className: "form-control",
        },
      },

      questions: {
        label: "Questions",
        type: "examquestions",
        editable: editable,
        events: {},
        attributes: {},
      },
    };
  },
  specialProperties: () => {
    return {
      header: {
        type: "template",
        configuration: {
          title: "Tests",
          subTitle: "View all available tests",
          template: "Header",
          buttons: (args) => {
            return [
              <button
                type="button"
                className="btn btn-purple  btn-sm  "
                onClick={() => {
                  $f.getValue(Modals.events.editTest, {
                    title: "Schedule test",
                    api: "new",
                    action: "create",
                  });
                }}
              >
                Schedule
              </button>,
            ];
          },
        },
        events: {},
      },
    };
  },
  views: {
    test: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/exam/event/user-exam/{id}";
          },
          dataPath: () => {},
          replace: {
            id: () => {
              return $f.getQueryPath(2);
            },
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "UserTest";
        },
        configuration: {
          includeProperties: () => {
            return [];
          },
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: true };
        },
      },
    },
  },
};

export default UserTest;
