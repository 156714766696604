import {
  useRosterState,
  useMeetingManager,
} from "amazon-chime-sdk-component-library-react";
import $f from "../../../../src";
import MeetingFunctions from "./functions";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useState } from "react";
import ab2str from "arraybuffer-to-string";
import $c from "../../../../modules/functions";
import moment from "moment";
import parse from "html-react-parser";
import { useEffect } from "react";

const Chat = (props) => {
  const { roster } = useRosterState();

  const [chatMessages, setChatMessages] = useState([]);
  const meetingManager = useMeetingManager();
  const [theMessage, setTheMessage] = useState(null);
  const [chatVisible, setChatVisible] = useState(false);

  const replaceURLWithHTMLLinks = (text) => {
    var exp =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/i;

    return parse(
      text.replace(exp, "<a href='$1' target='_blank'><u>$1</u></a>")
    );
  };

  useEffect(() => {
    if (props.show) {
      document
        .getElementById("meeting-chat-menu-item")
        .classList.remove("bg-lila");
    }
    setChatVisible(props.show);
  }, [props.show]);

  const receiveChatData = (mess) => {
    document
      .getElementById("meeting-chat-menu-item")
      .classList.remove("bg-lila");
    if (!chatVisible) {
      document
        .getElementById("meeting-chat-menu-item")
        .classList.add("bg-lila");
    }
    setChatMessages([...chatMessages, mess]);
    scrollChat();
  };

  const scrollChat = () => {
    let chat = document.querySelector(".chat-wrapper");

    if (chat) {
      setTimeout(function () {
        chat?.scrollTo(0, chat.scrollHeight);
      }, 150);
    }
  };

  meetingManager?.audioVideo?.realtimeSubscribeToReceiveDataMessage(
    "CHAT",
    receiveChatData
  );

  const sendMessage = () => {
    if (theMessage) {
      const mess = {
        uuid: $f.key(),
        action: "sendmessage",
        cmd: "TEXT",
        data: theMessage,
        createdDate: new Date().getTime(),
        senderName:
          MeetingFunctions.getCurrentAttendee(meetingManager)?.[
            "externalUserId"
          ].split("|")[0],
      };

      setChatMessages([...chatMessages, mess]);

      MeetingFunctions.sendChatMessage(meetingManager, mess);

      setTheMessage("");
      scrollChat();
    }
  };

  const onChangeMessage = (e) => {
    setTheMessage(e.target.value);
  };

  return (
    <div
      className={"h-100 position-relative ".concat(props.show ? "" : "d-none")}
    >
      <span className="d-block display-4-no-margin mb-1">In-call messages</span>
      <span className="color-grey small">
        Messages are viewed by all participants
      </span>
      <PerfectScrollbar className="mb-3 chat-wrapper">
        <div style={{ height: "calc(100vh - 20rem)" }}>
          {chatMessages.map((el) => {
            let msg = "";
            if (typeof el.data == "string") {
              msg = (
                <div className="ms-5 chat-my-message rounded mt-3 p-2 me-3">
                  {replaceURLWithHTMLLinks(el.data)}
                  <div className="text-end color-grey">
                    <small>{moment(el.createdDate).format("HH:mm")}</small>
                  </div>
                </div>
              );
            } else {
              const fullName = el.senderExternalUserId
                .split("|")[0]
                .replace("  ", " ")
                .split(" ");

              const firstName = fullName[0];

              const lastName = fullName[2]
                ? fullName[1] + " " + fullName[2]
                : fullName[1];

              const rmsg = JSON.parse(ab2str(el.data));

              msg = (
                <>
                  <div className="mt-3 color-purple">
                    {/* {$c.createAvatar(null, firstName, lastName)} */}
                    {MeetingFunctions.participantAvatar(
                      el.senderExternalUserId.split("|")[1],
                      meetingManager
                    )}
                  </div>
                  <div className="me-5 ms-4 chat-remote-message rounded mt-2 p-2">
                    {replaceURLWithHTMLLinks(rmsg.data)}
                    <div className="text-end color-grey">
                      <small>{moment(rmsg.createdDate).format("HH:mm")}</small>
                    </div>
                  </div>
                </>
              );
            }

            return msg;
          })}
        </div>
      </PerfectScrollbar>
      <div className="position-absolute me-4 w-100  pt-2">
        <div className="d-flex me-3 border-top pt-2">
          <textarea
            placeholder="Type your message"
            onChange={onChangeMessage}
            className="border-0 w-100 chat-area"
            value={theMessage}
            style={{ resize: "none" }}
            onKeyPress={(e) => {
              if (e.which === 13 && !e.shiftKey) {
                e.preventDefault();
                sendMessage();
              }
            }}
          ></textarea>
          <button
            className="btn btn-purple"
            type="button"
            onClick={sendMessage}
            style={{ borderRadius: "100%" }}
          >
            <i className="fas fa-paper-plane"></i>
          </button>
        </div>
        {/* <div className="input-group mb-3 ">
          <input
            type="text"
            className="form-control"
            placeholder="Type your message"
            onChange={onChangeMessage}
            value={theMessage}
            onKeyPress={(e) => {
              if (e.which === 13 && !e.shiftKey) {
                e.preventDefault();
                sendMessage();
              }
            }}
          />
          <button
            className="btn btn-primary"
            type="button"
            onClick={sendMessage}
          >
            <i className="fas fa-paper-plane"></i>
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default Chat;
