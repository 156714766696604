import { Component } from "react";
import ReactQuill from "react-quill";
import "quill/dist/quill.snow.css";
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";
import "./style.css";
import parse from "html-react-parser";
import SimpleReactValidator from "simple-react-validator";
import Upload from "../upload/upload";
import { triggerCollapse } from "./script";
import Switch from "../switch/switch";
import {
  arrayMove,
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import $f from "../../src";

const SortableItem = sortableElement(({ value }) => (
  <li style={{ listStyle: "none" }}>{value}</li>
));

const DragHandle = sortableHandle(() => (
  <span className="sortableHandler me-2">
    <i className="fas fa-ellipsis-v"></i>
    <i className="fas fa-ellipsis-v"></i>
  </span>
));

const SortableContainer = sortableContainer(({ children }) => {
  return (
    <ul className="ps-0" style={{ listStyle: "none" }}>
      {children}
    </ul>
  );
});

class FormQuestions extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({
      validators: {
        questionsExist: {
          message: "Form must at least have one question",
          rule: (val, params, validator) => {
            return val > 0;
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)),
        },
        hasCorrectAnswer: {
          message: "At least one answer should be marked as correct",
          rule: (val, params, validator) => {
            let hasCorrect = false;

            val.answers.forEach((el) => {
              if (el.isCorrect) {
                hasCorrect = true;
              }
            });

            return hasCorrect;
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)),
        },
      },
    });
    this.updateMultipleData = this.updateMultipleData.bind(this);
    this.updateAnswerData = this.updateAnswerData.bind(this);
    this.deleteRow = this.deleteRow.bind(this);
    this.addRow = this.addRow.bind(this);
    this.deleteAnswer = this.deleteAnswer.bind(this);
    this.addAnswer = this.addAnswer.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.editRender = this.editRender.bind(this);
    this.onReorderAnswers = this.onReorderAnswers.bind(this);
    this.onReorderQuestions = this.onReorderQuestions.bind(this);

    this.state = {
      loaded: false,
    };
  }

  validate() {
    let isValid = this.validator.allValid();

    this.setState({ isValid });
    this.validator.showMessages();
    this.forceUpdate();

    return isValid;
  }

  componentDidMount() {
    let events = [];

    Object.keys(this.props.property.events)?.forEach((element, idx) => {
      if (!element.toLowerCase().startsWith("onchange")) {
        this[element] = this.props.property.events[element];
        this[element] = this[element].bind(this);
        events[element] = this[element];
      } else {
        this["customOnChange"] = { ...this.props.property.events[element] };
        this["customOnChange"] = this["customOnChange"].bind(this);
      }
    });

    this.setState({
      loaded: true,
      events: events,
      attributes: { ...this.props.property.attributes },
      value: this.props.value ? this.props.value : [],
      // value: [
      //   { text1: "text11", text2: "text21" },
      //   { text1: "text12", text2: "text22" },
      // ],
      editable: this.props.property.editable === false ? false : true,
      configuration: { ...this.props.property.configuration },
    });
  }

  onReorderAnswers(args, qidx) {
    let value = [...this.state.value];

    const answers = [...value[qidx].answers];

    let q = arrayMove(answers, args.oldIndex, args.newIndex);

    value[qidx].answers = q;

    this.setState({
      value: value,
    });
  }

  onReorderQuestions(args) {
    this.setState({
      value: arrayMove(this.state.value, args.oldIndex, args.newIndex),
    });
  }

  updateMultipleData(idx, property, data) {
    let value = [...this.state.value];

    value[idx][property] = data;

    this.setState({ value });
  }

  updateAnswerData(idx, ansidx, property, data) {
    let value = [...this.state.value];

    value[idx].answers[ansidx][property] = data;

    this.setState({ value });
  }

  deleteRow(index) {
    let _this = this;

    window["secondaryModal"].setState({
      show: true,
      size: "default",
      title: (
        <h2 className="display-3-no-line-height header-ellipsis">
          {"Delete question?"}
        </h2>
      ),
      footer: [
        <button
          type="button"
          onClick={() => {
            window["secondaryModal"].close();
          }}
          className="btn btn-sm btn-white"
        >
          Cancel
        </button>,
        <button
          type="button"
          onClick={() => {
            let value = [..._this.state.value];
            value.splice(index, 1);
            _this.setState({ value: value });
            _this.validator.purgeFields();
            window["secondaryModal"].close();
          }}
          className="btn btn-sm btn-danger"
        >
          Delete
        </button>,
      ],
      body: (
        <p>
          Are you sure you want to delete Question {index + 1}
          <br></br>
        </p>
      ),
    });
  }

  addRow(type) {
    this.setState({
      value: [
        ...this.state.value,
        { type: type, answers: type == "essayType" ? [] : [{}, {}] },
      ],
    });
    setTimeout(() => {
      triggerCollapse();
    }, 0);
  }

  deleteAnswer(qidx, ansidx) {
    let _this = this;

    window["secondaryModal"].setState({
      show: true,
      size: "default",
      title: (
        <h2 className="display-3-no-line-height header-ellipsis">
          {"Delete answer?"}
        </h2>
      ),
      footer: [
        <button
          type="button"
          onClick={() => {
            window["secondaryModal"].close();
          }}
          className="btn btn-sm btn-white"
        >
          Cancel
        </button>,
        <button
          type="button"
          onClick={() => {
            let value = [..._this.state.value];
            value[qidx].answers.splice(ansidx, 1);
            _this.setState({ value: value });
            _this.validator.purgeFields();
            window["secondaryModal"].close();
          }}
          className="btn btn-sm btn-danger"
        >
          Delete
        </button>,
      ],
      body: (
        <p>
          Are you sure you want to delete Answer {ansidx + 1} of Question{" "}
          {qidx + 1}
          <br></br>
        </p>
      ),
    });
  }

  addAnswer(qidx) {
    const value = [...this.state.value];

    value[qidx].answers.push({});

    this.setState({ value: value });
  }

  handleEditorChange = (content, editor) => {
    this.setState({ value: content });

    setTimeout(this["customOnChange"], 0);
  };

  editRender() {
    const output = [];
    const toolbarOptions = [
      ["bold", "italic", "underline", "strike", "link"],
      [{ list: "ordered" }, { list: "bullet" }],
    ];

    let _this = this;

    this.state.value?.forEach((row, idx) => {
      let template = (
        <div className="bg-light-grey rounded pt-3 pe-3 ps-3 mb-3 border">
          <div className="form-group mb-3 d-flex">
            <div className="w-100">
              <DragHandle />
              Question {idx + 1}
            </div>
            <div className="d-flex">
              <span
                className="collapse-question me-3 collapsed"
                data-bs-toggle="collapse"
                data-bs-target={"#collapseQuestion"
                  .concat(idx)
                  .concat(", #collapsedQuestionContent".concat(idx))}
                role="button"
              ></span>
              <i
                className="fas fa-trash-alt color-black"
                onClick={() => {
                  this.deleteRow(idx);
                }}
                role="button"
              ></i>
            </div>
          </div>
          <div
            className="collapsed-question-content collapsed"
            id={"collapsedQuestionContent".concat(idx)}
          >
            {row.question ? parse(row.question) : ""}
          </div>
          <div
            className="question-body collapse"
            id={"collapseQuestion".concat(idx)}
          >
            <div className="form-group mb-3  mt-3 d-flex">
              <label className="label-top w-100">Option required</label>
              <Switch
                value={
                  _this.state.value[idx]?.isRequired?.toString() === "true"
                    ? true
                    : false
                }
                property={{
                  events: {},
                  attributes: {
                    className: "form-control",
                  },
                  parentChange: function (e) {
                    _this.updateMultipleData(idx, "isRequired", e);
                  },
                }}
              ></Switch>
            </div>
            <div className="form-group mb-3">
              <label className="label-top">Question</label>
              <div className="form-control p-0 pb-5">
                <ReactQuill
                  theme="snow"
                  value={row.question}
                  placeholder="Type question"
                  onChange={(value) => {
                    this.updateMultipleData(
                      idx,
                      "question",
                      value == "<p><br></p>" ? "" : value
                    );
                  }}
                  style={{ height: "60px" }}
                  modules={{
                    toolbar: toolbarOptions,
                  }}
                ></ReactQuill>
              </div>
              <span className="text-danger mt-2 d-block">
                {this.validator.message(
                  "Question ".concat(idx + 1),
                  this.state.value[idx].question,
                  "required"
                )}
              </span>
            </div>
            <div className="form-group mb-3">
              {row.type == "multipleChoice" ? (
                <>
                  <label className="label-top">Options</label>

                  <SortableContainer
                    onSortEnd={(args) => {
                      this.onReorderAnswers(args, idx);
                    }}
                    pressDelay={50}
                    helperClass="sortableHelper"
                    useDragHandle
                  >
                    {row.answers?.map((ans, ansIdx) => {
                      const value = (
                        <div className="d-flex mb-2">
                          <div className=" mt-1">
                            <DragHandle />
                          </div>
                          <div className="me-2 mt-1">{ansIdx + 1}</div>
                          <div className="me-2 question-answer">
                            <input
                              type="text"
                              value={ans.answer}
                              className="form-control "
                              placeholder="Type option"
                              onChange={(e) => {
                                this.updateAnswerData(
                                  idx,
                                  ansIdx,
                                  "answer",
                                  e.target.value
                                );
                              }}
                            ></input>
                            <span className="text-danger mt-2 d-block">
                              {this.validator.message(
                                "option "
                                  .concat(ansIdx + 1)
                                  .concat(" of question ")
                                  .concat(idx + 1),
                                this.state.value[idx].answers[ansIdx].answer,
                                "required"
                              )}
                            </span>
                          </div>
                          <div className="me-2"></div>
                          <div className="mt-1">
                            {row.answers.length > 2 ? (
                              <i
                                className="fas fa-trash-alt color-black"
                                onClick={() => {
                                  this.deleteAnswer(idx, ansIdx);
                                }}
                                role="button"
                              ></i>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      );

                      return (
                        <SortableItem
                          key={`item-${ansIdx}`}
                          index={ansIdx}
                          value={value}
                        />
                      );
                    })}
                  </SortableContainer>

                  <div
                    className="color-black"
                    onClick={() => {
                      this.addAnswer(idx);
                    }}
                    role="button"
                  >
                    + Add answer
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      );

      // output.push(template);
      output.push(
        <SortableItem key={`itemq-${idx}`} index={idx} value={template} />
      );
    });

    return (
      <div>
        <SortableContainer
          onSortEnd={this.onReorderQuestions}
          pressDelay={50}
          helperClass="sortableHelper"
          useDragHandle
        >
          {output}
        </SortableContainer>
        <span className="text-danger mb-2 d-block">
          {this.validator.message(
            "title",
            this.state.value.length,
            "questionsExist"
          )}
        </span>
        <div className="btn-group">
          <button
            type="button"
            className="btn btn-white dropdown-toggle form-control"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            + Add question
          </button>
          <ul className="dropdown-menu">
            <li>
              <span
                className="dropdown-item"
                role="button"
                onClick={() => {
                  this.addRow("multipleChoice");
                }}
              >
                Add multiple choice
              </span>
            </li>
            <li>
              <span
                className="dropdown-item"
                role="button"
                onClick={() => {
                  this.addRow("essayType");
                }}
              >
                Add text
              </span>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.loaded) {
      return this.editRender();
    }

    return "";
  }
}

export default FormQuestions;
