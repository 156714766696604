import { Component } from "react";
import { withRouter } from "react-router";
import $c from "../../../../modules/functions";
import $f from "../../../../src";
import Upload from "../../../../types/upload/upload";
import Header from "../header";
import SimpleReactValidator from "simple-react-validator";
import Modals from "../../../../modules/modals";

class Profile extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();
    this.validate = this.validate.bind(this);
    this.onChange = this.onChange.bind(this);

    this.deleteAccountModal = this.deleteAccountModal.bind(this);
    this.checkIfChanged = this.checkIfChanged.bind(this);
    this.update = this.update.bind(this);

    this.state = {
      loaded: false,
      edit: false,
      hasChanges: false,
    };
  }

  validate() {
    const isValid = this.validator.allValid();

    this.setState({ isValid });
    this.validator.showMessages();
    this.forceUpdate();

    return isValid;
  }

  componentDidMount() {
    const { data, module, view, specialProperties, response } = this.props;

    let templateConfiguration = view.template.configuration;

    const innerTemplates = ["header", "top", "bottom"];

    innerTemplates.map((itm, idx) => {
      let tmp = templateConfiguration[itm + "Template"]
        ? templateConfiguration[itm + "Template"]
        : () => {};
      this[itm + "Template"] = tmp.bind(this);
    });

    let { firstName, lastName, profileImage, email, _id, status } = response
      ? response.data[0]
      : {
          firstName: "",
          lastName: "",
          profileImage: "",
          email: "",
          _id: "",
          status: "",
        };

    let tag = "";

    if (response.host) {
      tag = "host";
    }

    if (response.instructor) {
      tag = "instructor";
    }

    this.setState({
      specialProperties,
      data,
      loaded: true,
      view,
      module,
      response,
      firstName,
      lastName,
      profileImage,
      email,
      _id,
      tag,
      status,
      initial: {
        firstName,
        lastName,
        profileImage,
        email,
        tag,
      },
    });
  }

  checkIfChanged() {
    let initialValues = this.state.initial;

    let hasChanges = false;
    if (
      initialValues.firstName !== this.state.firstName ||
      initialValues.lastName !== this.state.lastName ||
      initialValues.profileImage !== this.state.profileImage ||
      initialValues.tag !== this.state.tag
    ) {
      hasChanges = true;
    }

    return hasChanges;
  }

  onChange(value, property) {
    this.setState({ [property]: value });
  }

  deleteAccountModal() {
    let _this = this;
    window["primaryModal"].setState({
      show: true,
      size: "default",
      title: (
        <h2 className="display-3-no-line-height header-ellipsis">
          Delete account?
        </h2>
      ),
      footer: [
        <button
          type="button"
          onClick={() => {
            window["primaryModal"].close();
          }}
          className="btn btn-sm btn-white"
        >
          Cancel
        </button>,
        <button
          type="button"
          onClick={() => {
            $f.fetch(
              "/user/{id}",
              "DELETE",
              [],
              {},
              {
                id: () => {
                  return _this.state._id;
                },
              },
              (resp) => {
                $f.createNotificationFromResponse(
                  resp.data,
                  false,
                  true,
                  false
                );
                _this.props.history.push("/contacts");
              }
            );
          }}
          className="btn btn-sm btn-danger"
        >
          Delete
        </button>,
      ],
      body: (
        <p>
          <strong>This action:</strong> <br></br>
          <span>
            - will delete account of user{" "}
            <strong>
              "{this.state.firstName.concat(" ", this.state.lastName)}"
            </strong>{" "}
            and its data permanently
          </span>
        </p>
      ),
    });
  }

  update() {
    if (this.validate()) {
      let _this = this;

      let postData = {
        _id: this.state._id,
        profileImage: this.state.profileImage,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
      };

      if ($c.userIsAdmin()) {
        switch (this.state.tag) {
          case "host":
            postData["host"] = true;
            postData["instructor"] = false;
            break;

          case "instructor":
            postData["host"] = false;
            postData["instructor"] = true;
            break;
          default:
            postData["host"] = false;
            postData["instructor"] = false;
            break;
        }
      }

      $f.fetch("/user", "PATCH", postData, {}, {}, (resp) => {
        $f.createNotificationFromResponse(resp.data);
        _this.props.module.doWork();
      });
    }
  }

  render() {
    if (this.state.loaded) {
      let tagLabel = "Not specified";

      switch (this.state.tag) {
        case "host":
          tagLabel = "Conference host";
          break;

        case "instructor":
          tagLabel = "Instructor";
          break;
      }

      let _this = this;

      return (
        <>
          <Header
            data={{
              title: this.state.firstName.concat(" ", this.state.lastName),
              subTitle:
                $c.getUserId() !== this.state._id && !$c.userIsAdmin()
                  ? "Profile"
                  : "Manage profile",
              buttons: (args) => {
                if ($c.userIsAdmin() || $c.getUserId() === this.state._id) {
                  if (this.state.edit) {
                    return [
                      <button
                        className="btn btn-sm btn-white me-2"
                        onClick={() => {
                          this.props.module.doWork();
                        }}
                      >
                        Cancel
                      </button>,
                      <button
                        className="btn btn-sm btn-purple"
                        onClick={this.update}
                        disabled={!this.checkIfChanged()}
                      >
                        <span className="text-white">Save</span>
                      </button>,
                    ];
                  } else {
                    return [
                      this.state.status == "deactivated" ? (
                        <span className="p-2 me-2 text-danger">
                          User deactivated
                        </span>
                      ) : this.state.status == "registration" ? (
                        <span className="p-2 me-2 color-grey">
                          User invited
                        </span>
                      ) : null,
                      <button
                        className="btn btn-sm btn-white"
                        disabled={
                          this.state.status == "deactivated" ? true : false
                        }
                        onClick={() => {
                          this.setState({ edit: true });
                        }}
                      >
                        Edit
                      </button>,
                    ];
                  }
                }
              },
            }}
          ></Header>
          <div className="mt-3 mb-5">
            <div className="form-group mb-4">
              <label className="label-top">Photo</label>
              <div className="w-md-25">
                {this.state.edit ? (
                  <Upload
                    value={
                      this.state.profileImage
                        ? [
                            {
                              mimetype: "image/jpeg",
                              _id: this.state.profileImage,
                              title: "Profile image",
                            },
                          ]
                        : []
                    }
                    key={$f.key()}
                    property={{
                      parentChange: function (e, fileupload) {
                        let imageId = null;

                        try {
                          imageId = e[0]._id;
                        } catch (e) {}

                        _this.onChange(imageId, "profileImage");
                      },
                      label: "",
                      type: "upload",
                      editable: true,
                      configuration: {
                        url: "/agenda-file",
                        type: "profile",
                        fileDataProperty: "file",
                        allowedExtensions: ["jpg", "jpeg", "png"],
                        multipleFiles: false,
                        deleteFunction: (e) => {
                          _this.onChange(null, "profileImage");
                        },
                      },
                      caption: function (_this) {
                        return (
                          <div className="w-100 text-center p-4 ">
                            <img
                              src="/uni/svg/files/upload.svg"
                              className="svg-sm mb-2"
                            />
                            <span className="d-block mb-1">
                              <strong>
                                <span
                                  className="color-purple"
                                  role="button"
                                  onClick={_this.onTargetClick}
                                >
                                  Click to upload
                                </span>{" "}
                                or drag and drop
                              </strong>
                            </span>
                            <span className="color-grey d-block">
                              PNG, JPEG, JPG (max 1GB)
                            </span>
                          </div>
                        );
                      },
                      events: {},
                      attributes: {
                        className: "form-control bg-light-grey",
                      },
                    }}
                  />
                ) : (
                  $c.createAvatar(
                    this.state.profileImage,
                    this.state.firstName,
                    this.state.lastName,
                    "avatar-large",
                    "",
                    false
                  )
                )}
              </div>
            </div>
          </div>
          <div className="">
            <div className="form-group mb-4">
              <label className="label-top ">First name</label>
              <div className="w-md-25">
                {this.state.edit ? (
                  <>
                    <input
                      type="text"
                      value={this.state.firstName}
                      className="form-control form-sm-control"
                      onChange={(e) => {
                        this.onChange(e.target.value, "firstName");
                      }}
                      placeholder="Type first name"
                    ></input>
                    <span className="text-danger mt-2 d-block">
                      {this.validator.message(
                        "First name",
                        this.state.firstName,
                        "required"
                      )}
                    </span>
                  </>
                ) : (
                  <span>{this.state.firstName}</span>
                )}
              </div>
            </div>
          </div>
          <div>
            <div className="form-group mb-4 ">
              <label className="label-top ">Last name</label>
              <div className="w-md-25">
                {this.state.edit ? (
                  <>
                    <input
                      type="text"
                      value={this.state.lastName}
                      className="form-control form-sm-control"
                      onChange={(e) => {
                        this.onChange(e.target.value, "lastName");
                      }}
                      placeholder="Type last name"
                    ></input>
                    <span className="text-danger mt-2 d-block">
                      {this.validator.message(
                        "Last name",
                        this.state.lastName,
                        "required"
                      )}
                    </span>
                  </>
                ) : (
                  <span>{this.state.lastName}</span>
                )}
              </div>
            </div>
          </div>
          {$c.userIsAdmin() ? (
            this.state.edit ? (
              <div>
                <div className="form-group mb-4 ">
                  <label className="label-top ">Tag</label>
                  <div>
                    <span>{tagLabel}</span>
                  </div>
                </div>
              </div>
            ) : (
              // <div>
              //   <div className="form-group mb-4 ">
              //     <label className="label-top ">Tag</label>
              //     <div className="w-md-25">
              //       <select
              //         className=" form-select"
              //         value={this.state.tag}
              //         onChange={(e) => {
              //           this.onChange(e.target.value, "tag");
              //         }}
              //       >
              //         <option value="">Not specified</option>
              //         <option value="instructor">Instructor</option>
              //         <option value="host">Conference host</option>
              //       </select>
              //     </div>
              //   </div>
              // </div>
              <div>
                <div className="form-group mb-4 ">
                  <label className="label-top ">Tag</label>
                  <div>
                    <span>{tagLabel}</span>
                  </div>
                </div>
              </div>
            )
          ) : (
            ""
          )}
          <div>
            <div className="form-group mb-4 ">
              <label className="label-top ">
                Email{" "}
                {$c.getUserId() !== this.state._id && !$c.userIsAdmin()
                  ? ""
                  : "(cannot change)"}
              </label>
              <div>
                <span className="strong">{this.state.email}</span>
              </div>
            </div>
          </div>
          {$c.userIsAdmin() &&
          $c.getUserId() !== this.state._id &&
          this.state.status !== "registration" ? (
            <div className="pt-5 mt-5 border-top w-md-50 mb-5">
              <div className="color-grey row">
                <div className="col-9">
                  <span className="d-block">
                    <strong>Delete account</strong>
                  </span>
                  <span className="d-block">
                    Delete account and its data permanently
                  </span>
                </div>
                <div className="col-3">
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={this.deleteAccountModal}
                  >
                    Delete account
                  </button>
                </div>
              </div>
            </div>
          ) : this.state.status == "registration" && $c.userIsAdmin() ? (
            <div className="pt-5 mt-5 border-top w-md-50 mb-5">
              <div className="color-grey row">
                <div className="col-9">
                  <span className="d-block">
                    <strong> This user has been invited</strong>
                  </span>
                  <span className="d-block">Revoke invitation</span>
                </div>
                <div className="col-3">
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => {
                      Modals.contacts.revokeInvitation({
                        userName: this.state.firstName.concat(
                          " ",
                          this.state.lastName
                        ),
                        email: this.state.email,
                      });
                    }}
                  >
                    Revoke invitation
                  </button>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      );
    }

    return "";
  }
}

export default withRouter(Profile);
