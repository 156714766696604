import { Component } from "react";
import { withRouter } from "react-router";
import ProgressBar from "react-bootstrap/ProgressBar";
import $c from "../../../modules/functions";
import $f from "../../../src";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import moment from "moment";

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      userData: {},
    };
  }

  componentDidMount() {
    this.userData = this.userData.bind(this);

    const { data, module, view, specialProperties } = this.props;

    this.setState({
      specialProperties,
      data,
      view,
      module,
      userId: this.props.userId,
      bioOnly: this.props.bioOnly,
    });

    this.userData();
  }

  userData() {
    let _this = this;
    $f.fetch(
      "/user/{id}",
      "GET",
      null,
      {},
      {
        id: () => {
          return this.props.userId;
        },
      },
      (resp) => {
        try {
          _this.setState({ userData: resp.data.data[0], loaded: true });
        } catch (e) {}
      }
    );
  }

  render() {
    if (this.state.loaded) {
      let data = this.state.userData;

      let profileImage = "";

      if (data.profileImage && !data.hasPrivateInfo) {
        profileImage = data.profileImage
          ? $c.agendaFileImageURL(data.profileImage)
          : "";
      }

      let extraFields = [];

      if (!data.hasPrivateInfo) {
        data.extraFields.forEach((itm, idx) => {
          if (itm.label) {
            extraFields.push(
              <div className="mt-4 mb-4">
                <span className="text-dark d-block">{itm.label}</span>
                <span className="d-block text-black">
                  {data[itm.label] ? data[itm.label] : "-"}
                </span>
              </div>
            );
          }
        });
      }

      let spaces = [];

      if (!data.hasPrivateInfo) {
        if (data.groups) {
          data.groups.map((itm, idx) => {
            itm.groups.map((gitm, gidx) => {
              let to =
                "/spaces/" +
                itm.rootSpace._id +
                "/groups/" +
                gitm._id +
                "/participants";

              spaces.push(
                <Link
                  to={to}
                  className="btn-link ms-2"
                  onClick={() =>
                    window["primaryModal"].setState({ show: false })
                  }
                >
                  {gitm.name}
                </Link>
              );

              spaces.push(",");
            });
          });

          spaces.pop();
        }
      }

      let events = [];

      if (this.state.bioOnly) {
        return (
          <div className="container p-5">
            <h2 className=" text-black">
              {data.firstName.concat(" ", data.lastName)}
            </h2>
            <h4 className="d-block mb-4 text-black">{data.jobTitle}</h4>
            <div className="row">
              <div className="col-md-3">
                {profileImage ? (
                  <div className="">
                    <img
                      src={profileImage}
                      className="me-3 rounded img-fluid"
                    ></img>
                    <div className="mt-4 mb-4">
                      <span className="text-dark d-block">Email address:</span>
                      <a href={"mailto:" + data.email} className="btn-link">
                        {data.email}
                      </a>
                    </div>
                  </div>
                ) : (
                  <div className="mb-4">
                    <span className="text-dark d-block">Email address:</span>
                    <a href={"mailto:" + data.email} className="btn-link">
                      {data.email}
                    </a>
                  </div>
                )}
              </div>
              <div className="col-md-9">
                {data.biography ? parse(data.biography) : ""}
              </div>
            </div>
          </div>
        );
      }

      if (!data.hasPrivateInfo) {
        if (data.events) {
          Object.keys(data.events).map((itm, idx) => {
            let title = "";
            let url = "";

            switch (itm) {
              case "training":
                title = "Trainings";
                url = "/event/training/";
                break;
              case "exam":
                title = "Tests";
                url = "/event/test/";
                break;

              case "stream":
                title = "Conferences";
                url = "/event/conference/";
                break;

              case "meeting":
                title = "Meetings";
                url = "/event/meeting/";
                break;
            }
            if (data.events[itm].length > 0) {
              events.push(
                <div className="mt-2">
                  <span className="text-dark">{title}:</span>
                  {data.events[itm].map((gitm, gidx) => {
                    let to = url + gitm._id;
                    return (
                      <>
                        <Link
                          to={to}
                          className="btn-link ms-2"
                          onClick={() =>
                            window["primaryModal"].setState({ show: false })
                          }
                        >
                          {gitm.title}
                        </Link>
                        {gidx < data.events[itm].length - 1 ? "," : ""}
                      </>
                    );
                  })}
                </div>
              );
            }
          });
        }
      }

      if (!data.hasPrivateInfo) {
        if (!profileImage && data.biography && extraFields.length > 0) {
          return (
            <div className="container p-5">
              <h2 className=" text-black">
                {data.firstName.concat(" ", data.lastName)}
              </h2>
              <h4 className="d-block mb-4 text-black">{data.jobTitle}</h4>
              <div className="row">
                <div className="col-md-3">
                  <div className="mb-4">
                    <span className="text-dark d-block">Email address:</span>
                    <a href={"mailto:" + data.email} className="btn-link">
                      {data.email}
                    </a>
                  </div>
                  {extraFields}
                </div>
                <div className="col-md-9">
                  {data.biography ? parse(data.biography) : ""}
                </div>
              </div>
            </div>
          );
        }
      }

      return (
        <div className="container p-5 text-break">
          <div className="row">
            {profileImage ? (
              <div className="col-md-4">
                <img
                  src={profileImage}
                  className="me-3 rounded img-fluid"
                ></img>
                <div className="mt-4 mb-4">
                  <span className="text-dark d-block">Email address:</span>
                  <a href={"mailto:" + data.email} className="btn-link">
                    {data.email}
                  </a>
                </div>
                {extraFields}
              </div>
            ) : (
              ""
            )}
            <div className={profileImage ? "col-md-8" : "col-md-12"}>
              <h2 className=" text-black">
                {data.firstName.concat(" ", data.lastName)}
              </h2>
              <h4 className="d-block mb-4 text-black">{data.jobTitle}</h4>
              {profileImage ? (
                ""
              ) : (
                <>
                  <div className="mt-4 mb-4">
                    <span className="text-dark d-block">Email address:</span>
                    <a href={"mailto:" + data.email} className="btn-link">
                      {data.email}
                    </a>
                  </div>
                  {$c.userIsAdmin() ||
                  $c.getUserId() === data._id ||
                  $c.userIsViewer()
                    ? extraFields
                    : ""}
                </>
              )}
              {data.biography ? parse(data.biography) : ""}
              {$c.userIsAdmin() ||
              $c.getUserId() === data._id ||
              $c.userIsViewer() ? (
                <>
                  {spaces.length > 0 ? (
                    <div className="mt-4">
                      <strong>Spaces:</strong>
                      {spaces}
                    </div>
                  ) : (
                    ""
                  )}
                  {events.length > 0 ? (
                    <div className="mt-4">
                      <strong>Events</strong>
                      {events}
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return "loading...";
    }
  }
}

export default withRouter(UserProfile);
