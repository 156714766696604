import React, { Component } from "react";
import parse from "html-react-parser";
import $c from "../../../../../modules/functions";
import $f from "../../../../../src";
import { Link } from "react-router-dom";

import PerfectScrollbar from "react-perfect-scrollbar";

class ConferenceSettings extends Component {
  constructor(props) {
    super(props);

    this.setActiveTab = this.setActiveTab.bind(this);

    this.state = {
      data: [],
      loaded: false,
      training: {},
    };
  }

  componentDidMount() {
    const { data, module, view, specialProperties, response } = this.props;

    let templateConfiguration = view.template.configuration;

    const innerTemplates = ["header"];

    innerTemplates.map((itm, idx) => {
      let tmp = templateConfiguration[itm + "Template"]
        ? templateConfiguration[itm + "Template"]
        : () => {};
      this[itm + "Template"] = tmp.bind(this);
    });

    this.setState({
      activeTab: 0,
      specialProperties,
      data,
      loaded: true,
      view,
      module,
      response,
    });

    // document.body.style.paddingTop = "3.7rem";
  }

  setActiveTab(index) {
    this.setState({ activeTab: index });
  }

  render() {
    if (this.state.loaded) {
      const data = this.state.response.data[0];

      return (
        <React.Fragment>
          {this.headerTemplate()}
          <div className="pb-5 container-fluid me-0 ms-0 ps-0">
            <div className="display-3-no-bold border-bottom-1">Stream keys</div>
            <PerfectScrollbar>
              <ul className="nav nav-tabs navbar-expand">
                {data.days.map((el, idx) => {
                  const tabId = "a-".concat(idx);

                  let active = "";

                  if (idx == this.state.activeTab) {
                    active = "active show";
                  }

                  return (
                    <li
                      className={"nav-item "}
                      key={$f.key()}
                      onClick={() => {
                        this.setActiveTab(idx);
                      }}
                    >
                      <span
                        className={"nav-link color-strong-grey nav-ellipsis ".concat(
                          active
                        )}
                        data-bs-toggle="tab"
                        data-bs-target={"#".concat(tabId)}
                        role="button"
                      >
                        {"Day ".concat(idx + 1)}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </PerfectScrollbar>
            <div className="tab-content mt-3 ">
              {data.days.map((el, idx) => {
                const tabId = "a-".concat(idx);

                let active = "";

                if (idx == this.state.activeTab) {
                  active = "active show";
                }

                return (
                  <div
                    className={"tab-pane fade ".concat(active)}
                    id={tabId}
                    role="tabpanel"
                  >
                    <div className="row">
                      {el.sessions.map((sitm, sidx) => {
                        return (
                          <div className="col-md-4 mt-3">
                            <div className="card h-100">
                              <div className="card-body">
                                <div className="border-bottom mb-3 pb-3 ">
                                  <strong>Session {sidx + 1}</strong>
                                  <span className="d-block color-grey">
                                    {sitm.title}
                                  </span>
                                </div>

                                <span className="d-block  color-grey">
                                  Ingest Endpoint:
                                </span>
                                <div className="d-flex mt-1">
                                  <span className="d-inline-block text-break">
                                    {sitm.ingestEndpoint}
                                  </span>
                                  <i
                                    className="far fa-clone ms-2 pointer d-inline-block"
                                    onClick={(e) => {
                                      $c.copyToClipboard(sitm.ingestEndpoint);
                                      $f.createNotification({
                                        type: "success",
                                        message: "Link copied!",
                                      });
                                    }}
                                  ></i>
                                </div>
                                <span className="d-block  color-grey mt-3">
                                  Stream Key:
                                </span>
                                <div className="d-flex mt-1">
                                  <span className="d-inline-block text-break">
                                    {sitm.streamKey}{" "}
                                  </span>
                                  <i
                                    className="far fa-clone ms-2 pointer d-inline-block "
                                    onClick={(e) => {
                                      $c.copyToClipboard(sitm.streamKey);
                                      $f.createNotification({
                                        type: "success",
                                        message: "Stream Key copied!",
                                      });
                                    }}
                                  ></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default ConferenceSettings;
