import React, { Component } from "react";
import parse from "html-react-parser";
import $c from "../../../../../modules/functions";
import $f from "../../../../../src";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import Modals from "../../../../../modules/modals";
import OptionsForHeader from "../../../../../types/optionsForHeader/optionsForHeader";

const OptionsForHeaderButton = function (data) {
  let property = {
    configuration: {
      options: {
        edit: function (args) {
          if ($c.userIsHost() || $c.userIsAdmin()) {
            let newargs = {
              props: { ...data },
              action: "update",
            };

            return {
              label: "Edit",
              onClick: function () {
                $f.getValue(Modals.events.editConference, newargs);
              },
            };
          }
        },
        copyLink: function (args) {
          return {
            label: "Copy link",
            onClick: function () {
              $c.copyToClipboard(
                window["host"]("/conference/".concat(data.data._id))
              );
              $f.createNotification({
                type: "success",
                message: "Link copied!",
              });
            },
          };
        },
        delete: function (args) {
          if ($c.userIsAdmin() || $c.userIsHost()) {
            let newargs = {
              props: { ...data },
            };
            return {
              label: <span className="text-danger">Delete</span>,
              onClick: function () {
                $f.getValue(Modals.events.deleteConference, newargs);
              },
            };
          }
        },
      },
    },
  };

  return <OptionsForHeader data={data} property={property} />;
};

class ConferenceOverview extends Component {
  constructor(props) {
    super(props);

    this.setActiveTab = this.setActiveTab.bind(this);

    this.state = {
      data: [],
      loaded: false,
      training: {},
    };
  }

  componentDidMount() {
    const { data, module, view, specialProperties, response } = this.props;

    let templateConfiguration = view.template.configuration;

    const innerTemplates = ["header"];

    innerTemplates.map((itm, idx) => {
      let tmp = templateConfiguration[itm + "Template"]
        ? templateConfiguration[itm + "Template"]
        : () => {};
      this[itm + "Template"] = tmp.bind(this);
    });

    this.setState({
      activeTab: 0,
      specialProperties,
      data,
      loaded: true,
      view,
      module,
      response,
    });

    // document.body.style.paddingTop = "3.7rem";
  }

  setActiveTab(index) {
    this.setState({ activeTab: index });
  }

  render() {
    if (this.state.loaded) {
      const data = this.state.response.data[0];

      let banner = data.banner
        ? window["baseurl"](
            "/agenda-file/data/" +
              data.banner._id +
              "?type=preview&jwt=" +
              localStorage.getItem("jwt")
          )
        : "/uni/svg/conferencePlaceholder.jpg";

      return (
        <React.Fragment>
          {this.headerTemplate()}
          <div className="pb-5 container-fluid me-0 ms-0">
            <button
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasExample"
              aria-controls="offcanvasExample"
              className="display-lt-md-block mb-3 btn btn-sm btn-white"
            >
              Menu
            </button>

            <div
              className="container-fluid  text-white rounded "
              style={{
                minHeight: "300px",
                maxHeight: "350px",
                height: "100%",
                backgroundImage: "url(" + banner + ")",
                backgroundSize: "cover",
                boxShadow: "rgb(0 0 0 / 30%) 0px 0px 0px 2000px inset",
              }}
            >
              <div className="text-end p-3">
                <OptionsForHeaderButton data={data}></OptionsForHeaderButton>
              </div>
              <div className="p-5">
                <h1 className="display-2">{data.title}</h1>
                <div className="col-md-8 mt-3">
                  {data.shortDescription ? parse(data.shortDescription) : ""}
                </div>
              </div>
            </div>
            <div className="mt-3">
              <h2 className="display-3 mb-0">About</h2>
              <div className=" mt-1 ">
                {data.description ? parse(data.description) : ""}
              </div>
            </div>
            <div className="mt-3">
              <h2 className="display-3">Agenda</h2>
              <PerfectScrollbar className="scrollbar-main">
                <ul className="nav nav-tabs navbar-expand">
                  {data.days.map((el, idx) => {
                    const tabId = "a-".concat(idx);

                    let active = "";

                    if (idx == this.state.activeTab) {
                      active = "active show";
                    }

                    return (
                      <li
                        className={"nav-item "}
                        key={$f.key()}
                        onClick={() => {
                          this.setActiveTab(idx);
                        }}
                      >
                        <span
                          className={"nav-link color-strong-grey nav-ellipsis ".concat(
                            active
                          )}
                          data-bs-toggle="tab"
                          data-bs-target={"#".concat(tabId)}
                          role="button"
                        >
                          {$c.formatDate(el.date)}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </PerfectScrollbar>
              <div className="tab-content mt-3 ">
                {data.days.map((el, idx) => {
                  const tabId = "a-".concat(idx);

                  let active = "";

                  if (idx == this.state.activeTab) {
                    active = "active show";
                  }

                  return (
                    <div
                      className={"tab-pane fade ".concat(active)}
                      id={tabId}
                      role="tabpanel"
                    >
                      {$c.conferenceSessions(el.sessions, el, data)}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default ConferenceOverview;
