import { Link } from "react-router-dom";
import $f from "../src";
import EmptyStates from "./emptyStates";
import $c from "./functions";
import Modals from "./modals";

const ParticipantTable = {
  properties: (args) => {
    const { editable } = args;
    return {
      _id: {
        label: "Name",
        type: "text",
        events: {},
        editable: editable,
        attributes: {
          className: "form-control",
        },
        formatValue: (value, row) => {
          return row.firstName ? (
            $c.userIsAdmin() || $c.getUserId() === row._id ? (
              <>
                <Link to={"/user/".concat(row._id)}>
                  {$c.createAvatar(
                    row.profileImage,
                    row.firstName,
                    row.lastName
                  )}
                </Link>
                {row.organizer ? (
                  <span className="badge bg-light-blue ms-2 color-black">
                    Host
                  </span>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                <span
                  role={row.hasPrivateInfo && $c.userIsMember() ? "" : "button"}
                  onClick={() => {
                    if (row.hasPrivateInfo && $c.userIsMember()) {
                      return;
                    }
                    window.location.replace("/user/".concat(row._id));
                  }}
                >
                  {$c.createAvatar(
                    row.profileImage,
                    row.firstName,
                    row.lastName
                  )}
                </span>
                {row.organizer ? (
                  <span className="badge bg-light-blue ms-2 color-black">
                    Host
                  </span>
                ) : (
                  ""
                )}
              </>
            )
          ) : (
            value
          );

          // return row.firstName ? (
          //   <span
          //     role="button"
          //     onClick={() => {
          //       if (row.hasPrivateInfo && $c.userIsMember()) {
          //         return;
          //       }
          //       window.location.replace("/user/".concat(row._id));
          //       // Modals.contacts.userProfile({ userId: row._id });
          //     }}
          //   >
          //     {$c.createAvatar(row.profileImage, row.firstName, row.lastName)}
          //   </span>
          // ) : (
          //   value
          // );
        },
      },
      optionsfortable: {
        label: "",
        type: "optionsfortable",
        configuration: {
          options: {
            viewProfile: function (args) {
              if (args.props.data.hasPrivateInfo && $c.userIsMember()) {
                return;
              }

              return {
                label: "View profile",
                onClick: function () {
                  args.props.history.push("/user/".concat(args.props.data._id));
                  // Modals.contacts.userProfile({ userId: args.props.data._id });
                },
              };
            },
            removeuser: function (args) {
              if ($f.getQueryPath(2) === "meeting") {
                if (
                  (args.props.module.props.data.data[0].organizer._id !==
                    $c.getUserId() &&
                    !$c.userIsAdmin()) ||
                  args.props.module.props.data.data[0].organizer._id ===
                    args.props.data._id
                ) {
                  return null;
                }

                return {
                  label: <span className="text-danger">Remove user</span>,
                  onClick: (e) => {
                    Modals.participants.removeUser({
                      userName: args.props.data.firstName.concat(
                        " ",
                        args.props.data.lastName,
                        "",
                        args.props.data.email
                      ),
                      sectionId: $f.getQueryPath(3),
                      participantId: args.props.data._id,
                      type: "meeting",
                      participantsProperty: "meetingParticipants",
                    });
                  },
                };
              }

              if (
                $c.userIsAdmin() ||
                ($c.userIsHost() && $f.getQueryPath(2) == "conference")
              ) {
                let type = "";
                let sectionId = "";
                let participantsProperty = "";

                switch ($f.getQueryPath(2)) {
                  case "training":
                    type = "event/training";
                    sectionId = $f.getQueryPath(3);
                    participantsProperty = "trainingParticipants";
                    break;

                  case "conference":
                    type = "stream";
                    sectionId = $f.getQueryPath(3);
                    participantsProperty = "streamParticipants";
                    break;

                  case "test":
                    type = "event/exam";
                    sectionId = $f.getQueryPath(3);
                    participantsProperty = "examParticipants";
                    break;

                  case "space":
                    type = "space";
                    sectionId = $f.getQueryPath(5);
                    participantsProperty = "participants";

                    if (!$f.getQueryPath(5)) {
                      try {
                        sectionId =
                          args.props.module.props.data.data.spaces[0]._id;
                      } catch (e) {}
                    }

                    break;
                }

                if ($f.getQueryPath(1) == "space") {
                  type = "space";
                  sectionId = $f.getQueryPath(4)
                    ? $f.getQueryPath(4)
                    : $f.getQueryPath(2);
                  participantsProperty = "participants";
                }

                return {
                  label: <span className="text-danger">Remove user</span>,
                  onClick: (e) => {
                    Modals.participants.removeUser({
                      userName: args.props.data.firstName.concat(
                        " ",
                        args.props.data.lastName,
                        "",
                        args.props.data.email
                      ),
                      sectionId: sectionId,
                      participantId: args.props.data._id,
                      type: type,
                      participantsProperty: participantsProperty,
                    });
                  },
                };
              }
            },
          },
        },
      },
      firstName: {
        label: "",
        type: "text",
        events: {},
        editable: editable,
        attributes: {
          className: "form-control",
        },
      },
      lastName: {
        label: "",
        type: "text",
        events: {},
        editable: editable,
        attributes: {
          className: "form-control",
        },
      },
      email: {
        label: "Email",
        type: "text",
        events: {},
        editable: editable,
        attributes: {
          className: "form-control",
        },
      },
      participants: {
        label: "Search people and groups",
        type: "tags",
        editable: true,
        configuration: {
          type: "participants",
        },
        events: {},
        attributes: {
          className: "form-control",
          placeholder:
            "Search people and groups or paste multiple email addresses",
        },
      },
      participantsAll: {
        label: "Search people and groups",
        type: "tags",
        editable: true,
        configuration: {
          type: "all",
        },
        events: {},
        attributes: {
          className: "form-control",
          placeholder:
            "Search people and groups or paste multiple email addresses",
        },
      },
      participantsAllAndExternals: {
        label: "Search people and groups",
        type: "tags",
        editable: true,
        configuration: {
          type: "allandexternals",
        },
        events: {},
        attributes: {
          className: "form-control",
          placeholder:
            "Search people and groups or paste multiple email addresses",
        },
      },
    };
  },
  specialProperties: () => {
    return {
      pagination: {
        type: "pagination",
        configuration: {
          start: function (props) {
            return 0;
          },
          offset: "25",
          total: function (props) {
            return props.props.data.data.pagination.total;
          },
          current: function (props) {
            return props.props.data.data.pagination.currentPage;
          },
          replaceAttribute: "start",
        },
        events: {},
      },
    };
  },

  views: {
    list: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            switch ($f.getQueryPath(2)) {
              case "meeting":
                return "/meeting/{id}/participants";
                break;

              case "training":
                return "/event/training/{id}/participants";
                break;

              case "conference":
                return "/stream/{id}/participants";

              case "test":
                return "/event/exam/{id}/participants";
                break;

              case "space":
                return "/space/{id}/participants?start={start}&offset={offset}";
                break;
            }

            if ($f.getQueryPath(1) == "space") {
              return "/space/{id}/participants?start={start}&offset={offset}";
            }
          },
          dataPath: () => {
            if ($f.getQueryPath(1) == "space") {
              return "data.data";
            }
            switch ($f.getQueryPath(2)) {
              case "space":
                return "data.data";
                break;

              default:
                return "data.0.participants";
                break;
            }
          },
          formatResponse: (resp) => {
            switch ($f.getQueryPath(2)) {
              case "meeting":
                let guestUsers = [];

                resp.data[0].guestUsers.forEach((el) => {
                  resp.data[0].participants.push({
                    email: el,
                    firstName: "Guest",
                    lastName: "User",
                  });
                });

                return resp;
                break;

              default:
                return resp;
                break;
            }
          },
          replace: {
            id: () => {
              switch ($f.getQueryPath(2)) {
                case "space":
                  return $f.getQueryPath(5);
                  break;

                default:
                  return $f.getQueryPath(3);
                  break;
              }
            },
            start: 0,
            offset: 25,
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Table";
        },
        configuration: {
          tableConfiguration: {
            headers: {
              _id: {
                attributes: {
                  className: "w-50",
                },
              },
            },
          },
          headerTemplate: () => {
            if ($f.getQueryPath(1) == "space") {
              return "";
            }
            switch ($f.getQueryPath(2)) {
              case "space":
                return "";
                break;

              default:
                return (
                  <div className="display-3-no-bold border-bottom-1">
                    Participants
                  </div>
                );
            }
          },
          bottomTemplate: function () {
            if ($f.getQueryPath(1) == "space") {
              return this.state.specialProperties.pagination.component;
            }
            switch ($f.getQueryPath(2)) {
              case "space":
                return this.state.specialProperties.pagination.component;
                break;

              default:
                return "";
            }
          },
          emptyTemplate: (args) => {
            let path = window.location.pathname;

            let newArgs = { ...args };

            switch (true) {
              case path.startsWith("/event/meeting"):
                let meetingData = newArgs.props.module.props.data.data[0];

                newArgs = { ...newArgs.props.module.props };
                newArgs.addPeople = () => {
                  $f.getValue(Modals.participants.addAll, {
                    title: "Add people",
                    api: "new",
                    actionType: "meeting",
                    actionId: meetingData._id,
                    actionSection: "meetingParticipants",
                  });
                };
                if (meetingData.organizer._id !== $c.getUserId()) {
                  newArgs.viewOnly = true;
                }

                break;

              case path.startsWith("/event/test"):
                let testData = newArgs.props.module.props.data.data[0];

                newArgs = { ...newArgs.props.module.props };
                newArgs.addPeople = () => {
                  $f.getValue(Modals.participants.addParticipants, {
                    title: "Add people",
                    api: "new",
                    actionType: "event/exam",
                    actionId: testData._id,
                    actionSection: "examParticipants",
                  });
                };

                break;

              case path.startsWith("/event/training"):
                let trainingData = newArgs.props.module.props.data.data[0];

                newArgs = { ...newArgs.props.module.props };
                newArgs.addPeople = () => {
                  $f.getValue(Modals.participants.addParticipants, {
                    title: "Add people",
                    api: "new",
                    actionType: "event/training",
                    actionId: trainingData._id,
                    actionSection: "trainingParticipants",
                  });
                };

                break;

              case path.startsWith("/event/conference"):
                let conferencegData = newArgs.props.module.props.data.data[0];

                newArgs = { ...newArgs.props.module.props };
                newArgs.addPeople = () => {
                  $f.getValue(Modals.participants.addParticipants, {
                    title: "Add people",
                    api: "new",
                    actionType: "stream",
                    actionId: conferencegData._id,
                    actionSection: "streamParticipants",
                  });
                };

                break;

              case path.startsWith("/space"):
                newArgs.addPeople = () => {
                  $f.getValue(Modals.participants.addAll, {
                    title: "Add people",
                    api: "new",
                    actionType: "space",
                    actionId: $f.getQueryPath(4)
                      ? $f.getQueryPath(4)
                      : $f.getQueryPath(2),
                    actionSection: "participants",
                  });
                };

                break;
            }

            if ($f.getQueryPath(1) == "space") {
              return $f.getValue(EmptyStates.spaces.participants, newArgs);
            }

            if ($f.getQueryPath(2) == "space") {
              let spaceId = $f.getQueryPath(5)
                ? $f.getQueryPath(5)
                : args.state.module.props.data.data.spaces[0]._id;

              return $f.getValue(EmptyStates.spaces.participants, {
                addPeople: () => {
                  $f.getValue(Modals.participants.addAll, {
                    title: "Add people",
                    api: "new",
                    actionType: "space",
                    actionId: spaceId,
                    actionSection: "participants",
                  });
                },
              });
            }

            return $f.getValue(EmptyStates.defaultParticipants, newArgs);
          },
          includeProperties: ["_id", "optionsfortable", "email"],
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
    detail: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return { data: [{}] };
          },
          headers: {},
          mapper: () => {},
        },
        new: {
          url: () => {
            return { data: [{}] };
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Form";
        },
        configuration: {
          includeProperties: ["participants"],
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
    detailAll: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return { data: [{}] };
          },
          headers: {},
          mapper: () => {},
        },
        new: {
          url: () => {
            return { data: [{}] };
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Form";
        },
        configuration: {
          includeProperties: ["participantsAll"],
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
    detailAllAndExternals: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return { data: [{}] };
          },
          headers: {},
          mapper: () => {},
        },
        new: {
          url: () => {
            return { data: [{}] };
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Form";
        },
        configuration: {
          includeProperties: ["participantsAllAndExternals"],
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
    listNoGroupSpace: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: { data: [{}] },
          replace: {},
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Custom";
        },
        configuration: {
          topTemplate: (args) => {
            return $f.getValue(EmptyStates.spaces.noGroups, args);
          },
          includeProperties: () => {
            return [];
          },
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
  },
};

export default ParticipantTable;
