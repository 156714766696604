import { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import $f from "../../../src";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

class HorizontalNavScroll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    const { data, module, view, configuration } = this.props;

    this.setState({ loaded: true, data, module, view, configuration });
  }

  render() {
    if (this.state.loaded) {
      return (
        <>
          <PerfectScrollbar>
            <ul className="nav nav-tabs navbar-expand">
              {this.state.data.data.map((itm, idx) => {
                const active = itm.isActive() ? "active" : "";

                return (
                  <li className="nav-item ">
                    <Link
                      className={"nav-link color-strong-grey nav-ellipsis ".concat(
                        active
                      )}
                      aria-current={itm.url}
                      to={itm.url}
                      title={itm.title}
                    >
                      {itm.title}
                    </Link>
                  </li>
                );
              })}

              <li className="nav-item ms-auto display-lt-md-none">
                {this.state.configuration?.buttons?.map((el) => {
                  return $f.getValue(el, this);
                })}
              </li>
            </ul>
          </PerfectScrollbar>
          <div className="display-lt-md-block mb-2 mt-2">
            {this.state.configuration?.buttons?.map((el) => {
              return $f.getValue(el, this);
            })}
          </div>
        </>
      );
    }

    return "";
  }
}

export default withRouter(HorizontalNavScroll);
