import $c from "../functions";
import $f from "../../src";

const CommonFilesModule = {
  properties: (args) => {
    const { editable, isFolder } = args;

    return {
      _id: {
        label: "",
        path: "_id",
        type: "hidden",
        labelPosition: "none",
        events: {},
        editable: true,
        attributes: {
          className: "form-control",
        },
      },
      folder: {
        label: "",
        path: "folder",
        type: "hidden",
        labelPosition: "none",
        events: {},
        editable: true,
        attributes: {
          className: "form-control",
        },
      },
      title: {
        label: "Name",
        type: "text",
        editable: editable,
        events: {},
        validation: "required",
        attributes: {
          className: "form-control",
          placeholder: "Type file name",
        },
        formatValue: (value) => {
          return !editable ? (value ? value : "-") : value;
        },
      },
      name: {
        label: "Name",
        type: "text",
        editable: editable,
        events: {},
        attributes: {
          className: "form-control",
          placeholder: isFolder ? "Type folder name" : "Type file name",
        },
        validation: "required",
        formatValue: (value) => {
          return !editable ? (value ? value : "-") : value;
        },
      },
      description: {
        label: "Description",
        type: "textarea",
        editable: editable,
        events: {},
        attributes: {
          className: "form-control",
          placeholder: isFolder
            ? "Type folder description"
            : "Type file description",
        },
        formatValue: (value) => {
          return !editable ? (value ? value : "-") : value;
        },
      },
      parent: {
        label: "",
        type: "hidden",
        editable: true,
        labelPosition: "none",
        events: {},
        attributes: {},
      },
      keywords: {
        label: "Keywords",
        type: "tags",
        editable: editable,
        events: {},
        attributes: {
          className: "form-control",
          placeholder: "Set keywords",
        },
        formatValue: (value) => {
          if (value) {
            return !editable ? (value.length > 0 ? value : "-") : value;
          }
          return [];
        },
      },
      authors: {
        label: "Author(s)",
        type: "tags",
        editable: editable,
        events: {},
        attributes: {
          className: "form-control",
          placeholder: "Set authors",
        },
        formatValue: (value) => {
          if (value) {
            return !editable ? (value.length > 0 ? value : "-") : value;
          }
          return [];
        },
      },
      size: {
        label: "Size",
        type: "text",
        editable: false,
        events: {},
        attributes: {
          className: "form-control",
        },
        formatValue: (value, row) => {
          return <span className="d-block mb-4">{value}</span>;
        },
      },
      createdAt: {
        label: "Date Created",
        type: "text",
        editable: false,
        events: {},
        attributes: {},
        formatValue: (value, row) => {
          return (
            <span className="d-block mb-4">
              {$c.formatDateTime(row.createdAt)}
            </span>
          );
        },
      },
      updatedAt: {
        label: "Last modified",
        type: "text",
        editable: false,
        events: {},
        attributes: {},
        formatValue: (value, row) => {
          return (
            <span className="d-block mb-4">
              {$c.formatDateTime(row.updatedAt)}
            </span>
          );
        },
      },
    };
  },
  views: {
    createFolder: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return { data: [{}] };
          },

          replace: {},
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Form";
        },
        configuration: {
          includeProperties: () => {
            return ["name", "description", "parent"];
          },
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: true, isFolder: true };
        },
      },
    },
    fileDetails: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/file/{id}";
          },
          replace: {},
          headers: {},
          mapper: () => {},
        },
        agendaFile: {
          url: () => {
            return "/agenda-file/{id}";
          },
          replace: {},
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Form";
        },
        configuration: {
          includeProperties: () => {},
          excludeProperties: () => {
            if ($f.getQueryPath("1") == "event") {
              return ["name", "keywords", "authors"];
            }
            return ["name"];
          },
          primaryKey: "_id",
        },
        properties: (args) => {
          if ($c.userIsAdmin()) {
            return { editable: true };
          }

          try {
            if (args.owner._id == $c.getUserId()) {
              return { editable: true };
            }
          } catch (e) {}

          return { editable: false };
        },
      },
    },
    folderDetails: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/folder/{id}";
          },

          replace: {},
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Form";
        },
        configuration: {
          includeProperties: () => {},
          excludeProperties: () => {
            return ["keywords", "authors", "size", "title"];
          },
          primaryKey: "_id",
        },
        properties: (args) => {
          if ($c.userIsAdmin()) {
            return { editable: true, isFolder: true };
          }

          try {
            if (args.createdBy == $c.getUserId()) {
              return { editable: true, isFolder: true };
            }
          } catch (e) {}

          return { editable: false, isFolder: true };
        },
      },
    },
  },
};

export default CommonFilesModule;
