import { Link } from "react-router-dom";
import $f from "../src";
import $c from "./functions";
import Modals from "./modals";
import EmptyStates from "./emptyStates";

const FilesTable = {
  properties: (args) => {
    const { editable } = args;
    return {
      _id: {
        label: "",
        path: "_id",
        type: "text",
        events: {},
        attributes: {},
      },
      title: {
        label: "Name",
        type: "text",
        editable: editable,
        events: {},
        attributes: {},
      },
      optionsfortable: {
        label: "",
        type: "optionsfortable",
        configuration: {
          options: {
            open: function (args) {
              return {
                label: "Preview",
                onClick: function () {
                  let path = window.location.pathname;

                  switch (true) {
                    case path.startsWith("/event/training"):
                      args.props.history.push(
                        "/files/preview/t-".concat(args.props.data._id)
                      );
                      break;

                    default:
                      args.props.history.push(
                        "/files/preview/a-".concat(args.props.data._id)
                      );
                      break;
                  }
                },
              };
            },
            edit: function (args) {
              let isOwner = false;

              try {
                if (args.props.data.owner._id == $c.getUserId()) {
                  isOwner = true;
                }
              } catch (e) {}

              try {
                if (args.props.data.createdBy == $c.getUserId()) {
                  isOwner = true;
                }
              } catch (e) {}

              if (
                window.location.pathname.startsWith("/event/training") &&
                $c.userIsAdmin()
              ) {
                isOwner = true;
              }

              let newargs = {
                props: { data: { ...args.props.data } },
                action: "update-agenda-file",
                title: isOwner ? "Edit file" : "File details",
                api: "agendaFile",
                viewOnly: !isOwner,
              };

              return {
                label: isOwner ? "Edit file" : "File details",
                onClick: function () {
                  $f.getValue(Modals.files.editFile, newargs);
                },
              };
            },
            copyLink: function (args) {
              return {
                label: "Copy link",
                onClick: function () {
                  let path = window.location.pathname;

                  switch (true) {
                    case path.startsWith("/event/training"):
                      $c.copyToClipboard(
                        window["host"](
                          "/files/preview/t-".concat(args.props.data._id)
                        )
                      );
                      break;

                    default:
                      $c.copyToClipboard(
                        window["host"](
                          "/files/preview/a-".concat(args.props.data._id)
                        )
                      );
                      break;
                  }

                  $f.createNotification({
                    type: "success",
                    message: "Link copied!",
                  });
                },
              };
            },
            download: function (args) {
              if ($f.getQueryPath(2) === "training" && $c.userIsMember()) {
                return;
              }

              return {
                label: "Download file",
                onClick: function () {
                  $c.downloadFile(
                    "/agenda-file/data/".concat(
                      args.props.data._id,
                      "?type=download"
                    ),
                    args.props.data.title
                  );
                },
              };
            },

            delete: function (args) {
              let isOwner = false;

              try {
                if (args.props.data.owner._id == $c.getUserId()) {
                  isOwner = true;
                }
              } catch (e) {}

              try {
                if (args.props.data.createdBy == $c.getUserId()) {
                  isOwner = true;
                }
              } catch (e) {}

              if (isOwner) {
                return {
                  label: <span className="text-danger">Delete</span>,
                  onClick: function () {
                    let newargs = {
                      props: { data: { ...args.props.data } },
                      action: "delete-agenda-file",
                    };

                    switch ($f.getQueryPath(2)) {
                      case "meeting":
                        newargs.type = "meeting";
                        newargs.sectionId = "meetingId";
                        newargs.eventId = $f.getQueryPath(3);
                        break;

                      case "training":
                        newargs.type = "training";
                        newargs.sectionId = "trainingId";
                        newargs.eventId = $f.getQueryPath(3);
                        break;
                    }

                    $f.getValue(Modals.files.deleteFile, newargs);
                  },
                };
              }
            },
          },
        },
      },
      sessions: {
        label: "Session included",
        type: "text",
        editable: editable,
        events: {},
        attributes: {},
        formatValue: (value) => {
          try {
            return value === 1
              ? value.toString().concat(" session")
              : value.toString().concat(" sessions");
          } catch (e) {}
          return value;
        },
      },
      filesCount: {
        label: "Files included",
        type: "text",
        editable: editable,
        events: {},
        path: "files",
        attributes: {},
        formatValue: (value) => {
          try {
            return value === 1
              ? value.toString().concat(" file")
              : value.toString().concat(" files");
          } catch (e) {}
          return value;
        },
      },
      updatedAt: {
        label: "Last Modified",
        type: "text",
        editable: editable,
        events: {},
        attributes: {},
      },
      size: {
        label: "Size",
        type: "text",
        editable: editable,
        events: {},
        attributes: {},
      },
    };
  },
  specialProperties: () => {
    return {
      breadcrumb: {
        type: "breadcrumb",
        events: {},
        attributes: {},
        configuration: {
          type: () => {
            if ($f.getQueryPath(6)) {
              return "trainingSessions";
            }

            return "trainingModules";
          },
          customClass: "pt-2",
        },
      },
    };
  },
  views: {
    list: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            let path = window.location.pathname;

            switch (true) {
              case path.startsWith("/event/meeting"):
                return "/meeting/{meetingId}";
                break;
            }

            return "/folder/".concat($c.getPersonalFolderDirectory());
          },
          dataPath: () => {
            let path = window.location.pathname;

            switch (true) {
              case path.startsWith("/event/meeting"):
                return "data.0.files";
                break;
            }

            return "data.0.allFiles";
          },
          formatResponse: function (resp) {
            return $c.formatFileResponse(resp, "agenda");
          },
          replace: {
            meetingId: () => {
              return $f.getQueryPath(3);
            },
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: (args) => {
          let path = window.location.pathname;

          switch (true) {
            case path.startsWith("/event/meeting"):
              let meetingData = args.props.data.data[0];
              const allMeetingPeople = [];

              meetingData?.organizers?.forEach((el) => {
                allMeetingPeople.push(el._id);
              });

              meetingData?.participants?.forEach((el) => {
                allMeetingPeople.push(el._id);
              });

              if (allMeetingPeople.indexOf($c.getUserId()) >= 0) {
                return "FileTable";
              }

              break;

            case path.startsWith("/space"):
              return "FileTable";
              break;
          }

          return "Table";
        },
        configuration: {
          headerTemplate: () => {
            return (
              <div className="display-3-no-bold border-bottom-1">Files</div>
            );
          },
          emptyTemplate: (args) => {
            let path = window.location.pathname;

            let newArgs = { ...args };

            switch (true) {
              case path.startsWith("/event/meeting"):
                const meetingData = args.props.module.props.data.data[0];
                const allMeetingPeople = [];

                meetingData?.organizers?.forEach((el) => {
                  allMeetingPeople.push(el._id);
                });

                meetingData?.participants?.forEach((el) => {
                  allMeetingPeople.push(el._id);
                });

                if (allMeetingPeople.indexOf($c.getUserId()) < 0) {
                  newArgs.viewOnly = true;
                }

                break;
            }

            return $f.getValue(EmptyStates.defaultFiles, newArgs);
          },
          filetableConfiguration: {
            url: function (args) {
              let path = window.location.pathname;

              switch (true) {
                case path.startsWith("/event/meeting"):
                  return "/agenda-files/meeting/".concat($f.getQueryPath(3));

                default:
                  return "/file";
              }
            },
            fileDataProperty: function (args) {
              let path = window.location.pathname;

              switch (true) {
                case path.startsWith("/event/meeting"):
                  return "files";

                default:
                  return "file";
              }
            },
            type: function () {
              let path = window.location.pathname;

              switch (true) {
                case path.startsWith("/event/meeting"):
                  return "meeting";

                default:
                  return null;
              }
            },
            folder: function (args) {
              let path = window.location.pathname;

              switch (true) {
                case path.startsWith("/event/meeting"):
                  return null;

                default:
                  return $f.getParameterByName("_id")
                    ? $f.getParameterByName("_id")
                    : $c.getPersonalFolderDirectory();
              }
            },
          },
          tableConfiguration: {
            headers: {
              title: {
                attributes: {
                  className: "w-50",
                },
              },
            },
            cellTemplates: {
              title: (data) => {
                return $c.createFileTitleForTable(data);
              },
              updatedAt: (value) => {
                return $c.formatDateTime(value.props.value);
              },
            },
          },
          includeProperties: () => {
            return ["title", "optionsfortable", "updatedAt", "size"];
          },
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
    trainingModules: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: "/training/{id}/modules",
          replace: {
            id: () => {
              return $f.getQueryPath(3);
            },
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Table";
        },
        configuration: {
          headerTemplate: (_this) => {
            return (
              <>
                <div className="display-3-no-bold border-bottom-1">Files</div>
                {_this.state.specialProperties.breadcrumb.component}
              </>
            );
          },
          tableConfiguration: {
            headers: {
              title: {
                attributes: {
                  className: "w-50",
                },
              },
            },
            cellTemplates: {
              title: function (value) {
                let url =
                  "/event/training/".concat($f.getQueryPath(3)) +
                  "/files/module/".concat(value.props.data.moduleId);

                return (
                  <Link className="d-flex text-normal" to={url}>
                    <img
                      src="/uni/svg/folder.svg"
                      className="me-2"
                      style={{ width: "21px" }}
                    ></img>
                    <span className="text-ellipsis mt-auto mb-auto">
                      {value}
                    </span>
                  </Link>
                );
              },
            },
          },
          includeProperties: () => {
            return ["title", "sessions"];
          },
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
    trainingSessions: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: "/module/{id}/sessions",
          replace: {
            id: () => {
              return $f.getQueryPath(6);
            },
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Table";
        },
        configuration: {
          headerTemplate: (_this) => {
            return (
              <>
                <div className="display-3-no-bold border-bottom-1">Files</div>
                {_this.state.specialProperties.breadcrumb.component}
              </>
            );
          },
          tableConfiguration: {
            headers: {
              title: {
                attributes: {
                  className: "w-50",
                },
              },
            },
            cellTemplates: {
              title: function (value) {
                let url =
                  "/event/training/".concat($f.getQueryPath(3)) +
                  "/files/module/".concat($f.getQueryPath(6)) +
                  "/session/".concat(value.props.data.sessionId);

                return (
                  <Link
                    className="d-flex text-normal"
                    to={url}
                    title={value.props.data.title}
                  >
                    <img
                      src="/uni/svg/folder.svg"
                      className="me-2"
                      style={{ width: "21px" }}
                    ></img>
                    <span className="text-ellipsis mt-auto mb-auto">
                      {value}
                    </span>
                  </Link>
                );
              },
            },
          },
          includeProperties: () => {
            return ["title", "filesCount"];
          },
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
    trainingFiles: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: "/session/{id}/files",
          dataPath: () => {
            return "data.0.files";
          },
          formatResponse: function (resp) {
            return $c.formatFileResponse(resp, "training");
          },
          replace: {
            id: () => {
              return $f.getQueryPath(8);
            },
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Table";
        },
        configuration: {
          headerTemplate: (_this) => {
            return (
              <>
                <div className="display-3-no-bold border-bottom-1">Files</div>
                {_this.state.specialProperties.breadcrumb.component}
              </>
            );
          },
          emptyTemplate: (args) => {
            let path = window.location.pathname;

            let newArgs = { ...args };

            return $f.getValue(EmptyStates.defaultFiles, { viewOnly: true });
          },
          tableConfiguration: {
            headers: {
              title: {
                attributes: {
                  className: "w-50",
                },
              },
            },
            cellTemplates: {
              title: (data) => {
                return $c.createFileTitleForTable(data);
              },
              updatedAt: (value) => {
                return $c.formatDateTime(value.props.value);
              },
            },
          },
          includeProperties: () => {
            return ["title", "optionsfortable", "updatedAt", "size"];
          },
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
  },
};

export default FilesTable;
