import $c from "../functions";
import $f from "../../src";

const FilePreview = {
  properties: (args) => {
    const { editable } = args;

    return {
      _id: {
        label: "",
        path: "_id",
        type: "text",
        events: {},
        attributes: {},
      },
      title: {
        label: "",
        type: "text",
        editable: editable,
        events: {},
        attributes: {},
        formatValue: (value, row) => {
          return $c.createFileTitle(row);
        },
      },
      url: {
        label: "",
        type: "text",
        editable: editable,
        events: {},
        attributes: {},
        formatValue: (value, row) => {
          if (
            $f.getQueryPath(3).startsWith("a-") ||
            $f.getQueryPath(3).startsWith("t-")
          ) {
            return window["baseurl"](
              "/agenda-file/data/" +
                row._id.replace("a-", "").replace("t-", "") +
                "?type=preview&jwt=" +
                localStorage.getItem("jwt") +
                "&.epub"
            );
          }

          return window["baseurl"](
            "/file/data/" +
              row._id +
              "?type=preview&jwt=" +
              localStorage.getItem("jwt")
          );
        },
      },
      downloadUrl: {
        label: "",
        type: "text",
        editable: editable,
        events: {},
        attributes: {},
        formatValue: (value, row) => {
          if (
            $f.getQueryPath(3).startsWith("a-") ||
            $f.getQueryPath(3).startsWith("t-")
          ) {
            return (
              "/agenda-file/data/" +
              row._id.replace("a-", "").replace("t-", "") +
              "?type=download&.epub"
            );
          }

          return "/file/data/" + row._id + "?type=download";
        },
      },
    };
  },
  specialProperties: () => {
    return {
      header: {
        type: "template",
        configuration: {
          title: "Personal Files",
          subTitle:
            "This is your personal file repository. You can upload or drag & drop files into this page.",
          template: "Header",
        },
        events: {},
      },
    };
  },
  views: {
    filePreview: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            if (
              $f.getQueryPath(3).startsWith("a-") ||
              $f.getQueryPath(3).startsWith("t-")
            ) {
              return "/agenda-file/".concat(
                $f.getQueryPath(3).replace("a-", "").replace("t-", "")
              );
            }
            return "/file/".concat($f.getQueryPath(3));
          },
          formatResponse: (data) => {
            return $c.formatFileResponse(data);
          },
          replace: {
            start: 0,
            offset: 20,
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "FilePreview";
        },
        configuration: {
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
  },
};

export default FilePreview;
