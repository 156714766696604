import $c from "../functions";
import $f from "../../src";
import Modals from "../modals";
import Actions from "../actions/actions";
import EmptyStates from "../emptyStates";

const SpaceFiles = {
  properties: (args) => {
    const { editable } = args;
    return {
      _id: {
        label: "",
        path: "_id",
        type: "text",
        events: {},
        attributes: {},
      },
      title: {
        label: "Name",
        type: "text",
        editable: editable,
        events: {},
        attributes: {},
      },
      optionsfortable: {
        label: "",
        type: "optionsfortable",
        configuration: {
          options: {
            open: function (args) {
              if (args.props.data.isFolder) {
                return {
                  label: "Open",
                  onClick: function () {
                    args.props.history.push(
                      "/files?_id=".concat(args.props.data._id)
                    );
                  },
                };
              }

              return {
                label: "Preview",
                onClick: function () {
                  args.props.history.push(
                    "/files/preview/".concat(args.props.data._id)
                  );
                },
              };
            },
            edit: function (args) {
              let isOwner = false;

              try {
                if (args.props.data.owner._id == $c.getUserId()) {
                  isOwner = true;
                }
              } catch (e) {}

              if ($c.userIsAdmin()) {
                isOwner = true;
              }

              if (args.props.data.isFolder) {
                let newargs = {
                  props: { data: { ...args.props.data } },
                  action: "update",
                  title: isOwner ? "Edit folder" : "Preview folder",
                };

                return {
                  label: isOwner ? "Edit folder" : "Preview folder",
                  onClick: function () {
                    $f.getValue(Modals.files.editFolder, newargs);
                  },
                };
              }

              let newargs = {
                props: { data: { ...args.props.data } },
                action: "update",
                title: isOwner ? "Edit file" : "Preview file",
              };

              return {
                label: isOwner ? "Edit file" : "Preview file",
                onClick: function () {
                  $f.getValue(Modals.files.editFile, newargs);
                },
              };
            },
            copyLink: function (args) {
              if (args.props.data.isFolder) {
                return {
                  label: "Copy link",
                  onClick: function () {
                    $c.copyToClipboard(
                      window["host"]("/files?_id=".concat(args.props.data._id))
                    );
                    $f.createNotification({
                      type: "success",
                      message: "Link copied!",
                    });
                  },
                };
              }

              return {
                label: "Copy link",
                onClick: function () {
                  $c.copyToClipboard(
                    window["host"](
                      "/files/preview/".concat(args.props.data._id)
                    )
                  );
                  $f.createNotification({
                    type: "success",
                    message: "Link copied!",
                  });
                },
              };
            },
            download: function (args) {
              if (args.props.data.isFolder) {
                return {
                  label: "Download folder",
                  onClick: function () {
                    $c.downloadFile(
                      "/folder/downloadContents/".concat(
                        args.props.data._id,
                        "?type=download"
                      ),
                      args.props.data.title
                    );
                  },
                };
              }

              return {
                label: "Download file",
                onClick: function () {
                  $c.downloadFile(
                    "/file/data/".concat(args.props.data._id, "?type=download"),
                    args.props.data.title
                  );
                },
              };
            },
            favorites: function (args) {
              if (
                $f.getQueryPath(1) == "space" ||
                $f.getQueryPath(2) == "space"
              ) {
                return "";
              }

              let label = args.props.data.isFavorite
                ? "Remove from favorites"
                : "Add to favorites";

              if (args.props.data.isFolder) {
                return {
                  label: label,
                  onClick: function () {
                    if (args.props.data.isFavorite) {
                      Actions.files.removeFromFavorites(
                        args.props.data._id,
                        "folder"
                      );
                    } else {
                      Actions.files.addToFavorites(
                        args.props.data._id,
                        "folder"
                      );
                    }
                  },
                };
              }

              return {
                label: label,
                onClick: function () {
                  if (args.props.data.isFavorite) {
                    Actions.files.removeFromFavorites(
                      args.props.data._id,
                      "file"
                    );
                  } else {
                    Actions.files.addToFavorites(args.props.data._id, "file");
                  }
                },
              };
            },
            delete: function (args) {
              let isOwner = false;

              try {
                if (args.props.data.owner._id == $c.getUserId()) {
                  isOwner = true;
                }
              } catch (e) {}

              if ($c.userIsAdmin() || isOwner) {
                if (args.props.data.isFolder) {
                  return {
                    label: <span className="text-danger">Delete</span>,
                    onClick: function () {
                      $f.getValue(Modals.files.deleteFolder, args);
                    },
                  };
                }

                return {
                  label: <span className="text-danger">Delete</span>,
                  onClick: function () {
                    $f.getValue(Modals.files.deleteFile, args);
                  },
                };
              }
            },
          },
        },
      },
      updatedAt: {
        label: "Last Modified",
        type: "text",
        editable: editable,
        events: {},
        attributes: {},
      },
      size: {
        label: "Size",
        type: "text",
        editable: editable,
        events: {},
        attributes: {},
      },
    };
  },
  specialProperties: () => {
    return {
      header: {
        type: "template",
        configuration: function (args) {
          if (
            !args.props.data.data.spaces[0] &&
            !$f.getParameterByName("_id")
          ) {
            return {
              title: (resp) => {
                try {
                  return resp.data.data.breadCrumb[0].name;
                } catch (e) {}
                return "Space Files";
              },
              subTitle: (resp) => {
                try {
                  return (
                    <>
                      In this page you'll find files associated to{" "}
                      {resp.data.data.breadCrumb[0].name}.
                    </>
                  );
                } catch (e) {}
                return "In this page you'll find files associated to the current space";
              },
              buttons: [],
              template: "Header",
            };
          }

          return {
            title: (resp) => {
              try {
                return resp.data.data.breadCrumb[0].name;
              } catch (e) {}
              return "Space Files";
            },
            subTitle: (resp) => {
              try {
                return (
                  <>
                    In this page you'll find files associated to{" "}
                    {resp.data.data.breadCrumb[0].name}.
                  </>
                );
              } catch (e) {}
              return "In this page you'll find files associated to the current space";
            },
            buttons: [
              <button
                type="button"
                className="btn btn-white btn-sm me-2"
                onClick={() => {
                  let parentId = $f.getParameterByName("_id")
                    ? $f.getParameterByName("_id")
                    : args.props.data.data.spaces[0].fileDirectory;
                  $f.getValue(Modals.files.newFolder, {
                    inputData: [
                      {
                        name: "",
                        description: "",
                        parent: parentId,
                      },
                    ],
                  });
                }}
              >
                New Folder
              </button>,
              <button
                type="button"
                className="btn btn-purple  btn-sm  "
                onClick={() => {
                  let spaceFileDirectory = "";

                  try {
                    spaceFileDirectory =
                      args.props.data.data.spaces[0].fileDirectory;
                  } catch (e) {}

                  if (!$f.getParameterByName("_id")) {
                    window.history.pushState(
                      "",
                      "",
                      "?_id=" + spaceFileDirectory
                    );
                  }

                  const fileTemplateRef =
                    args.props.module.refs.files.current.refTemplate.current;
                  fileTemplateRef.onTargetClick();
                }}
              >
                Upload Files
              </button>,
            ],

            template: "Header",
          };
        },
        events: {},
      },
      files: {
        label: "",
        type: "module",
        configuration: (resp) => {
          if (!$f.getParameterByName("_id")) {
            let fileDirectory = "";

            try {
              fileDirectory = resp.props.value?.data?.spaces[0]?.fileDirectory;
            } catch (e) {}
            if (!fileDirectory) {
              return {
                module: "SpaceFiles",
                view: "spaceFilesNoGroup",
              };
            }

            return {
              module: "SpaceFiles",
              view: "spaceFiles",
              replace: {
                url: () => {
                  return "/folder/".concat(fileDirectory);
                },
              },
            };
          }

          return {
            module: "SpaceFiles",
            view: "spaceFiles",
          };
        },
      },
      groups: {
        type: "template",
        configuration: {
          data: (resp) => {
            let data = [];
            try {
              let hasActive = false;

              resp.data.data.spaces.forEach((el) => {
                if ($f.getQueryPath(5) == el._id) {
                  hasActive = true;
                  data.push({
                    url:
                      "/files/space/" +
                      el.breadCrumb[0]._id +
                      "/group/" +
                      el._id +
                      "?_id=" +
                      el.fileDirectory,
                    title: el.name,
                    isActive: () => {
                      return true;
                    },
                  });
                } else {
                  data.push({
                    url:
                      "/files/space/" +
                      el.breadCrumb[0]._id +
                      "/group/" +
                      el._id +
                      "?_id=" +
                      el.fileDirectory,
                    title: el.name,
                    isActive: () => {
                      return false;
                    },
                  });
                }
              });

              resp.data.data.breadCrumb.forEach((el) => {
                if (!hasActive) {
                  // data.unshift({
                  //   url: "/files/space/" + el._id,
                  //   title: el.name,
                  //   isActive: () => {
                  //     return true;
                  //   },
                  // });
                } else {
                  // data.push({
                  //   url: "/files/space/" + el._id,
                  //   title: el.name,
                  //   isActive: () => {
                  //     return false;
                  //   },
                  // });
                }
              });

              if (!hasActive) {
                data[0].isActive = () => {
                  return true;
                };
              }
            } catch (e) {}
            return data;
          },
          template: "HorizontalNav",
        },
      },
      breadcrumb: {
        type: "breadcrumb",
        events: {},
        attributes: {},
        configuration: {
          type: "spacefiles",
          customClass: "pt-2",
        },
      },
    };
  },
  views: {
    overview: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/spaces/root/" + $f.getQueryPath(3) + "?treeview=false";
          },
          dataPath: () => {
            return "data.spaces";
          },
          formatResponse: (data) => {
            return $c.formatFileResponse(data);
          },
          replace: {
            start: 0,
            offset: 20,
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Custom";
        },
        configuration: {
          headerTemplate: function () {
            return (
              <>
                {this.state.specialProperties.header.component}
                {this.state.specialProperties.groups.component}
                {this.state.specialProperties.files.component}
              </>
            );
          },
          includeProperties: () => {
            return ["_id"];
          },
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
    spaceFiles: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: "{url}",
          dataPath: () => {
            return "data.0.allFiles";
          },
          formatResponse: (data) => {
            return $c.formatFileResponse(data);
          },
          replace: {
            start: 0,
            offset: 20,
            url: () => {
              if ($f.getParameterByName("_id")) {
                return "/folder/".concat($f.getParameterByName("_id"));
              }

              return "/folder/";
            },
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "FileTable";
        },
        configuration: {
          headerTemplate: function () {
            return <>{this.state.specialProperties.breadcrumb.component}</>;
          },
          filetableConfiguration: {
            url: function (args) {
              return "/file";
            },
            fileDataProperty: "file",
            folder: function (args) {
              let spaceFileDirectory = "";

              try {
                spaceFileDirectory =
                  args.state.module.props.property.spaceFileDirectory;
              } catch (e) {}

              return $f.getParameterByName("_id")
                ? $f.getParameterByName("_id")
                : spaceFileDirectory;
            },
          },
          emptyTemplate: (args) => {
            let path = window.location.pathname;

            let newArgs = { ...args };

            return $f.getValue(EmptyStates.defaultFiles, newArgs);
          },
          tableConfiguration: {
            headers: {
              fullName: {
                attributes: {
                  className: "w-50",
                },
              },
            },

            cellTemplates: {
              title: (data, args, module) => {
                return $c.createFileTitleForTable(data);
              },
              updatedAt: (value) => {
                return $c.formatDateTime(value.props.value);
              },
            },
          },
          includeProperties: () => {
            return ["title", "optionsfortable", "updatedAt", "size"];
          },
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
    spaceFilesNoGroup: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: { data: [{}] },
          replace: {},
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Custom";
        },
        configuration: {
          topTemplate: (args) => {
            return $f.getValue(EmptyStates.spaces.noGroups, args);
          },
          includeProperties: () => {
            return [];
          },
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
  },
};

export default SpaceFiles;
