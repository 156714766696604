import { Link } from "react-router-dom";
import $f from "../../src";
import EmptyStates from "../emptyStates";
import $c from "../functions";
import Modals from "../modals";

const TrainingTestResults = {
  properties: (args) => {
    const { editable } = args;
    return {
      _id: {
        label: "",
        type: "hidden",
        events: {},
        editable: false,
        attributes: {
          className: "form-control",
        },
      },
      title: {
        label: "Title",
        type: "text",
        events: {},
        editable: false,
        attributes: {
          className: "form-control",
        },
      },
      participant: {
        label: "User",
        type: "text",
        editable: editable,
        events: {},
        attributes: {},
        formatValue: (value, row) => {
          return value?.firstName
            ? $c.createAvatar(
                value.profileImage,
                value.firstName,
                value.lastName
              )
            : value;
        },
      },
      joinEventButton: {
        label: "",
        type: "joineventbutton",
        formatValue: (value, row) => {
          return row;
        },
        events: {
          onClick: function (e) {
            const userExamId = this.props.value?.userExamId;
            if (userExamId) {
              window.location.replace("/test/".concat(this.props.value?._id));
            } else {
              Modals.events.startContinueTest({
                props: { data: this.props.value },
              });
            }
          },
        },
        attributes: {
          className: "btn btn-purple btn-sm",
        },
        caption: (args) => {
          const userExamId = args.props.value?.userExamId;

          if (userExamId) {
            return "Continue test";
          }
          return "Take test";
        },
        configuration: {
          eventNotStartedText: "",
          eventHasEndedText: "",
          hasEnded: (args) => {
            return $c.testSubmitted(args.props.value?.status);
          },
          fromDateProperty: "from",
          endDateProperty: "to",
          extraStartTime: 0,
          extraEndTime: 0,
          eventInterval: 1000,
          eventIntervalFunction: () => {},
        },
      },
      passingGrade: {
        label: "Category",
        type: "text",
        events: {},
        editable: editable,
        attributes: {
          className: "form-control",
        },
        formatValue: (value, row) => {
          return $c.testGrade(value, "badge");
        },
      },
      fromtoDatetime: {
        label: "Availability",
        type: "text",
        events: {},
        editable: editable,
        formatValue: (value, row) => {
          return $c.formatDateFromTo(row.from, row.to);
        },
        attributes: {
          className: "form-control",
        },
      },
      allParticipants: {
        label: "Status",
        type: "text",
        events: {},
        editable: editable,
        formatValue: (value, row) => {
          try {
            return row.participantsSubmitted
              .toString()
              .concat("/", row.allParticipants, " have submitted the test");
          } catch (e) {}
          return "";
        },
        attributes: {
          className: "form-control",
        },
      },
      result: {
        label: "Result",
        type: "text",
        events: {},
        editable: editable,
        attributes: {
          className: "form-control",
        },
        formatValue: (value, row, moduleReferer) => {
          try {
            if (row.passingGrade > 0) {
              switch (row.result) {
                case "Pass":
                  return <div className="badge bg-success">Pass</div>;
                  break;

                case "Fail":
                  return <div className="badge bg-danger">Fail</div>;
                  break;
              }
            }
          } catch (e) {}

          return "---";
        },
      },

      status: {
        label: "Status",
        type: "text",
        events: {},
        editable: editable,
        attributes: {
          className: "form-control",
        },
        formatValue: (value, row, moduleReferer) => {
          try {
            if (row.result == "Not Evaluated") {
              return "Pending evaluation";
            }

            return row.status ? row.status : "Not submitted";
          } catch (e) {}
        },
      },
      finalGrade: {
        label: "Grade",
        type: "text",
        path: "grade",
        events: {},
        editable: editable,
        attributes: {
          className: "form-control",
        },
        formatValue: (value, row) => {
          if (!value) {
            return "---";
          }

          if (row.result == "Not Evaluated") {
            return "---";
          }

          return value != "NaN" ? value.toString().concat("%") : "---";
        },
      },
      userResult: {
        label: "Result",
        type: "text",
        events: {},
        editable: editable,
        attributes: {
          className: "form-control",
        },
        formatValue: (value, row, moduleReferer) => {
          try {
            if (row.passingGrade > 0) {
              switch (row.status) {
                case "Pass":
                  return <div className="badge bg-success">Pass</div>;
                  break;

                case "Fail":
                  return <div className="badge bg-danger">Fail</div>;
                  break;
              }
            }
          } catch (e) {}

          return "---";
        },
      },
      userStatus: {
        label: "Status",
        type: "text",
        events: {},
        editable: editable,
        attributes: {
          className: "form-control",
        },
        formatValue: (value, row, moduleReferer) => {
          try {
            if (row.status == "Not Evaluated") {
              return "Pending evaluation";
            }

            if ($c.testSubmitted(row.status)) {
              return "Submitted";
            }
          } catch (e) {}

          return "---";
        },
      },
      userFinalGrade: {
        label: "Grade",
        type: "text",
        path: "finalGrade",
        events: {},
        editable: editable,
        attributes: {
          className: "form-control",
        },
        formatValue: (value, row) => {
          if (!value) {
            return "---";
          }

          if (row.status == "Not Evaluated") {
            return "---";
          }

          return value != "NaN" ? value.toString().concat("%") : "---";
        },
      },
    };
  },
  views: {
    list: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/training/{id}/tests";
          },
          showErrors: false,
          onError: (resp) => {
            return [];
          },
          replace: {
            id: () => {
              return $f.getQueryPath(3);
            },
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Table";
        },
        configuration: {
          tableConfiguration: {
            headers: {
              name: {
                attributes: {
                  className: "w-50",
                },
              },
            },
            cellTemplates: {
              title: (comp, itm) => {
                if ($c.userIsAdmin()) {
                  return (
                    <Link
                      to={"/event/training/".concat(
                        $f.getQueryPath(3),
                        "/test/",
                        itm._id.value,
                        "/results"
                      )}
                      className="color-grey"
                    >
                      {comp}
                    </Link>
                  );
                } else {
                  if (itm.status.value == "Not submitted") {
                    return <span className="color-grey">{comp}</span>;
                  }
                  return (
                    <Link
                      to={"/event/test/"
                        .concat(itm._id.data.userExamId)
                        .concat("/result")}
                      className="color-grey"
                    >
                      {comp}
                    </Link>
                  );
                }
              },
            },
          },
          emptyTemplate: function (arg) {
            return EmptyStates.events.trainings.results();
          },
          headerTemplate: () => {
            return (
              <div className="display-3-no-bold border-bottom-1">Results</div>
            );
          },
          includeProperties: function () {
            if ($c.userIsAdmin()) {
              return [
                "title",
                "passingGrade",
                "fromtoDatetime",
                "allParticipants",
              ];
            } else {
              if ($c.userIsSimpleMember()) {
                return [
                  "title",
                  "joinEventButton",
                  "passingGrade",
                  "fromtoDatetime",
                  "status",
                  "result",
                  "finalGrade",
                ];
              } else {
                return [
                  "title",
                  "passingGrade",
                  "fromtoDatetime",
                  "status",
                  "result",
                  "finalGrade",
                ];
              }
            }
          },
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
    userResults: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/exam/{id}/user-exam";
          },
          replace: {
            id: () => {
              return $f.getQueryPath(5);
            },
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Table";
        },
        configuration: {
          tableConfiguration: {
            headers: {
              name: {
                attributes: {
                  className: "w-50",
                },
              },
            },
            cellTemplates: {
              participant: (comp, itm) => {
                return (
                  <Link
                    to={"/event/test/".concat(itm._id.value).concat("/result")}
                    className="color-grey"
                  >
                    {comp}
                  </Link>
                );
              },
            },
          },
          emptyTemplate: () => {
            return EmptyStates.events.tests.results();
          },
          headerTemplate: (args) => {
            let examTitle = "Results";
            let examBadge = "";
            try {
              args.state.module.props.data.data[0].modules?.forEach(
                (module) => {
                  module.sessions?.forEach((session) => {
                    session.exam?.forEach((exam) => {
                      if (exam._id == $f.getQueryPath(5)) {
                        examTitle = exam.title;
                        examBadge = $c.testGrade(exam.passingGrade, "badge");
                      }
                    });
                  });
                }
              );
            } catch (e) {}

            return (
              <div className="d-flex border-bottom-1">
                <div className="mt-2">{examBadge}</div>
                <div
                  className="display-3-no-bold ms-2  w-100 text-ellipsis"
                  title={examTitle}
                >
                  {examTitle}
                </div>
              </div>
            );
          },
          includeProperties: function () {
            if ($c.userIsAdmin()) {
              return [
                "participant",
                "userResult",
                "userStatus",

                "userFinalGrade",
              ];
            }
            return [];
          },
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
  },
};

export default TrainingTestResults;
