const Users = {
  properties: {
    _id: {
      label: "",
      path: "_id",
      type: "text",
      events: {},
      attributes: {},
    },
  },
  views: {
    list: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {},
          data: [],
          replace: {},
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {},
        configuration: {
          includeProperties: () => {},
          excludeProperties: () => {},
        },
        properties: () => {},
      },
    },
  },
};

export default Users;
