import { Link } from "react-router-dom";
import $f from "../src";
import EmptyStates from "./emptyStates";
import $c from "./functions";
import Modals from "./modals";

const Contacts = {
  properties: (args) => {
    const { editable } = args;

    return {
      _id: {
        label: "",
        path: "_id",
        type: "text",
        events: {},
        attributes: {},
      },
      fullName: {
        label: "Name",
        type: "text",
        editable: editable,
        events: {},
        attributes: {},
        formatValue: (value, row) => {
          return row.firstName ? (
            $c.userIsAdmin() || $c.getUserId() === row._id ? (
              <Link to={"/user/".concat(row._id)}>
                {$c.createAvatar(row.profileImage, row.firstName, row.lastName)}
              </Link>
            ) : (
              <span
                role={row.hasPrivateInfo && $c.userIsMember() ? "" : "button"}
                onClick={() => {
                  if (row.hasPrivateInfo && $c.userIsMember()) {
                    return;
                  }
                  window.location.replace("/user/".concat(row._id));
                }}
              >
                {$c.createAvatar(row.profileImage, row.firstName, row.lastName)}
              </span>
            )
          ) : (
            value
          );
        },
      },
      optionsfortable: {
        label: "",
        type: "optionsfortable",
        configuration: {
          options: {
            viewProfile: function (args) {
              if (args.props.data.status !== "registration") {
                if (args.props.data.hasPrivateInfo && $c.userIsMember()) {
                  return;
                }

                return {
                  label: "View profile",
                  onClick: function () {
                    args.props.history.push(
                      "/user/".concat(args.props.data._id)
                    );
                  },
                };
              }
            },
            edit: function (args) {
              if ($c.userIsAdmin() || $c.getUserId() === args.props.data._id) {
                return {
                  label: "Edit",
                  onClick: function () {
                    args.props.history.push(
                      "/user/".concat(args.props.data._id)
                    );
                  },
                };
              }
            },
            activateDeactivate: function (args) {
              if (
                ($c.userIsAdmin() || $c.userIsViewer()) &&
                args.props.data.status !== "registration"
              ) {
                return {
                  label:
                    args.props.data.status == "deactivated"
                      ? "Activate user"
                      : "Deactivate",
                  onClick: function () {
                    Modals.contacts.activateDeactivateContact({
                      userName: args.props.data.firstName.concat(
                        " ",
                        args.props.data.lastName
                      ),
                      _id: args.props.data._id,
                      action:
                        args.props.data.status == "deactivated"
                          ? "activate"
                          : "deactivate",
                    });
                  },
                };
              }
              return;
            },
            resendInvitation: function (args) {
              const data = args.props.data;

              if (
                (data.hasPrivateInfo ||
                  $c.userIsAdmin() ||
                  $c.userIsViewer()) &&
                data.status == "registration"
              ) {
                return {
                  label: "Resend invitation",
                  onClick: (e) => {
                    Modals.contacts.resendInvitation({
                      userName: args.props.data.firstName.concat(
                        " ",
                        args.props.data.lastName
                      ),
                      email: args.props.data.email,
                    });
                  },
                };
              }
            },
            revokeInvitation: function (args) {
              const data = args.props.data;

              if (
                (data.hasPrivateInfo ||
                  $c.userIsAdmin() ||
                  $c.userIsViewer()) &&
                data.status == "registration"
              ) {
                return {
                  label: <span className="text-danger">Revoke invitation</span>,
                  onClick: (e) => {
                    Modals.contacts.revokeInvitation({
                      userName: args.props.data.firstName.concat(
                        " ",
                        args.props.data.lastName
                      ),
                      email: args.props.data.email,
                    });
                  },
                };
              }
            },
            delete: function (args) {
              if (
                $c.userIsAdmin() &&
                args.props.data.status !== "registration"
              ) {
                return {
                  label: <span className="text-danger">Delete</span>,
                  onClick: (e) => {
                    Modals.contacts.deleteContact({
                      userName: args.props.data.firstName.concat(
                        " ",
                        args.props.data.lastName
                      ),
                      _id: args.props.data._id,
                    });
                  },
                };
              }
            },
          },
        },
      },
      inviteContactsManually: {
        label: "",
        type: "invitecontactsmanually",
        events: {},
        attributes: {},
      },
      files: {
        label: "Upload excel file",
        type: "upload",
        editable: editable,
        configuration: {
          url: function (args) {
            return "/auth/uploadInvitation/excel";
          },
          type: "usersimport",
          fileDataProperty: "csvFile",
          allowedExtensions: ["xls", "xlsx"],
          multipleFiles: false,
        },
        parentChange: (value) => {
          document
            .getElementsByClassName("scheduleEventNextBtn")[0]
            .classList.add("disabled");

          try {
            if (value.length > 0) {
              document
                .getElementsByClassName("scheduleEventNextBtn")[0]
                .classList.remove("disabled");
            }
          } catch (e) {}
        },
        caption: function (_this) {
          return (
            <div className="w-100 text-center p-4 ">
              <img src="/uni/svg/files/upload.svg" className="svg-sm mb-2" />
              <span className="d-block mb-1">
                <strong>
                  <span
                    className="color-purple"
                    role="button"
                    onClick={_this.onTargetClick}
                  >
                    Click to upload
                  </span>{" "}
                  or drag and drop
                </strong>
              </span>
              <span className="color-grey d-block">XLSX, XLS (max 1GB)</span>
            </div>
          );
        },
        events: {},
        attributes: {
          className: "form-control bg-light-grey",
        },
      },
      email: {
        label: "Email",
        type: "text",
        editable: editable,
        events: {},
        attributes: {},
      },
      roles: {
        label: "Role",
        type: "text",
        editable: editable,
        events: {},
        attributes: {},
        formatValue: (value) => {
          if (value) {
            return value[0] ? (
              <span className="text-capitalize">{value[0].name}</span>
            ) : (
              "-"
            );
          }
          return "-";
        },
      },
      tag: {
        label: "Tag",
        type: "text",
        editable: editable,
        events: {},
        attributes: {},
        formatValue: (value, row) => {
          if (row.host) {
            return "Conference host";
          }
          if (row.instructor) {
            return "Instructor";
          }

          return "Not specified";
        },
      },
      status: {
        label: "Status",
        type: "text",
        editable: editable,
        events: {},
        attributes: {},
      },
    };
  },
  specialProperties: () => {
    return {
      header: {
        type: "template",
        configuration: function (args) {
          if ($f.getQueryPath(2) === "space") {
            if (
              !args.props.data.data.spaces[0] &&
              !$f.getParameterByName("_id")
            ) {
              return {
                title: (resp) => {
                  try {
                    return resp.data.data.breadCrumb[0].name;
                  } catch (e) {}
                  return "Space Files";
                },
                subTitle: (resp) => {
                  switch ($f.getQueryPath(2)) {
                    case "space":
                      try {
                        return (
                          <>
                            In this page you'll find contacts associated to{" "}
                            {resp.data.data.breadCrumb[0].name}.
                          </>
                        );
                      } catch (e) {}
                      break;

                    default:
                      return $c.userIsAdmin()
                        ? "Invite and manage your contacts"
                        : "In this page you can view your contacts";
                  }
                },
                buttons: [],
                template: "Header",
              };
            }
          }

          return {
            title: (resp) => {
              switch ($f.getQueryPath(2)) {
                case "space":
                  try {
                    return resp.data.data.breadCrumb[0].name;
                  } catch (e) {}
                  break;

                default:
                  return "Contacts";
              }
            },
            subTitle: (resp) => {
              switch ($f.getQueryPath(2)) {
                case "space":
                  try {
                    return (
                      <>
                        In this page you'll find contacts associated to{" "}
                        {resp.data.data.breadCrumb[0].name}.
                      </>
                    );
                  } catch (e) {}
                  break;

                default:
                  return $c.userIsAdmin()
                    ? "Invite and manage your contacts"
                    : "In this page you can view your contacts";
              }
            },
            buttons: (args) => {
              if ($c.userIsMember()) {
                return;
              }

              switch ($f.getQueryPath(2)) {
                case "space":
                  return (
                    <button
                      type="button"
                      className="btn btn-purple  btn-sm  "
                      onClick={() => {
                        let spaceId = $f.getQueryPath(5)
                          ? $f.getQueryPath(5)
                          : args.data.data.spaces[0]._id;
                        $f.getValue(Modals.participants.addAll, {
                          title: "Add people",
                          api: "new",
                          actionType: "space",
                          actionId: spaceId,
                          actionSection: "participants",
                        });
                      }}
                    >
                      Add people
                    </button>
                  );
                  break;

                default:
                  return (
                    <>
                      <button
                        type="button"
                        className="btn btn-white btn-sm me-2"
                        onClick={() => {
                          $c.downloadFile(
                            "/users/download/excel/?",
                            "Contacts"
                          );
                        }}
                      >
                        Export
                      </button>
                      <button
                        type="button"
                        className="btn btn-purple  btn-sm  "
                        onClick={() => {
                          Modals.contacts.inviteContacts();
                        }}
                      >
                        Invite People
                      </button>
                    </>
                  );
                  break;
              }
            },
            template: "Header",
          };
        },
        events: {},
      },
      participants: {
        label: "",
        type: "module",
        configuration: (resp) => {
          if (!$f.getQueryPath(5)) {
            let groupId = "";

            try {
              groupId = resp.props.value?.data?.spaces[0]._id;
            } catch (e) {}

            if (!groupId) {
              return {
                module: "ParticipantTable",
                view: "listNoGroupSpace",
              };
            }

            return {
              module: "ParticipantTable",
              view: "list",
              replace: {
                id: () => {
                  return groupId;
                },
                start: 0,
                offset: 25,
              },
            };
          }

          return {
            module: "ParticipantTable",
            view: "list",
          };
        },
      },
      groups: {
        type: "template",
        configuration: {
          data: (resp) => {
            let data = [];
            try {
              let hasActive = false;
              resp.data.data.spaces.forEach((el) => {
                if ($f.getQueryPath(5) == el._id) {
                  hasActive = true;
                  data.unshift({
                    url:
                      "/contacts/space/" +
                      el.breadCrumb[0]._id +
                      "/group/" +
                      el._id,
                    title: el.name,
                    isActive: () => {
                      return true;
                    },
                  });
                } else {
                  data.push({
                    url:
                      "/contacts/space/" +
                      el.breadCrumb[0]._id +
                      "/group/" +
                      el._id,
                    title: el.name,
                    isActive: () => {
                      return false;
                    },
                  });
                }
              });

              if (!hasActive) {
                data[0].isActive = () => {
                  return true;
                };
              }
            } catch (e) {}
            return data;
          },
          template: "HorizontalNav",
        },
      },
      pagination: {
        type: "pagination",
        configuration: {
          start: function (props) {
            return 0;
          },
          offset: "25",
          total: function (props) {
            return props.props.data.pagination.total;
          },
          current: function (props) {
            return props.props.data.pagination.currentPage;
          },
          replaceAttribute: "start",
        },
        events: {},
      },
    };
  },
  views: {
    list: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/users?start={start}&offset={offset}";
          },
          dataPath: () => {},
          replace: {
            start: 0,
            offset: 25,
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Table";
        },
        configuration: {
          headerTemplate: function () {
            return this.state.specialProperties.header.component;
          },
          bottomTemplate: function () {
            return this.state.specialProperties.pagination.component;
          },
          emptyTemplate: (args) => {
            return $f.getValue(EmptyStates.contacts.list, args);
          },
          tableConfiguration: {
            headers: {
              fullName: {
                attributes: {
                  className: "w-50",
                },
              },
            },
            cellTemplates: {
              fullName: (value, row) => {
                return (
                  <span
                    className={
                      row.status.value == "deactivated" ? "opacity-50" : ""
                    }
                  >
                    {value}
                  </span>
                );
              },
              email: (value, row) => {
                return (
                  <span
                    className={
                      row.status.value == "deactivated" ? "opacity-50" : ""
                    }
                  >
                    {value}
                  </span>
                );
              },
              roles: (value, row) => {
                return (
                  <span
                    className={
                      row.status.value == "deactivated" ? "opacity-50" : ""
                    }
                  >
                    {value}
                  </span>
                );
              },
              tag: (value, row) => {
                return (
                  <span
                    className={
                      row.status.value == "deactivated" ? "opacity-50" : ""
                    }
                  >
                    {value}
                  </span>
                );
              },
              status: (value, row) => {
                let status = "Invited";

                switch (row.status.value) {
                  case "deactivated":
                    status = "Deactivated";
                    break;

                  case "active":
                    status = "Registered";
                    break;
                }

                return (
                  <span
                    className={
                      row.status.value == "deactivated" ? "opacity-50" : ""
                    }
                  >
                    {status}
                  </span>
                );
              },
            },
          },
          includeProperties: () => {
            if ($c.userIsMember()) {
              return ["fullName", "optionsfortable", "email"];
            } else {
              return [
                "fullName",
                "optionsfortable",
                "email",
                "roles",
                "tag",
                "status",
              ];
            }
          },
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
    detail: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/user/{id}";
          },
          dataPath: () => {},
          replace: {
            id: () => {
              return $f.getQueryPath(2);
            },
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          if ($f.getQueryPath(3) == "job-details") {
            return "JobDetails";
          }

          if ($f.getQueryPath(3) == "security") {
            return "Security";
          }

          if ($f.getQueryPath(3) == "activity") {
            return "Activity";
          }

          return "Profile";
        },
        configuration: {
          includeProperties: [],
          excludeProperties: [],
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
    spaceUsers: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/spaces/root/" + $f.getQueryPath(3) + "?treeview=false";
          },
          dataPath: () => {
            return "data.spaces";
          },
          replace: {
            start: 0,
            offset: 25,
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Custom";
        },
        configuration: {
          headerTemplate: function () {
            return (
              <>
                {this.state.specialProperties.header.component}
                {this.state.specialProperties.groups.component}
                {this.state.specialProperties.participants.component}
              </>
            );
          },

          includeProperties: () => {
            return ["_id"];
          },
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
    inviteManually: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: { data: [{}] },
          replace: {},
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Form";
        },
        configuration: {
          includeProperties: () => {
            return ["inviteContactsManually"];
          },
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
    inviteMultiple: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: { data: [{}] },
          replace: {},
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Form";
        },
        configuration: {
          includeProperties: () => {
            return ["files"];
          },
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: true };
        },
      },
    },
    inviteDirectly: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: { data: [{}] },
          replace: {},
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Form";
        },
        configuration: {
          includeProperties: () => {
            return ["files"];
          },
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: true };
        },
      },
    },
  },
};

export default Contacts;
