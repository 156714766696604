import parse from "html-react-parser";
import { Component } from "react";

class Reminder extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.parentChange = function () {};

    this.state = {
      loaded: false,
    };
  }

  onChange(e) {
    this.setState({
      value: [JSON.parse(e.target.value)],
    });

    setTimeout(this["customOnChange"], 0);
    const _this = this;
    setTimeout(function () {
      _this.parentChange([JSON.parse(e.target.value)]);
    }, 0);
  }

  componentDidMount() {
    let events = [];

    Object.keys(this.props.property.events)?.forEach((element, idx) => {
      if (!element.toLowerCase().startsWith("onchange")) {
        this[element] = this.props.property.events[element];
        this[element] = this[element].bind(this);
        events[element] = this[element];
      } else if (element == "overrideOnChange") {
        this.onChange = this.props.property.event[element];
        this.onChange = this[element].bind(this);
        events["onChange"] = this[element];
      } else {
        this["customOnChange"] = this.props.property.events[element];
        this["customOnChange"] = this["customOnChange"].bind(this);
      }
    });

    if (this.props.property.parentChange) {
      this.parentChange = this.props.property.parentChange.bind(this);
    }

    try {
      delete this.props.value[0]._id;
    } catch (e) {}

    this.setState({
      loaded: true,
      events: events,
      attributes: { ...this.props.property.attributes },
      value: this.props.value
        ? this.props.value
        : [{ type: "minutes", value: 5 }],
      options: [
        {
          label: "5 minutes before",
          value: JSON.stringify({ type: "minutes", value: 5 }),
        },
        {
          label: "10 minutes before",
          value: JSON.stringify({ type: "minutes", value: 10 }),
        },
        {
          label: "30 minutes before",
          value: JSON.stringify({ type: "minutes", value: 30 }),
        },
        {
          label: "1 hour before",
          value: JSON.stringify({ type: "minutes", value: 60 }),
        },
        {
          label: "1 day before",
          value: JSON.stringify({ type: "days", value: 1 }),
        },
      ],
      editable: this.props.property.editable === false ? false : true,
      useCustomUI: this.props.property.useCustomUI,
    });

    if (events["didMount"]) {
      setTimeout(events["didMount"], 0);
    }
  }

  editable() {
    return (
      <select
        {...this.state.events}
        {...this.state.attributes}
        defaultValue={
          this.state.value[0]
            ? JSON.stringify(this.state.value[0])
            : this.state.value
        }
        onChange={this.onChange}
      >
        {this.state.options.map((el) => {
          return <option value={el.value}>{el.label}</option>;
        })}
      </select>
    );
  }

  nonEditable() {
    return typeof this.state.value === "string"
      ? parse(this.state.value)
      : this.state.value;
  }

  customUI() {}

  render() {
    if (this.state.loaded) {
      if (this.state.useCustomUI) {
        return this.customUI();
      } else if (this.state.editable) {
        return this.editable();
      } else {
        return this.nonEditable();
      }
    }

    return "";
  }
}

export default Reminder;
