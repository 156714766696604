import parse from "html-react-parser";
import { Component } from "react";
import SimpleReactValidator from "simple-react-validator";

class PassingGrade extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.validator = new SimpleReactValidator();

    this.state = {
      loaded: false,
    };
  }

  onChange(e) {
    if (e.target.value <= 100 && e.target.value >= 0) {
      this.setState({
        value: e.target.value != 0 ? e.target.value.replace(/^0+/, "") : 0,
      });
    }

    setTimeout(this["customOnChange"], 0);
  }

  validate() {
    const isValid = this.validator.allValid();

    this.setState({ isValid });
    this.validator.showMessages();
    this.forceUpdate();

    return isValid;
  }

  componentDidMount() {
    let events = [];

    Object.keys(this.props.property.events)?.forEach((element, idx) => {
      if (!element.toLowerCase().startsWith("onchange")) {
        this[element] = this.props.property.events[element];
        this[element] = this[element].bind(this);
        events[element] = this[element];
      } else {
        this["customOnChange"] = this.props.property.events[element];
        this["customOnChange"] = this["customOnChange"].bind(this);
      }
    });

    this.setState({
      loaded: true,
      events: events,
      attributes: { ...this.props.property.attributes },
      value: this.props.value ? this.props.value : 0,
      editable: this.props.property.editable === false ? false : true,
      useCustomUI: this.props.property.useCustomUI,
    });
  }

  editable() {
    return (
      <>
        <div className="d-flex">
          <input
            type="number"
            {...this.state.events}
            {...this.state.attributes}
            value={this.state.value}
            onChange={this.onChange}
            style={{ width: "6rem", textAlign: "right" }}
            placeholder="0"
            min="0"
            max="100"
          ></input>
          <div className="ms-2 mt-1">
            {this.state.value > 0 ? "Graded test" : "Practice test"}
          </div>
        </div>
        <span className="text-danger mt-2 d-block">
          {this.validator.message(
            this.props.property.label,
            this.state.value,
            this.props.property.validation ? this.props.property.validation : ""
          )}
        </span>
      </>
    );
  }

  nonEditable() {
    return typeof this.state.value === "string"
      ? parse(this.state.value)
      : this.state.value;
  }

  customUI() {}

  render() {
    if (this.state.loaded) {
      if (this.state.useCustomUI) {
        return this.customUI();
      } else if (this.state.editable) {
        return this.editable();
      } else {
        return this.nonEditable();
      }
    }

    return "";
  }
}

export default PassingGrade;
