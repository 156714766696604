import React, { Component } from "react";
import {
  Calendar as CalendarWidget,
  momentLocalizer,
} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./calendar.css";
import moment from "moment";
import Modals from "../../../../modules/modals";
import $f from "../../../../src";
import $c from "../../../../modules/functions";

moment.locale("en", {
  week: {
    dow: 1,
    doy: 1,
  },
});
const localizer = momentLocalizer(moment);

class Calendar extends Component {
  constructor(props) {
    super(props);

    this.createEventStyling = this.createEventStyling.bind(this);
    this.onSelectEvent = this.onSelectEvent.bind(this);
    this.onNavigate = this.onNavigate.bind(this);
    this.nextMonth = this.nextMonth.bind(this);
    this.previousMonth = this.previousMonth.bind(this);
    this.filtersMenu = this.filtersMenu.bind(this);
    this.initFiltersMenu = this.initFiltersMenu.bind(this);
    this.createCalendarViewDropDown =
      this.createCalendarViewDropDown.bind(this);

    this.state = {
      data: [],
      loaded: false,
    };
  }

  componentDidMount() {
    const { data, module, view, specialProperties } = this.props;

    const events = specialProperties.events.data.data;
    const eventColors = specialProperties.events.data.colours;

    let templateConfiguration = view.template.configuration;

    const innerTemplates = ["header"];

    innerTemplates.map((itm, idx) => {
      let tmp = templateConfiguration[itm + "Template"]
        ? templateConfiguration[itm + "Template"]
        : () => {};
      this[itm + "Template"] = tmp.bind(this);
    });

    this.setState({
      specialProperties,
      data,
      loaded: true,
      view,
      module,
      eventColors,
      events,
      date: new Date(),
      currentFilter: "allEvents",
      calendarView: "month",
      filters: {
        allEvents: { title: "All events", active: true },
        meetings: { title: "Meetings", active: false },
        trainings: { title: "Trainings", active: false },
        tests: { title: "Tests", active: false },
        conferences: { title: "Conferences", active: false },
      },
    });
  }

  createEventStyling(event) {
    const eventColors = this.state.eventColors;
    let type = event.type;

    const style = {
      backgroundColor: "#4e2c95",
      borderRadius: "0px",
      color: "#fff",
      border: "0px",
      display: "block",
      opacity: 0.7,
    };

    if (event.training) {
      style.backgroundColor = "#" + eventColors.templates[type];
    } else {
      style.backgroundColor = "#" + eventColors.events[type];
    }

    return {
      style: style,
    };
  }

  onSelectEvent(event) {
    Modals.events.calendarEvent(event);
  }

  onNavigate(e, type) {
    this.initFiltersMenu(type);
    this.setState({ date: e, events: [], currentFilter: type });

    const _this = this;

    $f.fetch(
      $f.getValue(this.props.view.apis.view.url),
      "GET",
      null,
      {},
      {
        start: 0,
        offset: 1000,
        url: function () {
          let url = "all";

          return "/event/all";
        },
        filters: function () {
          let filters = { value: "exams,trainings,meetings,streams" };

          let urlValue = $f.getQueryPath("3")
            ? $f.getQueryPath("3")
            : type
            ? type
            : null;

          switch (urlValue) {
            case "tests":
              filters = { value: "exams" };
              break;
            case "trainings":
              filters = { value: "trainings" };
              break;
            case "meetings":
              filters = { value: "meetings" };
              break;
            case "conferences":
              filters = { value: "streams" };
              break;
          }

          let filter = [
            filters,
            {
              key: "from",
              operator: "gt",
              value: moment(e)
                .startOf("month")
                .subtract(7, "days")
                .toISOString(),
            },
            {
              key: "to",
              operator: "lt",
              value: moment(e).endOf("month").add(7, "days").toISOString(),
            },
          ];

          return JSON.stringify(filter);
        },
      },
      (resp) => {
        if (resp.data) {
          if (resp.data.data) {
            _this.setState({ events: resp.data.data });
          }
        }
      }
    );
  }

  formatEvents(events) {
    const formattedEvents = [];
    events.forEach((itm) => {
      let titleFormatted = itm.title;
      switch (itm.type) {
        case "exam":
          titleFormatted = (
            <span>
              <i className="fas fa-pencil-alt me-2"></i>
              {itm.title}
            </span>
          );
          break;

        case "conference":
          titleFormatted = (
            <span>
              <i className="fas fa-video me-2"></i>
              {itm.title}
            </span>
          );
          break;

        case "meeting":
          titleFormatted = (
            <span>
              <i className="fas fa-video me-2"></i>
              {itm.title}
            </span>
          );
          break;

        case "training":
          titleFormatted = (
            <span>
              <i className="fas fa-tasks me-2"></i>
              {itm.title}
            </span>
          );
          break;
      }

      itm.titleFormatted = titleFormatted;
      itm.allDay = false;
      itm.start = moment(itm.from).toDate();
      itm.end = moment(itm.to).toDate();
      formattedEvents.push(itm);
    });

    return formattedEvents;
  }

  nextMonth() {
    let period = "month";

    switch (this.state.calendarView) {
      case "work_week":
        period = "week";
        break;

      case "day":
        period = "day";
        break;
    }

    this.setState({ date: moment(this.state.date).add(1, period).toDate() });

    this.onNavigate(
      moment(this.state.date).add(1, period).toDate(),
      this.state.currentFilter
    );
  }

  previousMonth() {
    let period = "month";

    switch (this.state.calendarView) {
      case "work_week":
        period = "week";
        break;

      case "day":
        period = "day";
        break;
    }

    this.setState({
      date: moment(this.state.date).subtract(1, period).toDate(),
    });

    this.onNavigate(
      moment(this.state.date).subtract(1, period).toDate(),
      this.state.currentFilter
    );
  }

  initFiltersMenu(type) {
    let filters = {
      allEvents: { title: "All events", active: false },
      meetings: { title: "Meetings", active: false },
      trainings: { title: "Trainings", active: false },
      tests: { title: "Tests", active: false },
      conferences: { title: "Conferences", active: false },
    };

    filters[type]["active"] = true;

    this.setState({ filters: filters });
  }

  createCalendarViewDropDown() {
    let viewLabel = "Monthly view";
    const _this = this;

    let otherOptions = [
      <li>
        <span
          className="dropdown-item"
          role="button"
          onClick={() => {
            _this.setState({ calendarView: "work_week" });
          }}
        >
          Weekly view
        </span>
      </li>,
      <li>
        <span
          className="dropdown-item"
          role="button"
          onClick={() => {
            _this.setState({ calendarView: "day" });
          }}
        >
          Daily view
        </span>
      </li>,
    ];

    switch (this.state.calendarView) {
      case "day":
        viewLabel = "Daily view";
        otherOptions = [
          <li>
            <span
              className="dropdown-item"
              role="button"
              onClick={() => {
                _this.setState({ calendarView: "month" });
              }}
            >
              Monthly view
            </span>
          </li>,
          <li>
            <span
              className="dropdown-item"
              role="button"
              onClick={() => {
                _this.setState({ calendarView: "work_week" });
              }}
            >
              Weekly view
            </span>
          </li>,
        ];
        break;

      case "work_week":
        viewLabel = "Weekly view";
        otherOptions = [
          <li>
            <span
              className="dropdown-item"
              role="button"
              onClick={() => {
                _this.setState({ calendarView: "month" });
              }}
            >
              Monthly view
            </span>
          </li>,
          <li>
            <span
              className="dropdown-item"
              role="button"
              onClick={() => {
                _this.setState({ calendarView: "day" });
              }}
            >
              Daily view
            </span>
          </li>,
        ];
        break;
    }

    return (
      <div className="dropdown d-inline-block">
        <button
          className="btn btn-sm btn-white me-2 dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {viewLabel}
        </button>
        <ul className="dropdown-menu">{otherOptions}</ul>
      </div>
    );
  }

  filtersMenu() {
    const _this = this;

    return (
      <>
        <ul className="nav nav-tabs navbar-expand">
          {Object.keys(this.state.filters).map((itm) => {
            let active = this.state.filters[itm].active ? "active" : "";

            return (
              <li className="nav-item " key={$f.key()}>
                <span
                  className={"nav-link color-strong-grey nav-ellipsis ".concat(
                    active
                  )}
                  aria-current={"/events/calendar"}
                  onClick={() => {
                    _this.onNavigate(_this.state.date, itm);
                  }}
                  role="button"
                >
                  {this.state.filters[itm].title}
                </span>
              </li>
            );
          })}
          <li className="nav-item ms-auto display-lt-md-none">
            <button
              type="button"
              onClick={() => {
                _this.setState({ date: new Date() });
              }}
              className="btn btn-sm btn-white me-2"
            >
              Today
            </button>

            {this.createCalendarViewDropDown()}
            {!$c.userIsMember() ? (
              <button
                type="button"
                onClick={() => {
                  Modals.events.scheduleEvent();
                }}
                className="btn btn-sm btn-purple"
              >
                Schedule
              </button>
            ) : (
              ""
            )}
          </li>
        </ul>
        <div className="display-lt-md-block mb-2 mt-2">
          <button
            type="button"
            onClick={() => {
              _this.setState({ date: new Date() });
            }}
            className="btn btn-sm btn-white me-2"
          >
            Today
          </button>

          {this.createCalendarViewDropDown()}
          <button
            type="button"
            onClick={() => {
              Modals.events.scheduleEvent();
            }}
            className="btn btn-sm btn-purple"
          >
            Schedule
          </button>
        </div>
      </>
    );
  }

  render() {
    if (this.state.loaded) {
      const _this = this;

      let calendarDate = moment(this.state.date).format("MMMM YYYY");

      switch (this.state.calendarView) {
        case "work_week":
          break;

        case "day":
          calendarDate = moment(this.state.date).format("ddd DD MMMM YYYY");
          break;
      }

      return (
        <React.Fragment>
          {this.headerTemplate()}
          {this.filtersMenu()}
          <div className="scroll-x vh-100 mb-5">
            <div className="border-bottom-1 border-top-1 pt-1 pb-1">
              <h2 className="display-2 mb-0313rem mt-0313rem ">
                {calendarDate}

                <i
                  className="fas fa-chevron-left ms-3 text-normal"
                  role="button"
                  onClick={this.previousMonth}
                ></i>
                <i
                  className="fas fa-chevron-right ms-3 text-normal"
                  role="button"
                  onClick={this.nextMonth}
                ></i>
              </h2>
            </div>
            <CalendarWidget
              titleAccessor="titleFormatted"
              localizer={localizer}
              onSelectEvent={this.onSelectEvent}
              onNavigate={(e) => {
                _this.onNavigate(e, this.state.currentFilter);
              }}
              popup={true}
              view={this.state.calendarView}
              eventPropGetter={function (event, start, end, isSelected) {
                return _this.createEventStyling(event);
              }}
              onView={() => {}}
              defaultView={this.state.calendarView}
              views={["day", "work_week", "month", "agenda"]}
              date={this.state.date}
              events={
                this.state.events ? _this.formatEvents(this.state.events) : []
              }
              messages={{
                showMore: (target) => (
                  <span
                    className="ml-2"
                    role="presentation"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        calendarOverlay: true,
                        currentTitleData: {},
                      });
                    }}
                  >
                    {" "}
                    ...{target}
                  </span>
                ),
              }}
            />{" "}
          </div>
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default Calendar;
