import React, { Component } from "react";
import parse from "html-react-parser";
import $c from "../../../../../modules/functions";
import $f from "../../../../../src";
import { Link, withRouter } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";

class Terms extends Component {
  constructor(props) {
    super(props);

    this.fetchData = this.fetchData.bind(this);

    this.state = {
      data: [],
      loaded: false,
      content: "",
    };
  }

  componentDidMount() {
    this.setState({
      loaded: false,
    });

    this.fetchData();
  }

  fetchData() {
    $f.fetch(
      "/getCompanySettings",
      "GET",
      null,
      { Authorization: "Bearer public" },
      {},
      (resp) => {
        let data = resp.data.data[0];

        this.setState({
          loaded: true,
          content: $c.parse(data.privacyPolicy),
        });
      }
    );
  }

  render() {
    if (this.state.loaded) {
      return <div>{this.state.content}</div>;
    } else {
      return "loading...";
    }
  }
}

export default withRouter(Terms);
