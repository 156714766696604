import React, { useEffect, useState } from "react";

import RemoteVideoTiles from "./remoteVideoTiles";
import ModeratorsVideoTiles from "./moderatorsVideoTiles";
import MeetingMenu from "./meetingMenu";
import $f from "../../../../src";

import Chat from "./chat";
import MeetingFunctions from "./functions";

import {
  useMeetingManager,
  useLocalVideo,
  useToggleLocalMute,
  useContentShareControls,
  useContentShareState,
  useAudioVideo,
  useRosterState,
} from "amazon-chime-sdk-component-library-react";
import Welcome from "./welcome";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";

const Meeting = (props) => {
  const [topMenu, setTopMenu] = useState(isMobile ? "home" : "participants");
  const [title, setTitle] = useState("");

  const [deviceSetupReady, setDeviceSetupReady] = useState(false);
  const meetingManager = useMeetingManager();

  const { isVideoEnabled, setIsVideoEnabled, toggleVideo } = useLocalVideo();

  const { muted, toggleMute } = useToggleLocalMute();
  const { toggleContentShare } = useContentShareControls();
  const { isLocalUserSharing } = useContentShareState();
  const { roster } = useRosterState();
  const audioVideo = useAudioVideo();

  useEffect(() => {
    let meetingTitle = props.meeting.title;

    if (props.meeting.sessionTitle) {
      meetingTitle = props.meeting.sessionTitle.concat(
        " / ",
        props.meeting.title
      );
    }

    setTitle(meetingTitle);
  }, []);

  document.body.style.paddingTop = "6.7rem";

  window.onbeforeunload = function () {
    meetingManager.leave();
  };

  meetingManager?.audioVideo?.realtimeSubscribeToReceiveDataMessage(
    "MUTE",
    (msg) => {
      MeetingFunctions.receiveActionFromMessage("MUTE", meetingManager, msg);
    }
  );

  meetingManager?.audioVideo?.realtimeSubscribeToReceiveDataMessage(
    "BANUNMUTE",
    (msg) => {
      MeetingFunctions.receiveActionFromMessage(
        "BANUNMUTE",
        meetingManager,
        msg
      );
    }
  );

  meetingManager?.audioVideo?.realtimeSubscribeToReceiveDataMessage(
    "UNMUTE",
    (msg) => {
      MeetingFunctions.receiveActionFromMessage("UNMUTE", meetingManager, msg);
    }
  );

  meetingManager?.audioVideo?.realtimeSubscribeToReceiveDataMessage(
    "VIDEO_DEACTIVATE",
    (msg) => {
      MeetingFunctions.receiveActionFromMessage(
        "VIDEO_DEACTIVATE",
        meetingManager,
        msg,
        setIsVideoEnabled
      );
    }
  );

  meetingManager?.audioVideo?.realtimeSubscribeToReceiveDataMessage(
    "VIDEO_ACTIVATE",
    (msg) => {
      MeetingFunctions.receiveActionFromMessage(
        "VIDEO_ACTIVATE",
        meetingManager,
        msg
      );
    }
  );

  meetingManager?.audioVideo?.realtimeSubscribeToReceiveDataMessage(
    "KICK",
    (msg) => {
      MeetingFunctions.receiveActionFromMessage("KICK", meetingManager, msg);
    }
  );

  meetingManager?.audioVideo?.realtimeSubscribeToReceiveDataMessage(
    "ENDCALL",
    (msg) => {
      MeetingFunctions.receiveActionFromMessage("ENDCALL", meetingManager, msg);
    }
  );

  //scroll-x
  const MyMeetingView = () => {
    console.log("pasok1", props.meeting);
    return (
      <>
        <header className="fixed-top">
          <nav className="navbar navbar-expand-md bg-light-grey top-navbar">
            <div className="container-fluid viewport-padding  color-strong-grey">
              <span className="display-5 mb-0">
                {window["COMPANY_LOGO_ONLY"]()}
                <span className="text-ellipsis mt-auto mb-auto" title={title}>
                  {title}
                </span>
              </span>
            </div>
          </nav>
          <nav
            className="navbar navbar-expand top-subnavbar border-bottom-1 bg-white "
            draggable="false"
          >
            {MeetingFunctions.recording(roster) ? (
              <div className="w-100 position-absolute display-lt-md-none text-end viewport-padding ">
                <div>
                  <img
                    src="/uni/svg/rec.svg"
                    width={20}
                    style={{ marginTop: "-4px" }}
                  ></img>
                  <span className="d-inline-block ms-2">Recording now</span>
                </div>
              </div>
            ) : (
              ""
            )}
            <div
              className=" viewport-padding scroll-md-x"
              draggable="false"
              style={{ zIndex: 1005 }}
            >
              <ul className="navbar-nav " draggable="false">
                <li className="nav-item" key={$f.key()}>
                  <Link
                    className="nav-link"
                    role="button"
                    to={
                      $f.getQueryPath(1) === "meeting"
                        ? "/event/meeting/".concat(
                            $f.getQueryPath(2).replace("m-", "")
                          )
                        : "/event/training/".concat(props.meeting.trainingId)
                    }
                    target="_blank"
                  >
                    <span className=" color-grey mb-3" role="button">
                      <i className="fas fa-chevron-left me-2   color-grey "></i>
                    </span>
                    Overview
                  </Link>
                </li>
                <li className="nav-item display-lt-md-block" key={$f.key()}>
                  <span
                    className={
                      topMenu == "home" ? "nav-link active" : "nav-link"
                    }
                    onClick={() => {
                      document
                        .getElementById("page-content-wrapper-meeting-id")
                        .classList.add("meeting-zindex-1000");
                      setTopMenu("home");
                    }}
                    role="button"
                  >
                    <i className="fas fa-video me-2"></i>
                    Live now
                  </span>
                </li>
                <li className="nav-item" key={$f.key()}>
                  <span
                    className={
                      topMenu == "participants" ? "nav-link active" : "nav-link"
                    }
                    onClick={() => {
                      document
                        .getElementById("page-content-wrapper-meeting-id")
                        .classList.remove("meeting-zindex-1000");
                      setTopMenu("participants");
                    }}
                    role="button"
                  >
                    <i className="fas fa-user-friends me-2"></i>
                    People
                  </span>
                </li>
                <li className="nav-item" key={$f.key()}>
                  <span
                    className={
                      topMenu == "chat" ? "nav-link active" : "nav-link"
                    }
                    onClick={() => {
                      document
                        .getElementById("page-content-wrapper-meeting-id")
                        .classList.remove("meeting-zindex-1000");
                      setTopMenu("chat");
                    }}
                    role="button"
                    id="meeting-chat-menu-item"
                  >
                    <i className="fas fa-comment-dots me-2"></i>
                    Chat
                  </span>
                </li>
                <li className="nav-item" key={$f.key()}>
                  <span
                    className={
                      topMenu == "breakout-room"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    onClick={() => {
                      document
                        .getElementById("page-content-wrapper-meeting-id")
                        .classList.remove("meeting-zindex-1000");
                      setTopMenu("breakout-room");
                    }}
                    role="button"
                  >
                    <i className="fas fa-th-large me-2"></i>
                    Breakout rooms
                  </span>
                </li>
              </ul>
            </div>
            <div className="w-100 position-absolute display-lt-md-none">
              <div className="text-center">
                {MeetingFunctions.createControlsPanel(
                  meetingManager,
                  isVideoEnabled,
                  toggleVideo,
                  muted,
                  toggleMute,
                  isLocalUserSharing,
                  toggleContentShare,
                  audioVideo,
                  props.meeting
                )}
              </div>
            </div>
          </nav>
        </header>
        <main className="container-fluid viewport-meeting-padding  pt-md-2125rem h-100">
          <div className="wrapper d-flex h-100">
            <div
              className="left-menu-meeting h-100"
              id="left-menu-meeting-panel-id"
            >
              <MeetingMenu menu={topMenu} meeting={props.meeting} />
              <Chat
                show={topMenu == "chat" ? true : false}
                meeting={props.meeting}
              ></Chat>
            </div>
            <div
              className="page-content-wrapper-meeting h-100 meeting-zindex-1000"
              id="page-content-wrapper-meeting-id"
            >
              {/* <div className="text-center">
                {MeetingFunctions.createControlsPanel(
                  meetingManager,
                  isVideoEnabled,
                  toggleVideo,
                  muted,
                  toggleMute,
                  isLocalUserSharing,
                  toggleContentShare
                )}
              </div> */}
              <div className="w-100 display-lt-md-block ">
                <div className="text-center">
                  {MeetingFunctions.createControlsPanel(
                    meetingManager,
                    isVideoEnabled,
                    toggleVideo,
                    muted,
                    toggleMute,
                    isLocalUserSharing,
                    toggleContentShare,
                    audioVideo,
                    props.meeting
                  )}
                </div>
              </div>
              <ModeratorsVideoTiles
                key={$f.key()}
                meeting={props.meeting}
              ></ModeratorsVideoTiles>
              {!$f.getParameterByName("breakout") &&
              !isMobile &&
              !MeetingFunctions.isMeeting() ? (
                <RemoteVideoTiles
                  key={$f.key()}
                  meeting={props.meeting}
                ></RemoteVideoTiles>
              ) : null}
            </div>
          </div>
        </main>
      </>
    );
  };

  return deviceSetupReady ? (
    MyMeetingView(props)
  ) : (
    <Welcome deviceSetup={setDeviceSetupReady} meeting={props.meeting} />
  );
};

export default Meeting;
