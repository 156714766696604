import parse from "html-react-parser";
import { Component } from "react";
import Moment, { isDate } from "moment";
import { DateTimePicker, Localization } from "react-widgets";
import { DateLocalizer, NumberLocalizer } from "react-widgets/IntlLocalizer";
import "react-widgets/styles.css";
import Data from "../../modules/data/data";
import moment from "moment";
import SimpleReactValidator from "simple-react-validator";

class FromToDate extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.changeFromDate = this.changeFromDate.bind(this);
    this.changeToDate = this.changeToDate.bind(this);

    this.validator = new SimpleReactValidator({
      validators: {
        compareDates: {
          message: "Starting date should not be after end date",
          rule: (val, params, validator) => {
            return moment(val.from).isBefore(val.to);
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)),
        },
      },
    });

    this.state = {
      loaded: false,
      value: {},
    };
  }

  onChange(e) {}

  validate() {
    const isValid = this.validator.allValid();

    this.setState({ isValid });
    this.validator.showMessages();
    this.forceUpdate();

    return isValid;
  }

  componentDidMount() {
    let events = [];

    Object.keys(this.props.property.events)?.forEach((element, idx) => {
      if (!element.toLowerCase().startsWith("onchange")) {
        this[element] = this.props.property.events[element];
        this[element] = this[element].bind(this);
        events[element] = this[element];
        if (element == "overrideOnChange") {
          this.onChange = this.props.property.events[element];
          this.onChange = this.onChange.bind(this);
          events["onChange"] = this.onChange;
        }
      } else {
        this["customOnChange"] = this.props.property.events[element];
        this["customOnChange"] = this["customOnChange"].bind(this);
      }
    });

    this.setState({
      loaded: true,
      events: events,
      attributes: { ...this.props.property.attributes },
      value:
        this.props.value.from && this.props.value.to
          ? {
              from: this.props.value.from,
              to: this.props.value.to,
            }
          : {
              from: moment(),

              to: moment().add(1, "days"),
            },
      editable: this.props.property.editable === false ? false : true,
      useCustomUI: this.props.property.useCustomUI,
    });
  }

  changeFromDate(e) {
    const date = moment(this.state.value.from)
      .set("date", moment(e).date())
      .set("year", moment(e).year())
      .set("month", moment(e).month());

    this.setState({
      value: { from: moment.utc(date).format(), to: this.state.value.to },
    });

    const _this = this;
    setTimeout(function () {
      _this.onChange(_this);
    }, 0);
  }

  changeToDate(e) {
    const date = moment(this.state.value.from)
      .set("date", moment(e).date())
      .set("year", moment(e).year())
      .set("month", moment(e).month());

    this.setState({
      value: { from: this.state.value.from, to: moment.utc(date).format() },
    });

    const _this = this;
    setTimeout(function () {
      _this.onChange(_this);
    }, 0);
  }

  editable() {
    return (
      <>
        <div className="d-flex w-50">
          <Localization
            date={new DateLocalizer({ culture: "en-GB", firstOfWeek: 0 })}
          >
            <DateTimePicker
              step={this.state.step}
              className={"w-100 me-2   rounded"}
              value={moment(this.state.value.from).toDate()}
              onChange={this.changeFromDate.bind(this)}
            />
          </Localization>
          <span className="pe-2 ps-2 pt-1">to</span>
          <Localization
            date={new DateLocalizer({ culture: "en-GB", firstOfWeek: 0 })}
          >
            <DateTimePicker
              step={this.state.step}
              className={"w-100 me-2  rounded"}
              value={moment(this.state.value.to).toDate()}
              onChange={this.changeToDate.bind(this)}
            />
          </Localization>
        </div>
        <span className="text-danger mt-2 d-block">
          {this.validator.message(
            this.props.property.label,
            this.state.value,
            "compareDates"
          )}
        </span>
      </>
    );
  }

  nonEditable() {
    return typeof this.state.value === "string"
      ? parse(this.state.value)
      : this.state.value;
  }

  customUI() {}

  render() {
    if (this.state.loaded) {
      if (this.state.useCustomUI) {
        return this.customUI();
      } else if (this.state.editable) {
        return this.editable();
      } else {
        return this.nonEditable();
      }
    }

    return "";
  }
}

export default FromToDate;
