import $f from "../../src";
import OptionsForHeader from "../../types/optionsForHeader/optionsForHeader";
import $c from "../functions";
import Modals from "../modals";

const OptionsForHeaderButton = function (data) {
  let property = {
    configuration: {
      caption: "Manage space",
      btnClassname: $c.userIsMember()
        ? "btn btn-white btn-sm ms-2"
        : "btn btn-purple btn-sm ms-2",
      options: {
        edit: function (args) {
          if ($c.userIsAdmin()) {
            let newargs = {
              props: { data: { ...args.props.data } },
              action: "update",
              view: "spacedetail",
            };

            return {
              label: "Edit",
              onClick: function () {
                $f.getValue(Modals.spaces.editSpace, newargs);
              },
            };
          }
        },
        copyLink: function (args) {
          return {
            label: "Copy link",
            onClick: function () {
              $c.copyToClipboard(window["host"](window.location.pathname));
              $f.createNotification({
                type: "success",
                message: "Link copied!",
              });
            },
          };
        },
        addChild: function (args) {
          if ($c.userIsAdmin()) {
            return {
              label: "Add group",
              onClick: function () {
                if ($c.userIsAdmin()) {
                  $f.getValue(Modals.spaces.addSpace, {
                    title: "Add group",
                    inputData: [
                      {
                        parent: args.props.data._id,
                        rootSpace: args.props.data._id,
                      },
                    ],
                  });
                }
              },
            };
          }
        },
        delete: function (args) {
          if ($c.userIsAdmin()) {
            args.title = "Delete space?";
            args.type = "space";
            args.callback = function (resp) {
              window.location.replace("/spaces");
            };
            return {
              label: <span className="text-danger">Delete</span>,
              onClick: function () {
                $f.getValue(Modals.spaces.deleteSpace, args);
              },
            };
          }
        },
      },
    },
  };

  return <OptionsForHeader data={data} property={property} />;
};

const Spaces = {
  properties: (args) => {
    const { editable } = args;

    return {
      _id: {
        label: "",
        path: "_id",
        labelPosition: "none",
        type: "hidden",
        events: {},
        attributes: {},
      },
      name: {
        label: "Title",
        type: "text",
        editable: editable,
        events: {},
        validation: "required",
        attributes: {
          className: "form-control",
          placeholder: "Type name",
        },
      },
      description: {
        label: "Description",
        type: "textarea",
        editable: editable,
        validation: "",
        events: {},
        attributes: {
          className: "form-control",
          placeholder: "Type description",
        },
      },
      image: {
        label: "Image",
        type: "upload",
        editable: true,
        configuration: {
          url: "/agenda-file",
          type: "meeting",
          fileDataProperty: "file",
          allowedExtensions: ["png", "jpeg", "jpg"],
          multipleFiles: false,
        },
        formatValue: (value) => {
          return value
            ? [
                {
                  mimetype: "image/jpeg",
                  _id: value,
                  title: "Image",
                },
              ]
            : [];
        },
        caption: function (_this) {
          return (
            <div className="w-100 text-center p-4 ">
              <img src="/uni/svg/files/upload.svg" className="svg-sm mb-2" />
              <span className="d-block mb-1">
                <strong>
                  <span
                    className="color-purple"
                    role="button"
                    onClick={_this.onTargetClick}
                  >
                    Click to upload
                  </span>{" "}
                  or drag and drop
                </strong>
              </span>
              <span className="color-grey d-block">
                PNG, JPEG, JPG (max 1GB)
              </span>
            </div>
          );
        },
        events: {},
        attributes: {
          className: "form-control bg-light-grey",
        },
      },
      advanceFields: {
        label: "",
        type: "spaceadvancefields",
        editable: editable,
        events: {},
        attributes: {},
        formatValue: (value, row) => {
          return {
            websiteUrl: row.websiteUrl ? row.websiteUrl : "",
            industry: row.industry ? row.industry : "",
            market: row.market ? row.market : "",
            moneyRaised: {
              currency: row.moneyRaised?.currency
                ? row.moneyRaised.currency
                : "€",
              amount: row.moneyRaised ? row.moneyRaised.amount : "",
            },
          };
        },
      },
      parent: {
        label: "",
        type: "hidden",
        editable: true,
        labelPosition: "none",
        events: {},
        attributes: {},
      },
      rootSpace: {
        label: "",
        type: "hidden",
        editable: true,
        labelPosition: "none",
        events: {},
        attributes: {},
      },
    };
  },
  specialProperties: () => {
    return {
      header: {
        type: "template",
        configuration: {
          title: "All spaces",
          subTitle: "View all available spaces",
          template: "Header",
          buttons: (args) => {
            if ($c.userIsMember()) {
              return [];
            }

            return [
              <button
                type="button"
                className="btn btn-purple  btn-sm  "
                onClick={() => {
                  $f.getValue(Modals.spaces.addSpace, {
                    title: "Create space",
                    view: "spacedetail",
                    api: "new",
                  });
                }}
              >
                Create Space
              </button>,
            ];
          },
        },
        events: {},
      },
      headerOverview: {
        type: "template",
        configuration: {
          title: (args) => {
            let data = args.data.data[0];

            return $c.createAvatar(data.image, data.name, "", "avatar-medium");
          },
          subTitle: (args) => {
            return args.data.data[0].description;
          },
          template: "Header",
          buttons: (args) => {
            return [$f.getValue(OptionsForHeaderButton, args.data.data[0])];
          },
        },
        events: {},
      },
    };
  },
  views: {
    list: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: "/root-spaces",
          dataPath: () => {},
          replace: {},
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Spaces";
        },
        configuration: {
          headerTemplate: function () {
            return this.state.specialProperties.header.component;
          },

          includeProperties: () => {},
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
    detail: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: "/space/{id}",
          dataPath: () => {},
          replace: {
            id: () => {
              return $f.getQueryPath(2);
            },
          },
          headers: {},
          mapper: () => {},
        },
        new: {
          url: () => {
            return { data: [{}] };
          },
          dataPath: () => {},
          replace: {},
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Form";
        },
        configuration: {
          includeProperties: () => {},
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: true };
        },
      },
    },
    spacedetail: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: "/space/{id}",
          dataPath: () => {},
          replace: {
            id: () => {
              return $f.getQueryPath(2);
            },
          },
          headers: {},
          mapper: () => {},
        },
        new: {
          url: () => {
            return { data: [{}] };
          },
          dataPath: () => {},
          replace: {},
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Form";
        },
        configuration: {
          includeProperties: () => {},
          excludeProperties: () => {
            return ["advanceFields"];
          },
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: true };
        },
      },
    },
    overview: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: "/space/{id}",
          dataPath: () => {},
          replace: {
            id: () => {
              return $f.getQueryPath(2);
            },
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "SpaceOverview";
        },
        configuration: {
          headerTemplate: function () {
            return this.state.specialProperties.headerOverview.component;
          },
          includeProperties: () => {},
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: true };
        },
      },
    },
  },
};

export default Spaces;
