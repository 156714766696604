import parse from "html-react-parser";
import { Component } from "react";

class Select extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.parentChange = function () {};

    this.state = {
      loaded: false,
    };
  }

  onChange(e) {
    this.setState({
      value: e.target.value,
    });

    let _this = this;
    setTimeout(this["customOnChange"], 0);

    setTimeout(function () {
      _this.parentChange(e.target.value);
    }, 0);
  }

  componentDidMount() {
    let events = [];

    Object.keys(this.props.property.events)?.forEach((element, idx) => {
      if (!element.toLowerCase().startsWith("onchange")) {
        this[element] = this.props.property.events[element];
        this[element] = this[element].bind(this);
        events[element] = this[element];
      } else {
        this["customOnChange"] = this.props.property.events[element];
        this["customOnChange"] = this["customOnChange"].bind(this);
      }
    });

    if (this.props.property.parentChange) {
      this.parentChange = this.props.property.parentChange.bind(this);
    }

    this.setState({
      loaded: true,
      events: events,
      attributes: { ...this.props.property.attributes },
      value: this.props.value,
      options: this.props.property.configuration?.options
        ? this.props.property.configuration.options
        : [],
      editable: this.props.property.editable === false ? false : true,
      useCustomUI: this.props.property.useCustomUI,
    });

    if (events["didMount"]) {
      setTimeout(events["didMount"], 0);
    }
  }

  editable() {
    return (
      <select
        {...this.state.events}
        {...this.state.attributes}
        value={this.state.value}
        onChange={this.onChange}
      >
        {this.state.options.map((el) => {
          return <option value={el.value}>{el.label}</option>;
        })}
      </select>
    );
  }

  nonEditable() {
    return typeof this.state.value === "string"
      ? parse(this.state.value)
      : this.state.value;
  }

  customUI() {}

  render() {
    if (this.state.loaded) {
      if (this.state.useCustomUI) {
        return this.customUI();
      } else if (this.state.editable) {
        return this.editable();
      } else {
        return this.nonEditable();
      }
    }

    return "";
  }
}

export default Select;
