import { Link } from "react-router-dom";
import $f from "../../src";
import $c from "../functions";
import Modals from "../modals";

const ConferencesQuestions = {
  properties: (args) => {
    const { editable } = args;

    return {
      _id: {
        label: "",
        path: "_id",
        type: "hidden",
        labelPosition: "none",
        events: {},
        attributes: {},
      },
      questionBody: {
        label: "",
        type: "textarea",
        editable: editable,
        events: {},
        attributes: {
          className: "form-control",
        },
      },
    };
  },
  specialProperties: () => {},
  views: {
    detail: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return {
              data: [{ question: null }],
            };
          },
          dataPath: () => {},
          replace: {},
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Form";
        },

        configuration: {
          includeProperties: (args) => {
            if (args.data[0]._id) {
              return ["questionBody"];
            }
            return ["questionBody"];
          },

          primaryKey: "_id",
        },
        properties: () => {
          return { editable: true };
        },
      },
    },
  },
};

export default ConferencesQuestions;
