import { Component } from "react";
import { withRouter } from "react-router";
import $f from "../../src";

class OptionsForHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      configuration: {},
    };
  }

  componentDidMount() {
    let events = [];

    this.setState({
      loaded: true,
      events: events,
      attributes: { ...this.props.property.attributes },
      configuration: { ...this.props.property.configuration },
      value: this.props.value,
    });
  }

  render() {
    if (this.state.loaded) {
      const collapseTarget = $f.key();

      return this.state.configuration ? (
        <div className=" dropstart d-inline-block">
          <button
            type="button"
            className={
              this.state.configuration.btnClassname
                ? this.state.configuration.btnClassname
                : "btn btn-white btn-sm ms-2"
            }
            data-bs-toggle="dropdown"
          >
            {this.state.configuration.caption
              ? this.state.configuration.caption
              : "Options"}
          </button>

          <ul className="dropdown-menu ">
            {Object.keys(this.state.configuration.options).map((el) => {
              return $f.getValue(this.state.configuration.options[el], this) ? (
                <li
                  className="dropdown-item small"
                  role="button"
                  key={$f.key()}
                  onClick={
                    $f.getValue(this.state.configuration.options[el], this)[
                      "onClick"
                    ]
                  }
                >
                  {
                    $f.getValue(this.state.configuration.options[el], this)[
                      "label"
                    ]
                  }
                </li>
              ) : (
                ""
              );
            })}
          </ul>
        </div>
      ) : (
        ""
      );
    }

    return "";
  }
}

export default withRouter(OptionsForHeader);
