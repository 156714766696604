import {
  ContentShare,
  useMeetingManager,
  useLocalVideo,
  useToggleLocalMute,
  PreviewVideo,
  useLogger,
} from "amazon-chime-sdk-component-library-react";
import React, { useState } from "react";
import { MeetingSessionConfiguration } from "amazon-chime-sdk-js";
import MeetingFunctions from "./functions";
import $f from "../../../../src";
import $c from "../../../../modules/functions";
import { useEffect } from "react";
import { fetchParticipants, joinEvent } from "./apis";

const Welcome = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  document.body.style.paddingTop = "3rem";

  const meetingManager = useMeetingManager();
  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const { muted, toggleMute } = useToggleLocalMute();
  const logger = useLogger();

  const joinMeeting = async () => {
    const data = await joinEvent(
      $f.getQueryPath(2),
      $f.getParameterByName("breakout")
    );

    let meeting = data.JoinInfo.Meeting;

    console.log("pasok1", props.meeting);

    let participants = await fetchParticipants(
      $f.getQueryPath(2).replace("m-", "").replace("v-", "")
    );

    data.JoinInfo.participants = participants;

    if (props.meeting.trainingId) {
      data.JoinInfo.trainingId = props.meeting.trainingId;
    }

    meeting.externalMeetingId = JSON.stringify(data.JoinInfo);

    const attendee = data.JoinInfo.Attendee;

    const joinData = new MeetingSessionConfiguration(meeting, attendee);

    await meetingManager.join(joinData);

    meetingManager.meetingSession.audioVideo.realtimeMuteLocalAudio();

    await meetingManager.start();

    props.deviceSetup(true);
  };

  const startMeetingManager = async () => {
    await meetingManager.start();
    props.deviceSetup(true);
  };

  useEffect(() => {
    joinMeeting();
  }, [isLoading]);

  if (!isLoading) {
    return "";
  }
  return (
    <main className="container-fluid  " style={{ minHeight: "100%" }}>
      <header>
        <nav className="navbar navbar-expand-md  ">
          <div className="container-fluid  viewport-padding  color-strong-grey">
            <span className="display-5 mb-0">{window["COMPANY_LOGO"]()}</span>
          </div>
        </nav>
      </header>
      <div className="viewport-padding">
        <div className="row">
          <div className="col-md-4 m-auto">
            <div className="pe-5">
              <h1
                className={
                  !$c.isLoggedIn() ? "display-3-no-line-height" : "display-3"
                }
              >
                The event is ready to start
              </h1>
              {!$c.isLoggedIn() ? (
                <h5 className="display-5 color-grey pb-1438rem">
                  Type your name to join event
                </h5>
              ) : (
                <div class="form-group mb-3"></div>
              )}
              {!$c.isLoggedIn() ? (
                <div class="form-group mb-3">
                  <label for="exampleInputEmail1" className="label-top">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Type your name"
                  />
                </div>
              ) : (
                <div class="form-group mb-3"></div>
              )}
              <button
                type="button"
                className="btn btn-purple  w-100"
                onClick={startMeetingManager}
              >
                Join Event
              </button>
              {!$c.isLoggedIn() ? (
                <div className="text-center mt-3">
                  <span className="w-100">Already have an account?</span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-md-8">
            <div className="position-relative">
              {isVideoEnabled ? (
                <PreviewVideo className="rounded me-5" />
              ) : (
                <div
                  className="rounded me-5 bg-black w-100"
                  style={{ height: "calc(100vh - 18rem)" }}
                ></div>
              )}
              <ContentShare />
              <div className="video-control-panel">
                {MeetingFunctions.createCameraDeviceButton(
                  meetingManager,
                  isVideoEnabled,
                  toggleVideo
                )}
                {MeetingFunctions.createAudioInputButton(
                  meetingManager,
                  muted,
                  toggleMute
                )}
              </div>

              {/* <div id="video">
                <div className="gridVideo"></div>
                <div id="previewVideo"></div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Welcome;
