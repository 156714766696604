import $f from "../src";
import $c from "./functions";
import Modals from "./modals";

const Homepage = {
  properties: (args) => {
    const { editable } = args;

    return {
      _id: {
        label: "",
        path: "_id",
        type: "text",
        events: {},
        attributes: {},
      },
    };
  },
  specialProperties: () => {
    return {};
  },
  views: {
    list: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: { data: [{}] },
          dataPath: () => {},
          replace: {},
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Homepage";
        },
        configuration: {
          includeProperties: () => {},
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
  },
};

export default Homepage;
