import { Component } from "react";
import { withRouter } from "react-router";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    const { data, module, view } = this.props;

    let templateConfiguration = view.template.configuration;

    const innerTemplates = ["header", "top", "bottom"];

    innerTemplates.map((itm, idx) => {
      let tmp = templateConfiguration[itm + "Template"]
        ? templateConfiguration[itm + "Template"]
        : () => {};
      this[itm + "Template"] = tmp.bind(this);
    });

    this.setState({ loaded: true, data, module, view });
  }

  render() {
    if (this.state.loaded) {
      return (
        <div>
          {this.topTemplate()}
          {this.state.data.map((el) => {
            return Object.keys(el).map((itm) => {
              if (el[itm].showOnTemplate) {
                switch (el[itm].property.labelPosition) {
                  case "right":
                    return (
                      <div className="form-group mb-3 d-flex">
                        <label className="label-top w-100">
                          {el[itm].label}
                        </label>
                        <div>{el[itm].component}</div>
                      </div>
                    );
                  case "none":
                    return (
                      <div className="form-group mb-3 ">
                        {el[itm].component}
                      </div>
                    );

                  default:
                    return (
                      <div className="form-group mb-3">
                        <label className="label-top d-block">
                          {el[itm].label}
                        </label>
                        {el[itm].component}
                      </div>
                    );
                }
              }
            });
          })}
          {this.bottomTemplate()}
        </div>
      );
    }

    return "";
  }
}

export default withRouter(Form);
