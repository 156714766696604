import $c from "./functions";

const TopMenu = {
  properties: () => {
    return {
      id: {
        label: "",
        type: "text",
        events: {},
        attributes: {},
      },
      title: {
        label: "",
        type: "text",
        events: {},
        attributes: {},
      },
      url: {
        label: "",
        type: "text",
        events: {},
        attributes: {},
      },
      isActive: {
        label: "",
        type: "text",
        events: {},
        attributes: {},
      },
    };
  },
  views: {
    list: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          dataPath: () => {},
          url: {
            data: [
              {
                id: "/",
                title: "Home",
                url: "/",
                children: [],
                isActive: function () {
                  if (window.location.pathname === this.url) {
                    return true;
                  }
                  return false;
                },
              },
              {
                id: "/contacts",
                title: "Contacts",
                url: "/contacts",
                children: [],
                isActive: function () {
                  if (window.location.pathname.startsWith(this.url)) {
                    return true;
                  }
                  return false;
                },
              },

              {
                id: "/files",
                title: "Library",
                url: "/files",
                children: [],
                isActive: function () {
                  if (window.location.pathname.startsWith(this.url)) {
                    return true;
                  }
                  return false;
                },
              },
              {
                id: "/events",
                title: "Events",
                url: "/events/calendar",
                children: [],
                isActive: function () {
                  if (window.location.pathname.startsWith(this.url)) {
                    return true;
                  }
                  return false;
                },
              },
              {
                id: "/spaces",
                title: "Spaces",
                url: "/spaces",
                children: [],
                isActive: function () {
                  if (window.location.pathname.startsWith(this.url)) {
                    return true;
                  }
                  return false;
                },
                // hidden: function () {
                //   return !$c.userIsAdmin();
                // },
              },
              {
                id: "/forms",
                title: "Forms",
                url: "/forms",
                children: [],
                isActive: function () {
                  if (window.location.pathname.startsWith(this.url)) {
                    return true;
                  }
                  return false;
                },
                hidden: function () {
                  return !$c.userIsAdmin();
                },
              },
              {
                id: "/tests/assessments",
                title: "Assesments",
                url: "/tests/assessments",
                children: [],
                hidden: function () {
                  return $c.getUserProperty("instructor") === true
                    ? false
                    : true;
                },
                isActive: function () {
                  if (window.location.pathname.startsWith(this.url)) {
                    return true;
                  }
                  return false;
                },
              },

              // {
              //   id: "/chat",
              //   title: "Chat",
              //   url: "/chat",
              //   children: [],
              //   isActive: function () {
              //     if (window.location.pathname.startsWith(this.url)) {
              //       return true;
              //     }
              //     return false;
              //   },
              // },
            ],
          },
          replace: {},
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "TopMenu";
        },
        configuration: {
          includeProperties: () => {},
          excludeProperties: () => {},
          primaryKey: "id",
        },

        properties: () => {},
      },
    },
    meeting: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          dataPath: () => {},
          url: {
            data: [
              {
                id: "/meeting/participants",
                title: "Participants",
                url: "/meeting",
                children: [],
                isActive: function () {
                  if (window.location.pathname.startsWith(this.url)) {
                    return true;
                  }
                  return false;
                },
              },
              {
                id: "/meeting/chat",
                title: "Chat",
                url: "/meeting/chat",
                children: [],
                isActive: function () {
                  if (window.location.pathname.startsWith(this.url)) {
                    return true;
                  }
                  return false;
                },
              },
              {
                id: "/meeting/breaking-rooms",
                title: "Breaking-rooms",
                url: "/meeting/breaking-rooms",
                children: [],
                isActive: function () {
                  if (window.location.pathname.startsWith(this.url)) {
                    return true;
                  }
                  return false;
                },
              },
            ],
          },
          replace: {},
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "TopMenu";
        },
        configuration: {
          includeProperties: () => {},
          excludeProperties: () => {},
          primaryKey: "id",
        },

        properties: () => {},
      },
    },
  },
};

export default TopMenu;
