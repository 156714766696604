import parse from "html-react-parser";
import { Component } from "react";

class Label extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    this.setState({
      loaded: true,
      attributes: { ...this.props.property.attributes },
      value: this.props.value,
    });
  }

  render() {
    if (this.state.loaded) {
      return (
        <label {...this.state.attributes}>{this.props.property.label}</label>
      );
    }

    return "";
  }
}

export default Label;
