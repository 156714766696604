import { Component } from "react";
import $f from "../../../../src";

class VerifyAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      key: "",
      hash: "",
      email: "",
    };

    this.sendOTP = this.sendOTP.bind(this);
    this.sendVerify = this.sendVerify.bind(this);
  }

  componentDidMount() {
    const { data, module, view } = this.props;

    document.body.style.paddingTop = "unset";

    this.setState({ loaded: true, data, module, view });

    this.sendOTP();
  }

  sendOTP(resend) {
    let authorization = "Bearer " + $f.getParameterByName("i");

    $f.fetch(
      "/auth/sendVerificationKey",
      "POST",
      resend ? { resend: true } : [],
      { Authorization: authorization },
      {},
      (response) => {
        if (response.data) {
          if (response.data.fullHash) {
            this.setState({
              hash: response.data.fullHash,
              email: response.data.email,
            });
          }
        }
      }
    );
  }

  sendVerify() {
    let authorization = "Bearer " + $f.getParameterByName("i");

    $f.fetch(
      "/auth/verify",
      "POST",
      {
        key: this.state.key,
        hash: this.state.hash,
      },
      { Authorization: authorization },
      {},
      (response) => {
        if (response.status == 200) {
          let url = "/confirm-password?i=" + $f.getParameterByName("i");
          window.location.replace(url);
        }
      }
    );
  }

  render() {
    if (this.state.loaded) {
      const data = this.state.data[0];

      return (
        <main className="container-fluid h-100">
          <header>
            <nav className="navbar navbar-expand-md  ">
              <div className="container-fluid  viewport-padding  color-strong-grey">
                <span className="display-5 mb-0">
                  {window["COMPANY_LOGO"]()}
                </span>
              </div>
            </nav>
          </header>
          <div className="wrapper d-flex h-100">
            <div className=" w-100 m-auto">
              <div className="viewport-padding fullpage-form main-height m-auto">
                <div className="">
                  <div className="">
                    <h1 className="display-3-no-line-height">
                      Verify your account
                    </h1>
                    <span className="d-block color-grey pb-1438rem">
                      We have send a verification code to your email. The code
                      expires shortly, so please enter it soon. Remember to
                      check your spam.
                    </span>
                  </div>
                  <div className="form-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Type or paste the 6-digit code here..."
                      value={this.state.key}
                      onChange={(e) => {
                        this.setState({ key: e.target.value });
                      }}
                    />
                  </div>
                  <button
                    type="button"
                    class="btn btn-purple  w-100"
                    onClick={this.sendVerify}
                  >
                    Submit
                  </button>
                  <div className="text-end mt-3">
                    <span className="color-purple w-100">
                      Didn't receive an email?{" "}
                      <span
                        role="button"
                        className="strong"
                        onClick={() => {
                          this.sendOTP(true);
                        }}
                      >
                        Resend it!
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      );
    }

    return "";
  }
}

export default VerifyAccount;
