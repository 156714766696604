import { Link } from "react-router-dom";
import Data from "./data/data";
import moment from "moment";
import base64 from "base-64";
import parse from "html-react-parser";
import JoinEventButton from "../types/joinEventButton/joinEventButton";
import $f from "../src";
import Modals from "./modals";
import Button from "../types/button/button";
import { ButtonGroup } from "react-bootstrap";
import ButtonWithRouter from "../types/buttonWithRouter/buttonWithRouter";

const $c = {
  addHeadersBeforeFetch: function (headers) {
    if (!headers["Authorization"]) {
      headers["Authorization"] = "Bearer ".concat(localStorage.getItem("jwt"));
    }

    return headers;
  },
  isEmail: function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
  numberWithCommas: function (x) {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return x;
  },
  boldString: function (str, substr) {
    substr = substr
      .replace("/Contacts", "")
      .replace("/Events", "")
      .replace("/Files", "")
      .trim();

    const n = str.toUpperCase();
    const q = substr.toUpperCase();
    const x = n.indexOf(q);
    if (!q || x === -1) {
      return str; // bail early
    }
    const l = q.length;
    return (
      str.substr(0, x) + "<b>" + str.substr(x, l) + "</b>" + str.substr(x + l)
    );
  },
  downloadFile: function (url, title) {
    $f.fetch(
      url.concat("&jwt=", localStorage.getItem("jwt")),
      "GET",
      null,
      {},
      {},
      (resp) => {
        try {
          const href = URL.createObjectURL(resp.data);

          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", title); //or any other extension
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        } catch (e) {}
      },
      "blob"
    );
  },
  readingDurationFromObjectToText: function (duration) {
    if (duration) {
      const { hours, minutes } = duration;

      if (hours == 0 && minutes == 0) {
        return "Self paced";
      }

      if (hours == 0) {
        return minutes + " minutes";
      }

      if (minutes == 0) {
        return hours + (hours > 1 ? " hours" : " hour");
      }

      return hours + " hours and " + minutes + " minutes";
    }

    return "Self paced";
  },
  examDurationFromObjectToText: function (duration) {
    if (duration) {
      const { hours, minutes } = duration;

      if (hours == 0 && minutes == 0) {
        return "";
      }

      if (hours == 0) {
        return minutes + " minutes";
      }

      if (minutes == 0) {
        return hours + (hours > 1 ? " hours" : " hour");
      }

      return hours + " hours and " + minutes + " minutes";
    }
    return "-";
  },
  examDurationMsToText: function (duration) {
    let milliseconds = Math.floor((duration % 1000) / 100),
      seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? hours : hours;
    minutes = minutes < 10 ? minutes : minutes;

    if (hours == 0 && minutes == 0) {
      return "";
    }

    if (hours == 0) {
      return minutes + " minutes duration";
    }

    if (minutes == 0) {
      return hours + " hour duration";
    }

    return hours + " hours and " + minutes + " minutes duration";
  },
  generalFetchErrorResponse: (error) => {
    try {
      if (error.response) {
        if (error.response.status == 401 || error.response.status == 403) {
          $c.logout(window.location.href);
        }
      }
    } catch (e) {
      console.log(e);
    }
  },
  checkEventDateStatus: function (from, to, extraStartTime, extraEndTime) {
    if (from && to) {
      if (moment(to).add(extraEndTime, "minutes").isBefore(moment())) {
        return "ended";
      } else if (
        moment(from).subtract(extraStartTime, "minutes").isBefore(moment()) &&
        moment(to).add(extraEndTime, "minutes").isAfter(moment())
      ) {
        return "join";
      } else if (
        moment(from).subtract(extraStartTime, "minutes").isAfter(moment())
      ) {
        return "pending";
      }
    }

    return "";
  },
  parse(value) {
    if (value) {
      value = value.replaceAll("<p><br></p>", "");
      return parse(value);
    }
    return value;
  },
  logout: function (url) {
    localStorage.clear();

    if (url) {
      if (typeof url === "string") {
        window.location.replace("/login?redirect=" + url);
      } else {
        window.location.replace("/login");
      }
    } else {
      window.location.replace("/login");
    }
  },
  isLoggedIn: function () {
    try {
      const extra = JSON.parse(base64.decode(localStorage.getItem("extra")));
      if (localStorage.getItem("jwt") && extra["_id"]) {
        return true;
      }
    } catch (e) {}
    return false;
  },
  getUserId: function () {
    try {
      const extra = JSON.parse(base64.decode(localStorage.getItem("extra")));

      return extra["_id"];
    } catch (e) {}
    return null;
  },
  getUserProperty: function (prop) {
    try {
      const extra = JSON.parse(base64.decode(localStorage.getItem("extra")));

      return extra[prop];
    } catch (e) {}
    return null;
  },
  getPersonalFolderDirectory: function () {
    try {
      const extra = JSON.parse(base64.decode(localStorage.getItem("extra")));

      return extra["personalFolderDirectory"];
    } catch (e) {}
    return null;
  },
  userIsAdmin: function () {
    return $c.getUserRole() == "admin" ? true : false;
  },
  userIsMember: function () {
    return $c.getUserRole() == "member" ? true : false;
  },
  userIsSimpleMember: function () {
    return $c.getUserRole() == "member" &&
      !$c.userIsHost() &&
      !$c.userIsInstructor()
      ? true
      : false;
  },
  userIsHost: function () {
    return $c.getUserProperty("host").toString() == "true" ? true : false;
  },
  userIsInstructor: function () {
    return $c.getUserProperty("instructor").toString() == "true" ? true : false;
  },
  userIsViewer: function () {
    return $c.getUserProperty("manager").toString() == "true" ? true : false;
  },
  getUserRole: function () {
    try {
      const extra = JSON.parse(base64.decode(localStorage.getItem("extra")));

      return extra["role"];
    } catch (e) {}
  },
  getLibraryDirectory: function () {
    try {
      const extra = JSON.parse(base64.decode(localStorage.getItem("extra")));

      return extra["library"];
    } catch (e) {}
    return null;
  },
  createAvatar: function (
    profileImageId,
    firstName,
    lastName,
    avatarClass,
    subTitle,
    showName
  ) {
    try {
      let profileImage =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdjmDJr6X8ABmwC01caNPcAAAAASUVORK5CYII=";

      let initials = firstName.charAt(0).concat(lastName.charAt(0));

      if (!avatarClass) {
        avatarClass = "avatar-xs";
      }

      const fullName = firstName + " " + lastName;

      if (profileImageId) {
        profileImage = window["baseurl"](
          "/agenda-file/data/" + profileImageId
        ).concat("?type=preview&jwt=" + localStorage.getItem("jwt"));
        initials = "";
      }

      return (
        <div
          className={"d-inline-block avatar-wrapper  ".concat(avatarClass)}
          style={{ lineHeight: "normal" }}
        >
          <div className="d-flex">
            <span className=" me-2 ">
              <img src={profileImage} alt={fullName} />
              <span>{initials}</span>
            </span>
            <div className="mt-auto mb-auto">
              {showName !== false ? (
                <span
                  className="text-ellipsis mt-auto mb-auto d-block avatar-fullname"
                  title={fullName}
                >
                  {fullName}
                </span>
              ) : (
                ""
              )}
              {subTitle ? (
                <span className="text-ellipsis mt-2 color-grey d-block avatar-subtitle">
                  {subTitle}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      );
    } catch (e) {}
    return "";
  },
  copyToClipboard: function (text) {
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  },
  createFileTitleForTable: function (value) {
    const data = value.props.data;

    const module = value.props.module;

    return $c.createFileTitle(data, module);
  },
  createFileTitle: function (data, module) {
    let mimetype = data.mimetype;

    if (data.recording) {
      mimetype = "videorecording";
      if (data.isAudio) {
        mimetype = "audiorecording";
      }
    }

    const icon = Data.FileIcons[mimetype?.toLowerCase()]
      ? Data.FileIcons[mimetype?.toLowerCase()]
      : Data.FileIcons["default"];

    if (mimetype == "folder") {
      return (
        <span
          className="d-flex text-normal"
          role="button"
          onClick={() => {
            if ($f.getQueryPath(2) == "favorites") {
              window.location.replace(data.url);
            } else {
              let url = window.location.pathname.concat("?_id=" + data._id);

              window.history.pushState({ path: url }, "", url);

              module.doWork();
            }
          }}
          title={data.title}
        >
          <img src={icon} alt={data.title} className="icon-xs me-2"></img>
          <span className="text-ellipsis mt-auto mb-auto">{data.title}</span>
        </span>
      );
    }

    return (
      <Link className="d-flex text-normal" to={data.url} title={data.title}>
        <img src={icon} alt={data.title} className="icon-xs me-2"></img>
        <span className="text-ellipsis mt-auto mb-auto">{data.title}</span>
      </Link>
    );
  },
  formatBytes: function (a, b = 2) {
    if (!+a) return "0 Bytes";
    const c = 0 > b ? 0 : b,
      d = Math.floor(Math.log(a) / Math.log(1024));
    return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${
      ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
    }`;
  },
  getTrainingIcon: function (iconType) {
    const icon = Data.TrainingIcons[iconType.toLowerCase()]
      ? Data.TrainingIcons[iconType.toLowerCase()]
      : Data.TrainingIcons["default"];

    return <img src={icon} className="icon-sm me-2"></img>;
  },
  getFileIcon: function (mimetype, title, obj) {
    let _mimetype = mimetype;

    if (obj) {
      if (obj.recording) {
        _mimetype = "videorecording";
        if (obj.isAudio) {
          _mimetype = "audiorecording";
        }
      }
    }

    const icon = Data.FileIcons[_mimetype.toLowerCase()]
      ? Data.FileIcons[_mimetype.toLowerCase()]
      : Data.FileIcons["default"];

    return <img src={icon} alt={title} className="icon-xs me-2"></img>;
  },
  getFontFileIcon: function (mimetype, title) {
    const icon = Data.FontFileIcons[mimetype.toLowerCase()]
      ? Data.FontFileIcons[mimetype.toLowerCase()]
      : Data.FontFileIcons["default"];

    return <i className={icon.concat(" fa-lg me-2")}></i>;
  },

  formatFileResponse: function (responseData, type) {
    if (responseData.data) {
      if (responseData.data[0]) {
        const allFiles = [];

        responseData.data[0].folders?.forEach((itm) => {
          itm.isFolder = true;
          itm.title = itm.name;
          itm.url = window.location.pathname.concat("?_id=" + itm._id);
          itm.authorLabel = itm.authors ? itm.authors.length + " Authors" : "-";
          itm.size = "-";
          itm.mimetype = "folder";
          allFiles.push(itm);
        });

        responseData.data[0].files?.forEach((itm) => {
          itm.isFolder = false;

          switch (type) {
            case "agenda":
              itm.url = "/files/preview/a-".concat(itm._id);

              break;

            case "training":
              itm.url = "/files/preview/t-".concat(itm._id);
              break;
            default:
              itm.url = "/files/preview/".concat(itm._id);
              break;
          }

          itm.authorLabel = itm.authors ? itm.authors.length + " Authors" : "-";
          allFiles.push(itm);
        });

        responseData.data[0].allFiles = allFiles;

        return responseData;
      }
    }

    return [];
  },
  formatDateTime: function (value) {
    return moment(value).format("ddd DD/MM/YYYY HH:mm");
  },
  formatDate: function (value) {
    return moment(value).format("ddd DD MMM, YYYY");
  },
  formatDateMonth: function (value) {
    return moment(value).format("MMMM DD, YYYY");
  },
  formatDateNoYear: function (value) {
    return moment(value).format("ddd MMM DD");
  },
  formatTime: function (value) {
    return moment(value).format("HH:mm");
  },
  formatDateFromTo: function (from, to) {
    let fromValue = moment(from).format("ddd DD MMM YY, HH:mm");
    let toValue = moment(to).format("ddd DD MMM YY, HH:mm");

    let fromDate = moment(from);
    let toDate = moment(to);
    let isSame = fromDate.isSame(toDate, "date");

    if (isSame) {
      return (
        moment(from).format("ddd DD MMM YY") +
        ", " +
        moment(from).format("HH:mm") +
        " - " +
        moment(to).format("HH:mm")
      );
    }
    return fromValue + " to " + toValue;
  },
  formatDateFromToNoTime: function (from, to) {
    let fromValue = moment(from).format("ddd DD MMM YY");
    let toValue = moment(to).format("ddd DD MMM YY");

    let fromDate = moment(from);
    let toDate = moment(to);
    let isSame = fromDate.isSame(toDate, "date");

    if (isSame) {
      return moment(from).format("ddd DD MMM YY");
    }
    return fromValue + " to " + toValue;
  },
  createICS: function (event) {
    let ics = require("ics");

    let start = moment(event.from)
      .format("YYYY-M-D-H-m")
      .split("-")
      .map(function (x) {
        return parseInt(x, 10);
      });

    let end = moment(event.to)
      .format("YYYY-M-D-H-m")
      .split("-")
      .map(function (x) {
        return parseInt(x, 10);
      });

    let link = window["host"](event.url);

    let description =
      "You are invited to join " +
      event.eventType +
      "\n\n Date:" +
      moment(event.from).format("ddd DD MMM YYYY") +
      "\n Time:" +
      moment(event.from).format("hh:mm A") +
      "\n\n To access your " +
      event.eventType +
      " you need first to login (" +
      window["host"]("/") +
      ") \n Join " +
      event.eventType +
      " \n\n" +
      link;

    const eventD = {
      start: start,
      end: end,
      title: event.title,
      location: event.eventType,
      url: link,
      description: description,
    };

    ics.createEvent(eventD, (error, value) => {
      if (error) {
        console.log(error);
        return;
      }
      window.open("data:text/calendar;charset=utf8," + value);
    });
  },
  testGrade: function (value, type) {
    switch (type) {
      case "overview":
        return parseInt(value) > 0
          ? "Grade ".concat(value).concat(" % or higher to pass")
          : "Practice test";
        break;
      case "label":
        return parseInt(value) > 0 ? "Graded" : "Practice";
        break;
      case "isGraded":
        return parseInt(value) > 0 ? true : false;
        break;

      case "badge":
        return value ? (
          <span
            className="badge bg-light-blue p-2 color-black rounded"
            style={{ width: "5rem" }}
          >
            Graded
          </span>
        ) : (
          <span
            className="badge bg-light-purple p-2 color-black rounded"
            style={{ width: "5rem" }}
          >
            Practice
          </span>
        );
        break;
    }
  },
  traininingMaterialDuration: function (duration) {
    let _duration = "";
    if (duration) {
      if (duration.hours) {
        if (duration.hours > 0) {
          _duration = duration.hours.toString().concat("hr ");
        }
      }

      if (duration.minutes) {
        _duration += ("00" + duration.minutes.toString())
          .slice(-2)
          .concat(" min");
      }

      if (_duration && (duration.hours > 0 || duration.minutes > 0)) {
        return _duration.concat("  reading");
      }
    }

    return "Self paced";
  },
  trainingMaterialRemainingTime: function (remaining) {
    //remainingMiliseconds

    var milliseconds = Math.floor((remaining % 1000) / 100),
      seconds = Math.floor((remaining / 1000) % 60),
      minutes = Math.floor((remaining / (1000 * 60)) % 60),
      hours = Math.floor((remaining / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? hours : hours;
    minutes = minutes < 10 ? minutes : minutes;

    if (hours == 0 && minutes == 0) {
      return "";
    }

    if (hours == 0) {
      return minutes + " min remaining to complete";
    }

    return hours + " hr " + minutes + " min remaining to complete";
  },
  testSubmitted: function (status) {
    const submittedStatus = [
      "Pass",
      "Fail",
      "No grade",
      "Not Evaluated",
      "Submitted",
    ];

    if (submittedStatus.indexOf(status) < 0) {
      return false;
    }

    return true;
  },
  testQuestionsCount: function (questions) {
    let multipleChoice = 0;
    let essayType = 0;

    questions.forEach((el) => {
      if (el.type == "multipleChoice") {
        multipleChoice++;
      }
      if (el.type == "essayType") {
        essayType++;
      }
    });

    if (multipleChoice == 0 && essayType == 0) {
      return "";
    }

    if (multipleChoice > 0 && essayType > 0) {
      return "Total "
        .concat(questions.length)
        .concat(" questions: ")
        .concat(multipleChoice)
        .concat(" multiple choice and  ")
        .concat(essayType)
        .concat(" essay questions");
    } else if (multipleChoice > 0) {
      return "Total "
        .concat(multipleChoice)
        .concat(" multiple choice questions");
    } else {
      return "Total ".concat(essayType).concat(" essay questions");
    }
  },
  conferenceSessions: function (sessions, day, conference) {
    const output = [];

    sessions.forEach((el, idx) => {
      const bgcolor = idx % 2 == 0 ? "bg-light-grey" : "";

      const tab = (
        <div className={"d-flex ".concat(bgcolor)}>
          <div
            className="p-5 border-end display-gt-md-block position-relative"
            style={{ width: "15rem" }}
          >
            <span className="conference-circle"></span>
            <h3 className="display-4-no-margin">{$c.formatTime(el.from)}</h3>
            <span className="color-grey">{$c.formatDateNoYear(el.from)}</span>
            <div className="">
              {el.recording ? (
                <ButtonWithRouter
                  data={el}
                  property={{
                    attributes: {
                      className: "btn btn-purple btn-sm mt-2",
                    },
                    events: {
                      onClick: function () {
                        console.log(this);
                        this.props.history.push(
                          "/files/preview/a-".concat(this.props.data.recording)
                        );
                      },
                    },
                    caption: "Watch on demand",
                  }}
                ></ButtonWithRouter>
              ) : (
                <JoinEventButton
                  data={el}
                  property={{
                    attributes: {
                      className: "btn btn-purple btn-sm mt-2",
                    },
                    events: {
                      onClick: function () {
                        window.open(
                          "/live/conference/"
                            .concat(conference._id)
                            .concat("/day/")
                            .concat(day._id)
                            .concat("/session/")
                            .concat(el._id),
                          "_blank"
                        );

                        // this.props.history.push(
                        //   "/live/conference/"
                        //     .concat(conference._id)
                        //     .concat("/day/")
                        //     .concat(day._id)
                        //     .concat("/session/")
                        //     .concat(el._id)
                        // );
                      },
                    },
                    caption: "Watch now",
                    configuration: {
                      eventNotStartedText: "",
                      eventHasEndedText: "",
                      fromDateProperty: "from",
                      endDateProperty: "to",
                      extraStartTime: 0,
                      extraEndTime: 0,
                    },
                  }}
                ></JoinEventButton>
              )}
            </div>
          </div>
          <div className="w-100 p-5">
            <div className="border-bottom display-lt-md-block pb-3 mb-3">
              <div className="d-flex">
                <div>
                  <h3 className="display-4-no-margin">
                    {$c.formatTime(el.from)}
                  </h3>
                  <span className="color-grey">
                    {$c.formatDateNoYear(el.from)}
                  </span>
                </div>
                <div className="m-auto">
                  <JoinEventButton
                    data={el}
                    property={{
                      attributes: {
                        className: "btn btn-purple  mt-2",
                      },
                      events: {
                        onClick: function () {
                          window.open(
                            "/live/conference/"
                              .concat(conference._id)
                              .concat("/day/")
                              .concat(day._id)
                              .concat("/session/")
                              .concat(el._id),
                            "_blank"
                          );

                          // this.props.history.push(
                          //   "/live/conference/"
                          //     .concat(conference._id)
                          //     .concat("/day/")
                          //     .concat(day._id)
                          //     .concat("/session/")
                          //     .concat(el._id)
                          // );
                        },
                      },
                      caption: "Watch now",
                      configuration: {
                        eventNotStartedText: "",
                        eventHasEndedText: "",
                        fromDateProperty: "from",
                        endDateProperty: "to",
                        extraStartTime: 0,
                        extraEndTime: 0,
                      },
                    }}
                  ></JoinEventButton>
                </div>
              </div>
            </div>
            <h3 className="display-4">{el.title}</h3>
            <div className="color-grey">{parse(el.description)}</div>
            <div className="w-75">
              {el.speakers.map((sel, sidx) => {
                if (sel.firstName) {
                  return (
                    <span
                      role="button"
                      onClick={() => {
                        // Modals.contacts.userProfile({
                        //   userId: sel._id,
                        // });
                        Modals.events.openSpeaker({
                          speaker: sel,
                          data: conference,
                        });
                      }}
                    >
                      {$c.createAvatar(
                        sel.profileImage,
                        sel.firstName,
                        sel.lastName,
                        "avatar-large p-2",
                        sel.jobTitle
                      )}
                    </span>
                  );
                }
              })}
            </div>
          </div>
        </div>
      );

      output.push(tab);
    });

    return output;
  },
  agendaFileImageURL: function (id) {
    return window["baseurl"](
      "/agenda-file/data/"
        .concat(id)
        .concat("?type=preview&jwt=")
        .concat(localStorage.getItem("jwt"))
    );
  },
  conferenceSpeakers: function (days) {
    const output = [];

    const speakersIds = [];

    days?.forEach((day) => {
      day?.sessions.forEach((session) => {
        session?.speakers.forEach((speaker) => {
          if (speakersIds.indexOf(speaker._id) < 0) {
            speakersIds.push(speaker._id);
            output.push(speaker);
          }
        });
      });
    });

    return output;
  },
  conferenceSessionSpeakers: function (session) {
    const output = [];

    const speakersIds = [];

    session?.speakers.forEach((speaker) => {
      if (speakersIds.indexOf(speaker._id) < 0) {
        speakersIds.push(speaker._id);
        output.push(speaker);
      }
    });

    return output;
  },
  conferenceParticipants: function (participants) {
    const output = [];

    const participantsIds = [];

    participants?.forEach((el) => {
      if (participantsIds.indexOf(el._id) < 0) {
        participantsIds.push(el._id);
        output.push(el);
      }
    });

    return output;
  },
  conferencePartners: function (sponsors) {
    let output = {};

    sponsors.forEach((el) => {
      if (!output[el.category]) {
        output[el.category] = [];
      }

      output[el.category].push(el);
    });

    return output;
  },
};
export default $c;
