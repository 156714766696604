import $f from "../../src";
import $c from "../functions";

const Data = {
  LeftMenuData: {
    contact: {
      data: [
        {
          id: "/",
          title: "Contacts",
          children: [
            {
              id: "/contacts",
              title: "All Contacts",
              url: "/contacts",
              children: [],
              isActive: function () {
                if (window.location.pathname == this.url) {
                  return true;
                }
                return false;
              },
            },
            {
              id: "/contacts/spaces",
              title: "Spaces",
              url: "",
              children: [],
              isActive: function () {
                return false;
              },
              isCollapsed: function () {
                if (window.location.pathname.startsWith("/contacts/space")) {
                  return true;
                }
                return false;
              },
            },
          ],
        },
      ],
    },
    user: {
      data: [
        {
          id: "/",
          title: "Settings",
          children: [
            {
              id: "/profile",
              title: "Profile",
              url: () => {
                return "/user/".concat($f.getQueryPath(2));
              },
              children: [],
              isActive: function () {
                if (window.location.pathname == this.url()) {
                  return true;
                }
                return false;
              },
            },
            {
              id: "/job-details",
              title: "Job details",
              url: () => {
                return "/user/".concat($f.getQueryPath(2), "/job-details");
              },
              children: [],
              isActive: function () {
                if (window.location.pathname == this.url()) {
                  return true;
                }
                return false;
              },
            },
            {
              id: "/activity",
              title: "Activity",
              url: () => {
                return "/user/".concat($f.getQueryPath(2), "/activity");
              },
              children: [],
              isActive: function () {
                if (window.location.pathname == this.url()) {
                  return true;
                }
                return false;
              },
              hidden: function () {
                if (
                  $c.userIsMember() &&
                  $c.getUserId() === $f.getQueryPath(2)
                ) {
                  return false;
                }
                if ($c.userIsAdmin()) {
                  return false;
                }

                return true;
              },
            },
            {
              id: "/security",
              title: "Security",
              url: () => {
                return "/user/".concat($f.getQueryPath(2), "/security");
              },
              children: [],
              isActive: function () {
                if (window.location.pathname == this.url()) {
                  return true;
                }
                return false;
              },
              hidden: function () {
                if (
                  $c.userIsMember() &&
                  $c.getUserId() === $f.getQueryPath(2)
                ) {
                  return false;
                }
                if ($c.userIsAdmin()) {
                  return false;
                }

                return true;
              },
            },
          ],
        },
      ],
    },
    files: {
      data: [
        {
          id: "/",
          title: "Library",
          children: [
            {
              id: "/files",
              title: "Personal Files",
              url: "/files",
              children: [],
              isActive: function () {
                if (window.location.pathname == "/files") {
                  return true;
                }
                return false;
              },
            },
            {
              id: "/files/library",
              title: "Company Files",
              url: "/files/library",
              children: [],
              isActive: function () {
                if (window.location.pathname.startsWith(this.url)) {
                  return true;
                }
                return false;
              },
            },
            {
              id: "/files/gfiles",
              title: "Spaces",
              url: "",
              children: [],
              isActive: function () {
                return false;
              },
              isCollapsed: function () {
                if (window.location.pathname.startsWith("/files/space")) {
                  return true;
                }
                return false;
              },
            },
            {
              id: "/files/favorites",
              title: "Favorites",
              url: "/files/favorites",
              children: [],
              isActive: function () {
                if (window.location.pathname.startsWith(this.url)) {
                  return true;
                }
                return false;
              },
            },
          ],
        },
      ],
    },
    forms: {
      data: [
        {
          id: "/",
          title: "Forms",
          children: [
            {
              id: "/forms",
              title: "All forms",
              url: () => {
                return "/forms";
              },
              children: [],
              isActive: function () {
                return false;
              },
            },
          ],
        },
      ],
    },
    form: {
      data: [
        {
          id: "/",
          title: "Forms",
          children: [
            {
              id: "/forms",
              title: "All forms",
              url: () => {
                return "/forms/";
              },
              children: [],
              isActive: function () {
                return false;
              },
            },
            {
              id: "/forms/:id",
              title: "Overview",
              url: () => {
                return "/forms/".concat($f.getQueryPath(2));
              },
              children: [],
              isActive: function () {
                if (
                  window.location.pathname ==
                  "/forms/".concat($f.getQueryPath(2))
                ) {
                  return true;
                }
                return false;
              },
            },
            {
              id: "/forms/:id",
              title: "Results",
              url: () => {
                return "/forms/".concat($f.getQueryPath(2)).concat("/results");
              },
              children: [],
              isActive: function () {
                if (
                  window.location.pathname ==
                  "/forms/".concat($f.getQueryPath(2)).concat("/results")
                ) {
                  return true;
                }
                return false;
              },
            },
          ],
        },
      ],
    },
    events: {
      data: [
        {
          id: "/",
          title: "Events",
          children: [
            {
              id: "/events/calendar",
              title: "Calendar",
              url: "/events/calendar",
              children: [],
              isActive: function () {
                if (window.location.pathname.startsWith(this.url)) {
                  return true;
                }
                return false;
              },
            },
            {
              id: "/",
              title: "Event lists",
              url: "",
              children: [
                {
                  id: "/events/meetings",
                  title: "Meetings",
                  url: "/events/meetings",
                  children: [],
                  isActive: function () {
                    if (window.location.pathname.startsWith(this.url)) {
                      return true;
                    }
                    return false;
                  },
                },
                {
                  id: "/events/trainings",
                  title: "Trainings",
                  url: "/events/trainings",
                  children: [],
                  isActive: function () {
                    if (window.location.pathname.startsWith(this.url)) {
                      return true;
                    }
                    return false;
                  },
                  hidden: function () {
                    if ($c.getUserProperty("host") === true) {
                      return true;
                    }
                    return false;
                  },
                },
                {
                  id: "/events/tests",
                  title: "Tests",
                  url: "/events/tests",
                  children: [],
                  hidden: function () {
                    if ($c.getUserProperty("host") === true) {
                      return true;
                    }

                    return $c.getUserProperty("instructor") === true
                      ? true
                      : false;
                  },
                  isActive: function () {
                    if (window.location.pathname.startsWith(this.url)) {
                      return true;
                    }
                    return false;
                  },
                },
                {
                  id: "/events/conferences",
                  title: "Conferences",
                  url: "/events/conferences",
                  children: [],
                  isActive: function () {
                    if (window.location.pathname.startsWith(this.url)) {
                      return true;
                    }
                    return false;
                  },
                },
              ],
              isCollapsed: function () {
                if (window.location.pathname.startsWith("/events")) {
                  return true;
                }
                return false;
              },
              isActive: function () {
                // if (window.location.pathname.startsWith("/events")) {
                //   return true;
                // }
                return false;
              },
            },
            {
              id: "/templates",
              title: "Templates",
              url: "",
              children: [
                {
                  id: "/templates/trainings",
                  title: "Trainings",
                  url: "/templates/trainings",
                  children: [],
                  isActive: function () {
                    if (window.location.pathname.startsWith(this.url)) {
                      return true;
                    }
                    return false;
                  },
                },
                {
                  id: "/templates/tests",
                  title: "Tests",
                  hidden: function () {
                    return $c.getUserProperty("instructor") === true
                      ? true
                      : false;
                  },
                  url: "/templates/tests",
                  children: [],
                  isActive: function () {
                    if (window.location.pathname.startsWith(this.url)) {
                      return true;
                    }
                    return false;
                  },
                },
              ],
              isCollapsed: function () {
                if (window.location.pathname.startsWith("/templates")) {
                  return true;
                }
                return false;
              },
              hidden: function () {
                if ($c.getUserProperty("host") === true || $c.userIsMember()) {
                  return true;
                }
                return false;
              },
              isActive: function () {
                // if (window.location.pathname == "/templates") {
                //   return true;
                // }
                return false;
              },
            },
          ],
        },
      ],
    },
    meetingOverview: {
      data: [
        {
          id: "/",
          title: "Meetings",
          children: [
            {
              id: "/event/meeting/:id",
              title: "Overview",
              url: () => {
                return "/event/meeting/".concat($f.getQueryPath(3));
              },
              children: [],
              isActive: function () {
                if (
                  window.location.pathname ==
                  "/event/meeting/".concat($f.getQueryPath(3))
                ) {
                  return true;
                }
                return false;
              },
            },
            {
              id: "/event/meeting/:id/participants",
              title: "Participants",
              url: () => {
                return "/event/meeting/"
                  .concat($f.getQueryPath(3))
                  .concat("/participants");
              },
              children: [],
              isActive: function () {
                if (
                  window.location.pathname ==
                  "/event/meeting/"
                    .concat($f.getQueryPath(3))
                    .concat("/participants")
                ) {
                  return true;
                }
                return false;
              },
            },
            {
              id: "/event/meeting/:id/files",
              title: "Files",
              url: () => {
                return "/event/meeting/"
                  .concat($f.getQueryPath(3))
                  .concat("/files");
              },
              children: [],
              isActive: function () {
                if (
                  window.location.pathname ==
                  "/event/meeting/".concat($f.getQueryPath(3)).concat("/files")
                ) {
                  return true;
                }
                return false;
              },
            },
          ],
        },
      ],
    },
    testOverview: {
      data: [
        {
          id: "/",
          title: "Tests",
          children: [
            {
              id: "/event/test/:id",
              title: "Overview",
              url: () => {
                return "/event/test/".concat($f.getQueryPath(3));
              },
              children: [],
              isActive: function () {
                if (
                  window.location.pathname ==
                  "/event/test/".concat($f.getQueryPath(3))
                ) {
                  return true;
                }
                return false;
              },
            },
            {
              id: "/event/test/:id/results",
              title: "Results",
              url: () => {
                return "/event/test/"
                  .concat($f.getQueryPath(3))
                  .concat("/results");
              },
              children: [],
              isActive: function () {
                if (
                  window.location.pathname ==
                  "/event/test/".concat($f.getQueryPath(3)).concat("/results")
                ) {
                  return true;
                }
                return false;
              },
            },
            {
              id: "/event/test/:id/participants",
              title: "Participants",
              hidden: () => {
                return !$c.userIsAdmin();
              },
              url: () => {
                return "/event/test/"
                  .concat($f.getQueryPath(3))
                  .concat("/participants");
              },
              children: [],
              isActive: function () {
                if (
                  window.location.pathname ==
                  "/event/test/"
                    .concat($f.getQueryPath(3))
                    .concat("/participants")
                ) {
                  return true;
                }
                return false;
              },
            },
          ],
        },
      ],
    },
    testEvaluation: {
      data: [
        {
          id: "/",
          title: "Assessments",
          children: [
            {
              id: "/tests/assessments",
              title: "Tests",
              url: "/tests/assessments",
              children: [],
              isActive: function () {
                if (window.location.pathname == "/tests/assessments") {
                  return true;
                }
                return false;
              },
            },
          ],
        },
      ],
    },
    training: {
      data: [
        {
          id: "/",
          title: "Trainings",
          children: [
            {
              id: "/event/training/",
              title: "Overview",
              url: () => {
                return "/event/training/".concat($f.getQueryPath(3));
              },
              children: [],
              isActive: function () {
                return false;
                if (
                  window.location.pathname ==
                  "/event/training/".concat($f.getQueryPath(3))
                ) {
                  return true;
                }
                return false;
              },
            },
            {
              id: "/event/training/participants",
              title: "Participants",
              url: () => {
                return "/event/training/"
                  .concat($f.getQueryPath(3))
                  .concat("/participants");
              },
              children: [],
              hidden: function () {
                return $c.userIsMember();
              },
              isActive: function () {
                if (
                  window.location.pathname ==
                  "/event/training/"
                    .concat($f.getQueryPath(3))
                    .concat("/participants")
                ) {
                  return true;
                }
                return false;
              },
            },
            {
              id: "/event/training/files",
              title: "Learning Material",
              url: () => {
                return "/event/training/"
                  .concat($f.getQueryPath(3))
                  .concat("/files");
              },
              children: [],
              isActive: function () {
                if (
                  window.location.pathname ==
                  "/event/training/".concat($f.getQueryPath(3)).concat("/files")
                ) {
                  return true;
                }
                return false;
              },
            },
            {
              id: "/event/training/results",
              title: "Tests",
              url: () => {
                return "/event/training/"
                  .concat($f.getQueryPath(3))
                  .concat("/results");
              },
              hidden: function () {
                return $c.userIsInstructor();
              },
              children: [],
              isActive: function () {
                if (
                  window.location.pathname ==
                    "/event/training/"
                      .concat($f.getQueryPath(3))
                      .concat("/results") ||
                  window.location.pathname ==
                    "/event/training/".concat(
                      $f.getQueryPath(3),
                      "/test/",
                      $f.getQueryPath(5),
                      "/results"
                    )
                ) {
                  return true;
                }
                return false;
              },
            },
          ],
        },
      ],
    },
    conference: {
      private: {
        data: [
          {
            id: "/",
            title: "Conference",
            children: [
              {
                id: "/event/conference",
                title: "Reception",
                url: () => {
                  return "/event/conference/".concat($f.getQueryPath(3));
                },
                children: [],
                isActive: function () {
                  if (
                    window.location.pathname ==
                    "/event/conference/".concat($f.getQueryPath(3))
                  ) {
                    return true;
                  }
                  return false;
                },
              },
              {
                id: "/conference",
                title: "Live now",
                url: () => {
                  return "/event/conference/".concat(
                    $f.getQueryPath(3),
                    "/live"
                  );
                },
                children: [],
                isActive: function () {
                  if (
                    window.location.pathname ==
                    "/event/conference/".concat($f.getQueryPath(3), "/live")
                  ) {
                    return true;
                  }
                  return false;
                },
              },
              {
                id: "/event/conference/speakers",
                title: "Speakers",
                url: () => {
                  return "/event/conference/"
                    .concat($f.getQueryPath(3))
                    .concat("/speakers");
                },
                children: [],
                isActive: function () {
                  if (
                    window.location.pathname ==
                    "/event/conference/"
                      .concat($f.getQueryPath(3))
                      .concat("/speakers")
                  ) {
                    return true;
                  }
                  return false;
                },
              },
              {
                id: "/event/conference/partners",
                title: "Partners",
                url: () => {
                  return "/event/conference/"
                    .concat($f.getQueryPath(3))
                    .concat("/partners");
                },
                children: [],
                isActive: function () {
                  if (
                    window.location.pathname ==
                    "/event/conference/"
                      .concat($f.getQueryPath(3))
                      .concat("/partners")
                  ) {
                    return true;
                  }
                  return false;
                },
              },
              {
                id: "/event/conference/participants",
                title: "Participants",
                url: () => {
                  return "/event/conference/"
                    .concat($f.getQueryPath(3))
                    .concat("/participants");
                },
                children: [],
                hidden: () => {
                  return $c.userIsSimpleMember();
                },
                isActive: function () {
                  if (
                    window.location.pathname ==
                    "/event/conference/"
                      .concat($f.getQueryPath(3))
                      .concat("/participants")
                  ) {
                    return true;
                  }
                  return false;
                },
              },
              {
                id: "/event/conference/polls",
                title: "Polls",
                url: () => {
                  return "/event/conference/"
                    .concat($f.getQueryPath(3))
                    .concat("/polls");
                },
                children: [],
                hidden: () => {
                  return $c.userIsSimpleMember();
                },
                isActive: function () {
                  if (
                    window.location.pathname ==
                    "/event/conference/"
                      .concat($f.getQueryPath(3))
                      .concat("/polls")
                  ) {
                    return true;
                  }
                  return false;
                },
              },
              {
                id: "/event/conference/settings",
                title: "Stream keys",
                url: () => {
                  return "/event/conference/"
                    .concat($f.getQueryPath(3))
                    .concat("/settings");
                },
                children: [],
                hidden: () => {
                  return $c.userIsSimpleMember();
                },
                isActive: function () {
                  if (
                    window.location.pathname ==
                    "/event/conference/"
                      .concat($f.getQueryPath(3))
                      .concat("/settings")
                  ) {
                    return true;
                  }
                  return false;
                },
              },
            ],
          },
        ],
      },
      live: {
        data: [
          {
            id: "/event/conference/speakers",
            title: "Speakers",
            url: () => {
              return "/event/conference/"
                .concat($f.getQueryPath(3))
                .concat("/speakers");
            },
            children: [],
            isActive: function () {
              if (!$f.getQueryPath(3)) {
                return true;
              }
              if (
                window.location.pathname ==
                "/event/conference/"
                  .concat($f.getQueryPath(3))
                  .concat("/speakers")
              ) {
                return true;
              }
              return false;
            },
          },

          {
            id: "/event/conference/polls",
            title: "Polls",
            url: () => {
              return "/event/conference/"
                .concat($f.getQueryPath(3))
                .concat("/polls");
            },
            children: [],
            isActive: function () {
              if (
                window.location.pathname ==
                "/event/conference/".concat($f.getQueryPath(3)).concat("/polls")
              ) {
                return true;
              }
              return false;
            },
          },
          {
            id: "/event/conference/qna",
            title: "Q&A",
            url: () => {
              return "/event/conference/"
                .concat($f.getQueryPath(3))
                .concat("/qna");
            },
            children: [],
            isActive: function () {
              if (
                window.location.pathname ==
                "/event/conference/".concat($f.getQueryPath(3)).concat("/qna")
              ) {
                return true;
              }
              return false;
            },
          },
        ],
      },
    },
    spaces: {
      data: [
        {
          id: "/",
          title: "All spaces",
          children: [
            {
              id: "/spaces",
              title: "Overview",
              url: "/spaces",
              children: [],

              isActive: function () {
                if (window.location.pathname == this.url) {
                  return true;
                }
                return false;
              },
            },
            {
              id: "/spaces",
              title: "Spaces",
              url: "",
              children: [],
              isActive: function () {
                return false;
              },
              isCollapsed: function () {
                if ($f.getQueryPath(2)) {
                  return true;
                }
                return false;
              },
            },
          ],
        },
      ],
    },
  },
  FileIcons: {
    folder: "/uni/svg/folder.svg",
    recording: "/uni/svg/files/rec.svg",
    audiorecording: "/uni/svg/trainings/audiorec.svg",
    videorecording: "/uni/svg/trainings/videorec.svg",
    parentfolder: "/uni/svg/backarrow.svg",
    test: "/uni/svg/test.svg",
    testfill: "/uni/svg/test_fill.svg",
    "application/pdf": "/uni/svg/trainings/pdf.svg",
    "application/epub+zip": "/uni/svg/trainings/epub.svg",
    "video/mp4": "/uni/svg/trainings/video.svg",
    videoconf: "/uni/svg/video_conf.svg",
    "application/xlsx": "/uni/svg/files/excel.svg",
    "application/vnd.ms-excel": "/uni/svg/files/excel.svg",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      "/uni/svg/files/excel.svg",
    "application/vnd.ms-powerpoint": "/uni/svg/files/powerpoint.svg",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      "/uni/svg/files/powerpoint.svg",
    "application/doc": "/uni/svg/files/word.svg",
    "application/docx": "/uni/svg/files/word.svg",
    "application/msword": "/uni/svg/files/word.svg",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      "/uni/svg/files/word.svg",
    "text/plain": "/uni/svg/files/textfile.svg",
    "image/jpeg": "/uni/svg/files/image.svg",
    "image/jpg": "/uni/svg/files/image.svg",
    "image/png": "/uni/svg/files/image.svg",
    default: "/uni/svg/files/unknownfile.svg",
  },
  TrainingIcons: {
    completed: "/uni/svg/trainings/completed.svg",
    liveclass: "/uni/svg/conferences/conference.svg",
    videoconference: "/uni/svg/trainings/Liveclass.svg",
    liveclasscompleted: "/uni/svg/trainings/Liveclasscompleted.svg",
    pdf: "/uni/svg/trainings/pdf.svg",
    pdfcompleted: "/uni/svg/trainings/pdfviewed.svg",
    test: "/uni/svg/trainings/test.svg",
    exam: "/uni/svg/trainings/test.svg",
    testcompleted: "/uni/svg/trainings/testcompleted.svg",
    video: "/uni/svg/trainings/video.svg",
    videocompleted: "/uni/svg/trainings/Videocompleted.svg",
    audiorecording: "/uni/svg/trainings/audiorec.svg",
    videorecording: "/uni/svg/trainings/videorec.svg",
    epub: "/uni/svg/trainings/epub.svg",
    epubcompleted: "/uni/svg/trainings/epubcompleted.svg",
    default: "/uni/svg/files/unknownfile.svg",
  },
  FormIcons: {
    public: "/uni/svg/forms/public.svg",
    nopublic: "/uni/svg/forms/notpublic.svg",
  },
  FontFileIcons: {
    folder: "/uni/svg/folder.svg",
    recording: "fas fa-record-vinyl",
    audiorecording: "/uni/svg/files/audio.svg",
    parentfolder: "far fa-file-audio",
    test: "fas fa-pencil-alt",
    testfill: "fas fa-pencil-alt",
    "application/pdf": "far fa-file-pdf",
    "application/epub+zip": "/uni/svg/files/EPUB.svg",
    "video/mp4": "far fa-file-video",
    videoconf: "fas fa-video",
    "application/xlsx": "far fa-file-excel",
    "application/vnd.ms-excel": "far fa-file-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      "far fa-file-excel",
    "application/vnd.ms-powerpoint": "far fa-file-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      "far fa-file-powerpoint",
    "application/doc": "far fa-file-word",
    "application/docx": "far fa-file-word",
    "application/msword": "far fa-file-word",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      "far fa-file-word",
    "text/plain": "far fa-file-alt",
    "image/jpeg": "far fa-file-image",
    "image/jpg": "far fa-file-image",
    "image/png": "far fa-file-image",
    default: "far fa-file",
  },
  TimeDropdown: {
    data: [
      "07:15",
      "07:30",
      "07:45",
      "08:00",
      "08:15",
      "08:30",
      "08:45",
      "09:00",
      "09:15",
      "09:30",
      "09:45",
      "10:00",
      "10:15",
      "10:30",
      "10:45",
      "11:00",
      "11:15",
      "11:30",
      "11:45",
      "12:00",
      "12:15",
      "12:30",
      "12:45",
      "13:00",
      "13:15",
      "13:30",
      "13:45",
      "14:00",
      "14:15",
      "14:30",
      "14:45",
      "15:00",
      "15:15",
      "15:30",
      "15:45",
      "16:00",
      "16:15",
      "16:30",
      "16:45",
      "17:00",
      "17:15",
      "17:30",
      "17:45",
      "18:00",
      "18:15",
      "18:30",
      "18:45",
      "19:00",
      "19:15",
      "19:30",
      "19:45",
      "20:00",
      "20:15",
      "20:30",
      "20:45",
      "21:00",
      "21:15",
      "21:30",
      "21:45",
      "22:00",
      "22:15",
      "22:30",
      "22:45",
      "23:00",
      "23:15",
      "23:30",
      "23:45",
      "00:00",
      "00:15",
      "00:30",
      "00:45",
      "01:00",
      "01:15",
      "01:30",
      "01:45",
      "02:00",
      "02:15",
      "02:30",
      "02:45",
      "03:00",
      "03:15",
      "03:30",
      "03:45",
      "04:00",
      "04:15",
      "04:30",
      "04:45",
      "05:00",
      "05:15",
      "05:30",
      "05:45",
      "06:00",
      "06:15",
      "06:30",
      "06:45",
      "07:00",
    ],
  },
  Duration: {
    hours: {
      data: ["0", "1", "2", "3", "4", "5"],
    },
    minutes: {
      data: ["0", "1", "5", "10", "15", "20", "30", "45"],
    },
  },
  ReadingDuration: {
    hours: {
      data: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
    },
    minutes: {
      data: [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
      ],
    },
  },
};

export default Data;
