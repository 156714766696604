import React, { Component } from "react";
import parse from "html-react-parser";
import $c from "../../../../modules/functions";
import $f from "../../../../src";
import { Link } from "react-router-dom";
import JoinEventButton from "../../../../types/joinEventButton/joinEventButton";
import Modals from "../../../../modules/modals";

class TrainingOverview extends Component {
  constructor(props) {
    super(props);

    this.upcomingEvents = this.upcomingEvents.bind(this);
    this.progress = this.progress.bind(this);

    this.state = {
      data: [],
      loaded: false,
      training: {},
    };
  }

  componentDidMount() {
    const { data, module, view, specialProperties } = this.props;

    let templateConfiguration = view.template.configuration;

    const innerTemplates = ["header"];

    innerTemplates.map((itm, idx) => {
      let tmp = templateConfiguration[itm + "Template"]
        ? templateConfiguration[itm + "Template"]
        : () => {};
      this[itm + "Template"] = tmp.bind(this);
    });

    this.setState({
      specialProperties,
      data,
      loaded: true,
      view,
      module,
      training: this.props.response.data[0],
      nextEvent: this.props.response.data[0].nextEvent,
    });
  }

  upcomingEvents() {
    if (this.state.nextEvent) {
      let _this = this;

      let showOnAdmin = true;

      if (this.state.nextEvent.type === "exam" && $c.userIsAdmin()) {
        showOnAdmin = false;
      }

      if (this.state.nextEvent.type === "exam" && $c.userIsInstructor()) {
        return "";
      }

      return (
        <>
          <div className="display-4-no-margin mt-4 ">Next event</div>
          <div className="border-bottom pb-4 pt-4">
            <div className="d-inline-flex w-100">
              <div className="d-inline-flex">
                {$c.getTrainingIcon(this.state.nextEvent.type)}
              </div>
              <div className="ms-2 w-100">
                <span className=" d-block">
                  <strong>{this.state.nextEvent.title}</strong>
                </span>
                <span className="color-grey d-block">
                  {$c.formatDateFromTo(
                    this.state.nextEvent.from,
                    this.state.nextEvent.to
                  )}
                </span>
              </div>
              <div className="w-100 text-end">
                {showOnAdmin ? (
                  <JoinEventButton
                    data={this.state.nextEvent}
                    property={{
                      events: {
                        onClick: function () {
                          switch (_this.state.nextEvent.type) {
                            case "videoConference":
                              window.open(
                                "/liveclass/v-".concat(
                                  _this.state.nextEvent._id
                                ),
                                "_blank"
                              );
                              // window.location.replace(
                              //   "/liveclass/v-".concat(
                              //     _this.state.nextEvent._id
                              //   )
                              // );
                              break;

                            case "exam":
                              const userExamId =
                                _this.state.nextEvent?.userExamInfo?._id;
                              if (userExamId) {
                                window.location.replace(
                                  "/test/".concat(_this.state.nextEvent?._id)
                                );
                              } else {
                                Modals.events.startContinueTest({
                                  props: { data: _this.state.nextEvent },
                                });
                              }
                              break;
                          }
                        },
                      },
                      attributes: {
                        className: "btn btn-purple btn-sm m-auto",
                      },
                      caption: (args) => {
                        switch (this.state.nextEvent.type) {
                          case "videoConference":
                            return "Join class";
                            break;

                          case "exam":
                            const userExamId =
                              this.state.nextEvent?.userExamInfo?._id;

                            if (userExamId) {
                              return "Continue test";
                            }
                            return "Take test";
                            break;
                        }
                      },
                      configuration: {
                        eventNotStartedText: (
                          <span className="color-purple">
                            <strong>
                              {this.state.nextEvent.type == "exam"
                                ? "Test starts soon"
                                : "Class starts soon"}
                            </strong>
                          </span>
                        ),
                        eventHasEndedText: () => {
                          if ($c.testSubmitted(this.state.nextEvent.status)) {
                            switch (this.state.nextEvent?.status) {
                              case "Not Evaluated":
                                return (
                                  <span className="color-purple">
                                    <strong>Pending evaluation</strong>
                                  </span>
                                );
                                break;

                              default:
                                return (
                                  <button
                                    type="button"
                                    className="btn btn-purple btn-sm"
                                    onClick={() => {
                                      window.location.replace(
                                        "/event/test/".concat(
                                          this.state.nextEvent._id,
                                          "/result"
                                        )
                                      );
                                    }}
                                  >
                                    View results
                                  </button>
                                );
                                break;
                            }
                          } else {
                            return (
                              <span className="text-danger">
                                <strong>
                                  {this.state.nextEvent.type == "exam"
                                    ? "Test has ended"
                                    : "Class has ended"}
                                </strong>
                              </span>
                            );
                          }
                        },
                        fromDateProperty: "from",
                        endDateProperty: "to",
                        extraStartTime: 0,
                        hasEnded: () => {
                          return $c.testSubmitted(
                            this.state.nextEvent?.userExamInfo?.status
                          );
                        },
                        extraEndTime: 0,
                        eventInterval: 1000,
                        eventIntervalFunction: () => {},
                      },
                    }}
                  ></JoinEventButton>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </>
      );
    }
    return [];
  }

  progress(module) {
    if ($c.userIsSimpleMember()) {
      let full = module.full;
      let progress = module.progress;

      if (progress == 0) {
        return (
          <span className="alert bg-light-grey p-2 small rounded">
            Not Started (0/{full})
          </span>
        );
      }

      if (progress < full) {
        return (
          <span className="alert bg-light-blue small p-2 rounded">
            Ongoing ({progress}/{full})
          </span>
        );
      }

      if (progress == full) {
        return (
          <span className="alert bg-light-purple p-2 small rounded">
            Completed ({progress}/{full})
          </span>
        );
      }
    }

    return "";
  }

  render() {
    if (this.state.loaded) {
      const data = this.state.data[0];

      return (
        <React.Fragment>
          {this.headerTemplate()}
          <div className="display-3-no-bold border-bottom-1">Overview</div>
          <div className="w-md-75">
            {data.description.value ? (
              <div className=" mt-3 color-grey">
                {parse(data.description.value)}
              </div>
            ) : (
              <div className="mt-3 color-grey">[No description]</div>
            )}
            {this.upcomingEvents()}
            <div className="display-4-no-margin mt-4 mb-2">Modules</div>
            <div className="row ">
              {this.state.training?.modules?.map((el, idx) => {
                return (
                  <div className="col-md-4 p-2">
                    <div className="card h-100">
                      <div className="card-body">
                        <Link
                          to={"/event/training/".concat(
                            this.state.training._id,
                            "/module/",
                            el._id,
                            "/session/",
                            el.sessions[0]._id
                          )}
                        >
                          <h3 className="display-4-no-margin w-100">
                            {"Module".concat(" ", idx + 1)}
                          </h3>
                          <div className="color-grey">{parse(el.title)}</div>
                        </Link>
                      </div>
                      <div className="card-footer border-0 bg-white pb-3">
                        {this.progress(el)}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default TrainingOverview;
