import Form from "./templates/forms/form";
import Index from "./index";
import TopMenu from "./templates/topmenu";
import Login from "./templates/login/login";
import Table from "./templates/table";
import LeftMenu from "./templates/leftmenu";
import IndexMeeting from "./templates/chime/index";
import Header from "./templates/header";
import Custom from "./templates/custom";
import HorizontalNav from "./templates/horizontalnav";
import FilePreview from "./templates/filepreview";
import ModalWidget from "./modalwidget";
import MeetingOverview from "./templates/events/meetingOverview";
import Calendar from "./templates/events/calendar";
import EventModal from "./templates/events/eventModal";
import TestOverview from "./templates/events/testOverview";
import TestResult from "./templates/tests/testResult";
import EvaluatorTest from "./templates/tests/evaluatorTest";
import TrainingOverview from "./templates/trainings/trainingOverview";
import TrainingModuleOverview from "./templates/trainings/trainingModuleOverview";
import TrainingSessionOverview from "./templates/trainings/trainingSessionOverview";
import FormsOverview from "./templates/forms/formsOverview";
import FormResult from "./templates/forms/formResult";
import ConferenceOverview from "./templates/conference/private/conferenceOverview";
import ConferenceSettings from "./templates/conference/private/conferenceSettings";

import HorizontalNavScroll from "./templates/horizontalnavscroll";
import ConferenceLive from "./templates/conference/private/conferenceLive";
import ConferenceSpeakers from "./templates/conference/private/conferenceSpeakers";
import ConferencePartners from "./templates/conference/private/conferencePartners";
import VerifyAccount from "./templates/login/verify";
import CreatePassword from "./templates/login/createPassword";
import ResetPassword from "./templates/login/resetPassword";
import ChangePassword from "./templates/login/changePassword";
import UserTest from "./templates/tests/userTest";
import ScheduleTest from "./templates/events/scheduleTest";
import SelectBoxes from "./templates/events/selectBoxes";
import ScheduleTraining from "./templates/events/scheduleTraining";
import FileTable from "./templates/filetable";
import Homepage from "./templates/homepage";
import UserProfile from "./templates/userProfile";
import Spaces from "./templates/spaces/spaces";
import SpaceOverview from "./templates/spaces/spaceOverview";
import ConferenceLiveOverview from "./templates/conference/private/conferenceLiveOverview";
import Profile from "./templates/userSettings/profile";
import JobDetails from "./templates/userSettings/jobDetails";
import Security from "./templates/userSettings/security";
import Activity from "./templates/userSettings/activity";
import ConferencePollResults from "./templates/conference/private/conferencePollResults";
import UserForm from "./templates/forms/userForm";
import UserPublicForm from "./templates/forms/userPublicForm";
import ConferencePublicOverview from "./templates/conference/public/conferencePublicOverview";
import ConferenceOnDemand from "./templates/conference/private/conferenceOnDemand";

const Templates = {
  Form: () => {
    return Form;
  },
  ConferencePublicOverview: () => {
    return ConferencePublicOverview;
  },
  ConferenceOnDemand: () => {
    return ConferenceOnDemand;
  },
  UserForm: () => {
    return UserForm;
  },
  UserPublicForm: () => {
    return UserPublicForm;
  },
  Activity: () => {
    return Activity;
  },
  JobDetails: () => {
    return JobDetails;
  },
  Security: () => {
    return Security;
  },
  ConferencePollResults: () => {
    return ConferencePollResults;
  },
  ConferenceLiveOverview: () => {
    return ConferenceLiveOverview;
  },
  UserProfile: () => {
    return UserProfile;
  },
  Profile: () => {
    return Profile;
  },
  SpaceOverview: () => {
    return SpaceOverview;
  },
  Spaces: () => {
    return Spaces;
  },
  Index: () => {
    return Index;
  },
  Homepage: () => {
    return Homepage;
  },
  FileTable: () => {
    return FileTable;
  },
  TopMenu: () => {
    return TopMenu;
  },
  CreatePassword: () => {
    return CreatePassword;
  },
  ChangePassword: () => {
    return ChangePassword;
  },
  ResetPassword: () => {
    return ResetPassword;
  },
  ScheduleTraining: () => {
    return ScheduleTraining;
  },
  ScheduleTest: () => {
    return ScheduleTest;
  },
  SelectBoxes: () => {
    return SelectBoxes;
  },
  UserTest: () => {
    return UserTest;
  },
  Login: () => {
    return Login;
  },
  Table: () => {
    return Table;
  },
  LeftMenu: () => {
    return LeftMenu;
  },
  IndexMeeting: () => {
    return IndexMeeting;
  },
  Header: () => {
    return Header;
  },
  Custom: () => {
    return Custom;
  },
  ConferenceLive: () => {
    return ConferenceLive;
  },
  ConferenceSpeakers: () => {
    return ConferenceSpeakers;
  },
  ConferencePartners: () => {
    return ConferencePartners;
  },
  HorizontalNav: () => {
    return HorizontalNav;
  },
  HorizontalNavScroll: () => {
    return HorizontalNavScroll;
  },
  FilePreview: () => {
    return FilePreview;
  },
  ModalWidget: () => {
    return ModalWidget;
  },
  MeetingOverview: () => {
    return MeetingOverview;
  },
  Calendar: () => {
    return Calendar;
  },
  EventModal: () => {
    return EventModal;
  },
  TestOverview: () => {
    return TestOverview;
  },
  TestResult: () => {
    return TestResult;
  },
  EvaluatorTest: () => {
    return EvaluatorTest;
  },
  TrainingOverview: () => {
    return TrainingOverview;
  },
  TrainingModuleOverview: () => {
    return TrainingModuleOverview;
  },
  TrainingSessionOverview: () => {
    return TrainingSessionOverview;
  },
  FormsOverview: () => {
    return FormsOverview;
  },
  FormResult: () => {
    return FormResult;
  },
  ConferenceOverview: () => {
    return ConferenceOverview;
  },
  ConferenceSettings: () => {
    return ConferenceSettings;
  },
  VerifyAccount: () => {
    return VerifyAccount;
  },
};

export default Templates;
