import { Component } from "react";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    const { data, module, view } = this.props;

    document.body.style.paddingTop = "unset";

    this.setState({ loaded: true, data, module, view });
  }

  render() {
    if (this.state.loaded) {
      const data = this.state.data[0];

      return (
        <main className="container-fluid h-100">
          <header>
            <nav className="navbar navbar-expand-md  ">
              <div className="container-fluid  viewport-padding  color-strong-grey">
                <span className="display-5 mb-0">
                  {window["COMPANY_LOGO"]()}
                </span>
              </div>
            </nav>
          </header>
          <div className="wrapper d-flex h-100">
            <div className=" w-100 m-auto">
              <div className="viewport-padding fullpage-form main-height m-auto">
                <div className="">
                  <div className="">
                    <h1 className="display-3-no-line-height">
                      Change password
                    </h1>
                    <span className="d-block color-grey pb-1438rem">
                      The password must contain at least 8 characters, 1 letter
                      and 1 number.
                    </span>
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="exampleInputEmail1" className="label-top">
                      New password
                    </label>
                    {data.password.component}
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="exampleInputEmail1" className="label-top">
                      Confirm password
                    </label>
                    {data.confirmPassword.component}
                  </div>
                  {data.changePassword.component}
                </div>
              </div>
            </div>
          </div>
        </main>
      );
    }

    return "";
  }
}

export default ChangePassword;
