import React, { Component } from "react";
import parse from "html-react-parser";
import $c from "../../../../../modules/functions";
import $f from "../../../../../src";
import { Link } from "react-router-dom";

import PerfectScrollbar from "react-perfect-scrollbar";
import JoinEventButton from "../../../../../types/joinEventButton/joinEventButton";

class ConferenceLiveOverview extends Component {
  constructor(props) {
    super(props);

    this.setActiveTab = this.setActiveTab.bind(this);
    this.getAllLiveSessions = this.getAllLiveSessions.bind(this);

    this.state = {
      data: [],
      loaded: false,
      training: {},
    };
  }

  componentDidMount() {
    const { data, module, view, specialProperties, response } = this.props;

    let templateConfiguration = view.template.configuration;

    const innerTemplates = ["header"];

    innerTemplates.map((itm, idx) => {
      let tmp = templateConfiguration[itm + "Template"]
        ? templateConfiguration[itm + "Template"]
        : () => {};
      this[itm + "Template"] = tmp.bind(this);
    });

    this.setState({
      activeTab: 0,
      specialProperties,
      data,
      loaded: true,
      view,
      module,
      response,
      liveSessions: [],
    });

    // document.body.style.paddingTop = "3.7rem";
  }

  setActiveTab(index) {
    this.setState({ activeTab: index });
  }

  getAllLiveSessions() {
    const data = this.state.response.data[0];

    const liveSessions = [];

    data?.days?.forEach((day) => {
      day.sessions.forEach((session) => {
        const sessionStatus = $c.checkEventDateStatus(
          session.from,
          session.to,
          "",
          ""
        );

        session.day = day;

        if (sessionStatus == "join") {
          liveSessions.push(session);
        }
      });
    });

    return liveSessions;
  }

  render() {
    if (this.state.loaded) {
      const data = this.state.response.data[0];

      return (
        <React.Fragment>
          {this.headerTemplate()}
          <div className="pb-5 container-fluid me-0 ms-0 ps-0">
            <button
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasExample"
              aria-controls="offcanvasExample"
              className="display-lt-md-block mb-3 btn btn-sm btn-white"
            >
              Menu
            </button>

            <div>
              <h2 className="display-3-no-bold header-ellipsis">
                Live sessions
              </h2>
              <h5 className="display-5 color-grey">
                In this page you can view and join the live sessions
              </h5>
            </div>

            <div className="mt-3 ms-0 ps-0">
              {this.getAllLiveSessions().map((el, idx) => {
                const bgcolor = idx % 2 == 0 ? "bg-light-grey" : "";

                return (
                  <div className={"d-flex ".concat(bgcolor)}>
                    <Link
                      className="p-5 border-end display-gt-md-block"
                      style={{ width: "15rem" }}
                      to={"/live/conference/"
                        .concat(data._id)
                        .concat("/day/")
                        .concat(el.day._id)
                        .concat("/session/")
                        .concat(el._id)}
                    >
                      <h3 className="display-4-no-margin">
                        {$c.formatTime(el.from)}
                      </h3>
                      <span className="color-grey">
                        {$c.formatDateNoYear(el.from)}
                      </span>
                      <div className="">
                        <JoinEventButton
                          data={el}
                          property={{
                            attributes: {
                              className: "btn btn-purple btn-sm mt-2",
                            },
                            events: {
                              onClick: function () {
                                window.open(
                                  "/live/conference/"
                                    .concat(data._id)
                                    .concat("/day/")
                                    .concat(el.day._id)
                                    .concat("/session/")
                                    .concat(el._id),
                                  "_blank"
                                );
                                // this.props.history.push(
                                //   "/live/conference/"
                                //     .concat(data._id)
                                //     .concat("/day/")
                                //     .concat(el.day._id)
                                //     .concat("/session/")
                                //     .concat(el._id)
                                // );
                              },
                            },
                            caption: "Watch now",
                            configuration: {
                              eventNotStartedText: "",
                              eventHasEndedText: "",
                              fromDateProperty: "from",
                              endDateProperty: "to",
                              extraStartTime: 0,
                              extraEndTime: 0,
                            },
                          }}
                        ></JoinEventButton>
                      </div>
                    </Link>
                    <Link
                      className="w-100 p-5 "
                      to={"/live/conference/"
                        .concat(data._id)
                        .concat("/day/")
                        .concat(el.day._id)
                        .concat("/session/")
                        .concat(el._id)}
                      target="_blank"
                    >
                      <div className="border-bottom display-lt-md-block pb-3 mb-3">
                        <div className="d-flex">
                          <div>
                            <h3 className="display-4-no-margin">
                              {$c.formatTime(el.from)}
                            </h3>
                            <span className="color-grey">
                              {$c.formatDateNoYear(el.from)}
                            </span>
                          </div>
                          <div className="m-auto">
                            <JoinEventButton
                              data={el}
                              property={{
                                attributes: {
                                  className: "btn btn-purple  mt-2",
                                },
                                events: {
                                  onClick: function () {
                                    window.open(
                                      "/live/conference/"
                                        .concat(data._id)
                                        .concat("/day/")
                                        .concat(el.day._id)
                                        .concat("/session/")
                                        .concat(el._id),
                                      "_blank"
                                    );
                                    // this.props.history.push(
                                    //   "/live/conference/"
                                    //     .concat(data._id)
                                    //     .concat("/day/")
                                    //     .concat(el.day._id)
                                    //     .concat("/session/")
                                    //     .concat(el._id)
                                    // );
                                  },
                                },
                                caption: "Watch now",
                                configuration: {
                                  eventNotStartedText: "",
                                  eventHasEndedText: "",
                                  fromDateProperty: "from",
                                  endDateProperty: "to",
                                  extraStartTime: 0,
                                  extraEndTime: 0,
                                },
                              }}
                            ></JoinEventButton>
                          </div>
                        </div>
                      </div>
                      <h3 className="display-4">{el.title}</h3>
                      <div className="color-grey">{parse(el.description)}</div>
                      <div className="w-75">
                        {el.speakers.map((sel, sidx) => {
                          if (sel.firstName) {
                            return $c.createAvatar(
                              sel.profileImage,
                              sel.firstName,
                              sel.lastName,
                              "avatar-large p-2",
                              sel.jobTitle
                            );
                          }
                        })}
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default ConferenceLiveOverview;
