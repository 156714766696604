import React, { Component } from "react";
import $f from "../../../src";
import { GridScripts } from "../scripts/script";
import { FileDrop } from "react-file-drop";

class FileTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loaded: false,
    };

    this.createHeaders = this.createHeaders.bind(this);
    this.createBody = this.createBody.bind(this);

    this.onFrameDragEnter = function () {};
    this.onFrameDragLeave = function () {};
    this.onFrameDrop = function () {};
    this.onDragOver = function () {};
    this.onDragLeave = function () {};
    this.onDrop = this.onDrop.bind(this);
    this.onTargetClick = this.onTargetClick.bind(this);

    this.fileInputRef = React.createRef();

    this.onFileInputChange = this.onFileInputChange.bind(this);
  }

  onDrop(files) {
    window["fileUploader"].onDrop(
      files,
      this.state.filetableConfiguration,
      this.state.module
    );
  }

  onFileInputChange = (event) => {
    const { files } = event.target;

    this.onDrop(files);
  };

  onTargetClick() {
    this.fileInputRef.current.click();
  }

  componentDidMount() {
    const { data, module, view, specialProperties } = this.props;

    let templateConfiguration = view.template.configuration;

    const innerTemplates = ["header", "bottom", "empty"];

    innerTemplates.map((itm, idx) => {
      let tmp = templateConfiguration[itm + "Template"]
        ? templateConfiguration[itm + "Template"]
        : () => {};
      this[itm + "Template"] = tmp.bind(this);
    });

    let filetableConfiguration =
      view.template.configuration.filetableConfiguration;

    this.setState({
      specialProperties,
      data,
      loaded: true,
      view,
      module,
      filetableConfiguration,
    });

    window["fileUploader"].setState({ module: module });

    GridScripts();
  }

  createHeaders() {
    let headers = [];
    let data = this.state.data[0];

    const tableConfiguration =
      this.state.view.template.configuration.tableConfiguration;

    if (tableConfiguration.showTableHeaders !== "false") {
      if (data) {
        Object.keys(data).forEach((itm, idx) => {
          if (data[itm].showOnTemplate) {
            let attributes = [];
            if (tableConfiguration) {
              if (tableConfiguration.headers) {
                if (tableConfiguration.headers[itm]) {
                  if (tableConfiguration.headers[itm]["attributes"]) {
                    if (
                      typeof tableConfiguration.headers[itm]["attributes"] ===
                      "function"
                    ) {
                      attributes = tableConfiguration.headers[itm][
                        "attributes"
                      ](data, itm);
                    } else {
                      attributes =
                        tableConfiguration.headers[itm]["attributes"];
                    }
                  }
                }
              }
            }

            headers.push(
              <th
                scope="col"
                key={$f.key()}
                {...attributes}
                style={{ minWidth: "3rem" }}
              >
                {data[itm].property.label}
              </th>
            );
          }
        });

        return (
          <thead key={$f.key()}>
            <tr key={$f.key()}>{headers}</tr>
          </thead>
        );
      }
    }
    return "";
  }

  createBody() {
    let rows = [];

    let data = this.state.data;

    const tableConfiguration =
      this.state.view.template.configuration.tableConfiguration;

    data.forEach((itm, idx) => {
      let cells = [];

      Object.keys(itm).forEach((citm) => {
        if (itm[citm].showOnTemplate) {
          let itmComponent = itm[citm].component;
          let attributes = [];
          if (tableConfiguration) {
            if (tableConfiguration.cellTemplates) {
              if (tableConfiguration.cellTemplates[citm]) {
                itmComponent = tableConfiguration.cellTemplates[citm](
                  itmComponent,
                  itm,
                  this
                );
              }
            }
            if (tableConfiguration.cell) {
              if (tableConfiguration.cell[citm]) {
                if (tableConfiguration.cell[citm]["attributes"]) {
                  attributes = tableConfiguration.cell[citm]["attributes"];
                }
              }
            }
          }

          cells.push(
            <td key={$f.key()} {...attributes}>
              {itmComponent}
            </td>
          );
        }
      });

      rows.push(<tr key={$f.key()}>{cells}</tr>);
    });

    return <tbody key={$f.key()}>{rows}</tbody>;
  }

  render() {
    if (this.state.loaded) {
      return (
        <React.Fragment>
          {this.headerTemplate(this)}
          <FileDrop
            onFrameDragEnter={(event) => this.onFrameDragEnter(event)}
            onFrameDragLeave={(event) => this.onFrameDragLeave(event)}
            onFrameDrop={(event) => this.onFrameDrop(event)}
            onDragOver={(event) => this.onDragOver(event)}
            onDragLeave={(event) => this.onDragLeave(event)}
            onDrop={(files, event) => this.onDrop(files, event)}
          >
            {this.state.data.length > 0 ? (
              <div className="scroll-x " style={{ minHeight: "600px" }}>
                <table className="table table-hover">
                  {this.createHeaders()}
                  {this.createBody()}
                </table>
              </div>
            ) : (
              this.emptyTemplate(this)
            )}
          </FileDrop>
          <input
            onChange={this.onFileInputChange}
            ref={this.fileInputRef}
            onClick={(event) => {
              event.target.value = null;
            }}
            type="file"
            style={{ display: "none" }}
            multiple
          />
          {this.bottomTemplate(this)}
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default FileTable;
