import { Link } from "react-router-dom";
import $f from "../../src";
import JoinEventButton from "../../types/joinEventButton/joinEventButton";
import OptionsForHeader from "../../types/optionsForHeader/optionsForHeader";
import EmptyStates from "../emptyStates";
import $c from "../functions";
import Modals from "../modals";

const OptionsForHeaderButton = function (args) {
  let property = {
    configuration: {
      options: {
        edit: function (args) {
          if (
            args.props.data.organizer._id === $c.getUserId() ||
            $c.userIsAdmin()
          ) {
            let newargs = {
              props: { data: { ...args.props.data } },
              action: "update",
            };

            return {
              label: "Edit",
              onClick: function () {
                $f.getValue(Modals.events.editMeeting, newargs);
              },
            };
          }
        },
        copyLink: function (args) {
          return {
            label: "Copy link",
            onClick: function () {
              $c.copyToClipboard(
                window["host"]("/event/meeting/".concat(args.props.data._id))
              );
              $f.createNotification({
                type: "success",
                message: "Link copied!",
              });
            },
          };
        },
        downloadICS: function (args) {
          return {
            label: "Download ics",
            onClick: function () {
              const event = {
                eventType: "meeting",
                from: args.props.data.from,
                to: args.props.data.to,
                title: args.props.data.title,
                url: "/event/meeting/".concat(args.props.data._id),
              };

              $c.createICS(event);
            },
          };
        },
        delete: function (args) {
          if (
            args.props.data.organizer._id === $c.getUserId() ||
            $c.userIsAdmin()
          ) {
            let newargs = { ...args };
            newargs.callback = function () {
              window.location.replace("/events/meetings");
            };
            return {
              label: <span className="text-danger">Delete</span>,
              onClick: function () {
                $f.getValue(Modals.events.deleteMeeting, newargs);
              },
            };
          }
        },
      },
    },
  };

  return (
    <span className="me-2">
      <OptionsForHeader data={args.data[0]} property={property} />
    </span>
  );
};

const Meetings = {
  properties: (args) => {
    const { editable, isDetails } = args;

    return {
      _id: {
        label: "",
        path: "_id",
        type: "hidden",
        labelPosition: "none",
        events: {},
        attributes: {},
      },
      title: {
        label: "Title",
        type: "text",
        editable: editable,
        events: {},
        validation: "required",
        attributes: {
          className: "form-control",
          placeholder: "Type title",
        },
      },
      optionsfortable: {
        label: "",
        type: "optionsfortable",
        configuration: {
          options: {
            open: function (args) {
              return {
                label: "Open",
                onClick: function () {
                  args.props.history.push(
                    "/event/meeting/".concat(args.props.data._id)
                  );
                },
              };
            },
            edit: function (args) {
              if (
                args.props.data.organizer._id === $c.getUserId() ||
                $c.userIsAdmin()
              ) {
                let newargs = {
                  props: { data: { ...args.props.data } },
                  action: "update",
                };

                return {
                  label: "Edit",
                  onClick: function () {
                    $f.getValue(Modals.events.editMeeting, newargs);
                  },
                };
              }
            },
            copyLink: function (args) {
              return {
                label: "Copy link",
                onClick: function () {
                  $c.copyToClipboard(
                    window["host"](
                      "/event/meeting/".concat(args.props.data._id)
                    )
                  );
                  $f.createNotification({
                    type: "success",
                    message: "Link copied!",
                  });
                },
              };
            },
            downloadICS: function (args) {
              return {
                label: "Download ics",
                onClick: function () {
                  const event = {
                    eventType: "meeting",
                    from: args.props.data.from,
                    to: args.props.data.to,
                    title: args.props.data.title,
                    url: "/event/meeting/".concat(args.props.data._id),
                  };

                  $c.createICS(event);
                },
              };
            },
            delete: function (args) {
              if (
                args.props.data.organizer._id === $c.getUserId() ||
                $c.userIsAdmin()
              ) {
                return {
                  label: <span className="text-danger">Delete</span>,
                  onClick: function () {
                    $f.getValue(Modals.events.deleteMeeting, args);
                  },
                };
              }
            },
          },
        },
      },
      fromtoDatetime: {
        label: "Availability",
        type: "fromtodatetime",
        events: {},
        formatValue: (value, row) => {
          return { from: row.from, to: row.to };
        },
        attributes: {
          className: "form-control",
        },
      },
      description: {
        label: "Description",
        type: "editor",
        editable: editable,
        events: {},
        attributes: {
          className: "form-control",
          placeholder: "Type description",
        },
      },

      organizer: {
        label: "Organizer",
        type: "text",
        editable: editable,
        events: {},
        attributes: {},
        formatValue: (value, row) => {
          return row.organizer
            ? $c.createAvatar(
                row.organizer.profileImg,
                row.organizer.firstName,
                row.organizer.lastName
              )
            : value;
        },
      },
      organizers: {
        label: "Hosts",
        type: () => {
          return editable ? "text" : "tags";
        },
        editable: editable,
        configuration: {
          type: "nogroups",
        },
        events: {},
        attributes: {
          placeholder: "Add hosts",
        },
        formatValue: (value, row) => {
          let editableValue = [];

          if (editableValue) {
            value?.forEach((el) => {
              editableValue.push({
                id: el._id,
                name: el["firstName"]?.concat(
                  " ",
                  el["lastName"],
                  " - ",
                  el["email"]
                ),
                type: "participant",
              });
            });
          }

          return editable
            ? editableValue
            : $c.createAvatar(
                row.organizer.profileImg,
                row.organizer.firstName,
                row.organizer.lastName,
                "avatar-default"
              );
        },
      },
      participants: {
        label: "Participants",
        type: "tags",
        editable: editable,
        configuration: {
          type: "all",
        },
        events: {},
        attributes: {
          className: "form-control",
        },
      },
      files: {
        label: isDetails ? "" : "Attached files",
        type: "upload",
        editable: editable,
        configuration: {
          wrapperClassName: isDetails ? "d-none" : "",
          url: function (args) {
            if (args.props.data._id) {
              return "/agenda-files/meeting/".concat(args.props.data._id);
            }
            return "/agenda-file";
          },
          type: "meeting",
          fileDataProperty: function (args) {
            if (args.props.data._id) {
              return "files";
            } else {
              return "file";
            }
          },
          allowedExtensions: ["pdf", "png", "jpeg", "jpg", "mp4"],
          multipleFiles: true,
        },
        caption: function (_this) {
          return (
            <div className="w-100 text-center p-4 ">
              <img src="/uni/svg/files/upload.svg" className="svg-sm mb-2" />
              <span className="d-block mb-1">
                <strong>
                  <span
                    className="color-purple"
                    role="button"
                    onClick={_this.onTargetClick}
                  >
                    Click to upload
                  </span>{" "}
                  or drag and drop
                </strong>
              </span>
              <span className="color-grey d-block">
                PDF, PNG, JPEG, MP4, (max 1GB)
              </span>
            </div>
          );
        },
        events: {},
        attributes: {
          className: isDetails ? "d-none" : "form-control bg-light-grey ",
        },
      },
      reminder: {
        label: "Reminder",
        type: "reminder",
        events: {},
        path: "reminders",
        // formatValue: (value, row) => {
        //   console.log(value);
        //   try {
        //     return JSON.stringify(row.reminders[0]);
        //   } catch (e) {
        //     return "";
        //   }
        // },
        attributes: {
          className: "form-control form-select ",
        },
      },
      options: {
        label: "Options",
        type: "label",
        labelPosition: "none",
        attributes: {
          className: "display-4-no-margin color-grey  mt-2",
        },
      },
      recordingOptions: {
        label: "",
        type: "recordingOptions",
        labelPosition: "none",
        path: "options",
        attributes: {},
        events: {},
      },
      participantsCount: {
        label: "",
        type: "text",
        editable: editable,
        events: {},
        attributes: {},
        formatValue: (value, row) => {
          return row.participants ? row.participants.length : "0";
        },
      },
      filesCount: {
        label: "",
        type: "text",
        editable: editable,
        events: {},
        attributes: {},
        formatValue: (value, row) => {
          return row.files ? row.files.length : "0";
        },
      },
      date: {
        label: "Date",
        type: "text",
        editable: editable,
        events: {},
        attributes: {},
        formatValue: (value, row) => {
          return $c.formatDateFromTo(row.from, row.to);
        },
      },
    };
  },
  specialProperties: () => {
    return {
      header: {
        type: "template",
        configuration: {
          title: "Meetings",
          subTitle: "View all available meetings",
          template: "Header",
          buttons: (args) => {
            return [
              <button
                type="button"
                className="btn btn-purple  btn-sm  "
                onClick={() => {
                  $f.getValue(Modals.events.editMeeting, {
                    title: "Schedule meeting",
                    api: "new",
                    action: "create",
                  });
                }}
              >
                Schedule
              </button>,
            ];
          },
        },
        events: {},
      },
      headerOverview: {
        type: "template",
        configuration: {
          title: (args) => {
            return args.data.data[0].title;
          },
          //subTitle: "Meeting event",
          template: "Header",
          buttons: (args) => {
            if (
              $f.getQueryPath(4) == "participants" &&
              args.data.data[0].organizer._id == $c.getUserId()
            ) {
              return [
                OptionsForHeaderButton(args.data),
                <button
                  type="button"
                  className="btn btn-purple  btn-sm  "
                  onClick={() => {
                    $f.getValue(Modals.participants.addAll, {
                      title: "Add people",
                      api: "new",
                      actionType: "meeting",
                      actionId: args.data.data[0]._id,
                      actionSection: "meetingParticipants",
                    });
                  }}
                >
                  Add people
                </button>,
              ];
            }
            if ($f.getQueryPath(4) == "files") {
              const allMeetingPeople = [];

              args.data.data[0]?.organizers?.forEach((el) => {
                allMeetingPeople.push(el._id);
              });

              args.data.data[0]?.participants?.forEach((el) => {
                allMeetingPeople.push(el._id);
              });

              if (allMeetingPeople.indexOf($c.getUserId()) >= 0) {
                return [
                  OptionsForHeaderButton(args.data),
                  <button
                    type="button"
                    className="btn btn-purple  btn-sm  "
                    onClick={() => {
                      const fileTemplateRef =
                        args.module.refs.files.current.refTemplate.current;

                      fileTemplateRef.onTargetClick();
                    }}
                  >
                    Upload files
                  </button>,
                ];
              }
            }
            const property = {
              events: {
                onClick: function () {
                  window.open(
                    "/meeting/m-".concat(this.props.data._id),
                    "_blank"
                  );
                  // window.location.replace(
                  //   "/meeting/m-".concat(this.props.data._id)
                  // );
                },
              },
              attributes: {
                className: "btn btn-purple btn-sm",
              },
              caption: "Join meeting",
              configuration: {
                eventNotStartedText: (
                  <span className="color-purple">
                    <strong>Meeting starts soon</strong>
                  </span>
                ),
                eventHasEndedText: (
                  <span className="text-danger">
                    <strong>Meeting has ended</strong>
                  </span>
                ),
                fromDateProperty: "from",
                endDateProperty: "to",
                extraStartTime: 0,
                extraEndTime: 0,
                eventInterval: 1000,
                eventIntervalFunction: () => {},
              },
            };

            return [
              OptionsForHeaderButton(args.data),
              <JoinEventButton
                data={args.data?.data[0]}
                property={property}
              ></JoinEventButton>,
            ];
          },
        },
        events: {},
      },
      eventsNav: {
        type: "template",
        configuration: {
          data: (resp) => {
            let output = [];
            let data = [
              { name: "All meetings", filter: "" },
              // { name: "Upcoming", filter: "upcoming" },
              // { name: "Ongoing", filter: "ongoing" },
              // { name: "Ended", filter: "completed" },
            ];
            try {
              data.forEach((el, idx) => {
                let isActive = false;
                if ($f.getParameterByName("filter") == el.filter) {
                  isActive = true;
                } else {
                  if (!$f.getParameterByName("filter")) {
                    if (idx == 0) {
                      isActive = true;
                    }
                  }
                }

                output.push({
                  url:
                    idx == 0
                      ? "/events/meetings"
                      : "/events/meetings?filter=" + el.filter,
                  title: el.name,
                  isActive: () => {
                    return isActive;
                  },
                });
              });
            } catch (e) {}
            return output;
          },
          template: "HorizontalNavScroll",
        },
      },
      participants: {
        label: "",
        type: "module",
        configuration: {
          module: "ParticipantTable",
          view: "list",
        },
      },
      pagination: {
        type: "pagination",
        configuration: {
          start: function (props) {
            return 0;
          },
          offset: "25",
          total: function (props) {
            return props.props.data.pagination.total;
          },
          current: function (props) {
            return props.props.data.pagination.currentPage;
          },
          replaceAttribute: "start",
        },
        events: {},
      },
      files: {
        label: "",
        type: "module",
        configuration: function (args) {
          return {
            module: "FilesTable",
            view: "list",
            isOwner: args.props.data.data[0].organizer._id == $c.getUserId(),
          };
        },
      },
    };
  },
  views: {
    list: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/event/meeting/?start={start}&offset={offset}{filters}";
          },
          dataPath: () => {},
          replace: {
            start: 0,
            offset: 25,
            filters: function () {
              switch ($f.getParameterByName("filter")) {
                case "completed":
                  return (
                    "&filters=" +
                    JSON.stringify([
                      { key: "to", operator: "lt", value: new Date() },
                    ])
                  );
                  break;

                default:
                  return "";
                  break;

                case "ongoing":
                  return (
                    "&filters=" +
                    JSON.stringify([
                      { operator: "is", key: "event", value: "today" },
                    ])
                  );
                  break;

                case "upcoming":
                  return (
                    "&filters=" +
                    JSON.stringify([
                      { key: "to", operator: "gt", value: new Date() },
                    ])
                  );
                  break;
              }
            },
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Table";
        },
        configuration: {
          emptyTemplate: function (arg) {
            return EmptyStates.events.meetings.default();
          },
          headerTemplate: function () {
            return (
              <>
                {this.state.specialProperties.header.component}
                {this.state.specialProperties.eventsNav.component}
              </>
            );
          },
          bottomTemplate: function () {
            return this.state.specialProperties.pagination.component;
          },
          tableConfiguration: {
            headers: {
              title: {
                attributes: {
                  className: "w-50",
                },
              },
            },
            cellTemplates: {
              title: (comp, itm) => {
                return (
                  <Link
                    to={"/event/meeting/".concat(itm._id.value)}
                    className="color-grey"
                  >
                    {comp}
                  </Link>
                );
              },
            },
          },
          includeProperties: () => {
            return ["title", "optionsfortable", "organizer", "date"];
          },
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
    overview: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/meeting/{id}";
          },
          dataPath: () => {},
          replace: {
            id: () => {
              return $f.getQueryPath(3);
            },
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          if (["participants", "files"].indexOf($f.getQueryPath(4)) >= 0) {
            return "Custom";
          }

          return "MeetingOverview";
        },
        configuration: {
          topTemplate: function () {
            switch ($f.getQueryPath(4)) {
              case "participants":
                return this.state.specialProperties.participants.component;
              case "files":
                return this.state.specialProperties.files.component;
            }

            return "";
          },
          headerTemplate: function () {
            return this.state.specialProperties.headerOverview.component;
          },
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
    detail: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/meeting/{id}";
          },
          dataPath: () => {},
          replace: {
            id: () => {
              return $f.getQueryPath(3);
            },
          },
          headers: {},
          mapper: () => {},
        },
        new: {
          url: () => {
            return { data: [{}] };
          },
          dataPath: () => {},
          replace: {
            id: () => {
              return $f.getQueryPath(3);
            },
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Form";
        },
        configuration: {
          includeProperties: (args) => {
            if (args.data[0]._id) {
              return [
                "_id",
                "title",
                "fromtoDatetime",
                "description",
                "organizers",
                // "participants",
                "files",
                "reminder",
                "options",
                "recordingOptions",
              ];
            } else {
              return [
                "_id",
                "title",
                "fromtoDatetime",
                "description",
                "participants",
                "organizers",
                "files",
                "reminder",
                "options",
                "recordingOptions",
              ];
            }
          },
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: (args) => {
          if (args._id) {
            return { editable: true, isDetails: true };
          }
          return { editable: true, isDetails: false };
        },
      },
    },
  },
};

export default Meetings;
