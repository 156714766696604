const RouterMap = {
  "/": {
    middle: { module: "Homepage", view: "list" },
    topmenu: { module: "TopMenu", view: "list" },
  },
  "/contacts": {
    middle: { module: "Contacts", view: "list" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/user/:id": {
    middle: { module: "Contacts", view: "detail" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/user/:id/job-details": {
    middle: { module: "Contacts", view: "detail" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/user/:id/security": {
    middle: { module: "Contacts", view: "detail" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/user/:id/activity": {
    middle: { module: "Contacts", view: "detail" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/contacts/space/:id": {
    middle: { module: "Contacts", view: "spaceUsers" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/contacts/space/:id/group/:gid": {
    middle: { module: "Contacts", view: "spaceUsers" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/files": {
    middle: { module: "PersonalFiles", view: "perosnalFiles" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/files/library": {
    middle: { module: "CompanyFiles", view: "companyFiles" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/files/favorites": {
    middle: { module: "Favorites", view: "favorites" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/files/space/:id": {
    middle: { module: "SpaceFiles", view: "overview" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/files/space/:id/group/:sid": {
    middle: { module: "SpaceFiles", view: "overview" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/files/preview/:id": {
    fullpage: { module: "FilePreview", view: "filePreview" },
  },
  "/agenda-files/preview/:id": {
    fullpage: { module: "FilePreview", view: "filePreview" },
  },
  "/events/meetings": {
    middle: { module: "Meetings", view: "list" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/event/meeting/:id": {
    middle: { module: "Meetings", view: "overview" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/event/meeting/:id/participants": {
    middle: { module: "Meetings", view: "overview" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/event/meeting/:id/files": {
    middle: { module: "Meetings", view: "overview" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/events/trainings": {
    middle: { module: "Trainings", view: "list" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/event/training/:id": {
    middle: { module: "Trainings", view: "overview" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/event/training/:id/files": {
    middle: { module: "Trainings", view: "overview" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/event/training/:id/results": {
    middle: { module: "Trainings", view: "overview" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/event/training/:id/test/:testid/results": {
    middle: { module: "Trainings", view: "overview" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/event/training/:id/files/module/:mid": {
    middle: { module: "Trainings", view: "overview" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/event/training/:id/files/module/:mid/session/:sid": {
    middle: { module: "Trainings", view: "overview" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/event/training/:id/participants": {
    middle: { module: "Trainings", view: "overview" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/event/training/:id/module/:mid": {
    middle: { module: "Trainings", view: "overview" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/event/training/:id/module/:mid/session/:sid": {
    middle: { module: "Trainings", view: "overview" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/events/conferences": {
    middle: { module: "Conferences", view: "list" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/event/conference/:id": {
    middle: { module: "Conferences", view: "overview" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/conference/:id": {
    fullpage: { module: "Conferences", view: "public" },
  },
  "/event/conference/:id/participants": {
    middle: { module: "Conferences", view: "overview" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/event/conference/:id/speakers": {
    middle: { module: "Conferences", view: "overview" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/event/conference/:id/live": {
    middle: { module: "Conferences", view: "overview" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/event/conference/:id/partners": {
    middle: { module: "Conferences", view: "overview" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/event/conference/:id/polls": {
    middle: { module: "Conferences", view: "overview" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/event/conference/:id/settings": {
    middle: { module: "Conferences", view: "overview" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/live/conference/:id/day/:did/session/:sid": {
    fullpage: { module: "Conferences", view: "live" },
  },
  "/ondemand/conference/:id/day/:did/session/:sid": {
    fullpage: { module: "Conferences", view: "ondemand" },
  },
  "/events/conferences/partners": {
    middle: { module: "Conferences", view: "list" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/events/tests": {
    middle: { module: "Tests", view: "list" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/event/test/:id": {
    middle: { module: "Tests", view: "overview" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/event/test/:id/results": {
    middle: { module: "Tests", view: "results" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/event/test/:id/participants": {
    middle: { module: "Tests", view: "detail" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/event/test/:id/results": {
    middle: { module: "Tests", view: "detail" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/event/test/:id/result": {
    fullpage: { module: "TestResults", view: "result" },
  },
  "/tests/assessments": {
    middle: { module: "TestEvaluations", view: "list" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/tests/assessments/:id": {
    fullpage: { module: "TestEvaluations", view: "result" },
  },
  "/test/:id": {
    fullpage: { module: "UserTest", view: "test" },
  },
  "/templates/tests": {
    middle: { module: "TestTemplates", view: "list" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/templates/trainings": {
    middle: { module: "TrainingTemplates", view: "list" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/login": {
    fullpage: { module: "Login", view: "list" },
  },
  "/account-verify": {
    fullpage: { module: "Login", view: "otp" },
  },
  "/confirm-password": {
    fullpage: { module: "Login", view: "confirmPassword" },
  },
  "/change-password": {
    fullpage: { module: "Login", view: "changePassword" },
  },
  "/register": {
    fullpage: { module: "Login", view: "confirmPassword" },
  },
  "/forgot-password": {
    fullpage: { module: "Login", view: "resetPassword" },
  },
  "/meeting/:id": {
    meeting: { module: "Meeting", view: "list" },
  },
  "/trainingConference/:id": {
    meeting: { module: "Meeting", view: "list" },
  },
  "/liveclass/:id": {
    meeting: { module: "Meeting", view: "list" },
  },
  "/events/calendar": {
    middle: { module: "Calendar", view: "list" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/events/calendar/:type": {
    middle: { module: "Calendar", view: "list" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/forms": {
    middle: { module: "Forms", view: "list" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/form/:id": {
    fullpage: { module: "Forms", view: "userForm" },
  },
  "/forms/:id": {
    middle: { module: "Forms", view: "overview" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/public-form/": {
    fullpage: { module: "Forms", view: "publicForm" },
  },
  "/forms/:id/results": {
    middle: { module: "Forms", view: "overview" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/forms/result/:id": {
    fullpage: { module: "FormsResults", view: "result" },
  },
  "/spaces": {
    middle: { module: "Spaces", view: "list" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/space/:id": {
    middle: { module: "Spaces", view: "overview" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/space/:id/group/:gid": {
    middle: { module: "Spaces", view: "overview" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/space/:id/group/:gid/files": {
    middle: { module: "Spaces", view: "overview" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
  "/space/:id/group/:gid/participants": {
    middle: { module: "Spaces", view: "overview" },
    topmenu: { module: "TopMenu", view: "list" },
    leftmenu: { module: "LeftMenu", view: "general" },
  },
};

export default RouterMap;
