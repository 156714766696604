import { Component } from "react";

import { withRouter } from "react-router";

class ButtonWithRouter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      caption: "",
      excludeFromModuleValues: true,
    };
  }

  onChange(e) {
    this.setState({
      value: e.target.value,
    });
  }

  componentDidMount() {
    let events = {};

    Object.keys(this.props.property.events)?.forEach((element, idx) => {
      this[element] = this.props.property.events[element];
      this[element] = this[element].bind(this);
      events[element] = this[element];
    });

    this.setState({
      loaded: true,
      events: events,
      attributes: { ...this.props.property.attributes },
      value: this.props.value,
      caption: this.props.property.caption,
      editable: this.props.property.editable === false ? false : true,
      useCustomUI: this.props.property.useCustomUI,
    });
  }

  editable() {
    return (
      <button type="button" {...this.state.events} {...this.state.attributes}>
        {this.state.caption}
      </button>
    );
  }

  render() {
    if (this.state.loaded) {
      return this.editable();
    }

    return "";
  }
}

export default withRouter(ButtonWithRouter);
