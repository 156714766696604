import React, { Component } from "react";
import $f from "../../../src";
import { GridScripts } from "../scripts/script";

class Table extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loaded: false,
    };

    this.createHeaders = this.createHeaders.bind(this);
    this.createBody = this.createBody.bind(this);
    // this.header = this.header.bind(this);
  }

  componentDidMount() {
    const { data, module, view, specialProperties } = this.props;

    let templateConfiguration = view.template.configuration;

    const innerTemplates = ["header", "bottom", "empty"];

    innerTemplates.map((itm, idx) => {
      let tmp = templateConfiguration[itm + "Template"]
        ? templateConfiguration[itm + "Template"]
        : () => {};
      this[itm + "Template"] = tmp.bind(this);
    });

    this.setState({
      specialProperties,
      data,
      loaded: true,
      view,
      module,
    });

    GridScripts();
  }

  createHeaders() {
    let headers = [];
    let data = this.state.data[0];

    const tableConfiguration =
      this.state.view.template.configuration.tableConfiguration;

    if (tableConfiguration.showTableHeaders !== "false") {
      if (data) {
        Object.keys(data).forEach((itm, idx) => {
          if (data[itm].showOnTemplate) {
            let attributes = [];
            if (tableConfiguration) {
              if (tableConfiguration.headers) {
                if (tableConfiguration.headers[itm]) {
                  if (tableConfiguration.headers[itm]["attributes"]) {
                    if (
                      typeof tableConfiguration.headers[itm]["attributes"] ===
                      "function"
                    ) {
                      attributes = tableConfiguration.headers[itm][
                        "attributes"
                      ](data, itm);
                    } else {
                      attributes =
                        tableConfiguration.headers[itm]["attributes"];
                    }
                  }
                }
              }
            }

            headers.push(
              <th
                scope="col"
                key={$f.key()}
                {...attributes}
                style={{ minWidth: "3rem" }}
              >
                {data[itm].property.label}
              </th>
            );
          }
        });

        return (
          <thead key={$f.key()}>
            <tr key={$f.key()}>{headers}</tr>
          </thead>
        );
      }
    }
    return "";
  }

  createBody() {
    let rows = [];

    let data = this.state.data;

    const tableConfiguration =
      this.state.view.template.configuration.tableConfiguration;

    data.forEach((itm, idx) => {
      let cells = [];

      Object.keys(itm).forEach((citm) => {
        if (itm[citm].showOnTemplate) {
          let itmComponent = itm[citm].component;
          let attributes = [];
          if (tableConfiguration) {
            if (tableConfiguration.cellTemplates) {
              if (tableConfiguration.cellTemplates[citm]) {
                itmComponent = tableConfiguration.cellTemplates[citm](
                  itmComponent,
                  itm,
                  this
                );
              }
            }
            if (tableConfiguration.cell) {
              if (tableConfiguration.cell[citm]) {
                if (tableConfiguration.cell[citm]["attributes"]) {
                  attributes = tableConfiguration.cell[citm]["attributes"];
                }
              }
            }
          }

          cells.push(
            <td key={$f.key()} {...attributes}>
              {itmComponent}
            </td>
          );
        }
      });

      let rowAttributes = [];
      if (tableConfiguration.rowAttributes) {
        rowAttributes = $f.getValue(tableConfiguration.rowAttributes, itm);
      }

      rows.push(
        <tr key={$f.key()} {...rowAttributes}>
          {cells}
        </tr>
      );
    });

    return <tbody key={$f.key()}>{rows}</tbody>;
  }

  render() {
    if (this.state.loaded) {
      return (
        <React.Fragment>
          {this.headerTemplate(this)}
          <div className="scroll-x " style={{ minHeight: "600px" }}>
            {this.state.data.length > 0 ? (
              <table className="table table-hover">
                {this.createHeaders()}
                {this.createBody()}
              </table>
            ) : (
              this.emptyTemplate(this)
            )}
          </div>
          {this.bottomTemplate(this)}
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default Table;
