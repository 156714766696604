import { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import EmptyStates from "../../../../modules/emptyStates";
import $c from "../../../../modules/functions";
import $f from "../../../../src";
import Header from "../header";

class Activity extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      edit: false,
      hasChanges: false,
    };
  }

  componentDidMount() {
    const { data, module, view, specialProperties, response } = this.props;

    let templateConfiguration = view.template.configuration;

    const innerTemplates = ["header", "top", "bottom"];

    let { firstName, lastName, _id, status, groups, events } = response
      ? response.data[0]
      : {
          firstName: "",
          lastName: "",
          _id: "",
          status: "",
          groups: [],
          events: {},
        };

    innerTemplates.map((itm, idx) => {
      let tmp = templateConfiguration[itm + "Template"]
        ? templateConfiguration[itm + "Template"]
        : () => {};
      this[itm + "Template"] = tmp.bind(this);
    });

    this.setState({
      specialProperties,
      data,
      loaded: true,
      view,
      module,
      _id,
      firstName,
      groups,
      events,
      lastName,
      status,
      response,
    });
  }

  render() {
    if (this.state.loaded) {
      if ($c.userIsMember() && $c.getUserId() !== $f.getQueryPath(2)) {
        return "Page not found";
      }

      let spaceTab = [];

      let eventTab = [];

      if (this.state.status === "deactivated") {
        spaceTab = EmptyStates.account.deactivated();
        eventTab = EmptyStates.account.deactivated();
      } else {
        if (!this.state.groups) {
          spaceTab = EmptyStates.account.spaces();
        } else {
          if (this.state.groups.length < 1) {
            spaceTab = EmptyStates.account.spaces();
          }
          this.state.groups?.forEach((el) => {
            spaceTab.push(
              <div className="col-md-4 p-2">
                <div className="card h-100">
                  <div className="card-body">
                    <Link to={"/space/".concat(el.rootSpace._id)}>
                      <h3 className="display-4-no-margin w-100">
                        {el.rootSpace.name}
                      </h3>
                    </Link>
                    <div className="color-grey">
                      <span>Groups: </span>
                      {el.groups.map((group) => {
                        return (
                          <Link
                            to={"/space/".concat(
                              el.rootSpace._id,
                              "/group/",
                              group._id
                            )}
                            className="btn-link me-2"
                          >
                            {group.name}
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                  <div className="card-footer border-0 bg-white pb-3 color-grey">
                    <Link to={"/space/".concat(el.rootSpace._id)}>
                      Go to space<i className="fas fa-chevron-right ms-2"></i>
                    </Link>
                  </div>
                </div>
              </div>
            );
          });
        }

        let eventsCount = 0;

        if (this.state.events) {
          Object.keys(this.state.events)?.forEach((el) => {
            eventsCount = eventsCount + this.state.events[el].length;
          });

          if (!eventsCount) {
            eventTab = EmptyStates.account.events();
          } else {
            Object.keys(this.state.events)?.forEach((el) => {
              let title = el;
              let subTitle = "";
              let goto = el;
              let eventPath = "";

              switch (el) {
                case "exam":
                  title = "Tests";
                  subTitle = "Your tests will appear here";
                  goto = "test";
                  eventPath = "/event/test/";
                  break;

                case "training":
                  title = "Trainings";
                  goto = "training";
                  subTitle = "Your trainings will appear here";
                  eventPath = "/event/training/";
                  break;
              }

              eventTab.push(
                <div className="mt-3">
                  <h2 className="display-4">{title}</h2>
                  {this.state.events[el].length < 1 ? (
                    <div className="color-grey">{subTitle}</div>
                  ) : (
                    ""
                  )}
                  <div className="row">
                    {this.state.events[el].map((event) => {
                      return (
                        <div className="col-md-4 p-2">
                          <div className="card h-100">
                            <div className="card-body">
                              <Link to={eventPath.concat(event._id)}>
                                <h3 className="display-4-no-margin w-100">
                                  {event.title}
                                </h3>
                              </Link>
                              <div className="color-grey"></div>
                            </div>
                            <div className="card-footer border-0 bg-white pb-3 color-grey">
                              <Link to={eventPath.concat(event._id)}>
                                Go to {goto}
                                <i className="fas fa-chevron-right ms-2"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
              // eventTab.push(
              //   <div className="col-md-4">
              //     <div className="card h-100">
              //       <div className="card-body">
              //         <Link to={"/space/".concat(el.rootSpace._id)}>
              //           <h3 className="display-4-no-margin w-100">
              //             {el.rootSpace.name}
              //           </h3>
              //         </Link>
              //         <div className="color-grey">
              //           <span>Groups: </span>
              //           {el.groups.map((group) => {
              //             return (
              //               <Link
              //                 to={"/space/".concat(
              //                   el.rootSpace._id,
              //                   "/group/",
              //                   group._id
              //                 )}
              //                 className="btn-link me-2"
              //               >
              //                 {group.name}
              //               </Link>
              //             );
              //           })}
              //         </div>
              //       </div>
              //       <div className="card-footer border-0 bg-white pb-3 color-grey">
              //         Go to space<i className="fas fa-chevron-right ms-2"></i>
              //       </div>
              //     </div>
              //   </div>
              // );
            });
          }
        } else {
          eventTab = EmptyStates.account.events();
        }
      }

      return (
        <>
          <Header
            data={{
              title: this.state.firstName.concat(" ", this.state.lastName),
              subTitle:
                $c.getUserId() === this.state._id
                  ? "In this page you'll find spaces and events that you are associated."
                  : "In this page you'll find spaces and events associated with this user",
              buttons: (args) => {
                if ($c.userIsAdmin() || $c.getUserId() === this.state._id) {
                  return [
                    this.state.status == "deactivated" ? (
                      <span className="p-2 me-2 text-danger">
                        User deactivated
                      </span>
                    ) : null,
                  ];
                }
              },
            }}
          ></Header>
          <div className="mt-3">
            <div className="mb-3 w-md-85">
              <ul className="nav nav-tabs navbar-expand">
                <li className="nav-item">
                  <span
                    className="nav-link color-strong-grey active "
                    role="button"
                    data-bs-toggle="tab"
                    data-bs-target={"#activity-spaces"}
                  >
                    Spaces
                  </span>
                </li>
                <li>
                  <span
                    className="nav-link color-strong-grey  "
                    role="button"
                    data-bs-toggle="tab"
                    data-bs-target={"#activity-events"}
                  >
                    Events
                  </span>
                </li>
              </ul>
              <div className="tab-content mt-3 ">
                <div
                  className="tab-pane fade active show"
                  id="activity-spaces"
                  role="tabpanel"
                  aria-labelledby="activity-spaces"
                >
                  <div className="row">{spaceTab}</div>
                </div>
                <div
                  className="tab-pane fade "
                  id="activity-events"
                  role="tabpanel"
                  aria-labelledby="activity-events"
                >
                  {eventTab}
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }

    return "";
  }
}

export default withRouter(Activity);
