import moment from "moment";
import { Link } from "react-router-dom";
import $f from "../../src";
import JoinEventButton from "../../types/joinEventButton/joinEventButton";
import $c from "../functions";

const Calendar = {
  properties: (args) => {
    const { editable } = args;

    return {};
  },
  specialProperties: () => {
    return {
      header: {
        type: "template",
        configuration: {
          title: "Calendar",
          subTitle: "",
          template: "Header",
        },
        events: {},
      },
      events: {
        type: "text",
        events: {},
      },
      filters: {
        type: "template",
        configuration: {
          buttons: function (args) {
            if ($c.userIsMember()) {
              return [
                <button
                  type="button"
                  onClick={() => {}}
                  className="btn btn-sm btn-white me-2"
                >
                  Today
                </button>,
                <button
                  type="button"
                  onClick={() => {}}
                  className="btn btn-sm btn-white me-2"
                >
                  Monthly view
                </button>,
              ];
            }

            return [
              <button
                type="button"
                onClick={() => {}}
                className="btn btn-sm btn-white me-2"
              >
                Today
              </button>,
              <button
                type="button"
                onClick={() => {}}
                className="btn btn-sm btn-white me-2"
              >
                Monthly view
              </button>,
              <button
                type="button"
                onClick={() => {}}
                className="btn btn-sm btn-purple"
              >
                Schedule
              </button>,
            ];
          },
          data: (resp) => {
            let data = [
              {
                url: "/events/calendar",
                title: "All events",
                isActive: () => {
                  return window.location.pathname === "/events/calendar";
                },
              },
              {
                url: "/events/calendar/meetings",
                title: "Meetings",
                isActive: () => {
                  return (
                    window.location.pathname === "/events/calendar/meetings"
                  );
                },
              },
              {
                url: "/events/calendar/trainings",
                title: "Trainings",
                isActive: () => {
                  return (
                    window.location.pathname === "/events/calendar/trainings"
                  );
                },
              },
              {
                url: "/events/calendar/tests",
                title: "Tests",
                isActive: () => {
                  return window.location.pathname === "/events/calendar/tests";
                },
              },
              {
                url: "/events/calendar/conferences",
                title: "Conferences",
                isActive: () => {
                  return (
                    window.location.pathname === "/events/calendar/conferences"
                  );
                },
              },
            ];

            return data;
          },
          template: "HorizontalNav",
        },
      },
    };
  },
  views: {
    list: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "{url}?start={start}&offset={offset}&filters={filters}";
          },
          dataPath: () => {},
          replace: {
            start: 0,
            offset: 1000,
            url: function () {
              let url = "all";

              return "/event/all";
            },
            filters: function () {
              let filters = { value: "exams,trainings,meetings,streams" };

              let urlValue = $f.getQueryPath("3") ? $f.getQueryPath("3") : null;

              switch (urlValue) {
                case "tests":
                  filters = { value: "exams" };
                  break;
                case "trainings":
                  filters = { value: "trainings" };
                  break;
                case "meetings":
                  filters = { value: "meetings" };
                  break;
                case "conferences":
                  filters = { value: "streams" };
                  break;
              }

              let filter = [
                filters,
                {
                  key: "from",
                  operator: "gt",
                  value: moment()
                    .startOf("month")
                    .subtract(7, "days")
                    .toISOString(),
                },
                {
                  key: "to",
                  operator: "lt",
                  value: moment().endOf("month").add(7, "days").toISOString(),
                },
              ];

              return JSON.stringify(filter);
            },
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Calendar";
        },
        configuration: {
          headerTemplate: function () {
            return <>{this.state.specialProperties.header.component}</>;
          },
          tableConfiguration: {
            headers: {
              title: {
                attributes: {
                  className: "w-50",
                },
              },
            },
            cellTemplates: {
              title: (comp, itm) => {
                return (
                  <Link
                    to={"/event/meeting/".concat(itm._id.value)}
                    className="color-grey"
                  >
                    {comp}
                  </Link>
                );
              },
            },
          },
          includeProperties: () => {
            return ["title", "organizer", "date"];
          },
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
    eventModal: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "{url}";
          },
          dataPath: () => {},
          replace: {},
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "EventModal";
        },
        configuration: {
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
  },
};

export default Calendar;
