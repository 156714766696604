import { Link } from "react-router-dom";
import $f from "../../src";
import $c from "../functions";
import Modals from "../modals";

const ConferencesPolls = {
  properties: (args) => {
    const { editable } = args;

    return {
      _id: {
        label: "",
        path: "_id",
        type: "hidden",
        labelPosition: "none",
        events: {},
        attributes: {},
      },
      title: {
        label: "Name",
        type: "text",
        editable: editable,
        events: {},
        attributes: {
          className: "form-control",
          placeholder: "Type poll name",
        },
      },
      question: {
        label: "",
        type: "conferencepolls",
        editable: editable,
        events: {},
        attributes: {},
      },
    };
  },
  specialProperties: () => {},
  views: {
    detail: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/streams/{conferenceId}/pollAnswer/{pollId}";
            // return "/streams/{conferenceId}/day/{dayId}/session/{sessionId}/pollAnswer/{pollId}";
          },
          dataPath: () => {},
          replace: {},
          headers: {},
          mapper: () => {},
        },
        new: {
          url: () => {
            return {
              data: [{ title: "", question: { text: "", answers: [{}, {}] } }],
            };
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Form";
        },

        configuration: {
          includeProperties: (args) => {
            if (args.data[0]._id) {
              return ["_id", "title", "question"];
            }
            return ["title", "question"];
          },

          primaryKey: "_id",
        },
        properties: () => {
          return { editable: true };
        },
      },
    },
  },
};

export default ConferencesPolls;
