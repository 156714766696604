import parse from "html-react-parser";
import moment from "moment";
import { Component } from "react";
import $c from "../../modules/functions";
import $f from "../../src";

class ConferenceVideo extends Component {
  constructor(props) {
    super(props);

    this.intervalFunction = () => {};
    this.checkVideoInfo = this.checkVideoInfo.bind(this);
    this.getLiveInfo = this.getLiveInfo.bind(this);
    this.parentChange = function () {};

    this.state = {
      loaded: false,
      from: null,
      to: null,
      timeoutFunction: null,
    };
  }

  componentWillUnmount() {
    clearTimeout(this.checkVideoInfo);

    if (this.state.timeoutFunction) {
      clearTimeout(this.state.timeoutFunction);
    }

    //  clearTimeout(this.getLiveInfo);
  }

  componentDidMount() {
    let events = [];

    // this.refresh = this.refresh.bind(this);

    this.getLiveInfo = this.getLiveInfo.bind(this);

    if (this.props.property.parentChange) {
      this.parentChange = this.props.property.parentChange.bind(this);
    }

    const configuration = { ...this.props.property.configuration };

    window["streamURL"] = null;

    const from = configuration["fromDateProperty"]
      ? this.props.data[configuration["fromDateProperty"]]
      : this.props.data.from;

    const to = configuration["toDateProperty"]
      ? this.props.data[configuration["toDateProperty"]]
      : this.props.data.to;

    const extraStartTime = configuration.extraStartTime
      ? configuration.extraStartTime
      : 0;

    const extraEndTime = configuration.extraEndTime
      ? $f.getValue(configuration.extraEndTime, this)
      : 0;

    const title = configuration.title;

    const eventStatus = $c.checkEventDateStatus(
      from,
      to,
      extraStartTime,
      extraEndTime
    );

    this.setState({
      loaded: true,
      events: events,
      configuration: configuration,
      caption: this.props.property.caption,
      attributes: { ...this.props.property.attributes },
      hostEnter: false,
      attendees: null,
      liveInfoTimeout: null,
      title: title,
      eventStatus: eventStatus,
      extraStartTime: extraStartTime,
      eventHostNotEnterYet: configuration.eventHostNotEnterYet
        ? configuration.eventHostNotEnterYet
        : 0,
      eventNotStartedText: configuration.eventNotStartedText
        ? $f.getValue(configuration.eventNotStartedText)
        : "",
      eventHasEndedText: configuration.eventHasEndedText
        ? $f.getValue(configuration.eventHasEndedText)
        : "",
      extraEndTime: extraEndTime,
      from: from,
      to: to,
      videoWrapperKey: $f.key(),
    });

    setTimeout(this.checkVideoInfo, 0);
  }

  getLiveInfo() {
    let _this = this;
    $f.fetch(this.state.configuration.infoURL, "GET", null, {}, {}, (resp) => {
      if (resp.status == 200) {
        if (resp.data) {
          if (resp.data.data) {
            const info = resp.data.data[0];

            if (info.currentSession) {
              if (info.currentSession.isLive) {
                _this.setState({
                  hostEnter: info.currentSession.isLive,
                  attendees: info.currentSession.viewerCount,
                });
              } else {
                _this.setState({
                  hostEnter: false,
                  attendees: 0,
                });
              }
              setTimeout(() => {
                _this.parentChange(_this);
              }, 0);
            }
          }
        }
      }
    });

    this.checkVideoInfo();
  }

  checkVideoInfo() {
    let _this = this;

    if (this.state.from && this.state.to) {
      switch (this.state.eventStatus) {
        case "ended":
          break;

        case "pending":
          const duration = moment.duration(
            moment(this.state.from).diff(moment())
          );
          const hours = duration.asHours();

          let timeout = 60000;

          if (hours >= 24) {
            timeout = 86400000;
          } else if (hours < 0) {
            timeout = 6000;
          } else if (hours < 1) {
            timeout = 60000;
          } else if (hours < 24) {
            timeout = 1800000;
          }

          this.setState({
            timeoutFunction: setTimeout(this.getLiveInfo, timeout),
          });

          break;

        case "join":
          this.setState({
            timeoutFunction: setTimeout(this.getLiveInfo, 5000),
          });
          break;

        default:
          break;
      }
    }
  }

  render() {
    if (this.state.loaded) {
      if (this.state.from && this.state.to) {
        switch (this.state.eventStatus) {
          case "ended":
            return this.state.eventHasEndedText;

          case "join":
            if (!this.state.hostEnter) {
              return this.state.eventHostNotEnterYet;
            }

            if (!window["streamURL"]) {
              window["streamURL"] = this.state.configuration.playbackUrl;
              $f.addCSS(
                "https://cdnjs.cloudflare.com/ajax/libs/video.js/7.11.4/video-js.css",
                true
              );
              $f.addScript(
                "https://cdnjs.cloudflare.com/ajax/libs/video.js/7.11.4/video.min.js",
                true
              );
              $f.addScript(
                "https://player.live-video.net/1.3.1/amazon-ivs-videojs-tech.min.js",
                true
              );
              $f.addScript(
                "https://player.live-video.net/1.3.1/amazon-ivs-quality-plugin.min.js",
                true
              );
              $f.addScript("/conference/conference.js", true);
            }

            return (
              <div key="video-wrapper">
                <video
                  id="amazon-ivs-videojs"
                  className="video-js vjs-4-3 vjs-big-play-centered rounded conference-video"
                  controls
                  autoPlay
                  playsInline
                  src={this.state.configuration.playbackUrl}
                ></video>
                <div className="d-flex p-2 mt-2">
                  <div>
                    Session:{" "}
                    <span className="display-4">{this.state.title}</span>
                  </div>
                  <span className="d-block ms-auto"></span>
                  <span className="d-block">
                    <span
                      style={{ backgroundColor: "red" }}
                      className="p-2 me-2 rounded text-white"
                    >
                      Live
                    </span>
                    {this.state.attendees >= 0
                      ? this.state.attendees?.toString().concat(" are watching")
                      : ""}
                  </span>
                </div>
              </div>
            );

          case "pending":
            return this.state.eventNotStartedText;

          default:
            return "";
        }
      }
    }

    return "";
  }
}

export default ConferenceVideo;
