import { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import $f from "../../../src";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

class HorizontalNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    const { data, module, view, configuration } = this.props;

    this.setState({ loaded: true, data, module, view, configuration });
  }

  render() {
    if (this.state.loaded) {
      const moreOptions = [];
      let moreTitle = "More";

      return (
        <>
          <ul className="nav nav-tabs navbar-expand">
            {this.state.data.data.map((itm, idx) => {
              const active = itm.isActive() ? "active" : "";

              if (idx < 3) {
                return (
                  <li className="nav-item ">
                    <Link
                      className={"nav-link color-strong-grey nav-ellipsis ".concat(
                        active
                      )}
                      aria-current={itm.url}
                      to={itm.url}
                      title={itm.title}
                    >
                      {itm.title}
                    </Link>
                  </li>
                );
              } else {
                if (this.state.data.data.length == 4) {
                  return (
                    <li className="nav-item ">
                      <Link
                        className={"nav-link color-strong-grey nav-ellipsis ".concat(
                          active
                        )}
                        aria-current={itm.url}
                        to={itm.url}
                        title={itm.title}
                      >
                        {itm.title}
                      </Link>
                    </li>
                  );
                } else {
                  if (!active) {
                    moreOptions.push(
                      <li className="nav-item ">
                        <Link
                          className={"dropdown-item  color-strong-grey text-ellipsis ".concat(
                            active
                          )}
                          aria-current={itm.url}
                          to={itm.url}
                          title={itm.title}
                        >
                          {itm.title}
                        </Link>
                      </li>
                    );
                  } else {
                    moreTitle = itm.title;
                  }
                }
              }
            })}

            {moreOptions.length > 0 ? (
              <li className="nav-item dropdown">
                <a
                  className={"nav-link dropdown-toggle color-strong-grey ".concat(
                    moreTitle != "More" ? "active" : ""
                  )}
                  data-bs-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-expanded="false"
                >
                  {moreTitle}
                </a>
                <ul className="dropdown-menu">{moreOptions}</ul>
              </li>
            ) : (
              ""
            )}

            <li className="nav-item ms-auto display-lt-md-none  mt-auto mb-auto">
              {this.state.configuration?.buttons?.map((el) => {
                return $f.getValue(el, this);
              })}
            </li>
          </ul>

          <div className="display-lt-md-block mb-2 mt-2">
            {this.state.configuration?.buttons?.map((el) => {
              return $f.getValue(el, this);
            })}
          </div>
        </>
      );
    }

    return "";
  }
}

export default withRouter(HorizontalNav);
