import Text from "./text/text";
import Template from "./template/template";
import Module from "./module/module";
import OptionsForTable from "./optionsForTable/optionsForTable";
import TextArea from "./textarea/textarea";
import Button from "./button/button";
import JoinEventButton from "./joinEventButton/joinEventButton";
import Password from "./password/password";
import Multiple from "./multiple/multiple";
import Hidden from "./hidden/hidden";
import Tabs from "./tabs/tabs";
import Editor from "./editor/editor";
import Upload from "./upload/upload";
import Switch from "./switch/switch";
import Label from "./label/label";
import Select from "./select/select";
import FromToDatetime from "./fromtodatetime/fromtodatetime";
import Reminder from "./reminder/reminder";
import ExamQuestions from "./examquestions/examquestions";
import TrainingModules from "./trainingmodules/trainingmodules";
import Tags from "./tags/tags";
import FormQuestions from "./formquestions/formquestions";
import Duration from "./duration/duration";
import AttendeesSubmittedTest from "./attendeessubmittedtest/attendeessubmittedtest";
import ConferenceVideo from "./conferenceVideo/conferenceVideo";
import AttendeeTestGrade from "./attendeetestgrade/attendeetestgrade";
import Number from "./number/number";
import RecordingOptions from "./recordingOptions/recordingOptions";
import ConferenceDays from "./conferencedays/conferencedays";
import FromTotime from "./fromtotime/fromtotime";
import Breadcrumb from "./breadcrumb/breadcrumb";
import Pagination from "./pagination/pagination";
import InviteContactsManually from "./invitecontactsmanually/invitecontactsmanually";
import FromToDate from "./fromtodate/fromtodate";
import PassingGrade from "./passingGrade/passingGrade";
import OptionsForHeader from "./optionsForHeader/optionsForHeader";
import ReadingDuration from "./readingDuration/readingDuration";
import SpaceAdvanceFields from "./spaceAdvanceFields/spaceAdvanceFields";
import ConferencePolls from "./conferencepolls/conferencepolls";
import FormOptions from "./formOptions/formOptions";
import GeneralSearch from "./generalsearch/generalsearch";
import Profile from "./profile/profile";
import ButtonWithRouter from "./buttonWithRouter/buttonWithRouter";

const Types = {
  text: Text,
  buttonwithrouter: ButtonWithRouter,
  generalsearch: GeneralSearch,
  profile: Profile,
  template: Template,
  formoptions: FormOptions,
  module: Module,
  passinggrade: PassingGrade,
  fromtodate: FromToDate,
  conferencepolls: ConferencePolls,
  optionsforheader: OptionsForHeader,
  spaceadvancefields: SpaceAdvanceFields,
  readingduration: ReadingDuration,
  conferencedays: ConferenceDays,
  invitecontactsmanually: InviteContactsManually,
  pagination: Pagination,
  fromtotime: FromTotime,
  breadcrumb: Breadcrumb,
  optionsfortable: OptionsForTable,
  attendeetestgrade: AttendeeTestGrade,
  recordingOptions: RecordingOptions,
  textarea: TextArea,
  number: Number,
  button: Button,
  joineventbutton: JoinEventButton,
  password: Password,
  hidden: Hidden,
  multiple: Multiple,
  tabs: Tabs,
  editor: Editor,
  upload: Upload,
  switch: Switch,
  label: Label,
  select: Select,
  fromtodatetime: FromToDatetime,
  reminder: Reminder,
  examquestions: ExamQuestions,
  trainingmodules: TrainingModules,
  tags: Tags,
  formquestions: FormQuestions,
  duration: Duration,
  attendeessubmittedtest: AttendeesSubmittedTest,
  conferencevideo: ConferenceVideo,
};

export default Types;
