import React, { Component } from "react";
import $f from "../../src";
import parse from "html-react-parser";
import $c from "../../modules/functions";
import { Link } from "react-router-dom";
import "./style.css";

class GeneralSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };

    this.onInput = this.onInput.bind(this);
    this.searchOpenResult = this.searchOpenResult.bind(this);
    this.searchFeatures = this.searchFeatures.bind(this);
  }

  validator() {}

  componentDidMount() {
    let events = [];

    this.setState({
      loaded: true,
      events: events,
      showResults: false,
      show: true,
      results: null,
      value: "",
    });

    var ignoreClickOnMeElement = document.getElementsByClassName(
      "general-search-wrapper"
    );

    let _this = this;
    document.addEventListener("click", function (event) {
      if (ignoreClickOnMeElement[0]) {
        var isClickInsideElement = ignoreClickOnMeElement[0].contains(
          event.target
        );
        if (!isClickInsideElement) {
          _this.setState({ showResults: false });
        }
      }
    });
  }

  onInput(e) {
    let _this = this;
    $f.fetch(
      "/searchResults/{query}",
      "GET",
      null,
      {},
      {
        query: () => {
          return e.target.value.replace("/", "%2F");
        },
      },
      (resp) => {
        let formattedData = {};

        if (resp.status == 200) {
          let data = resp.data.data;
          if (data) {
            data.forEach((itm, idx) => {
              let type = itm.type;
              itm.rawTitle = itm.title;

              if (type.indexOf("file") >= 0) {
                itm.title = parse($c.boldString(itm.title, e.target.value));
                type = "file";
              } else if (type === "user") {
                type = "user";
                itm.mimetype = "user-profile";

                itm.fullName = parse(
                  $c.boldString(itm.fullName, e.target.value)
                );
                itm.email = parse($c.boldString(itm.email, e.target.value));
                itm.title = (
                  <span className="text-black">
                    {itm.fullName}
                    <span className="ml-3 small text-grey">{itm.email}</span>
                  </span>
                );
              } else {
                itm.title = parse($c.boldString(itm.title, e.target.value));
                itm.mimetype = "events";
                type = "events";
              }

              if (!formattedData[type]) {
                formattedData[type] = {
                  type: type,
                  groupLabel: "",
                  results: [],
                };
              }
              let formattedRecord = {};
              formattedRecord["_id"] = itm._id;
              formattedRecord["type"] = type;
              formattedRecord["rawTitle"] = itm.rawTitle;

              formattedRecord["icon"] = "";
              formattedRecord["mimetype"] = itm.mimetype;

              switch (itm.type) {
                case "user":
                  formattedRecord["title"] = "";
                  formattedData[type]["groupLabel"] = "Contacts";
                  formattedRecord["link"] = "";
                  formattedRecord["icon"] = $c.createAvatar(
                    itm.profileImage,
                    itm.firstName,
                    itm.lastName,
                    null,
                    itm.email
                  );

                  break;

                case "training-test":
                  formattedRecord["title"] = <>Training test:{itm.title}</>;
                  formattedRecord["link"] =
                    "/event/training/" + itm._id + "/results";
                  formattedData[type]["groupLabel"] = "Events";
                  formattedRecord["icon"] = (
                    <i
                      className="fas fa-square me-2 font-size-23"
                      style={{ color: "rgb(43, 53, 138,0.7)" }}
                    ></i>
                  );
                  break;

                case "training":
                  formattedRecord["title"] = <>Training:{itm.title}</>;

                  formattedData[type]["groupLabel"] = "Events";
                  formattedRecord["link"] = "/event/training/" + itm._id;
                  formattedRecord["icon"] = (
                    <i
                      className="fas fa-square me-2 font-size-23"
                      style={{ color: "rgb(43, 53, 138,0.7)" }}
                    ></i>
                  );
                  break;

                case "meeting":
                  formattedRecord["title"] = <>Meeting: {itm.title}</>;

                  formattedData[type]["groupLabel"] = "Events";
                  formattedRecord["link"] = "/event/meeting/" + itm._id;
                  formattedRecord["icon"] = (
                    <i
                      className="fas fa-square me-2 font-size-23"
                      style={{ color: "rgb(49, 18, 89,0.7)" }}
                    ></i>
                  );
                  break;

                case "stream":
                  formattedRecord["title"] = <>Conference: {itm.title}</>;

                  formattedData[type]["groupLabel"] = "Events";
                  formattedRecord["link"] = "/event/conference/" + itm._id;
                  formattedRecord["icon"] = (
                    <i
                      className="fas fa-square me-2 font-size-23"
                      style={{ color: "rgb(49, 116, 173,0.7)" }}
                    ></i>
                  );
                  break;

                case "test":
                  formattedRecord["title"] = <>Test: {itm.title}</>;
                  formattedData[type]["groupLabel"] = "Events";
                  formattedRecord["link"] = "/event/test/" + itm._id;
                  formattedRecord["icon"] = (
                    <i
                      className="fas fa-square me-2 font-size-23"
                      style={{ color: "rgb(18, 76, 89,0.7)" }}
                    ></i>
                  );
                  break;

                case "training-file":
                  formattedRecord["title"] = (
                    <>
                      {itm.title}
                      <span className="small text-grey d-block  mt-1">
                        Training: {itm.trainingTitle}
                      </span>
                    </>
                  );

                  formattedRecord["fileType"] = "file";
                  formattedRecord["_id"] = "t-".concat(itm._id);
                  formattedData[type]["groupLabel"] = "Files";
                  formattedRecord["icon"] = $c.getFileIcon(itm.mimetype);
                  break;

                case "meeting-file":
                  formattedRecord["title"] = (
                    <>
                      {itm.title}
                      <span className="small text-grey d-block mt-1">
                        Meeting: {itm.meetingTitle}
                      </span>
                    </>
                  );
                  formattedData[type]["groupLabel"] = "Files";
                  formattedRecord["fileType"] = "file";

                  formattedRecord["icon"] = $c.getFileIcon(itm.mimetype);
                  break;

                case "file":
                  formattedRecord["fileType"] = "file";
                  formattedRecord["title"] = (
                    <>
                      <span>{itm.title}</span>
                      {itm.keywordFound ? (
                        <span
                          className="small text-grey d-block  mt-1"
                          style={{ paddingLeft: "31px" }}
                        >
                          Keywords:{" "}
                          {parse(
                            $c.boldString(itm.keywordFound, e.target.value)
                          )}
                        </span>
                      ) : (
                        ""
                      )}
                    </>
                  );
                  formattedData[type]["groupLabel"] = "Files";
                  formattedRecord["icon"] = $c.getFileIcon(itm.mimetype);
                  break;

                default:
                  formattedRecord["title"] = itm.title;
                  // formattedData[type]["groupLabel"] = "Other";
                  break;
              }

              formattedData[type]["results"].push(formattedRecord);
            });
          }
        }

        let output = [_this.searchFeatures(_this)];
        _this.setState({ results: output });

        let totalResults = 0;

        if (formattedData) {
          Object.keys(formattedData)?.forEach((itm, idx) => {
            let results = [];

            formattedData[itm].results?.forEach((ritm, ridx) => {
              let hiddenClass = "";

              if (ridx >= 5) {
                hiddenClass = "d-none";
              }

              totalResults++;

              results.push(
                <li
                  className={
                    "list-group-item border-0 p-0 " +
                    hiddenClass +
                    " search-group-" +
                    idx
                  }
                >
                  {_this.searchOpenResult(
                    ritm,
                    <>
                      <span>{ritm.icon}</span>
                      <span className="pb-1 text-black">{ritm.title}</span>
                    </>
                  )}
                </li>
              );
            });

            if (results.length > 5) {
              results.push(
                <li className={"list-group-item border-0 pl-2 "}>
                  <span
                    className="text-primary text-strong pointer"
                    style={{ paddingLeft: "30px" }}
                    onClick={(e) => {
                      let elements = document.getElementsByClassName(
                        "search-group-" + idx + " d-none"
                      );

                      let i = 0;
                      for (let item of elements) {
                        if (i < 5) {
                          item.classList.remove("d-none");
                        }
                        i++;
                      }

                      if (elements.length < 5) {
                        e.target.parentElement.classList.add("d-none");
                      }
                    }}
                  >
                    Load more
                  </span>
                </li>
              );
            }

            if (results.length > 0) {
              let border = "";
              if (idx == 0) {
                border = "border-top";
              }

              output.push(
                <div className={"mb-3 pt-2 " + border}>
                  <span className="d-block pl-2 color-grey">
                    {formattedData[itm].groupLabel} (
                    {formattedData[itm].results.length})
                  </span>
                  <ul className={"list-group  search-group-" + idx}>
                    {results}
                  </ul>
                </div>
              );
            }
          });

          if (totalResults == 0) {
            if (
              e.target.value
                .replace("/Contacts", "")
                .replace("/Events", "")
                .replace("/Files", "")
                .trim()
            ) {
              output.push(
                <ul className="list-group   border-top ">
                  <li className={"list-group-item border-0 p-0 "}>
                    <span className="d-block pt-2 pb-2 pl-2 ">
                      <span>
                        <img
                          src="/uni/svg/noresults.svg"
                          className=" me-2"
                          style={{
                            width: "22px",
                            paddingBottom: "2px",
                          }}
                        />
                      </span>
                      <span className="pb-1">
                        We couldn't find any results for{" "}
                        {e.target.value
                          .replace("/Contacts", "")
                          .replace("/Events", "")
                          .replace("/Files", "")}
                        .
                      </span>
                    </span>
                  </li>
                </ul>
              );
            }
          }

          _this.setState({
            results: output,
            showResults: true,
          });
        }

        // return formattedData;
      }
    );
  }

  searchOpenResult(data, linkTitle) {
    if (data.fileType === "file") {
      return (
        <Link
          to={"/files/preview/" + data._id}
          target="new"
          className="d-block d-flex pt-2 pb-2 pl-2 "
        >
          {linkTitle}
        </Link>
      );
    } else if (data.mimetype.startsWith("user-profile")) {
      return (
        <Link
          to={"/user/" + data._id}
          target="new"
          className="d-block d-flex pt-2 pb-2 pl-2 "
        >
          {linkTitle}
        </Link>
      );
    } else if (data.mimetype.startsWith("events")) {
      return (
        <Link
          to={data.link}
          target="new"
          className="d-block d-flex pt-2 pb-2 pl-2 "
        >
          {linkTitle}
        </Link>
      );
    } else {
      return (
        <span
          className="d-block d-flex pt-2 pb-2 pl-2 "
          onClick={(e) => {}}
          title={data.rawTitle}
        >
          {linkTitle}
        </span>
      );
    }
  }

  searchFeatures() {
    return (
      <div className="pb-2 ">
        <span className="d-block pl-2 color-grey">Search features</span>
        <ul className="list-group ">
          {/* {UniFun.userIsEligible({ forWhat: "module" }) ? ( */}
          <li className={"list-group-item border-0 p-0 "}>
            <span
              className="d-block search-result-link pt-2 pb-2 pl-2 "
              role="button"
              onClick={(e) => {
                this.setState({ value: "/Contacts " });

                setTimeout(function () {
                  document
                    .getElementsByClassName("general-search")[0]
                    .dispatchEvent(new Event("input", { bubbles: true }));

                  document.getElementsByClassName("general-search")[0].focus();
                }, 10);
              }}
            >
              <span>
                <img
                  src="/uni/svg/search.svg"
                  className=" me-2"
                  style={{
                    width: "18px",
                    paddingBottom: "2px",
                  }}
                />
              </span>
              <span className="pb-1  text-black">Contacts</span>
            </span>
          </li>

          <li className={"list-group-item border-0 p-0 "}>
            <span
              role="button"
              onClick={(e) => {
                this.setState({
                  value: "/Events ",
                });
                setTimeout(function () {
                  document
                    .getElementsByClassName("general-search")[0]
                    .dispatchEvent(new Event("input", { bubbles: true }));
                  document.getElementsByClassName("general-search")[0].focus();
                }, 10);
              }}
              className="d-block search-result-link pt-2 pb-2 pl-2 "
            >
              <span>
                <img
                  src="/uni/svg/search.svg"
                  className=" me-2"
                  style={{
                    width: "18px",
                    paddingBottom: "2px",
                  }}
                />
              </span>
              <span className="pb-1 text-black">Events</span>
            </span>
          </li>
          <li className={"list-group-item border-0 p-0 "}>
            <span
              role="button"
              onClick={(e) => {
                this.setState({
                  value: "/Files ",
                });
                setTimeout(function () {
                  document
                    .getElementsByClassName("general-search")[0]
                    .dispatchEvent(new Event("input", { bubbles: true }));
                  document.getElementsByClassName("general-search")[0].focus();
                }, 10);
              }}
              className="d-block search-result-link pt-2 pb-2 pl-2 "
            >
              <span>
                <img
                  src="/uni/svg/search.svg"
                  className=" me-2"
                  style={{
                    width: "18px",
                    paddingBottom: "2px",
                  }}
                />
              </span>
              <span className="pb-1 text-black">Files</span>
            </span>
          </li>
        </ul>
      </div>
    );
  }

  editable() {
    let output = (
      <div className=" general-search-wrapper" style={{ position: "relative" }}>
        <input
          type="text"
          defaultValue={this.state.value}
          value={this.state.value}
          placeholder="Search contacts, events and files..."
          onChange={(e) => {
            this.setState({ value: e.target.value });
          }}
          onFocus={() => {
            this.setState({
              showResults: true,
              results: this.state.results
                ? this.state.results
                : this.searchFeatures(this),
            });
          }}
          onInput={this.onInput}
          autocomplete="off"
          className="form-control general-search border-0"
        ></input>

        <div
          className={
            this.state.showResults ? "general-search-results border " : "d-none"
          }
          //style={{ position: "absolute", top: "37px", zIndex: 1000 }}
        >
          <div className=" text-dark p-3">{this.state.results}</div>
        </div>
      </div>
    );

    return output;
  }

  render() {
    if (this.state.loaded) {
      if (!this.state.show) {
        return "";
      }

      return this.editable();
    }

    return "";
  }
}

export default GeneralSearch;
