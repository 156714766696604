import {
  useRosterState,
  useMeetingManager,
  useAttendeeStatus,
  useAudioVideo,
  MicrophoneActivity,
} from "amazon-chime-sdk-component-library-react";
import $c from "../../../../modules/functions";
import $f from "../../../../src";
import MeetingFunctions from "./functions";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { getAttendeeInfo } from "./apis";

export function Participant(props) {
  const { videoEnabled, sharingContent, muted, signalStrength } =
    useAttendeeStatus(props.attendeeId);
  const meetingManager = useMeetingManager();

  const fullName = props.externalUserId.replace("  ", " ").split(" ");

  console.log("pasok", fullName);

  const firstName = fullName[0];

  const lastName = fullName[2] ? fullName[1] + " " + fullName[2] : fullName[1];

  let options = [];

  if (props.isCurrentModerator && !props.isCurrentAttendee) {
    if (
      MeetingFunctions.participantIsModerator(meetingManager, $c.getUserId())
    ) {
      options.push(
        <div className=" dropstart d-inline-block" role="button">
          <i
            className="fas fa-ellipsis-v fa-fw  color-purple"
            data-bs-toggle="dropdown"
          ></i>
          <ul className="dropdown-menu ">
            {videoEnabled ? (
              <li
                className="dropdown-item small"
                role="button"
                key={$f.key()}
                onClick={() => {
                  MeetingFunctions.deactivateAttendeeVideo(
                    meetingManager,
                    props.attendeeId
                  );
                }}
              >
                Deactivate video
              </li>
            ) : (
              ""
            )}
            {!muted ? (
              <li
                className="dropdown-item small"
                role="button"
                key={$f.key()}
                onClick={() => {
                  MeetingFunctions.muteAttendee(
                    meetingManager,
                    props.attendeeId
                  );
                }}
              >
                Mute
              </li>
            ) : (
              ""
            )}
            {/* <li
            className="dropdown-item small"
            role="button"
            key={$f.key()}
            onClick={() => {
              MeetingFunctions.banUnmuteAttendee(
                meetingManager,
                props.attendeeId
              );
            }}
          >
            Ban Unmute
          </li> */}

            <li
              className="dropdown-item small"
              role="button"
              key={$f.key()}
              onClick={() => {
                window["meetingModal"].setState({
                  show: true,
                  size: "default",
                  title: (
                    <>
                      <div className="display-3-no-line-height ">
                        Kick participant?
                      </div>
                    </>
                  ),
                  footer: [
                    <button
                      type="button"
                      onClick={() => {
                        window["meetingModal"].close();
                      }}
                      className="btn btn-sm btn-white"
                    >
                      Cancel
                    </button>,
                    <button
                      type="button"
                      onClick={() => {
                        MeetingFunctions.kickAttendee(
                          meetingManager,
                          props.attendeeId
                        );
                        window["meetingModal"].close();
                      }}
                      className="btn btn-sm btn-danger"
                    >
                      Kick
                    </button>,
                  ],
                  body: (
                    <p>
                      Are you sure you want to kick <strong>{fullName}</strong>{" "}
                      from the call?
                    </p>
                  ),
                });
              }}
            >
              Kick
            </li>
          </ul>
        </div>
      );
    }
  }

  return (
    <div className="d-flex mb-2">
      {/* <div className="w-65">{$c.createAvatar(null, firstName, lastName)}</div> */}
      <div className="w-65">
        {MeetingFunctions.participantAvatar(props.userId, meetingManager)}
      </div>

      <div className="text-end" style={{ width: "10rem" }}>
        {sharingContent ? (
          <i className="fas fa-desktop fa-fw color-purple"></i>
        ) : (
          ""
        )}
        <i
          className={
            videoEnabled
              ? "fas fa-video fa-fw ms-2  color-purple pointer"
              : "fas fa-video-slash fa-fw ms-2 text-secondary"
          }
          onClick={() => {
            if (props.isCurrentModerator && !props.isCurrentAttendee) {
              if (videoEnabled) {
                MeetingFunctions.deactivateAttendeeVideo(
                  meetingManager,
                  props.attendeeId
                );
              }
            }
          }}
        ></i>
        {/* <i
          className={
            muted
              ? "fas fa-microphone-slash fa-fw ms-2 text-secondary"
              : "fas fa-microphone fa-fw ms-2  color-purple"
          }
        ></i> */}
        <MicrophoneActivity
          attendeeId={props.attendeeId}
          className={"mic-activity ".concat(muted ? "" : "pointer")}
          onClick={() => {
            if (props.isCurrentModerator && !props.isCurrentAttendee) {
              if (!muted) {
                MeetingFunctions.muteAttendee(meetingManager, props.attendeeId);
              }
            }
          }}
        ></MicrophoneActivity>
        {props.isModerator ? "" : options}
      </div>
    </div>
  );
}

const Participants = (props) => {
  const { roster } = useRosterState();
  const attendees = Object.values(roster);
  const meetingManager = useMeetingManager();
  const audioVideo = useAudioVideo();

  const moderatorsItems = [];

  attendees.forEach(async (attendee) => {
    const { chimeAttendeeId, name, externalUserId } = attendee;
    const attendeeInfo = MeetingFunctions.getAttendee(roster, chimeAttendeeId);

    if (
      MeetingFunctions.participantIsModerator(
        meetingManager,
        attendeeInfo.externalUserId
      ) &&
      !MeetingFunctions.isRecordingUser(attendeeInfo.name)
    ) {
      const liItem = (
        <li style={{ lineHeight: "2rem" }}>
          <Participant
            attendeeId={chimeAttendeeId}
            externalUserId={attendeeInfo.name}
            userId={attendeeInfo.externalUserId}
            isModerator={true}
            isCurrentAttendee={MeetingFunctions.isCurrentAttendee(
              audioVideo,
              chimeAttendeeId
            )}
            isCurrentModerator={MeetingFunctions.isCurrentModerator(
              meetingManager,
              audioVideo
            )}
            key={$f.key()}
          ></Participant>
        </li>
      );

      if (MeetingFunctions.isCurrentAttendee(audioVideo, chimeAttendeeId)) {
        moderatorsItems.unshift(liItem);
      } else {
        moderatorsItems.push(liItem);
      }
    }
  });

  const attendeeItems = [];
  attendees.forEach((attendee) => {
    const { chimeAttendeeId, name, externalUserId } = attendee;
    const attendeeInfo = MeetingFunctions.getAttendee(roster, chimeAttendeeId);
    console.log("pasok", attendeeInfo);

    if (
      (!MeetingFunctions.participantIsModerator(
        meetingManager,
        attendeeInfo.externalUserId
      ) &&
        !MeetingFunctions.isRecordingUser(attendeeInfo.name)) ||
      MeetingFunctions.isBreakout()
    ) {
      const liItem = (
        <li>
          <Participant
            attendeeId={chimeAttendeeId}
            externalUserId={attendeeInfo.name}
            isModerator={false}
            userId={attendeeInfo.externalUserId}
            isCurrentAttendee={MeetingFunctions.isCurrentAttendee(
              audioVideo,
              chimeAttendeeId
            )}
            isCurrentModerator={MeetingFunctions.isCurrentModerator(
              meetingManager,
              chimeAttendeeId
            )}
            key={$f.key()}
          ></Participant>
        </li>
      );
      if (MeetingFunctions.isCurrentAttendee(audioVideo, chimeAttendeeId)) {
        attendeeItems.unshift(liItem);
      } else {
        attendeeItems.push(liItem);
      }
    }
  });

  console.log("pasok", attendeeItems);

  return (
    <>
      <span className="d-block display-4-no-margin mb-3">People</span>
      <PerfectScrollbar style={{ maxHeight: "40rem" }}>
        <div className="me-2">
          {!MeetingFunctions.isBreakout() ? (
            <>
              <div className="display-5 color-grey mb-2">
                Hosts ({moderatorsItems.length})
              </div>
              {moderatorsItems.length > 0 ? (
                <ul className="ps-0">{moderatorsItems}</ul>
              ) : (
                <span className="color-grey d-block mb-3">
                  There are not any hosts yet
                </span>
              )}
            </>
          ) : (
            ""
          )}
          <div className="display-5 color-grey mb-2">
            Participants ({attendeeItems.length})
          </div>
          {attendeeItems.length > 0 ? (
            <ul className="ps-0">{attendeeItems}</ul>
          ) : (
            <span className="color-grey">
              There are not any participants yet
            </span>
          )}
        </div>
      </PerfectScrollbar>
    </>
  );
};
export default Participants;
