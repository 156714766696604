import $c from "../../../../modules/functions";
import $f from "../../../../src";
import MeetingFunctions from "./functions";

export async function joinEvent(meetingId, breakoutId) {
  let url = "/join/".concat(meetingId);

  if (breakoutId) {
    url = url.concat("?breakout=", breakoutId);
  }

  const response = await fetch(window["baseurl"](url), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    },
  })
    .then((response) => {
      if (response.status == 401 || response.status == 403) {
        $c.logout(window.location.href);
      }
      return response.json();
    })
    .catch((error) => {
      console.log("There has been a problem with your fetch operation:", error);
    });

  return await response;
}

export async function createBreakout(meetingId, breakoutId) {
  let url = "/create/".concat(meetingId, "?breakout=", breakoutId);

  const response = await fetch(window["baseurl"](url), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    },
  })
    .then((response) => {
      if (response.status == 401 || response.status == 403) {
        $c.logout(window.location.href);
      }
      return response.json();
    })
    .catch((error) => {
      console.log("There has been a problem with your fetch operation:", error);
    });

  return await response;
}

export async function getAttendeeInfo(meetingId, attendeeId) {
  const response = await fetch(
    window["baseurl"]("/attendee/".concat(meetingId, "?attendee=", attendeeId))
  );
  // .then(async (response) => {
  //   if (response.status == 401 || response.status == 403) {
  //     $c.logout(window.location.href);
  //   }
  //   return await response.json();
  // })
  // .catch((error) => {
  //   console.log("There has been a problem with your fetch operation:", error);
  // });
  return await response.json();
}

export async function fetchParticipants(meetingId) {
  let url = "/meeting/".concat(meetingId, "/participants");

  if ($f.getQueryPath(2).startsWith("v-")) {
    url = "/event/training/videoConference/".concat(meetingId, "/participants");
  }

  const response = await fetch(window["baseurl"](url), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    },
  })
    .then((response) => {
      if (response.status == 401 || response.status == 403) {
        $c.logout(window.location.href);
      }
      return response.json();
    })
    .catch((error) => {
      console.log("There has been a problem with your fetch operation:", error);
    });

  let participants = [];
  try {
    participants = await response.data[0].participants;
  } catch (e) {}

  return participants;
}

export async function fetchBreakoutRooms(meetingId) {
  let url = "/breakouts/".concat(meetingId);

  const response = await fetch(window["baseurl"](url), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    },
  })
    .then((response) => {
      if (response.status == 401 || response.status == 403) {
        $c.logout(window.location.href);
      }
      return response.json();
    })
    .catch((error) => {
      console.log("There has been a problem with your fetch operation:", error);
    });

  return await response;
}

export async function breakoutAssignParticipants(
  participants,
  breakoutId,
  callback
) {
  $f.fetch(
    "/assignParticipants/{breakoutId}",
    "POST",
    {
      participants: participants,
    },
    {},
    {
      breakoutId: () => {
        return breakoutId;
      },
    },
    (resp) => {
      if (callback) {
        callback(resp);
      }
    }
  );
}

export async function renameBreakout(breakoutId, title, callback) {
  $f.fetch(
    "/renameBreakoutRoom/{breakoutId}",
    "PUT",
    {
      title: title,
    },
    {},
    {
      breakoutId: () => {
        return breakoutId;
      },
    },
    (resp) => {
      if (callback) {
        callback(resp);
      }
    }
  );
}
