import { Link } from "react-router-dom";
import $f from "../../src";
import $c from "../functions";
import Modals from "../modals";

const ConferencesPartners = {
  properties: (args) => {
    const { editable } = args;

    return {
      _id: {
        label: "",
        path: "_id",
        type: "hidden",
        labelPosition: "none",
        events: {},
        attributes: {},
      },
      title: {
        label: "Name",
        type: "text",
        editable: editable,
        events: {},
        attributes: {
          className: "form-control",
        },
        validation: "required",
      },
      optionsfortable: {
        label: "",
        type: "optionsfortable",
        configuration: {
          options: {
            edit: function (args) {
              let newargs = {
                props: { data: { ...args.props.data } },
                action: "update",
              };

              return {
                label: "Edit",
                onClick: function () {
                  $f.getValue(Modals.events.editPartner, newargs);
                },
              };
            },
            delete: function (args) {
              return {
                label: <span className="text-danger">Delete</span>,
                onClick: function () {
                  $f.getValue(Modals.events.deletePartner, args);
                },
              };
            },
          },
        },
      },
      categoryForList: {
        label: "Category",
        type: "text",
        path: "category",
        editable: false,
        events: {},
        attributes: {
          className: "form-control",
        },
      },
      category: {
        label: "Category",
        type: "select",
        editable: editable,
        configuration: {
          options: [],
        },
        validation: "required",
        events: {
          didMount: function () {
            let _this = this;
            $f.fetch(
              "/sponsorCategories",
              "GET",
              null,
              {},
              {},
              (resp) => {
                let options = [{ label: "Select category", value: "" }];
                if (resp) {
                  if (resp.data) {
                    if (resp.data.data) {
                      resp.data.data.forEach((el) => {
                        options.push({ label: el, value: el });
                      });
                    }
                  }
                }
                _this.setState({ options: options });
              },
              null,
              null,
              null,
              null,
              false
            );
          },
        },
        attributes: {
          className: "form-select",
        },
      },
      logo: {
        label: "Partner logo",
        type: "upload",
        editable: editable,
        configuration: {
          url: "/agenda-file",
          type: "meeting",
          fileDataProperty: "file",
          allowedExtensions: ["png", "jpeg", "jpg"],
          multipleFiles: false,
        },
        formatValue: function (value) {
          if (value) {
            return [value];
          }
          return value;
        },
        caption: function (_this) {
          return (
            <div className="w-100 text-center p-4 ">
              <img src="/uni/svg/files/upload.svg" className="svg-sm mb-2" />
              <span className="d-block mb-1">
                <strong>
                  <span
                    className="color-purple"
                    role="button"
                    onClick={_this.onTargetClick}
                  >
                    Click to upload
                  </span>{" "}
                  or drag and drop
                </strong>
              </span>
              <span className="color-grey d-block">PNG, JPEG, (max 50MB)</span>
            </div>
          );
        },
        events: {},
        attributes: {
          className: "form-control bg-light-grey",
        },
      },
      description: {
        label: "Description",
        type: "editor",
        editable: editable,
        events: {},
        attributes: {
          className: "form-control",
        },
      },
      link: {
        label: "Link",
        type: "text",
        editable: editable,
        events: {},
        attributes: {
          className: "form-control",
        },
      },
    };
  },
  specialProperties: () => {
    return {
      header: {
        type: "template",
        configuration: {
          title: "Partners",
          subTitle: "In this page you can add partners for your conferences.",
          template: "Header",
          buttons: (args) => {
            return (
              <button type="button" className="btn btn-purple  btn-sm  ">
                Add partner
              </button>
            );
          },
        },
        events: {},
      },

      eventsNav: {
        type: "template",
        configuration: {
          data: (resp) => {
            let output = [];
            let data = [
              { name: "All conferences", filter: "" },
              // { name: "Upcoming", filter: "upcoming" },
              // { name: "Ongoing", filter: "ongoing" },
              // { name: "Ended", filter: "completed" },
            ];
            try {
              data.forEach((el, idx) => {
                let isActive = false;
                if ($f.getParameterByName("filter") == el.filter) {
                  isActive = true;
                } else {
                  if (
                    !$f.getParameterByName("filter") &&
                    $f.getQueryPath(3) != "partners"
                  ) {
                    if (idx == 0) {
                      isActive = true;
                    }
                  }
                }

                output.push({
                  url:
                    idx == 0
                      ? "/events/conferences"
                      : "/events/conferences?filter=" + el.filter,
                  title: el.name,
                  isActive: () => {
                    return isActive;
                  },
                });
              });

              output.push({
                url: "/events/conferences/partners",
                title: "Partners",
                isActive: () => {
                  if (
                    window.location.pathname == "/events/conferences/partners"
                  ) {
                    return true;
                  }
                  return false;
                },
              });
            } catch (e) {}
            return output;
          },
          template: "HorizontalNavScroll",
        },
      },
    };
  },
  views: {
    list: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/sponsors?start={start}&offset={offset}";
          },
          dataPath: () => {},
          replace: {
            start: 0,
            offset: 25,
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Table";
        },

        configuration: {
          tableConfiguration: {
            headers: {
              title: {
                attributes: {
                  className: "w-50",
                },
              },
            },
            cellTemplates: {
              title: (data, row) => {
                let newargs = {
                  props: { data: { ...row._id.data } },
                  action: "update",
                };

                return (
                  <span
                    role="button"
                    onClick={() => {
                      $f.getValue(Modals.events.editPartner, newargs);
                    }}
                  >
                    {data}
                  </span>
                );
              },
            },
          },
          includeProperties: () => {
            return ["title", "optionsfortable", "categoryForList"];
          },

          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
    detail: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/sponsors/{id}";
          },
          dataPath: () => {},
          replace: {},
          headers: {},
          mapper: () => {},
        },
        new: {
          url: () => {
            return { data: [{}] };
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Form";
        },

        configuration: {
          includeProperties: (args) => {
            if (args.data[0]._id) {
              return [
                "_id",
                "title",
                "category",
                "logo",
                "description",
                "link",
              ];
            } else {
              return ["title", "category", "logo", "description", "link"];
            }
          },
          headerTemplate: function () {
            return (
              <div className="display-3-no-bold  border-bottom-1">Partners</div>
            );
          },

          primaryKey: "_id",
        },
        properties: () => {
          return { editable: true };
        },
      },
    },
  },
};

export default ConferencesPartners;
