import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Index from "./index";
import { matchPath } from "react-router";
import $f from "../../src";
import RouterMap from "../../modules/routerMap";
import Module from "../../types/module/module";

function getRouter(routerMapper, history) {
  const routes = Object.keys(routerMapper).filter((item) => {
    return matchPath(history.location.pathname, {
      path: item,
      exact: true,
    });
  });

  const routerMapperBestMatch = routerMapper[routes];

  window["index"].setState({
    topmenu: null,
    middle: null,
    leftmenu: null,
    fullpage: null,
  });

  Object.keys(routerMapperBestMatch).forEach((el, idx) => {
    if (routerMapperBestMatch[el]) {
      let property = {};

      property.configuration = {
        module: $f.getValue(routerMapperBestMatch[el]["module"]),
        view: $f.getValue(routerMapperBestMatch[el]["view"]),
      };

      if (routerMapperBestMatch[el]["apiReplace"]) {
        property.configuration.apiReplace = $f.getValue(
          routerMapperBestMatch[el]["apiReplace"]
        );
      }

      if (routerMapperBestMatch[el]["apiHeaders"]) {
        property.configuration.apiHeaders = $f.getValue(
          routerMapperBestMatch[el]["apiHeaders"]
        );
      }

      let key = $f.key();

      if (window.location.pathname == "/") {
        document.body.style.backgroundColor = "#f6f7f9";
      } else {
        document.body.style.backgroundColor = "unset";
      }

      if (el == "leftmenu") {
        if (window.location.pathname.startsWith("/files")) {
          key = "files";
        } else if (
          window.location.pathname.startsWith("/event/test/") ||
          window.location.pathname.startsWith("/event/meeting/") ||
          window.location.pathname.startsWith("/event/training/") ||
          window.location.pathname.startsWith("/event/conference/") ||
          window.location.pathname.startsWith("/forms/") ||
          window.location.pathname.startsWith("/user/")
        ) {
          key = $f.key();
        } else {
          key = $f.getValue(routerMapperBestMatch["middle"]["module"]);
        }
      }

      window["index"].setState({
        [el]: (
          <Module
            property={property}
            key={key}
            ref={(refComponent) => {
              window[el] = refComponent;
            }}
          ></Module>
        ),
      });
    }
  });
}

const AppRouter = () => {
  const history = useHistory();

  useEffect(() => {
    let hasHistory = false;
    if (!hasHistory) {
      if (RouterMap) {
        getRouter(RouterMap, history);
      }
    }
    return history.listen((location) => {
      hasHistory = true;
      if (RouterMap) {
        getRouter(RouterMap, history);
      }
    });
  }, [history]);

  return (
    <Index
      key={$f.key()}
      history={history}
      ref={(refComponent) => {
        window["index"] = refComponent;
      }}
    ></Index>
  );
};

export default AppRouter;
