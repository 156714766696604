import $f from "../src";
import Module from "../types/module/module";
import Actions from "./actions/actions";
import $c from "./functions";
import Modals from "./modals";

const EmptyStates = {
  defaultFiles: function (args) {
    if (args.viewOnly) {
      return (
        <div className="d-flex empty-state-height">
          <div className="m-auto">
            <h3 className="display-3 ">There are no files</h3>
            <span className="d-block color-grey">
              {args.subtitle
                ? args.subtitle
                : "If files exists will appear here"}
            </span>
          </div>
        </div>
      );
    }

    return (
      <div className="d-flex empty-state-height">
        <div className="m-auto">
          <h3 className="display-3 ">Upload your first file</h3>
          <span className="d-block color-grey">
            Upload or drag & drop files
          </span>
          <button
            className="btn btn-purple mt-3 btn-sm"
            onClick={() => {
              args.onTargetClick();
            }}
          >
            Upload files
          </button>
        </div>
      </div>
    );
  },
  defaultParticipants: function (args) {
    if (args.viewOnly) {
      return (
        <div className="d-flex empty-state-height">
          <div className="m-auto">
            <h3 className="display-3 ">
              There are no participants at the moment
            </h3>
            <span className="d-block color-grey">
              If participants exists will appear here
            </span>
          </div>
        </div>
      );
    }

    return (
      <div className="d-flex empty-state-height">
        <div className="m-auto">
          <h3 className="display-3 ">There no participants in the event</h3>
          <span className="d-block color-grey">Add people to the event</span>
          <button
            type="button"
            className="btn btn-purple  btn-sm  mt-3"
            onClick={args.addPeople}
          >
            Add people
          </button>
        </div>
      </div>
    );
  },
  account: {
    spaces: function (args) {
      return (
        <div className="d-flex empty-state-height">
          <div className="m-auto">
            <h3 className="display-3 ">There are not any Spaces yet</h3>
            <span className="d-block color-grey">Spaces will appear here</span>
          </div>
        </div>
      );
    },
    events: function (args) {
      return (
        <div className="d-flex empty-state-height">
          <div className="m-auto">
            <h3 className="display-3 ">There are not any events yet</h3>
            <span className="d-block color-grey">Events will appear here</span>
          </div>
        </div>
      );
    },
    deactivated: function (args) {
      return (
        <div className="d-flex empty-state-height">
          <div className="m-auto">
            <h3 className="display-3 ">User is deactivated</h3>
            <span className="d-block color-grey">
              You can activate user from security
            </span>
          </div>
        </div>
      );
    },
  },
  contacts: {
    settings: {
      space: function (args) {
        return (
          <div className="d-flex empty-state-height">
            <div className="m-auto">
              <h3 className="display-3 ">There are not any Spaces yet</h3>
              <span className="d-block color-grey">
                Spaces will appear here
              </span>
            </div>
          </div>
        );
      },
    },
    list: function (args) {
      return (
        <div className="d-flex empty-state-height">
          <div className="m-auto">
            <h3 className="display-3 ">There are not any contacts yet</h3>
            <span className="d-block color-grey">
              Contacts will appear here
            </span>
          </div>
        </div>
      );
    },
  },
  homepage: {
    today: function (args) {
      return (
        <div style={{ minHeight: "13rem" }} className="text-center d-flex">
          <div className="m-auto">
            <h3 className="display-4 ">
              You have not any scheduled events for today.
            </h3>
            <button
              className="btn btn-purple  btn-sm"
              onClick={() => {
                window.location.replace("/events/calendar");
              }}
            >
              View all
            </button>
          </div>
        </div>
      );
    },
    next7days: function (args) {
      return (
        <div style={{ minHeight: "13rem" }} className="text-center d-flex">
          <div className="m-auto">
            <h3 className="display-4 ">
              You have not any scheduled events for the next 7 days.
            </h3>
            <button
              className="btn btn-purple  btn-sm"
              onClick={() => {
                window.location.replace("/events/calendar");
              }}
            >
              View all
            </button>
          </div>
        </div>
      );
    },
    spaces: function (args) {
      return (
        <div style={{ minHeight: "11rem" }} className="text-center d-flex">
          <div className="m-auto">
            <div className="mb-3">
              {$c.userIsAdmin() ? (
                "In Spaces you can assign people and store files into groups."
              ) : (
                <>
                  You are not assigned to a Space yet. In spaces you <br></br>
                  can find your colleagues and share files with them.
                </>
              )}
            </div>
            <button
              className="btn btn-purple  btn-sm"
              onClick={() => {
                window.location.replace("/spaces");
              }}
            >
              Go to spaces
            </button>
          </div>
        </div>
      );
    },
    libary: function (args) {
      return (
        <div style={{ minHeight: "11rem" }} className="text-center d-flex">
          <div className="m-auto">
            <div className="mb-3">
              {$c.userIsAdmin() ? (
                <>
                  In Library you can upload and manage your personal files,
                  share company<br></br>files to your people and manage files in
                  Spaces.
                </>
              ) : (
                <>
                  In Library you can upload and manage your personal files, view
                  files<br></br>shared by your company and files that are added
                  in your Spaces.
                </>
              )}
            </div>
            <button
              className="btn btn-purple  btn-sm"
              onClick={() => {
                window.location.replace("/files");
              }}
            >
              Go to library
            </button>
          </div>
        </div>
      );
    },
  },
  forms: {
    forms: function (args) {
      return (
        <div className="d-flex empty-state-height">
          <div className="m-auto">
            <h3 className="display-3 ">Create your first form</h3>
            <span className="d-block color-grey">
              Your forms will appear here
            </span>
            <button
              type="button"
              className="btn btn-purple  btn-sm  mt-3"
              onClick={() => {
                $f.getValue(Modals.forms.editForm, {
                  title: "Create form",
                  api: "new",
                });
              }}
            >
              Create form
            </button>
          </div>
        </div>
      );
    },
    results: function (args) {
      return (
        <div className="d-flex empty-state-height">
          <div className="m-auto">
            <h3 className="display-3 ">There are not any results</h3>
            <span className="d-block color-grey">Results will appear here</span>
          </div>
        </div>
      );
    },
  },
  spaces: {
    space: function (args) {
      return (
        <div className="d-flex empty-state-height">
          <div className="m-auto">
            <h3 className="display-3 ">Create your first space</h3>
            <span className="d-block color-grey">
              In spaces you can assign your people and store your files into
              groups.
            </span>
            <button
              className="btn btn-purple mt-3 btn-sm"
              onClick={() => {
                Modals.spaces.addSpace({ view: "spacedetail" });
              }}
            >
              Create space
            </button>
          </div>
        </div>
      );
    },
    group: function (args) {
      return (
        <div className="d-flex empty-state-height">
          <div className="m-auto">
            <h3 className="display-3 text-break">
              Add your first group to "{args.title}"
            </h3>
            <span className="d-block color-grey">
              Groups will appear on the column on the left
            </span>
            <button
              className="btn btn-purple mt-3 btn-sm"
              onClick={() => {
                $f.getValue(Modals.spaces.addSpace, {
                  title: "Add group",
                  inputData: [
                    {
                      parent: args.id,
                      rootSpace: args.id,
                    },
                  ],
                });
              }}
            >
              Add group
            </button>
          </div>
        </div>
      );
    },
    noGroups: function (args) {
      return (
        <div className="d-flex empty-state-height">
          <div className="m-auto">
            <h3 className="display-3 text-break">
              There no groups in this space yet
            </h3>
            <span className="d-block color-grey">
              Groups will appear on the menu under the space
            </span>
          </div>
        </div>
      );
    },
    selectGroup: function (args) {
      return (
        <div className="d-flex empty-state-height">
          <div className="m-auto">
            <h3 className="display-3 text-break">
              Select a group from the column "Groups" on the left
            </h3>
            <span className="d-block color-grey"></span>
          </div>
        </div>
      );
    },
    participants: function (args) {
      return (
        <div className="d-flex empty-state-height">
          <div className="m-auto">
            <h3 className="display-3 ">Add your first contact</h3>
            <span className="d-block color-grey">
              You can only add registered users
            </span>
            <button
              type="button"
              className="btn btn-purple  btn-sm  mt-3"
              onClick={args.addPeople}
            >
              Add people
            </button>
          </div>
        </div>
      );
    },
  },
  events: {
    tests: {
      default: function (args) {
        if ($c.userIsAdmin()) {
          return (
            <div className="d-flex empty-state-height">
              <div className="m-auto">
                <h3 className="display-3 ">Schedule your first test</h3>
                <span className="d-block color-grey">
                  Schedule a test for your contacts
                </span>
                <button
                  className="btn btn-purple mt-3 btn-sm"
                  onClick={() => {
                    Modals.events.scheduleTest();
                  }}
                >
                  Schedule
                </button>
              </div>
            </div>
          );
        }

        return (
          <div className="d-flex empty-state-height">
            <div className="m-auto">
              <h3 className="display-3 ">There are not any tests yet</h3>
              <span className="d-block color-grey">Tests will appear here</span>
            </div>
          </div>
        );
      },
      results: function (args) {
        return (
          <div className="d-flex empty-state-height">
            <div className="m-auto">
              <h3 className="display-3 ">There are no results</h3>
              <span className="d-block color-grey">
                {"Results will appear here"}
              </span>
            </div>
          </div>
        );
      },
    },
    trainings: {
      default: function (args) {
        if ($c.userIsAdmin()) {
          return (
            <div className="d-flex empty-state-height">
              <div className="m-auto">
                <h3 className="display-3 ">Schedule your first training</h3>
                <span className="d-block color-grey">
                  Schedule a training for your contacts
                </span>
                <button
                  className="btn btn-purple mt-3 btn-sm"
                  onClick={() => {
                    Modals.events.scheduleTraining();
                  }}
                >
                  Schedule
                </button>
              </div>
            </div>
          );
        }
        return (
          <div className="d-flex empty-state-height">
            <div className="m-auto">
              <h3 className="display-3 ">There are not any trainings yet</h3>
              <span className="d-block color-grey">
                Trainings will appear here
              </span>
            </div>
          </div>
        );
      },
      results: function (args) {
        return (
          <div className="d-flex empty-state-height">
            <div className="m-auto">
              <h3 className="display-3 ">There are no results</h3>
              <span className="d-block color-grey">
                {"Results will appear here"}
              </span>
            </div>
          </div>
        );
      },
      files: function () {
        return (
          <div className="d-flex empty-state-height">
            <div className="m-auto">
              <h3 className="display-3 ">There are no files at the moment</h3>
              <span className="d-block color-grey">
                Session files will appear here
              </span>
            </div>
          </div>
        );
      },
    },
    conferences: {
      pollResults: function (args) {
        return (
          <div className="d-flex empty-state-height">
            <div className="m-auto">
              <h3 className="display-3 ">There are no results</h3>
              <span className="d-block color-grey">
                Poll results will appear here
              </span>
            </div>
          </div>
        );
      },
      default: function (args) {
        if ($c.userIsAdmin()) {
          return (
            <div className="d-flex empty-state-height">
              <div className="m-auto">
                <h3 className="display-3 ">Schedule your first conference</h3>
                <span className="d-block color-grey">
                  Schedule a conference for your contacts
                </span>
                <button
                  className="btn btn-purple mt-3 btn-sm"
                  onClick={() => {
                    $f.getValue(Modals.events.editConference, {
                      title: "Schedule conference",
                      api: "new",
                      action: "create",
                    });
                  }}
                >
                  Schedule
                </button>
              </div>
            </div>
          );
        }
        return (
          <div className="d-flex empty-state-height">
            <div className="m-auto">
              <h3 className="display-3 ">There are not any conferences yet</h3>
              <span className="d-block color-grey">
                Conferences will appear here
              </span>
            </div>
          </div>
        );
      },
    },
    meetings: {
      default: function (args) {
        return (
          <div className="d-flex empty-state-height">
            <div className="m-auto">
              <h3 className="display-3 ">Schedule your first meeting</h3>
              <span className="d-block color-grey">
                Schedule a video conference with your contacts
              </span>
              <button
                className="btn btn-purple mt-3 btn-sm"
                onClick={() => {
                  $f.getValue(Modals.events.editMeeting, {
                    title: "Schedule meeting",
                    api: "new",
                    action: "create",
                  });
                }}
              >
                Schedule
              </button>
            </div>
          </div>
        );
      },
      passed: function (args) {
        return (
          <div className="d-flex empty-state-height">
            <div className="m-auto">
              <h3 className="display-3 ">No meetings found</h3>
              <span className="d-block color-grey"></span>
            </div>
          </div>
        );
      },
    },
  },
};

export default EmptyStates;
