import React, { Component } from "react";
import $c from "../../../../modules/functions";
import $f from "../../../../src";

class MeetingOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loaded: false,
    };
  }

  componentDidMount() {
    const { data, module, view, specialProperties } = this.props;

    let templateConfiguration = view.template.configuration;

    const innerTemplates = ["header"];

    innerTemplates.map((itm, idx) => {
      let tmp = templateConfiguration[itm + "Template"]
        ? templateConfiguration[itm + "Template"]
        : () => {};
      this[itm + "Template"] = tmp.bind(this);
    });

    this.setState({
      specialProperties,
      data,
      loaded: true,
      view,
      module,
    });
  }

  render() {
    if (this.state.loaded) {
      const data = this.state.data[0];
      return (
        <React.Fragment>
          {this.headerTemplate()}
          <div className="display-3-no-bold border-bottom-1">Overview</div>
          {data.description.value ? (
            <div className=" mt-3 color-grey">{data.description.component}</div>
          ) : (
            ""
          )}
          <div className="mt-4">
            <span>{data.organizers.component}</span>
          </div>
          <div className="row mt-4">
            <div className="col-md-6">
              <div className="bg-light-grey p-2 rounded mb-2 d-flex">
                <span className="p-2 rounded bg-white me-2">
                  <img src="/uni/svg/datetime.svg" style={{ width: "20px" }} />
                </span>
                <span className="mt-auto mb-auto">{data.date.component}</span>
              </div>
              <div className="bg-light-grey p-2 rounded mb-2 d-flex">
                <span className="p-2 rounded bg-white me-2">
                  <img
                    src="/uni/svg/meeting_participants.svg"
                    style={{ width: "20px" }}
                  />
                </span>
                <span className="mt-auto mb-auto">
                  {data.participantsCount.component} participants
                </span>
              </div>
              <div className="bg-light-grey p-2 rounded mb-2 d-flex">
                <span className="p-2 rounded bg-white me-2">
                  <img
                    src="/uni/svg/graded_practicetest.svg"
                    style={{ width: "20px" }}
                  />
                </span>
                <span className="mt-auto mb-auto">
                  {data.filesCount.component} files included
                </span>
              </div>
            </div>
            <div className="col-md-6"></div>
          </div>
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default MeetingOverview;
