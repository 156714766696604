import parse from "html-react-parser";
import { Component } from "react";

class Password extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);

    this.state = {
      loaded: false,
    };
  }

  onChange(e) {
    this.setState({
      value: e.target.value,
    });

    setTimeout(this["customOnChange"], 0);
  }

  componentDidMount() {
    let events = [];

    Object.keys(this.props.property.events)?.forEach((element, idx) => {
      if (!element.toLowerCase().startsWith("onchange")) {
        this[element] = this.props.property.events[element];
        this[element] = this[element].bind(this);
        events[element] = this[element];
      } else {
        this["customOnChange"] = { ...this.props.property.events[element] };
        this["customOnChange"] = this["customOnChange"].bind(this);
      }
    });

    this.setState({
      loaded: true,
      events: events,
      attributes: { ...this.props.property.attributes },
      value: this.props.value,
      editable: this.props.property.editable === false ? false : true,
      useCustomUI: this.props.property.useCustomUI,
    });
  }

  editable() {
    return (
      <input
        type="password"
        {...this.state.events}
        {...this.state.attributes}
        defaultValue={this.state.value}
        onChange={this.onChange}
      ></input>
    );
  }

  nonEditable() {
    return typeof this.state.value === "string"
      ? parse(this.state.value)
      : this.state.value;
  }

  customUI() {}

  render() {
    if (this.state.loaded) {
      if (this.state.useCustomUI) {
        return this.customUI();
      } else if (this.state.editable) {
        return this.editable();
      } else {
        return this.nonEditable();
      }
    }

    return "";
  }
}

export default Password;
