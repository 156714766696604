import { Component } from "react";
import $f from "../../src";
import Templates from "../../themes/hapeiron/templates";

class Template extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    let configuration = {
      ...$f.getValue(this.props.property.configuration, this),
    };

    Object.keys(configuration).forEach((itm) => {
      configuration[itm] = $f.getValue(configuration[itm], this.props);
    });

    this.setState({
      loaded: true,
      configuration: configuration,
    });
  }

  render() {
    if (this.state.loaded) {
      const Template = $f.getValue(
        Templates[$f.getValue(this.state.configuration.template)]
      );

      return (
        <Template
          data={this.state.configuration}
          configuration={this.state.configuration}
          component={this}
          key={$f.key()}
        ></Template>
      );
    }

    return "";
  }
}

export default Template;
