import $c from "../functions";
import $f from "../../src";
import Modals from "../modals";
import Actions from "../actions/actions";
import EmptyStates from "../emptyStates";

const Favorites = {
  properties: (args) => {
    const { editable } = args;

    return {
      _id: {
        label: "",
        path: "_id",
        type: "text",
        events: {},
        attributes: {},
      },
      title: {
        label: "Name",
        type: "text",
        editable: editable,
        events: {},
        attributes: {},
      },
      optionsfortable: {
        label: "",
        type: "optionsfortable",
        configuration: {
          options: {
            open: function (args) {
              let url = "/files/preview/".concat(args.props.data._id);

              if (args.props.data.isFolder) {
                if (args.props.data.root == "library") {
                  url = "/files/library?_id=".concat(args.props.data._id);
                } else if (args.props.data.root?.type == "space") {
                  url = "/files/space/".concat(
                    args.props.data.root._id,
                    "?_id=",
                    args.props.data._id
                  );
                } else {
                  url = "/files?_id=".concat(args.props.data._id);
                }

                return {
                  label: "Open",
                  onClick: function () {
                    args.props.history.push(url);
                  },
                };
              }

              return {
                label: "Preview",
                onClick: function () {
                  args.props.history.push(url);
                },
              };
            },
            edit: function (args) {
              let isOwner = false;

              try {
                if (args.props.data.owner._id == $c.getUserId()) {
                  isOwner = true;
                }
              } catch (e) {}

              if ($c.userIsAdmin()) {
                isOwner = true;
              }

              if (args.props.data.isFolder) {
                let newargs = {
                  props: { data: { ...args.props.data } },
                  action: "update",
                  title: isOwner ? "Edit folder" : "Preview folder",
                };

                return {
                  label: isOwner ? "Edit folder" : "Preview folder",
                  onClick: function () {
                    $f.getValue(Modals.files.editFolder, newargs);
                  },
                };
              }

              let newargs = {
                props: { data: { ...args.props.data } },
                action: "update",
                title: isOwner ? "Edit file" : "Preview file",
              };

              return {
                label: isOwner ? "Edit file" : "Preview file",
                onClick: function () {
                  $f.getValue(Modals.files.editFile, newargs);
                },
              };
            },
            copyLink: function (args) {
              let url = "/files/preview/".concat(args.props.data._id);

              if (args.props.data.isFolder) {
                if (args.props.data.root == "library") {
                  url = "/files/library?_id=".concat(args.props.data._id);
                } else if (args.props.data.root?.type == "space") {
                  url = "/files/space/".concat(
                    args.props.data.root._id,
                    "?_id=",
                    args.props.data._id
                  );
                } else {
                  url = "/files?_id=".concat(args.props.data._id);
                }

                return {
                  label: "Copy link",
                  onClick: function () {
                    $c.copyToClipboard(window["host"](url));
                    $f.createNotification({
                      type: "success",
                      message: "Link copied!",
                    });
                  },
                };
              }

              return {
                label: "Copy link",
                onClick: function () {
                  $c.copyToClipboard(window["host"](url));
                  $f.createNotification({
                    type: "success",
                    message: "Link copied!",
                  });
                },
              };
            },
            download: function (args) {
              if (args.props.data.isFolder) {
                return {
                  label: "Download folder",
                  onClick: function () {
                    $c.downloadFile(
                      "/folder/downloadContents/".concat(
                        args.props.data._id,
                        "?type=download"
                      ),
                      args.props.data.title
                    );
                  },
                };
              }

              return {
                label: "Download file",
                onClick: function () {
                  $c.downloadFile(
                    "/file/data/".concat(args.props.data._id, "?type=download"),
                    args.props.data.title
                  );
                },
              };
            },
            favorites: function (args) {
              let label = args.props.data.isFavorite
                ? "Remove from favorites"
                : "Add to favorites";

              if (args.props.data.isFolder) {
                return {
                  label: label,
                  onClick: function () {
                    if (args.props.data.isFavorite) {
                      Actions.files.removeFromFavorites(
                        args.props.data._id,
                        "folder"
                      );
                    } else {
                      Actions.files.addToFavorites(
                        args.props.data._id,
                        "folder"
                      );
                    }
                  },
                };
              }

              return {
                label: label,
                onClick: function () {
                  if (args.props.data.isFavorite) {
                    Actions.files.removeFromFavorites(
                      args.props.data._id,
                      "file"
                    );
                  } else {
                    Actions.files.addToFavorites(args.props.data._id, "file");
                  }
                },
              };
            },
            delete: function (args) {
              let isOwner = false;

              try {
                if (args.props.data.owner._id == $c.getUserId()) {
                  isOwner = true;
                }
              } catch (e) {}

              if ($c.userIsAdmin() || isOwner) {
                if (args.props.data.isFolder) {
                  return {
                    label: <span className="text-danger">Delete</span>,
                    onClick: function () {
                      $f.getValue(Modals.files.deleteFolder, args);
                    },
                  };
                }

                return {
                  label: <span className="text-danger">Delete</span>,
                  onClick: function () {
                    $f.getValue(Modals.files.deleteFile, args);
                  },
                };
              }
            },
          },
        },
      },
      updatedAt: {
        label: "Last Modified",
        type: "text",
        editable: editable,
        events: {},
        attributes: {},
      },
      size: {
        label: "Size",
        type: "text",
        editable: editable,
        events: {},
        attributes: {},
      },
    };
  },
  specialProperties: () => {
    return {
      header: {
        type: "template",
        configuration: {
          title: "Favorites",
          subTitle: "This is your favorite files.",
          template: "Header",
        },
        events: {},
      },
    };
  },
  views: {
    favorites: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            if ($f.getParameterByName("_id")) {
              return "/folder/".concat($f.getParameterByName("_id"));
            }

            return "/file/".concat($c.getUserId(), "/favorites");
          },
          formatResponse: (responseData) => {
            if (responseData.data) {
              if (responseData.data[0]) {
                const allFiles = [];

                responseData.data?.forEach((itm) => {
                  if (!itm.fileKey) {
                    itm.isFolder = true;
                    itm.title = itm.name;

                    if (itm.root == "library") {
                      itm.url = "/files/library?_id=".concat(itm._id);
                    } else if (itm.root?.type == "space") {
                      itm.url = "/files/space/".concat(
                        itm.root._id,
                        "?_id=",
                        itm._id
                      );
                    } else {
                      itm.url = "/files?_id=".concat(itm._id);
                    }

                    itm.size = "-";
                    itm.mimetype = "folder";
                    allFiles.unshift(itm);
                  } else {
                    itm.url = "/files/preview/".concat(itm._id);

                    allFiles.push(itm);
                  }
                });

                responseData.data = allFiles;

                return responseData;
              }
            }

            return [];
          },
          replace: {
            start: 0,
            offset: 20,
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Table";
        },
        configuration: {
          headerTemplate: function () {
            return this.state.specialProperties.header.component;
          },
          emptyTemplate: (args) => {
            let newArgs = { ...args };

            newArgs.viewOnly = true;
            newArgs.subtitle = "Your favorite files will appear here";
            return $f.getValue(EmptyStates.defaultFiles, newArgs);
          },
          tableConfiguration: {
            headers: {
              fullName: {
                attributes: {
                  className: "w-50",
                },
              },
            },
            cellTemplates: {
              title: (data) => {
                return $c.createFileTitleForTable(data);
              },
              updatedAt: (value) => {
                return $c.formatDateTime(value.props.value);
              },
            },
          },
          includeProperties: () => {
            return ["title", "optionsfortable", "updatedAt", "size"];
          },
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
  },
};

export default Favorites;
