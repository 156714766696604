import { Component } from "react";
import $c from "../../../modules/functions";
import $f from "../../../src";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import ReactPlayer from "react-player";
import ReactAudioPlayer from "react-audio-player";
import { ReactReader } from "react-reader";
import { withRouter } from "react-router";

class FilePreview extends Component {
  constructor(props) {
    super(props);

    this.getTrainingInfoFile = this.getTrainingInfoFile.bind(this);
    this.updateTrainingReadingTime = this.updateTrainingReadingTime.bind(this);

    this.state = {
      loaded: false,
      trainingInfo: {},
    };
  }

  componentDidMount() {
    const { data, module, view } = this.props;

    this.detailPanel = this.detailPanel.bind(this);
    this.epubLocationChanged = this.epubLocationChanged.bind(this);

    this.setState({ loaded: true, data, module, view });

    document.body.style.paddingTop = "3.7rem";
    document.body.style.backgroundColor = "#f6f7f9";

    if ($f.getQueryPath(3).startsWith("t-")) {
      this.getTrainingInfoFile();

      if ($c.userIsMember()) {
        const trainingReading = setInterval(
          this.updateTrainingReadingTime,
          $c.getUserProperty("intervalChecker")
        );
        this.setState({ trainingReading: trainingReading });
      }
    }
  }

  componentWillUnmount() {
    if (this.state.trainingReading) {
      clearInterval(this.state.trainingReading);
    }
  }

  updateTrainingReadingTime() {
    let _this = this;

    $f.fetch(
      "/training/{id}/duration",
      "PATCH",
      [],
      {},
      {
        id: () => {
          return $f.getQueryPath(3).replace("t-", "");
        },
      },
      (resp) => {}
    );
  }

  getTrainingInfoFile() {
    let _this = this;
    $f.fetch(
      "/agenda-file/{id}/isDownloadable",
      "GET",
      null,
      {},
      {
        id: () => {
          return $f.getQueryPath(3).replace("t-", "");
        },
      },
      (resp) => {
        if (resp.data) {
          try {
            _this.setState({
              trainingInfo: {
                isDownlolable: resp.data.data[0],
                moduleName: resp.data.data[1].moduleName,
              },
            });
          } catch (e) {}
        }
      }
    );
  }

  detailPanel() {
    const data = this.state.data[0];
    return (
      <div>
        <span className="d-block display-4">File details</span>
        <div>
          <div className="form-group mb-4">
            <label className="label-top w-100 strong">Name</label>
            <div>{data._id.data.title}</div>
          </div>
          {this.state.trainingInfo.moduleName ? (
            <div className="form-group mb-4">
              <label className="label-top w-100 strong">Module</label>
              <div>{this.state.trainingInfo.moduleName}</div>
            </div>
          ) : (
            ""
          )}
          <div className="form-group mb-4">
            <label className="label-top w-100 strong">Owner</label>
            <div>
              {$c.createAvatar(
                data._id.data.owner.profileImage,
                data._id.data.owner.fullName,
                ""
              )}
            </div>
          </div>
          <div className="form-group mb-4">
            <label className="label-top w-100 strong">Size</label>
            <div>{data._id.data.size}</div>
          </div>
          <div className="form-group mb-4">
            <label className="label-top w-100 strong">Description</label>
            <div className="text-break">
              {data._id.data.description ? data._id.data.description : "-"}
            </div>
          </div>
          {$f.getQueryPath(3).startsWith("t-") ||
          $f.getQueryPath(3).startsWith("a-") ? (
            ""
          ) : (
            <>
              <div className="form-group mb-4">
                <label className="label-top w-100 strong">Keywords</label>
                <div>
                  {data._id.data.keywords && data._id.data.keywords.length > 0
                    ? data._id.data.keywords.join(", ")
                    : "-"}
                </div>
              </div>
              <div className="form-group mb-4">
                <label className="label-top w-100 strong">Authors</label>
                <div>
                  {data._id.data.authors && data._id.data.authors.length > 0
                    ? data._id.data.authors.join(", ")
                    : "-"}
                </div>
              </div>
            </>
          )}
          <div className="form-group mb-4">
            <label className="label-top w-100 strong">Uploaded on</label>
            <div>{$c.formatDateTime(data._id.data.createdAt)}</div>
          </div>
          <div className="form-group mb-4">
            <label className="label-top w-100 strong">Last modified</label>
            <div>{$c.formatDateTime(data._id.data.updatedAt)}</div>
          </div>
        </div>
      </div>
    );
  }

  epubLocationChanged(epubcifi) {
    this.setState({ location: epubcifi });
  }

  render() {
    if (this.state.loaded) {
      const data = this.state.data[0];

      let previewWrapper = [];

      switch (data._id.data.mimetype) {
        case "video/mp4":
          previewWrapper = (
            <div className="player-wrapper h-100">
              <ReactPlayer
                className="react-player"
                url={data.url.value}
                width="100%"
                height="100%"
                controls="true"
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                      onContextMenu: (e) => e.preventDefault(),
                    },
                  },
                }}
              />
            </div>
          );
          break;

        case "audio/mpeg":
          previewWrapper = (
            <div className="w-100 h-100">
              <ReactAudioPlayer
                src={data.url.value}
                autoPlay
                controls
                className="d-block m-auto mt-5"
              />
            </div>
          );
          break;

        case "application/epub+zip":
          previewWrapper = (
            <div className="w-100 h-100">
              <ReactReader
                url={data.url.value}
                location={this.state.location}
                locationChanged={this.epubLocationChanged}
                epubOptions={{
                  allowScriptedContent: true,
                }}
              />
            </div>
          );
          break;

        default:
          previewWrapper = (
            <div className="w-100 h-100">
              <DocViewer
                config={{
                  header: {
                    disableHeader: true,
                    disableFileName: true,
                    retainURLParams: false,
                  },
                  csvDelimiter: ",", // "," as default,
                  pdfZoom: {
                    defaultZoom: 0.4, // 1 as default,
                    zoomJump: 0.2, // 0.1 as default,
                  },
                }}
                pluginRenderers={DocViewerRenderers}
                documents={[{ uri: data.url.value }]}
              />
            </div>
          );

          break;
      }

      return (
        <>
          <header className="fixed-top">
            <nav className="navbar navbar-expand-md bg-white top-navbar">
              <div className="ps-5 color-strong-grey text-ellipsis text-ellipsis-mobile-18 text-ellipsis-mobile-38 d-flex">
                <div
                  role="button"
                  className="m-auto"
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                >
                  <i className="fas fa-chevron-left me-2  fa-lg color-grey"></i>
                </div>
                <span className="display-5 mb-0 ">{data.title.component}</span>
              </div>
              <div className="m-auto me-2" style={{ minWidth: "8rem" }}>
                {!$f.getQueryPath(3).startsWith("t-") ? (
                  <button
                    className="btn btn-sm btn-purple"
                    onClick={() => {
                      $c.downloadFile(
                        data.downloadUrl.value,
                        data._id.data.title
                      );
                    }}
                  >
                    <i className="fas fa-file-download me-2"></i>Download
                  </button>
                ) : this.state.trainingInfo.isDownlolable ? (
                  <button
                    className="btn btn-sm btn-purple"
                    onClick={() => {
                      $c.downloadFile(
                        data.downloadUrl.value,
                        data._id.data.title
                      );
                    }}
                  >
                    <i className="fas fa-file-download me-2"></i>Download
                  </button>
                ) : (
                  ""
                )}
                <button
                  className="btn btn-sm  btn-white display-lt-md-block ms-2"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <i className="fas fa-info-circle"></i>
                </button>
              </div>
            </nav>
          </header>
          <main
            className="container-fluid d-flex p-0"
            style={{ height: "calc(100vh - 4rem)" }}
          >
            <div className="wrapper w-100 p-2">{previewWrapper}</div>
            <div className="display-lt-md-none h-100">
              <div className="detail-panel border d-flex bg-white h-100">
                <div
                  className="collapse collapse-horizontal p-3"
                  id="preview-detail-panel"
                >
                  <div style={{ width: "20rem" }}>{this.detailPanel()}</div>
                </div>
                <div
                  className="detail-panel-column p-3"
                  data-bs-toggle="collapse"
                  data-bs-target="#preview-detail-panel"
                  role="button"
                >
                  <i className="fas fa-info-circle"></i>
                </div>
              </div>
            </div>
          </main>
          <div
            className="offcanvas offcanvas-end"
            tabindex="-1"
            id="offcanvasRight"
            aria-labelledby="offcanvasRightLabel"
          >
            <div className="offcanvas-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">{this.detailPanel()}</div>
          </div>
        </>
      );
    }

    return "";
  }
}

export default withRouter(FilePreview);
