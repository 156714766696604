import $f from "../../../../src";
import BreakoutRooms from "./breakoutrooms";
import Chat from "./chat";
import MeetingFunctions from "./functions";
import Participants from "./participants";

const MeetingMenu = (props) => {
  switch (props.menu) {
    case "chat":
      return "";
    case "breakout-room":
      return (
        <BreakoutRooms key={$f.key()} meeting={props.meeting}></BreakoutRooms>
      );
    default:
      return (
        <Participants key={$f.key()} meeting={props.meeting}></Participants>
      );
  }
};
export default MeetingMenu;
