import parse from "html-react-parser";
import moment from "moment";
import { Component } from "react";
import { withRouter } from "react-router";
import $c from "../../modules/functions";
import $f from "../../src";

class JoinEventButton extends Component {
  constructor(props) {
    super(props);

    this.intervalFunction = () => {};

    this.state = {
      loaded: false,
      from: null,
      to: null,
    };
  }

  componentDidMount() {
    let events = [];

    this.refresh = this.refresh.bind(this);

    Object.keys(this.props.property.events)?.forEach((element, idx) => {
      this[element] = this.props.property.events[element];
      this[element] = this[element].bind(this);
      events[element] = this[element];
    });

    const configuration = { ...this.props.property.configuration };

    if (configuration.eventIntervalFunction) {
      this.intervalFunction = configuration.eventIntervalFunction.bind(this);
    }

    this.setState({
      loaded: true,
      events: events,
      configuration: configuration,
      caption: $f.getValue(this.props.property.caption, this),
      eventIntervalFunction: configuration.eventIntervalFunction
        ? configuration.eventIntervalFunction
        : null,
      eventInterval: configuration.eventInterval
        ? configuration.eventInterval
        : 1000,
      attributes: { ...this.props.property.attributes },
      extraStartTime: configuration.extraStartTime
        ? configuration.extraStartTime
        : 0,
      eventNotStartedText: configuration.eventNotStartedText
        ? $f.getValue(configuration.eventNotStartedText, this)
        : "",
      eventHasEndedText: configuration.eventHasEndedText
        ? $f.getValue(configuration.eventHasEndedText, this)
        : "",
      extraEndTime: configuration.extraEndTime
        ? $f.getValue(configuration.extraEndTime, this)
        : 0,
      hasEnded: configuration.hasEnded
        ? $f.getValue(configuration.hasEnded, this)
        : 0,
      from: configuration["fromDateProperty"]
        ? this.props.data[configuration["fromDateProperty"]]
        : this.props.data.from,
      to: configuration["toDateProperty"]
        ? this.props.data[configuration["toDateProperty"]]
        : this.props.data.to,
    });
  }

  refresh() {
    let _this = this;

    const duration = moment.duration(moment(this.state.from).diff(moment()));
    const hours = duration.asHours();

    let timeout = 60000;

    if (hours >= 24) {
      timeout = 86400000;
    } else if (hours < 1) {
      timeout = 60000;
    } else if (hours < 24) {
      timeout = 1800000;
    }

    setTimeout(function () {
      _this.setState({ reload: true });
    }, timeout);
  }

  render() {
    if (this.state.loaded) {
      if (this.state.from && this.state.to) {
        let eventStatus = $c
          .checkEventDateStatus(
            this.state.from,
            this.state.to,
            this.state.extraStartTime,
            this.state.extraEndTime
          )
          .toLowerCase();

        if (this.state.hasEnded) {
          eventStatus = "ended";
        }

        switch (eventStatus) {
          case "ended":
            return this.state.eventHasEndedText;

          case "join":
            return (
              <button
                type="button"
                {...this.state.events}
                {...this.state.attributes}
              >
                {this.state.caption}
              </button>
            );

          case "pending":
            this.refresh();
            return this.state.eventNotStartedText;

          default:
            return "";
        }
      }
    }

    return "";
  }
}

export default withRouter(JoinEventButton);
