import React, { Component } from "react";
import $c from "../../../../modules/functions";
import $f from "../../../../src";

class TestOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loaded: false,
    };
  }

  componentDidMount() {
    const { data, module, view, specialProperties } = this.props;

    let templateConfiguration = view.template.configuration;

    const innerTemplates = ["header"];

    innerTemplates.map((itm, idx) => {
      let tmp = templateConfiguration[itm + "Template"]
        ? templateConfiguration[itm + "Template"]
        : () => {};
      this[itm + "Template"] = tmp.bind(this);
    });

    this.setState({
      specialProperties,
      data,
      loaded: true,
      view,
      module,
    });
  }

  render() {
    if (this.state.loaded) {
      const data = this.state.data[0];

      let examGrade = null;

      if (data._id.data.passingGrade) {
        if (data._id.data.userExamInfo) {
          if (
            data._id.data.userExamInfo.status == "Fail" ||
            data._id.data.userExamInfo.status == "Pass" ||
            data._id.data.userExamInfo.status == "No grade"
          ) {
            if (data._id.data.userExamInfo.status == "Fail") {
              examGrade = (
                <span className="badge bg-danger p-2 color-white rounded me-1">
                  {data._id.data.userExamInfo.status}
                </span>
              );
            }
            if (data._id.data.userExamInfo.status == "Pass") {
              examGrade = (
                <span className="badge bg-success p-2 color-white rounded me-1">
                  {data._id.data.userExamInfo.status}
                </span>
              );
            }
          }
        }
      }

      return (
        <React.Fragment>
          {this.headerTemplate()}
          <div className="display-3-no-bold border-bottom-1">Overview</div>
          {data.instructions.value ? (
            <div className=" mt-3 color-grey">
              {data.instructions.component}
            </div>
          ) : (
            ""
          )}
          <div className="row mt-4">
            <div className="col-md-6">
              {data._id.data.userExamInfo ? (
                <div className="bg-light-grey p-2 rounded mb-2 d-flex">
                  <span className="p-2 rounded bg-white me-2">
                    <img src="/uni/svg/star.svg" style={{ width: "20px" }} />
                  </span>
                  {data._id.data.userExamInfo.status == "Not Evaluated" ? (
                    <span className="mt-auto mb-auto">
                      Your test hasn't been evaluated yet! You will receive an
                      email when the evaluation is completed.
                    </span>
                  ) : (
                    <span className="mt-auto mb-auto">
                      {examGrade} Your final grade is{" "}
                      {data.attendeetestgrade.component}%
                    </span>
                  )}
                </div>
              ) : (
                ""
              )}
              <div className="bg-light-grey p-2 rounded mb-2 d-flex">
                <span className="p-2 rounded bg-white me-2">
                  <img src="/uni/svg/datetime.svg" style={{ width: "20px" }} />
                </span>
                <span className="mt-auto mb-auto">{data.date.component}</span>
              </div>
              <div className="bg-light-grey p-2 rounded mb-2 d-flex">
                <span className="p-2 rounded bg-white me-2">
                  <img
                    src="/uni/svg/testdutation.svg"
                    style={{ width: "20px" }}
                  />
                </span>
                <span className="mt-auto mb-auto">
                  {data.examDuration.component} duration
                </span>
              </div>
              {!data._id.data.userExamInfo && $c.userIsAdmin() ? (
                <div className="bg-light-grey p-2 rounded mb-2 d-flex">
                  <span className="p-2 rounded bg-white me-2">
                    <img src="/uni/svg/user.svg" style={{ width: "20px" }} />
                  </span>
                  <span className="mt-auto mb-auto">
                    {data.attendeessubmittedtest.component} attendees have
                    submitted the test
                  </span>
                </div>
              ) : (
                ""
              )}
              <div className="bg-light-grey p-2 rounded mb-2 d-flex">
                <span className="p-2 rounded bg-white me-2">
                  <img
                    src="/uni/svg/graded_practicetest.svg"
                    style={{ width: "20px" }}
                  />
                </span>
                <span className="mt-auto mb-auto">
                  {$c.testGrade(data._id.data.passingGrade, "overview")}
                </span>
              </div>
              <div className="bg-light-grey p-2 rounded mb-2 d-flex">
                <span className="p-2 rounded bg-white me-2">
                  <img
                    src="/uni/svg/check-mark.svg"
                    style={{ width: "20px" }}
                  />
                </span>
                <span className="mt-auto mb-auto">
                  {$c.testQuestionsCount(data.questions.value)}
                </span>
              </div>
            </div>
            <div className="col-md-6"></div>
          </div>
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default TestOverview;
