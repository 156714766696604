import React, { Component } from "react";
import parse from "html-react-parser";
import $c from "../../../../../modules/functions";
import $f from "../../../../../src";
import { Link } from "react-router-dom";

import PerfectScrollbar from "react-perfect-scrollbar";
import Modals from "../../../../../modules/modals";

class ConferenceSpeakers extends Component {
  constructor(props) {
    super(props);

    this.setActiveTab = this.setActiveTab.bind(this);

    this.state = {
      data: [],
      loaded: false,
      training: {},
    };
  }

  componentDidMount() {
    const { data, module, view, specialProperties, response } = this.props;

    let templateConfiguration = view.template.configuration;

    const innerTemplates = ["header"];

    innerTemplates.map((itm, idx) => {
      let tmp = templateConfiguration[itm + "Template"]
        ? templateConfiguration[itm + "Template"]
        : () => {};
      this[itm + "Template"] = tmp.bind(this);
    });

    this.setState({
      activeTab: 0,
      specialProperties,
      data,
      loaded: true,
      view,
      module,
      response,
    });

    // document.body.style.paddingTop = "3.7rem";
  }

  setActiveTab(index) {
    this.setState({ activeTab: index });
  }

  render() {
    if (this.state.loaded) {
      const data = this.state.response.data[0];

      return (
        <React.Fragment>
          {this.headerTemplate()}
          <div className="pb-5 container-fluid me-0 ms-0 ps-0">
            <div className="display-3-no-bold border-bottom-1">Speakers</div>
            <div className="row mt-3">
              {$c.conferenceSpeakers(data.days).map((el) => {
                return (
                  <div
                    className="col-md-4 p-3"
                    role="button"
                    onClick={() => {
                      // Modals.contacts.userProfile({
                      //   userId: el._id,
                      // });
                      Modals.events.openSpeaker({ speaker: el, data: data });
                    }}
                  >
                    {$c.createAvatar(
                      el.profileImage,
                      el.firstName,
                      el.lastName,
                      "avatar-large",
                      el.jobTitle
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default ConferenceSpeakers;
