import axios from "axios";
import Types from "./types/types";
import { v4 as uuidv4 } from "uuid";
import React from "react";
import ReactDOM from "react-dom";
import $c from "./modules/functions";
import { AlertList } from "react-bs-notifier";

const $f = {
  stringToObject: (string) => {
    return string.split(".").reduce((o, i) => o[i]);
  },
  getObjectValueFromPath(obj, path, delimeter = ".") {
    return path.split(delimeter).reduce(function (o, k) {
      return o && o[k];
    }, obj);
  },
  key: () => {
    return "key-".concat(uuidv4());
  },
  getValue(input, args) {
    return typeof input === "function" ? input(args) : input ? input : null;
  },
  validateRefValues: (ref) => {
    const currentRef = ref.current;

    let allValid = true;

    if (currentRef) {
      Object.keys(currentRef.refs).forEach((el) => {
        if (currentRef.refs[el].current) {
          if (currentRef.refs[el].current.validate) {
            let isValid = currentRef.refs[el].current.validate();

            if (!isValid) {
              allValid = false;
            }
          }
        }
      });
    }

    return allValid;
  },
  getRefValues: (ref) => {
    const currentRef = ref.current;

    let output = {};

    if (currentRef) {
      Object.keys(currentRef.refs).forEach((el) => {
        if (currentRef.refs[el].current) {
          let propertyName = el.split("-")[0];

          output[propertyName] = {
            value: currentRef.refs[el].current.state.value,
          };
        }
      });
    }

    return output;
  },
  notifications: [],
  createNotificationFromResponse(
    data,
    onlyfail = false,
    closeModal = false,
    updateMain = false
  ) {
    if (data.exceptions) {
      data.exceptions.map((itm, idx) => {
        $f.createNotification({
          message: itm.errorDescription,
          type: "danger",
        });
      });
    }
    if (!onlyfail) {
      if (data.message) {
        $f.createNotification({ message: data.message, type: "success" });
        if (closeModal) {
          window["primaryModal"].setState({ show: false });
        }
        if (updateMain) {
          window["middle"].doWork();
        }
      }
    }
  },
  createNotification(args) {
    if (args.notification) {
      const idx = $f.notifications.indexOf(args.notification);

      if (idx >= 0) {
        $f.notifications = [
          ...$f.notifications.slice(0, idx),
          ...$f.notifications.slice(idx + 1),
        ];
      }
    } else {
      $f.notifications.push({
        id: new Date().getTime(),
        type: args.type,
        message: <span className="pr-2">{args.message}</span>,
      });
    }
    ReactDOM.render(
      <AlertList
        alerts={$f.notifications}
        timeout={5000}
        onDismiss={(item) => {
          $f.createNotification({ notification: item });
        }}
      />,
      document.getElementById("notification")
    );
  },
  moduleSpecialProperties: (
    data = [],
    properties = {},
    module = null,
    view = null,
    moduleReferer = null
  ) => {
    let _moduleDataRecord = {};

    if (properties) {
      Object.keys(properties).forEach((attr) => {
        let _currentAttribute = properties[attr];

        _moduleDataRecord[attr] = {};

        let Type = _currentAttribute.type
          ? Types[$f.getValue(_currentAttribute.type)]
          : Types["text"];

        const typeReferer = attr;

        moduleReferer["refs"][typeReferer] = React.createRef();

        let value = _currentAttribute["formatValue"]
          ? _currentAttribute["formatValue"](data)
          : data;

        _moduleDataRecord[attr]["component"] = (
          <Type
            property={properties[attr]}
            key={$f.key()}
            value={value}
            module={moduleReferer}
            ref={moduleReferer["refs"][typeReferer]}
            data={data}
          ></Type>
        );

        _moduleDataRecord[attr]["data"] = data;
        _moduleDataRecord[attr]["property"] = properties[attr];
      });
    }

    return _moduleDataRecord;
  },
  //enrich external data to set the module data used in the template
  moduleData: (
    data = [],
    properties = {},
    module = null,
    view = null,
    moduleReferer = null
  ) => {
    let _moduleData = [];

    //get module template name
    let moduleTemplate = module.template;

    //get module template configuration if any (optional)
    let moduleTemplateConfiguration = moduleTemplate.configuration;

    //get module included attributes (optional)
    let moduleIncludeAttributes = $f.getValue(
      moduleTemplateConfiguration.includeProperties,
      { data, properties, module, view, moduleReferer }
    );

    //get module excluded attributes (optional)
    let moduleExcludeAttributes = $f.getValue(
      moduleTemplateConfiguration.excludeProperties,
      { data, properties, module, view, moduleReferer }
    );

    moduleReferer["refs"] = {};

    //loop external data and enrich them
    data?.forEach((row, idx) => {
      let _moduleDataRecord = {};

      let modulePropertiesArguments = $f.getValue(
        moduleTemplate.properties,
        row
      );
      // console.log(modulePropertiesArguments);

      let moduleProperties = properties(
        modulePropertiesArguments,
        row,
        module,
        view
      );
      Object.keys(moduleProperties).forEach((attr) => {
        let _showOnTemplate = true;

        _moduleDataRecord[attr] = {};

        if (moduleExcludeAttributes) {
          if (moduleExcludeAttributes?.indexOf(attr) >= 0) {
            _showOnTemplate = false;
          } else {
            _showOnTemplate = true;
          }
        }

        if (moduleIncludeAttributes) {
          if (moduleIncludeAttributes?.indexOf(attr) >= 0) {
            _showOnTemplate = true;
          } else {
            _showOnTemplate = false;
          }
        }

        let _currentAttribute = properties(row)[attr];

        let Type = _currentAttribute.type
          ? Types[$f.getValue(_currentAttribute.type)]
          : Types["text"];

        let path = _currentAttribute.path
          ? $f.getValue(_currentAttribute.path)
          : attr;

        let typeReferer = attr;

        if (row["_id"]) {
          typeReferer = attr.concat("-" + row["_id"]);
        } else {
          typeReferer = attr.concat("-0");
        }

        moduleReferer["refs"][typeReferer] = React.createRef();

        let value = moduleProperties[attr]["formatValue"]
          ? moduleProperties[attr]["formatValue"](row[path], row, moduleReferer)
          : row[path];

        _moduleDataRecord[attr]["component"] = (
          <Type
            property={moduleProperties[attr]}
            key={$f.key()}
            initialValue={value}
            value={value}
            module={moduleReferer}
            data={row}
            rowIndex={idx}
            dataLength={data.length}
            ref={moduleReferer["refs"][typeReferer]}
          ></Type>
        );

        _moduleDataRecord[attr]["showOnTemplate"] = _showOnTemplate;
        _moduleDataRecord[attr]["data"] = row;
        _moduleDataRecord[attr]["label"] = $f.getValue(
          moduleProperties[attr]["label"],
          row
        );
        _moduleDataRecord[attr]["property"] = moduleProperties[attr];

        _moduleDataRecord[attr]["value"] = value;
      });

      _moduleData.push(_moduleDataRecord);
    });

    return _moduleData;
  },
  mapper: (data = [], properties = {}) => {
    const newData = [];
    data.forEach((row) => {
      let newRow = {};
      Object.keys(properties).forEach((property) => {
        if (properties[property]["path"]) {
          if (typeof properties[property]["path"] === "function") {
            newRow[property] = properties[property]["path"](row);
          } else {
            newRow[property] = $f.getObjectValueFromPath(
              row,
              properties[property]["path"]
            );
          }
        } else {
          newRow[property] = row[property] ? row[property] : null;
        }
      });
      newData.push(newRow);
    });
    return newData;
  },
  fetch: (
    api,
    method = "GET",
    data = null,
    headers = {},
    replace = {},
    callback = null,
    responseType = null,
    params = null,
    onUploadProgress = null,
    cancelToken = null,
    showErrors = true,
    onError = null
  ) => {
    Object.keys(replace)?.forEach((el) => {
      api = api.replace("{" + el + "}", replace[el]);
    });

    headers = $c.addHeadersBeforeFetch(headers);

    new axios(
      {
        url: window["baseurl"](api),
        method: method,
        data: data,
        headers: headers,
        responseType: responseType,
        params: params,
        onUploadProgress: onUploadProgress,
        cancelToken: cancelToken,
      },
      {}
    )
      .then(callback)
      .catch((error) => {
        $c.generalFetchErrorResponse(error);
        if (showErrors) {
          if (error.response) {
            $f.createNotificationFromResponse(error.response.data);
          }
        }
        if (onError) {
          $f.getValue(onError, error);
        }
        console.log(error);
      });
  },
  getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  },
  getQueryPath(index, url = window.location.pathname) {
    let splitUrl = url.split("/");

    return splitUrl[index];
  },
  getValuesFromModule: (module) => {
    let output = {};

    Object.keys(module.refs)?.forEach((el) => {
      const current = module.refs[el].current;

      if (current) {
        if (!current.state.excludeFromModuleValues) {
          output[el.split("-")[0]] = current.state.value;
        }
      }
    });

    return output;
  },
  getModuleView: (module) => {
    return module.state.view ? module.state.view : null;
  },
  addScript(url, nocache) {
    const script = document.createElement("script");
    let cacheBreak = new Date().valueOf();
    if (nocache) {
      script.src = url;
    } else {
      script.src = url + "?" + cacheBreak;
    }
    script.async = false;
    document.body.appendChild(script);
  },
  addCSS(url, nocache) {
    const css = document.createElement("link");
    css.type = "text/css";
    css.rel = "stylesheet";
    let cacheBreak = new Date().valueOf();
    if (nocache) {
      css.href = url;
    } else {
      css.href = url + "?" + cacheBreak;
    }
    document.getElementsByTagName("head")[0].appendChild(css);
  },
};
export default $f;
