import parse from "html-react-parser";
import { Component } from "react";
import "react-widgets/styles.css";
import Data from "../../modules/data/data";
import moment from "moment";
import SimpleReactValidator from "simple-react-validator";

class Duration extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.changeHours = this.changeHours.bind(this);
    this.changeMinutes = this.changeMinutes.bind(this);
    this.parentChange = function () {};

    this.validator = new SimpleReactValidator({
      validators: {
        compareDuration: {
          message: "Duration cannot be 0",
          rule: (val, params, validator) => {
            if (
              val?.hours?.toString() == "0" &&
              val?.minutes?.toString() == "0"
            ) {
              return false;
            }
            return true;
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)),
        },
      },
    });

    this.state = {
      loaded: false,
      value: {},
    };
  }

  validate() {
    const isValid = this.validator.allValid();

    this.setState({ isValid });
    this.validator.showMessages();
    this.forceUpdate();

    return isValid;
  }

  onChange(e) {}

  componentDidMount() {
    let events = [];

    Object.keys(this.props.property.events)?.forEach((element, idx) => {
      if (!element.toLowerCase().startsWith("onchange")) {
        this[element] = this.props.property.events[element];
        this[element] = this[element].bind(this);
        events[element] = this[element];
        if (element == "overrideOnChange") {
          this.onChange = this.props.property.events[element];
          this.onChange = this.onChange.bind(this);
          events["onChange"] = this.onChange;
        }
      } else {
        this["customOnChange"] = this.props.property.events[element];
        this["customOnChange"] = this["customOnChange"].bind(this);
      }
    });

    if (this.props.property.parentChange) {
      this.parentChange = this.props.property.parentChange.bind(this);
    }

    let _value = {
      hours: 1,
      minutes: 0,
    };

    console.log(this.props.value);

    if (this.props.value) {
      if (
        (this.props.value.hours !== null &&
          typeof this.props.value.hours !== "undefined") ||
        (this.props.value.minutes !== null &&
          typeof this.props.value.minutes !== "undefined")
      ) {
        _value = {
          hours: this.props.value.hours,
          minutes: this.props.value.minutes,
        };
      }
    }

    this.setState({
      loaded: true,
      events: events,
      attributes: { ...this.props.property.attributes },
      value: _value,
      editable: this.props.property.editable === false ? false : true,
      useCustomUI: this.props.property.useCustomUI,
    });
  }

  changeMinutes(e) {
    this.setState({
      value: { minutes: e.target.value, hours: this.state.value.hours },
    });

    const _this = this;
    setTimeout(function () {
      _this.onChange(_this);
    }, 0);

    setTimeout(function () {
      _this.parentChange({
        hours: parseInt(_this.state.value.hours),
        minutes: parseInt(e.target.value),
      });
    }, 0);
  }

  changeHours(e) {
    this.setState({
      value: {
        hours: parseInt(e.target.value),
        minutes: parseInt(this.state.value.minutes),
      },
    });

    const _this = this;
    setTimeout(function () {
      _this.onChange(_this);
    }, 0);

    setTimeout(function () {
      _this.parentChange({
        hours: parseInt(e.target.value),
        minutes: parseInt(_this.state.value.minutes),
      });
    }, 0);
  }

  editable() {
    return (
      <>
        <div className="d-flex w-75">
          <select
            className="form-control form-select  time-picker "
            onChange={this.changeHours}
            defaultValue={this.state.value.hours?.toString()}
          >
            {Data.Duration.hours.data.map((el) => (
              <option value={el}>{el.concat(" hours")}</option>
            ))}
          </select>
          <span className="pe-2 ps-2 pt-1">and</span>
          <select
            className="form-control form-select   time-picker"
            onChange={this.changeMinutes}
            defaultValue={this.state.value.minutes?.toString()}
          >
            {Data.Duration.minutes.data.map((el) => (
              <option value={el}>{el.concat(" minutes")}</option>
            ))}
          </select>
        </div>
        <span className="text-danger mt-2 d-block">
          {this.validator.message(
            this.props.property.label ? this.props.property.label : "duration",
            this.state.value,
            "compareDuration"
          )}
        </span>
      </>
    );
  }

  nonEditable() {
    let out = "";
    if (this.state.value.hours) {
      if (this.state.value.hours === 1) {
        out = this.state.value.hours + " hour";
      } else {
        out = this.state.value.hours + " hours";
      }
    }

    if (this.state.value.minutes) {
      if (out) {
        out = out.concat(" and ");
      }

      out = this.state.value.minutes + " minutes";
    }

    return typeof this.state.value === "string" ? parse(this.state.value) : out;
  }

  customUI() {}

  render() {
    if (this.state.loaded) {
      if (this.state.useCustomUI) {
        return this.customUI();
      } else if (this.state.editable) {
        return this.editable();
      } else {
        return this.nonEditable();
      }
    }

    return "";
  }
}

export default Duration;
