const Projects = {
  properties: () => {
    return {
      _id: {
        label: "",
        path: "_id",
        type: "text",
        events: {},
        attributes: {},
      },
      email: {
        label: "",
        type: "text",
        events: {},
        attributes: {},
      },
    };
  },
  views: {
    list: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/restphp/users/";
          },
          dataPath: () => {},

          replace: {},
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Form";
        },
        configuration: {
          includeProperties: ["email", "_id"],
          excludeProperties: () => {},
          primaryKey: "_id",
        },

        properties: () => {},
      },
    },
  },
};

export default Projects;
