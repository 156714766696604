import { Component } from "react";
import parse from "html-react-parser";
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";
import $c from "../../../../modules/functions";
import $f from "../../../../src";
import SimpleReactValidator from "simple-react-validator";

class UserPublicForm extends Component {
  constructor(props) {
    super(props);

    this.updateAnswerData = this.updateAnswerData.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.fetchPublicForm = this.fetchPublicForm.bind(this);
    this.validate = this.validate.bind(this);

    this.validator = new SimpleReactValidator({
      validators: {
        hasUserAnswer: {
          message: "This field is required",
          rule: (val, params, validator) => {
            let hasAnswer = false;

            val.answers.forEach((el) => {
              if (val.type === "multipleChoice") {
                if (el.userAnswer) {
                  hasAnswer = true;
                }
              } else {
                if (el.answer) {
                  hasAnswer = true;
                }
              }
            });

            return hasAnswer;
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)),
        },
      },
    });

    this.state = {
      loaded: false,
    };
  }

  validate() {
    let isValid = this.validator.allValid();

    this.setState({ isValid });
    this.validator.showMessages();
    this.forceUpdate();

    return isValid;
  }

  componentDidMount() {
    const { data, module, view } = this.props;

    const formId = this.props.response.data[0]._id;

    this.setState({
      loaded: false,
      data,
      module,
      view,
      formId,
      confirmTerms: false,
    });

    document.body.style.paddingTop = "0rem";
    document.body.style.backgroundColor = "#f6f7f9";

    setTimeout(this.fetchPublicForm, 0);
  }

  updateAnswerData(idx, ansidx, property, data) {
    let form = this.state.form;

    if (!form.questions[idx].answers[ansidx]) {
      form.questions[idx].answers.push({ userAnswer: "" });
    }

    form.questions[idx].answers[ansidx][property] = data;

    this.setState({ form });
  }

  fetchPublicForm() {
    let _this = this;
    $f.fetch(
      "/user-forms/new-user-form/{id}",
      "GET",
      null,
      {},
      {
        id: () => {
          return _this.state.formId.replace("m-", "").replace("v-", "");
        },
      },
      (resp) => {
        let form = resp.data.data[0];
        if (!$c.isLoggedIn() && !form.isAnonymous) {
          for (var i = 0; i < window["PUBLIC_FORM_USER_FIELDS_COUNT"](); i++) {
            form.questions[i].type = "text";
          }
        }

        _this.setState({ form: form, loaded: true });
      },
      null,
      null,
      null,
      null,
      false
    );
  }

  submitForm() {
    if (!this.validate()) {
      return "";
    }

    const { form } = this.state;

    let _this = this;

    form.questions?.forEach((el) => {
      if (el.type === "text") {
        el.type = "essayType";
      }
      if (el.type !== "multipleChoice") {
        el.answers?.forEach((a) => {
          delete a.userAnswer;
        });
      }
    });

    const postData = {
      formId: this.state.formId,
      questions: [...form.questions],
    };

    window["primaryModal"].setState({
      show: true,
      size: "default",
      title: (
        <h2 className="display-3-no-line-height header-ellipsis">
          Submit form?
        </h2>
      ),
      footer: [
        <button
          type="button"
          onClick={() => {
            window["primaryModal"].close();
          }}
          className="btn btn-sm btn-white"
        >
          Cancel
        </button>,
        <button
          type="button"
          onClick={() => {
            let headers = {};

            if (!$c.isLoggedIn()) {
              headers = {
                Authorization: "Bearer null",
              };
            }

            $f.fetch(
              "/user-forms",
              "POST",
              postData,
              headers,
              {},
              (resp) => {
                if (resp.status === 200) {
                  $f.createNotification({
                    message: "The form has been submited",
                    type: "success",
                  });
                }

                window["primaryModal"].close();

                setTimeout(() => {
                  $f.getValue(window["PUBLIC_FORM_RETURN_HOME"], this);
                }, 500);
              },
              null,
              null,
              null,
              null,
              true,
              () => {
                window["primaryModal"].close();
              }
            );
          }}
          className="btn btn-sm btn-purple"
        >
          Submit form
        </button>,
      ],
      body: (
        <p>
          You are about to submit your form. If you want to review your answers
          click cancel, or click submit form to submit it.
        </p>
      ),
    });
  }

  render() {
    if (this.state.loaded) {
      const { form } = this.state;

      return (
        <>
          <header className="fixed-top">
            <nav className="navbar navbar-expand-md bg-white  top-navbar">
              <div className="container-fluid viewport-padding  color-strong-grey">
                <span className="display-5 mb-0">
                  {window["COMPANY_LOGO"]()}
                </span>
                <div className="text-md-end mt-auto mb-auto">
                  <span className="strong text-ellipsis">
                    {parse(form.title)}
                  </span>
                </div>
              </div>
            </nav>
          </header>

          <main className="container-fluid viewport-padding bg-light-grey pb-5">
            <div className="wrapper" style={{ paddingTop: "3.563rem" }}>
              <div className="notification"></div>
              <div className="container p-3 rounded border mb-3 mt-5 bg-white">
                <label>
                  <strong> {parse(form.title)}</strong>
                </label>
                <div>{parse(form.instructions)}</div>
              </div>
              <div className="container p-3 rounded border mb-3 mt-3 bg-white">
                <div>
                  {form.questions?.map((el, idx) => {
                    let borderBottom = "";

                    if (idx < form.questions.length - 1) {
                      borderBottom = "border-bottom-1";
                    }

                    return (
                      <div
                        className={"pt-3 pb-3 ".concat(borderBottom)}
                        key={"question".concat(idx)}
                      >
                        {!$c.isLoggedIn() && !form.isAnonymous ? (
                          idx >= window["PUBLIC_FORM_USER_FIELDS_COUNT"] ? (
                            <div className="d-flex">
                              <label className="w-100 ">
                                <strong>
                                  Question{" "}
                                  {idx -
                                    window["PUBLIC_FORM_USER_FIELDS_COUNT"] +
                                    1}
                                </strong>
                              </label>
                              <div
                                className="small color-grey text-end"
                                style={{ width: "8rem" }}
                              >
                                <span>{el.isRequired ? "* Required" : ""}</span>
                              </div>
                            </div>
                          ) : (
                            ""
                          )
                        ) : (
                          <div className="d-flex">
                            <label className="w-100 ">
                              <strong>Question {idx + 1}</strong>
                            </label>
                            <div
                              className="small color-grey text-end"
                              style={{ width: "8rem" }}
                            >
                              <span>{el.isRequired ? "* Required" : ""}</span>
                            </div>
                          </div>
                        )}
                        {!$c.isLoggedIn() && !form.isAnonymous ? (
                          <div className="d-flex">
                            <div className="w-100">{parse(el.question)}</div>
                            <div
                              className="small color-grey text-end"
                              style={{ width: "8rem" }}
                            >
                              <span>{el.isRequired ? "* Required" : ""}</span>
                            </div>
                          </div>
                        ) : (
                          <div>{parse(el.question)}</div>
                        )}
                        {el.image ? (
                          <img
                            src={window["baseurl"](
                              "/agenda-file/data/" +
                                el.image +
                                "?type=preview&jwt=" +
                                localStorage.getItem("jwt")
                            )}
                            key={"essayImage".concat(idx)}
                            className="img-fluid mb-3"
                          ></img>
                        ) : (
                          ""
                        )}
                        {/* <div className="color-grey mb-3">
                          {el.type == "multipleChoice" ? "Answers" : "Answer"}
                        </div> */}

                        {el.answers.map((ans, ansIdx) => {
                          if (el.type == "multipleChoice") {
                            let checked = false;

                            let icon = <Icon.FiCheck color="white" size={20} />;

                            let userAnswerText = "";
                            if (ans.userAnswer?.toString() == "true") {
                              checked = true;
                            }

                            return (
                              <div className="mb-2" key={$f.key()}>
                                <div className="d-flex">
                                  <Checkbox
                                    className="me-2"
                                    checked={checked}
                                    onChange={(checked) => {
                                      this.updateAnswerData(
                                        idx,
                                        ansIdx,
                                        "userAnswer",
                                        checked ? "true" : "false"
                                      );
                                    }}
                                    icon={
                                      <div
                                        style={{
                                          display: "flex",
                                          flex: 1,
                                          backgroundColor: checked
                                            ? "#4e2c95"
                                            : "#fff",
                                          alignSelf: "stretch",
                                        }}
                                      >
                                        {icon}
                                      </div>
                                    }
                                    borderColor={
                                      ans.userAnswer?.toString() == "true"
                                        ? "#4e2c95"
                                        : "#d7d7d7"
                                    }
                                    borderRadius={23}
                                    style={{
                                      overflow: "hidden",
                                      backgroundColor: "#fff",
                                    }}
                                    size={23}
                                  />

                                  <div className="w-100">{ans.answer}</div>
                                </div>
                              </div>
                            );
                          }
                        })}
                        {el.type === "essayType" ? (
                          <div className="mb-2">
                            <div className="d-flex">
                              <div className="w-100">
                                <textarea
                                  className="form-control"
                                  onChange={(e) => {
                                    this.updateAnswerData(
                                      idx,
                                      0,
                                      "answer",
                                      e.target.value
                                    );
                                  }}
                                >
                                  {el.answers[0] ? el.answers[0].answer : ""}
                                </textarea>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {el.type === "text" ? (
                          <div className="mb-2 mt-3">
                            <div className="d-flex">
                              <div className="w-100">
                                <input
                                  className="form-control"
                                  type="text"
                                  onChange={(e) => {
                                    this.updateAnswerData(
                                      idx,
                                      0,
                                      "answer",
                                      e.target.value
                                    );
                                  }}
                                  value={
                                    el.answers[0] ? el.answers[0].answer : ""
                                  }
                                ></input>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {el.isRequired ? (
                          <span className="text-danger mt-2 d-block">
                            {this.validator.message(
                              "question ".concat(1),
                              el,
                              "hasUserAnswer"
                            )}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
                </div>
                {form.type.startsWith("public") && !$c.isLoggedIn() ? (
                  <div className="mt-3 mb-2">
                    <div className="d-flex">
                      <Checkbox
                        className="me-2"
                        checked={this.state.confirmTerms}
                        onChange={(checked) => {
                          this.setState({ confirmTerms: checked });
                        }}
                        icon={
                          <div
                            style={{
                              display: "flex",
                              flex: 1,
                              backgroundColor: this.state.confirmTerms
                                ? "#4e2c95"
                                : "#fff",
                              alignSelf: "stretch",
                            }}
                          >
                            <Icon.FiCheck color="white" size={20} />
                          </div>
                        }
                        borderColor={
                          this.state.confirmTerms ? "#4e2c95" : "#d7d7d7"
                        }
                        borderRadius={23}
                        style={{
                          overflow: "hidden",
                          backgroundColor: "#fff",
                        }}
                        size={23}
                      />

                      <div className="w-100">
                        I confirm I have read and accept the terms and privacy
                        policy
                      </div>
                    </div>
                    <div className="">
                      <button
                        type="button"
                        className="btn btn-purple btn-sm ps-4 pe-4 text-white"
                        onClick={this.submitForm}
                        disabled={!this.state.confirmTerms}
                      >
                        Submit
                      </button>
                    </div>
                    <div className="mt-3 color-grey">
                      <small>
                        This site is protected by reCAPTCHA and{" "}
                        <a
                          href="https://policies.google.com/privacy?hl=en"
                          target="_blank"
                          className="text-primary"
                        >
                          Google Privacy Policy
                        </a>{" "}
                        and{" "}
                        <a
                          href="https://policies.google.com/terms?hl=en"
                          target="_blank"
                          className="text-primary"
                        >
                          Term
                        </a>{" "}
                        apply.
                      </small>
                    </div>
                  </div>
                ) : (
                  <div className="">
                    <button
                      type="button"
                      className="btn btn-purple btn-sm ps-4 pe-4"
                      onClick={this.submitForm}
                    >
                      Submit
                    </button>
                  </div>
                )}
              </div>
            </div>
          </main>
        </>
      );
    }

    return "";
  }
}

export default UserPublicForm;
