import { Component } from "react";
import $f from "../../src";
import Types from "../types";

class Multiple extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.updateMultipleData = this.updateMultipleData.bind(this);
    this.deleteRow = this.deleteRow.bind(this);
    this.addRow = this.addRow.bind(this);

    this.state = {
      loaded: false,
    };
  }

  onChange(e) {
    this.setState({
      value: e.target.value,
    });

    setTimeout(this["customOnChange"], 0);
  }

  componentDidMount() {
    let events = [];

    Object.keys(this.props.property.events)?.forEach((element, idx) => {
      if (!element.toLowerCase().startsWith("onchange")) {
        this[element] = this.props.property.events[element];
        this[element] = this[element].bind(this);
        events[element] = this[element];
      } else {
        this["customOnChange"] = { ...this.props.property.events[element] };
        this["customOnChange"] = this["customOnChange"].bind(this);
      }
    });

    this.setState({
      loaded: true,
      events: events,
      attributes: { ...this.props.property.attributes },
      value: this.props.value ? this.props.value : [],
      // value: [
      //   { text1: "text11", text2: "text21" },
      //   { text1: "text12", text2: "text22" },
      // ],
      editable: this.props.property.editable === false ? false : true,
      configuration: { ...this.props.property.configuration },
    });
  }

  updateMultipleData(property, index, component) {
    let value = [...this.state.value];

    value[index][property] = component.state.value;

    this.setState({ value });
  }

  deleteRow(index) {
    let value = [...this.state.value];
    value.splice(index, 1);
    this.setState({ value: value });
  }

  addRow() {
    this.setState({ value: [...this.state.value, {}] });
  }

  render() {
    if (this.state.loaded) {
      const { properties } = this.state.configuration;
      const _this = this;

      const output = [];

      this.state.value?.forEach((row, idx) => {
        let rowControl = {};

        Object.keys(properties)?.forEach((property) => {
          const _currentAttribute = properties[property];

          let Type = _currentAttribute.type
            ? Types[$f.getValue(_currentAttribute.type)]
            : Types["text"];

          // const customOnChange = _currentAttribute.events?.onChange
          //   ? function () {
          //       console.log(this);
          //       _this.updateMultipleData(
          //         this.props.multipleProperty,
          //         this.props.multipleIndex,
          //         this
          //       );
          //     }
          //   : _this.onChange;

          _currentAttribute.events["onBlur"] = function () {
            _this.updateMultipleData(
              this.props.multipleProperty,
              this.props.multipleIndex,
              this
            );
          };

          let value = _currentAttribute["formatValue"]
            ? _currentAttribute["formatValue"](row[property], row)
            : row[property];

          const control = (
            <Type
              property={_currentAttribute}
              key={$f.key()}
              value={value}
              module={this.props.module}
              data={row}
              multipleIndex={idx}
              multipleProperty={property}
            ></Type>
          );

          rowControl[property] = control;
        });

        let template = $f.getValue(this.state.configuration.template, {
          controls: rowControl,
          deleteRow: this.deleteRow,
          addRow: this.addRow,
          multipleIndex: idx,
        });

        output.push(template);
      });

      let wrapperTemplate = $f.getValue(
        this.state.configuration.wrapperTemplate,
        { template: output, addRow: this.addRow }
      );

      return wrapperTemplate;
    }

    return "";
  }
}

export default Multiple;
