import { Component } from "react";
import $f from "../../../../src";
import parse from "html-react-parser";
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";
import $c from "../../../../modules/functions";

class FormResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    const { data, module, view } = this.props;

    this.setState({
      loaded: true,
      data,
      module,
      view,
      form: this.props.response.data[0],
    });

    document.body.style.paddingTop = "0rem";
    document.body.style.backgroundColor = "#f6f7f9";
  }

  render() {
    if (this.state.loaded) {
      const data = this.state.data[0];
      const { form } = this.state;

      return (
        <>
          <header className="fixed-top">
            <nav className="navbar navbar-expand-md bg-white  top-navbar">
              <div className="container-fluid viewport-padding  color-strong-grey">
                <span className="display-5 mb-0">
                  {$c.createAvatar(
                    form.userInfo.profileImage,
                    form.userInfo.firstName,
                    form.userInfo.lastName
                  )}
                </span>
                <div className="text-md-end mt-auto mb-auto">
                  Submitted on:{" "}
                  <strong>{$c.formatDateTime(form.createdAt)}</strong>
                </div>
              </div>
            </nav>
          </header>
          <main className="container-fluid viewport-padding bg-light-grey pb-5">
            <div className="wrapper" style={{ paddingTop: "3.563rem" }}>
              <div className="notification"></div>
              <div className="container p-3 rounded border mb-3 mt-5 bg-white">
                <h1 className="display-4">{parse(form.title)}</h1>
                <label>
                  <strong>Instructions</strong>
                </label>
                <div>{parse(form.instructions)}</div>
              </div>
              <div className="container p-3 rounded border mb-3 mt-3 bg-white">
                <div>
                  {form.questions?.map((el, idx) => {
                    let borderBottom = "";

                    if (idx < form.questions.length - 1) {
                      borderBottom = "border-bottom-1";
                    }

                    return (
                      <div className={"pt-3 pb-3 ".concat(borderBottom)}>
                        <div className="d-flex">
                          <label className="w-100 ">
                            <strong>Question {idx + 1}</strong>
                          </label>
                          <div
                            className="small color-grey"
                            style={{ width: "8rem" }}
                          >
                            <span></span>
                          </div>
                        </div>
                        <div>{parse(el.question)}</div>
                        {/* <div className="color-grey mb-3 mt-3">
                          {el.type == "multipleChoice" ? "Answers" : "Answer"}
                        </div> */}
                        {el.answers.map((ans) => {
                          if (el.type == "multipleChoice") {
                            let answerColor = "";

                            let checked = false;

                            let icon = <Icon.FiCheck color="white" size={20} />;

                            if (ans.userAnswer.toString() == "true") {
                              answerColor = "#28a745";
                              checked = true;
                            }

                            return (
                              <div className="mb-2">
                                <div className="d-flex">
                                  <Checkbox
                                    className="me-2"
                                    checked={checked}
                                    disabled
                                    icon={
                                      <div
                                        style={{
                                          display: "flex",
                                          flex: 1,
                                          backgroundColor: answerColor,
                                          alignSelf: "stretch",
                                          borderColor: answerColor,
                                        }}
                                      >
                                        {icon}
                                      </div>
                                    }
                                    borderColor={answerColor}
                                    borderRadius={23}
                                    style={{
                                      overflow: "hidden",
                                      backgroundColor: "#fff",
                                    }}
                                    size={23}
                                  />

                                  <div className="w-100">{ans.answer}</div>
                                  <div
                                    className="small color-grey"
                                    style={{ width: "8rem" }}
                                  ></div>
                                </div>
                              </div>
                            );
                          }

                          if (el.type == "essayType") {
                            return (
                              <div className="mb-2">
                                <div className="d-flex">
                                  <div className="w-100">
                                    {ans.answer ? parse(ans.answer) : ""}
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </main>
        </>
      );
    }

    return "";
  }
}

export default FormResult;
