import React, { Component } from "react";
import parse from "html-react-parser";
import $c from "../../../../../modules/functions";
import $f from "../../../../../src";
import { Link, withRouter } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import Modals from "../../../../../modules/modals";
import ConferenceInterestForm from "./conferenceInterestForm";
import Privacy from "./privacy";
import Terms from "./terms";

class ConferencePublicOverview extends Component {
  constructor(props) {
    super(props);

    this.setActiveTab = this.setActiveTab.bind(this);

    this.reserveSeatModal = this.reserveSeatModal.bind(this);

    this.openPrivacy = this.openPrivacy.bind(this);
    this.openTerms = this.openTerms.bind(this);

    this.state = {
      data: [],
      loaded: false,
      training: {},
    };

    if ($c.isLoggedIn()) {
      // props.history.push("/event/conference/".concat($f.getQueryPath(2)));
    } else {
    }
    const script = document.createElement("script");
    script.src =
      "https://www.google.com/recaptcha/api.js?render=6LfqMDQeAAAAAKxo_kbK9dVlnwXtRoGWTEOUWn-X";
    script.async = false;
    document.body.appendChild(script);
  }

  componentDidMount() {
    const { data, module, view, specialProperties, response } = this.props;

    let templateConfiguration = view.template.configuration;

    const innerTemplates = ["header"];

    innerTemplates.map((itm, idx) => {
      let tmp = templateConfiguration[itm + "Template"]
        ? templateConfiguration[itm + "Template"]
        : () => {};
      this[itm + "Template"] = tmp.bind(this);
    });

    this.setState({
      activeTab: 0,
      specialProperties,
      data,
      loaded: true,
      view,
      module,
      response,
    });

    document.body.style.paddingTop = "3.76rem";
  }

  setActiveTab(index) {
    this.setState({ activeTab: index });
  }

  scrollTo(id) {
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.pageYOffset - 60;

    window.scrollTo({ top: y, behavior: "smooth" });
  }

  openPrivacy() {
    const ref = React.createRef();

    const Body = <Privacy key={$f.key()} ref={ref}></Privacy>;

    window["secondaryModal"].setState({
      show: true,
      size: "lg",
      title: (
        <h2 className="display-3-no-line-height header-ellipsis">
          Privacy Policy
        </h2>
      ),
      footer: [
        <button
          type="button"
          onClick={() => {
            window["secondaryModal"].close();
          }}
          className="btn btn-sm btn-white"
        >
          Close
        </button>,
      ],
      body: Body,
    });
  }

  openTerms() {
    const ref = React.createRef();

    const Body = <Terms key={$f.key()} ref={ref}></Terms>;

    window["secondaryModal"].setState({
      show: true,
      size: "lg",
      title: (
        <h2 className="display-3-no-line-height header-ellipsis">
          Terms and conditions
        </h2>
      ),
      footer: [
        <button
          type="button"
          onClick={() => {
            window["secondaryModal"].close();
          }}
          className="btn btn-sm btn-white"
        >
          Close
        </button>,
      ],
      body: Body,
    });
  }

  reserveSeatModal() {
    const ref = React.createRef();

    const Body = (
      <ConferenceInterestForm key={$f.key()} ref={ref}></ConferenceInterestForm>
    );

    window["primaryModal"].setState({
      show: true,
      size: "lg",
      title: (
        <h2 className="display-3-no-line-height header-ellipsis">
          Thank you for your interest!
        </h2>
      ),
      footer: [
        <button
          type="button"
          onClick={() => {
            window["primaryModal"].close();
          }}
          className="btn btn-sm btn-white"
        >
          Close
        </button>,
      ],
      body: Body,
    });
  }

  render() {
    if (this.state.loaded) {
      const data = this.state.response.data[0];

      const sponsors = $c.conferencePartners(data.sponsors);

      let banner = data.banner
        ? window["baseurl"](
            "/agenda-file/data/" +
              data.banner._id +
              "?type=preview&jwt=" +
              localStorage.getItem("jwt")
          )
        : "/uni/svg/conferencePlaceholder.jpg";

      console.log("pasok", data);

      return (
        <React.Fragment>
          <header className="fixed-top">
            <div className="row viewport-padding bg-white">
              <div className="col-4">
                <nav className="navbar navbar-expand-md  top-navbar">
                  <div className="container-fluid   color-strong-grey">
                    <div className="display-5 mb-0">
                      {window["COMPANY_LOGO"]()}
                    </div>
                  </div>
                </nav>
              </div>
              <div className="col-4 m-auto"></div>
              <div className="col-4 m-auto text-end">
                {!$c.isLoggedIn() ? (
                  <button
                    className="btn btn-white color-purple border-0"
                    onClick={() => {
                      if ($c.isLoggedIn()) {
                        window.location.replace(
                          "/event/conference/".concat(data._id)
                        );
                      } else {
                        $c.logout("/event/conference/".concat(data._id));
                      }
                    }}
                  >
                    Sign in
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>

            {this.state.topmenu}
          </header>
          <div
            className="container-fluid  text-white  "
            style={{
              minHeight: "400px",
              maxHeight: "450px",
              height: "100%",
              backgroundImage: "url(" + banner + ")",
              backgroundSize: "cover",
              boxShadow: "rgb(0 0 0 / 50%) 0px 0px 0px 2000px inset",
            }}
          >
            <div className="viewport-padding">
              <div className="text-end p-3"></div>
              <div className="pt-5 pb-5">
                <div>
                  <small>Conference</small>
                </div>
                <div>
                  <small>
                    {$c.formatDateFromToNoTime(data.from, data.to)}, attend
                    virtually
                  </small>
                </div>
                <h1 className="display-2 mt-2">{data.title}</h1>
                <div className="col-md-8 mt-3">
                  {data.shortDescription ? parse(data.shortDescription) : ""}
                </div>
                <div>
                  <button
                    className="btn btn-purple btn-sm pe-3 ps-3 me-2"
                    onClick={() => {
                      if ($c.isLoggedIn()) {
                        window.location.replace(
                          "/event/conference/".concat(data._id)
                        );
                      } else {
                        $c.logout("/event/conference/".concat(data._id));
                      }
                    }}
                  >
                    Join
                  </button>
                  <button
                    className="btn btn-white btn-sm pe-3 ps-3"
                    onClick={() => {
                      this.reserveSeatModal();
                    }}
                  >
                    <strong>Reserve a seat</strong>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <main
            className="container-fluid viewport-padding "
            style={{ minΗeight: "100%" }}
          >
            <div className="pb-5 container-fluid me-0 ms-0">
              <div className="mt-3">
                <PerfectScrollbar className="scrollbar-main">
                  <ul className="nav nav-tabs navbar-expand">
                    <li className={"nav-item "} key={$f.key()}>
                      <span
                        className={"nav-link color-strong-grey nav-ellipsis "}
                        role="button"
                        onClick={() => {
                          this.scrollTo("about");
                        }}
                      >
                        About
                      </span>
                    </li>
                    <li className={"nav-item "} key={$f.key()}>
                      <span
                        className={"nav-link color-strong-grey nav-ellipsis "}
                        role="button"
                        onClick={() => {
                          this.scrollTo("agenda");
                        }}
                      >
                        Agenda
                      </span>
                    </li>
                    <li className={"nav-item "} key={$f.key()}>
                      <span
                        className={"nav-link color-strong-grey nav-ellipsis "}
                        role="button"
                        onClick={() => {
                          this.scrollTo("speakers");
                        }}
                      >
                        Speakers
                      </span>
                    </li>
                    <li className={"nav-item "} key={$f.key()}>
                      <span
                        className={"nav-link color-strong-grey nav-ellipsis "}
                        role="button"
                        onClick={() => {
                          this.scrollTo("partners");
                        }}
                      >
                        Partners
                      </span>
                    </li>
                  </ul>
                </PerfectScrollbar>
              </div>
              <div className="mt-5" id="about">
                <h2 className="display-4 border-bottom pb-2">About</h2>
                <div className=" mt-1 ">
                  {data.description ? parse(data.description) : ""}
                </div>
              </div>
              <div className="mt-5" id="agenda">
                <h2 className="display-4 border-bottom pb-2">Agenda</h2>
                <PerfectScrollbar>
                  <ul className="nav nav-tabs navbar-expand border-0">
                    {data.days.map((el, idx) => {
                      const tabId = "a-".concat(idx);

                      let active = "color-grey bg-light-grey";

                      if (idx == this.state.activeTab) {
                        active = "active show bg-grey text-white ";
                      }

                      return (
                        <li
                          className={"nav-item me-2"}
                          key={$f.key()}
                          onClick={() => {
                            this.setActiveTab(idx);
                          }}
                        >
                          <div
                            className={" nav-ellipsis border rounded p-3   ".concat(
                              active
                            )}
                            data-bs-toggle="tab"
                            data-bs-target={"#".concat(tabId)}
                            role="button"
                            style={{ width: "20rem" }}
                          >
                            <div className="">
                              <strong>Day {idx + 1}</strong>
                            </div>
                            <div className="small">
                              {$c.formatDateMonth(el.date)}
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </PerfectScrollbar>
                <div className="tab-content mt-3 ">
                  {data.days.map((el, idx) => {
                    const tabId = "a-".concat(idx);

                    let active = "";

                    if (idx == this.state.activeTab) {
                      active = "active show";
                    }

                    return (
                      <div
                        className={"tab-pane fade ".concat(active)}
                        id={tabId}
                        role="tabpanel"
                      >
                        {$c.conferenceSessions(el.sessions, el, data)}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="mt-5" id="speakers">
                <h2 className="display-4 border-bottom pb-2">Speakers</h2>
                <div className="row mt-1">
                  {$c.conferenceSpeakers(data.days).map((el) => {
                    return (
                      <div
                        className="col-md-3 p-3"
                        role="button"
                        onClick={() => {
                          // Modals.contacts.userProfile({
                          //   userId: el._id,
                          // });
                          Modals.events.openSpeaker({
                            speaker: el,
                            data: data,
                          });
                        }}
                      >
                        {$c.createAvatar(
                          el.profileImage,
                          el.firstName,
                          el.lastName,
                          "avatar-large",
                          el.jobTitle
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="mt-5" id="partners">
                <h2 className="display-4 border-bottom pb-2">Partners</h2>
                <div className="row mt-1">
                  {Object.keys(sponsors).map((el) => {
                    return (
                      <div className="mt-3">
                        <span className="display-4 color-grey">{el}</span>
                        <div className="row mt-3">
                          {sponsors[el].map((el) => {
                            return (
                              <div
                                className="col-md-3 p-3"
                                role="button"
                                onClick={() => {
                                  Modals.events.openPartner(el);
                                }}
                              >
                                <img
                                  src={$c.agendaFileImageURL(el.logo._id)}
                                  style={{
                                    maxHeight: "12rem",
                                    maxWidth: "12rem",
                                  }}
                                  title={el.title}
                                ></img>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </main>
          <footer className="bg-light-grey pt-5 pb-3">
            <div
              className="container-fluid viewport-padding "
              style={{ minΗeight: "100%" }}
            >
              <div className="row">
                <div className="col-md-10 mt-5">{window["COMPANY_NAME"]()}</div>
                <div className="col-md-2 mt-5">
                  <h4 className="display-4 color-strong-grey">Resources</h4>
                  <nav className="nav flex-column ">
                    <span
                      class="nav-link color-strong-grey ps-0"
                      onClick={this.openPrivacy}
                      role="button"
                    >
                      Privacy
                    </span>
                    <span
                      class="nav-link color-strong-grey ps-0"
                      onClick={this.openTerms}
                      role="button"
                    >
                      Terms
                    </span>
                    <a
                      href="mailto:support@interfima.org"
                      class="nav-link color-strong-grey ps-0"
                      role="button"
                    >
                      Contact
                    </a>
                  </nav>
                </div>
              </div>
              <div className="color-strong-grey mt-4">
                @2023 {window["COMPANY_NAME_ONLY"]()}. All rights reserved.
              </div>
            </div>
          </footer>
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default withRouter(ConferencePublicOverview);
