import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import SortableTree, {
  getTreeFromFlatData,
  removeNodeAtPath,
  getFlatDataFromTree,
} from "react-sortable-tree";
import $c from "../../../../modules/functions";
import Modals from "../../../../modules/modals";
import $f from "../../../../src";
import Module from "../../../../types/module/module";
import OptionsForHeader from "../../../../types/optionsForHeader/optionsForHeader";
import Header from "../header";
import CustomNodeContentRenderer from "./groupsTree/CustomNodeContentRenderer";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import EmptyStates from "../../../../modules/emptyStates";

const OptionsForHeaderButton = function (data) {
  if (!$c.userIsAdmin()) {
    return [];
  }

  let property = {
    configuration: {
      options: {
        edit: function (args) {
          if ($c.userIsAdmin()) {
            let newargs = {
              props: { data: { ...args.props.data } },
              action: "update",
              title: "Edit group",
            };

            return {
              label: "Edit",
              onClick: function () {
                $f.getValue(Modals.spaces.editSpace, newargs);
              },
            };
          }
        },
        // copyLink: function (args) {
        //   return {
        //     label: "Copy link",
        //     onClick: function () {
        //       $c.copyToClipboard(window["host"](window.location.pathname));
        //       $f.createNotification({
        //         type: "success",
        //         message: "Link copied!",
        //       });
        //     },
        //   };
        // },
        addChild: function (args) {
          if ($c.userIsAdmin()) {
            return {
              label: "Add child",
              onClick: function () {
                if ($c.userIsAdmin()) {
                  $f.getValue(Modals.spaces.addSpace, {
                    title: "Add child",
                    inputData: [
                      {
                        parent: args.props.data._id,
                        rootSpace: $f.getQueryPath(2),
                      },
                    ],
                  });
                }
              },
            };
          }
        },
        delete: function (args) {
          if ($c.userIsAdmin()) {
            args.type = "group";
            args.callback = function (resp) {
              window.location.replace("/space/".concat($f.getQueryPath(2)));
            };
            return {
              label: <span className="text-danger">Delete</span>,
              onClick: function () {
                $f.getValue(Modals.spaces.deleteSpace, args);
              },
            };
          }
        },
      },
    },
  };

  return <OptionsForHeader data={data} property={property} />;
};

class SpaceOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    const { data, module, view, specialProperties, response } = this.props;

    let templateConfiguration = view.template.configuration;

    this.getSpaceGroups = this.getSpaceGroups.bind(this);
    this.setExpanded = this.setExpanded.bind(this);
    this.setExpandedParents = this.setExpandedParents.bind(this);
    this.getGroupInfo = this.getGroupInfo.bind(this);
    this.sectionButtons = this.sectionButtons.bind(this);
    this.reorder = this.reorder.bind(this);
    this.findPath = this.findPath.bind(this);

    this.filesRef = React.createRef();

    this.filesScrollRef = React.createRef();

    const innerTemplates = ["header", "top", "bottom"];

    innerTemplates.map((itm, idx) => {
      let tmp = templateConfiguration[itm + "Template"]
        ? templateConfiguration[itm + "Template"]
        : () => {};
      this[itm + "Template"] = tmp.bind(this);
    });

    this.setState({
      specialProperties,
      data,
      loaded: true,
      view,
      module,
      restData: response.data,
      groupsTreeData: [],
      groupsTreeDataLoaded: false,
      groupInfoLoaded: false,
      groupInfo: null,
    });

    this.getSpaceGroups();
    if ($f.getQueryPath(4)) {
      this.getGroupInfo();
    }
  }

  getSpaceGroups() {
    let _this = this;
    $f.fetch(
      "/spaces/root/{id}?treeview={treeview}",
      "GET",
      null,
      {},
      {
        id: () => {
          return $f.getQueryPath(2);
        },
        treeview: () => {
          return $c.userIsAdmin().toString();
        },
      },
      (resp) => {
        let data = [];

        if ($c.userIsMember()) {
          data = resp.data.data.spaces;

          _this.setState({
            groupsTreeData: [...data],
            groupsTreeDataLoaded: true,
          });
        } else {
          data = resp.data.data;

          data.forEach((node) => {
            _this.findPath(node, $f.getQueryPath(4), []);
          });

          //  _this.setExpanded(data, []);
          setTimeout(() => {
            _this.setState({
              groupsTreeData: [..._this.setExpandedParents(data)],
              groupsTreeDataLoaded: true,
            });
          }, 0);
        }
      }
    );
  }

  findPath = (tree, id, pathStack = []) => {
    //  if (tree._id === id) return { id: tree._id, path: pathStack };

    if (tree._id === id) {
      tree.selected = true;
      this.setState({ selectedTreePath: [...pathStack] });
    }

    pathStack.push(tree._id);

    for (const node of tree.children) {
      const result = this.findPath(node, id, [...pathStack]);
      if (result) return result; //this will stop going deeper or to unwanted childs after getting the result
    }
  };

  setExpandedParents(children) {
    children.forEach((el) => {
      el.expanded = false;
      if (this.state.selectedTreePath?.indexOf(el._id) >= 0) {
        el.expanded = true;
      }
      if (el.children) {
        this.setExpandedParents(el.children);
      }
    });
    return children;
  }

  setExpanded(children, path) {
    children.forEach((el) => {
      if (el._id == $f.getQueryPath(4)) {
        el.selected = true;
        el.expanded = true;
        this.setState({ selectedTreePath: [...path] });
      } else {
        el.expanded = false;
      }
      if (el.children) {
        path.push(el._id);
        this.setExpanded(el.children, path);
      }
    });
  }

  getGroupInfo() {
    let _this = this;
    $f.fetch(
      "/space/{id}",
      "GET",
      null,
      {},
      {
        id: () => {
          return $f.getQueryPath(4);
        },
      },
      (resp) => {
        _this.setState({ groupInfo: resp.data.data[0], groupInfoLoaded: true });

        if (_this.filesScrollRef.current) {
          setTimeout(_this.filesScrollRef.current.updateScroll, 1500);
        }
      }
    );
  }

  reorder(id, parentId) {
    $f.fetch(
      "/space",
      "PATCH",
      { parent: parentId, _id: id },
      {},
      {},
      (resp) => {}
    );
  }

  sectionButtons(section) {
    let _this = this;
    switch (section) {
      case "participants":
        return (
          <button
            className="btn btn-sm btn-purple "
            onClick={() => {
              $f.getValue(Modals.participants.addAll, {
                title: "Add people",
                api: "new",
                actionType: "space",
                actionId: $f.getQueryPath(4)
                  ? $f.getQueryPath(4)
                  : $f.getQueryPath(2),
                actionSection: "participants",
              });
            }}
          >
            Add people
          </button>
        );
        break;

      case "files":
        return [
          <button
            type="button"
            className="btn btn-white btn-sm me-2"
            onClick={() => {
              $f.getValue(Modals.files.newFolder, {
                inputData: [
                  {
                    name: "",
                    description: "",
                    parent: $f.getParameterByName("_id")
                      ? $f.getParameterByName("_id")
                      : _this.state.groupInfo.spaceFileDirectory,
                  },
                ],
              });
            }}
          >
            New Folder
          </button>,
          <button
            type="button"
            className="btn btn-purple  btn-sm  "
            onClick={() => {
              const fileTemplateRef =
                _this.filesRef.current.refTemplate.current;

              fileTemplateRef.onTargetClick();
            }}
          >
            Upload Files
          </button>,
        ];

        break;
    }
  }

  render() {
    if (this.state.loaded) {
      let _this = this;

      let emptyState = [];

      if ($f.getQueryPath(4)) {
        if (this.state.groupInfoLoaded) {
          if (this.state.restData[0].children?.length > 0) {
            emptyState = EmptyStates.spaces.selectGroup();
          } else {
            emptyState = EmptyStates.spaces.group({
              title: this.state.data[0].name.value,
              id: this.state.data[0]._id.value,
            });
          }
        }
      } else {
        if (this.state.restData[0].children?.length > 0) {
          emptyState = EmptyStates.spaces.selectGroup();
        } else {
          emptyState = EmptyStates.spaces.group({
            title: this.state.data[0].name.value,
            id: this.state.data[0]._id.value,
          });
        }
      }

      return (
        <div>
          <div
            className={"border-bottom pb-2 ".concat(
              $f.getQueryPath(3) === "group" ? "display-lt-md-none" : ""
            )}
          >
            {this.headerTemplate()}

            {this.topTemplate()}
          </div>
          <div>
            <div className="row" style={{ minHeight: "calc(100vh - 250px)" }}>
              <div
                className={"col-md-3 border-end pe-0 ".concat(
                  $f.getQueryPath(3) === "group" ? "display-lt-md-none" : ""
                )}
              >
                <div className="d-flex mt-2 mb-2">
                  <div className="display-5-no-margin color-grey">Groups</div>
                  <div className="ms-auto me-2">
                    {$c.userIsAdmin() ? (
                      <span
                        className="color-purple small"
                        role="button"
                        onClick={() => {
                          $f.getValue(Modals.spaces.addSpace, {
                            title: "Add group",
                            inputData: [
                              {
                                parent: this.state.data[0]._id.value,
                                rootSpace: this.state.data[0]._id.value,
                              },
                            ],
                          });
                        }}
                      >
                        Add group
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="d-block ">
                  <PerfectScrollbar
                    style={{ maxHeight: "calc(100vh - 17rem)" }}
                  >
                    <SortableTree
                      treeData={this.state.groupsTreeData}
                      isVirtualized={false}
                      onMoveNode={(args) => {
                        try {
                          this.setState({ groupsTreeData: [...args.treeData] });
                        } catch (e) {
                          console.log(e);
                        }

                        this.reorder(args.node._id, args.nextParentNode._id);
                      }}
                      canDrag={false} //{$c.userIsAdmin()}
                      onChange={(treeData, args) => {
                        try {
                          if (
                            JSON.stringify(this.state.groupsTreeData) !==
                            JSON.stringify(treeData)
                          ) {
                            this.setState({ groupsTreeData: [...treeData] });
                          }
                        } catch (e) {
                          console.log(e);
                        }
                      }}
                      searchMethod={() => {}}
                      searchQuery={() => {}}
                      nodeContentRenderer={CustomNodeContentRenderer}
                      generateNodeProps={({ node, path, treeIndex }) => ({
                        add: $c.userIsAdmin()
                          ? function () {
                              $f.getValue(Modals.spaces.addSpace, {
                                title: "Add child",
                                inputData: [
                                  {
                                    parent: node._id,
                                    rootSpace: _this.state.data[0]._id.value,
                                  },
                                ],
                              });
                            }
                          : null,
                        more: function () {
                          return "";
                        },
                        remove: $c.userIsAdmin()
                          ? function () {
                              $f.getValue(Modals.spaces.deleteSpace, {
                                props: { data: { ...node } },
                                type: "group",
                                callback: function (resp) {
                                  window.location.replace(
                                    "/space/".concat($f.getQueryPath(2))
                                  );
                                },
                              });
                            }
                          : null,
                        title: function () {
                          return (
                            <Link
                              to={"/space/".concat(
                                $f.getQueryPath(2),
                                "/group/",
                                node._id
                              )}
                              className="ms-2  text-break d-block"
                            >
                              {node.name}
                            </Link>
                          );
                        },
                      })}
                    />
                    {this.state.groupsTreeData.length < 1 &&
                    this.state.groupsTreeDataLoaded
                      ? "There are no groups yet. Groups will appear here."
                      : ""}
                  </PerfectScrollbar>
                </div>
              </div>
              <div className="col-md-9">
                <div className="p-2 mt-3">
                  {this.state.groupInfo ? (
                    <div>
                      <div className="">
                        <Header
                          data={{
                            title: $c.createAvatar(
                              this.state.groupInfo.image,
                              this.state.groupInfo.name,
                              "",
                              "avatar-medium"
                            ),
                            subTitle: $c.userIsAdmin()
                              ? "Below you can view the group details, the participants and the files of this group. People assigned to this group can only view the files uploaded in this group.  "
                              : "Below you can view the group details, the participants and the files of this group. ",
                            buttons: (args) => {
                              return [
                                $f.getValue(
                                  OptionsForHeaderButton,
                                  this.state.groupInfo
                                ),
                              ];
                            },
                          }}
                        ></Header>
                      </div>
                      <div>
                        <ul className="nav nav-tabs navbar-expand">
                          <li className="nav-item ">
                            <span
                              className={"nav-link color-strong-grey nav-ellipsis ".concat(
                                !$f.getQueryPath(5) ? "active" : ""
                              )}
                              title="Overview"
                              data-bs-toggle="tab"
                              data-bs-target="#space-overview"
                              role="button"
                              onClick={() => {
                                document
                                  .getElementById("participantButtons")
                                  .classList.add("collapse");
                                document
                                  .getElementById("filesButtons")
                                  .classList.add("collapse");
                              }}
                            >
                              Overview
                            </span>
                          </li>
                          <li className="nav-item ">
                            <span
                              className={"nav-link color-strong-grey nav-ellipsis ".concat(
                                $f.getQueryPath(5) == "participants"
                                  ? "active"
                                  : ""
                              )}
                              title="Participants"
                              data-bs-toggle="tab"
                              data-bs-target="#space-participants"
                              role="button"
                              onClick={() => {
                                document
                                  .getElementById("participantButtons")
                                  .classList.remove("collapse");
                                document
                                  .getElementById("filesButtons")
                                  .classList.add("collapse");
                                let url =
                                  "/space/" +
                                  $f.getQueryPath(2) +
                                  "/group/" +
                                  $f.getQueryPath(4) +
                                  "/participants";

                                window.history.pushState(
                                  { path: url },
                                  "",
                                  url
                                );
                              }}
                            >
                              Participants
                            </span>
                          </li>
                          <li className="nav-item ">
                            <span
                              className={"nav-link color-strong-grey nav-ellipsis ".concat(
                                $f.getQueryPath(5) == "files" ? "active" : ""
                              )}
                              title="Files"
                              data-bs-toggle="tab"
                              data-bs-target="#space-files"
                              role="button"
                              onClick={() => {
                                document
                                  .getElementById("participantButtons")
                                  .classList.add("collapse");
                                document
                                  .getElementById("filesButtons")
                                  .classList.remove("collapse");
                                let url =
                                  "/space/" +
                                  $f.getQueryPath(2) +
                                  "/group/" +
                                  $f.getQueryPath(4) +
                                  "/files";

                                window.history.pushState(
                                  { path: url },
                                  "",
                                  url
                                );
                              }}
                            >
                              Files
                            </span>
                          </li>
                          <li className="nav-item ms-auto display-lt-md-none  mt-auto mb-auto">
                            <div
                              id="participantButtons"
                              className={
                                $f.getQueryPath(5) == "participants"
                                  ? ""
                                  : "collapse"
                              }
                            >
                              {$c.userIsAdmin()
                                ? this.sectionButtons("participants")
                                : ""}
                            </div>

                            <div
                              id="filesButtons"
                              className={
                                $f.getQueryPath(5) == "files" ? "" : "collapse"
                              }
                            >
                              {this.sectionButtons("files")}
                            </div>
                          </li>
                        </ul>

                        <div className="tab-content  ">
                          <div
                            className={"tab-pane fade ".concat(
                              !$f.getQueryPath(5) ? "active show" : ""
                            )}
                            id="space-overview"
                            role="tabpanel"
                            aria-labelledby="space-overview-tab"
                          >
                            <div className="form-group mb-4 mt-4">
                              <label className="label-top">Description</label>
                              <span className="d-block">
                                {this.state.groupInfo.description
                                  ? this.state.groupInfo.description
                                  : "-"}
                              </span>
                            </div>
                            <div className="form-group mb-4 mt-4">
                              <label className="label-top">Website</label>
                              <span className="d-block">
                                {this.state.groupInfo.websiteUrl
                                  ? this.state.groupInfo.websiteUrl
                                  : "-"}
                              </span>
                            </div>
                            <div className="form-group mb-4 mt-4">
                              <label className="label-top">Industry</label>
                              <span className="d-block">
                                {this.state.groupInfo.industry
                                  ? this.state.groupInfo.industry
                                  : "-"}
                              </span>
                            </div>
                            <div className="form-group mb-4 mt-4">
                              <label className="label-top">Market</label>
                              <span className="d-block">
                                {this.state.groupInfo.market
                                  ? this.state.groupInfo.market
                                  : "-"}
                              </span>
                            </div>
                            <div className="form-group mb-4 mt-4">
                              <label className="label-top">Money raised</label>
                              <span className="d-block">
                                {this.state.groupInfo.moneyRaised?.currency
                                  ? this.state.groupInfo.moneyRaised.currency
                                  : ""}
                                {this.state.groupInfo.moneyRaised?.amount
                                  ? this.state.groupInfo.moneyRaised.amount
                                  : "-"}
                              </span>
                            </div>
                          </div>
                          <div
                            className={"tab-pane fade ".concat(
                              $f.getQueryPath(5) == "participants"
                                ? "active show"
                                : ""
                            )}
                            id="space-participants"
                            role="tabpanel"
                            aria-labelledby="space-participants-tab"
                          >
                            <Module
                              property={{
                                label: "",

                                configuration: (resp) => {
                                  return {
                                    module: "ParticipantTable",
                                    view: "list",
                                    replace: {
                                      id: () => {
                                        let id = $f.getQueryPath(2);

                                        if ($f.getQueryPath(4)) {
                                          id = $f.getQueryPath(4);
                                        }

                                        return id;
                                      },
                                      start: 0,
                                      offset: 25,
                                    },
                                  };
                                },
                              }}
                            />
                          </div>
                          <div
                            className={"tab-pane fade ".concat(
                              $f.getQueryPath(5) == "files" ? "active show" : ""
                            )}
                            id="space-files"
                            role="tabpanel"
                            aria-labelledby="space-files-tab"
                            onClick={() => {
                              _this.filesScrollRef.current.updateScroll();
                            }}
                          >
                            <PerfectScrollbar
                              style={{ maxHeight: "calc(100vh - 25rem)" }}
                              ref={this.filesScrollRef}
                            >
                              <Module
                                ref={this.filesRef}
                                property={{
                                  label: "",
                                  spaceFileDirectory:
                                    this.state.groupInfo.spaceFileDirectory,
                                  configuration: (resp) => {
                                    if (!$f.getParameterByName("_id")) {
                                      return {
                                        module: "SpaceFiles",
                                        view: "spaceFiles",
                                        replace: {
                                          url: () => {
                                            return "/folder/".concat(
                                              this.state.groupInfo
                                                .spaceFileDirectory
                                            );
                                          },
                                        },
                                      };
                                    }

                                    return {
                                      module: "SpaceFiles",
                                      view: "spaceFiles",
                                    };
                                  },
                                }}
                              />
                            </PerfectScrollbar>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    emptyState
                  )}
                </div>
              </div>
            </div>
          </div>
          {this.bottomTemplate()}
        </div>
      );
    }

    return "";
  }
}

export default withRouter(SpaceOverview);
