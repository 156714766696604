import Contacts from "./contacts";
import Calendar from "./events/calendar";
import ConferencesQuestions from "./events/conferenceQuestions";
import Conferences from "./events/conferences";
import ConferencesPartners from "./events/conferencesPartners";
import ConferencesPolls from "./events/conferencesPolls";
import Meetings from "./events/meetings";
import Tests from "./events/tests";
import Trainings from "./events/trainings";
import CommonFilesModule from "./files/commonFilesModule";
import CompanyFiles from "./files/companyFiles";
import Favorites from "./files/favorites";
import FilePreview from "./files/filepreview";
import PersonalFiles from "./files/personalFiles";
import SpaceFiles from "./files/spaceFiles";
import FilesTable from "./filesTable";
import Forms from "./forms/forms";
import FormsResults from "./forms/formsResults";
import Homepage from "./homepage";
import LeftMenu from "./leftmenu";
import Login from "./login";
import Meeting from "./meeting";
import ParticipantTable from "./participantsTable";
import Projects from "./projects";
import Spaces from "./spaces/spaces";
import TestTemplates from "./templates/testTemplates";
import TrainingTemplates from "./templates/trainingTemplates";
import TestEvaluations from "./test/testEvaluations";
import TestResults from "./test/testResults";
import TrainingTestResults from "./test/trainingTestResults";
import UserTest from "./test/usertest";
import TopMenu from "./topmenu";
import Users from "./users";

const Modules = {
  Projects,
  TopMenu,
  Users,
  UserTest,
  TrainingTestResults,
  ConferencesQuestions,
  Login,
  Homepage,
  Contacts,
  LeftMenu,
  Meeting,
  Spaces,
  PersonalFiles,
  CompanyFiles,
  ConferencesPartners,
  ConferencesPolls,
  Favorites,
  Meetings,
  Trainings,
  Conferences,
  Tests,
  TestTemplates,
  TrainingTemplates,
  SpaceFiles,
  FilePreview,
  CommonFilesModule,
  Calendar,
  Forms,
  FormsResults,
  ParticipantTable,
  TestResults,
  TestEvaluations,
  FilesTable,
};

export default Modules;
