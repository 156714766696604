import {
  useMeetingManager,
  useRosterState,
  useRemoteVideoTileState,
  useAudioVideo,
  RemoteVideo,
  useAttendeeStatus,
  MicrophoneActivity,
  useLocalVideo,
  LocalVideo,
} from "amazon-chime-sdk-component-library-react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import $f from "../../../../src";

import MeetingFunctions from "./functions";
import { isMobile } from "react-device-detect";

export function RemoteVideoTileLabel(props) {
  const { videoEnabled, sharingContent, muted, signalStrength } =
    useAttendeeStatus(props.attendeeId);

  let options = [];

  if (props.isCurrentModerator && !props.isCurrentAttendee) {
    options.push(
      <i className="fas fa-ellipsis-v fa-fw ms-2  color-purple"></i>
    );
  }

  let isVideoEnabled = videoEnabled;

  if (props.isCurrentAttendee) {
    isVideoEnabled = props.isVideoEnabled;
  }

  return (
    <div className=" d-flex ">
      <div className=" d-flex " style={{ width: "6.5rem" }}>
        <span className="text-ellipsis" title={props.externalUserId}>
          {props.externalUserId}
        </span>
      </div>
      <div className="text-end" style={{ width: "5rem" }}>
        {sharingContent ? (
          <i className="fas fa-desktop fa-fw color-purple"></i>
        ) : (
          ""
        )}
        <i
          className={
            isVideoEnabled
              ? "fas fa-video fa-fw ms-2  color-purple"
              : "fas fa-video-slash fa-fw ms-2 text-secondary"
          }
        ></i>
        <MicrophoneActivity
          attendeeId={props.attendeeId}
          className="mic-activity"
        ></MicrophoneActivity>
      </div>
    </div>
  );
}

const RemoteVideoTiles = (props) => {
  const meetingManager = useMeetingManager();
  const { attendeeIdToTileId } = useRemoteVideoTileState();
  const audioVideo = useAudioVideo();
  const { roster } = useRosterState();
  const attendees = Object.values(roster);
  const { isVideoEnabled } = useLocalVideo();
  let videoTiles = {};

  Object.keys(attendeeIdToTileId)?.forEach((tileId) => {
    const attendeeInfo = MeetingFunctions.getAttendee(roster, tileId);

    videoTiles[tileId] = (
      <div className="remoteVideoTile m-2 rounded" key={$f.key()}>
        <RemoteVideo
          tileId={attendeeIdToTileId[tileId]}
          className="rounded"
          style={{ width: "12rem", height: "8rem" }}
        />
        <label className="rounded-9rem ps-2 pe-2">
          <RemoteVideoTileLabel
            attendeeId={tileId}
            externalUserId={attendeeInfo.name}
            isModerator={true}
            isCurrentAttendee={MeetingFunctions.isCurrentAttendee(
              audioVideo,
              tileId
            )}
            isCurrentModerator={MeetingFunctions.isCurrentModerator(
              meetingManager,
              audioVideo
            )}
            key={$f.key()}
          ></RemoteVideoTileLabel>
        </label>
      </div>
    );
  });

  const attendeeItems = [];
  attendees.forEach((attendee) => {
    const { chimeAttendeeId, name, externalUserId } = attendee;

    const attendeeInfo = MeetingFunctions.getAttendee(roster, chimeAttendeeId);

    if (
      !MeetingFunctions.participantIsModerator(
        meetingManager,
        attendeeInfo.externalUserId
      ) &&
      MeetingFunctions.getCurrentAttendee(meetingManager)?.["attendeeId"] !==
        chimeAttendeeId &&
      !MeetingFunctions.isRecordingUser(attendeeInfo.name)
    ) {
      const vTile = videoTiles[chimeAttendeeId] ? (
        videoTiles[chimeAttendeeId]
      ) : (
        <div className="remoteVideoTile empty m-2 rounded" key={$f.key()}>
          <label className="rounded-9rem ps-2 pe-2">
            <RemoteVideoTileLabel
              attendeeId={chimeAttendeeId}
              externalUserId={attendeeInfo.name}
              isModerator={true}
              isCurrentAttendee={MeetingFunctions.isCurrentAttendee(
                audioVideo,
                chimeAttendeeId
              )}
              isCurrentModerator={MeetingFunctions.isCurrentModerator(
                meetingManager,
                audioVideo
              )}
              key={$f.key()}
            ></RemoteVideoTileLabel>
          </label>
          {MeetingFunctions.createRemoteVideoAvatar(
            attendeeInfo.name,
            null,
            "avatar-large"
          )}
        </div>
      );

      attendeeItems.push(vTile);
    }
  });

  if (!MeetingFunctions.isCurrentModerator(meetingManager, audioVideo)) {
    const attendeeInfo = MeetingFunctions.getAttendee(
      roster,
      MeetingFunctions.getCurrentAttendee(meetingManager)?.["attendeeId"]
    );
    if (!MeetingFunctions.isRecordingUser(attendeeInfo.name)) {
      if (isVideoEnabled) {
        attendeeItems.unshift(
          <div className="remoteVideoTile  m-2 rounded" key={$f.key()}>
            <LocalVideo
              className="rounded"
              style={{ width: "12rem", height: "8rem" }}
            />
            <label className="rounded-9rem ps-2 pe-2">
              <RemoteVideoTileLabel
                isModerator={false}
                isCurrentAttendee={true}
                isCurrentModerator={false}
                isVideoEnabled={isVideoEnabled}
                attendeeId={attendeeInfo.chimeAttendeeId}
                externalUserId={attendeeInfo.name}
                key={$f.key()}
              ></RemoteVideoTileLabel>
            </label>
          </div>
        );
      } else {
        attendeeItems.unshift(
          <div className="remoteVideoTile empty m-2 rounded" key={$f.key()}>
            <label className="rounded-9rem ps-2 pe-2">
              <RemoteVideoTileLabel
                attendeeId={attendeeInfo.chimeAttendeeId}
                externalUserId={attendeeInfo.name}
                isModerator={true}
                isCurrentAttendee={true}
                isCurrentModerator={false}
                key={$f.key()}
              ></RemoteVideoTileLabel>
            </label>
            {MeetingFunctions.createRemoteVideoAvatar(
              attendeeInfo.name,
              null,
              "avatar-large"
            )}
          </div>
        );
      }
    }
  }

  return attendeeItems.length > 0 && !isMobile ? (
    <PerfectScrollbar style={{ width: "calc(100vw - 24rem)" }}>
      <div className="me-2 d-flex " style={{ width: "130%" }}>
        {attendeeItems}
      </div>
    </PerfectScrollbar>
  ) : (
    ""
  );
};
export default RemoteVideoTiles;
