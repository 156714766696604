import parse from "html-react-parser";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import $c from "../../../../modules/functions";
import $f from "../../../../src";

class EventModal extends Component {
  constructor(props) {
    super(props);

    this.eventRender = this.eventRender.bind(this);

    this.state = {
      data: [],
      loaded: false,
    };
  }

  componentDidMount() {
    const { data, module, view, specialProperties, response } = this.props;

    const events = specialProperties.events.data.data;
    const eventColors = specialProperties.events.data.colours;
    const eventType = module.props.property.configuration.type;
    const conferenceDayId = module.props.property.configuration.conferenceDayId;
    const eventId = module.props.property.configuration.eventId;

    let templateConfiguration = view.template.configuration;

    const innerTemplates = ["header"];

    innerTemplates.map((itm, idx) => {
      let tmp = templateConfiguration[itm + "Template"]
        ? templateConfiguration[itm + "Template"]
        : () => {};
      this[itm + "Template"] = tmp.bind(this);
    });

    this.setState({
      specialProperties,
      data,
      loaded: true,
      view,
      module,
      eventColors,
      events,
      eventType,
      response,
      conferenceDayId,
      eventId,
    });
  }

  eventRender() {
    const data = this.state.response.data[0];

    switch (this.state.eventType) {
      case "trainingexam":
        let examModuleTitle = "";
        let trainingExam = null;

        data.modules?.forEach((module) => {
          module.sessions?.forEach((session) => {
            session?.exam?.forEach((ex) => {
              if (ex._id === this.state.eventId) {
                trainingExam = ex;
                examModuleTitle = module.title;
              }
            });
          });
        });

        return (
          <div className="container-fluid ps-0">
            <div className="rounded mb-4 d-flex ">
              <span className=" rounded  me-2">
                <img src="/uni/svg/test.svg" style={{ width: "20px" }} />
              </span>
              <div
                className="mt-auto mb-auto"
                onClick={() => {
                  window["primaryModal"].setState({ show: false });
                }}
              >
                <Link
                  to={"/event/test/".concat(trainingExam._id)}
                  className="btn-link text-primary small"
                >
                  {window["host"]("/event/test/".concat(trainingExam._id))}
                </Link>
                <span className="small color-grey d-block">
                  Take this test from your computer, tablet or mobile.
                </span>
              </div>
            </div>
            <div className="rounded mb-2 d-flex mb-4">
              <span className="rounded  me-2">
                <img
                  src="/uni/svg/testdutation.svg"
                  style={{ width: "20px" }}
                />
              </span>
              <span className="mt-auto mb-auto">
                {$c
                  .examDurationFromObjectToText(trainingExam.examDuration)
                  .concat(" duration")}
              </span>
            </div>
            <div className="rounded mb-4 d-flex">
              <span className=" rounded  me-2">
                <img
                  src="/uni/svg/graded_practicetest.svg"
                  style={{ width: "20px" }}
                />
              </span>
              <span className="mt-auto mb-auto">
                {$c.testGrade(trainingExam.passingGrade, "overview")}
              </span>
            </div>
            <div className="rounded mb-4 d-flex">
              <span className=" rounded  me-2">
                <img
                  src="/uni/svg/event_description.svg"
                  style={{ width: "20px" }}
                />
              </span>
              <span className="mt-auto mb-auto text-break">
                This test is part of module "{examModuleTitle}"
              </span>
            </div>
          </div>
        );

      case "liveclass":
        let learningMaterial = 0;
        let moduleTitle = "";

        data.modules?.forEach((module) => {
          module.sessions?.forEach((session) => {
            session?.videoConference?.forEach((conference) => {
              if (conference._id === this.state.eventId) {
                learningMaterial =
                  session.videoPresentation?.filter((el) => {
                    return !el.recording;
                  }).length + session.reading.length;
                moduleTitle = module.title;
              }
            });
          });
        });

        return (
          <div className="container-fluid ps-0">
            <div className="rounded mb-4 d-flex ">
              <span className=" rounded  me-2">
                <img src="/uni/svg/videocall.svg" style={{ width: "20px" }} />
              </span>
              <div
                className="mt-auto mb-auto"
                onClick={() => {
                  window["primaryModal"].setState({ show: false });
                }}
              >
                <Link
                  to={"/liveclass/".concat("v-", this.state.eventId)}
                  className="btn-link text-primary small"
                >
                  {window["host"](
                    "/liveclass/".concat("v-", this.state.eventId)
                  )}
                </Link>
                <span className="small color-grey d-block">
                  Join this meeting from your computer, tablet or mobile.
                </span>
              </div>
            </div>
            <div className="rounded mb-2 d-flex mb-4">
              <span className="rounded  me-2">
                <img
                  src="/uni/svg/graded_practicetest.svg"
                  style={{ width: "20px" }}
                />
              </span>
              <span className="mt-auto mb-auto">
                {learningMaterial} learning material included
              </span>
            </div>

            <div className="rounded mb-4 d-flex">
              <span className=" rounded  me-2">
                <img
                  src="/uni/svg/event_description.svg"
                  style={{ width: "20px" }}
                />
              </span>
              <span className="mt-auto mb-auto text-break">
                This online class is part of module "{moduleTitle}"
              </span>
            </div>
          </div>
        );

      case "meeting":
        return (
          <div className="container-fluid ps-0">
            <div className="rounded mb-4 d-flex ">
              <span className=" rounded  me-2">
                <img src="/uni/svg/videocall.svg" style={{ width: "20px" }} />
              </span>
              <div
                className="mt-auto mb-auto"
                onClick={() => {
                  window["primaryModal"].setState({ show: false });
                }}
              >
                <Link
                  to={"/meeting/".concat("m-", data._id)}
                  className="btn-link text-primary small"
                >
                  {window["host"]("/meeting/".concat("m-", data._id))}
                </Link>
                <span className="small color-grey d-block">
                  Join this meeting from your computer, tablet or mobile.
                </span>
              </div>
            </div>
            <div className="rounded mb-2 d-flex mb-4">
              <span className="rounded  me-2">
                <img
                  src="/uni/svg/graded_practicetest.svg"
                  style={{ width: "20px" }}
                />
              </span>
              <span className="mt-auto mb-auto">
                {data.files.length} files included
              </span>
            </div>
            {data.description ? (
              <div className="rounded mb-4 d-flex">
                <span className=" rounded  me-2">
                  <img
                    src="/uni/svg/event_description.svg"
                    style={{ width: "20px" }}
                  />
                </span>
                <span className="mt-auto mb-auto">
                  {parse(data.description)}
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
        );

      case "stream":
        let day = {};

        data.days.forEach((el) => {
          if (el._id == this.state.conferenceDayId) {
            day = el;
          }
        });

        return (
          <div className="container-fluid ps-0">
            <div className="rounded mb-4 d-flex ">
              <span className=" rounded  me-2">
                <img src="/uni/svg/videocall.svg" style={{ width: "20px" }} />
              </span>
              <div
                className="mt-auto mb-auto"
                onClick={() => {
                  window["primaryModal"].setState({ show: false });
                }}
              >
                <Link
                  to={"/event/conference/".concat(data._id)}
                  className="btn-link text-primary small"
                >
                  {window["host"]("/event/conference/".concat(data._id))}
                </Link>
                <span className="small color-grey d-block">
                  Join this conference from your computer, tablet or mobile.
                </span>
              </div>
            </div>
            <div className="rounded mb-2 d-flex mb-4">
              <span className="rounded  me-2">
                <img
                  src="/uni/svg/graded_practicetest.svg"
                  style={{ width: "20px" }}
                />
              </span>
              <span className="mt-auto mb-auto">
                {day.sessions.length} sessions included in the current day
              </span>
            </div>
            <div className="rounded mb-4 d-flex">
              <span className=" rounded  me-2">
                <img
                  src="/uni/svg/event_description.svg"
                  style={{ width: "20px" }}
                />
              </span>
              <span className="mt-auto mb-auto">{parse(data.description)}</span>
            </div>
          </div>
        );

      case "exam":
        return (
          <div className="container-fluid ps-0">
            <div className="rounded mb-4 d-flex ">
              <span className=" rounded  me-2">
                <img src="/uni/svg/test.svg" style={{ width: "20px" }} />
              </span>
              <div
                className="mt-auto mb-auto"
                onClick={() => {
                  window["primaryModal"].setState({ show: false });
                }}
              >
                <Link
                  to={"/event/test/".concat(data._id)}
                  className="btn-link text-primary small"
                >
                  {window["host"]("/event/test/".concat(data._id))}
                </Link>
                <span className="small color-grey d-block">
                  Take this test from your computer, tablet or mobile.
                </span>
              </div>
            </div>
            <div className="rounded mb-2 d-flex mb-4">
              <span className="rounded  me-2">
                <img
                  src="/uni/svg/testdutation.svg"
                  style={{ width: "20px" }}
                />
              </span>
              <span className="mt-auto mb-auto">
                {$c
                  .examDurationFromObjectToText(data.examDuration)
                  .concat(" duration")}
              </span>
            </div>
            <div className="rounded mb-4 d-flex">
              <span className=" rounded  me-2">
                <img
                  src="/uni/svg/graded_practicetest.svg"
                  style={{ width: "20px" }}
                />
              </span>
              <span className="mt-auto mb-auto">
                {$c.testGrade(data.passingGrade, "overview")}
              </span>
            </div>
          </div>
        );
    }

    return "";
  }

  render() {
    if (this.state.loaded) {
      const _this = this;
      return <React.Fragment>{this.eventRender()}</React.Fragment>;
    } else {
      return "loading...";
    }
  }
}

export default EventModal;
