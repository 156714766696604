import {
  useMeetingManager,
  useRosterState,
  useRemoteVideoTileState,
  useAudioVideo,
  RemoteVideo,
  useAttendeeStatus,
  LocalVideo,
  useContentShareControls,
  useLocalVideo,
  useToggleLocalMute,
  useLocalAudioInputActivity,
  MicrophoneActivity,
  ContentShare,
  VideoTileGrid,
  VideoGrid,
} from "amazon-chime-sdk-component-library-react";
import $f from "../../../../src";
import { isMobile } from "react-device-detect";

import MeetingFunctions from "./functions";

const onToggleExpand = (tileId) => {
  try {
    document.getElementById(tileId).classList.toggle("videoTile-full-screen");
    document
      .getElementById("contentVideo")
      .classList.toggle("videoTile-full-screen");
  } catch (e) {}
};

export function RemoteVideoTileLabel(props) {
  const { videoEnabled, sharingContent, muted, signalStrength } =
    useAttendeeStatus(props.attendeeId);

  let options = [];

  if (props.isCurrentModerator && !props.isCurrentAttendee) {
    options.push(
      <i className="fas fa-ellipsis-v fa-fw ms-2  color-purple"></i>
    );
  }

  return (
    <div className=" d-flex ">
      <div className=" d-flex " style={{ width: "6.5rem" }}>
        <span className="text-ellipsis" title={props.externalUserId}>
          {props.externalUserId}
        </span>
      </div>
      <div className="text-end" style={{ width: "5rem" }}>
        <i
          className={
            videoEnabled
              ? "fas fa-video fa-fw ms-2  color-purple"
              : "fas fa-video-slash fa-fw ms-2 text-secondary"
          }
        ></i>
        {/* <i
          className={
            muted
              ? "fas fa-microphone-slash fa-fw ms-2 text-secondary"
              : "fas fa-microphone fa-fw ms-2  color-purple"
          }
        ></i> */}
        <MicrophoneActivity
          attendeeId={props.attendeeId}
          className="mic-activity"
        ></MicrophoneActivity>

        <i
          className={
            "fas fa-expand-alt fa-fw  color-purple expand-video-tile pointer"
          }
          onClick={() => {
            onToggleExpand(props.videoTileId);
          }}
        ></i>
      </div>
    </div>
  );
}

const ModeratorsVideoTiles = (props) => {
  const meetingManager = useMeetingManager();
  const { attendeeIdToTileId } = useRemoteVideoTileState();
  const audioVideo = useAudioVideo();

  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const { muted, toggleMute } = useToggleLocalMute();
  const { toggleContentShare } = useContentShareControls();

  const a = useLocalAudioInputActivity();

  const { roster } = useRosterState();
  const attendees = Object.values(roster);

  let videoTiles = {};

  let moderatorTiles = [];

  Object.keys(attendeeIdToTileId)?.forEach((tileId) => {
    const attendeeInfo = MeetingFunctions.getAttendee(roster, tileId);

    if (
      MeetingFunctions.participantIsModerator(
        meetingManager,
        attendeeInfo.externalUserId
      )
    ) {
      const videoTileId = $f.key();

      moderatorTiles.push(
        <RemoteVideo
          tileId={attendeeIdToTileId[tileId]}
          className=""
          key={$f.key()}
        />
      );

      videoTiles[tileId] = (
        <div
          className="moderatorVideoTile  position-relative"
          key={$f.key()}
          id={videoTileId}
        >
          <label className="rounded-9rem ps-2 pe-2">
            <RemoteVideoTileLabel
              attendeeId={tileId}
              externalUserId={attendeeInfo.name}
              isModerator={true}
              isCurrentAttendee={MeetingFunctions.isCurrentAttendee(
                audioVideo,
                tileId
              )}
              isCurrentModerator={MeetingFunctions.isCurrentModerator(
                meetingManager,
                audioVideo
              )}
              videoTileId={videoTileId}
              key={$f.key()}
            ></RemoteVideoTileLabel>
          </label>
          <RemoteVideo
            tileId={attendeeIdToTileId[tileId]}
            className=""
            key={$f.key()}
          />
        </div>
      );
    }
  });

  const attendeeItems = [];
  const participants = [];
  const moderatorTitles = [];

  if (MeetingFunctions.isCurrentModerator(meetingManager, audioVideo)) {
    const localAttendeeId =
      audioVideo.audioVideoController?.realtimeController?.state
        ?.localAttendeeId;

    const attendeeInfo = MeetingFunctions.getAttendee(roster, localAttendeeId);
    if (!MeetingFunctions.isRecordingUser(attendeeInfo.name)) {
      const videoTileId = $f.key();

      attendeeItems.push(
        <div
          className="moderatorVideoTile   position-relative"
          key={$f.key()}
          id={videoTileId}
        >
          <label className="rounded-9rem ps-2 pe-2">
            <RemoteVideoTileLabel
              attendeeId={localAttendeeId}
              externalUserId={attendeeInfo.name}
              isModerator={true}
              isCurrentAttendee={MeetingFunctions.isCurrentAttendee(
                audioVideo,
                localAttendeeId
              )}
              isCurrentModerator={MeetingFunctions.isCurrentModerator(
                meetingManager,
                audioVideo
              )}
              videoTileId={videoTileId}
              key={$f.key()}
            ></RemoteVideoTileLabel>
          </label>

          <LocalVideo className="" key={$f.key()}></LocalVideo>
          <div className="empty ">
            {isVideoEnabled
              ? ""
              : MeetingFunctions.createRemoteVideoAvatar(attendeeInfo.name)}
          </div>
        </div>
      );
    }
  }

  attendees.forEach((attendee) => {
    const { chimeAttendeeId, name, externalUserId } = attendee;

    const attendeeInfo = MeetingFunctions.getAttendee(roster, chimeAttendeeId);

    if (!MeetingFunctions.isRecordingUser(attendeeInfo.name)) {
      if (
        MeetingFunctions.participantIsModerator(
          meetingManager,
          attendeeInfo.externalUserId
        )
      ) {
        moderatorTitles.push(videoTiles[chimeAttendeeId]);
        if (videoTiles[chimeAttendeeId]) {
          attendeeItems.push(videoTiles[chimeAttendeeId]);
        } else {
          if (
            !MeetingFunctions.isCurrentAttendee(audioVideo, chimeAttendeeId)
          ) {
            const videoTileId = $f.key();

            attendeeItems.push(
              <div
                className="moderatorVideoTile  position-relative"
                key={$f.key()}
                id={videoTileId}
              >
                <label className="rounded-9rem ps-2 pe-2">
                  <RemoteVideoTileLabel
                    attendeeId={chimeAttendeeId}
                    externalUserId={attendeeInfo.name}
                    isModerator={true}
                    isCurrentAttendee={MeetingFunctions.isCurrentAttendee(
                      audioVideo,
                      chimeAttendeeId
                    )}
                    isCurrentModerator={MeetingFunctions.isCurrentModerator(
                      meetingManager,
                      audioVideo
                    )}
                    videoTileId={videoTileId}
                    key={$f.key()}
                  ></RemoteVideoTileLabel>
                </label>

                <div className="empty ">
                  {MeetingFunctions.createRemoteVideoAvatar(attendeeInfo.name)}
                </div>
              </div>
            );
          }
        }
      } else {
        participants.push(chimeAttendeeId);
      }
    }
  });

  let grid = [];

  switch (attendeeItems.length) {
    case 1:
      grid.push(attendeeItems);
      break;
    case 2:
      grid.push(
        <div className="row p-0 h-100">
          <div className="col-md-6 p-0">{attendeeItems[0]}</div>
          <div className="col-md-6 p-0">{attendeeItems[1]}</div>
        </div>
      );
      break;
    case 3:
      grid.push(
        <div className="row p-0 h-100">
          <div className="col-md-6 pe-0">{attendeeItems[0]}</div>
          <div className="col-md-6 ">
            <div className="row p-0 h-100">
              <div className="col-md-12 p-0">{attendeeItems[1]}</div>
              <div className="col-md-12 p-0">{attendeeItems[2]}</div>
            </div>
          </div>
        </div>
      );
      break;
    case 4:
      grid.push(
        <div className="row p-0 h-100">
          <div className="col-md-6 p-0">{attendeeItems[0]}</div>
          <div className="col-md-6 p-0">{attendeeItems[1]}</div>
          <div className="col-md-6 p-0">{attendeeItems[2]}</div>
          <div className="col-md-6 p-0">{attendeeItems[3]}</div>
        </div>
      );
      break;
    default:
      grid.push(
        <div className="border h-100">
          <div style={{ minHeight: "450px" }} className="d-flex">
            <div className="m-auto">
              <div className="text-center">
                <h3 className="display-3 ">Please wait for the host to join</h3>
                <span className="d-block color-grey">
                  Host will be online soon
                </span>
              </div>
            </div>
          </div>
        </div>
      );
      break;
  }

  const ModeratorTileClass = (moderators) => {
    if (moderators.length < 2) {
      return { gridTemplate: "repeat(1,1fr) / 1fr", zIndex: 0 };
    }

    return { zIndex: 0 };
  };

  return (
    <div
      className=" m-2 ms-0 me-0 position-relative"
      id="video-control-grid"
      style={
        participants.length > 0
          ? $f.getParameterByName("breakout") || MeetingFunctions.isMeeting()
            ? { height: "calc(100% - 2rem)" }
            : !isMobile
            ? { height: "calc(100% - 12rem)" }
            : { height: "calc(100% - 2rem)" }
          : { height: "calc(100% - 2rem)" }
      }
    >
      <div id="contentVideo">
        <ContentShare />
      </div>

      {MeetingFunctions.isBreakout() || MeetingFunctions.isMeeting() ? (
        <VideoTileGrid
          style={{ zIndex: 0 }}
          key={$f.key()}
          noRemoteVideoView={
            participants.length == 0 ? (
              <div style={{ minHeight: "450px" }} className="d-flex">
                <div className="m-auto">
                  <h3 className="display-3 ">
                    Waiting for the participants to join
                  </h3>
                  <span className="d-block color-grey">
                    Room participants will join soon
                  </span>
                </div>
              </div>
            ) : (
              ""
            )
          }
        ></VideoTileGrid>
      ) : //grid
      isMobile ? (
        <VideoTileGrid
          style={ModeratorTileClass(moderatorTiles)}
          noRemoteVideoView={
            moderatorTiles.length == 0 ? (
              <div style={{ minHeight: "450px" }} className="d-flex">
                <div className="m-auto">
                  <h3 className="display-3 ">Moderator cameras</h3>
                  <span className="d-block color-grey"></span>
                </div>
              </div>
            ) : (
              ""
            )
          }
        >
          {moderatorTiles}
        </VideoTileGrid>
      ) : (
        grid
      )}
      {/* {grid} */}
      {/* <VideoTileGrid></VideoTileGrid> */}
      {/* <div className="video-control-panel">
        {MeetingFunctions.createCameraDeviceButton(
          meetingManager,
          isVideoEnabled,
          toggleVideo
        )}

        {MeetingFunctions.createAudioInputButton(
          meetingManager,
          muted,
          toggleMute
        )}
        {MeetingFunctions.isBreakout() ||
        MeetingFunctions.isCurrentModerator(meetingManager, audioVideo)
          ? MeetingFunctions.createContentShareButton(toggleContentShare)
          : ""}
        {MeetingFunctions.createExpandButton(
          onToggleExpand,
          "video-control-grid"
        )}
        {MeetingFunctions.createLeaveButton()}
      </div> */}
    </div>
  );
};
export default ModeratorsVideoTiles;
