import Meeting from "./meeting";
import { Component } from "react";
import { ThemeProvider } from "styled-components";
import {
  lightTheme,
  MeetingProvider,
} from "amazon-chime-sdk-component-library-react";
import ModalWidget from "../../modalwidget";
import $c from "../../../../modules/functions";
import MeetingFunctions from "./functions";
import $f from "../../../../src";

class IndexMeeting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      menu: "participants",
      meetingStatus: null,
      moderatorEntered: false,
      isModerator: false,
    };
  }

  componentDidMount() {
    const { data, module, view, response } = this.props;

    if (!$c.isLoggedIn()) {
      $c.logout(window.location.href);
      return;
    }

    if (Array.isArray(response.data)) {
      response.data = response.data[0];
    }

    let meetingStatus = $c.checkEventDateStatus(
      response.data.from,
      response.data.to,
      10,
      30
    );

    const isModerator = MeetingFunctions.isModerator(response.data.moderators);

    if (meetingStatus == "join" && !isModerator) {
      if (!response.data.moderatorHasJoined) {
        meetingStatus = "waiting_host";
        //meetingStatus = "join";
      }
    }

    document.body.style.paddingTop = "0rem";

    this.setState({
      loaded: true,
      data,
      module,
      view,
      meetingStatus,
      response,
      isModerator,
      meeting: { ...response.data },
    });

    if (meetingStatus == "waiting_host") {
      setTimeout(this.props.module.doWork, 5000);
    }
  }

  render() {
    if (this.state.loaded) {
      switch (this.state.meetingStatus) {
        case "waiting_host":
          return (
            <main className="container-fluid " style={{ minHeight: "100%" }}>
              <div className="wrapper  vh-100">
                <header>
                  <nav className="navbar navbar-expand-md  ">
                    <div className="container-fluid viewport-padding  pt-md-2125rem  color-strong-grey">
                      <span className="display-5 mb-0">
                        {window["COMPANY_LOGO_ONLY"]()}
                      </span>
                    </div>
                  </nav>
                </header>

                <div className="viewport-padding fullpage-form m-auto main-height d-grid">
                  <div className="mt-auto mb-auto ">
                    <div className="text-center">
                      <h1 className="display-3-no-line-height">
                        Waiting for the host...
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          );

        case "pending":
          return (
            <main className="container-fluid " style={{ minHeight: "100%" }}>
              <div className="wrapper  vh-100">
                <header>
                  <nav className="navbar navbar-expand-md  ">
                    <div className="container-fluid viewport-padding  pt-md-2125rem  color-strong-grey">
                      <span className="display-5 mb-0">
                        {window["COMPANY_LOGO_ONLY"]()}
                      </span>
                    </div>
                  </nav>
                </header>

                <div className="viewport-padding fullpage-form m-auto main-height d-grid">
                  <div className="mt-auto mb-auto ">
                    <div className="text-center">
                      <h1 className="display-3-no-line-height">
                        Meeting starts soon
                      </h1>
                      <h5 className="display-5 color-grey pb-1438rem">
                        {$c.formatDateFromTo(
                          this.state.response.data.from,
                          this.state.response.data.to
                        )}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          );

        case "ended":
          return (
            <main className="container-fluid " style={{ minHeight: "100%" }}>
              <div className="wrapper  vh-100">
                <header>
                  <nav className="navbar navbar-expand-md  ">
                    <div className="container-fluid viewport-padding  pt-md-2125rem color-strong-grey">
                      <span className="display-5 mb-0">
                        {window["COMPANY_LOGO_ONLY"]()}
                      </span>
                    </div>
                  </nav>
                </header>

                <div className="viewport-padding fullpage-form m-auto main-height d-grid">
                  <div className="mt-auto mb-auto ">
                    <div className="text-center">
                      <h1 className="display-3-no-line-height">
                        Meeting has ended
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          );

        case "join":
          return (
            <>
              <ThemeProvider theme={lightTheme}>
                <MeetingProvider>
                  <Meeting meeting={this.state.meeting}></Meeting>
                </MeetingProvider>
              </ThemeProvider>
              <ModalWidget
                ref={(refComponent) => {
                  window["meetingModal"] = refComponent;
                }}
              ></ModalWidget>
            </>
          );
      }
    }

    return "";
  }
}

export default IndexMeeting;
