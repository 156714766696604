import React, { Component } from "react";
import $f from "../../../../../src";
import { Link } from "react-router-dom";
import Data from "../../../../../modules/data/data";
import ConferenceVideo from "../../../../../types/conferenceVideo/conferenceVideo";
import $c from "../../../../../modules/functions";
import Modals from "../../../../../modules/modals";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";
import Actions from "../../../../../modules/actions/actions";

class ConferenceLive extends Component {
  constructor(props) {
    super(props);

    if (!$c.isLoggedIn()) {
      $c.logout(window.location.href);
    }

    this.setActiveTab = this.setActiveTab.bind(this);
    this.topMenu = this.topMenu.bind(this);
    this.participants = this.participants.bind(this);
    this.speakers = this.speakers.bind(this);
    this.polls = this.polls.bind(this);
    this.qna = this.qna.bind(this);
    this.fetchQnA = this.fetchQnA.bind(this);
    this.sessions = this.sessions.bind(this);
    this.fetchPolls = this.fetchPolls.bind(this);
    this.pollsList = this.pollsList.bind(this);
    this.askQuestion = this.askQuestion.bind(this);
    this.fetchStreamKeys = this.fetchStreamKeys.bind(this);

    this.state = {
      data: [],
      loaded: false,
      training: {},
      questions: [],
      question: "",
    };
  }

  componentDidMount() {
    const { data, module, view, specialProperties, response } = this.props;

    let templateConfiguration = view.template.configuration;

    const innerTemplates = ["header"];

    innerTemplates.map((itm, idx) => {
      let tmp = templateConfiguration[itm + "Template"]
        ? templateConfiguration[itm + "Template"]
        : () => {};
      this[itm + "Template"] = tmp.bind(this);
    });

    const conferenceData = response.data[0];

    let currentDay = {};
    let currentSession = {};
    let participants = conferenceData.participants
      ? conferenceData.participants
      : [];

    conferenceData.days.forEach((el) => {
      if (el._id == $f.getQueryPath(5)) {
        currentDay = el;
        el.sessions.forEach((sel) => {
          if (sel._id == $f.getQueryPath(7)) {
            currentSession = sel;
            return;
          }
        });
        return;
      }
    });

    this.setState({
      activeTab: 0,
      specialProperties,
      data,
      loaded: true,
      view,
      module,
      response,
      currentDay,
      currentSession,
      participants,
      polls: [],
      checkedAnswers: {},
      changeVote: {},
    });

    // document.body.style.paddingTop = "3.7rem";

    // $f.addCSS("/conference/video-js.css");
    // $f.addScript("/conference/video.min.js");
    // $f.addScript("/conference/amazon-ivs-videojs-tech.min.js");
    // $f.addScript("/conference/amazon-ivs-quality-plugin.min.js");
  }

  setActiveTab(index) {
    if (index === 1) {
      this.fetchPolls();
    }
    if (index === 2) {
      this.fetchQnA();
    }
    if (index === 3) {
      //this.fetchStreamKeys();
    }

    this.setState({ activeTab: index });
  }

  topMenu() {
    const confMenu = Data.LeftMenuData.conference.live.data;

    let isSpeaker = false;
    $c.conferenceSessionSpeakers(this.state.currentSession)?.map((el) => {
      if ($c.getUserId() === el._id || $c.userIsAdmin()) {
        isSpeaker = true;
      }
    });

    return (
      <nav
        className="navbar navbar-expand top-subnavbar border-bottom-1 bg-white scroll-x"
        draggable="false"
      >
        <div className="container-fluid viewport-padding " draggable="false">
          <ul className="navbar-nav" draggable="false">
            <li className="nav-item" key={$f.key()}>
              <Link
                className={"nav-link nav-ellipsis "}
                to={"/event/conference/".concat($f.getQueryPath(3))}
              >
                <span className=" color-grey mb-3" role="button">
                  <i className="fas fa-chevron-left me-2   color-grey "></i>
                </span>
                Reception
              </Link>
            </li>
            {confMenu?.map((itm, idx) => {
              const active = this.state.activeTab === idx ? "active" : "";

              if (itm.auth) {
                if (!$f.getValue(itm.auth)) {
                  return "";
                }
              }

              return (
                <li className="nav-item" key={$f.key()}>
                  <span
                    className={"nav-link nav-ellipsis ".concat(active)}
                    role="button"
                    onClick={(e) => {
                      this.setActiveTab(idx);
                    }}
                  >
                    {$f.getValue(itm.title)}
                  </span>
                </li>
              );
            })}
            {/* {isSpeaker ? (
              <li className="nav-item" key={$f.key()}>
                <span
                  className={"nav-link nav-ellipsis ".concat(
                    this.state.activeTab === confMenu.length ? "active" : ""
                  )}
                  role="button"
                  onClick={(e) => {
                    this.setActiveTab(confMenu.length);
                  }}
                >
                  Stream keys
                </span>
              </li>
            ) : (
              ""
            )} */}
          </ul>
        </div>
      </nav>
    );
  }

  fetchStreamKeys() {
    let _this = this;
    let isSpeaker = false;
    $c.conferenceSessionSpeakers(this.state.currentSession)?.map((el) => {
      if ($c.getUserId() === el._id || $c.userIsAdmin()) {
        isSpeaker = true;
      }
    });

    if (isSpeaker) {
      $f.fetch(
        "/stream/{conferenceId}/settings",
        "GET",
        null,
        {},
        {
          conferenceId: () => {
            return $f.getQueryPath(3);
          },
          sessionId: () => {
            return $f.getQueryPath(7);
          },
        },
        (resp) => {
          //_this.setState({ polls: resp.data.data });
        },
        null,
        null,
        null,
        null,
        false
      );
    }
  }

  streamKeys() {}

  speakers() {
    const data = this.state.response.data[0];
    return (
      <>
        <div className="display-4 ">Speakers</div>
        <div className="mt-3">
          {$c.conferenceSessionSpeakers(this.state.currentSession).length <
          1 ? (
            <span className="color-grey">No speakers available</span>
          ) : (
            $c
              .conferenceSessionSpeakers(this.state.currentSession)
              .map((el) => {
                return (
                  <div
                    className="mt-3"
                    role="button"
                    onClick={() => {
                      Modals.events.openSpeaker({
                        speaker: el,
                        data: data,
                      });
                    }}
                  >
                    {$c.createAvatar(
                      el.profileImage,
                      el.firstName,
                      el.lastName,
                      "avatar-large",
                      el.jobTitle
                    )}
                  </div>
                );
              })
          )}
        </div>
      </>
    );
  }

  fetchPolls() {
    let _this = this;
    $f.fetch(
      "/streams/{conferenceId}/pollAnswers/sessions/{sessionId}",
      "GET",
      null,
      {},
      {
        conferenceId: () => {
          return $f.getQueryPath(3);
        },
        sessionId: () => {
          return $f.getQueryPath(7);
        },
      },
      (resp) => {
        let checkedAnswers = { ...this.state.checkedAnswers };
        let changeVote = { ...this.state.changeVote };

        resp.data.data?.forEach((el) => {
          if (el.votedAnswer) {
            if (!changeVote[el._id]) {
              checkedAnswers[el._id] = el.votedAnswer;
            }
          }
        });

        _this.setState({
          polls: resp.data.data,
          checkedAnswers: checkedAnswers,
        });

        if ($c.userIsAdmin() || $c.userIsHost()) {
          //  setTimeout(_this.fetchPolls, 5000);
        }
      },
      null,
      null,
      null,
      null,
      false
    );
  }

  isSubmitPollEnable(el) {
    if (this.state.checkedAnswers[el._id]) {
      if (typeof this.state.checkedAnswers[el._id] === "string") {
        if (this.state.checkedAnswers[el._id] !== el.votedAnswer) {
          return false;
        }
      }
    }
    return true;
  }

  pollsList() {
    let _this = this;
    return (
      <PerfectScrollbar>
        <div
          style={{
            height: $c.userIsSimpleMember()
              ? "calc(100vh - 200px)"
              : "calc(100vh - 300px)",
          }}
        >
          {this.state.polls.map((el) => {
            let buttons = [];
            let subtitle = "Click edit to edit poll before start";

            let title = el.title;

            if ($c.userIsSimpleMember()) {
              switch (el.status) {
                case "ended":
                  title = "Poll has ended";
                  subtitle = el.votes
                    ? el.votes.toString().concat(" participants voted")
                    : "0 participants voted";
                  buttons = [];
                  break;

                case "started":
                  title = "Poll in progress";
                  subtitle = "";
                  // subtitle = !el.hasVoted ? (
                  //   "You can only choose one answer. Your vote is anonymous."
                  // ) : (
                  //   <>
                  //     You have already voted.<br></br> Press change vote if you
                  //     want to change your vote."
                  //   </>
                  // );

                  if (!this.state.changeVote[el._id] && el.hasVoted) {
                    buttons = [
                      <button
                        className={"btn btn-purple btn-sm text-white"}
                        onClick={() => {
                          let changeVote = {
                            ...this.state.changeVote,
                          };

                          changeVote[el._id] = true;

                          console.log(changeVote);

                          this.setState({ changeVote });
                        }}
                      >
                        Change vote
                      </button>,
                    ];
                  } else {
                    let _this = this;
                    buttons = [
                      <button
                        className={"btn btn-purple btn-sm text-white"}
                        onClick={() => {
                          Actions.polls.vote({
                            answerId: this.state.checkedAnswers[el._id],
                            replace: {
                              conferenceId: () => {
                                return $f.getQueryPath(3);
                              },
                              dayId: () => {
                                return $f.getQueryPath(5);
                              },
                              sessionId: () => {
                                return $f.getQueryPath(7);
                              },
                              pollId: () => {
                                return el._id;
                              },
                            },
                            callback: () => {
                              let changeVote = {
                                ...this.state.changeVote,
                              };

                              _this.fetchPolls();

                              delete changeVote[el._id];

                              this.setState({ changeVote });
                            },
                          });
                        }}
                        disabled={this.isSubmitPollEnable(el)}
                      >
                        Submit vote
                      </button>,
                    ];
                  }
                  break;

                default:
                  break;
              }
            } else {
              switch (el.status) {
                case "not_started":
                  buttons = [
                    <button
                      className="btn btn-white btn-sm me-2"
                      onClick={() => {
                        Modals.events.deletePoll({
                          callback: () => {
                            _this.fetchPolls();
                          },
                          props: { data: { title: el.title } },
                          replace: {
                            conferenceId: () => {
                              return $f.getQueryPath(3);
                            },
                            dayId: () => {
                              return $f.getQueryPath(5);
                            },
                            sessionId: () => {
                              return $f.getQueryPath(7);
                            },
                            pollId: () => {
                              return el._id;
                            },
                          },
                        });
                      }}
                    >
                      Delete
                    </button>,
                    <button
                      className="btn btn-white btn-sm me-2"
                      onClick={() => {
                        Modals.events.editPoll({
                          title: "Edit poll",
                          action: "update",
                          callback: () => {
                            _this.fetchPolls();
                          },
                          replace: {
                            conferenceId: () => {
                              return $f.getQueryPath(3);
                            },
                            dayId: () => {
                              return $f.getQueryPath(5);
                            },
                            sessionId: () => {
                              return $f.getQueryPath(7);
                            },
                            pollId: () => {
                              return el._id;
                            },
                          },
                        });
                      }}
                    >
                      Edit
                    </button>,
                    <button
                      className="btn btn-purple btn-sm "
                      onClick={() => {
                        Modals.events.startPoll({
                          props: { data: { title: el.title } },
                          callback: () => {
                            _this.fetchPolls();
                          },
                          replace: {
                            conferenceId: () => {
                              return $f.getQueryPath(3);
                            },
                            dayId: () => {
                              return $f.getQueryPath(5);
                            },
                            sessionId: () => {
                              return $f.getQueryPath(7);
                            },
                            pollId: () => {
                              return el._id;
                            },
                          },
                        });
                      }}
                    >
                      Start poll
                    </button>,
                  ];
                  break;

                case "ended":
                  subtitle = el.votes
                    ? el.votes.toString().concat(" participants voted")
                    : "0 participants voted";
                  buttons = [<span className=" p-2 pe-0">Poll ended</span>];
                  break;

                case "started":
                  subtitle = el.votes
                    ? el.votes.toString().concat(" participants voted")
                    : "0 participants voted";

                  buttons = [
                    <span className=" me-2 p-2">Poll in progress</span>,
                    <button
                      className="btn btn-purple btn-sm "
                      onClick={() => {
                        Modals.events.endPoll({
                          props: { data: { title: el.title } },
                          callback: () => {
                            _this.fetchPolls();
                          },
                          replace: {
                            conferenceId: () => {
                              return $f.getQueryPath(3);
                            },
                            dayId: () => {
                              return $f.getQueryPath(5);
                            },
                            sessionId: () => {
                              return $f.getQueryPath(7);
                            },
                            pollId: () => {
                              return el._id;
                            },
                          },
                        });
                      }}
                    >
                      End poll
                    </button>,
                  ];
                  break;

                default:
                  break;
              }
            }

            return (
              <div className="border rounded p-3 bg-light-grey mt-3">
                <span className="strong  d-block text-break">{title}</span>
                <span className="color-grey small">{subtitle}</span>
                <div>
                  <hr></hr>
                </div>
                <div>
                  <span className="strong d-block mb-2 text-break">
                    {el.question.text}
                  </span>
                  <div>
                    {el.question.answers.map((ans) => {
                      switch (el.status) {
                        case "not_started":
                          return (
                            <div className="mb-2">
                              <span className="text-break">{ans.answer}</span>
                              <ProgressBar
                                variant="purple"
                                max={100}
                                min={0}
                                style={{
                                  "--bs-progress-height": "0.5rem",
                                }}
                                now={0}
                              ></ProgressBar>
                              <div className="d-flex mt-1 color-grey small"></div>
                            </div>
                          );

                        case "started":
                          if ($c.userIsSimpleMember()) {
                            return (
                              <div className="mb-2">
                                <span className="text-break">{ans.answer}</span>

                                <div className="d-flex mt-1">
                                  <div className="w-100 mt-2">
                                    <ProgressBar
                                      variant="purple"
                                      max={100}
                                      min={0}
                                      style={{
                                        "--bs-progress-height": "0.5rem",
                                      }}
                                      now={0}
                                    ></ProgressBar>
                                  </div>
                                  <div>
                                    <Checkbox
                                      className="ms-2"
                                      checked={
                                        this.state.checkedAnswers[el._id] ===
                                        ans._id
                                          ? true
                                          : false
                                      }
                                      onChange={(checked) => {
                                        if (
                                          !el.hasVoted ||
                                          this.state.changeVote[el._id]
                                        ) {
                                          let checkedAnswers = {
                                            ...this.state.checkedAnswers,
                                          };

                                          if (checked) {
                                            checkedAnswers[el._id] = ans._id;
                                          }

                                          this.setState({
                                            checkedAnswers,
                                          });
                                        }
                                      }}
                                      icon={
                                        <div
                                          style={{
                                            display: "flex",
                                            flex: 1,
                                            backgroundColor:
                                              this.state.checkedAnswers[
                                                el._id
                                              ] === ans._id
                                                ? "#4e2c95"
                                                : "#fff",
                                            alignSelf: "stretch",
                                          }}
                                        >
                                          <Icon.FiCheck
                                            color="white"
                                            size={20}
                                          />
                                        </div>
                                      }
                                      borderColor={
                                        this.state.checkedAnswers[el._id] ===
                                        ans._id
                                          ? "#4e2c95"
                                          : "#d7d7d7"
                                      }
                                      borderRadius={23}
                                      style={{
                                        overflow: "hidden",
                                        backgroundColor: "#fff",
                                      }}
                                      size={23}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div className="mb-2">
                                <span className="text-break">{ans.answer}</span>
                                <ProgressBar
                                  variant="purple"
                                  max={el.votes ? el.votes : 0}
                                  min={0}
                                  style={{
                                    "--bs-progress-height": "0.5rem",
                                  }}
                                  now={ans.votes}
                                ></ProgressBar>
                                <div className="d-flex mt-1 color-grey small">
                                  <span className="w-100">
                                    {el.votes > 0
                                      ? Math.floor((ans.votes * 100) / el.votes)
                                      : 0}
                                    %
                                  </span>
                                  <span className="text-nowrap">
                                    {ans.votes
                                      ? ans.votes
                                          .toString()
                                          .concat(" ", "votes")
                                      : "0 votes"}
                                  </span>
                                </div>
                              </div>
                            );
                          }

                        case "ended":
                          return (
                            <div className="mb-2">
                              <span className="text-break">{ans.answer}</span>
                              <ProgressBar
                                variant="purple"
                                max={el.votes ? el.votes : 0}
                                min={0}
                                style={{
                                  "--bs-progress-height": "0.5rem",
                                }}
                                now={ans.votes}
                              ></ProgressBar>
                              <div className="d-flex mt-1 color-grey small">
                                <span className="w-100">
                                  {el.votes > 0
                                    ? Math.floor((ans.votes * 100) / el.votes)
                                    : 0}
                                  %
                                </span>
                                <span className="text-nowrap">
                                  {ans.votes
                                    ? ans.votes.toString().concat(" ", "votes")
                                    : "0 votes"}
                                </span>
                              </div>
                            </div>
                          );
                      }
                    })}
                  </div>
                  <div className="text-end mt-3">{buttons}</div>
                </div>
              </div>
            );
          })}
        </div>
      </PerfectScrollbar>
    );
  }

  polls() {
    let _this = this;

    if (!this.state.hostEnter) {
      if ($c.userIsSimpleMember()) {
        return (
          <div className="">
            <div className="display-4-no-margin">Polls</div>
            <div className="color-grey mt-1">Polls will appear here</div>
          </div>
        );
      }

      return (
        <div className="">
          <h4 className="display-4-no-margin ">Polls not available yet</h4>
          <div className="color-grey mt-1">
            You need to start the stream before creating polls
          </div>
        </div>
      );
    }

    return (
      <>
        {$c.userIsSimpleMember() ? (
          <>
            <div className="display-4-no-margin">Polls</div>
            {this.state.polls?.length > 0 ? (
              <div className="mt-1 mb-2 color-grey">
                Υou can only choose one answer. You can change your vote before
                the end of the poll. Your vote is anonymous.
              </div>
            ) : (
              <div className="mt-1 mb-2 color-grey">Polls will appear here</div>
            )}
          </>
        ) : (
          <div className=" mb-4">
            <div className="display-4-no-margin">Polls</div>
            <div className="mt-1 mb-2 color-grey">Create your polls</div>
            <div className="mt-1">
              <button
                className="btn btn-sm btn-purple"
                onClick={() => {
                  Modals.events.editPoll({
                    api: "new",
                    title: "Start a poll",
                    action: "create",
                    callback: () => {
                      _this.fetchPolls();
                    },
                    replace: {
                      conferenceId: () => {
                        return $f.getQueryPath(3);
                      },
                      dayId: () => {
                        return $f.getQueryPath(5);
                      },
                      sessionId: () => {
                        return $f.getQueryPath(7);
                      },
                    },
                  });
                }}
              >
                Create new poll
              </button>
            </div>
          </div>
        )}
        {this.pollsList()}
      </>
    );
  }

  participants() {
    return (
      <>
        <div className="display-4-no-bold ">Participants</div>
        <div className="mt-3">
          {$c.conferenceParticipants(this.state.participants).map((el) => {
            return (
              <div className="mt-3">
                {$c.createAvatar(
                  el.profileImage,
                  el.firstName,
                  el.lastName,
                  "avatar-xs",
                  el.jobTitle
                )}
              </div>
            );
          })}
        </div>
      </>
    );
  }

  sessions() {
    return (
      <>
        <div className="display-4-no-bold ">Today's sessions</div>
        <div className="mt-3">
          {this.state.currentDay.sessions.map((sel) => {
            return (
              <div className="pb-2 mb-2 border-bottom-1">
                <span className="d-block display-4-no-margin">
                  Session: <strong>{sel.title}</strong>
                </span>
                <span className="d-block color-grey mt-2">
                  {$c.formatDateFromTo(sel.from, sel.to)}
                </span>
              </div>
            );
          })}
        </div>
      </>
    );
  }

  askQuestion() {
    let _this = this;
    const Body = (
      <div>
        <p>
          Questions will be viewed by the host only. Questions are answered
          during the presentation orally.
        </p>
        <div className="mt-2">
          {_this.state.question}
          <textarea
            className="form-control"
            onChange={(e) => {
              _this.setState({ question: e.target.value });
            }}
          >
            {_this.state.question}
          </textarea>
        </div>
      </div>
    );

    window["primaryModal"].setState({
      show: true,
      size: "default",
      title: (
        <h2 className="display-3-no-line-height header-ellipsis">
          Ask question
        </h2>
      ),
      footer: [
        <button
          type="button"
          onClick={() => {
            _this.setState({ question: "" });
            window["primaryModal"].close();
          }}
          className="btn btn-sm btn-white"
        >
          Cancel
        </button>,
        <button
          type="button"
          onClick={() => {
            if (_this.state.question) {
              $f.fetch(
                "/stream/{conferenceId}/day/{dayId}/session/{sessionId}/questionAnswer",
                "POST",
                { questionBody: _this.state.question },
                {},
                {
                  conferenceId: () => {
                    return $f.getQueryPath(3);
                  },
                  dayId: () => {
                    return $f.getQueryPath(5);
                  },
                  sessionId: () => {
                    return $f.getQueryPath(7);
                  },
                },
                (resp) => {
                  _this.setState({
                    questions: [..._this.state.questions, _this.state.question],
                    question: "",
                  });
                  window["primaryModal"].close();
                }
              );
            }
          }}
          className="btn btn-sm btn-purple"
        >
          Send question
        </button>,
      ],
      body: Body,
    });
  }

  qna() {
    let _this = this;

    if (!this.state.hostEnter) {
      if (!$c.userIsSimpleMember()) {
        return (
          <div className="">
            <h5 className="display-4-no-margin">Questions</h5>
            <div className="mt-1 color-grey">Questions will appear here</div>
          </div>
        );
      }

      return (
        <div className="">
          <h4 className="display-4-no-margin ">Questions</h4>
          <div className="mt-1 color-grey">
            Please wait till the stream starts
          </div>
        </div>
      );
    }

    return (
      <>
        {!$c.userIsSimpleMember() ? (
          <>
            <div className="display-4-no-margin">Questions</div>
            <div className="mt-1 color-grey">
              {this.state.qna?.length > 0
                ? "All questions are anonymous and you may choose which one to answer throughout the presentation."
                : "Questions will appear here"}
            </div>
            <PerfectScrollbar>
              <div
                style={{
                  height: !$c.userIsSimpleMember()
                    ? "calc(100vh - 200px)"
                    : "calc(100vh - 300px)",
                }}
              >
                {this.state.qna?.map((question, idx) => {
                  return (
                    <div className="border rounded p-3 bg-light-grey mt-3">
                      <span className="strong  d-block text-break">
                        Question {idx + 1}
                      </span>
                      <span className="mt-2 d-block text-break">
                        {question.body}
                      </span>
                    </div>
                  );
                })}
              </div>
            </PerfectScrollbar>
          </>
        ) : (
          <div className=" mb-4">
            <div className="display-4-no-margin ">Ask a question</div>
            <div className="mt-1 mb-2 color-grey">
              Only the host can view your questions. Questions are anonymous.
            </div>
            <div className="mt-1">
              <button
                className="btn btn-sm btn-purple"
                onClick={() => {
                  this.askQuestion();
                  // Modals.events.askQuestion({
                  //   api: "new",
                  //   title: "Ask question",
                  //   action: "create",
                  //   callback: () => {
                  //     _this.fetchPolls();
                  //   },
                  //   replace: {
                  //     conferenceId: () => {
                  //       return $f.getQueryPath(3);
                  //     },
                  //     dayId: () => {
                  //       return $f.getQueryPath(5);
                  //     },
                  //     sessionId: () => {
                  //       return $f.getQueryPath(7);
                  //     },
                  //   },
                  // });
                }}
              >
                Ask question
              </button>
            </div>
            <PerfectScrollbar>
              <div
                className="mt-2"
                style={{
                  height: !$c.userIsSimpleMember()
                    ? "calc(100vh - 200px)"
                    : "calc(100vh - 300px)",
                }}
              >
                <div className="text-start">
                  {this.state.qna?.map((question, idx) => {
                    return (
                      <div className="border rounded p-3 bg-light-grey mt-3">
                        <span className="strong  d-block text-break">
                          Question {idx + 1}
                        </span>
                        <span className="mt-2 d-block text-break">
                          {question.body}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </PerfectScrollbar>
          </div>
        )}
      </>
    );
  }

  fetchQnA() {
    let _this = this;
    $f.fetch(
      "/stream/{conferenceId}/day/{dayId}/session/{sessionId}/questionAnswer",
      "GET",
      null,
      {},
      {
        conferenceId: () => {
          return $f.getQueryPath(3);
        },
        sessionId: () => {
          return $f.getQueryPath(7);
        },
        dayId: () => {
          return $f.getQueryPath(5);
        },
      },
      (resp) => {
        _this.setState({ qna: resp.data.data });
      },
      null,
      null,
      null,
      null,
      false
    );
  }

  render() {
    if (this.state.loaded) {
      const data = this.state.response.data[0];

      return (
        <>
          <header className="fixed-top">
            <nav className="navbar navbar-expand-md bg-light-grey top-navbar">
              <div className="container-fluid viewport-padding  color-strong-grey">
                <span className="display-5 mb-0">
                  {window["COMPANY_NAME"]()}
                </span>
              </div>
            </nav>
            {this.topMenu()}
          </header>
          <main className="container-fluid viewport-meeting-padding pt-2125rem h-100">
            <div className="wrapper h-100">
              <div className="row">
                <div className="col-md-9">
                  <ConferenceVideo
                    data={this.state.currentSession}
                    property={{
                      parentChange: (_this) => {
                        this.setState({
                          hostEnter: _this.state.hostEnter,
                          attendees: _this.state.attendees,
                        });

                        if (this.state.activeTab == 1) {
                          this.fetchPolls();
                        }
                        if (this.state.activeTab == 2) {
                          this.fetchQnA();
                        }
                      },
                      configuration: {
                        title: this.state.currentSession.title,
                        eventNotStartedText: (
                          <>
                            <div className="conference-video d-flex">
                              <div>
                                Session:{" "}
                                <span className="display-4">
                                  {this.state.currentSession.title}
                                </span>
                              </div>
                              <div className="m-auto text-center align-middle">
                                <h2 className="display-3">
                                  The session hasn't started yet
                                </h2>
                                <span>
                                  Session starts{" "}
                                  {$c.formatDateFromTo(
                                    this.state.currentSession.from,
                                    this.state.currentSession.to
                                  )}
                                </span>
                              </div>
                            </div>
                          </>
                        ),
                        eventHostNotEnterYet: (
                          <>
                            <div className="conference-video d-flex">
                              <div>
                                Session:{" "}
                                <span className="display-4">
                                  {this.state.currentSession.title}
                                </span>
                              </div>
                              <div className="m-auto text-center align-middle">
                                <h2 className="display-3">
                                  The session hasn't started yet
                                </h2>
                                <span>
                                  Please wait for the host to start the session
                                </span>
                              </div>
                            </div>
                          </>
                        ),
                        eventHasEndedText: (
                          <>
                            <div className="conference-video d-flex">
                              <div>
                                Session:{" "}
                                <span className="display-4">
                                  {this.state.currentSession.title}
                                </span>
                              </div>
                              <div className="m-auto text-center align-middle">
                                <h2 className="display-3">
                                  The session has ended
                                </h2>
                                <span>
                                  Session date{" "}
                                  {$c.formatDateFromTo(
                                    this.state.currentSession.from,
                                    this.state.currentSession.to
                                  )}
                                </span>
                              </div>
                            </div>
                          </>
                        ),
                        fromDateProperty: "from",
                        endDateProperty: "to",
                        extraStartTime: 10,
                        extraEndTime: 30,
                        playbackUrl: this.state.currentSession.playbackUrl,
                        infoURL:
                          "/stream/" +
                          $f.getQueryPath(3) +
                          "/day/" +
                          $f.getQueryPath(5) +
                          "/session/" +
                          $f.getQueryPath(7) +
                          "/liveInfo",
                      },
                    }}
                  ></ConferenceVideo>
                  {/* <video
                    id="amazon-ivs-videojs"
                    className="video-js vjs-4-3 vjs-big-play-centered rounded conference-video"
                    controls
                    autoPlay
                    playsInline
                    src={this.state.currentSession.playbackUrl}
                  ></video> */}
                </div>
                <div className="col-md-3">
                  {this.state.activeTab == "0" ? this.speakers() : ""}
                  {this.state.activeTab == "1" ? this.polls() : ""}
                  {this.state.activeTab == "2" ? this.qna() : ""}
                </div>
              </div>
            </div>
          </main>
        </>
      );
    } else {
      return "loading...";
    }
  }
}

export default ConferenceLive;
