import React, { Component } from "react";
import Modules from "../../modules/modules";
import $f from "../../src";
import Templates from "../../themes/hapeiron/templates";

class Module extends Component {
  state = {
    loaded: false,
    output: "loading...",
  };
  constructor(props) {
    super(props);
    this.doWork = this.doWork.bind(this);

    this.refTemplate = React.createRef();
  }

  componentDidMount() {
    this.doWork();
  }

  doWork(replace, headers) {
    const configuration = $f.getValue(this.props.property.configuration, this);

    const module = Modules[$f.getValue(configuration.module)];

    const properties = module.properties;

    const specialProperties = $f.getValue(module.specialProperties);

    const view = module.views[$f.getValue(configuration.view)];

    const api = configuration.api
      ? view.apis[$f.getValue(configuration.api)]
      : view.defaultAPI
      ? view.apis[$f.getValue(view.defaultAPI)]
      : "view";

    const dataPath = $f.getValue(api.dataPath);

    const formatResponse = api.formatResponse;

    const postData = api.postData ? $f.getValue(api.postData) : null;

    const template = $f.getValue(view.template.name, this);

    const Template = $f.getValue(Templates[template]);

    const inputData = $f.getValue(configuration.inputData, this);

    if (inputData) {
      const data = $f.moduleData(inputData, properties, view, null, this);

      const specialPropertiesData = $f.moduleSpecialProperties(
        inputData,
        specialProperties,
        view,
        null,
        this
      );

      this.setState({
        output: (
          <Template
            ref={this.refTemplate}
            data={data}
            module={this}
            key={$f.key()}
            view={view}
            specialProperties={specialPropertiesData}
          />
        ),
        loaded: true,
        view,
      });
    } else if (typeof $f.getValue(api.url) !== "string") {
      const data = $f.moduleData(
        $f.getValue(api.url).data,
        properties,
        view,
        null,
        this
      );

      const specialPropertiesData = $f.moduleSpecialProperties(
        $f.getValue(api.url).data,
        specialProperties,
        view,
        null,
        this
      );

      this.setState({
        output: (
          <Template
            ref={this.refTemplate}
            data={data}
            module={this}
            key={$f.key()}
            view={view}
            specialProperties={specialPropertiesData}
          />
        ),
        loaded: true,
        view,
      });
    } else {
      const _this = this;

      $f.fetch(
        $f.getValue(api.url, this),
        api.method,
        postData,
        headers
          ? headers
          : configuration.headers
          ? configuration.headers
          : api.headers,
        replace
          ? replace
          : configuration.replace
          ? configuration.replace
          : api.replace,
        (response) => {
          let responseData = { ...response.data };

          if (formatResponse) {
            responseData = formatResponse(responseData);
          }

          const data = $f.moduleData(
            dataPath
              ? $f.getObjectValueFromPath(responseData, dataPath)
              : responseData.data,
            properties,
            view,
            null,
            _this
          );

          const specialPropertiesData = $f.moduleSpecialProperties(
            response.data,
            specialProperties,
            view,
            null,
            this
          );

          _this.setState({
            output: (
              <Template
                data={data}
                module={_this}
                key={$f.key()}
                view={view}
                specialProperties={specialPropertiesData}
                response={response.data}
                ref={_this.refTemplate}
              />
            ),
            loaded: true,
            view,
          });
        },
        null,
        null,
        null,
        null,
        api.showErrors === false ? false : true,
        api.onError ? api.onError : null
      );
    }
  }

  render() {
    return this.state.loaded ? this.state.output : "";
  }
}

export default Module;
