import parse from "html-react-parser";
import { Component } from "react";
import SimpleReactValidator from "simple-react-validator";

class SpaceAdvanceFields extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.validator = new SimpleReactValidator();
    this.updateData = this.updateData.bind(this);

    this.state = {
      loaded: false,
    };
  }

  onChange(e) {
    this.setState({
      value: e.target.value,
    });

    setTimeout(this["customOnChange"], 0);
  }

  validate() {
    const isValid = this.validator.allValid();

    this.setState({ isValid });
    this.validator.showMessages();
    this.forceUpdate();

    return isValid;
  }

  componentDidMount() {
    let events = [];

    Object.keys(this.props.property.events)?.forEach((element, idx) => {
      if (!element.toLowerCase().startsWith("onchange")) {
        this[element] = this.props.property.events[element];
        this[element] = this[element].bind(this);
        events[element] = this[element];
      } else {
        this["customOnChange"] = this.props.property.events[element];
        this["customOnChange"] = this["customOnChange"].bind(this);
      }
    });

    this.setState({
      loaded: true,
      events: events,
      attributes: { ...this.props.property.attributes },
      value: this.props.value,
      editable: this.props.property.editable === false ? false : true,
      useCustomUI: this.props.property.useCustomUI,
      showFields: false,
    });
  }

  updateData(property, data) {
    let value = { ...this.state.value };

    value[property] = data;

    this.setState({ value });
  }

  updateMoneyRaisedData(property, data) {
    let value = { ...this.state.value };

    if (!value["moneyRaised"]) {
      value["moneyRaised"] = {};
    }

    value["moneyRaised"][property] = data;

    this.setState({ value });
  }

  editable() {
    return (
      <div>
        <div
          role="button"
          className="color-purple"
          onClick={() => {
            this.setState({ showFields: !this.state.showFields });
          }}
        >
          {this.state.showFields
            ? "Hide advanced fields"
            : "Show advanced fields"}
        </div>
        <div
          className={"mt-3 ".concat(this.state.showFields ? "" : "collapse")}
          id="space-advance-fields"
        >
          <div className="form-group mb-3">
            <label className="label-top">Website url</label>
            <input
              type="text"
              value={this.state.value.websiteUrl}
              placeholder="Type website url"
              className="form-control"
              onChange={(e) => {
                this.updateData("websiteUrl", e.target.value);
              }}
            ></input>
          </div>
          <div className="form-group mb-3">
            <label className="label-top">Industry</label>
            <input
              type="text"
              value={this.state.value.industry}
              placeholder="Type industry"
              className="form-control"
              onChange={(e) => {
                this.updateData("industry", e.target.value);
              }}
            ></input>
          </div>
          <div className="form-group mb-3">
            <label className="label-top">Market</label>
            <input
              type="text"
              value={this.state.value.market}
              placeholder="e.g. EMEA / County"
              className="form-control"
              onChange={(e) => {
                this.updateData("market", e.target.value);
              }}
            ></input>
          </div>
          <div className="form-group mb-3">
            <label className="label-top">Money raised</label>
            <div className="input-group mb-3">
              <div style={{ width: "4rem" }} className="me-2">
                <select
                  className="form-select "
                  onChange={(e) => {
                    this.updateMoneyRaisedData("currency", e.target.value);
                  }}
                >
                  <option value="€">€</option>
                  <option value="$">$</option>
                  <option value="د.إ">د.إ</option>
                </select>
              </div>
              <input
                type="text"
                value={this.state.value.moneyRaised?.amount}
                placeholder="100.000"
                className="form-control"
                onChange={(e) => {
                  this.updateMoneyRaisedData("amount", e.target.value);
                }}
              ></input>
            </div>
          </div>
        </div>
      </div>
    );
  }

  nonEditable() {
    return typeof this.state.value === "string"
      ? parse(this.state.value)
      : this.state.value;
  }

  customUI() {}

  render() {
    if (this.state.loaded) {
      if (this.state.useCustomUI) {
        return this.customUI();
      } else if (this.state.editable) {
        return this.editable();
      } else {
        return this.nonEditable();
      }
    }

    return "";
  }
}

export default SpaceAdvanceFields;
