import parse from "html-react-parser";
import { Component } from "react";
import ReactQuill from "react-quill";
import "quill/dist/quill.snow.css";
import SimpleReactValidator from "simple-react-validator";

class Editor extends Component {
  constructor(props) {
    super(props);

    this.handleEditorChange = this.handleEditorChange.bind(this);

    this.validator = new SimpleReactValidator();

    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    let events = [];

    Object.keys(this.props.property.events)?.forEach((element, idx) => {
      if (!element.toLowerCase().startsWith("onchange")) {
        this[element] = this.props.property.events[element];
        this[element] = this[element].bind(this);
        events[element] = this[element];
      } else {
        this["customOnChange"] = this.props.property.events[element];
        this["customOnChange"] = this["customOnChange"].bind(this);
      }
    });

    this.setState({
      loaded: true,
      events: events,
      attributes: { ...this.props.property.attributes },
      value: this.props.value,
      editable: this.props.property.editable === false ? false : true,
      useCustomUI: this.props.property.useCustomUI,
    });
  }

  validate() {
    const isValid = this.validator.allValid();

    this.setState({ isValid });
    this.validator.showMessages();
    this.forceUpdate();

    return isValid;
  }

  handleEditorChange = (content, editor) => {
    if (content == "<p><br></p>") {
      content = "";
    }

    this.setState({ value: content });

    setTimeout(this["customOnChange"], 0);
  };

  editable() {
    const toolbarOptions = [
      ["bold", "italic", "underline", "strike", "link"],
      [{ list: "ordered" }, { list: "bullet" }],
    ];

    return (
      <>
        <div className="form-control p-0 pb-5">
          <ReactQuill
            theme="snow"
            placeholder={this.state.attributes.placeholder}
            value={this.state.value}
            onChange={this.handleEditorChange}
            style={{ height: "60px" }}
            modules={{
              toolbar: toolbarOptions,
            }}
          ></ReactQuill>
        </div>
        <span className="text-danger mt-2 d-block">
          {this.validator.message(
            this.props.property.label,
            this.state.value,
            this.props.property.validation ? this.props.property.validation : ""
          )}
        </span>
      </>
    );
  }

  nonEditable() {
    return typeof this.state.value === "string"
      ? parse(this.state.value)
      : this.state.value;
  }

  customUI() {}

  render() {
    if (this.state.loaded) {
      if (this.state.useCustomUI) {
        return this.customUI();
      } else if (this.state.editable) {
        return this.editable();
      } else {
        return this.nonEditable();
      }
    }

    return "";
  }
}

export default Editor;
