import moment from "moment";
import { Link } from "react-router-dom";
import $f from "../../src";
import EmptyStates from "../emptyStates";
import $c from "../functions";
import Modals from "../modals";

const Conferences = {
  properties: (args) => {
    const { editable } = args;

    return {
      _id: {
        label: "",
        path: "_id",
        type: "hidden",
        labelPosition: "none",
        events: {},
        attributes: {},
      },
      title: {
        label: "Title",
        type: "text",
        editable: editable,
        events: {},
        validation: "required",
        attributes: {
          className: "form-control",
          placeholder: "Type conference title",
        },
      },
      description: {
        label: "About",
        type: "editor",
        editable: editable,

        events: {},
        validation: "required",
        attributes: {
          className: "form-control",
          placeholder: "Type conference overview",
        },
      },

      hosts: {
        label: "Organizers",
        type: "tags",
        editable: editable,
        configuration: {
          type: "hosts",
        },
        events: {},
        attributes: {
          className: "form-control",
        },
      },
      eventType: {
        label: "Event type",
        type: "select",
        editable: editable,
        configuration: {
          options: [
            { label: "Private", value: "Private" },
            { label: "Public", value: "Public" },
          ],
        },
        events: {},
        attributes: {
          className: "form-select",
        },
      },

      shortDescription: {
        label: "Short description (shown inside the banner)",
        type: "editor",
        editable: editable,

        events: {},
        validation: "required",
        attributes: {
          className: "form-control",
          placeholder: "Type conference overview",
        },
      },

      participants: {
        label: "Participants",
        type: "tags",
        editable: editable,
        configuration: {
          type: "participants",
        },
        events: {},
        attributes: {
          className: "form-control",
        },
      },
      sponsors: {
        label: "Partners",
        type: "tags",
        editable: editable,
        configuration: {
          type: "sponsors",
        },
        events: {},
        attributes: {
          className: "form-control",
        },
      },
      banner: {
        label: "Banner",
        type: "upload",
        editable: editable,
        configuration: {
          url: "/agenda-file",
          type: "meeting",
          fileDataProperty: "file",
          allowedExtensions: ["png", "jpeg", "jpg"],
          multipleFiles: false,
        },
        caption: function (_this) {
          return (
            <div className="w-100 text-center p-4 ">
              <img src="/uni/svg/files/upload.svg" className="svg-sm mb-2" />
              <span className="d-block mb-1">
                <strong>
                  <span
                    className="color-purple"
                    role="button"
                    onClick={_this.onTargetClick}
                  >
                    Click to upload
                  </span>{" "}
                  or drag and drop
                </strong>
              </span>
              <span className="color-grey d-block">
                PDF, PNG, JPEG (max 1GB)
              </span>
            </div>
          );
        },
        events: {},
        attributes: {
          className: "form-control bg-light-grey",
        },
        formatValue: (value) => {
          return value ? [value] : [];
        },
      },
      optionsfortable: {
        label: "",
        type: "optionsfortable",
        configuration: {
          options: {
            open: function (args) {
              return {
                label: "Open",
                onClick: function () {
                  args.props.history.push(
                    "/event/conference/".concat(args.props.data._id)
                  );
                },
              };
            },
            edit: function (args) {
              if ($c.userIsHost() || $c.userIsAdmin()) {
                let newargs = {
                  props: { data: { ...args.props.data } },
                  action: "update",
                };

                return {
                  label: "Edit",
                  onClick: function () {
                    $f.getValue(Modals.events.editConference, newargs);
                  },
                };
              }
            },
            copyLink: function (args) {
              return {
                label: "Copy link",
                onClick: function () {
                  $c.copyToClipboard(
                    window["host"]("/conference/".concat(args.props.data._id))
                  );
                  $f.createNotification({
                    type: "success",
                    message: "Link copied!",
                  });
                },
              };
            },
            delete: function (args) {
              if ($c.userIsAdmin() || $c.userIsHost()) {
                return {
                  label: <span className="text-danger">Delete</span>,
                  onClick: function () {
                    $f.getValue(Modals.events.deleteConference, args);
                  },
                };
              }
            },
          },
        },
      },
      days: {
        label: <span className="d-block display-4-no-margin">Days</span>,
        type: "conferencedays",
        editable: editable,
        configuration: {},
        events: {},
        attributes: {},
      },
      category: {
        label: "Category",
        type: "text",
        editable: editable,
        events: {},
        attributes: {},
      },
      startDate: {
        label: "Starts on",
        type: "text",
        editable: editable,
        events: {},
        attributes: {},
        formatValue: (value, row) => {
          return $c.formatDateTime(row.startDate);
        },
      },
    };
  },
  specialProperties: () => {
    return {
      header: {
        type: "template",
        configuration: {
          title: "Conferences",
          subTitle: "View all available conferences",
          template: "Header",
          buttons: (args) => {
            const btns = [];

            if (
              $f.getQueryPath(3) == "partners" &&
              ($c.userIsHost() || $c.userIsAdmin())
            ) {
              btns.push(
                <button
                  type="button"
                  className="btn btn-purple  btn-sm  me-2"
                  onClick={() => {
                    $f.getValue(Modals.events.addPartner, {
                      title: "Add partner",
                    });
                  }}
                >
                  Add partner
                </button>
              );
            } else if ($c.userIsAdmin() || $c.userIsHost()) {
              btns.push(
                <button
                  type="button"
                  className="btn btn-purple  btn-sm  "
                  onClick={() => {
                    $f.getValue(Modals.events.editConference, {
                      title: "Schedule conference",
                      api: "new",
                      action: "create",
                    });
                  }}
                >
                  Schedule
                </button>
              );
            }
            return btns;
          },
        },
        events: {},
      },
      pagination: {
        type: "pagination",
        configuration: {
          start: function (props) {
            return 0;
          },
          offset: "25",
          total: function (props) {
            return props.props.data.pagination.total;
          },
          current: function (props) {
            return props.props.data.pagination.currentPage;
          },
          replaceAttribute: "start",
        },
        events: {},
      },
      headerOverview: {
        type: "template",
        configuration: {
          title: (args) => {
            return args.data.data[0].title;
          },
          buttons: (args) => {
            if ($f.getQueryPath(4) == "participants") {
              return [
                <button
                  type="button"
                  className="btn btn-purple  btn-sm  "
                  onClick={() => {
                    $f.getValue(Modals.participants.addParticipants, {
                      title: "Add people",
                      api: "new",
                      actionType: "stream",
                      actionId: args.data.data[0]._id,
                      actionSection: "streamParticipants",
                    });
                  }}
                >
                  Add people
                </button>,
              ];
            }
          },
          template: "Header",
        },
        events: {},
      },
      participants: {
        label: "",
        type: "module",
        configuration: {
          module: "ParticipantTable",
          view: "list",
        },
      },
      partners: {
        label: "",
        type: "module",
        configuration: {
          module: "ConferencesPartners",
          view: "list",
        },
      },
      polls: {
        label: "",
        type: "module",
        configuration: {
          module: "ConferencesPolls",
          view: "list",
        },
      },
      eventsNav: {
        type: "template",
        configuration: {
          data: (resp) => {
            let output = [];
            let data = [
              { name: "All conferences", filter: "" },
              // { name: "Upcoming", filter: "upcoming" },
              // { name: "Ongoing", filter: "ongoing" },
              // { name: "Ended", filter: "completed" },
            ];
            try {
              data.forEach((el, idx) => {
                let isActive = false;
                if ($f.getParameterByName("filter") == el.filter) {
                  isActive = true;
                } else {
                  if (
                    !$f.getParameterByName("filter") &&
                    $f.getQueryPath(3) != "partners"
                  ) {
                    if (idx == 0) {
                      isActive = true;
                    }
                  }
                }

                output.push({
                  url:
                    idx == 0
                      ? "/events/conferences"
                      : "/events/conferences?filter=" + el.filter,
                  title: el.name,
                  isActive: () => {
                    return isActive;
                  },
                });
              });

              if ($c.userIsHost() || $c.userIsAdmin()) {
                output.push({
                  url: "/events/conferences/partners",
                  title: "Partners",
                  isActive: () => {
                    if (
                      window.location.pathname == "/events/conferences/partners"
                    ) {
                      return true;
                    }
                    return false;
                  },
                });
              }
            } catch (e) {}
            return output;
          },
          template: "HorizontalNavScroll",
        },
      },
    };
  },
  views: {
    list: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/streams?start={start}&offset={offset}{filters}";
          },
          dataPath: () => {},
          replace: {
            start: 0,
            offset: 25,
            filters: function () {
              switch ($f.getParameterByName("filter")) {
                case "completed":
                  return (
                    "&filters=" +
                    JSON.stringify([
                      { key: "to", operator: "lt", value: new Date() },
                    ])
                  );
                  break;

                default:
                  return "";
                  break;

                case "ongoing":
                  return (
                    "&filters=" +
                    JSON.stringify([
                      { operator: "is", key: "event", value: "today" },
                    ])
                  );
                  break;

                case "upcoming":
                  return (
                    "&filters=" +
                    JSON.stringify([
                      { key: "to", operator: "gt", value: new Date() },
                    ])
                  );
                  break;
              }
            },
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          if ($f.getQueryPath(3) == "partners") {
            return "Custom";
          }

          return "Table";
        },

        configuration: {
          emptyTemplate: function (arg) {
            return EmptyStates.events.conferences.default();
          },
          headerTemplate: function () {
            return (
              <>
                {this.state.specialProperties.header.component}
                {this.state.specialProperties.eventsNav.component}
              </>
            );
          },
          bottomTemplate: function () {
            switch ($f.getQueryPath(3)) {
              case "partners":
                return this.state.specialProperties.partners.component;
            }

            return this.state.specialProperties.pagination.component;
          },
          tableConfiguration: {
            headers: {
              title: {
                attributes: {
                  className: "w-50",
                },
              },
            },
            cellTemplates: {
              title: (comp, itm) => {
                return (
                  <Link
                    to={"/event/conference/".concat(itm._id.value)}
                    className="color-grey"
                  >
                    {comp}
                  </Link>
                );
              },
            },
          },
          includeProperties: () => {
            return ["title", "optionsfortable", "startDate"];
          },
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
    detail: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/stream/{id}";
          },
          dataPath: () => {},
          replace: {
            id: () => {
              return $f.getQueryPath(3);
            },
          },
          headers: {},
          mapper: () => {},
        },
        new: {
          url: () => {
            return {
              data: [
                {
                  days: [
                    {
                      date: moment(),
                      sessions: [
                        {
                          from: moment()
                            .set("hour", 10)
                            .set("minute", 0)
                            .set("second", 0)
                            .format(),
                          to: moment()
                            .set("hour", 18)
                            .set("minute", 0)
                            .set("second", 0)
                            .format(),
                        },
                      ],
                    },
                  ],
                },
              ],
            };
          },
          dataPath: () => {},
          replace: {
            id: () => {
              return $f.getQueryPath(3);
            },
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Form";
        },
        configuration: {
          includeProperties: (args) => {
            if (args.data[0]._id) {
              return [
                "_id",
                "title",
                "description",
                "eventType",
                "shortDescription",
                "hosts",

                "sponsors",

                "banner",
                "days",
              ];
            } else {
              return [
                "title",
                "description",
                "eventType",
                "shortDescription",
                "hosts",
                "participants",
                "sponsors",

                "banner",
                "days",
              ];
            }
          },
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: true };
        },
      },
    },
    overview: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            switch ($f.getQueryPath(4)) {
              case "settings":
                return "/stream/{id}/settings";
            }

            return "/stream/{id}";
          },
          dataPath: () => {},
          replace: {
            id: () => {
              return $f.getQueryPath(3);
            },
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          switch ($f.getQueryPath(4)) {
            case "participants":
              return "Custom";

            case "polls":
              return "ConferencePollResults";

            case "settings":
              return "ConferenceSettings";

            case "speakers":
              return "ConferenceSpeakers";
            case "partners":
              return "ConferencePartners";

            case "live":
              return "ConferenceLiveOverview";
          }

          return "ConferenceOverview";
        },

        configuration: {
          topTemplate: function () {
            switch ($f.getQueryPath(4)) {
              case "participants":
                return this.state.specialProperties.participants.component;
              case "polls":
                return this.state.specialProperties.polls.component;
              case "partners":
                return this.state.specialProperties.partners.component;
            }

            return "";
          },
          headerTemplate: function () {
            switch ($f.getQueryPath(4)) {
              case "participants":
                return this.state.specialProperties.headerOverview.component;
              case "settings":
                return this.state.specialProperties.headerOverview.component;
              case "speakers":
                return this.state.specialProperties.headerOverview.component;
              case "partners":
                return this.state.specialProperties.headerOverview.component;
              case "polls":
                return this.state.specialProperties.headerOverview.component;
              case "live":
                return this.state.specialProperties.headerOverview.component;
            }

            return "";
          },
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
    public: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/stream/{id}";
          },
          dataPath: () => {},
          replace: {
            id: () => {
              return $f.getQueryPath(2);
            },
          },
          headers: {
            Authorization: "Bearer public",
          },
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "ConferencePublicOverview";
        },

        configuration: {
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
    live: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            if (!$c.isLoggedIn()) {
              $c.logout(window.location.href);
            }

            return "/stream/{id}";
          },
          dataPath: () => {},
          replace: {
            id: () => {
              return $f.getQueryPath(3);
            },
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "ConferenceLive";
        },

        configuration: {
          headerTemplate: function () {
            return "";
          },
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
    ondemand: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            if (!$c.isLoggedIn()) {
              $c.logout(window.location.href);
            }

            return "/stream/{id}";
          },
          dataPath: () => {},
          replace: {
            id: () => {
              return $f.getQueryPath(3);
            },
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "ConferenceOnDemand";
        },

        configuration: {
          headerTemplate: function () {
            return "";
          },
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
  },
};

export default Conferences;
