import { Router } from "react-router";
import { createBrowserHistory } from "history";
import AppRouter from "./themes/hapeiron/AppRouter";

const newHistory = createBrowserHistory();

function App() {
  return (
    <Router history={newHistory}>
      <AppRouter></AppRouter>
    </Router>
  );
}

export default App;
