import { Component } from "react";
import $f from "../../src";
import ReactQuill from "react-quill";
import "quill/dist/quill.snow.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { DateTimePicker, Localization } from "react-widgets";
import { DateLocalizer, NumberLocalizer } from "react-widgets/IntlLocalizer";
import "react-widgets/styles.css";
import moment from "moment";
import Upload from "../upload/upload";
import Duration from "../duration/duration";
import $c from "../../modules/functions";
import SimpleReactValidator from "simple-react-validator";
import Tags from "../tags/tags";
import {
  arrayMove,
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import Modals from "../../modules/modals";
import FromTotime from "../fromtotime/fromtotime";

const SortableItem = sortableElement(({ value }) => (
  <li style={{ listStyle: "none" }}>{value}</li>
));

const DragHandle = sortableHandle(() => (
  <span className="sortableHandler me-2">
    <i className="fas fa-ellipsis-v"></i>
    <i className="fas fa-ellipsis-v"></i>
  </span>
));

const SortableContainer = sortableContainer(({ children }) => {
  return (
    <ul className="nav nav-tabs navbar-expand" style={{ listStyle: "none" }}>
      {children}
    </ul>
  );
});

class ConferenceDays extends Component {
  constructor(props) {
    super(props);

    this.deleteModule = this.deleteModule.bind(this);
    this.addModule = this.addModule.bind(this);

    this.deleteSession = this.deleteSession.bind(this);
    this.addSession = this.addSession.bind(this);

    this.editRender = this.editRender.bind(this);
    this.editSessions = this.editSessions.bind(this);

    this.setActiveTab = this.setActiveTab.bind(this);
    this.onModuleChange = this.onModuleChange.bind(this);
    this.onSessionChange = this.onSessionChange.bind(this);

    this.onReorderModules = this.onReorderModules.bind(this);
    this.onReorderSessions = this.onReorderSessions.bind(this);

    this.updateInstructors = this.updateInstructors.bind(this);

    this.validate = this.validate.bind(this);

    this.validator = new SimpleReactValidator({
      validators: {
        compareDates: {
          message: "Starting time should not be after end time",
          rule: (val, params, validator) => {
            return moment(val.from).isBefore(val.to);
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)),
        },
      },
    });

    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    let events = [];

    Object.keys(this.props.property.events)?.forEach((element, idx) => {
      if (!element.toLowerCase().startsWith("onchange")) {
        this[element] = this.props.property.events[element];
        this[element] = this[element].bind(this);
        events[element] = this[element];
      } else {
        this["customOnChange"] = { ...this.props.property.events[element] };
        this["customOnChange"] = this["customOnChange"].bind(this);
      }
    });

    let configuration = this.props.property.configuration
      ? { ...this.props.property.configuration }
      : {};

    configuration.canAdd = configuration.hasOwnProperty("canAdd")
      ? configuration.hasOwnProperty("canAdd")
      : true;
    configuration.canRemove = configuration.hasOwnProperty("canRemove")
      ? configuration.hasOwnProperty("canRemove")
      : true;

    this.setState({
      loaded: true,
      events: events,
      attributes: { ...this.props.property.attributes },
      value: this.props.value ? this.props.value : [],
      activeTab: 0,
      activeSessionTab: 0,
      editable: this.props.property.editable === false ? false : true,
      configuration: configuration,
    });
  }

  onReorderModules(args) {
    let value = [...this.state.value];

    const modules = arrayMove(value, args.oldIndex, args.newIndex);

    this.setState({
      value: modules,
    });
  }

  validate() {
    const isValid = this.validator.allValid();

    this.setState({ isValid });
    this.validator.showMessages();
    this.forceUpdate();

    return isValid;
  }

  updateInstructors(moduleIdx, sessionIdx, instructors) {
    let value = [...this.state.value];

    value[moduleIdx]["sessions"][sessionIdx]["instructors"] = instructors
      ? instructors
      : [];

    this.setState({
      value: value,
    });
  }

  onReorderSessions(args, moduleIdx) {
    let value = [...this.state.value];
    const sessions = arrayMove(
      value[moduleIdx].sessions,
      args.oldIndex,
      args.newIndex
    );

    value[moduleIdx].sessions = sessions;

    this.setState({
      value: value,
    });
  }

  setActiveTab(moduleIdx) {
    this.setState({ activeTab: moduleIdx, activeSessionTab: 0 });
  }

  setActiveSessionTab(sessionIdx) {
    this.setState({ activeSessionTab: sessionIdx });
  }

  addModule() {
    this.setState({
      value: [
        ...this.state.value,
        {
          date: moment(),
          sessions: [
            {
              from: moment()
                .set("hour", 10)
                .set("minute", 0)
                .set("second", 0)
                .format(),
              to: moment()
                .set("hour", 18)
                .set("minute", 0)
                .set("second", 0)
                .format(),
            },
          ],
        },
      ],
      activeTab: this.state.value.length,
      activeSessionTab: 0,
    });
  }

  deleteModule(index) {
    let value = [...this.state.value];

    let _this = this;

    const Body = (
      <p>
        Are you sure you want to delete <strong>"Day {index + 1}"</strong>?
      </p>
    );

    window["secondaryModal"].setState({
      show: true,
      size: "default",
      title: (
        <h2 className="display-3-no-line-height header-ellipsis">Delete day</h2>
      ),
      footer: [
        <button
          type="button"
          onClick={() => {
            window["secondaryModal"].close();
          }}
          className="btn btn-sm btn-white"
        >
          Cancel
        </button>,
        <button
          type="button"
          onClick={() => {
            value.splice(index, 1);
            _this.setState({ value: value, activeTab: 0, activeSessionTab: 0 });
            _this.validator.purgeFields();
            window["secondaryModal"].close();
          }}
          className="btn btn-sm btn-danger"
        >
          Delete
        </button>,
      ],
      body: Body,
    });
  }

  addSession(moduleIdx) {
    let value = [...this.state.value];
    value[moduleIdx]["sessions"].push({
      from: moment().set("hour", 10).set("minute", 0).set("second", 0).format(),
      to: moment().set("hour", 18).set("minute", 0).set("second", 0).format(),
    });
    this.setState({ value: value });
  }

  deleteSession(moduleIdx, sessionIdx) {
    let value = [...this.state.value];
    let _this = this;

    const Body = (
      <p>
        Are you sure you want to delete session{" "}
        <strong>
          "
          {value[moduleIdx]["sessions"][sessionIdx]["title"]
            ? value[moduleIdx]["sessions"][sessionIdx]["title"]
            : sessionIdx + 1}
          "
        </strong>
        ?
      </p>
    );

    window["secondaryModal"].setState({
      show: true,
      size: "default",
      title: (
        <h2 className="display-3-no-line-height header-ellipsis">
          Delete session
        </h2>
      ),
      footer: [
        <button
          type="button"
          onClick={() => {
            window["secondaryModal"].close();
          }}
          className="btn btn-sm btn-white"
        >
          Cancel
        </button>,
        <button
          type="button"
          onClick={() => {
            value[moduleIdx]["sessions"].splice(sessionIdx, 1);

            _this.setState({ value: value, activeSessionTab: 0 });
            _this.validator.purgeFields();
            window["secondaryModal"].close();
          }}
          className="btn btn-sm btn-danger"
        >
          Delete
        </button>,
      ],
      body: Body,
    });
  }

  onModuleChange(midx, property, val) {
    let value = [...this.state.value];
    value[midx][property] = val;
    this.setState({ value: value });
  }

  onModuleDateChange(midx, property, val) {
    let value = [...this.state.value];
    value[midx][property] = val;

    value[midx].sessions?.forEach((el) => {
      el["from"] = moment(val)
        .set({
          hour: moment(el["from"]).get("hour"),
          minute: moment(el["from"]).get("minute"),
          second: 0,
        })
        .format();

      el["to"] = moment(val)
        .set({
          hour: moment(el["to"]).get("hour"),
          minute: moment(el["to"]).get("minute"),
          second: 0,
        })
        .format();
    });

    this.setState({ value: value });
  }

  onSessionChange(midx, sidx, property, val) {
    let value = [...this.state.value];
    value[midx]["sessions"][sidx][property] = val;
    this.setState({ value: value });
  }

  onSessionTimeChange(midx, sidx, val) {
    let value = [...this.state.value];
    value[midx]["sessions"][sidx]["from"] = moment.utc(val.from).format();
    value[midx]["sessions"][sidx]["to"] = moment.utc(val.to).format();

    this.setState({ value: value });
  }

  editSessions(sessions, moduleIdx, module) {
    const tabContent = [];
    const tabs = [];
    let _this = this;

    const toolbarOptions = [
      ["bold", "italic", "underline", "strike", "link"],
      [{ list: "ordered" }, { list: "bullet" }],
    ];

    sessions?.forEach((row, idx) => {
      const tabId = "a".concat($f.key());

      let active = "";

      if (idx == this.state.activeSessionTab) {
        active = "active show";
      }

      tabs.push(
        <SortableItem
          key={`session-${moduleIdx}-${idx}`}
          index={idx}
          value={
            <li className={"nav-item "} key={$f.key()}>
              <span
                className={"nav-link color-strong-grey nav-ellipsis ".concat(
                  active
                )}
                data-bs-toggle="tab"
                data-bs-target={"#".concat(tabId)}
                role="button"
                onClick={() => {
                  this.setActiveSessionTab(idx);
                }}
              >
                <DragHandle></DragHandle>
                Session {idx + 1}
                {this.state.configuration.canRemove && sessions.length > 1 ? (
                  <i
                    className="fas fa-times ms-2"
                    onClick={() => {
                      this.deleteSession(moduleIdx, idx);
                    }}
                  ></i>
                ) : (
                  ""
                )}
              </span>
            </li>
          }
        ></SortableItem>
      );

      tabContent.push(
        <div
          className={"tab-pane fade ".concat(active)}
          id={tabId}
          role="tabpanel"
          aria-labelledby="profile-tab"
        >
          <div class="form-group mb-3">
            <label className="label-top">Select time slot</label>

            <FromTotime
              key={$f.key()}
              value={{
                from: moment(module.date)
                  .set("hour", moment(row.from).hours())
                  .set("minute", moment(row.from).minutes())
                  .set("second", 0)
                  .format(),
                to: moment(module.date)
                  .set("hour", moment(row.to).hours())
                  .set("minute", moment(row.to).minutes())
                  .set("second", 0)
                  .format(),
              }}
              property={{
                label: "time slot",
                events: {
                  overrideOnChange: (e) => {
                    _this.onSessionTimeChange(moduleIdx, idx, e.state.value);
                  },
                },
                attributes: { className: "form-control" },
              }}
            />
            <span className="text-danger mt-2 d-block">
              {this.validator.message(
                "Slot time of session "
                  .concat(idx + 1)
                  .concat(" of day" + (moduleIdx + 1)),
                { from: row.from, to: row.to },
                "compareDates"
              )}
            </span>
          </div>
          <div class="form-group mb-3">
            <label className="label-top">Title</label>
            <input
              type="text"
              value={row.title}
              className="form-control"
              onChange={(e) => {
                this.onSessionChange(moduleIdx, idx, "title", e.target.value);
              }}
              placeholder="Type session title"
            ></input>
            <span className="text-danger mt-2 d-block">
              {this.validator.message(
                "title of session "
                  .concat(idx + 1)
                  .concat(" of day" + (moduleIdx + 1)),
                row.title,
                "required"
              )}
            </span>
          </div>
          <div class="form-group mb-3">
            <label className="label-top">About the session</label>
            <div className="form-control p-0 pb-5">
              <ReactQuill
                theme="snow"
                value={row.description}
                style={{ height: "60px" }}
                modules={{
                  toolbar: toolbarOptions,
                }}
                onChange={(content) => {
                  this.onSessionChange(
                    moduleIdx,
                    idx,
                    "description",
                    content == "<p><br></p>" ? "" : content
                  );
                }}
                placeholder="Type session about"
              ></ReactQuill>
            </div>
            <span className="text-danger mt-2 d-block">
              {this.validator.message(
                "description of session "
                  .concat(idx + 1)
                  .concat(" of day" + (moduleIdx + 1)),
                row.description,
                "required"
              )}
            </span>
          </div>
          <div class="form-group mb-3">
            <label className="label-top">Select speakers</label>
            <Tags
              key={$f.key()}
              value={row.speakers}
              property={{
                configuration: {
                  type: "instructors",
                },
                events: {},
                attributes: {
                  className: "form-control",
                },
                parentChange: function (e) {
                  _this.onSessionChange(moduleIdx, idx, "speakers", e);
                },
              }}
            />
          </div>
        </div>
      );
    });

    if (this.state.configuration.canAdd) {
      tabs.push(
        <li className={"nav-item "} key={$f.key()}>
          <span
            className="nav-link color-strong-grey nav-ellipsis "
            role="button"
            onClick={() => {
              this.addSession(moduleIdx);
            }}
          >
            <span className="color-black">Add Session</span>
          </span>
        </li>
      );
    }

    return (
      <div className="mb-3">
        <span className="d-block display-4-no-margin mb-3 label-top">
          Sessions
        </span>
        <PerfectScrollbar>
          <ul className="nav nav-tabs navbar-expand">
            <SortableContainer
              onSortEnd={(args) => {
                this.onReorderSessions(args, moduleIdx);
              }}
              pressDelay={50}
              helperClass="sortableHelper"
              useDragHandle
              axis="x"
            >
              {tabs}
            </SortableContainer>
          </ul>
        </PerfectScrollbar>
        <div className="tab-content mt-3 ">{tabContent}</div>
      </div>
    );
  }

  editRender() {
    const output = [];

    const tabContent = [];
    const tabs = [];

    const toolbarOptions = [
      ["bold", "italic", "underline", "strike", "link"],
      [{ list: "ordered" }, { list: "bullet" }],
    ];

    this.state.value?.forEach((row, idx) => {
      let rowControls = [];

      const tabId = "a".concat($f.key());

      let active = "";

      if (idx == this.state.activeTab) {
        active = "active show";
      }

      tabs.push(
        <SortableItem
          key={`item-${idx}`}
          index={idx}
          value={
            <li className={"nav-item "}>
              <span
                className={"nav-link color-strong-grey nav-ellipsis ".concat(
                  active
                )}
                data-bs-toggle="tab"
                data-bs-target={"#".concat(tabId)}
                role="button"
              >
                <span
                  onClick={() => {
                    this.setActiveTab(idx);
                  }}
                >
                  <DragHandle></DragHandle>
                  Day {idx + 1}
                </span>
                {this.state.configuration.canRemove &&
                this.state.value.length > 1 ? (
                  <i
                    className="fas fa-times ms-2"
                    onClick={() => {
                      this.deleteModule(idx);
                    }}
                  ></i>
                ) : (
                  ""
                )}
              </span>
            </li>
          }
        />
      );

      tabContent.push(
        <div
          className={"tab-pane fade ".concat(active)}
          id={tabId}
          role="tabpanel"
          aria-labelledby="profile-tab"
          // key={"moduletab".concat(idx)}
        >
          <div class="form-group mb-3">
            <label className="label-top">Select Date</label>
            <Localization
              date={new DateLocalizer({ culture: "en-GB", firstOfWeek: 0 })}
            >
              <DateTimePicker
                step={this.state.step}
                className={"w-25  ps-0 rounded"}
                value={moment(row.date).toDate()}
                onChange={(e) => {
                  this.onModuleDateChange(idx, "date", e);
                }}
              />
            </Localization>
            {/* <input
              type="text"
              value={row.title}
              className="form-control"
              onChange={(e) => {
                this.onModuleChange(idx, "title", e.target.value);
              }}
            ></input>
            <span className="text-danger mt-2 d-block">
              {this.validator.message(
                "title of module ".concat(idx + 1),
                row.title,
                "required"
              )}
            </span> */}
          </div>

          {this.editSessions(row.sessions, idx, row)}
        </div>
      );
    });

    if (this.state.configuration.canAdd) {
      tabs.push(
        <li className={"nav-item "} key={$f.key()}>
          <span
            className="nav-link color-strong-grey nav-ellipsis "
            role="button"
            onClick={this.addModule}
          >
            <span className="color-black">Add day</span>
          </span>
        </li>
      );
    }

    return (
      <div className="mb-3">
        <PerfectScrollbar>
          <ul className="nav nav-tabs navbar-expand">
            <SortableContainer
              onSortEnd={this.onReorderModules}
              pressDelay={50}
              helperClass="sortableHelper"
              axis="x"
              useDragHandle
            >
              {tabs}
            </SortableContainer>
          </ul>
        </PerfectScrollbar>
        <div className="tab-content mt-3 ">{tabContent}</div>
      </div>
    );
  }

  render() {
    if (this.state.loaded) {
      return this.editRender();
    }

    return "";
  }
}

export default ConferenceDays;
