import { Component } from "react";
import $f from "../../src";
import ReactQuill from "react-quill";
import "quill/dist/quill.snow.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import FromToDatetime from "../fromtodatetime/fromtodatetime";
import Reminder from "../reminder/reminder";
import Upload from "../upload/upload";
import $c from "../../modules/functions";
import SimpleReactValidator from "simple-react-validator";
import Tags from "../tags/tags";
import {
  arrayMove,
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import Modals from "../../modules/modals";
import moment from "moment";
import ReadingDuration from "../readingDuration/readingDuration";
import Duration from "../duration/duration";

const SortableItem = sortableElement(({ value }) => (
  <li style={{ listStyle: "none" }}>{value}</li>
));

const DragHandle = sortableHandle(() => (
  <span className="sortableHandler me-2">
    <i className="fas fa-ellipsis-v"></i>
    <i className="fas fa-ellipsis-v"></i>
  </span>
));

const SortableContainer = sortableContainer(({ children }) => {
  return (
    <ul className="nav nav-tabs navbar-expand" style={{ listStyle: "none" }}>
      {children}
    </ul>
  );
});

class TrainingModules extends Component {
  constructor(props) {
    super(props);

    this.deleteModule = this.deleteModule.bind(this);
    this.addModule = this.addModule.bind(this);

    this.deleteSession = this.deleteSession.bind(this);
    this.addSession = this.addSession.bind(this);

    this.editRender = this.editRender.bind(this);
    this.editSessions = this.editSessions.bind(this);

    this.onVideoConferenceChange = this.onVideoConferenceChange.bind(this);
    this.addVideoClass = this.addVideoClass.bind(this);
    this.deleteVideoClass = this.deleteVideoClass.bind(this);

    this.setActiveTab = this.setActiveTab.bind(this);
    this.onModuleChange = this.onModuleChange.bind(this);
    this.onSessionChange = this.onSessionChange.bind(this);

    this.editUpload = this.editUpload.bind(this);

    this.onMaterialChange = this.onMaterialChange.bind(this);
    this.addMaterial = this.addMaterial.bind(this);
    this.deleteMaterial = this.deleteMaterial.bind(this);

    this.onReorderModules = this.onReorderModules.bind(this);
    this.onReorderSessions = this.onReorderSessions.bind(this);

    this.addTest = this.addTest.bind(this);
    this.updateTest = this.updateTest.bind(this);
    this.updateInstructors = this.updateInstructors.bind(this);
    this.updateTestAvailability = this.updateTestAvailability.bind(this);
    this.updateTestDuration = this.updateTestDuration.bind(this);
    this.updateTestReminder = this.updateTestReminder.bind(this);

    this.validate = this.validate.bind(this);

    this.validator = new SimpleReactValidator({
      validators: {
        compareDuration: {
          message: "Duration cannot be 0",
          rule: (val, params, validator) => {
            if (
              val?.hours.toString() == "0" &&
              val?.minutes.toString() == "0"
            ) {
              return false;
            }
            return true;
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)),
        },
        liveClasses: {
          message: "Starting date should not be after end date",
          rule: (val, params, validator) => {
            return moment(val.from).isBefore(val.to);
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)),
        },
        materialValidation: {
          message: "Material validation",
          rule: (val, params, validator) => {
            return val;
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)),
        },
      },
    });

    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    let events = [];

    Object.keys(this.props.property.events)?.forEach((element, idx) => {
      if (!element.toLowerCase().startsWith("onchange")) {
        this[element] = this.props.property.events[element];
        this[element] = this[element].bind(this);
        events[element] = this[element];
      } else {
        this["customOnChange"] = { ...this.props.property.events[element] };
        this["customOnChange"] = this["customOnChange"].bind(this);
      }
    });

    this.setState({
      loaded: true,
      events: events,
      attributes: { ...this.props.property.attributes },
      value: this.props.value ? this.props.value : [],
      activeTab: 0,
      activeSessionTab: 0,
      editable: this.props.property.editable === false ? false : true,
      configuration: { ...this.props.property.configuration },
    });
  }

  onReorderModules(args) {
    let value = [...this.state.value];

    const modules = arrayMove(value, args.oldIndex, args.newIndex);

    this.setState({
      value: modules,
    });
  }

  validate() {
    const isValid = this.validator.allValid();

    this.setState({ isValid });
    this.validator.showMessages();
    this.forceUpdate();

    return isValid;
  }

  updateTest(moduleIdx, sessionIdx, examIdx, exam) {
    let value = [...this.state.value];

    value[moduleIdx]["sessions"][sessionIdx]["exam"][examIdx] = exam;

    this.setState({
      value: value,
    });
  }

  updateTestAvailability(moduleIdx, sessionIdx, examIdx, availability) {
    let value = [...this.state.value];

    value[moduleIdx]["sessions"][sessionIdx]["exam"][examIdx]["from"] =
      availability.from;
    value[moduleIdx]["sessions"][sessionIdx]["exam"][examIdx]["to"] =
      availability.to;

    this.setState({
      value: value,
    });
  }

  updateTestReminder(moduleIdx, sessionIdx, examIdx, reminder) {
    let value = [...this.state.value];

    value[moduleIdx]["sessions"][sessionIdx]["exam"][examIdx]["reminders"] =
      reminder;

    this.setState({
      value: value,
    });
  }

  updateTestDuration(moduleIdx, sessionIdx, examIdx, duration) {
    let value = [...this.state.value];

    value[moduleIdx]["sessions"][sessionIdx]["exam"][examIdx]["examDuration"] =
      duration;

    this.setState({
      value: value,
    });
  }

  updateInstructors(moduleIdx, sessionIdx, instructors) {
    let value = [...this.state.value];

    value[moduleIdx]["sessions"][sessionIdx]["instructors"] = instructors
      ? instructors
      : [];

    this.setState({
      value: value,
    });
  }

  addTest(moduleIdx, sessionIdx, examIdx, exam) {
    let value = [...this.state.value];

    if (!value[moduleIdx]["sessions"][sessionIdx]["exam"]) {
      value[moduleIdx]["sessions"][sessionIdx]["exam"] = [];
    }

    exam.from = moment()
      .add(30 - (moment().minute() % 30), "minutes")
      .format("YYYY-MM-DDTHH:mm:ss.000Z");
    exam.to = moment()
      .add(30 - (moment().minute() % 30), "minutes")
      .add(1, "hour")
      .format("YYYY-MM-DDTHH:mm:ss.000Z");

    exam.examDuration = { hours: 1, minutes: 0 };

    value[moduleIdx]["sessions"][sessionIdx]["exam"].push(exam);

    this.setState({
      value: value,
    });
  }

  deleteTest(moduleIdx, sessionIdx, examId) {
    let value = [...this.state.value];
    value[moduleIdx]["sessions"][sessionIdx]["exam"].splice(examId, 1);
    this.setState({ value: value });
  }

  onReorderSessions(args, moduleIdx) {
    let value = [...this.state.value];
    const sessions = arrayMove(
      value[moduleIdx].sessions,
      args.oldIndex,
      args.newIndex
    );

    value[moduleIdx].sessions = sessions;

    this.setState({
      value: value,
    });
  }

  setActiveTab(moduleIdx) {
    this.setState({ activeTab: moduleIdx, activeSessionTab: 0 });
  }

  setActiveSessionTab(sessionIdx) {
    this.setState({ activeSessionTab: sessionIdx });
  }

  addModule() {
    this.setState({
      value: [
        ...this.state.value,
        {
          sessions: [
            {
              videoConference: [],
              exam: [],
              reading: [],
              videoPresentation: [],
            },
          ],
        },
      ],
      activeTab: this.state.value.length,
      activeSessionTab: 0,
    });
  }

  deleteModule(index) {
    let value = [...this.state.value];

    let _this = this;

    const Body = (
      <p>
        Are you sure you want to delete module{" "}
        <strong>
          "{value[index]["title"] ? value[index]["title"] : index + 1}"
        </strong>
        ?
      </p>
    );

    window["secondaryModal"].setState({
      show: true,
      size: "default",
      title: (
        <h2 className="display-3-no-line-height header-ellipsis">
          Delete module
        </h2>
      ),
      footer: [
        <button
          type="button"
          onClick={() => {
            window["secondaryModal"].close();
          }}
          className="btn btn-sm btn-white"
        >
          Cancel
        </button>,
        <button
          type="button"
          onClick={() => {
            value.splice(index, 1);
            _this.setState({ value: value, activeTab: 0, activeSessionTab: 0 });
            _this.validator.purgeFields();
            window["secondaryModal"].close();
          }}
          className="btn btn-sm btn-danger"
        >
          Delete
        </button>,
      ],
      body: Body,
    });
  }

  addSession(moduleIdx) {
    let value = [...this.state.value];
    value[moduleIdx]["sessions"].push({
      videoConference: [],
      exam: [],
      reading: [],
      videoPresentation: [],
    });
    this.setState({ value: value });
  }

  deleteSession(moduleIdx, sessionIdx) {
    let value = [...this.state.value];
    let _this = this;

    const Body = (
      <p>
        Are you sure you want to delete session{" "}
        <strong>
          "
          {value[moduleIdx]["sessions"][sessionIdx]["title"]
            ? value[moduleIdx]["sessions"][sessionIdx]["title"]
            : sessionIdx + 1}
          "
        </strong>
        ?
      </p>
    );

    window["secondaryModal"].setState({
      show: true,
      size: "default",
      title: (
        <h2 className="display-3-no-line-height header-ellipsis">
          Delete session
        </h2>
      ),
      footer: [
        <button
          type="button"
          onClick={() => {
            window["secondaryModal"].close();
          }}
          className="btn btn-sm btn-white"
        >
          Cancel
        </button>,
        <button
          type="button"
          onClick={() => {
            value[moduleIdx]["sessions"].splice(sessionIdx, 1);
            _this.setState({ value: value, activeSessionTab: 0 });
            _this.validator.purgeFields();

            window["secondaryModal"].close();
          }}
          className="btn btn-sm btn-danger"
        >
          Delete
        </button>,
      ],
      body: Body,
    });
  }

  onModuleChange(midx, property, val) {
    let value = [...this.state.value];
    value[midx][property] = val;
    this.setState({ value: value });
  }

  onSessionChange(midx, sidx, property, val) {
    let value = [...this.state.value];
    value[midx]["sessions"][sidx][property] = val;
    this.setState({ value: value });
  }

  onVideoConferenceChange(midx, sidx, vidx, property, val) {
    let value = [...this.state.value];

    if (property == "availability") {
      value[midx]["sessions"][sidx]["videoConference"][vidx]["from"] = val.from;
      value[midx]["sessions"][sidx]["videoConference"][vidx]["to"] = val.to;
    } else {
      value[midx]["sessions"][sidx]["videoConference"][vidx][property] = val;
    }

    this.setState({ value: value });
  }

  addVideoClass(midx, sidx) {
    let value = [...this.state.value];
    if (!value[midx]["sessions"][sidx]["videoConference"]) {
      value[midx]["sessions"][sidx]["videoConference"] = [];
    }

    value[midx]["sessions"][sidx]["videoConference"].push({
      from: moment().add(30 - (moment().minute() % 30), "minutes"),
      to: moment()
        .add(30 - (moment().minute() % 30), "minutes")
        .add(1, "hour"),
    });
    this.setState({ value: value });
  }

  deleteVideoClass(midx, sidx, vidx) {
    let value = [...this.state.value];
    value[midx]["sessions"][sidx]["videoConference"].splice(vidx, 1);
    this.setState({ value: value });
  }

  onMaterialChange(midx, sidx, vidx, property, materialType, val) {
    let value = [...this.state.value];
    value[midx]["sessions"][sidx][materialType][vidx][property] = val;
    this.setState({ value: value });
  }

  addMaterial(midx, sidx, materialType, val) {
    let value = [...this.state.value];
    if (!value[midx]["sessions"][sidx][materialType]) {
      value[midx]["sessions"][sidx][materialType] = [];
    }
    value[midx]["sessions"][sidx][materialType].push(val);
    this.setState({ value: value });
  }

  deleteMaterial(midx, sidx, vidx, materialType) {
    let value = [...this.state.value];
    let _this = this;

    const Body = (
      <p>
        Are you sure you want to delete{" "}
        <strong>
          "{value[midx]["sessions"][sidx][materialType][vidx]["title"]}"
        </strong>
        ?
      </p>
    );

    window["secondaryModal"].setState({
      show: true,
      size: "default",
      title: (
        <h2 className="display-3-no-line-height header-ellipsis">
          Delete material
        </h2>
      ),
      footer: [
        <button
          type="button"
          onClick={() => {
            window["secondaryModal"].close();
          }}
          className="btn btn-sm btn-white"
        >
          Cancel
        </button>,
        <button
          type="button"
          onClick={() => {
            value[midx]["sessions"][sidx][materialType].splice(vidx, 1);
            _this.setState({ value: value });
            _this.validator.purgeFields();
            window["secondaryModal"].close();
          }}
          className="btn btn-sm btn-danger"
        >
          Delete
        </button>,
      ],
      body: Body,
    });
  }

  editUpload(value, moduleIndex, sessionIndex) {
    let title =
      value.mimetype == "video/mp4" ? "Rename video presentation" : "Edit pdf";

    if (value.recording) {
      title = "Rename recording";
    }

    let _this = this;

    let setChangeTitle = value.title;
    let setDuration = value.duration ? value.duration : null;

    let Body = (
      <div className="form-group mb-3">
        <label className="label-top">Name</label>
        <input
          type="text"
          className="form-control"
          defaultValue={value.title}
          onChange={(e) => {
            setChangeTitle = e.target.value;
          }}
        />
      </div>
    );

    if (value.mimetype == "application/pdf") {
      Body = (
        <>
          <div className="form-group mb-3">
            <label className="label-top">Title</label>
            <input
              type="text"
              className="form-control"
              placeholder="Type title"
              defaultValue={value.title}
              onChange={(e) => {
                setChangeTitle = e.target.value;
              }}
            />
          </div>
          <div className="form-group mb-3">
            <label className="label-top">Reading time</label>
            <ReadingDuration
              value={value.duration}
              property={{
                type: "upload",
                editable: true,
                events: {},
                attributes: {},
                parentChange: (e) => {
                  setDuration = e;
                },
              }}
            ></ReadingDuration>
          </div>
        </>
      );
    }

    window["secondaryModal"].setState({
      show: true,
      size: "default",
      title: (
        <h2 className="display-3-no-line-height header-ellipsis">{title}</h2>
      ),
      footer: [
        <button
          type="button"
          onClick={() => {
            window["secondaryModal"].close();
          }}
          className="btn btn-sm btn-white"
        >
          Cancel
        </button>,
        <button
          type="button"
          onClick={() => {
            switch (value.mimetype) {
              case "video/mp4":
                _this.onMaterialChange(
                  moduleIndex,
                  sessionIndex,
                  value.vidx,
                  "title",
                  "videoPresentation",
                  setChangeTitle
                );
                break;

              case "application/pdf":
                _this.onMaterialChange(
                  moduleIndex,
                  sessionIndex,
                  value.vidx,
                  "title",
                  "reading",
                  setChangeTitle
                );
                _this.onMaterialChange(
                  moduleIndex,
                  sessionIndex,
                  value.vidx,
                  "duration",
                  "reading",
                  setDuration
                );
                _this.onMaterialChange(
                  moduleIndex,
                  sessionIndex,
                  value.vidx,
                  "subTitle",
                  "reading",
                  "Reading time: ".concat(
                    $c.readingDurationFromObjectToText(setDuration)
                  )
                );

                break;
            }
            window["secondaryModal"].close();
          }}
          className="btn btn-sm btn-purple"
        >
          Save
        </button>,
      ],
      body: Body,
    });
  }

  editSessions(sessions, moduleIdx) {
    const tabContent = [];
    const tabs = [];
    let _this = this;

    sessions?.forEach((row, idx) => {
      let learningMaterialValue = [];

      if (row.videoPresentation) {
        row.videoPresentation.forEach((el, vidx) => {
          el.vidx = vidx;
          learningMaterialValue.push(el);
        });
      }

      if (row.reading) {
        row.reading.forEach((el, vidx) => {
          el.vidx = vidx;
          el.subTitle = "Reading time: ".concat(
            $c.readingDurationFromObjectToText(el.duration)
          );
          learningMaterialValue.push(el);
        });
      }

      const tabId = "a".concat($f.key());

      let active = "";

      if (idx == this.state.activeSessionTab) {
        active = "active show";
      }

      tabs.push(
        <SortableItem
          key={`session-${moduleIdx}-${idx}`}
          index={idx}
          value={
            <li className={"nav-item "} key={$f.key()}>
              <span
                className={"nav-link color-strong-grey nav-ellipsis ".concat(
                  active
                )}
                data-bs-toggle="tab"
                data-bs-target={"#".concat(tabId)}
                role="button"
                onClick={() => {
                  this.setActiveSessionTab(idx);
                }}
              >
                <DragHandle></DragHandle>
                Session {idx + 1}
                {this.state.configuration.canRemove && sessions.length > 1 ? (
                  <i
                    className="fas fa-times ms-2"
                    onClick={() => {
                      this.deleteSession(moduleIdx, idx);
                    }}
                  ></i>
                ) : (
                  ""
                )}
              </span>
            </li>
          }
        ></SortableItem>
      );

      tabContent.push(
        <div
          className={"tab-pane fade ".concat(active)}
          id={tabId}
          role="tabpanel"
          aria-labelledby="profile-tab"
        >
          <div class="form-group mb-3">
            <label className="label-top">Title</label>
            <input
              type="text"
              value={row.title}
              className="form-control"
              placeholder="Type session title"
              onChange={(e) => {
                this.onSessionChange(moduleIdx, idx, "title", e.target.value);
              }}
            ></input>
            <span className="text-danger mt-2 d-block">
              {this.validator.message(
                "title of session "
                  .concat(idx + 1)
                  .concat(" of module" + (moduleIdx + 1)),
                row.title,
                "required"
              )}
            </span>
          </div>

          {!this.state.configuration.isTemplate ? (
            <>
              <div class="form-group mb-3">
                <label className="label-top">Instructors</label>
                <Tags
                  key={$f.key()}
                  value={row.instructors}
                  property={{
                    configuration: {
                      type: "instructors",
                    },
                    events: {},
                    attributes: {
                      className: "form-control",
                      placeholder: "Search instructors",
                    },
                    parentChange: function (e) {
                      _this.updateInstructors(moduleIdx, idx, e);
                    },
                  }}
                />
              </div>
              <div class="form-group mb-3">
                <label className="label-top">Live classes</label>
                <div className="">
                  <span
                    className="color-black"
                    onClick={() => this.addVideoClass(moduleIdx, idx)}
                    role="button"
                  >
                    + Add live class
                  </span>
                  {row.videoConference?.map((vconf, vidx) => {
                    return (
                      <>
                        <div className="mt-3 border rounded p-3">
                          <div className="text-end">
                            <i
                              className="fas fa-trash-alt"
                              role="button"
                              onClick={() => {
                                this.deleteVideoClass(moduleIdx, idx, vidx);
                              }}
                            ></i>
                          </div>

                          <div className="form-group mb-3">
                            <label className="label-top">Title</label>
                            <input
                              type="text"
                              value={vconf.title}
                              className="form-control"
                              placeholder="Type live class title"
                              onChange={(e) => {
                                this.onVideoConferenceChange(
                                  moduleIdx,
                                  idx,
                                  vidx,
                                  "title",
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                          <div className="form-group mb-3">
                            <label className="label-top">Availability</label>
                            <FromToDatetime
                              value={{ from: vconf.from, to: vconf.to }}
                              property={{
                                events: {
                                  overrideOnChange: (e) => {
                                    this.onVideoConferenceChange(
                                      moduleIdx,
                                      idx,
                                      vidx,
                                      "availability",
                                      e.state.value
                                    );
                                  },
                                },
                                attributes: { className: "form-control" },
                              }}
                            />
                            <span className="text-danger mt-2 d-block">
                              {this.validator.message(
                                "Live class".concat(vidx + 1) +
                                  " of session "
                                    .concat(idx + 1)
                                    .concat(" of module" + (moduleIdx + 1)),
                                vconf,
                                "liveClasses"
                              )}
                            </span>
                          </div>
                          <div className="form-group mb-3">
                            <label className="label-top">Reminder</label>
                            <Reminder
                              value={vconf.reminders}
                              property={{
                                parentChange: (e) => {
                                  _this.onVideoConferenceChange(
                                    moduleIdx,
                                    idx,
                                    vidx,
                                    "reminders",
                                    e
                                  );
                                },
                                events: {},
                                attributes: { className: "form-select w-50" },
                              }}
                            />
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          <div class="form-group mb-3">
            <label className="label-top">Tests</label>
            <div className="">
              <span
                className="color-black"
                onClick={() => {
                  Modals.events.selectTestFromTemplateForTraining({
                    select: this.addTest,
                    moduleIndex: moduleIdx,
                    sessionIndex: idx,
                  });
                  //  this.addTest(moduleIdx, idx)
                }}
                role="button"
              >
                + Add test
              </span>
              {row.exam?.map((exam, vidx) => {
                return (
                  <div className="mt-3 border rounded p-3">
                    <div className="text-end">
                      <i
                        className="fas fa-pen me-2"
                        role="button"
                        onClick={() => {
                          if (this.state.configuration.isTemplate) {
                            Modals.events.selectTestFromTemplateForTraining({
                              select: this.updateTest,
                              moduleIndex: moduleIdx,
                              sessionIndex: idx,
                              examIndex: vidx,
                            });
                          } else {
                            Modals.events.editTestTemplateForTraining({
                              inputData: [exam],
                              onSave: (exam) => {
                                _this.updateTest(moduleIdx, idx, vidx, exam);
                              },
                            });
                          }
                        }}
                      ></i>
                      <i
                        className="fas fa-trash-alt"
                        role="button"
                        onClick={() => {
                          this.deleteTest(moduleIdx, idx, vidx);
                        }}
                      ></i>
                    </div>

                    <div className="form-group mb-3">
                      <span className="d-block mb-3">{exam.title}</span>

                      {this.state.configuration.isTemplate != true ? (
                        <span className="display-5 color-grey mb-2 d-block">
                          {/* {$c.formatDateFromTo(exam.from, exam.to)} */}
                        </span>
                      ) : (
                        ""
                      )}
                      {$c.testGrade(exam.passingGrade, "badge")}
                      <div className="mt-3">
                        <label className="label-top">Availability</label>
                        <FromToDatetime
                          key={$f.key()}
                          value={{ from: exam.from, to: exam.to }}
                          property={{
                            events: {
                              overrideOnChange: (e) => {
                                _this.updateTestAvailability(
                                  moduleIdx,
                                  idx,
                                  vidx,
                                  e.state.value
                                );
                              },
                            },
                            attributes: { className: "form-control" },
                          }}
                        />
                      </div>
                      <div className="mt-3">
                        <label className="label-top">Duration</label>
                        <Duration
                          key={$f.key()}
                          value={exam.examDuration}
                          property={{
                            parentChange: (e) => {
                              _this.updateTestDuration(moduleIdx, idx, vidx, e);
                            },
                            events: {},
                            attributes: { className: "form-control" },
                          }}
                        />
                        <span className="text-danger mt-2 d-block">
                          {this.validator.message(
                            "Test ".concat(vidx + 1) +
                              " of session "
                                .concat(idx + 1)
                                .concat(" of module" + (moduleIdx + 1)),
                            exam.examDuration,
                            "compareDuration"
                          )}
                        </span>
                      </div>
                      <div className="mt-3">
                        <label className="label-top">Reminder</label>
                        <Reminder
                          value={exam.reminders}
                          property={{
                            parentChange: (e) => {
                              _this.updateTestReminder(moduleIdx, idx, vidx, e);
                            },
                            events: {},
                            attributes: { className: "form-select w-50" },
                          }}
                        />
                      </div>

                      {/* <input
                        type="text"
                        value={exam.title}
                        className="form-control"
                        onChange={(e) => {
                          this.onTestChange(
                            moduleIdx,
                            idx,
                            vidx,
                            "title",
                            e.target.value
                          );
                        }}
                      /> */}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div class="form-group mb-3">
            <label className="label-top">Learning materials</label>
            <div className="">
              <Upload
                value={learningMaterialValue}
                key={$f.key()}
                property={{
                  label: "Attached files",
                  type: "upload",
                  editable: true,
                  configuration: {
                    url: "/agenda-file",
                    type: "training",
                    fileDataProperty: "file",
                    allowedExtensions: ["pdf", "mp4"],
                    multipleFiles: true,
                    editFunction: (e) => {
                      _this.editUpload(e, moduleIdx, idx);
                    },
                    deleteFunction: (e) => {
                      switch (e.mimetype) {
                        case "audio/mpeg":
                          _this.deleteMaterial(
                            moduleIdx,
                            idx,
                            e.vidx,
                            "videoPresentation"
                          );
                          break;

                        case "video/mp4":
                          _this.deleteMaterial(
                            moduleIdx,
                            idx,
                            e.vidx,
                            "videoPresentation"
                          );
                          break;

                        case "application/pdf":
                          _this.deleteMaterial(
                            moduleIdx,
                            idx,
                            e.vidx,
                            "reading"
                          );
                          break;
                      }
                    },
                  },
                  parentChange: function (e, fileupload) {
                    switch (fileupload.mimetype) {
                      case "video/mp4":
                        fileupload.vidx = row.videoPresentation
                          ? row.videoPresentation.length
                          : 0;
                        _this.addMaterial(
                          moduleIdx,
                          idx,
                          "videoPresentation",
                          fileupload
                        );
                        break;

                      case "application/pdf":
                        fileupload.vidx = row.reading ? row.reading.length : 0;
                        fileupload.duration = { hours: 0, minutes: 0 };

                        _this.addMaterial(
                          moduleIdx,
                          idx,
                          "reading",
                          fileupload
                        );
                        break;
                    }
                  },
                  caption: function (_this) {
                    return (
                      <div className="w-100 text-center p-4 ">
                        <img
                          src="/uni/svg/files/upload.svg"
                          className="svg-sm mb-2"
                        />
                        <span className="d-block mb-1">
                          <strong>
                            <span
                              className="color-purple"
                              role="button"
                              onClick={_this.onTargetClick}
                            >
                              Click to upload
                            </span>{" "}
                            or drag and drop
                          </strong>
                        </span>
                        <span className="color-grey d-block">
                          PDF, MP4 (max 1GB)
                        </span>
                      </div>
                    );
                  },
                  events: {},
                  attributes: {
                    className: "form-control bg-light-grey",
                  },
                }}
              />
            </div>
          </div>
        </div>
      );
    });

    if (this.state.configuration.canAdd) {
      tabs.push(
        <li className={"nav-item "} key={$f.key()}>
          <span
            className="nav-link color-strong-grey nav-ellipsis "
            role="button"
            onClick={() => {
              this.addSession(moduleIdx);
            }}
          >
            <span className="color-black">Add Session</span>
          </span>
        </li>
      );
    }

    return (
      <div className="mb-3">
        <PerfectScrollbar>
          <ul className="nav nav-tabs navbar-expand">
            <SortableContainer
              onSortEnd={(args) => {
                this.onReorderSessions(args, moduleIdx);
              }}
              pressDelay={50}
              helperClass="sortableHelper"
              useDragHandle
              axis="x"
            >
              {tabs}
            </SortableContainer>
          </ul>
        </PerfectScrollbar>
        <div className="tab-content mt-3 ">{tabContent}</div>
      </div>
    );
  }

  editRender() {
    const output = [];

    const tabContent = [];
    const tabs = [];

    const toolbarOptions = [
      ["bold", "italic", "underline", "strike", "link"],
      [{ list: "ordered" }, { list: "bullet" }],
    ];

    this.state.value?.forEach((row, idx) => {
      let rowControls = [];

      const tabId = "a".concat($f.key());

      let active = "";

      if (idx == this.state.activeTab) {
        active = "active show";
      }

      tabs.push(
        <SortableItem
          key={`item-${idx}`}
          index={idx}
          value={
            <li className={"nav-item "}>
              <span
                className={"nav-link color-strong-grey nav-ellipsis ".concat(
                  active
                )}
                data-bs-toggle="tab"
                data-bs-target={"#".concat(tabId)}
                role="button"
              >
                <span
                  onClick={() => {
                    this.setActiveTab(idx);
                  }}
                >
                  <DragHandle></DragHandle>
                  Module {idx + 1}
                </span>
                {this.state.configuration.canRemove &&
                this.state.value.length > 1 ? (
                  <i
                    className="fas fa-times ms-2"
                    onClick={() => {
                      this.deleteModule(idx);
                    }}
                  ></i>
                ) : (
                  ""
                )}
              </span>
            </li>
          }
        />
      );

      tabContent.push(
        <div
          className={"tab-pane fade ".concat(active)}
          id={tabId}
          role="tabpanel"
          aria-labelledby="profile-tab"
          // key={"moduletab".concat(idx)}
        >
          <div class="form-group mb-3">
            <label className="label-top">Title</label>
            <input
              type="text"
              value={row.title}
              className="form-control"
              placeholder="Type module title"
              onChange={(e) => {
                this.onModuleChange(idx, "title", e.target.value);
              }}
            ></input>
            <span className="text-danger mt-2 d-block">
              {this.validator.message(
                "title of module ".concat(idx + 1),
                row.title,
                "required"
              )}
            </span>
          </div>
          <div class="form-group mb-3">
            <label className="label-top">Description</label>
            <div className="form-control p-0 pb-5">
              <ReactQuill
                theme="snow"
                value={row.description}
                style={{ height: "60px" }}
                modules={{
                  toolbar: toolbarOptions,
                }}
                placeholder="Type module description"
                onChange={(content) => {
                  this.onModuleChange(
                    idx,
                    "description",
                    content == "<p><br></p>" ? "" : content
                  );
                }}
              ></ReactQuill>
            </div>
            {/* <span className="text-danger mt-2 d-block">
              {this.validator.message(
                "description",
                row.description,
                "required"
              )}
            </span> */}
          </div>
          {this.editSessions(row.sessions, idx)}
        </div>
      );
    });

    if (this.state.configuration.canAdd) {
      tabs.push(
        <li className={"nav-item "} key={$f.key()}>
          <span
            className="nav-link color-strong-grey nav-ellipsis "
            role="button"
            onClick={this.addModule}
          >
            <span className="color-black">Add module</span>
          </span>
        </li>
      );
    }

    return (
      <div className="mb-3">
        <PerfectScrollbar>
          <ul className="nav nav-tabs navbar-expand">
            <SortableContainer
              onSortEnd={this.onReorderModules}
              pressDelay={50}
              helperClass="sortableHelper"
              axis="x"
              useDragHandle
            >
              {tabs}
            </SortableContainer>
          </ul>
        </PerfectScrollbar>
        <div className="tab-content mt-3 ">{tabContent}</div>
      </div>
    );
  }

  render() {
    if (this.state.loaded) {
      return this.editRender();
    }

    return "";
  }
}

export default TrainingModules;
