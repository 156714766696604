import { Link } from "react-router-dom";
import $f from "../../src";
import JoinEventButton from "../../types/joinEventButton/joinEventButton";
import EmptyStates from "../emptyStates";
import $c from "../functions";
import Modals from "../modals";

const Forms = {
  properties: (args) => {
    const { editable } = args;

    return {
      _id: {
        label: "",
        path: "_id",
        labelPosition: "none",
        type: "hidden",
        events: {},
        attributes: {},
      },
      title: {
        label: "Title",
        type: "text",
        editable: editable,
        events: {},
        validation: "required",
        attributes: {
          className: "form-control",
          placeholder: "Type title",
        },
      },
      formoptions: {
        label: "",
        type: "formoptions",
        editable: editable,
        events: {},
        formatValue: (cell, row) => {
          return row.type
            ? row
            : {
                type: "public",
                isAnonymous: false,
              };
        },
        attributes: {
          className: "form-control",
        },
      },
      optionsfortable: {
        label: "",
        type: "optionsfortable",
        configuration: {
          options: {
            edit: function (args) {
              return {
                label: "Edit",
                onClick: () => {
                  let newargs = {
                    props: { data: { ...args.props.data } },
                    action: "update",
                  };

                  $f.getValue(Modals.forms.editForm, newargs);
                },
              };
            },
            copyLink: function (args) {
              return {
                label: "Copy form link",
                onClick: function () {
                  $c.copyToClipboard(
                    window["host"]("/form/m-".concat(args.props.data._id))
                  );
                  $f.createNotification({
                    type: "success",
                    message: "Link copied!",
                  });
                },
              };
            },
            sendInvitation: function (args) {
              return {
                label: "Send invitation",
                onClick: function () {
                  $f.getValue(Modals.participants.addAllAndExternals, {
                    title: "Send invitation",
                    api: "new",
                    actionType: "formsInvitation",
                    actionId: args.props.data._id,
                    btnTitle: "Send",
                    subtitle: (
                      <small className="color-grey">
                        invitees will receive an email with the link of the form
                      </small>
                    ),
                  });
                },
              };
            },
            results: function (args) {
              if ($f.getQueryPath(3) === "results") {
                return;
              }

              return {
                label: "View results",
                onClick: function () {
                  window.location.replace(
                    "/forms/".concat(args.props.data._id, "/results")
                  );
                },
              };
            },
            delete: function (args) {
              return {
                label: <span className="text-danger">Delete</span>,
                onClick: function () {
                  let newargs = {
                    props: { data: { ...args.props.data } },
                    callback: () => {
                      window.location.replace("/forms");
                    },
                  };

                  $f.getValue(Modals.forms.deleteForm, newargs);
                },
              };
            },
          },
        },
      },
      createdAt: {
        label: "Date Created",
        type: "text",
        editable: editable,
        events: {},
        attributes: {},
        formatValue: (value, row) => {
          return $c.formatDateTime(row.createdAt);
        },
      },
      updatedAt: {
        label: "Last Modified",
        type: "text",
        editable: editable,
        events: {},
        attributes: {},
        formatValue: (value, row) => {
          return $c.formatDateTime(row.updatedAt);
        },
      },
      instructions: {
        label: "Instructions",
        type: "editor",
        editable: editable,
        events: {},
        validation: "required",
        attributes: {
          className: "form-control",
          placeholder: "Type instructions",
        },
      },
      questions: {
        label: "Questions",
        type: "formquestions",
        editable: editable,
        events: {},
        attributes: {},
      },
      participants: {
        label: "Search people and groups",
        type: "tags",
        editable: true,
        configuration: {
          type: "participants",
        },
        events: {},
        attributes: {
          className: "form-control",
          placeholder:
            "Search people and groups or paste multiple email addresses",
        },
      },
    };
  },
  specialProperties: () => {
    return {
      header: {
        type: "template",
        configuration: {
          title: "All Forms",
          subTitle: "View all available forms",
          template: "Header",
          buttons: (args) => {
            return [
              <button
                type="button"
                className="btn btn-purple  btn-sm  "
                onClick={() => {
                  $f.getValue(Modals.forms.editForm, {
                    title: "Create form",
                    api: "new",
                  });
                }}
              >
                Create form
              </button>,
            ];
          },
        },
        events: {},
      },
      headerOverview: {
        type: "template",
        configuration: {
          title: (args) => {
            return args.data.data[0].title;
          },
          //subTitle: "Meeting event",
          template: "Header",
          buttons: (args) => {
            const property = {
              events: {
                onClick: function () {
                  window.location.replace(
                    "/test/m-".concat(this.props.data._id)
                  );
                },
              },
              attributes: {
                className: "btn btn-purple btn-sm",
              },
              caption: "Take test",
              configuration: {
                eventNotStartedText: (
                  <span className="color-purple">
                    <strong>Test starts soon</strong>
                  </span>
                ),
                eventHasEndedText: (
                  <span className="text-danger">
                    <strong>Test has ended</strong>
                  </span>
                ),
                fromDateProperty: "from",
                endDateProperty: "to",
                extraStartTime: 0,
                extraEndTime: 0,
                eventInterval: 1000,
                eventIntervalFunction: () => {},
              },
            };

            return (
              <JoinEventButton
                data={args.data?.data[0]}
                property={property}
              ></JoinEventButton>
            );
          },
        },
        events: {},
      },

      results: {
        label: "",
        type: "module",
        configuration: {
          module: "FormsResults",
          view: "list",
        },
      },
    };
  },
  views: {
    list: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/forms?start={start}&offset={offset}";
          },
          dataPath: () => {},
          replace: {
            start: 0,
            offset: 25,
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Table";
        },

        configuration: {
          headerTemplate: function () {
            return this.state.specialProperties.header.component;
          },
          emptyTemplate: function (args) {
            return EmptyStates.forms.forms(args);
          },
          tableConfiguration: {
            headers: {
              title: {
                attributes: {
                  className: "w-50",
                },
              },
            },
            cellTemplates: {
              title: (comp, itm) => {
                return (
                  <Link
                    to={"/forms/".concat(itm._id.value)}
                    className="color-grey"
                  >
                    {comp}
                  </Link>
                );
              },
            },
          },
          includeProperties: () => {
            return ["title", "optionsfortable", "createdAt", "updatedAt"];
          },
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
    overview: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/forms/{id}";
          },
          dataPath: () => {},
          replace: {
            id: () => {
              return $f.getQueryPath(2);
            },
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "FormsOverview";
        },
        configuration: {
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
    userForm: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/user-forms/new-user-form/{id}";
          },
          dataPath: () => {},
          replace: {
            id: () => {
              return $f.getQueryPath(2).replace("m-", "").replace("v-", "");
            },
          },
          headers: {},
          mapper: () => {},
        },
        new: {
          url: () => {
            return { data: [{}] };
          },
          dataPath: () => {},
          replace: {},
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "UserForm";
        },
        configuration: {
          includeProperties: () => {
            return ["_id", "title", "instructions", "questions"];
          },
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: true };
        },
      },
    },
    publicForm: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/public-form";
          },
          dataPath: () => {},
          replace: {
            id: () => {
              return $f.getQueryPath(2);
            },
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "UserPublicForm";
        },
        configuration: {
          includeProperties: () => {
            return ["_id", "title", "instructions", "questions"];
          },
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: true };
        },
      },
    },

    detail: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/forms/{id}";
          },
          dataPath: () => {},
          replace: {
            id: () => {
              return $f.getQueryPath(2);
            },
          },
          headers: {},
          mapper: () => {},
        },
        new: {
          url: () => {
            return { data: [{}] };
          },
          dataPath: () => {},
          replace: {},
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Form";
        },
        configuration: {
          includeProperties: () => {
            return ["_id", "title", "instructions", "formoptions", "questions"];
          },
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: true };
        },
      },
    },
  },
};

export default Forms;
