import parse from "html-react-parser";
import { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import $f from "../../src";

class InviteContactsManually extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.addRow = this.addRow.bind(this);
    this.deleteRow = this.deleteRow.bind(this);
    this.updateMultipleData = this.updateMultipleData.bind(this);
    this.editable = this.editable.bind(this);

    this.validator = new SimpleReactValidator();

    this.state = {
      loaded: false,
    };
  }

  onChange(e) {
    this.setState({
      value: e.target.value,
    });

    setTimeout(this["customOnChange"], 0);
  }

  validate() {
    const isValid = this.validator.allValid();

    this.setState({ isValid });
    this.validator.showMessages();
    this.forceUpdate();

    return isValid;
  }

  componentDidMount() {
    let events = [];

    Object.keys(this.props.property.events)?.forEach((element, idx) => {
      if (!element.toLowerCase().startsWith("onchange")) {
        this[element] = this.props.property.events[element];
        this[element] = this[element].bind(this);
        events[element] = this[element];
      } else {
        this["customOnChange"] = this.props.property.events[element];
        this["customOnChange"] = this["customOnChange"].bind(this);
      }
    });

    this.setState({
      loaded: true,
      events: events,
      value: [{ key: $f.key() }],
    });
  }

  deleteRow(index) {
    let value = [...this.state.value];
    value.splice(index, 1);
    this.setState({ value: value });
  }

  addRow() {
    this.setState({ value: [...this.state.value, { key: $f.key() }] });
  }

  updateMultipleData(property, index, elementValue) {
    let value = [...this.state.value];

    value[index][property] = elementValue;

    this.setState({ value });
  }

  editable() {
    return (
      <div>
        {this.state.value.map((el, idx) => {
          return (
            <div className="mt-2" key={el.key}>
              <div className="d-flex">
                <label className="color-grey w-100">User #{idx + 1}</label>
                <i
                  className="fas fa-trash-alt color-black"
                  onClick={() => {
                    this.deleteRow(idx);
                  }}
                  role="button"
                ></i>
              </div>
              <div className="row">
                <div className="col-md-6 p-1">
                  <input
                    type="text"
                    defaultValue={el.firstname}
                    placeholder="Type first name"
                    className="form-control"
                    onChange={(e) => {
                      this.updateMultipleData("firstName", idx, e.target.value);
                    }}
                  ></input>
                </div>
                <div className="col-md-6 p-1">
                  <input
                    type="text"
                    defaultValue={el.lastname}
                    placeholder="Type last name"
                    className="form-control"
                    onChange={(e) => {
                      this.updateMultipleData("lastName", idx, e.target.value);
                    }}
                  ></input>
                </div>
                <div className="col-md-6 p-1">
                  <input
                    type="email"
                    defaultValue={el.email}
                    placeholder="Type email address"
                    className="form-control"
                    onChange={(e) => {
                      this.updateMultipleData("email", idx, e.target.value);
                    }}
                  ></input>
                  <span className="text-danger mt-2 d-block">
                    {this.validator.message(
                      "Email of user ".concat(idx + 1),
                      this.state.value[idx]["email"],
                      "required|email"
                    )}
                  </span>
                </div>
                <div className="col-md-6 p-1">
                  <select
                    className="form-select form-control"
                    defaultValue={el.tag}
                    onChange={(e) => {
                      this.updateMultipleData("tag", idx, e.target.value);
                    }}
                  >
                    <option value="">Not specified (default)</option>
                    <option value="instructor">Instructor</option>
                    <option value="host">Conference host</option>
                    <option value="viewer">Viewer</option>
                  </select>
                </div>
              </div>
            </div>
          );
        })}
        <span className="color-purple" role="button" onClick={this.addRow}>
          <i className="fas fa-user-plus me-2"></i>
          Add new user
        </span>
      </div>
    );
  }

  nonEditable() {
    return typeof this.state.value === "string"
      ? parse(this.state.value)
      : this.state.value;
  }

  customUI() {}

  render() {
    if (this.state.loaded) {
      return this.editable();
    }

    return "";
  }
}

export default InviteContactsManually;
