import { ConsoleLogger } from "amazon-chime-sdk-js";
import parse from "html-react-parser";
import { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import $f from "../../src";
import Select from "../select/select";
import Switch from "../switch/switch";

class RecordingOptions extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.validator = new SimpleReactValidator();

    this.state = {
      loaded: false,
    };
  }

  onChange(e, property) {
    let value = { ...this.state.value };

    value[property] = e;

    if (property == "recording") {
      if (e.toString() === "false") {
        value["recordingMethod"] = "VideoAudio";
        value["recording"] = "false";
      } else {
        value["recordingMethod"] = this.state.value?.recordingMethod
          ? this.state.value.recordingMethod
          : "VideoAudio";
      }
    }

    this.setState({
      value: value,
    });

    setTimeout(this["customOnChange"], 0);
  }

  validate() {
    const isValid = this.validator.allValid();

    this.setState({ isValid });
    this.validator.showMessages();
    this.forceUpdate();

    return isValid;
  }

  componentDidMount() {
    let events = [];

    Object.keys(this.props.property.events)?.forEach((element, idx) => {
      if (!element.toLowerCase().startsWith("onchange")) {
        this[element] = this.props.property.events[element];
        this[element] = this[element].bind(this);
        events[element] = this[element];
      } else {
        this["customOnChange"] = this.props.property.events[element];
        this["customOnChange"] = this["customOnChange"].bind(this);
      }
    });

    let propValue = this.props.value
      ? this.props.value
      : {
          recording: "false",
          recordingMethod: "VideoAudio",
          allowCameraForParticipants: "true",
        };

    propValue.allowCameraForParticipants =
      propValue.allowCameraForParticipants.toString() === "true"
        ? "true"
        : "false";

    propValue.recording =
      propValue.recording.toString() === "true" ? "true" : "false";

    this.setState({
      loaded: true,
      events: events,
      attributes: { ...this.props.property.attributes },
      value: propValue,
      editable: this.props.property.editable === false ? false : true,
      useCustomUI: this.props.property.useCustomUI,
      showRecordingOptions: this.props.value?.recording ? true : false,
    });
  }

  editable() {
    let _this = this;
    return (
      <>
        <div className="form-group mb-3 d-flex">
          <label className="label-top w-100">
            Allow camera for participants
          </label>
          <Switch
            value={
              this.state.value?.allowCameraForParticipants.toString() === "true"
                ? true
                : false
            }
            property={{
              events: {},
              attributes: {
                className: "form-control",
              },
              parentChange: function (e) {
                _this.onChange(e.toString(), "allowCameraForParticipants");
              },
            }}
          ></Switch>
        </div>

        <div className="form-group mb-3 d-flex">
          <label className="label-top w-100">Record event</label>
          <Switch
            value={
              this.state.value?.recording.toString() === "true" ? true : false
            }
            property={{
              events: {},
              attributes: {
                className: "form-control",
              },
              parentChange: function (e) {
                _this.onChange(e, "recording");
              },
            }}
          ></Switch>
        </div>
        <div
          className={
            this.state.value?.recording.toString() === "false" ? "d-none" : ""
          }
        >
          <div className="form-group mb-3">
            <label className="label-top w-100">Select record method</label>
            <Select
              value={this.state.value?.recordingMethod}
              property={{
                events: {
                  didMount: function () {
                    const _this = this;
                    $f.fetch(
                      "/recordingMethods",
                      "GET",
                      null,
                      {},
                      {},
                      (resp) => {
                        const options = resp.data.data ? resp.data.data : [];
                        _this.setState({ options: options });
                      }
                    );
                  },
                },
                attributes: {
                  className: "form-control form-select",
                },
                parentChange: function (e) {
                  _this.onChange(e, "recordingMethod");
                },
              }}
            ></Select>
          </div>
        </div>
      </>
    );
  }

  nonEditable() {
    return typeof this.state.value === "string"
      ? parse(this.state.value)
      : this.state.value;
  }

  customUI() {}

  render() {
    if (this.state.loaded) {
      if (this.state.useCustomUI) {
        return this.customUI();
      } else if (this.state.editable) {
        return this.editable();
      } else {
        return this.nonEditable();
      }
    }

    return "";
  }
}

export default RecordingOptions;
