import React, { Component } from "react";
import $f from "../../src";

class Pagination extends Component {
  state = {
    start: 0,
    offset: 25,
    current: 0,
    totalRecords: 0,
    first: "First",
    last: "Last",
    previous: "Previous",
    next: "Next",
    loaded: false,
    replaceAttribute: "page",
  };

  constructor(props) {
    super(props);

    this.clickPage = this.clickPage.bind(this);
    let events = {};

    Object.keys(this.props.property.events)?.forEach((element, idx) => {
      if (!element.toLowerCase().startsWith("onchange")) {
        this[element] = this.props.property.events[element];
        this[element] = this[element].bind(this);
        events[element] = this[element];
      } else {
        this["customOnChange"] = this.props.property.events[element];
        this["customOnChange"] = this["customOnChange"].bind(this);
      }
    });

    const configuration = $f.getValue(this.props.property.configuration, this);

    this.state = {
      start: $f.getValue(configuration.start, this)
        ? $f.getValue(configuration.start, this)
        : 0,
      offset: $f.getValue(configuration.offset, this)
        ? $f.getValue(configuration.offset, this)
        : 25,
      current: $f.getValue(configuration.current, this)
        ? $f.getValue(configuration.current, this)
        : 0,
      replaceAttribute: $f.getValue(configuration.replaceAttribute, this),
      totalRecords:
        $f.getValue(configuration.total, this) >= 0
          ? $f.getValue(configuration.total, this)
          : 25,
    };
  }

  clickPage(page) {
    let replace = { ...this.props.module.state.view.apis.view.replace };

    replace[this.state.replaceAttribute] = page;

    this.props.module.doWork(replace);
  }

  componentDidMount() {
    this.setState({
      loaded: true,
      totalPages:
        this.state.totalRecords < this.state.offset
          ? 1
          : Math.ceil(this.state.totalRecords / this.state.offset),
    });
  }

  render() {
    let output = [];

    if (this.state.loaded) {
      let pagination = [];
      if (this.state.totalPages > 1) {
        let from = this.state.current - 2 <= 0 ? 1 : this.state.current - 2;
        let to =
          from + 3 < this.state.totalPages ? from + 3 : this.state.totalPages;

        if (this.state.current > this.state.start) {
          pagination.push({
            label: <i className="fas fa-step-backward" title="First"></i>,
            value: this.state.start,
          });
          pagination.push({
            label: <i className="fas fa-chevron-left" title="Previous"></i>,
            value: this.state.current - 1,
          });
        }

        for (let i = from; i <= to; i++) {
          pagination.push({
            label: i,
            value: i - 1,
            isCurrent: this.state.current == i - 1 ? true : false,
          });
        }

        if (this.state.current < this.state.totalPages - 1) {
          pagination.push({
            label: <i className="fas fa-chevron-right" title="Next"></i>,
            value: this.state.current + 1,
          });
          pagination.push({
            label: <i className="fas fa-step-forward" title="Last"></i>,
            value: this.state.totalPages - 1,
          });
        }
      }

      pagination.map((itm, idx) => {
        let isCurrentClass = "";
        if (itm.isCurrent) {
          isCurrentClass = " active bg-purple text-white";
        }

        output.push(
          <li
            className={" nav-item border m-1 pe-2 ps-2 pt-1 pb-1 color-grey".concat(
              isCurrentClass
            )}
          >
            <span
              className={"nav-link  pointer "}
              onClick={(e) => this.clickPage(itm.value)}
            >
              <small>{itm.label}</small>
            </span>
          </li>
        );
      });

      return (
        <div className="row">
          <div className="col-md-6">
            <div className="color-grey records p-2 m-auto">
              Total: {this.state.totalRecords}
            </div>
          </div>
          <div className="col-md-6">
            <div className="m-auto">
              <ul className="pagination justify-content-end ">{output}</ul>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="row">
          <div className="col-md-6">
            <div className="color-grey records p-2 m-auto">
              Total: {this.state.totalRecords}
            </div>
          </div>
          <div className="col-md-6"></div>
        </div>
      );
    }
  }
}

export default Pagination;
