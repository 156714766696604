export function triggerCollapse() {
  Array.from(document.getElementsByClassName("collapse-question")).forEach(
    (el, idx) => {
      if (!el.classList.contains("collapsed")) {
        el.click();
      }
      if (
        Array.from(document.getElementsByClassName("collapse-question"))
          .length ==
        idx + 1
      ) {
        el.click();
      }
    }
  );
}
