import { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import $c from "../../../../modules/functions";

class Spaces extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    const { data, module, view, specialProperties, response } = this.props;

    let templateConfiguration = view.template.configuration;

    const innerTemplates = ["header", "top", "bottom"];

    innerTemplates.map((itm, idx) => {
      let tmp = templateConfiguration[itm + "Template"]
        ? templateConfiguration[itm + "Template"]
        : () => {};
      this[itm + "Template"] = tmp.bind(this);
    });

    this.setState({
      specialProperties,
      data,
      loaded: true,
      view,
      module,
      restData: response.data,
    });
  }

  render() {
    if (this.state.loaded) {
      return (
        <div>
          {this.headerTemplate()}
          {this.topTemplate()}
          <div className="row w-md-85">
            {this.state.restData.map((el) => {
              let background = null;

              if (el.image) {
                background = {
                  backgroundImage: "url(".concat(
                    $c.agendaFileImageURL(el.image),
                    ")"
                  ),
                };
              }

              return (
                <div className="col-md-4 p-1">
                  <Link to={"/space/".concat(el._id)}>
                    <div
                      className="card bg-login-img mt-2 me-2"
                      style={background}
                    >
                      <div
                        className="card-body pt-4"
                        style={{ height: "10rem" }}
                      >
                        <h4 className="display-4-no-bold text-white text-break text-ellipsis">
                          {el.name}
                        </h4>
                      </div>
                      <div
                        className="card-footer text-white border-0"
                        style={{ backgroundColor: "unset" }}
                      >
                        {el.participants} participants
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
          {this.bottomTemplate()}
        </div>
      );
    }

    return "";
  }
}

export default withRouter(Spaces);
