import { Component } from "react";
import parse from "html-react-parser";
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";
import $c from "../../../../modules/functions";
import $f from "../../../../src";

class UserTest extends Component {
  constructor(props) {
    super(props);

    this.updateAnswerData = this.updateAnswerData.bind(this);
    this.updateRemainingTime = this.updateRemainingTime.bind(this);
    this.formatRemainingTime = this.formatRemainingTime.bind(this);
    this.submitExam = this.submitExam.bind(this);

    this.state = {
      loaded: false,
      timeoutFunction: null,
    };
  }

  componentDidMount() {
    const { data, module, view } = this.props;

    const exam = this.props.response.data[0];

    let hasEssay = false;

    exam.questions.forEach((el) => {
      if (el.type == "essayType") {
        hasEssay = true;
      }
    });

    this.setState({
      loaded: true,
      data,
      module,
      view,
      exam,
      hasEssay,
      status: exam.status,
      remainingTime: exam.remainingTime,
      trainingInfo: {
        moduleId: exam?.examEvent?.moduleId,
        sessionId: exam?.examEvent?.sessionId,
        training: exam?.examEvent?.training,
      },
    });

    document.body.style.paddingTop = "0rem";
    document.body.style.backgroundColor = "#f6f7f9";

    setTimeout(this.updateRemainingTime, 0);
  }

  formatRemainingTime(remainingTime) {
    let timeLeft = "";

    if (remainingTime.hours) {
      timeLeft = remainingTime.hours + "hr ";
    }

    if (remainingTime.minutes) {
      timeLeft += String("00" + remainingTime.minutes).slice(-2) + "min ";
    }

    if (remainingTime.hours < 1) {
      if (remainingTime.seconds) {
        timeLeft +=
          String("00" + Math.floor(remainingTime.seconds)).slice(-2) + "sec";
      }
    }

    return timeLeft;
  }

  updateAnswerData(idx, ansidx, property, data) {
    let exam = this.state.exam;

    if (!exam.questions[idx].answers[ansidx]) {
      exam.questions[idx].answers.push({ userAnswer: "" });
    }

    exam.questions[idx].answers[ansidx][property] = data;

    this.setState({ exam });

    this.updateRemainingTime();
  }

  submitExam() {
    const { exam } = this.state;

    let _this = this;

    const postData = {
      _id: exam._id,
      questions: [...exam.questions],
    };

    window["primaryModal"].setState({
      show: true,
      size: "default",
      title: (
        <h2 className="display-3-no-line-height header-ellipsis">
          Submit test?
        </h2>
      ),
      footer: [
        <button
          type="button"
          onClick={() => {
            window["primaryModal"].close();
          }}
          className="btn btn-sm btn-white"
        >
          Cancel
        </button>,
        <button
          type="button"
          onClick={() => {
            if (this.state.timeoutFunction) {
              clearTimeout(this.state.timeoutFunction);
            }

            $f.fetch(
              "/exam/user-exam/finalize/{id}",
              "PATCH",
              postData,
              {},
              {
                id: () => {
                  return exam._id;
                },
              },
              (resp) => {
                if (_this.state.trainingInfo.training) {
                  window.location.replace(
                    "/event/training/".concat(
                      _this.state.trainingInfo.training,
                      "/results"
                    )
                  );
                } else {
                  if (_this.state.hasEssay) {
                    window.location.replace(
                      "/event/test/".concat(exam.examEvent._id)
                    );
                  } else {
                    window.location.replace(
                      "/event/test/".concat(exam._id, "/result")
                    );
                  }
                }
              }
            );
          }}
          className="btn btn-sm btn-purple"
        >
          Submit test
        </button>,
      ],
      body: (
        <p>
          You are about to submit your test. If you want to review your answers
          click cancel, or click submit test to submit it.<br></br> This actions
          cannot be undone!<br></br>
          {this.state.hasEssay
            ? "You will receive an email notification when the test is evaluated."
            : ""}
        </p>
      ),
    });
  }

  updateRemainingTime() {
    const { exam } = this.state;

    const postData = {
      _id: exam._id,
      questions: [...exam.questions],
    };

    if (this.state.timeoutFunction) {
      clearTimeout(this.state.timeoutFunction);
    }

    let _this = this;

    if (this.state.status == "Pending") {
      $f.fetch(
        "/exam/user-exam/",
        "PATCH",
        postData,
        {},
        {},
        (resp) => {
          const data = resp.data.data[0];
          this.setState({
            remainingTime: data.remainingTime,
            status: data.status,
          });

          let timeout = 5000;
          let stopTimeout = false;

          if (!data.remainingTime.hours && !data.remainingTime.minutes) {
            timeout = 3000;

            if (data.remainingTime.seconds <= 10) {
              timeout = 1000;
            }

            if (data.remainingTime.seconds <= 1) {
              if (_this.state.timeoutFunction) {
                clearTimeout(_this.state.timeoutFunction);
              }
              stopTimeout = true;
              _this.finalizeAuto();
            }
          }

          if (!stopTimeout) {
            _this.setState({
              timeoutFunction: setTimeout(_this.updateRemainingTime, timeout),
            });
          }
        },
        null,
        null,
        null,
        null,
        false,
        (resp) => {
          _this.finalizeAuto();
        }
      );
    } else {
      this.finalizeAuto();
    }
  }

  finalizeAuto() {
    const { exam } = this.state;
    let _this = this;

    if (this.state.timeoutFunction) {
      clearTimeout(this.state.timeoutFunction);
    }

    $f.fetch(
      "/exam/user-exam/status/{id}",
      "GET",
      null,
      {},
      {
        id: () => {
          return exam._id;
        },
      },
      (resp) => {
        window["primaryModal"].setState({
          show: true,
          size: "default",
          title: (
            <h2 className="display-3-no-line-height header-ellipsis">
              Test duration has ended
            </h2>
          ),
          footer: [
            <button
              type="button"
              onClick={() => {
                if (_this.state.trainingInfo.training) {
                  window.location.replace(
                    "/event/training/".concat(
                      _this.state.trainingInfo.training,
                      "/results"
                    )
                  );
                } else {
                  if (_this.state.hasEssay) {
                    window.location.replace(
                      "/event/test/".concat(exam.examEvent._id)
                    );
                  } else {
                    window.location.replace(
                      "/event/test/".concat(exam._id, "/result")
                    );
                  }
                }
              }}
              className="btn btn-sm btn-purple"
            >
              {this.state.hasEssay ? "Continue" : "View results"}
            </button>,
          ],
          body: (
            <p>
              You haven't submitted the test within its time limit.<br></br>{" "}
              Don't worry, your progress has been submitted automatically.
              <br></br>
              {this.state.hasEssay
                ? "You will receive an email notification when the test is evaluated."
                : "Click below to view your result."}
            </p>
          ),
        });
      }
    );
  }

  render() {
    if (this.state.loaded) {
      const data = this.state.data[0];
      const { exam } = this.state;

      return (
        <>
          <header className="fixed-top">
            <nav className="navbar navbar-expand-md bg-white  top-navbar">
              <div className="container-fluid viewport-padding  color-strong-grey">
                <span className="display-5 mb-0">{parse(exam.title)}</span>
                <div className="text-md-end mt-auto mb-auto">
                  <i className="fas fa-clock me-2 text-primary"></i>
                  <strong>
                    {this.formatRemainingTime(this.state.remainingTime)}
                  </strong>
                </div>
              </div>
            </nav>
          </header>
          <main className="container-fluid viewport-padding bg-light-grey pb-5">
            <div className="wrapper" style={{ paddingTop: "3.563rem" }}>
              <div className="notification"></div>
              <div className="container p-3 rounded border mb-3 mt-5 bg-white">
                <label>
                  <strong>Instructions</strong>
                </label>
                <div>{parse(exam.instructions)}</div>
              </div>
              <div className="container p-3 rounded border mb-3 mt-3 bg-white">
                <div>
                  {exam.questions?.map((el, idx) => {
                    let borderBottom = "";

                    if (idx < exam.questions.length - 1) {
                      borderBottom = "border-bottom-1";
                    }

                    return (
                      <div
                        className={"pt-3 pb-3 ".concat(borderBottom)}
                        key={"question".concat(idx)}
                      >
                        <div className="d-flex">
                          <label className="w-100 ">
                            <strong>Question {idx + 1}</strong>
                          </label>
                          <div
                            className="small color-grey text-end"
                            style={{ width: "8rem" }}
                          >
                            <span>{el.weight} points</span>
                          </div>
                        </div>
                        <div>{parse(el.question)}</div>
                        {el.image ? (
                          <img
                            src={window["baseurl"](
                              "/agenda-file/data/" +
                                el.image +
                                "?type=preview&jwt=" +
                                localStorage.getItem("jwt")
                            )}
                            key={"essayImage".concat(idx)}
                            className="img-fluid mb-3"
                          ></img>
                        ) : (
                          ""
                        )}
                        <div className="color-grey mb-3">
                          {el.type == "multipleChoice" ? "Answers" : "Answer"}
                        </div>

                        {el.answers.map((ans, ansIdx) => {
                          if (el.type == "multipleChoice") {
                            let checked = false;

                            let icon = <Icon.FiCheck color="white" size={20} />;

                            let userAnswerText = "";
                            if (ans.userAnswer.toString() == "true") {
                              checked = true;
                            }

                            return (
                              <div className="mb-2" key={$f.key()}>
                                <div className="d-flex">
                                  <Checkbox
                                    className="me-2"
                                    checked={checked}
                                    onChange={(checked) => {
                                      this.updateAnswerData(
                                        idx,
                                        ansIdx,
                                        "userAnswer",
                                        checked ? "true" : "false"
                                      );
                                    }}
                                    icon={
                                      <div
                                        style={{
                                          display: "flex",
                                          flex: 1,
                                          backgroundColor: checked
                                            ? "#4e2c95"
                                            : "#fff",
                                          alignSelf: "stretch",
                                        }}
                                      >
                                        {icon}
                                      </div>
                                    }
                                    borderColor={
                                      ans.userAnswer.toString() == "true"
                                        ? "#4e2c95"
                                        : "#d7d7d7"
                                    }
                                    borderRadius={23}
                                    style={{
                                      overflow: "hidden",
                                      backgroundColor: "#fff",
                                    }}
                                    size={23}
                                  />

                                  <div className="w-100">{ans.answer}</div>
                                  <div
                                    className="small color-grey"
                                    style={{ width: "8rem" }}
                                  >
                                    <span className="text-break">
                                      {userAnswerText}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                        {el.type == "essayType" ? (
                          <div className="mb-2">
                            <div className="d-flex">
                              <div className="w-100">
                                <textarea
                                  className="form-control"
                                  onChange={(e) => {
                                    this.updateAnswerData(
                                      idx,
                                      0,
                                      "userAnswer",
                                      e.target.value
                                    );
                                  }}
                                >
                                  {el.answers[0]
                                    ? el.answers[0].userAnswer
                                    : ""}
                                </textarea>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className="text-end">
                  {this.state.status == "Pending" ? (
                    <button
                      type="button"
                      className="btn btn-purple btn-sm ps-4 pe-4"
                      onClick={this.submitExam}
                    >
                      Submit
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </main>
        </>
      );
    }

    return "";
  }
}

export default UserTest;
