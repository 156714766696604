import { Component } from "react";
import $f from "../../src";
import Types from "../types";
import PerfectScrollbar from "react-perfect-scrollbar";

class Tabs extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.updateMultipleData = this.updateMultipleData.bind(this);
    this.deleteRow = this.deleteRow.bind(this);
    this.addRow = this.addRow.bind(this);

    this.state = {
      loaded: false,
    };
  }

  onChange(e) {
    this.setState({
      value: e.target.value,
    });

    setTimeout(this["customOnChange"], 0);
  }

  componentDidMount() {
    let events = [];

    Object.keys(this.props.property.events)?.forEach((element, idx) => {
      if (!element.toLowerCase().startsWith("onchange")) {
        this[element] = this.props.property.events[element];
        this[element] = this[element].bind(this);
        events[element] = this[element];
      } else {
        this["customOnChange"] = { ...this.props.property.events[element] };
        this["customOnChange"] = this["customOnChange"].bind(this);
      }
    });

    this.setState({
      loaded: true,
      events: events,
      attributes: { ...this.props.property.attributes },
      value: this.props.value ? this.props.value : [{}],
      activeTab: 0,
      editable: this.props.property.editable === false ? false : true,
      configuration: { ...this.props.property.configuration },
    });
  }

  updateMultipleData(property, index, component) {
    let value = [...this.state.value];

    value[index][property] = component.state.value;

    this.setState({ value });
  }

  deleteRow(index) {
    let value = [...this.state.value];
    value.splice(index, 1);
    this.setState({ value: value, activeTab: index > 0 ? index - 1 : 0 });
  }

  addRow() {
    this.setState({
      value: [...this.state.value, {}],
      activeTab: this.state.value.length,
    });
  }

  setActiveTab(index) {
    this.setState({ activeTab: index });
  }

  render() {
    if (this.state.loaded) {
      const { properties } = this.state.configuration;
      const _this = this;

      const tabContent = [];
      const tabs = [];

      this.state.value?.forEach((row, idx) => {
        let rowControls = [];

        Object.keys(properties).forEach((property) => {
          const _currentAttribute = properties[property];

          let Type = _currentAttribute.type
            ? Types[$f.getValue(_currentAttribute.type)]
            : Types["text"];

          // const customOnChange = _currentAttribute.events?.onChange
          //   ? function () {
          //       console.log(this);
          //       _this.updateMultipleData(
          //         this.props.multipleProperty,
          //         this.props.multipleIndex,
          //         this
          //       );
          //     }
          //   : _this.onChange;

          _currentAttribute.events["onBlur"] = function () {
            _this.updateMultipleData(
              this.props.multipleProperty,
              this.props.multipleIndex,
              this
            );
          };

          const control = (
            <div class="form-group mb-3">
              {typeof _currentAttribute.label === "string" ? (
                <label class="label-top">{_currentAttribute.label}</label>
              ) : (
                $f.getValue(_currentAttribute.label)
              )}
              <Type
                property={_currentAttribute}
                key={$f.key()}
                value={row[property]}
                module={this.props.module}
                data={row}
                multipleIndex={idx}
                multipleProperty={property}
              ></Type>
            </div>
          );

          rowControls.push(control);
        });

        const tabId = "a".concat($f.key());

        let active = "";

        if (idx == this.state.activeTab) {
          active = "active show";
        }

        tabs.push(
          <li
            className={"nav-item "}
            key={$f.key()}
            onClick={() => {
              this.setActiveTab(idx);
            }}
          >
            <span
              className={"nav-link color-strong-grey nav-ellipsis ".concat(
                active
              )}
              data-bs-toggle="tab"
              data-bs-target={"#".concat(tabId)}
              role="button"
            >
              {$f.getValue(this.state.configuration.label, this)} {idx + 1}
              {this.state.configuration.canRemove ? (
                <i
                  className="fas fa-times ms-2"
                  onClick={() => {
                    this.deleteRow(idx);
                  }}
                ></i>
              ) : (
                ""
              )}
            </span>
          </li>
        );

        tabContent.push(
          <div
            className={"tab-pane fade ".concat(active)}
            id={tabId}
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            {rowControls}
          </div>
        );
      });

      if (this.state.configuration.canAdd) {
        tabs.push(
          <li className={"nav-item "} key={$f.key()}>
            <span
              className="nav-link color-strong-grey nav-ellipsis "
              role="button"
              onClick={this.addRow}
            >
              {$f.getValue(this.state.configuration.addLabel, this)}
            </span>
          </li>
        );
      }

      return (
        <div className="mb-3">
          <PerfectScrollbar>
            <ul className="nav nav-tabs navbar-expand">{tabs}</ul>
          </PerfectScrollbar>
          <div className="tab-content mt-3 ">{tabContent}</div>
        </div>
      );
    }

    return "";
  }
}

export default Tabs;
