import { Component } from "react";
import $f from "../../../../src";
import parse from "html-react-parser";
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";
import $c from "../../../../modules/functions";
import { isMobile } from "react-device-detect";

class TestResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    const { data, module, view } = this.props;

    this.setState({
      loaded: true,
      data,
      module,
      view,
      exam: this.props.response.data[0],
    });

    document.body.style.paddingTop = "0rem";
    document.body.style.backgroundColor = "#f6f7f9";
  }

  render() {
    if (this.state.loaded) {
      const data = this.state.data[0];
      const { exam } = this.state;

      let resultbadge = "";

      if (exam.passingGrade) {
        switch (exam.status) {
          case "Fail":
            resultbadge = (
              <div className="container p-0 pt-3">
                <div className="alert-danger  p-2  rounded d-flex">
                  <div className="badge bg-danger">Fail</div>
                  <div className="text-danger ms-2 mt-1">
                    {$c.userIsAdmin() ? "Participant's" : "Your"} grade is{" "}
                    {exam.finalGrade} %
                  </div>
                </div>
              </div>
            );
            break;

          case "Pass":
            resultbadge = (
              <div className="container p-0 pt-3">
                <div className="alert-success  p-2  rounded d-flex">
                  <div className="badge bg-success">Pass</div>
                  <div className="text-success ms-2 mt-1">
                    {$c.userIsAdmin() ? "Participant's" : "Your"} grade is{" "}
                    {exam.finalGrade} %
                  </div>
                </div>
              </div>
            );
            break;

          case "No grade":
            resultbadge = (
              <div className="container p-0 pt-3">
                <div className="alert-info  p-2  rounded d-flex">
                  <div className="text-dark ms-2 mt-1">
                    {$c.userIsAdmin() ? "Participant's" : "Your"} grade is{" "}
                    {exam.finalGrade} %
                  </div>
                </div>
              </div>
            );
            break;

          case "Not Evaluated":
            resultbadge = (
              <div className="container p-0 pt-3">
                <div className="alert-warning  p-2  rounded d-flex">
                  <div className="text-dark ms-2 mt-1">
                    {$c.userIsAdmin()
                      ? "Test hasn't been evaluated yet!"
                      : "Your test hasn't been evaluated yet! You will receive an  email when the evaluation is completed."}
                  </div>
                </div>
              </div>
            );
            break;
        }
      } else {
        switch (exam.status) {
          default:
            resultbadge = (
              <div className="container p-0 pt-3">
                <div className="alert-info  p-2  rounded d-flex">
                  <div className="text-dark ms-2 mt-1">
                    Your grade is {exam.finalGrade} %
                  </div>
                </div>
              </div>
            );
            break;

          case "Not Evaluated":
            resultbadge = (
              <div className="container p-0 pt-3">
                <div className="alert-warning  p-2  rounded d-flex">
                  <div className="text-dark ms-2 mt-1">
                    Your test hasn't been evaluated yet! You will receive an
                    email when the evaluation is completed.
                  </div>
                </div>
              </div>
            );
            break;
        }
      }

      return (
        <>
          <header className="fixed-top">
            <nav className="navbar navbar-expand-md bg-white  top-navbar">
              <div className="container-fluid viewport-padding  color-strong-grey">
                <span className="display-5 mb-0">
                  {$c.createAvatar(
                    exam.participant.profileImage,
                    exam.participant.firstName,
                    exam.participant.lastName
                  )}
                </span>
                <div className="text-md-end mt-auto mb-auto">
                  {$c.userIsAdmin() && !isMobile ? (
                    <button
                      className="btn btn-purple btn-sm me-3"
                      onClick={(e) => {
                        $c.downloadFile(
                          "/exam/user-exam/".concat(
                            exam._id,
                            "/downloadResult?"
                          ),
                          "Exam results"
                        );
                      }}
                    >
                      Download
                    </button>
                  ) : (
                    ""
                  )}
                  Submitted on:{" "}
                  <strong>{$c.formatDateTime(exam.finalizedOn)}</strong>
                </div>
              </div>
            </nav>
          </header>
          <main className="container-fluid viewport-padding bg-light-grey pb-5">
            <div className="wrapper" style={{ paddingTop: "3.563rem" }}>
              <div className="notification"></div>
              {resultbadge}

              <div className="container p-3 rounded border mb-3 mt-2 bg-white">
                <h1 className="display-4">{parse(exam.title)}</h1>
                <label>
                  <strong>Instructions</strong>
                </label>
                <div>{parse(exam.instructions)}</div>
              </div>
              <div className="container p-3 rounded border mb-3 mt-3 bg-white">
                <div>
                  {exam.questions?.map((el, idx) => {
                    let borderBottom = "";

                    if (idx < exam.questions.length - 1) {
                      borderBottom = "border-bottom-1";
                    }

                    let questionIsCorrect = true;

                    el.answers.map((ans) => {
                      if (el.type == "multipleChoice") {
                        const answersUserAnswer =
                          ans?.userAnswer == "true" || ans?.userAnswer == true
                            ? true
                            : false;
                        const answersIsCorrect =
                          ans?.isCorrect == "true" || ans?.isCorrect == true
                            ? true
                            : false;

                        if (answersUserAnswer !== answersIsCorrect) {
                          questionIsCorrect = false;
                        }
                      }
                    });

                    return (
                      <div className={"pt-3 pb-3 ".concat(borderBottom)}>
                        <div className="d-flex">
                          <label className="w-100 ">
                            <strong>Question {idx + 1}</strong>
                            {el.type == "multipleChoice" ? (
                              questionIsCorrect ? (
                                <div className="badge bg-success pe-2 ps-2 pt-1 pb-1 small ms-2">
                                  <small>Correct</small>
                                </div>
                              ) : (
                                <div className="badge bg-danger  pe-2 ps-2 pt-1 pb-1 small ms-2">
                                  Wrong
                                </div>
                              )
                            ) : (
                              ""
                            )}
                          </label>
                          <div
                            className="small color-grey"
                            style={{ width: "8rem" }}
                          >
                            <span>{el.weight} points</span>
                          </div>
                        </div>
                        <div>{parse(el.question)}</div>
                        {el.image ? (
                          <img
                            src={window["baseurl"](
                              "/agenda-file/data/" +
                                el.image +
                                "?type=preview&jwt=" +
                                localStorage.getItem("jwt")
                            )}
                            className="img-fluid mb-3"
                          ></img>
                        ) : (
                          ""
                        )}
                        <div className="color-grey mb-3">
                          {el.type == "multipleChoice" ? "Answers" : "Answer"}
                        </div>
                        {el.answers.map((ans) => {
                          if (el.type == "multipleChoice") {
                            let answerColor = "";

                            let checked = false;

                            let icon = <Icon.FiCheck color="white" size={20} />;

                            let userAnswerText = "";

                            const userAnswer =
                              ans?.userAnswer == "true" ||
                              ans?.userAnswer == true
                                ? true
                                : false;
                            const isCorrect =
                              ans?.isCorrect == "true" || ans?.isCorrect == true
                                ? true
                                : false;

                            if (isCorrect && userAnswer) {
                              answerColor = "#28a745";
                              checked = true;
                              userAnswerText = "Your answer";
                            } else if (isCorrect && !userAnswer) {
                              answerColor = "#28a745";
                              userAnswerText = "Correct answer";
                              checked = false;
                            }
                            if (!isCorrect && userAnswer) {
                              answerColor = "#dc3545";
                              checked = true;
                              userAnswerText = "Your answer";
                              icon = <Icon.FiXCircle color="white" size={20} />;
                            }

                            //"#28a745"

                            return (
                              <div className="mb-2">
                                <div className="d-flex">
                                  <Checkbox
                                    className="me-2"
                                    checked={checked}
                                    icon={
                                      <div
                                        style={{
                                          display: "flex",
                                          flex: 1,
                                          backgroundColor: answerColor,
                                          alignSelf: "stretch",
                                          borderColor: answerColor,
                                        }}
                                      >
                                        {icon}
                                      </div>
                                    }
                                    borderColor={answerColor}
                                    borderRadius={23}
                                    disabled
                                    style={{
                                      overflow: "hidden",
                                      backgroundColor: "#fff",
                                    }}
                                    size={23}
                                  />

                                  <div className="w-100 text-break">
                                    {ans.answer}
                                  </div>
                                  <div
                                    className="small color-grey"
                                    style={{ width: "8rem" }}
                                  >
                                    <span className="text-break">
                                      {userAnswerText}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}

                        {el.type == "essayType" ? (
                          <div className="mb-2">
                            <div className="d-flex">
                              <div className="w-100 text-break">
                                {el.answers[0]
                                  ? el.answers[0].userAnswer
                                  : "[ No answer ]"}
                                {el.mark != null ? (
                                  <div className="mt-3">
                                    <div
                                      className="alert-warning rounded p-2 text-center"
                                      style={{ width: "13rem" }}
                                    >
                                      Instructor's grade:{" "}
                                      <strong>{Number(el.mark)}/10</strong>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </main>
        </>
      );
    }

    return "";
  }
}

export default TestResult;
