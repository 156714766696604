import { Component } from "react";
import { withRouter } from "react-router";
import $f from "../../../src";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    const { data, module, view } = this.props;

    this.setState({ loaded: true, data, module, view });
  }

  render() {
    if (this.state.loaded) {
      return (
        <div className="header row mb-2">
          <div className="col-md-8">
            <button
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasExample"
              aria-controls="offcanvasExample"
              className="display-lt-md-block mb-3 btn btn-sm btn-white"
            >
              Menu
            </button>

            <div className="d-flex">
              {/* <div
                role="button"
                className="m-auto"
                onClick={() => {
                  this.props.history.goBack();
                }}
              >
                <i className="fas fa-chevron-left me-3  fa-lg color-grey"></i>
              </div> */}
              <h1 className="display-1-no-line-height header-ellipsis">
                {$f.getValue(this.state.data.title)}
              </h1>
              {$f.getValue(this.state.data.options)}
            </div>
            <h5 className="display-5 color-grey">
              {$f.getValue(this.state.data.subTitle)}
            </h5>
          </div>
          <div className="col-md-4 text-md-end mt-auto mb-auto">
            <div>{$f.getValue(this.state.data.buttons)}</div>
          </div>
        </div>
      );
    }

    return "";
  }
}

export default withRouter(Header);
