import parse from "html-react-parser";
import { Component } from "react";
import "react-widgets/styles.css";
import Data from "../../modules/data/data";
import moment from "moment";

class ReadingDuration extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.changeHours = this.changeHours.bind(this);
    this.changeMinutes = this.changeMinutes.bind(this);
    this.parentChange = function () {};

    this.state = {
      loaded: false,
      value: {},
    };
  }

  onChange(e) {}

  componentDidMount() {
    let events = [];

    Object.keys(this.props.property.events)?.forEach((element, idx) => {
      if (!element.toLowerCase().startsWith("onchange")) {
        this[element] = this.props.property.events[element];
        this[element] = this[element].bind(this);
        events[element] = this[element];
        if (element == "overrideOnChange") {
          this.onChange = this.props.property.events[element];
          this.onChange = this.onChange.bind(this);
          events["onChange"] = this.onChange;
        }
      } else {
        this["customOnChange"] = this.props.property.events[element];
        this["customOnChange"] = this["customOnChange"].bind(this);
      }
    });

    if (this.props.property.parentChange) {
      this.parentChange = this.props.property.parentChange.bind(this);
    }

    this.setState({
      loaded: true,
      events: events,
      attributes: { ...this.props.property.attributes },
      value:
        this.props.value.hours || this.props.value.minutes
          ? {
              hours: this.props.value.hours,
              minutes: this.props.value.minutes,
            }
          : {
              hours: 0,
              minutes: 0,
            },
      editable: this.props.property.editable === false ? false : true,
      useCustomUI: this.props.property.useCustomUI,
    });
  }

  changeMinutes(e) {
    this.setState({
      value: { minutes: e.target.value, hours: this.state.value.hours },
    });

    const _this = this;
    setTimeout(function () {
      _this.onChange(_this);
    }, 0);

    setTimeout(function () {
      _this.parentChange({
        hours: _this.state.value.hours,
        minutes: e.target.value,
      });
    }, 0);
  }

  changeHours(e) {
    this.setState({
      value: { hours: e.target.value, minutes: this.state.value.minutes },
    });

    const _this = this;
    setTimeout(function () {
      _this.onChange(_this);
    }, 0);

    setTimeout(function () {
      _this.parentChange({
        hours: e.target.value,
        minutes: _this.state.value.minutes,
      });
    }, 0);
  }

  editable() {
    return (
      <>
        <div className="d-flex w-75">
          <select
            className="form-control form-select  time-picker "
            onChange={this.changeHours}
            defaultValue={this.state.value.hours.toString()}
          >
            {Data.ReadingDuration.hours.data.map((el) => (
              <option value={el}>{el.concat(" hours")}</option>
            ))}
          </select>
          <span className="pe-2 ps-2 pt-1">and</span>
          <select
            className="form-control form-select   time-picker"
            onChange={this.changeMinutes}
            defaultValue={this.state.value.minutes.toString()}
          >
            {Data.ReadingDuration.minutes.data.map((el) => (
              <option value={el}>{el.concat(" minutes")}</option>
            ))}
          </select>
        </div>
      </>
    );
  }

  nonEditable() {
    let out = "Self paced";
    if (this.state.value.hours) {
      if (this.state.value.hours === 1) {
        out = this.state.value.hours + " hour";
      } else {
        out = this.state.value.hours + " hours";
      }
    }

    if (this.state.value.minutes) {
      if (out) {
        out = out.concat(" and ");
      }

      out = this.state.value.minutes + " minutes";
    }

    return out;
  }

  customUI() {}

  render() {
    if (this.state.loaded) {
      if (this.state.useCustomUI) {
        return this.customUI();
      } else if (this.state.editable) {
        return this.editable();
      } else {
        return this.nonEditable();
      }
    }

    return "";
  }
}

export default ReadingDuration;
