import { Component } from "react";
import { withRouter } from "react-router";
import $c from "../../../modules/functions";
import $f from "../../../src";
import { Link } from "react-router-dom";
import moment from "moment";
import EmptyStates from "../../../modules/emptyStates";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import ProgressBar from "react-bootstrap/ProgressBar";

class Homepage extends Component {
  constructor(props) {
    super(props);

    this.libraryData = this.libraryData.bind(this);
    this.planData = this.planData.bind(this);
    this.spacesData = this.spacesData.bind(this);
    this.upcomingEventsData = this.upcomingEventsData.bind(this);
    this.todayEventsData = this.todayEventsData.bind(this);

    this.state = {
      loaded: false,
      library: {
        ownedByMeFiles: "loading...",
        libraryFilesCount: "loading...",
        favoriteFiles: "loading...",
      },
      upcomingEvents: [],
      todayEvents: [],
      spaces: [],
      plan: {},
    };
  }

  componentDidMount() {
    const { data, module, view, specialProperties } = this.props;

    let templateConfiguration = view.template.configuration;

    const innerTemplates = ["header", "top", "bottom"];

    innerTemplates.map((itm, idx) => {
      let tmp = templateConfiguration[itm + "Template"]
        ? templateConfiguration[itm + "Template"]
        : () => {};
      this[itm + "Template"] = tmp.bind(this);
    });

    this.setState({ specialProperties, data, loaded: true, view, module });

    this.libraryData();
    this.spacesData();
    if ($c.userIsAdmin()) {
      this.planData();
    }
    this.todayEventsData();
    this.upcomingEventsData();
  }

  todayEventsData() {
    let _this = this;
    $f.fetch(
      "/event/all?filters=[{%22operator%22:%22is%22,%22key%22:%22event%22,%22value%22:%22today%22}]",
      "GET",
      null,
      {},
      {},
      (resp) => {
        try {
          _this.setState({ todayEvents: resp.data.data });
        } catch (e) {}
      }
    );
  }

  upcomingEventsData() {
    let _this = this;
    $f.fetch(
      "/event/all?filters={filters}",
      "GET",
      null,
      {},
      {
        filters: () => {
          return JSON.stringify([
            { value: "exams,trainings,meetings,streams" },
            {
              key: "to",
              operator: "gt",
              value: moment().add("1", "days").utc(),
            },
            {
              key: "to",
              operator: "lt",
              value: moment().add("7", "days").utc(),
            },
          ]);
        },
      },
      (resp) => {
        try {
          _this.setState({ upcomingEvents: resp.data.data });
        } catch (e) {}
      }
    );
  }

  eventRow(el, idx, data) {
    const border = idx == 2 || idx == data.length - 1 ? "" : "border-bottom";

    switch (el.type) {
      case "exam":
        let url = "/event/test/".concat(el._id);

        if (el.training) {
          if (el.training._id) {
            url = "/event/training/".concat(el.training._id, "/results");
          }

          if ($c.userIsInstructor()) {
            url = "/event/training/".concat(
              el.training._id,
              "/module/",
              el.module._id,
              "/session/",
              el.session._id
            );
          }
        }

        return (
          <Link to={url} className={"pb-2 pt-2 d-flex ".concat(border)}>
            <div className="d-inline-flex">
              <span className="m-auto d-block" style={{ width: "30px" }}>
                {$c.getTrainingIcon("test")}
              </span>
            </div>
            <div className="ms-2">
              <span className="d-block  mt-1">
                <strong>{$c.testGrade(el.gradePass, "label")} test:</strong>{" "}
                {el.title}
              </span>
              <span className="color-grey d-block  mt-1">
                {$c.formatDateFromTo(el.from, el.to)}
              </span>
            </div>
          </Link>
        );
        break;

      case "meeting":
        return (
          <Link
            to={"/event/meeting/".concat(el._id)}
            className={"pb-2 pt-2 d-flex ".concat(border)}
          >
            <div className="d-inline-flex">
              <span className="m-auto d-block" style={{ width: "30px" }}>
                {$c.getTrainingIcon("videoconference")}
              </span>
            </div>
            <div className="ms-2">
              <span className="d-block  mt-1">
                <strong>Meeting:</strong> {el.title}
              </span>
              <span className="color-grey d-block  mt-1">
                {$c.formatDateFromTo(el.from, el.to)}
              </span>
            </div>
          </Link>
        );
        break;

      case "conference":
        return (
          <Link
            to={"/liveclass/v-".concat(el._id)}
            className={"pb-2 pt-2 d-flex ".concat(border)}
          >
            <div className="d-inline-flex">
              <span className="m-auto d-block" style={{ width: "30px" }}>
                {$c.getTrainingIcon("liveclass")}
              </span>
            </div>
            <div className="ms-2">
              <span className="d-block mt-1">
                <strong>Live class:</strong> {el.title}
              </span>
              <span className="color-grey d-block mt-1">
                {$c.formatDateFromTo(el.from, el.to)}
              </span>
            </div>
          </Link>
        );
        break;

      case "stream":
        return (
          <Link
            to={"/event/conference/".concat(el._id)}
            className={"pb-2 pt-2 d-flex ".concat(border)}
          >
            <div className="d-inline-flex">
              <span className="m-auto d-block" style={{ width: "30px" }}>
                {$c.getTrainingIcon("liveclass")}
              </span>
            </div>
            <div className="ms-2">
              <span className="d-block  mt-1">
                <strong>Conference:</strong> {el.title}
              </span>
              <span className="color-grey d-block  mt-1">
                {$c.formatDateFromTo(el.from, el.to)}
              </span>
            </div>
          </Link>
        );
        break;
    }
  }

  events() {
    const _todayEvents = [];

    this.state.todayEvents.forEach((el, idx) => {
      //  if (idx < 3) {
      _todayEvents.push(this.eventRow(el, idx, this.state.todayEvents));
      //  }
    });

    if (_todayEvents.length < 1) {
      _todayEvents.push(EmptyStates.homepage.today());
    }

    const _upcomingEvents = [];

    this.state.upcomingEvents.forEach((el, idx) => {
      //   if (idx < 3) {
      _upcomingEvents.push(this.eventRow(el, idx, this.state.upcomingEvents));
      //   }
    });

    if (_upcomingEvents.length < 1) {
      _upcomingEvents.push(EmptyStates.homepage.next7days());
    }

    return (
      <div className="card  h-100">
        <div className="card-body">
          <div className="d-flex">
            <h2 className="display-3 d-block w-75">Upcoming events</h2>
            <div className="w-25 text-end m-auto">
              <div className="">
                <Link to={"/events/calendar"} className=" btn btn-white btn-sm">
                  View all
                </Link>
              </div>
            </div>
          </div>
          <ul className="nav nav-tabs navbar-expand">
            <li className="nav-item ">
              <span
                className="nav-link color-strong-grey nav-ellipsis  d-block active"
                data-bs-toggle="tab"
                data-bs-target="#homeage-events-today-tab"
                role="button"
              >
                Today ({this.state.todayEvents.length})
              </span>
            </li>
            <li className="nav-item ">
              <span
                className="nav-link color-strong-grey d-block nav-ellipsis"
                data-bs-toggle="tab"
                data-bs-target="#homeage-events-next-tab"
                role="button"
              >
                Next 7 days ({this.state.upcomingEvents.length})
              </span>
            </li>
          </ul>
          <div className="tab-content mt-3 ">
            <div
              className="tab-pane fade active show"
              id="homeage-events-today-tab"
              role="tabpanel"
            >
              <PerfectScrollbar style={{ maxHeight: "13rem" }}>
                {_todayEvents}
              </PerfectScrollbar>
            </div>
            <div
              className="tab-pane fade "
              id="homeage-events-next-tab"
              role="tabpanel"
            >
              <PerfectScrollbar style={{ maxHeight: "14rem" }}>
                {_upcomingEvents}
              </PerfectScrollbar>
            </div>
          </div>
        </div>
      </div>
    );
  }

  planData() {
    let _this = this;

    $f.fetch(
      "/company/{company}/getInfoForDashboard",
      "GET",
      null,
      {},
      {
        company: () => {
          return $c.getUserProperty("companyId");
        },
      },
      (resp) => {
        try {
          _this.setState({ plan: resp.data.data[0] });
        } catch (e) {}
      }
    );
  }

  plan() {
    return (
      <div className="card h-100">
        <div className="card-body">
          <h2 className="display-3 ">Plan</h2>
          <span className=" color-grey">
            {this.state.plan?.plan?.type} subscription | Expires on{" "}
            {this.state.plan?.plan?.expires
              ? moment(this.state.plan.plan.expires).format("ddd DD MMM YYYY")
              : ""}
            <br></br>
            Server location: {this.state.plan?.plan?.serverLocation}
          </span>
          <div className=" color-grey">
            <div className="p-2">
              <div className="mb-1">
                <img
                  src="/uni/svg/user.svg"
                  style={{ width: "21px" }}
                  className="me-2 filter-grey"
                ></img>{" "}
                {this.state.plan?.currentUsers} /{" "}
                {$c.numberWithCommas(this.state.plan?.totalUsers)} registered
                users
              </div>
              <ProgressBar
                variant="purple"
                now={this.state.plan?.currentUsers}
                min={0}
                max={this.state.plan?.totalUsers}
              ></ProgressBar>
            </div>

            <div className="p-2">
              <div className="mb-1">
                <img
                  src="/uni/svg/folder_storage.svg"
                  style={{ width: "21px" }}
                  className="me-2 filter-grey"
                ></img>{" "}
                {parseInt(this.state.plan?.currentFileStorage)} /
                {$c.numberWithCommas(this.state.plan?.fileStorage)}GB storage
                used
              </div>
              <ProgressBar
                variant="purple"
                now={this.state.plan?.currentFileStorage}
                min={0}
                max={this.state.plan?.fileStorage}
              ></ProgressBar>
            </div>

            <div className="p-2">
              <div className="mb-1">
                <img
                  src="/uni/svg/videocall.svg"
                  style={{ width: "21px" }}
                  className="me-2 filter-grey"
                ></img>{" "}
                {this.state.plan?.currentTimeForConferences} /
                {$c.numberWithCommas(this.state.plan?.totalTimeForConferences)}{" "}
                hours used
              </div>
              <ProgressBar
                variant="purple"
                now={this.state.plan?.currentTimeForConferences}
                min={0}
                max={this.state.plan?.totalTimeForConferences}
              ></ProgressBar>
            </div>

            <div className="p-2">
              <div className="mb-1">
                <img
                  src="/uni/svg/stream_overiew_dashboard.svg"
                  style={{ width: "21px" }}
                  className="me-2 filter-grey"
                ></img>{" "}
                {this.state.plan?.currentTimeForStreaming} /
                {$c.numberWithCommas(this.state.plan?.totalTimeForStreaming)}{" "}
                hours used
              </div>
              <ProgressBar
                variant="purple"
                now={this.state.plan?.currentTimeForStreaming}
                min={0}
                max={this.state.plan?.totalTimeForStreaming}
              ></ProgressBar>
            </div>
          </div>
        </div>
      </div>
    );
  }

  spacesData() {
    let _this = this;

    $f.fetch("/root-spaces", "GET", null, {}, {}, (resp) => {
      try {
        _this.setState({ spaces: resp.data.data });
      } catch (e) {}
    });
  }

  spaces() {
    const spacesArray = [];

    this.state.spaces.forEach((el, idx) => {
      if (idx < 3) {
        const border =
          idx == 2 || idx == this.state.spaces.length - 1
            ? ""
            : "border-bottom";

        spacesArray.push(
          <div className={"pb-2 pt-2 ".concat(border)}>
            <Link to={"/space/".concat(el._id)}>{el.name}</Link>
          </div>
        );
      }
    });

    if (spacesArray.length < 1) {
      spacesArray.push(EmptyStates.homepage.spaces());
    }

    return (
      <div className="card h-100">
        <div className="card-body">
          <div className="d-flex">
            <h2 className="display-3 w-75 ">
              Spaces ({this.state.spaces.length})
            </h2>
            <div className="w-25 text-end m-auto">
              <div className="">
                <Link to={"/spaces"} className=" btn btn-white btn-sm">
                  View all
                </Link>
              </div>
            </div>
          </div>

          {spacesArray}
        </div>
      </div>
    );
  }

  libraryData() {
    let _this = this;

    $f.fetch("/files/dashboard/info", "GET", null, {}, {}, (resp) => {
      try {
        _this.setState({ library: resp.data.data[0] });
      } catch (e) {}
    });
  }

  library() {
    if (
      !this.state.library.ownedByMeFiles &&
      !this.state.library.libraryFilesCount &&
      !this.state.library.favoriteFiles
    ) {
      return (
        <div className="card h-100">
          <div className="card-body">
            <h2 className="display-3 ">Library</h2>
            {EmptyStates.homepage.libary()}
          </div>
        </div>
      );
    }

    return (
      <div className="card h-100">
        <div className="card-body">
          <h2 className="display-3 ">Library</h2>
          <div className="border-bottom pb-2 pt-2">
            <Link to={"/files"}>
              {this.state.library.ownedByMeFiles} personal files
            </Link>
          </div>
          <div className="border-bottom pb-2 pt-2">
            <Link to={"/files/library"}>
              {this.state.library.libraryFilesCount} company files
            </Link>
          </div>
          <div className=" pb-2 pt-2">
            <Link to={"/files/favorites"}>
              {this.state.library.favoriteFiles} favorites
            </Link>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.loaded) {
      if ($c.userIsAdmin()) {
        return (
          <div className="row h-100">
            <div className="col-md-8 p-2">{this.events()}</div>
            <div className="col-md-4 p-2">{this.plan()}</div>
            <div className="col-md-6 p-2">{this.spaces()}</div>
            <div className="col-md-6 p-2"> {this.library()}</div>
          </div>
        );
      } else {
        return (
          <div className="row h-100">
            <div className="col-md-12 p-2">{this.events()}</div>
            <div className="col-md-6 p-2">{this.spaces()}</div>
            <div className="col-md-6 p-2"> {this.library()}</div>
          </div>
        );
      }
    }

    return "";
  }
}

export default withRouter(Homepage);
