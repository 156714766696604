import { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import $f from "../../../src";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

class LeftMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    const { data, module, view } = this.props;

    this.setState({ loaded: true, data, module, view });

    document.body.style.paddingTop = "6.7rem";
  }

  render() {
    if (this.state.loaded) {
      let backToListBtn = [];

      if (window.location.pathname.startsWith("/event/")) {
        backToListBtn.push(
          <span
            className="small color-grey mb-3 d-block"
            role="button"
            onClick={(e) => {
              this.props.history.push(
                "/events/".concat($f.getQueryPath(2), "s")
              );
            }}
            data-bs-dismiss="offcanvas"
          >
            <i className="fas fa-chevron-left me-2   color-grey "></i>
            Back to list
          </span>
        );
      }

      return (
        <div
          className="left-menu offcanvas"
          id="offcanvasExample"
          key={$f.key()}
        >
          <div
            className="display-5 mb-3 left-menu-close-link "
            data-bs-dismiss="offcanvas"
            key={$f.key()}
          >
            Close
          </div>
          {this.state.data.map((itm) => {
            return (
              <>
                <span
                  className="d-block display-4-no-margin mb-2"
                  key={$f.key()}
                >
                  {itm.title.component}
                </span>
                {backToListBtn}

                <PerfectScrollbar
                  style={{ maxHeight: "40rem" }}
                  ref={(ref) => {
                    this._scrollBarRef = ref;
                  }}
                >
                  <ul
                    className="border-left-1 ms-0 ps-0"
                    style={{ overflowY: "auto" }}
                    key={$f.key()}
                  >
                    {itm.children.value?.map((child) => {
                      const hidden = $f.getValue(child.hidden);

                      if (hidden) {
                        return "";
                      }

                      const secChild = child.children?.map((secChild) => {
                        const hidden = $f.getValue(secChild.hidden);

                        if (hidden) {
                          return "";
                        }

                        const active = secChild.isActive() ? "active" : "";

                        if (child.auth) {
                          if (!$f.getValue(child.auth)) {
                            return "";
                          }
                        }

                        return (
                          <li
                            className={active}
                            key={$f.key()}
                            data-bs-dismiss="offcanvas"
                          >
                            <Link
                              className="nav-link text-break"
                              to={secChild.url}
                              key={$f.key()}
                            >
                              {secChild.title}
                            </Link>
                          </li>
                        );
                      });

                      const active = child.isActive() ? "active" : "";

                      if (child.url) {
                        return (
                          <li
                            className={active}
                            key={$f.key()}
                            data-bs-dismiss="offcanvas"
                          >
                            <Link
                              className="nav-link"
                              to={child.url}
                              key={$f.key()}
                            >
                              {child.title}
                            </Link>

                            {secChild.length > 0 ? (
                              <ul className="ms-0 ps-0" key={$f.key()}>
                                {secChild}
                              </ul>
                            ) : (
                              ""
                            )}
                          </li>
                        );
                      } else {
                        let ulID = $f.key();

                        const collapsed = child.isCollapsed
                          ? child.isCollapsed()
                            ? "collapsed"
                            : "collapse"
                          : "collapse";

                        return (
                          <li className={active} key={$f.key()}>
                            <span
                              className="nav-link"
                              key={$f.key()}
                              data-bs-toggle="collapse"
                              data-bs-target={"#".concat(ulID)}
                              role="button"
                              onClick={() => {
                                let _this = this;

                                setTimeout(function () {
                                  _this._scrollBarRef.updateScroll();
                                }, 150);
                              }}
                            >
                              {child.title}
                            </span>

                            {secChild.length > 0 ? (
                              <ul
                                className={"ms-0 ps-0 ".concat(collapsed)}
                                key={$f.key()}
                                id={ulID}
                              >
                                {secChild}
                              </ul>
                            ) : (
                              ""
                            )}
                          </li>
                        );
                      }
                    })}
                  </ul>
                </PerfectScrollbar>
              </>
            );
          })}
        </div>
      );
    }

    return "";
  }
}

export default withRouter(LeftMenu);
