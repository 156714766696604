import React, { Component } from "react";
import parse from "html-react-parser";
import $c from "../../../../modules/functions";
import $f from "../../../../src";
import { Link } from "react-router-dom";
import JoinEventButton from "../../../../types/joinEventButton/joinEventButton";
import Modals from "../../../../modules/modals";

class TrainingSessionOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loaded: false,
      training: {},
    };
  }

  componentDidMount() {
    const { data, module, view, specialProperties } = this.props;

    let templateConfiguration = view.template.configuration;

    const innerTemplates = ["header"];

    innerTemplates.map((itm, idx) => {
      let tmp = templateConfiguration[itm + "Template"]
        ? templateConfiguration[itm + "Template"]
        : () => {};
      this[itm + "Template"] = tmp.bind(this);
    });

    let trainingModule = null;

    let training = this.props.response.data[0];

    training?.modules.forEach((el) => {
      if (el._id == $f.getQueryPath(5)) {
        trainingModule = el;
      }
    });

    let session = null;

    trainingModule?.sessions?.forEach((el) => {
      if (el._id == $f.getQueryPath(7)) {
        session = el;
      }
    });

    this.setState({
      specialProperties,
      data,
      loaded: true,
      view,
      module,
      training: training,
      trainingModule,
      session,
    });
  }

  render() {
    if (this.state.loaded) {
      const data = this.state.data[0];

      const videoConference = this.state.session.videoConference
        ? this.state.session.videoConference
        : [];

      const tests = this.state.session.exam ? this.state.session.exam : [];

      let learningMaterial = [];
      let recordings = [];

      if (this.state.session.reading) {
        this.state.session.reading.forEach((el) => {
          el.type = "reading";
          learningMaterial.push(el);
        });
        // learningMaterial = learningMaterial.concat(this.state.session.reading);
      }
      if (this.state.session.videoPresentation) {
        this.state.session.videoPresentation.forEach((el) => {
          if (el.recording) {
            el.type = "recording";
            recordings.push(el);
          } else {
            el.type = "video";
            learningMaterial.push(el);
          }
        });
        // learningMaterial = learningMaterial.concat(
        //   this.state.session.videoPresentation
        // );
      }

      return (
        <React.Fragment>
          {this.headerTemplate()}
          <div className="display-3-no-bold ">
            {this.state.trainingModule.title}
          </div>
          <div className="display-5 color-grey">
            {this.state.trainingModule.description
              ? parse(this.state.trainingModule.description)
              : ""}
          </div>
          {this.state.specialProperties.sessions.component}
          <label className=" mt-3 p-2 rounded bg-light-grey-alt ">
            {this.state.session.title}
          </label>
          {this.state.session?.instructors.length > 0 ? (
            <div className="mt-3 mb-4">
              <div className="display-4-no-margin  mt-3 mb-1">Instructors</div>
              {this.state.session?.instructors?.map((el) => {
                return (
                  <div
                    className="me-4 d-inline-block pb-3"
                    role={!el.hasPrivateInfo ? "button" : "span"}
                    onClick={() => {
                      if (!el.hasPrivateInfo) {
                        Modals.contacts.userProfile({
                          userId: el._id,
                          bioOnly: true,
                        });
                      }
                    }}
                  >
                    {$c.createAvatar(
                      el.profileImage,
                      el.firstName,
                      el.lastName,
                      "avatar-default"
                    )}
                  </div>
                );
              })}
            </div>
          ) : (
            ""
          )}

          {videoConference.length > 0 ? (
            <div>
              <div className="display-4-no-margin  mt-3">
                Live classes
                {$c.userIsSimpleMember() ? (
                  <span className="color-grey font-normal ms-2">
                    (
                    {
                      videoConference.filter((el) => {
                        return el.done;
                      }).length
                    }
                    /{videoConference.length} attended)
                  </span>
                ) : (
                  ""
                )}
              </div>
              {videoConference.map((el, idx) => {
                let borderBottom = "";

                if (idx + 1 < videoConference.length) {
                  borderBottom = "border-bottom-1";
                }

                const iconType = el.done ? "completed" : "videoconference";

                return (
                  <div className={"d-flex pb-3 pt-3 ".concat(borderBottom)}>
                    <div className="d-flex w-100">
                      <div className="d-inline-flex">
                        {$c.getTrainingIcon(iconType)}
                      </div>
                      <div className="ms-2">
                        <span className=" d-block">
                          <strong>Live class</strong>:
                          <span className="ms-1">{el.title}</span>
                        </span>
                        <span className="color-grey d-block">
                          {$c.formatDateFromTo(el.from, el.to)}
                        </span>
                      </div>
                    </div>

                    <div className="text-end " style={{ width: "12rem" }}>
                      <JoinEventButton
                        data={el}
                        property={{
                          events: {
                            onClick: function () {
                              window.open(
                                "/liveclass/v-".concat(el._id),
                                "_blank"
                              );
                              // window.location.replace(
                              //   "/liveclass/v-".concat(el._id)
                              // );
                            },
                          },
                          attributes: {
                            className: "btn btn-purple btn-sm m-auto",
                          },
                          caption: (args) => {
                            return "Join class";
                          },
                          configuration: {
                            eventNotStartedText: (
                              <span className="color-purple">
                                <strong>Class starts soon</strong>
                              </span>
                            ),
                            eventHasEndedText: (
                              <span className="text-danger">
                                <strong>Class has ended</strong>
                              </span>
                            ),
                            fromDateProperty: "from",
                            endDateProperty: "to",
                            extraStartTime: 0,
                            extraEndTime: 0,
                            eventInterval: 1000,
                            eventIntervalFunction: () => {},
                          },
                        }}
                      ></JoinEventButton>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            ""
          )}
          {learningMaterial.length > 0 ? (
            <div>
              <div className="display-4-no-margin  mt-3">
                Learning material
                {$c.userIsSimpleMember() ? (
                  <span className="color-grey font-normal ms-2">
                    (
                    {
                      learningMaterial.filter((el) => {
                        return el.done;
                      }).length
                    }
                    /{learningMaterial.length} viewed)
                  </span>
                ) : (
                  ""
                )}
              </div>
              {learningMaterial.map((el, idx) => {
                let borderBottom = "";

                if (idx + 1 < learningMaterial.length) {
                  borderBottom = "border-bottom-1 ";
                }

                let iconType = "";
                let materialType = "Reading";

                switch (el.mimetype) {
                  case "application/pdf":
                    iconType = "pdf";
                    break;

                  case "application/epub+zip":
                    iconType = "epub";
                    break;

                  case "video/mp4":
                    iconType = "video";
                    materialType = "Video";
                    break;
                }

                iconType = el.done ? "completed" : iconType;

                return (
                  <Link to={"/files/preview/t-".concat(el._id)}>
                    <div className={"d-flex pb-3 pt-3 ".concat(borderBottom)}>
                      <div className="d-inline-flex">
                        {$c.getTrainingIcon(iconType)}
                      </div>
                      <div className="ms-2">
                        <span className=" d-block">
                          <strong>{materialType}</strong>:
                          <span className="ms-1 ">{el.title}</span>
                        </span>
                        <span className="color-grey d-block">
                          {$c.userIsSimpleMember()
                            ? el.remainingMiliseconds
                              ? $c.trainingMaterialRemainingTime(
                                  el.remainingMiliseconds
                                )
                              : $c.traininingMaterialDuration(el.duration)
                            : $c.traininingMaterialDuration(el.duration)}
                        </span>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          ) : (
            ""
          )}
          {tests.length > 0 ? (
            <div>
              <div className="display-4-no-margin  mt-3">
                Tests
                {$c.userIsSimpleMember() ? (
                  <span className="color-grey font-normal ms-2">
                    (0/{tests.length} attended)
                  </span>
                ) : (
                  ""
                )}
              </div>
              {tests.map((el, idx) => {
                let borderBottom = "";

                if (idx + 1 < tests.length) {
                  borderBottom = "border-bottom-1";
                }

                let isGraded = $c.testGrade(el.passingGrade, "isGraded");

                const iconType = el.done ? "completed" : "test";
                return (
                  <div className={"d-flex pb-3 pt-3 ".concat(borderBottom)}>
                    <div className="d-flex w-100">
                      <div className="d-inline-flex">
                        {$c.getTrainingIcon(iconType)}
                      </div>
                      <div className="ms-2">
                        <span className=" d-block">
                          <strong>
                            {isGraded ? "Graded" : "Practice"} test
                          </strong>
                          :<span className="ms-1">{el.title}</span>
                        </span>
                        <span className="color-grey d-block">
                          {$c.formatDateFromTo(el.from, el.to)}
                        </span>
                      </div>
                    </div>
                    <div className="text-end " style={{ width: "12rem" }}>
                      {$c.userIsSimpleMember() ? (
                        <JoinEventButton
                          data={el}
                          property={{
                            events: {
                              onClick: function () {
                                const userExamId = el?.userExamInfo?._id;
                                if (userExamId) {
                                  window.location.replace(
                                    "/test/".concat(el._id)
                                  );
                                } else {
                                  Modals.events.startContinueTest({
                                    props: { data: el },
                                  });
                                }
                              },
                            },
                            attributes: {
                              className: "btn btn-purple btn-sm",
                            },
                            caption: (args) => {
                              const userExamId = el.userExamInfo?._id;

                              if (userExamId) {
                                return "Continue test";
                              }
                              return "Take test";
                            },
                            configuration: {
                              eventNotStartedText: (
                                <span className="color-purple">
                                  <strong>Test starts soon</strong>
                                </span>
                              ),
                              eventHasEndedText: () => {
                                if (
                                  $c.testSubmitted(el?.userExamInfo?.status)
                                ) {
                                  switch (el?.userExamInfo?.status) {
                                    case "Not Evaluated":
                                      return (
                                        <span className="color-purple">
                                          <strong>Pending evaluation</strong>
                                        </span>
                                      );
                                      break;

                                    default:
                                      return (
                                        <button
                                          type="button"
                                          className="btn btn-purple btn-sm"
                                          onClick={() => {
                                            const userExamId =
                                              el.userExamInfo?._id;
                                            if (userExamId) {
                                              window.location.replace(
                                                "/event/test/".concat(
                                                  userExamId,
                                                  "/result"
                                                )
                                              );
                                            }
                                          }}
                                        >
                                          View results
                                        </button>
                                      );
                                      break;
                                  }
                                } else {
                                  return (
                                    <span className="text-danger">
                                      <strong>Test has ended</strong>
                                    </span>
                                  );
                                }
                              },
                              hasEnded: () => {
                                return $c.testSubmitted(
                                  el?.userExamInfo?.status
                                );
                              },
                              fromDateProperty: "from",
                              endDateProperty: "to",
                              extraStartTime: 0,
                              extraEndTime: 0,
                              eventInterval: 1000,
                              eventIntervalFunction: () => {},
                            },
                          }}
                        ></JoinEventButton>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            ""
          )}
          {recordings.length > 0 ? (
            <div>
              <div className="display-4-no-margin  mt-3">Recordings</div>
              {recordings.map((el, idx) => {
                let borderBottom = "";

                if (idx + 1 < recordings.length) {
                  borderBottom = "border-bottom-1 ";
                }

                const iconType = el.isAudio
                  ? "audiorecording"
                  : "videorecording";

                return (
                  <Link to={"/files/preview/a-".concat(el._id)}>
                    <div className={"d-flex pb-3 pt-3 ".concat(borderBottom)}>
                      <div className="d-inline-flex">
                        {$c.getTrainingIcon(iconType)}
                      </div>
                      <div className="ms-2">
                        <span className=" d-block">
                          <strong>{el.isAudio ? "Audio" : "Video"}</strong>:
                          <span className="ms-1 ">{el.title}</span>
                        </span>
                        <span className="color-grey d-block">
                          {$c.traininingMaterialDuration(el.duration)}
                        </span>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          ) : (
            ""
          )}
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default TrainingSessionOverview;
