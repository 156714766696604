import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";

class ModalWidget extends Component {
  state = {
    data: [],
    loaded: false,
    title: [],
    body: [],
    footer: [],
    show: false,
    size: "",
    showClose: true,
    overflowY: "auto",
  };

  constructor(props) {
    super(props);

    this.close = this.close.bind(this);
  }

  componentDidMount() {
    this.setState({ data: this.props.data, loaded: true });
  }

  close() {
    this.setState({
      title: [],
      body: [],
      footer: [],
      show: false,
      size: "",
      close: null,
      extra: null,
      onClose: null,
      showClose: true,
      overflowY: "auto",
    });
  }

  render() {
    let output = (
      <Modal
        show={this.state.show}
        centered={true}
        backdrop="static"
        onHide={this.close}
        size={this.state.size}
        onExit={this.close}
        onExiting={this.close}
      >
        {this.state.title ? (
          <Modal.Header className="border-0 pb-3 pt-3">
            <Modal.Title className="mb-0 w-100 pb-1 ">
              <div className="d-flex">{this.state.title}</div>
            </Modal.Title>
            {/* {this.state.showClose ? (
              <span className="bg-light-grey">
                <i className="fas fa-times ms-2" onClick={this.close}></i>
              </span>
            ) : (
              ""
            )} */}
          </Modal.Header>
        ) : (
          ""
        )}
        <Modal.Body
          className="pt-0 pb-3"
          style={{
            maxHeight: "calc(100vh - 210px)",
            overflowY: this.state.overflowY,
          }}
        >
          {this.state.body}
        </Modal.Body>
        <Modal.Footer className="bg-light-grey border-0 p-1">
          {this.state.footer}
        </Modal.Footer>
      </Modal>
    );

    if (this.state.loaded) {
      return output;
    } else {
      return "";
    }
  }
}

export default ModalWidget;
