import parse from "html-react-parser";
import { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import $c from "../../modules/functions";

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    this.setState({
      loaded: true,
    });
  }

  customUI() {}

  render() {
    if (this.state.loaded) {
      let initials = $c.getUserProperty("firstName")?.charAt(0);

      let profileImage =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAACXBIWXMAAAsSAAALEgHS3X78AAACBklEQVR4nM3Yv0tVYRzH8ROEg6UJQVxEFCWo4fMPtBQubn0gTHFx8A8QdNGCREXamoJP6CTmEA5BYLoUODtpNCg1BFlhUAS5lD/u43DvE4fbufeec/Tc8x3e8/fFA+fH8w2cc0GaCBUI3Sc0Q2iJ0CqhDUKvCT0nNEtogFB72hnOuSAp6hqhSUJbhIqEXMzeEXpIqJAJsHxazwj9SYCK6i+hhSSnGgc3Suj3GWGVHRAaJ3QhNZBQG6H1c4ZV9obQ1cRAQp2EdjPG+T4S6o4NJNRB6FODcL7PhLrqAgldJvS+wTjfDqHWesAXOeF8L6sCCQ3mjPMN/wck1EzoiwGcI7RPqKUSOGEAFm7qH5DQRUJfDaDCfSfU5IE0AIpqwAOXDWCiWvHAfQOYqH56YN6QWt2wDrxnHThqHThlHThrHThpHThiHXg7IHRkABLVCaErAaFNA5iotv2X5JEBTFRzHthTPs68QeGKhG6Gf1jXDKDCva38o77FZLuWrLsTdWlaMQBzhF5Vu9UVCP3IGfeLUEete/Fd5vfAFAn1x1l9PMgJOJ1kefS4wbgnadZvY4SOM4YdE5pItR8sI3sJ7WWE+0aor9b8usAyspXQU0KH5wQ7Ymmd3FZvdixgCNpNSEy/Dj4gNE/oetyZiYAhaDNLm7BFQh9Y/bVUZGl7ukRoiNClpLOcc8EpvMGl4+1ja/sAAAAASUVORK5CYII=";

      if ($c.getUserProperty("profileImg")) {
        profileImage = window["baseurl"](
          "/agenda-file/data/" + $c.getUserProperty("profileImg")
        ).concat("?type=preview&jwt=" + localStorage.getItem("jwt"));
        initials = "";
      }

      return (
        <div className=" dropdown d-inline-block">
          <div
            className={"d-inline-block avatar-wrapper  avatar-medium"}
            style={{ lineHeight: "normal" }}
            data-bs-toggle="dropdown"
            role="button"
          >
            <div className="d-flex">
              <span className=" me-2 ">
                <img src={profileImage} />
                <span>{initials}</span>
              </span>
            </div>
          </div>
          <ul className="dropdown-menu">
            <li className=" small" role="button">
              <Link
                to={"/user/".concat($c.getUserId())}
                className="dropdown-item"
              >
                My profile
              </Link>
            </li>
            <li
              className="dropdown-item small"
              role="button"
              onClick={() => {
                $c.logout();
              }}
            >
              Logout
            </li>
          </ul>
        </div>
      );
    }

    return "";
  }
}

export default Profile;
