import { Component } from "react";
import { withRouter } from "react-router";

class Custom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    const { data, module, view, specialProperties } = this.props;

    let templateConfiguration = view.template.configuration;

    const innerTemplates = ["header", "top", "bottom"];

    innerTemplates.map((itm, idx) => {
      let tmp = templateConfiguration[itm + "Template"]
        ? templateConfiguration[itm + "Template"]
        : () => {};
      this[itm + "Template"] = tmp.bind(this);
    });

    this.setState({ specialProperties, data, loaded: true, view, module });
  }

  render() {
    if (this.state.loaded) {
      let _this = this;
      return (
        <>
          {this.headerTemplate()}
          {this.topTemplate()}
          {this.bottomTemplate()}
        </>
      );
    }

    return "";
  }
}

export default withRouter(Custom);
