import parse from "html-react-parser";
import { Component } from "react";
import $f from "../../src";

class AttendeesSubmittedTest extends Component {
  constructor(props) {
    super(props);

    this.getUserExam = this.getUserExam.bind(this);

    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    let events = [];

    this.setState({
      loaded: true,
      events: events,
      attributes: { ...this.props.property.attributes },
      value: "0/0",
      editable: this.props.property.editable === false ? false : true,
      useCustomUI: this.props.property.useCustomUI,
    });

    this.getUserExam();
  }

  getUserExam() {
    const userExam = this.props.data._id;

    const _this = this;

    $f.fetch(
      "/exam/{id}/user-exam",
      "GET",
      null,
      {},
      { id: userExam },
      (resp) => {
        if (resp.data) {
          if (resp.data.data) {
            let i = 0;
            resp.data.data.forEach((el) => {
              if (el.finalGrade != "NaN") {
                i++;
              }
            });

            _this.setState({ value: i + "/" + resp.data.data.length });
          }
        }
      }
    );
  }

  nonEditable() {
    return typeof this.state.value === "string"
      ? parse(this.state.value)
      : this.state.value;
  }

  customUI() {}

  render() {
    if (this.state.loaded) {
      return this.state.value;
    }

    return "";
  }
}

export default AttendeesSubmittedTest;
