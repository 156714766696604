import React, { Component } from "react";
import parse from "html-react-parser";
import $c from "../../../../../modules/functions";
import $f from "../../../../../src";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import EmptyStates from "../../../../../modules/emptyStates";

class ConferencePollResults extends Component {
  constructor(props) {
    super(props);

    this.setActiveTab = this.setActiveTab.bind(this);

    this.state = {
      data: [],
      loaded: false,
      training: {},
    };
  }

  componentDidMount() {
    const { data, module, view, specialProperties, response } = this.props;

    let templateConfiguration = view.template.configuration;

    const innerTemplates = ["header"];

    innerTemplates.map((itm, idx) => {
      let tmp = templateConfiguration[itm + "Template"]
        ? templateConfiguration[itm + "Template"]
        : () => {};
      this[itm + "Template"] = tmp.bind(this);
    });

    this.fetchPolls = this.fetchPolls.bind(this);

    this.setState({
      activeTab: 0,
      specialProperties,
      data,
      loaded: true,
      view,
      module,
      response,
      polls: [],
    });

    this.fetchPolls();

    // document.body.style.paddingTop = "3.7rem";
  }

  setActiveTab(index) {
    this.setState({ activeTab: index });
  }

  fetchPolls() {
    let _this = this;
    $f.fetch(
      "/streams/{streamId}/pollAnswers",
      "GET",
      null,
      {},
      {
        streamId: () => {
          return $f.getQueryPath(3);
        },
      },
      (resp) => {
        _this.setState({ polls: resp.data.data });
      }
    );
  }

  render() {
    if (this.state.loaded) {
      const data = this.state.response.data[0];

      return (
        <React.Fragment>
          {this.headerTemplate()}
          <div className="pb-5 container-fluid me-0 ms-0 ps-0">
            <div className="display-3-no-bold ">Poll results</div>
            <div className=" color-grey mb-2">
              Poll results will appear here
            </div>

            <PerfectScrollbar>
              <ul className="nav nav-tabs navbar-expand">
                {this.state.polls.map((el, idx) => {
                  const tabId = "a-".concat(idx);

                  let active = "";

                  if (idx == this.state.activeTab) {
                    active = "active show";
                  }

                  return (
                    <li
                      className={"nav-item "}
                      key={$f.key()}
                      onClick={() => {
                        this.setActiveTab(idx);
                      }}
                    >
                      <span
                        className={"nav-link color-strong-grey nav-ellipsis ".concat(
                          active
                        )}
                        data-bs-toggle="tab"
                        data-bs-target={"#".concat(tabId)}
                        role="button"
                      >
                        {$c.formatDate(el.day)}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </PerfectScrollbar>
            <div className="tab-content mt-3 ">
              {this.state.polls.map((el, idx) => {
                const tabId = "a-".concat(idx);

                let active = "";

                if (idx == this.state.activeTab) {
                  active = "active show";
                }

                let hasPollResults = false;

                return (
                  <div
                    className={"tab-pane fade ".concat(active)}
                    id={tabId}
                    role="tabpanel"
                  >
                    <div>
                      {el.sessions.map((sitm, sidx) => {
                        return (
                          <div>
                            <div className="display-5 color-grey mb-1">
                              {sitm.title}
                            </div>

                            <div className="row">
                              {sitm.polls?.filter((el) => {
                                return el.status === "ended";
                              }).length > 0
                                ? sitm.polls
                                    ?.filter((el) => {
                                      return el.status === "ended";
                                    })
                                    .map((poll) => {
                                      hasPollResults = true;
                                      return (
                                        <div className="col-4">
                                          <div className="border rounded p-3  mt-3">
                                            <div className="strong  d-block text-break">
                                              {poll.title}
                                            </div>

                                            <div className="color-grey small">
                                              {poll.votes
                                                .toString()
                                                .concat(" ", "votes")}
                                            </div>
                                            <div>
                                              <hr className="color-grey"></hr>
                                            </div>
                                            <div>
                                              <div className="strong d-block mb-4 text-break">
                                                {poll.question.text}
                                              </div>
                                              <PerfectScrollbar
                                                style={{ maxHeight: "300px" }}
                                              >
                                                <div className="me-3">
                                                  {poll.question.answers.map(
                                                    (ans) => {
                                                      return (
                                                        <div className="mb-4">
                                                          <span className="text-break mb-2 d-block">
                                                            {ans.answer}
                                                          </span>
                                                          <ProgressBar
                                                            variant="purple"
                                                            now={ans.votes}
                                                            min={0}
                                                            style={{
                                                              "--bs-progress-height":
                                                                "0.5rem",
                                                            }}
                                                            max={
                                                              poll.votes
                                                                ? poll.votes
                                                                : 0
                                                            }
                                                          ></ProgressBar>
                                                          <div className="d-flex mt-1 color-grey small">
                                                            <span className="w-100">
                                                              {poll.votes > 0
                                                                ? Math.floor(
                                                                    (ans.votes *
                                                                      100) /
                                                                      poll.votes
                                                                  )
                                                                : 0}
                                                              %
                                                            </span>
                                                            <span className="text-nowrap">
                                                              {ans.votes
                                                                .toString()
                                                                .concat(
                                                                  " ",
                                                                  "votes"
                                                                )}
                                                            </span>
                                                          </div>
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              </PerfectScrollbar>
                                              <div className="text-end"></div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })
                                : ""}
                              {!hasPollResults
                                ? EmptyStates.events.conferences.pollResults()
                                : ""}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

//{//EmptyStates.events.conferences.pollResults()}

export default ConferencePollResults;
