import { Link } from "react-router-dom";
import $f from "../../src";
import JoinEventButton from "../../types/joinEventButton/joinEventButton";
import OptionsForHeader from "../../types/optionsForHeader/optionsForHeader";
import EmptyStates from "../emptyStates";
import $c from "../functions";
import Modals from "../modals";

const OptionsForHeaderButton = function (args) {
  let property = {
    configuration: {
      options: {
        edit: function (args) {
          if ($c.userIsAdmin()) {
            let newargs = {
              props: { data: { ...args.props.data }, view: "edit" },
              action: "update",
            };

            return {
              label: "Edit",
              onClick: function () {
                $f.getValue(Modals.events.editTest, newargs);
              },
            };
          }
        },
        copyLink: function (args) {
          return {
            label: "Copy link",
            onClick: function () {
              $c.copyToClipboard(
                window["host"]("/event/test/".concat(args.props.data._id))
              );
              $f.createNotification({
                type: "success",
                message: "Link copied!",
              });
            },
          };
        },
        downloadICS: function (args) {
          return {
            label: "Download ics",
            onClick: function () {
              const event = {
                eventType: "test",
                from: args.props.data.from,
                to: args.props.data.to,
                title: args.props.data.title,
                url: "/event/test/".concat(args.props.data._id),
              };

              $c.createICS(event);
            },
          };
        },
        // duplicate: function (args) {
        //   if ($c.userIsAdmin()) {
        //     return {
        //       label: "Duplicate",
        //       onClick: () => {
        //         $f.getValue(Modals.events.duplicateTest, args);
        //       },
        //     };
        //   }
        // },
        delete: function (args) {
          if ($c.userIsAdmin()) {
            let newargs = { ...args };
            newargs.callback = function () {
              window.location.replace("/events/tests");
            };
            return {
              label: <span className="text-danger">Delete</span>,
              onClick: function () {
                $f.getValue(Modals.events.deleteTest, newargs);
              },
            };
          }
        },
      },
    },
  };

  return (
    <span className="me-2">
      <OptionsForHeader data={args.data[0]} property={property} />
    </span>
  );
};

const Tests = {
  properties: (args) => {
    const { editable } = args;

    return {
      _id: {
        label: "",
        path: "_id",
        labelPosition: "none",
        type: "hidden",
        events: {},
        attributes: {},
      },
      title: {
        label: "Title",
        type: "text",
        editable: editable,
        events: {},
        validation: "required",
        attributes: {
          className: "form-control",
          placeholder: "Type test title",
        },
      },
      titleReadonly: {
        label: "Title",
        type: "text",
        editable: editable,
        path: "title",
        events: {},
        validation: "required",
        attributes: {
          className: "form-control ",
          readOnly: "readOnly",
          disabled: "disabled",
        },
      },
      fromTemplate: {
        type: "hidden",
        events: {},
        labelPosition: "none",
        validation: "required",
        attributes: {
          className: "form-control",
        },
      },
      optionsfortable: {
        label: "",
        type: "optionsfortable",
        configuration: {
          options: {
            open: function (args) {
              return {
                label: "Open",
                onClick: function () {
                  args.props.history.push(
                    "/event/test/".concat(args.props.data._id)
                  );
                },
              };
            },
            edit: function (args) {
              if ($c.userIsAdmin()) {
                let newargs = {
                  props: { data: { ...args.props.data } },
                  action: "update",
                };

                return {
                  label: "Edit",
                  onClick: function () {
                    $f.getValue(Modals.events.editTest, newargs);
                  },
                };
              }
            },
            copyLink: function (args) {
              return {
                label: "Copy link",
                onClick: function () {
                  $c.copyToClipboard(
                    window["host"]("/event/test/".concat(args.props.data._id))
                  );
                  $f.createNotification({
                    type: "success",
                    message: "Link copied!",
                  });
                },
              };
            },
            downloadICS: function (args) {
              return {
                label: "Download ics",
                onClick: function () {
                  const event = {
                    eventType: "test",
                    from: args.props.data.from,
                    to: args.props.data.to,
                    title: args.props.data.title,
                    url: "/event/test/".concat(args.props.data._id),
                  };

                  $c.createICS(event);
                },
              };
            },
            // duplicate: function (args) {
            //   if ($c.userIsAdmin()) {
            //     return {
            //       label: "Duplicate",
            //       onClick: () => {
            //         $f.getValue(Modals.events.duplicateTest, args);
            //       },
            //     };
            //   }
            // },
            delete: function (args) {
              if ($c.userIsAdmin()) {
                return {
                  label: <span className="text-danger">Delete</span>,
                  onClick: function () {
                    $f.getValue(Modals.events.deleteTest, args);
                  },
                };
              }
            },
          },
        },
      },

      passingGrade: {
        label: "Category",
        type: "text",
        events: {},
        editable: editable,
        attributes: {
          className: "form-control",
        },
        formatValue: (value, row) => {
          return $c.testGrade(value, "badge");

          // if (value) {
          //   return (
          //     <span
          //       className="badge bg-light-blue p-2 color-black rounded"
          //       style={{ width: "5rem" }}
          //     >
          //       Graded
          //     </span>
          //   );
          // }
          // return (
          //   <span
          //     className="badge bg-light-purple p-2 color-black rounded"
          //     style={{ width: "5rem" }}
          //   >
          //     Practice
          //   </span>
          // );
        },
      },
      date: {
        label: "Scheduled on",
        type: "text",
        editable: editable,
        events: {},
        attributes: {},
        formatValue: (value, row) => {
          return $c.formatDateFromTo(row.from, row.to);
        },
      },
      fromtoDatetime: {
        label: "Availability",
        type: "fromtodatetime",
        events: {},
        formatValue: (value, row) => {
          return { from: row.from, to: row.to };
        },
        attributes: {
          className: "form-control",
        },
      },
      examDuration: {
        label: "Duration",
        type: "duration",
        events: {},
        editable: editable,
        formatValue: (value, row) => {
          return {
            hours: row?.examDuration?.hours,
            minutes: row?.examDuration?.minutes,
          };
        },
        attributes: {
          className: "form-control",
        },
      },
      participants: {
        label: "Participants",
        type: "tags",
        editable: editable,
        configuration: {
          type: "participants",
        },
        events: {},
        attributes: {
          className: "form-control",
        },
      },
      attendeetestgrade: {
        label: "",
        type: "attendeetestgrade",
        editable: editable,
        path: "participants",
        events: {},
        attributes: {
          className: "form-control",
        },
      },
      attendeessubmittedtest: {
        label: "",
        type: "attendeessubmittedtest",
        editable: editable,
        path: "participants",
        events: {},
        attributes: {
          className: "form-control",
        },
      },
      evaluators: {
        label: "Evaluators",
        type: "tags",
        editable: editable,
        configuration: {
          type: "instructors",
        },
        events: {},
        attributes: {
          className: "form-control",
        },
      },
      participants: {
        label: "Participants",
        type: "tags",
        editable: editable,
        configuration: {
          type: "participants",
        },
        events: {},
        attributes: {
          className: "form-control",
        },
      },
      reminder: {
        label: "Reminder",
        type: "reminder",
        events: {},
        path: "reminders",
        // formatValue: (value, row) => {
        //   console.log(value);
        //   try {
        //     return JSON.stringify(row.reminders[0]);
        //   } catch (e) {
        //     return "";
        //   }
        // },
        attributes: {
          className: "form-control form-select ",
        },
      },
      instructions: {
        label: "Instructions",
        type: "editor",
        editable: editable,
        events: {},
        validation: "required",
        attributes: {
          className: "form-control",
          placeholder: "Type test instructions",
        },
      },
      passingGradeEdit: {
        label: "Passing grade (%)",
        type: "passinggrade",
        path: "passingGrade",
        events: {},
        editable: editable,
        validation: "required|min:0,num|max:100,num",
        attributes: {
          className: "form-control",
        },
      },
      questions: {
        label: "Questions",
        type: "examquestions",
        editable: editable,
        events: {},
        attributes: {},
      },
    };
  },
  specialProperties: () => {
    return {
      header: {
        type: "template",
        configuration: {
          title: "Tests",
          subTitle: "View all available tests",
          template: "Header",
          buttons: (args) => {
            if ($c.userIsMember()) {
              return [];
            }
            return [
              <button
                type="button"
                className="btn btn-purple  btn-sm  "
                onClick={() => {
                  // $f.getValue(Modals.events.editTest, {
                  //   title: "Schedule test",
                  //   api: "new",
                  //   action: "create",
                  // });

                  Modals.events.scheduleTest();
                }}
              >
                Schedule
              </button>,
            ];
          },
        },
        events: {},
      },
      pagination: {
        type: "pagination",
        configuration: {
          start: function (props) {
            return 0;
          },
          offset: "25",
          total: function (props) {
            return props.props.data.pagination.total;
          },
          current: function (props) {
            return props.props.data.pagination.currentPage;
          },
          replaceAttribute: "start",
        },
        events: {},
      },
      headerOverview: {
        type: "template",
        configuration: {
          title: (args) => {
            return args.data.data[0].title;
          },

          template: "Header",
          buttons: (args) => {
            if ($f.getQueryPath(4) == "participants") {
              return [
                OptionsForHeaderButton(args.data),
                <button
                  type="button"
                  className="btn btn-purple  btn-sm  "
                  onClick={() => {
                    $f.getValue(Modals.participants.addParticipants, {
                      title: "Add people",
                      api: "new",
                      actionType: "event/exam",
                      actionId: args.data.data[0]._id,
                      actionSection: "examParticipants",
                    });
                  }}
                >
                  Add people
                </button>,
              ];
            }

            if ($c.userIsMember()) {
              const property = {
                events: {
                  onClick: function () {
                    // window.location.replace("/test/".concat(this.props.data._id));

                    const userExamId = this.props.data?.userExamInfo?._id;
                    if (userExamId) {
                      window.location.replace(
                        "/test/".concat(this.props.data._id)
                      );
                    } else {
                      Modals.events.startContinueTest(this);
                    }
                  },
                },
                attributes: {
                  className: "btn btn-purple btn-sm",
                },
                caption: (args) => {
                  const userExamId = args.props.data?.userExamInfo?._id;

                  if (userExamId) {
                    return "Continue test";
                  }
                  return "Take test";
                },
                configuration: {
                  eventNotStartedText: (
                    <span className="color-purple">
                      <strong>Test starts soon</strong>
                    </span>
                  ),
                  eventHasEndedText: (
                    <span className="text-danger">
                      <strong>Test has ended</strong>
                    </span>
                  ),
                  fromDateProperty: "from",
                  endDateProperty: "to",
                  hasEnded: (args) => {
                    return $c.testSubmitted(
                      args.props.data?.userExamInfo?.status
                    );
                  },
                  extraStartTime: 0,
                  extraEndTime: 0,
                  eventInterval: 1000,
                  eventIntervalFunction: () => {},
                },
              };

              if (args.data?.data[0].userExamInfo) {
                if (
                  args.data?.data[0].userExamInfo.status == "Fail" ||
                  args.data?.data[0].userExamInfo.status == "Pass" ||
                  args.data?.data[0].userExamInfo.status == "No grade" ||
                  args.data?.data[0].userExamInfo.status == "Not Evaluated"
                ) {
                  return "";
                }
              }

              return [
                OptionsForHeaderButton(args.data),
                <JoinEventButton
                  data={args.data?.data[0]}
                  property={property}
                ></JoinEventButton>,
              ];
            } else {
              return [OptionsForHeaderButton(args.data)];
            }
          },
        },
        events: {},
      },
      eventsNav: {
        type: "template",
        configuration: {
          data: (resp) => {
            let output = [];
            let data = [
              { name: "All tests", filter: "" },
              // { name: "Upcoming", filter: "upcoming" },
              // { name: "Ongoing", filter: "ongoing" },
              // { name: "Ended", filter: "completed" },
            ];
            try {
              data.forEach((el, idx) => {
                let isActive = false;
                if ($f.getParameterByName("filter") == el.filter) {
                  isActive = true;
                } else {
                  if (!$f.getParameterByName("filter")) {
                    if (idx == 0) {
                      isActive = true;
                    }
                  }
                }

                output.push({
                  url:
                    idx == 0
                      ? "/events/tests"
                      : "/events/tests?filter=" + el.filter,
                  title: el.name,
                  isActive: () => {
                    return isActive;
                  },
                });
              });
            } catch (e) {}
            return output;
          },
          template: "HorizontalNavScroll",
        },
      },
      participants: {
        label: "",
        type: "module",
        configuration: {
          module: "ParticipantTable",
          view: "list",
        },
      },
      results: {
        label: "",
        type: "module",
        configuration: {
          module: "TestResults",
          view: "list",
        },
      },
    };
  },
  views: {
    list: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/event/exam?start={start}&offset={offset}{filters}";
          },
          dataPath: () => {},
          replace: {
            start: 0,
            offset: 25,
            filters: function () {
              switch ($f.getParameterByName("filter")) {
                case "completed":
                  return (
                    "&filters=" +
                    JSON.stringify([
                      { key: "to", operator: "lt", value: new Date() },
                    ])
                  );
                  break;

                case "ongoing":
                  return (
                    "&filters=" +
                    JSON.stringify([
                      { operator: "is", key: "event", value: "today" },
                    ])
                  );
                  break;

                case "upcoming":
                  return (
                    "&filters=" +
                    JSON.stringify([
                      { key: "to", operator: "gt", value: new Date() },
                    ])
                  );
                  break;

                default:
                  return "";
                  break;
              }
            },
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Table";
        },
        configuration: {
          emptyTemplate: function (arg) {
            return EmptyStates.events.tests.default();
          },
          headerTemplate: function () {
            return (
              <>
                {this.state.specialProperties.header.component}
                {this.state.specialProperties.eventsNav.component}
              </>
            );
          },
          bottomTemplate: function () {
            return this.state.specialProperties.pagination.component;
          },
          tableConfiguration: {
            headers: {
              title: {
                attributes: {
                  className: "w-50",
                },
              },
            },
            cellTemplates: {
              title: (comp, itm) => {
                return (
                  <Link
                    to={"/event/test/".concat(itm._id.value)}
                    className="color-grey d-block"
                  >
                    {comp}
                  </Link>
                );
              },
            },
          },
          includeProperties: () => {
            return ["title", "optionsfortable", "passingGrade", "date"];
          },
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
    overview: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/event/exam/{id}";
          },
          dataPath: () => {},
          replace: {
            id: () => {
              return $f.getQueryPath(3);
            },
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "TestOverview";
        },
        configuration: {
          headerTemplate: function () {
            return this.state.specialProperties.headerOverview.component;
          },
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
    detail: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/event/exam/{id}";
          },
          dataPath: () => {},
          replace: {
            id: () => {
              return $f.getQueryPath(3);
            },
          },
          headers: {},
          mapper: () => {},
        },
        new: {
          url: () => {
            return { data: [{ passingGrade: "0" }] };
          },
          dataPath: () => {},
          replace: {
            id: () => {
              return $f.getQueryPath(3);
            },
          },
          headers: {},
          mapper: () => {},
        },
        fromTemplate: {
          url: () => {
            return "/template/exam/{id}";
          },
          dataPath: () => {},
          replace: {},
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: (args) => {
          console.log(args);
          if (["participants", "results"].indexOf($f.getQueryPath(4)) >= 0) {
            return "Custom";
          }

          return "Form";
        },

        configuration: {
          topTemplate: function () {
            switch ($f.getQueryPath(4)) {
              case "participants":
                return this.state.specialProperties.participants.component;
              case "results":
                return this.state.specialProperties.results.component;
            }

            return "";
          },
          headerTemplate: function () {
            return this.state.specialProperties.headerOverview.component;
          },
          includeProperties: (args) => {
            if (
              args?.moduleReferer?.props?.property?.configuration?.api ==
              "fromTemplate"
            ) {
              return [
                "_id",
                "title",
                "fromtoDatetime",
                "examDuration",
                "participants",
                "evaluators",
                "reminder",
                "instructions",
                "passingGradeEdit",
                "questions",
              ];
            }
            if (args.data[0]._id) {
              return [
                "_id",
                "title",
                "fromtoDatetime",
                "examDuration",
                //"participants",
                "evaluators",
                "reminder",
                "instructions",
                "passingGradeEdit",
                "questions",
              ];
            } else {
              return [
                "title",
                "fromtoDatetime",
                "examDuration",
                "participants",
                "evaluators",
                "reminder",
                "instructions",
                "passingGradeEdit",
                "questions",
              ];
            }
          },

          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: true };
        },
      },
    },
    edit: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/event/exam/{id}";
          },
          dataPath: () => {},
          replace: {
            id: () => {
              return $f.getQueryPath(3);
            },
          },
          headers: {},
          mapper: () => {},
        },
        new: {
          url: () => {
            return { data: [{ passingGrade: "0" }] };
          },
          dataPath: () => {},
          replace: {
            id: () => {
              return $f.getQueryPath(3);
            },
          },
          headers: {},
          mapper: () => {},
        },
        fromTemplate: {
          url: () => {
            return "/template/exam/{id}";
          },
          dataPath: () => {},
          replace: {},
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: (args) => {
          return "Form";
        },

        configuration: {
          headerTemplate: function () {
            return this.state.specialProperties.headerOverview.component;
          },
          includeProperties: (args) => {
            if (
              args?.moduleReferer?.props?.property?.configuration?.api ==
              "fromTemplate"
            ) {
              return [
                "_id",
                "title",
                "fromtoDatetime",
                "examDuration",
                "participants",
                "evaluators",
                "reminder",
                "instructions",
                "passingGradeEdit",
                "questions",
              ];
            }
            if (args.data[0]._id) {
              return [
                "_id",
                "title",
                "fromtoDatetime",
                "examDuration",
                //"participants",
                "evaluators",
                "reminder",
                "instructions",
                "passingGradeEdit",
                "questions",
              ];
            } else {
              return [
                "title",
                "fromtoDatetime",
                "examDuration",
                "participants",
                "evaluators",
                "reminder",
                "instructions",
                "passingGradeEdit",
                "questions",
              ];
            }
          },

          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: true };
        },
      },
    },
    detailForTraining: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/event/exam/{id}";
          },
          dataPath: () => {},
          replace: {
            id: () => {
              return $f.getQueryPath(3);
            },
          },
          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Form";
        },

        configuration: {
          topTemplate: function () {
            let data = this.state.data[0];

            if (data.fromTemplate) {
              if (data.fromTemplate.value) {
                return (
                  <div className="bg-warning p-3 rounded ">
                    <i className="fas fa-exclamation-triangle me-2"></i>
                    You can only change specific attributes. You may change all
                    the attributes, like questions, after saving the training.
                  </div>
                );
              }
            }

            return "";
          },
          includeProperties: (args) => {
            if (args.data[0].fromTemplate) {
              return [
                "_id",
                "titleReadonly",
                "fromtoDatetime",
                "examDuration",
                "evaluators",
                "reminder",
                "fromTemplate",
              ];
            } else {
              return [
                "_id",
                "title",
                "fromtoDatetime",
                "examDuration",
                "evaluators",
                "reminder",
                "instructions",
                "passingGradeEdit",
                "questions",
                "fromTemplate",
              ];
            }
          },

          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: true };
        },
      },
    },
  },
};

export default Tests;
