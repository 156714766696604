import React, { Component } from "react";
import parse from "html-react-parser";
import $c from "../../../../modules/functions";
import $f from "../../../../src";
import { Link } from "react-router-dom";
import Header from "../header";
import OptionsForHeader from "../../../../types/optionsForHeader/optionsForHeader";
import Modals from "../../../../modules/modals";
import Module from "../../../../types/module/module";

const OptionsForHeaderButton = function (data) {
  if (!$c.userIsAdmin()) {
    return [];
  }

  let property = {
    configuration: {
      options: {
        edit: function () {
          let newargs = {
            props: { data: { ...data } },
            action: "update",
          };

          return {
            label: "Edit",
            onClick: function () {
              $f.getValue(Modals.forms.editForm, newargs);
            },
          };
        },
        copyLink: function () {
          return {
            label: "Copy form link",
            onClick: function () {
              $c.copyToClipboard(window["host"]("/form/m-".concat(data._id)));
              $f.createNotification({
                type: "success",
                message: "Link copied!",
              });
            },
          };
        },
        sendInvitation: function (args) {
          return {
            label: "Send invitation",
            onClick: function () {
              $f.getValue(Modals.participants.addAllAndExternals, {
                title: "Send invitation",
                api: "new",
                actionType: "formsInvitation",
                actionId: data._id,
                btnTitle: "Send",
                subtitle: (
                  <small className="color-grey">
                    invitees will receive an email with the link of the form
                  </small>
                ),
              });
            },
          };
        },
        results: function () {
          if ($f.getQueryPath(3) === "results") {
            return;
          }

          return {
            label: "View results",
            onClick: function () {
              window.location.replace("/forms/".concat(data._id, "/results"));
            },
          };
        },

        delete: function () {
          return {
            label: <span className="text-danger">Delete</span>,
            onClick: function () {
              let newargs = {
                props: { data: { ...data } },
                callback: () => {
                  window.location.replace("/forms");
                },
              };

              $f.getValue(Modals.forms.deleteForm, newargs);
            },
          };
        },
      },
    },
  };

  return <OptionsForHeader data={data} property={property} />;
};

class FormsOverview extends Component {
  state = {
    data: [],
    loaded: false,
  };
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { data, module, view, specialProperties } = this.props;

    let templateConfiguration = view.template.configuration;

    const innerTemplates = ["header"];

    innerTemplates.map((itm, idx) => {
      let tmp = templateConfiguration[itm + "Template"]
        ? templateConfiguration[itm + "Template"]
        : () => {};
      this[itm + "Template"] = tmp.bind(this);
    });

    this.setState({
      specialProperties,
      data,
      loaded: true,
      view,
      module,
    });
  }

  render() {
    if (this.state.loaded) {
      const data = this.state.data[0]._id.data;

      return (
        <React.Fragment>
          <div className="">
            <Header
              data={{
                title: data.title,
                subTitle:
                  "In this page you'll find the overview and the results of the selected form",
                buttons: (args) => {
                  return [$f.getValue(OptionsForHeaderButton, data)];
                },
              }}
            ></Header>
          </div>
          <div>
            <ul className="nav nav-tabs navbar-expand">
              <li className="nav-item ">
                <Link
                  className={"nav-link color-strong-grey nav-ellipsis ".concat(
                    !$f.getQueryPath(3) ? "active" : ""
                  )}
                  aria-current={"/forms/".concat(data._id)}
                  to={"/forms/".concat(data._id)}
                  title="Overview"
                >
                  Overview
                </Link>
              </li>
              <li className="nav-item ">
                <Link
                  className={"nav-link color-strong-grey nav-ellipsis ".concat(
                    $f.getQueryPath(3) === "results" ? "active" : ""
                  )}
                  aria-current={"/forms/".concat(data._id, "/results")}
                  to={"/forms/".concat(data._id, "/results")}
                  title="Overview"
                >
                  Results
                </Link>
              </li>
            </ul>
          </div>

          {$f.getQueryPath(3) === "results" ? (
            <Module
              property={{
                label: "",

                configuration: (resp) => {
                  return {
                    module: "FormsResults",
                    view: "list",
                  };
                },
              }}
            />
          ) : (
            <div className="row mt-4">
              <div className="col-md-12">
                {data.instructions ? (
                  <div className=" mt-3 color-grey">
                    {parse(data.instructions)}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6">
                <div className="bg-light-grey p-2 rounded mb-2 d-flex">
                  <span className="p-2 rounded bg-white me-2">
                    <img
                      src="/uni/svg/check-mark.svg"
                      style={{ width: "20px" }}
                    />
                  </span>
                  <span className="mt-auto mb-auto">
                    {$c.testQuestionsCount(data.questions)}
                  </span>
                </div>
                <div className="bg-light-grey p-2 rounded mb-2 d-flex">
                  <span className="p-2 rounded bg-white me-2">
                    <img
                      src={
                        data.type.startsWith("public")
                          ? "/uni/svg/forms/public.svg"
                          : "/uni/svg/forms/notpublic.svg"
                      }
                      style={{ width: "20px" }}
                    />
                  </span>
                  <span className="mt-auto mb-auto">
                    {data.type.startsWith("public")
                      ? "Form is public"
                      : "Form is available for registered users"}
                  </span>
                </div>
                {data.type !== "private" ? (
                  <div className="bg-light-grey p-2 rounded mb-2 d-flex">
                    <span className="p-2 rounded bg-white me-2">
                      <img src="/uni/svg/user.svg" style={{ width: "20px" }} />
                    </span>
                    <span className="mt-auto mb-auto">
                      {data.isAnonymous
                        ? "Users who submit the form are anonymous (name & email is not required)"
                        : "Users who submit the form are not anonymous (name & email is required)"}
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6"></div>
            </div>
          )}
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default FormsOverview;
