import { Link } from "react-router-dom";
import $f from "../../src";
import JoinEventButton from "../../types/joinEventButton/joinEventButton";
import EmptyStates from "../emptyStates";
import $c from "../functions";
import Modals from "../modals";

const FormsResults = {
  properties: (args) => {
    const { editable } = args;

    return {
      _id: {
        label: "",
        path: "_id",
        labelPosition: "none",
        type: "hidden",
        events: {},
        attributes: {},
      },
      name: {
        label: "Name",
        type: "text",
        editable: editable,
        events: {},
        attributes: {
          className: "form-control",
        },
        formatValue: function (value, row) {
          if (row.userInfo === null) {
            return "-";
          }
          return $c.createAvatar(
            row.userInfo.profileImage,
            row.userInfo.firstName,
            row.userInfo.lastName
          );
        },
      },

      userType: {
        label: "User type",
        type: "text",
        editable: editable,
        events: {},
        attributes: {
          className: "form-control",
        },
      },
      userEmail: {
        label: "Email",
        type: "text",
        editable: editable,
        events: {},
        attributes: {
          className: "form-control",
        },
        formatValue: function (value, row) {
          return row.userInfo.email;
        },
      },
      status: {
        label: "status",
        type: "text",
        editable: editable,
        events: {},
        attributes: {
          className: "form-control",
        },
      },
      createdAt: {
        label: "Submitted on",
        type: "text",
        editable: editable,
        events: {},
        attributes: {},
        formatValue: (value, row) => {
          return $c.formatDateTime(row.createdAt);
        },
      },
    };
  },
  specialProperties: () => {
    return {
      header: {
        type: "template",
        configuration: {
          title: "Results",
          subTitle: "",
          template: "Header",
        },
        events: {},
      },
    };
  },
  views: {
    list: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/user-forms/forms/{id}";
          },
          dataPath: () => {},

          replace: {
            id: () => {
              return $f.getQueryPath(2);
            },
          },

          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "Table";
        },
        configuration: {
          emptyTemplate: function (args) {
            return EmptyStates.forms.results(args);
          },
          // headerTemplate: () => {
          //   return (
          //     <div className="display-3-no-bold border-bottom-1">Results</div>
          //   );
          // },
          tableConfiguration: {
            headers: {
              title: {
                attributes: {
                  className: "w-50",
                },
              },
            },
            cellTemplates: {
              name: (comp, itm) => {
                return (
                  <Link
                    to={"/forms/result/".concat(itm._id.value)}
                    className="color-grey"
                  >
                    {comp}
                  </Link>
                );
              },
            },
          },
          includeProperties: () => {
            return ["name", "userType", "userEmail", "status", "createdAt"];
          },
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
    result: {
      defaultAPI: () => {
        return "view";
      },
      apis: {
        view: {
          url: () => {
            return "/user-forms/{id}";
          },
          dataPath: () => {},

          replace: {
            id: () => {
              return $f.getQueryPath(3);
            },
          },

          headers: {},
          mapper: () => {},
        },
      },
      template: {
        name: () => {
          return "FormResult";
        },
        configuration: {
          includeProperties: () => {
            return ["_id"];
          },
          excludeProperties: () => {},
          primaryKey: "_id",
        },
        properties: () => {
          return { editable: false };
        },
      },
    },
  },
};

export default FormsResults;
